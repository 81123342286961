import React from "react";

const focus = ({
  size = "24",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.0001 12C17.0001 14.7614 14.7615 17.0001 12.0001 17.0001C9.23865 17.0001 7 14.7614 7 12C7 9.23859 9.23865 6.99994 12.0001 6.99994C14.7615 6.99994 17.0001 9.23859 17.0001 12Z" />
      <path d="M23.0001 11.0001H21.949C21.479 6.28308 17.7169 2.521 12.9999 2.05096V0.999939C12.9999 0.448059 12.553 0 12 0C11.447 0 11.0001 0.448059 11.0001 0.999939V2.05096C6.28308 2.521 2.521 6.28308 2.05096 11.0001H0.999939C0.44696 11.0001 0 11.4479 0 12C0 12.5521 0.44696 12.9999 0.999939 12.9999H2.05096C2.52191 17.7169 6.28308 21.479 11.0001 21.949V23.0001C11.0001 23.5519 11.447 24 12 24C12.553 24 12.9999 23.5519 12.9999 23.0001V21.949C17.7169 21.4781 21.479 17.7169 21.949 12.9999H23.0001C23.553 12.9999 24 12.5521 24 12C24 11.4479 23.553 11.0001 23.0001 11.0001ZM12.986 19.933C12.95 19.4139 12.529 18.9999 12 18.9999C11.471 18.9999 11.05 19.4139 11.014 19.933C7.39197 19.485 4.51593 16.6089 4.06805 12.9869C4.58698 12.95 5.00006 12.5281 5.00006 12C5.00006 11.4719 4.58698 11.05 4.06696 11.014C4.51501 7.39197 7.39105 4.51593 11.0131 4.06805C11.05 4.58606 11.471 5.00006 12 5.00006C12.529 5.00006 12.95 4.58606 12.986 4.06696C16.608 4.51501 19.4841 7.39105 19.9319 11.0131C19.413 11.05 18.9999 11.4719 18.9999 12C18.9999 12.5281 19.413 12.95 19.933 12.986C19.4841 16.6089 16.6089 19.4841 12.986 19.933Z" />
    </svg>
  );
};

export default focus;
