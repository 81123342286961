import React from "react";
 
import {
   StyledWrapper,
  StyledListBox,
    StyledListContentDescription,
  StyledContactDescriptionWrapper,
  StyledTimeAndImageWrapper,
  StyleImageWrapper,
  
} from "./TheRelationship.styles";
 
import {
  Row,
   Col,
   Progress,
   Skeleton
} from "antd";
 

const RelationshipLoaderSkeleton = () => {
  
  return (
    
   <StyledWrapper >
   <Row>
    <Col
                  className="card_wrap"
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={6}
                >
                  <StyledListBox >
                  <StyledListContentDescription style={{marginTop:"70px"}} >
                        <StyledContactDescriptionWrapper>
                          
                            <h5
                              className={
                                 "reguler_text"
                              }
                            >
                                <Skeleton.Input style={{ width: 200 ,height:20}} active={true} />
                            </h5>
                           
                            <h5
                              className={
                                
                                   "reguler_location_text"
                              }
                            >
                               <Skeleton.Input style={{ width: 200,height:20 }} active={true} />
                            </h5>
                          

                          <h5 className="source_name">
                           <Skeleton.Input style={{ width: 200,height:20 }} active={true} />
                          </h5>
                        </StyledContactDescriptionWrapper>

                        <StyledTimeAndImageWrapper>
                          <h5> <Skeleton.Input style={{ width: 50,height:20 }} active={true} />   </h5>
                          <h5>   <Skeleton.Input style={{ width: 50 ,height:20}} active={true} /> </h5>
                          <span>  <Skeleton.Input style={{ width: 50,height:20 }} active={true} />  </span>

                          <StyleImageWrapper>
                            
                            
                            
                              <Progress
                                type="circle"
                                showInfo={false}
                                strokeWidth="6"
                                strokeColor={{
                                  "0%": "transparent",
                                  "100%": "transparent",
                                }}
                                percent={100}
                                width={160}
                              />
                            
                          </StyleImageWrapper>
                        </StyledTimeAndImageWrapper>
                        <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} />
                        </StyledListContentDescription>
                       
                  </StyledListBox>
                  
    </Col>
    <Col
                  className="card_wrap"
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={6}
                >
                  <StyledListBox>
                  <StyledListContentDescription style={{marginTop:"70px"}}>
                        <StyledContactDescriptionWrapper>
                          
                            <h5
                              className={
                                 "reguler_text"
                              }
                            >
                                <Skeleton.Input style={{ width: 200 ,height:20}} active={true} />
                            </h5>
                           
                            <h5
                              className={
                                
                                   "reguler_location_text"
                              }
                            >
                               <Skeleton.Input style={{ width: 200,height:20 }} active={true} />
                            </h5>
                          

                          <h5 className="source_name">
                           <Skeleton.Input style={{ width: 200,height:20 }} active={true} />
                          </h5>
                        </StyledContactDescriptionWrapper>

                        <StyledTimeAndImageWrapper>
                          <h5> <Skeleton.Input style={{ width: 50,height:20 }} active={true} />   </h5>
                          <h5>   <Skeleton.Input style={{ width: 50 ,height:20}} active={true} /> </h5>
                          <span>  <Skeleton.Input style={{ width: 50,height:20 }} active={true} />  </span>

                          <StyleImageWrapper>
                            
                            
                            
                              <Progress
                                type="circle"
                                showInfo={false}
                                strokeWidth="6"
                                strokeColor={{
                                  "0%": "transparent",
                                  "100%": "transparent",
                                }}
                                percent={100}
                                width={160}
                              />
                            
                          </StyleImageWrapper>
                        </StyledTimeAndImageWrapper>
                        <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} />
                        </StyledListContentDescription>
                       
                  </StyledListBox>
                  
    </Col>
    <Col
                  className="card_wrap"
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={6}
                >
                  <StyledListBox>
                  <StyledListContentDescription style={{marginTop:"70px"}}>
                        <StyledContactDescriptionWrapper>
                          
                            <h5
                              className={
                                 "reguler_text"
                              }
                            >
                                <Skeleton.Input style={{ width: 200 ,height:20}} active={true} />
                            </h5>
                           
                            <h5
                              className={
                                
                                   "reguler_location_text"
                              }
                            >
                               <Skeleton.Input style={{ width: 200,height:20 }} active={true} />
                            </h5>
                          

                          <h5 className="source_name">
                           <Skeleton.Input style={{ width: 200,height:20 }} active={true} />
                          </h5>
                        </StyledContactDescriptionWrapper>

                        <StyledTimeAndImageWrapper>
                          <h5> <Skeleton.Input style={{ width: 50,height:20 }} active={true} />   </h5>
                          <h5>   <Skeleton.Input style={{ width: 50 ,height:20}} active={true} /> </h5>
                          <span>  <Skeleton.Input style={{ width: 50,height:20 }} active={true} />  </span>

                          <StyleImageWrapper>
                            
                            
                            
                              <Progress
                                type="circle"
                                showInfo={false}
                                strokeWidth="6"
                                strokeColor={{
                                  "0%": "transparent",
                                  "100%": "transparent",
                                }}
                                percent={100}
                                width={160}
                              />
                            
                          </StyleImageWrapper>
                        </StyledTimeAndImageWrapper>
                        <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} />
                        </StyledListContentDescription>
                       
                  </StyledListBox>
                  
    </Col>
    <Col
                  className="card_wrap"
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={6}
                >
                  <StyledListBox>
                  <StyledListContentDescription style={{marginTop:"70px"}}>
                        <StyledContactDescriptionWrapper>
                          
                            <h5
                              className={
                                 "reguler_text"
                              }
                            >
                                <Skeleton.Input style={{ width: 200 ,height:20}} active={true} />
                            </h5>
                           
                            <h5
                              className={
                                
                                   "reguler_location_text"
                              }
                            >
                               <Skeleton.Input style={{ width: 200,height:20 }} active={true} />
                            </h5>
                          

                          <h5 className="source_name">
                           <Skeleton.Input style={{ width: 200,height:20 }} active={true} />
                          </h5>
                        </StyledContactDescriptionWrapper>

                        <StyledTimeAndImageWrapper>
                          <h5> <Skeleton.Input style={{ width: 50,height:20 }} active={true} />   </h5>
                          <h5>   <Skeleton.Input style={{ width: 50 ,height:20}} active={true} /> </h5>
                          <span>  <Skeleton.Input style={{ width: 50,height:20 }} active={true} />  </span>

                          <StyleImageWrapper>
                            
                            
                            
                              <Progress
                                type="circle"
                                showInfo={false}
                                strokeWidth="6"
                                strokeColor={{
                                  "0%": "transparent",
                                  "100%": "transparent",
                                }}
                                percent={100}
                                width={160}
                              />
                            
                          </StyleImageWrapper>
                        </StyledTimeAndImageWrapper>
                        <Skeleton.Button active={true} size={'default'} shape={'default'} block={false} />
                        </StyledListContentDescription>
                       
                  </StyledListBox>
                  
    </Col>
    </Row>
</StyledWrapper>    
    
  );
};

export default RelationshipLoaderSkeleton;
