import { Breadcrumb, Modal, Form, Input, InputNumber } from "antd";
import React, { useState } from "react";
import Layout from "../containers/TheLayout/Layout";
import styled from "styled-components";
import { Button } from "antd/lib/radio";
import { Link, useHistory } from "react-router-dom";
import { CloseCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { adminRoutes } from "../helpers/adminRoutes";

const StyleLayout = styled.div`
  padding: 15px;
  background: #f6f7f9;
  border-radius: 20px 20px 0px 0px;
  @media screen and (max-width: 959px) {
    padding: 15px;
  }
`;

const StyledButton = styled(Button)`
  width: 100px;
  min-width: 80px;
  height: 48px;
  background: linear-gradient(
    276.7deg,
    ${(props) => props.theme.colors.primary} 0%,
    #3798db 100%
  );
  box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
  border-radius: 5px !important;
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : "#FFFFFF")};

  left: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.div`
  position: absolute;
  z-index: 10;
  left: 40%;
  top: -55px;
`;

const StyledContainerControl = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 40px 30px;

  .ant-btn ant-btn-primary {
    width: 100px;
    min-width: 80px;
    height: 48px;
    background: linear-gradient(
      276.7deg,
      ${(props) => props.theme.colors.primary} 0%,
      #3798db 100%
    );
    box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
    border-radius: 5px;
    font-size: 14px;
    color: ${(props) => (props.color ? props.color : "#FFFFFF")};
  }
`;
const StyledFormControl = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const StyledFormItem = styled.div`
  width: 400px;
  max-width: 100%;
  margin-right: 30px;
  :last-child {
    width: 830px;
  }
  .custom-textbox {
    display: flex;
    flex-direction: column;
    .ant-form-item-label {
      align-self: flex-start;
    }
  }
`;

const StyledCloseButton = styled.div`
  .ant-radio-button-wrapper {
    font-size: 24px;
    color: #747487;
  }
`;

const StyledWrapper = styled.div`
  padding: 0 10px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  position: relative;
`;
const StyleListImage = styled.div`
  width: 300px;
  height: 300px;
`;

const StyledWrapperMenu = styled.ul`
  margin-top: 50px;
  list-style-type: none;
  margin-top: 50px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
  position: absolute;
  top: 55px;
  right: 10px;
  list-style: none;
  z-index: 1;
  min-height: 120px;
  min-width: 120px;
  border: 1px solid #eee;
`;

const StyledWrapperLI = styled.li`
  min-height: 36px;
  padding: 8px 0px;
  // margin-bottom:10px;
  .ant-radio-button-wrapper {
    width: 100%;
  }
`;

const StyledListBOX = styled.section`
  height: 310px;
  background: rgba(0, 0, 0, 0.1);
  flex: 0 0 23%;
  -ms-flex: 0 0 23%;
  max-width: 100%;
  // padding: 15px !important;
  display: block;
  width: 100%;
  // padding:25px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0 20px 20px 0;
  position: relative;
  top-margin: 100px;
  position: relative;
  .ant-radio-button-wrapper {
    border: none;
    content: none;
    position: relative;
    padding: 0px;
    border-radius: 10px !important;
    width: 100%;
    // position: absolute;
    // right: 0%;
    iframe {
      border-radius: 10px 10px 0px 0px;
      width: 100%;
      height: 180px;
    }
  }
  .ant-radio-button-wrapper:before {
    content: none;
  }
`;
const StyledListContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-item: center;
  text-align: left;
  font-size: 18px;
  line-height: normal;
  margin: 50px 0px 0px 10px;
  text-overflow: initial;
  white-space: initial;
  height: 400px;
`;

const StyledListContentDescription = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-item: center;
  .ant-radio-button-wrapper {
    position: inherit;
  }
`;

const StyledListTimeDetail = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 35%;
  left: 35%;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  margin-top: 15px;
`;

const StyledListTimeCameIn = styled.label`
  margin-bottom: 5px;
  font-size: 14px;
  color: #747487;
`;

const StyledListDuration = styled.label`
  margin-bottom: 5px;
  background: linear-gradient(#013465, #1884d9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  font-weight: 900;
`;

const StyledListAgo = styled.label`
  font-size: 14px;
  color: #747487;
`;

const StyledShowContact = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`;
const StyledContentTitle = styled.div`
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 34px;
  line-height: 28px;
  margin-bottom: 30px;
  color: #232333;
  width: max-content;
  padding-bottom: 14px;
`;

const StyledContactDetail = styled.div`
  margin-bottom: 5px;
  .ant-radio-button-wrapper {
    position: inherit;
    display: table-row;
    span {
      font-family: Mulish;
      font-size: 24px;
      font-weight: 800;
    }
  }
`;

const StyledMoveToULDetail = styled.ul`
  margin-top: 50px;
  height: 100%;
  width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  list-style-type: none;
`;

const StyledMoveToLIDetail = styled.li`
  min-height: 60px;
  padding: 8px 0px;
  text-align: center;
  .ant-radio-button-wrapper {
    position: inherit;
    span {
      font-family: Mulish;
      font-size: 24px;
      font-weight: 800;
    }
  }
`;

const StyledShowActivityUL = styled.ul`
  width: inherit;
`;

const StyledShowActivity = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const StyledShowActivityDetailBoldContent = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #232333;
`;

const StyledShowActivityDetailItalicContent = styled.label`
  font-family: Mulish;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #232333;
`;

const StyledShowActivityDetailNormalContent = styled.label`
  font-family: Mulish;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #232333;
`;

const StyledOpenModal = styled.div`
  .ant-radio-button-wrapper {
    background: transparent;
    border: none;
    padding: 0px;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
  }
`;

const StyledMenuAndButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .ant-radio-button-wrapper:focus-within {
    box-shadow: unset;
  }
`;

const StyledMenuContentTitle = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #232333;
`;

const StyledMenuWrapper = styled.div`
  height: 48px;
  background-color: white;
  width: 482px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
`;

const StyledLinkWrapper = styled.span`
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${(props) => props.theme.colors.primary};
  a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const StyledPlusIconWrapper = styled.div`
  .anticon {
    color: ${(props) => props.theme.colors.primary};
    margin-right: 14px;
    font-size: 48px;
    background: linear-gradient(
      276.7deg,
      ${(props) => props.theme.colors.primary} 0%,
      #3798db 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 50%;
    box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
  }
`;
const StyledVideoInformationWrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const StyledVideNameInfo = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #232333;
  margin-bottom: 10px;
`;

const StyledCreaterInfo = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #747487;
  margin-bottom: 13px;
`;

const StyledVideoCount = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #747487;
`;

const data = [
  {
    id: 1,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 2,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 3,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 4,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 5,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 6,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
];
const Video = () => {
  const history = useHistory();

  const [state, setState] = useState({});

  const handleVideoById = (e, i) => {
    history.push(adminRoutes.VIDEODETAIL);
  };
  return (
    <div>
      <StyleLayout>
        <div className="breadcrumbs">
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Relationships</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <StyledMenuAndButtonWrapper>
          <StyledOpenModal>
            <Button>
              <StyledPlusIconWrapper>
                <PlusCircleFilled />
              </StyledPlusIconWrapper>
            </Button>
          </StyledOpenModal>
          <StyledMenuContentTitle>Grow</StyledMenuContentTitle>
        </StyledMenuAndButtonWrapper>
        <StyledWrapper>
          {data.map((k, index) => (
            <StyledListBOX key={index}>
              <Button onClick={(e) => handleVideoById(e, index)}>
                <Button>
                  <iframe src={k.video}></iframe>
                </Button>
                <StyledVideoInformationWrapper>
                  <StyledVideNameInfo> {k.name} </StyledVideNameInfo>
                  <StyledCreaterInfo> By {k.name} </StyledCreaterInfo>
                  <StyledVideoCount>
                    {" "}
                    {k.id}/{k.total}{" "}
                  </StyledVideoCount>
                </StyledVideoInformationWrapper>
              </Button>
            </StyledListBOX>
          ))}
        </StyledWrapper>
      </StyleLayout>
    </div>
  );
};

export default Video;
