import React, { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import { SecondaryButton } from "../../../../ui/Button";
import { Card } from "../../../../ui/Card";
import { Datatable, UserAvatarAndFullName } from "../../../../ui/Datatable";
import { Heading } from "../../../../ui/Heading";
import { Container } from "../../../../ui/Layout/Container";
import Actions from "../../../../ui/Datatable/Actions";
import { ToggleSwitch } from "../../../../ui/Form";

import { useHistory } from "react-router-dom";
import { StyledLink } from "./TheSourceList.styles";
import { settingsRoutes } from "helpers/adminRoutes";
import SourceAPI from "api/http/source";
import {
  getDynamicMenuString,
  imagePath,
  paginationObj,
  querystring,
  searchObj,
} from "utils/fn";
import { SecondaryFloatButton } from "ui/Button/index";
import { useSelector } from "react-redux";

const Source = () => {
  const history = useHistory();
  const { configData } = useSelector((state) => state.configData);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {},
    sorter: {},
    populate: [
      { path: "relationships" },
      {
        path: "relationships",
        path: "userId",
        select: ["firstName", "lastName", "avatar"],
      },
    ],
  });

  const getSourceList = useCallback((pagination) => {
    setLoading(true);
    SourceAPI.getSourceList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData(data && data.data);
        setPagination((p) => ({ ...p, total: data.total }));
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getSourceList(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSourceList]);

  const handleDelete = (id) => {
    SourceAPI.deleteSource(id, { id })
      .then(() => {
        getSourceList(pagination);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const handleClick = () => {
    history.push(settingsRoutes.SOURCE_ADD, {
      from: settingsRoutes.SOURCE,
    });
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_) => {
        return <ToggleSwitch defaultChecked={_} onChange={console.log} />;
      },
      fixed: "left",
      width: 70,
    },
    {
      title: "Title",
      dataIndex: "source",
      key: "source",
      sorter: (a, b) => a.source.localeCompare(b.source),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.userId?.firstName.localeCompare(b.userId?.firstName),
      render: (_, record) => {
        return (
          <UserAvatarAndFullName
            name={`${record?.userId?.firstName || ""} ${
              record?.userId?.lastName || ""
            }`}
            image={
              record.userId?.avatar?.path
                ? imagePath(record.userId?.avatar?.path)
                : ""
            }
          />
        );
      },
    },
    {
      title: "Source",
      dataIndex: "",
      key: "source",
      render: (_, record) => {
        return (
          <span>
            <StyledLink
              href={record.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview Link
            </StyledLink>
          </span>
        );
      },
    },
    {
      title: "Visibility",
      dataIndex: "visibilityType",
      key: "visibilityType",
      sorter: (a, b) => a.visibilityType.localeCompare(b.visibilityType),
    },
    {
      title: "New Relationships",
      dataIndex: "relationships",
      key: "relationships",
      sorter: (a, b) => a.relationships - b.relationships,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(settingsRoutes.SOURCE_EDIT.replace(":id", item), {
          from: settingsRoutes.SOURCE,
          id: item,
        });
        break;
      case "delete":
        handleDelete(item);
        break;

      default:
        break;
    }
  };

  const filterChangeHandler = ({ search, ...page }, filters, sorter) => {
    const filter = searchObj(["source"], search);
    setPagination((p) => ({ ...p, ...page, filter, sorter }));
    getSourceList({
      ...pagination,
      ...page,
      filter,
      populate: [
        { path: "relationships" },

        {
          path: "userId",
          select: ["firstName", "lastName", "avatar"],
        },
      ],
      sorter,
    });
  };

  return (
    <Container>
      <Heading>
        {getDynamicMenuString(configData?.[0], ["connect", "sources"])}
      </Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          loading={loading}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <SecondaryFloatButton
              onClick={handleClick}
              buttonText={
                "Create " +
                getDynamicMenuString(configData?.[0], ["connect", "sources"])
              }
            />
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default Source;
