import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import { SecondaryButton } from "ui/Button";
import { Card } from "ui/Card";
import { Datatable, UserAvatarAndFullName } from "ui/Datatable";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import Actions from "ui/Datatable/Actions";
import { ToggleSwitch } from "ui/Form";
import { useHistory } from "react-router";
import { settingsRoutes } from "helpers/adminRoutes";
import UserAPI from "../../../../api/http/user";
import { imagePath, paginationObj, querystring, searchObj } from "utils/fn";
import { message } from "antd";
import { SecondaryFloatButton } from "ui/Button/index";

const User = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {},
    sorter: {},
  });

  const getUserList = useCallback((pagination) => {
    setLoading(true);
    UserAPI.getUserList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData(data?.data);
        setPagination((p) => ({ ...p, total: data?.total }));
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getUserList(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserList]);

  const handleDelete = (id, item) => {
    UserAPI.deleteUser(item, { id: item })
      .then(({ data }) => {
        getUserList(pagination);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const updateStatus = (userId, status) => {
    UserAPI.updateUser(userId, { status })
      .then(() => {
        getUserList(pagination);
        message.success("User updated successfully");
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (_, record) => {
        return (
          <ToggleSwitch
            checked={_}
            onChange={(value) => updateStatus(record._id, value)}
          />
        );
      },
      fixed: "left",
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (_, row) => {
        return (
          <UserAvatarAndFullName
            name={`${row.firstName} ${row.lastName}`}
            image={row?.avatar?.path ? imagePath(row?.avatar?.path) : ""}
          />
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Groups",
      dataIndex: "groups",
      key: "groups",
      // sorter: (a, b) => a.groups.localeCompare(b.groups),
    },
    {
      title: "Date Joined",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (_) => {
        return dayjs(_).format("M/DD/YYYY");
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(settingsRoutes.USER_EDIT.replace(":id", item));
        break;
      case "delete":
        handleDelete(":id", item);
        break;
      default:
        break;
    }
  };

  const filterChangeHandler = ({ search, ...page }, filters, sorter) => {
    const filter = searchObj(["firstName", "lastName", "email"], search);
    setPagination((p) => ({ ...p, ...page, filter, sorter }));
    getUserList({ ...pagination, ...page, filter, sorter });
  };

  const inviteUserClickHandler = () => {
    history.push(settingsRoutes.USER_ADD);
  };

  return (
    <Container>
      <Heading>Users</Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          loading={loading}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <SecondaryFloatButton
              onClick={inviteUserClickHandler}
              buttonText="Invite User"
            />
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default User;
