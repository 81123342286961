import React from "react";
import { ConnectButtonWrap } from "./TheRelationship.styles";
import { Button } from "antd";
import { FaUnlockAlt } from "react-icons/fa";

const ConnectButton = (props) => {
  return (
    <ConnectButtonWrap>
      <Button
        className="connect_button"
        // shape="round"
        // size="large"
        icon={<FaUnlockAlt className="connect_lock_button" />}
        {...props}
      >
        Connect
      </Button>
    </ConnectButtonWrap>
  );
};

export default ConnectButton;
