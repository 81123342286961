import React, { useEffect, useState } from "react";
import { Empty, message } from "antd";
import { HeroCard } from "../../../ui/Card";
import { Heading } from "../../../ui/Heading";
import {
  StyledCardWrapper,
  HeadingWrapper,
  // StyledActionButton,
} from "../style";
import { imagePath } from "utils/fn";
import { teamStatus } from "utils/constants";
import { HiPlus } from "react-icons/hi";
import AddTeamModal from "Components/Team/AddTeam/AddTeam";
import { useDispatch, useSelector } from "react-redux";
import { adminRoutes } from "helpers/adminRoutes";
import { me } from "store/reducers/user.reducer";
import { SecondaryButton } from "ui/Button/Button";
import { FloatButton } from "Components/Relationship/TheRelationship.styles";
import AddTeamMembers from "Components/Team/AddTeam/AddTeamMembers";
import {
  AcceptDeniedButton,
  StyledActionButton,
} from "Components/Team/FindTeam/style";
import { FaCheck, FaTimes } from "react-icons/fa";
import TeamAPI from "api/http/team";
import { useHistory, useLocation } from "react-router-dom";

const TeamSection = ({ user }) => {
  const [data, setData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { user: loggedInUser } = useSelector((state) => state.user);
  const userId = localStorage.getItem("userId");
  const history = useHistory();

  const getStatusMessage = (type) => {
    switch (type) {
      case "accept":
        return "accepted";
      case "reject":
        return "rejected";
      default:
        return "Updated";
    }
  };

  const getStatus = (type) => {
    switch (type) {
      case "accept":
        return teamStatus.joined;
      case "reject":
        return teamStatus.rejected;

      default:
        return "";
    }
  };

  const refreshTeams = () => {
    dispatch(me()).then(() => {
      // getTeamList(pagination);
      console.log("joined");
    });
  };

  useEffect(() => {
    setData(
      (user?.teams || [])
        .filter((ts) => ts.status === teamStatus.joined)
        .map((t) => t.team)
    );
  }, [user?.teams]);

  const [modalStates, setModalStates] = useState(false);
  const [teamId, setTeamId] = useState("");
  const teamIdData = (teamIdUser) => {
    setTeamId(teamIdUser);
  };

  const joinActionHandler = (team) => {
    TeamAPI.joinTeam({
      team: team?._id,
      user: loggedInUser?._id || userId,
      status:
        team?.visibility === "public"
          ? teamStatus.joined
          : teamStatus.requested,
    })
      .then(() => {
        refreshTeams();
        if (team?.visibility === "public") {
          history.push(adminRoutes.TEAMDETAILS.replace(":name", team?._id));
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const handleAcceptReject = (e, item, type) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === "accept") {
      actionHandler("accept", item);
    } else {
      actionHandler("reject", item);
    }
  };

  const actionHandler = (type, team) => {
    switch (type) {
      case "accept":
      case "reject":
        TeamAPI.updateTeamMember(team?.joined._id, {
          status: getStatus(type),
          team: team?._id,
        })
          .then((data) => {
            if (getStatus(type) === "Joined") {
              history.push(adminRoutes.TEAMDETAILS.replace(":name", team?._id));
            } else {
              history.push(adminRoutes.TEAM);
            }
            dispatch(me());
            // refreshTeams();
            message.success(
              type === "reject"
                ? " Request declined successfully"
                : `Request ${getStatusMessage(type)} successfully`
            );
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
        break;
      default:
        break;
    }
  };
  return (
    <StyledCardWrapper>
      <HeadingWrapper>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
          onClick={() => setModalState(true)}
          height={"52px"}
          width={"52px"}
        />
        <Heading fontSize={"24px"} color={"#232333"} marginBottom={"0px"}>
          Community
        </Heading>
      </HeadingWrapper>

      {(data || []).map(
        (item, idx) =>
          item !== null && (
            <HeroCard
              item={item}
              key={idx}
              imageSrc={imagePath(item?.avatar?.path)}
              // imageWidth={"337px"}
              // imageHeight={"100%"}
              cardHeading={item?.name}
              cardGroup={item?.visibility}
              cardMember={item?.memberCount}
              actionButton={
                location.pathname !== "/profile" &&
                !(
                  !!item?.joined &&
                  [teamStatus.rejected].includes(item?.joined?.status)
                ) ? (
                  <>
                    {item?.joined?.initiateByRole === "admin" ? (
                      <AcceptDeniedButton>
                        <button
                          className="greenBtn"
                          onClick={(e) => handleAcceptReject(e, item, "accept")}
                        >
                          <FaCheck
                            className="check"
                            // onClick={() => actionHandler("accept", item)}
                          />
                        </button>
                        <button
                          className="redBtn"
                          onClick={(e) => handleAcceptReject(e, item, "reject")}
                        >
                          <FaTimes
                            className="cancel"
                            // onClick={() => actionHandler("reject", item)}
                          />
                        </button>
                      </AcceptDeniedButton>
                    ) : (
                      <StyledActionButton
                        onClick={() => joinActionHandler(item)}
                        disabled={!!item?.joined}
                      >
                        {item?.joined
                          ? item?.joined?.initiateByRole === "user"
                            ? item?.joined?.status
                            : ""
                          : item?.visibility === "public"
                          ? "Join"
                          : "Request"}
                      </StyledActionButton>
                    )}
                  </>
                ) : null
              }
              cardDescription={item?.description}
              users={item?.admins}
            />
          )
      )}
      {!data.length && <Empty description={`Data Not Found!`} />}
      <AddTeamModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        setModalStat={setModalState}
        from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        teamIdUser={teamIdData}
      />
      <AddTeamMembers
        onCancel={(value) => setModalStates(value)}
        modalState={modalStates}
        from={adminRoutes.TEAM}
        teamId={teamId}
      />
      <FloatButton>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
          onClick={() => setModalState(true)}
        />
      </FloatButton>
    </StyledCardWrapper>
  );
};
export default TeamSection;
