import React, { useState, useEffect } from "react";
import { Row } from "antd";
import { EventCard } from "../../../ui/Card";
import { StyledEventWrapper } from "../style";

const EventSection = ({ UserProfileEventData, tabKey }) => {
  const typesOfEvent = UserProfileEventData.map((e) => e.type);
  const [forKey, setForKey] = useState();

  const tpye = typesOfEvent.forEach((element) => {
    if (element === tabKey) {
      console.log("done");
    } else {
      console.log("not tabkey");
    }
  });

  console.log(
    typesOfEvent.map((e) => e === tabKey),
    "typesOfEvent"
  );
  console.log(tpye, "type");

  return (
    <StyledEventWrapper>
      <Row gutter={[30, 30]}>
        {UserProfileEventData.map((item) => (
          <EventCard
            eventName={item.eventName}
            eventAt={item.eventAt}
            eventBy={item.eventBy}
            eventUsers={item.users}
            eventImage={item.image}
            eventType={item.type}
          />
        ))}
      </Row>
    </StyledEventWrapper>
  );
};

export default EventSection;
