export const users = [
  {
    id: 1,
    status: true,
    points: 5,
    rewardName: "Reward 1",
  },
  {
    id: 2,
    status: false,
    points: 5,
    rewardName: "Reward 2",
  },
  {
    id: 3,
    status: true,
    points: 5,
    rewardName: "Reward 3",
  },
];

export const apiKeys = [
  {
    id: 1,
    status: true,
    name: "Publishable Key",
    token: "pk_live_ndwHDp9817ocSR2RJ3Vw0KBn",
    createdAt: "2021-05-21",
  },
  {
    id: 2,
    status: false,
    name: "Secret Key",
    token: "sk_live_ndwHDp9817ocSD2RJ3Vw0KBn",
    createdAt: "2021-05-21",
  },
];
