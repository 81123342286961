import React from "react";

const settings = ({ color = "#666666", ...props }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22 12.3537V9.60369L18.7131 8.23419C18.6216 7.97638 18.5254 7.72681 18.4071 7.48206L19.734 4.17863L17.7891 2.23438L14.5152 3.58256C14.2656 3.46156 14.0106 3.35981 13.7473 3.26562L12.3537 0H9.60369L8.2445 3.25463C7.97087 3.35156 7.70756 3.45331 7.447 3.57706L4.17863 2.26394L2.23438 4.20819L3.56675 7.447C3.4375 7.71306 3.333 7.98394 3.23331 8.26375L0 9.64631V12.3963L3.23606 13.7445C3.33575 14.0236 3.443 14.2952 3.57156 14.5613L2.26394 17.8214L4.20819 19.7656L7.45525 18.4284C7.71581 18.5522 7.98188 18.6512 8.2555 18.7454L9.64631 22H12.3963L13.7576 18.7289C14.0181 18.6319 14.2759 18.5302 14.5228 18.4119L17.8207 19.7333L19.7649 17.7891L18.4113 14.5076C18.5268 14.2608 18.6209 14.0106 18.7124 13.7555L22 12.3537ZM10.9787 15.125C8.70169 15.125 6.85369 13.277 6.85369 11C6.85369 8.723 8.70169 6.875 10.9787 6.875C13.2557 6.875 15.1037 8.723 15.1037 11C15.1037 13.277 13.2557 15.125 10.9787 15.125Z" />
    </svg>
  );
};

export default settings;
