import { Row, Col, Form, Button } from "antd";
import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "ui/Button";
import { Card } from "ui/Card";
import { CardTitle } from "ui/Card/Card";
import { FormInput, FormItem } from "ui/Form";
import { Container } from "ui/Layout/Container";
import { HiPlus } from "react-icons/hi";
import { DeleteSvg } from "../../../../assets/svgs";
import Icon from "@ant-design/icons";
import { ToggleSwitch } from "ui/Form/ToggleSwitch";

const DeleteIcon = (props) => {
  return <Icon size={10} component={DeleteSvg} {...props} />;
};

const StyledInputAllWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 822px;

  gap: 20px;

  .input_details_mobile {
    display: none;
  }

  @media (max-width: 348px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 0px;

    .input_details_mobile {
      display: block;
    }
  }
`;

const StyledFormItem = styled(FormItem)`
  .ant-form-item-control-input-content {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .input_details {
    display: block;
  }

  @media (max-width: 348px) {
    .input_details {
      display: none;
    }
  }
`;

const StyledButtonWrapper = styled.div``;

const Streak = () => {
  const handleAddField = () => {
    console.log("add field");
  };
  const handleDeleteField = () => {
    console.log("add field");
  };

  return (
    <Container>
      {/* <Heading>{params.id ? "Edit Points" : "Points"}</Heading> */}
      <Card padding="25px 30px 15px">
        <CardTitle>Streaks</CardTitle>
        <Container bgColor="white">
          <Form
            layout="vertical"
            onFinish={(e) => console.log(e)}
            requiredMark={false}
          >
            <FormItem
              label="Enabled"
              name="enabled"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <ToggleSwitch />
            </FormItem>

            <StyledInputAllWrapper>
              <StyledFormItem
                label="Select Multiplier"
                name="multiplier"
                type="text"
                placeholder=" "
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
                // maxWidth={"100px"}
              >
                <FormInput />
                <span className="input_details">%</span>
              </StyledFormItem>

              <span className="input_details_mobile">%</span>

              <StyledFormItem
                label="Every"
                name="every"
                type="text"
                placeholder=""
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
                // maxWidth={"80px"}
              >
                <FormInput />
                <span className="input_details">Days</span>
              </StyledFormItem>

              <span className="input_details_mobile">Days</span>
            </StyledInputAllWrapper>

            <StyledButtonWrapper>
              {/* <FormItem> */}
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                Save
              </PrimaryButton>
              {/* </FormItem> */}
            </StyledButtonWrapper>
          </Form>
        </Container>
      </Card>
    </Container>
  );
};

export default Streak;
