import { Form } from "antd";
import TypeAPI from "api/http/type";
import { settingsRoutes } from "helpers/adminRoutes";
import React, { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { PrimaryButton } from "ui/Button";
import { Card, CardTitle } from "ui/Card";
import { FormInput, FormItem } from "ui/Form";
import { Container } from "ui/Layout/Container";
import { message } from "antd";
import { Prompt } from "react-router";
import { useBeforeunload } from "react-beforeunload";

const AddType = () => {
  const params = useParams();
  const history = useHistory();
  const [showPrompt, setShowPrompt] = React.useState(false);

  const [form] = Form.useForm();

  const getType = useCallback((id) => {
    TypeAPI.getType(id)
      .then(({ data }) => {
        form.setFieldsValue({ name: data?.name });
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.id) {
      getType(params.id);
    }
  }, [getType, params.id]);

  const handleSubmit = (data) => {
    setShowPrompt(false);
    if (params.id) {
      TypeAPI.updateType(params.id, { ...data })
        .then(() => {
          message.success("Type updated successfully");
          history.push(settingsRoutes.TYPE);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    } else {
      TypeAPI.addType({ ...data })
        .then(() => {
          message.success("Type added successfully");
          history.push(settingsRoutes.TYPE);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    }
  };
  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });
  return (
    <Container>
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Card padding="25px 30px 15px">
        <CardTitle containerStyle={{ padding: "10px 0 30px 0" }}>
          {params.id ? "Edit Type" : "Type"}
        </CardTitle>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          requiredMark={false}
          onValuesChange={() => {
            setShowPrompt(true);
          }}
        >
          <FormItem
            label="Type Name"
            name="name"
            type="text"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormInput placeholder="Enter type name" />
          </FormItem>
          <FormItem>
            <PrimaryButton margin="15px 0 0 0" htmlType="submit">
              Save
            </PrimaryButton>
          </FormItem>
        </Form>
      </Card>
    </Container>
  );
};

export default AddType;
