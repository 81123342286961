import { Form, message } from "antd";
import PointsAPI from "api/http/points";
import { settingsRoutes } from "helpers/adminRoutes";
import React, { useEffect, useCallback, useState } from "react";
import { useParams, useHistory } from "react-router";
import { PrimaryButton } from "ui/Button";
import { Card } from "ui/Card";
import { CardTitle } from "ui/Card/Card";
import { FormInput, FormItem } from "ui/Form";
import { FormSelect } from "ui/Form/Select";
import { ToggleSwitch } from "ui/Form/ToggleSwitch";
import { Heading } from "ui/Heading/Heading";
import { Container } from "ui/Layout/Container";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import { ResetDailyText, StyledInputWrapper } from "./styled";
import { groupBy } from "utils/fn";
import { pointActions } from "utils/constants";

const AddPoints = () => {
  const params = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const [timemodifiers, setTimeModifier] = useState(false);
  const [defaulState, setDefaultState] = useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [dataChange, setDataChange] = useState(false);

  const leadOptions = [...pointActions];

  const operatorOptions = [
    { label: "Add", value: "add" },
    { label: "Remove", value: "remove" },
  ];

  const timeOptions = [
    { label: "Hours", value: "hours" },
    { label: "Minutes", value: "minutes" },
  ];

  const getPoints = useCallback((id) => {
    PointsAPI.getPoints(id)
      .then(({ data }) => {
        form.setFieldsValue({
          ...data,
          action: data?.action,
          maxRedemptions: data?.maxRedemptions,
          timeModifier: data?.timeModifier,
          pointsAwarded: data?.pointsAwarded,
        });
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.id) {
      getPoints(params.id);
    }
  }, [getPoints, params.id]);

  const handleStatus = (e) => {
    setTimeModifier(e);
    setDataChange(true);
  };

  const handleSubmit = (data) => {
    setShowPrompt(false);
    setDataChange(false);
    data.timeModifier = timemodifiers;
    if (params.id) {
      PointsAPI.updatePoints(params.id, { ...data })
        .then(() => {
          message.success("Type updated successfully");
          history.push(settingsRoutes.POINT);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    } else {
      PointsAPI.addPoints({ ...data })
        .then(() => {
          message.success("Type added successfully");
          history.push(settingsRoutes.POINT);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    }
  };
  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  return (
    <Container>
      {/* { form.isFieldsTouched() || (dataChange===true && defaulState === false) && <Beforeunload
                    onBeforeunload={() => "You will lose all changes if you navigate away from this page without saving"} />} */}
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      {/* <Heading>{params.id ? "Edit Points" : "Points"}</Heading> */}
      <Card padding="25px 30px 15px">
        <CardTitle>{params.id ? "Edit Points" : "Points"}</CardTitle>
        <Container bgColor="white">
          <Form
            form={form}
            initialValues={
              params.id
                ? {}
                : {
                    timeModifier: false,
                    operator: "add",
                    time: "hours",
                  }
            }
            layout="vertical"
            onFinish={(e) => handleSubmit(e)}
            requiredMark={false}
            onValuesChange={() => {
              setShowPrompt(true);
            }}
          >
            <FormItem
              label="Select Action"
              name="action"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormSelect
                placeholder="Select action"
                // defaultValue="addLead"
                options={groupBy(leadOptions, "group")}
                hasGroup={true}
              />
            </FormItem>
            <FormItem
              label="Points Awarded"
              name="pointsAwarded"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput />
            </FormItem>
            <FormItem
              label="Time Modifier ?"
              name="timeModifier"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <ToggleSwitch onChange={(e) => handleStatus(e)} />
            </FormItem>

            {/* New Input Start */}
            <StyledInputWrapper>
              <FormItem
                label="Operator"
                name="operator"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormSelect options={operatorOptions} />
              </FormItem>

              <FormItem
                label="Points"
                name="points"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormInput placeholder="Enter points" />
              </FormItem>

              <FormItem
                label="Every"
                name="every"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormInput placeholder="Enter every" />
              </FormItem>

              <FormItem
                label="Time"
                name="time"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormSelect options={timeOptions} />
              </FormItem>
            </StyledInputWrapper>
            {/* New Input End */}

            <FormItem
              label="Max Redeemptions"
              name="maxRedemptions"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
              extra={"This resets daily at midnight"}
            >
              <FormInput width={"480px"} />
            </FormItem>
            <FormItem>
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                Save
              </PrimaryButton>
            </FormItem>
          </Form>
        </Container>
      </Card>
    </Container>
  );
};

export default AddPoints;
