import React from "react";
import { Modal, Card } from "antd";
import { IoIosClose } from "react-icons/io";
import { StyledTitle } from "Components/Admin/ModuleSetting/Team/AddTeam";
import CommentBar from "ui/PostCommentBar/CommentBar";
import { useBeforeunload } from "react-beforeunload";

const EditComment = ({
  modalState,
  onCancel = console.log,
  onSubmit = console.log,
  commentData,
  actions = console.log,
  onSubmitUpdate = console.log,
}) => {
  const [isFormChange, setIsFormChange] = React.useState(false);

  const onSubmitUpdates = (
    id,
    data,
    cb,
    keepOpen = false,
    showMessage = true
  ) => {
    onSubmitUpdate(id, data, cb, !keepOpen, showMessage);
    setIsFormChange(false);
  };

  function fromChanges() {
    setIsFormChange(true);
  }
  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
    } else {
    }
  };

  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() =>
          !isFormChange
            ? onCancel(false)
            : openPrompt(
                "You will lose all changes if you navigate away from this page without saving"
              )
        }
        width={"960px"}
        footer={null}
        style={{ borderRadius: "5px" }}
        className="modal_wrapper"
        closeIcon={
          <IoIosClose
            className="modal_close_icon"
            // onClick={() => onCancel(false)}
          />
        }
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        {modalState ? <StyledTitle>Edit Comment</StyledTitle> : ""}
        <CommentBar
          onFormChange={fromChanges}
          onCancel={() => onCancel(false)}
          mb={52}
          commentData={commentData}
          onSubmit={onSubmitUpdates}
          isEdit={true}
        />
      </Modal>
    </>
  );
};

export default EditComment;
