import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Header, Sidebar } from ".";
import Content from "./Content";
import { useWindowSize } from "../../utils/hooks/useWindowSize";

const openSider = (e) => {
  e.preventDefault();
  let x = document.getElementsByClassName("openSider");
  document.body.classList.toggle("openSider");
};

const Layout = () => {
  const { width } = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    if (width < 992) {
      document.body.classList.add("openSider");
    }
  }, [width, location.pathname]);

  return (
    <div className="content-wrap">
      <div className="back-drop" onClick={openSider}></div>
      <Sidebar />
      <Header />
      <Content />
    </div>
  );
};

export default Layout;
