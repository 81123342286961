import { Button } from "antd";
import { CopySvg } from "assets/svgs";
import dayjs from "dayjs";
import { adminRoutes, settingsRoutes } from "helpers/adminRoutes";
import React, { useState,useEffect } from "react";
import { SecondaryButton } from "ui/Button";
import { SecondaryFloatButton } from "ui/Button/index";
import { Card } from "ui/Card";
import { Datatable } from "ui/Datatable";
import Actions from "ui/Datatable/Actions";
import { ToggleSwitch } from "ui/Form";
import { Heading } from "ui/Heading";
import { HeadingWithSubTitle } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { Flex } from "ui/Layout/Flex";
import { copyToClipboard, paginationObj, querystring, searchObj } from "utils/fn";
import { useHistory } from "react-router-dom";
import ApiConnectsAPI from "api/http/apiConnects";
import { useParams } from "react-router";

const API = () => {
  const params = useParams();
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {
     },
 
    sorter: {},
  });

  const generateSecretHandler = () => {
    history.push(settingsRoutes.ADD_API);
  };


  const handleDelete = (id, item) => {
    // ApiConnectsAPI.updateApiConnects(item, { id: item })
    //   .then(({ data }) => {
    //     ApiConnectsAPI.getApiConnectsList().then(({ data }) => {
    //       setData(data.data)
    //  })  .finally(() => {
    //    setLoading(false);
    //  });
    //   })
    //   .catch((error) => {
    //     if (error) {
    //       // message.error(error.message);
    //     }
    //   });
  };
  const actionOptions = [
   
    {
      label: "Edit",
      value: "edit"
     },
    //   {
    //   label: "Delete",
    //   value: "delete",
    //   isDanger: true,
    // },
  ];
  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(settingsRoutes.API_EDIT.replace(":id", item));
        break;
      case "delete":
        handleDelete(":id", item);

         break;

      default:
        break;
    }
  };



  useEffect(() => {
    setLoading(true)
    ApiConnectsAPI.getApiConnectsList().then(({ data }) => {
           
         setData(data.data)
    })  .finally(() => {
      setLoading(false);
    });
   },[])

   const handleStatus = (e, id) => {
    ApiConnectsAPI.updateApiConnects(id, { status: e })
      .then(({ data }) => {
        ApiConnectsAPI.getApiConnectsList().then(({ data }) => {
           
          setData(data.data)})
      })
      .catch((error) => {
        if (error) {
          // message.error(error.message);
        }
      });
  };

  const filterChangeHandler = ({ search, ...page }, filters, sorter) => {
    console.log("search",search)
    const filter = searchObj(["name"], search);
    setPagination((p) => ({ ...p, ...page, filter }));
    ApiConnectsAPI.getApiConnectsList(querystring(paginationObj({ ...pagination, ...page, filter }))).then(({ data }) => {
         setData(data.data)
    })  .finally(() => {
      setLoading(false);
    });
  };
  
  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <ToggleSwitch
            defaultChecked={_}
            onChange={(e) => handleStatus(e, record._id)}
          />
        );
      },
      fixed: "left",
      width: 70,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 150,
    },
    // {
    //   title: "Token",
    //   dataIndex: "token",
    //   key: "token",
    //   sorter: (a, b) => a.token.localeCompare(b.token),
    //   render: (_) => {
    //     return <TokenCell text={_} onCopy={() => copyToClipboard(_)} />;
    //   },
    // },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_) => {
        return dayjs(_).format("M/DD/YYYY");
      },
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
      fixed: "right",
    },
  ];
  return (
    <Container>
      <Heading>API</Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          search={true}
          loading={loading}
          showItemPerPage={true}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          onChange={filterChangeHandler}
           headerContentRight={
            <SecondaryFloatButton
              onClick={generateSecretHandler}
              buttonText="Connect API"
            />
          }
          pagination={false}
        />
      </Card>
    </Container>
  );
};

const TokenCell = ({ text, onCopy }) => {
  return (
    <Flex>
      {text}{" "}
      <Button onClick={onCopy} type="text" shape="circle" icon={<CopySvg />} />{" "}
    </Flex>
  );
};

export default API;
