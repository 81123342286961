import React, { useState, useCallback, useEffect } from "react";
import { Modal, Form, Spin } from "antd";
import { IoIosClose } from "react-icons/io";
import { Container } from "ui/Layout/Container";
import { Card } from "ui/Card";
import styled from "styled-components";
import { searchObj, querystring, paginationObj } from "utils/fn";
import UserAPI from "api/http/user";
import { message } from "antd";
import { FormSelectSearch, FormItem } from "ui/Form";
import { PrimaryButton } from "ui/Button";
import TeamAPI from "api/http/team";
import { teamStatus, userRole } from "utils/constants";
import { imagePath } from "utils/fn";
import { useBeforeunload } from "react-beforeunload";
import { useHistory } from "react-router-dom";

const AddTeamMembers = ({
  modalState,
  data,
  onCancel = console.log,
  onSubmit = console.log,
  from = "",
  _id = "",
  isEdit = false,
  teamId,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchUser, setsearchUser] = useState([]);
  const [invitedUsers, setInvitedUser] = useState([]);
  const [isFormChange, setIsFormChange] = useState(false);

  const onSubmitForm = () => {
    // onCancel(false);

    form.resetFields();
    onSubmit();

    if (from) {
      history.push(from);
    }
  };

  const createOptions = (data = []) => {
    return data.map((u) => ({
      avatar: u?.avatar
        ? { src: imagePath(u?.avatar?.path) }
        : { fullName: `${u?.firstName || ""} ${u?.lastName || ""}` },
      label: `${u.firstName} ${u.lastName}`,
      value: u._id,
    }));
  };

  useEffect(() => {
    setLoading(true);
    const defaultFilter = {
      team: teamId,
      $or: [
        {
          initiateByRole: userRole.admin,
          status: teamStatus.requested,
        },
        {
          status: teamStatus.joined,
        },
      ],
      $limit: 50,
      $select: ["user"],
    };
    if (teamId && modalState) {
      TeamAPI.getTeamMemberList(querystring(defaultFilter))
        .then((members) => {
          setInvitedUser(members.data.data.map((u) => u.user));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {};
  }, [teamId, modalState]);

  const getUserList = useCallback(
    ({ filter = {} }) => {
      const defaultFilter = {
        _id: {
          $nin: [...invitedUsers],
        },
        ...filter,
      };
      console.log({ invitedUsers });
      return UserAPI.getUserList(
        querystring(paginationObj({ filter: defaultFilter }))
      )
        .then(({ data }) => {
          setsearchUser(data.data);
          setUsers(createOptions(data.data));
          return createOptions(data.data);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
          return [];
        });
    },
    [invitedUsers]
  );
  const searchUsers = (search) => {
    const filter = searchObj(["firstName", "lastName"], search);
    return getUserList({ filter });
  };

  const handleSubmit = (team) => {
    setIsFormChange(false);
    let isError = false;
    (team?.users || []).forEach((user) => {
      TeamAPI.joinTeam({
        team: teamId,
        user: user,
        status: teamStatus.requested,
      })
        .then(() => {
          message.success("User invited successfully");
          onSubmitForm();
          setUsers([]);
          setsearchUser([]);
        })
        .catch((error) => {
          isError = true;
          console.log(error);
          getTeamMembers({
            filter: {
              team: teamId,
              user: user,
            },
          })
            .then((member) => {
              member.data.data.forEach((status) =>
                status.status === "Requested"
                  ? message.error("User already invited")
                  : // update team member
                    member.data.data.forEach((id) =>
                      TeamAPI.updateTeamMember(id._id, {
                        status: teamStatus.requested,
                        initiateByRole: userRole.admin,
                      })
                        .then(() => {
                          message.success("User invited successfully");
                          onSubmitForm();
                          setUsers([]);
                          setsearchUser([]);
                          isError = true;
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                    )
              );
            })
            .catch((err) => {
              console.log(err);
              isError = true;
            });
        });
    });
    if (!isError) {
      onCancel(false);
    }
  };

  const getTeamMembers = (filter = {}) => {
    return new Promise((resolve, reject) => {
      try {
        TeamAPI.getTeamMemberList(querystring(paginationObj(filter)))
          .then((data) => {
            resolve(data);
          })
          .catch((error) => {
            console.log(error);
            resolve();
          });
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  // function fromChanges() {
  //   setIsFormChange(true);
  // }
  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
      form.resetFields();
    } else {
    }
  };

  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          !isFormChange
            ? onCancel(false)
            : openPrompt(
                "You will lose all changes if you navigate away from this page without saving"
              );
        }}
        width={"960px"}
        footer={null}
        style={{ borderRadius: "5px" }}
        className="modal_wrapper"
        closeIcon={
          <IoIosClose
            className="modal_close_icon"
            // onClick={() => onCancel(false)}
          />
        }
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        {/* <Container minH="100%"> */}
        <Card>
          <StyledTitle>Invite Users</StyledTitle>
          {loading && <Spin size="large" />}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark={false}
            onValuesChange={() => {
              setIsFormChange(true);
            }}
          >
            <FormItem
              label="Users Name"
              name="users"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormSelectSearch
                placeholder="Select Users"
                mode="multiple"
                filterOption={false}
                defaultOptions={users}
                onSearch={searchUsers}
                searchUser={searchUser}
              />
            </FormItem>
            <FormItem>
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                {" "}
                Save
              </PrimaryButton>
            </FormItem>
          </Form>
        </Card>
        {/* </Container> */}
      </Modal>
    </>
  );
};

export default AddTeamMembers;

export const StyledTitle = styled.h3`
  font-weight: bold;
  font-size: 34px;
  line-height: 28px;
  color: #232333;
  text-align: center;
  /* margin-top: 39px;
  margin-bottom: 40px; */
  width: 100%;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
