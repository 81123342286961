import styled from "styled-components";
import { Modal } from "antd";
import { Col } from "react-bootstrap";

export const StyledModal = styled(Modal)`
  width: 100% !important;
  height: 100vh;
  top: 0px;

  .video-react {
    width: 100% !important;
    height: 100vh !important;
    margin: auto;
  }

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    padding: 0px;
  }

  .ril__navButtons {
    z-index: 1;
  }

  /* .ril__toolbar {
    top: -26px;
    left: 100px;
    right: 100px;
  } */
`;

export const VideoPlayerCol = styled(Col)`
  /* padding-left: 0px;
  padding-right: 3px;

  :last-child {
    padding-right: 0px;
  } */
`;

export const VideoPlayerWrapper = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;

  .video-react {
    width: 100% !important;

    height: 100% !important;

    /* height: 289.83px !important; */

    /* height: unset !important; */

    /* height: auto !important; */

    position: relative;
  }

  .video-react .video-react-video {
    position: unset;

    /* top: unset;
    left: unset;
    width: 100%;
    height: auto; */
  }

  .video-react-button {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  @media (max-width: 575px) {
    .video-react-button {
      height: 1.2em;
      width: 1.5em;
    }

    .video-react .video-react-big-play-button:before {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 24px;
    }
  }
`;
