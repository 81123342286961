import React, { useEffect } from "react";
import { Button, Form, Input, Checkbox, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import { adminRoutes } from "../helpers/adminRoutes";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { login, portal } from "store/reducers/user.reducer";
import { tabDatas } from "store/reducers/tab.reducer";
import PortalAPI from "api/http/portal";

const StyledFormItem = styled.div`
  .ant-form-item {
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 470px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
`;

const StyledRemeberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .ant-checkbox-inner {
    height: 28px;
    width: 28px;
    border-radius: 5px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
  }

  .ant-form-item input[type="checkbox"] {
    height: 28px;
    width: 28px;
    outline: none;
    border: transparent;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #e5e5e5;
  }

  .ant-checkbox-checked::after {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border: 1px solid #e5e5e5;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: ${(props) => props.theme.colors.primary};
    left: 36%;
  }

  .ant-checkbox-wrapper {
    font-family: "Mulish";
    font-size: 14px;
    line-height: 24px;
    color: #747487;
    letter-spacing: normal;
    font-weight: normal;
    display: flex;
    align-items: flex-end;
  }
`;

const StyledRegisterWrapper = styled.div`
  display: flex;

  span {
    font-size: 14px;
    line-height: 24px;
    color: #747487;
    word-spacing: 0.5px;
  }

  .register_link {
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
    margin-left: 4px;
  }
`;

const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }
  width: 100%;
  height: 48px;
  background: ${(props) => props.theme.colors.secondary};
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: white;
`;
const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 474px;
  max-width: 100%;
`;

const StyledForms = styled.div`
  padding-top: 32px;

  .ant-form-item {
    margin-bottom: 14px;
  }

  .ant-input-affix-wrapper {
    min-height: 48px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .ant-input {
    background-color: transparent;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }

  @media screen and (max-width: 991px) {
    padding-top: 0px;
  }
`;

const StyledFormsLabel = styled.div`
  width: max-content;
  line-height: 34px;
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 14px;
  border-bottom: 1px solid #000;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const StyledForgotPassword = styled.span`
  /* color: ${(props) => props.theme.colors.primary}; */
  text-decoration: underline;
  color: #232333;
  font-size: 14px;
  line-height: 24px;
`;

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { error, isLoggedIn, loading, user } = useSelector(
    (state) => state.user
  );

  const onFinish = (values) => {
    values.strategy = "local";
    values.email = values.email.toLowerCase();
    dispatch(login(values));
  };

  const getMenuLink = (key) => {
    switch (key) {
      case "dashboard":
        return adminRoutes.DASHBOARD;
      case "sources":
        return adminRoutes.SOURCE;
      case "relationships":
        return adminRoutes.RELATIONSHIP;
      case "find-a-team":
        return adminRoutes.TEAM;
      // case "create-a-team":
      //   return { onClick: () => setModalState(true) };
      case "watch-videos":
        return adminRoutes.WATCH_VIDEO;
      case "manage-videos":
        return adminRoutes.MANAGEVIDEO;
      case "go-live":
        return adminRoutes.DASHBOARD;
      case "my-event":
        return adminRoutes.MYEVENTS;
      case "find-an-event":
        return adminRoutes.FINDEVENTS;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (error) {
      message.error(error.message);
    } else if (isLoggedIn) {
      // message.success("Logged In successfully");

      if (user?.defaultPage) {
        history.push(user?.defaultPage);
      } else {
        PortalAPI.getPortalListDetail(localStorage.getItem("portalId"))
          .then((data) => {
            if (data?.data?.defaultPage) {
              let dta = getMenuLink(data?.data?.defaultPage);
              history.push(dta);
            } else {
              history.push(adminRoutes.DASHBOARD);
            }
          })
          .catch((err) => {
            console.log(err.message);
            // message.error(err.message);
          });
        // history.push(adminRoutes.DASHBOARD)
      }

      // message.success("Information saved successfully");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoggedIn]);

  return (
    <StyledForm>
      <StyledFormsLabel>Login</StyledFormsLabel>
      <StyledForms>
        <Form
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          requiredMark={"optional"}
          validateTrigger="onSubmit"
        >
          <StyledFormItem>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                { required: true, message: "This is required" },
              ]}
            >
              <Input placeholder="Enter your email address" />
            </Form.Item>
          </StyledFormItem>
          <Form.Item
            name="password"
            label="Password"
            className="password-w-icon"
            rules={[
              {
                required: true,
                message: "This is required",
              },
              {
                min: 8,
                message: "password should contain minimum 8 characters",
              },
            ]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <StyledRemeberWrapper>
            <Form.Item
              className="remember-check"
              name="remember"
              valuePropName="checked"
              initialValue=""
            >
              <Checkbox className="">Remember me</Checkbox>
            </Form.Item>
            <Form.Item>
              <Link className="text-white forget-txt" to={"forget_password"}>
                <StyledForgotPassword>Forgot Password</StyledForgotPassword>
              </Link>
            </Form.Item>
          </StyledRemeberWrapper>
          <Form.Item>
            <StyledButton htmlType="submit" loading={loading}>
              Log In
            </StyledButton>
          </Form.Item>
          <StyledRegisterWrapper>
            <span>New? Click here to</span>
            <Link className="register_link" to={"register"}>
              Register
            </Link>
          </StyledRegisterWrapper>
        </Form>
      </StyledForms>
    </StyledForm>
  );
};

export default Login;
