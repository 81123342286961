import { httpAPI } from "api";

const RelationshipTabAPI = {
  addRelationshipTab(params) {
    return httpAPI(`/relationshipTab`, params, "post");
  },
  getRelationshipTabList(query) {
    return httpAPI(`/relationshipTab?${query || ""}`, "", "get");
  },
  getRelationshipTabListDetail(id) {
    return httpAPI(`/relationshipTab/${id}`, "", "get");
  },
  updateRelationshipTab(id, params) {
    return httpAPI(`/relationshipTab/${id}`, params, "patch");
  },
  deleteRelationshipTab(id, params) {
    return httpAPI(`/relationshipTab/${id}`, params, "delete");
  },
};

export default RelationshipTabAPI;
