import styled from "styled-components";

export const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 822px;
  flex-wrap: nowrap;
  gap: 30px;
  margin-bottom: 20px;

  .ant-form-item-has-error {
    margin-bottom: 0px;
    margin-top: 10px;
  }
`;

export const AddNewField = styled.div`
  display: inline-block;
  margin-top: 16px;
  .ant-btn-circle {
    width: 48px;
    height: 48px;
    background: linear-gradient(
      276.7deg,
      ${(props) => props.theme.colors.primary} 0%,
      ${(props) => props.theme.colors.primary2} 100%
    );
    box-shadow: 0px 10px 15px ${(props) => props.theme.colors.primary}59;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }

  .plus_icon {
    font-size: 34px;
  }
`;

export const DeleteField = styled.div`
  display: inline-block;
  margin-top: 16px;
  .ant-btn-circle {
    width: 48px;
    height: 48px;
    /* background: linear-gradient(142.07deg, #fc3c3c 13.94%, #ad1b1b 86.39%); */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(142.07deg, #fc3c3c 13.94%, #ad1b1b 86.39%);
    box-shadow: 0px 10px 12px 0px #fc5b5b59;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
`;
