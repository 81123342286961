import { Form, message } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import { PrimaryButton } from "ui/Button";
import { FormInput, FormItem } from "ui/Form";
import { FileUploadAvtar } from "ui/Form/FileUploadAvtar";
import { FormInputPassword } from "ui/Form/Input";
import { Heading } from "ui/Heading/Heading";
import { Container } from "ui/Layout/Container";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";

import {
  StyledEditUserCard,
  StyledUploadAvtarWrapper,
  StyledFormItemWrapper,
  StyledUploadAvtarTextWrapper,
} from "./UserStyles";
import UserAPI from "../../../../api/http/user";
import { imagePath } from "utils/fn";
import { settingsRoutes } from "helpers/adminRoutes";
import { ToggleSwitch } from "ui/Form/index";

const InviteUser = () => {
  const [defaulState, setDefaultState] = useState(false);
  const [dataChange, setDataChange] = useState(false);
  const params = useParams();
  const history = useHistory();
  const [userDetails, setUserDetails] = useState("");
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [toggleData, setToggleData] = React.useState(false);

  const [state, setState] = useState({
    image: "",
    image_src: "",
  });

  const [form] = Form.useForm();

  const getUser = useCallback((id) => {
    UserAPI.getUser(id)
      .then(({ data }) => {
        form.setFieldsValue({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          memberNumber: data.memberNumber,
          "location.address": data?.location?.address,
        });
        setToggleData(data.emailVerified);
        setUserDetails(data);
        if (data.avatar) {
          setState((s) => ({ ...s, image_src: imagePath(data.avatar.path) }));
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.id) {
      getUser(params.id);
    }
  }, [getUser, params.id]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error("Image must smaller than 50 MB!");
      return false;
    }
    setDataChange(true);

    getBase64(file, (base64) =>
      setState((s) => ({
        ...s,
        image_src: base64,
        image: file,
      }))
    );
    return false;
  };

  const handleSubmit = (data) => {
    setShowPrompt(false);

    if (params.id) {
      setDataChange(false);
      UserAPI.updateUser(params.id, { ...data, avatar: state?.image })
        .then(() => {
          UserAPI.updateEmailVerification(params.id, {
            emailVerified: toggleData,
          });
          message.success("User updated successfully");
          history.push(settingsRoutes.USER);
        })
        .catch((error) => {
          if (error.message === "email: value already exists.") {
            let messages = "Email already exists";
            message.error(messages);
          } else {
            message.error(error.message);
          }
        });
    } else {
      data.createdBy = "admin";
      data.emailVerified = true;
      UserAPI.addUser({ ...data, avatar: state?.image })
        .then((data) => {
          UserAPI.updateEmailVerification(data._id, {
            emailVerified: toggleData,
          });

          message.success("User added successfully");
          history.push(settingsRoutes.USER);
        })
        .catch((error) => {
          console.log(error);
          if (error.message === "email: value already exists.") {
            let messages = "Email already exists";
            message.error(messages);
          } else {
            message.error(error.message);
          }
        });
    }
  };

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  const handleStatus = (values) => {
    setToggleData(values);
  };

  return (
    <Container>
      {/* {      <Beforeunload
                    onBeforeunload={() => "You will lose all changes if you navigate away from this page without saving"} />} */}
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Heading marginBottom={"85px"}>
        {params.id ? "Edit User Details" : "Add User"}
      </Heading>
      <StyledEditUserCard padding="25px 30px 15px">
        {/* <CardTitle containerStyle={{ padding: "10px 0 30px 0" }}>
          {params.id ? "Edit User Details" : "Invite User"}
        </CardTitle> */}
        <Form
          form={form}
          layout="vertical"
          onFinish={(value) => handleSubmit(value)}
          requiredMark={false}
          onValuesChange={() => {
            setShowPrompt(true);
          }}
        >
          <StyledUploadAvtarWrapper>
            <FileUploadAvtar
              uploadSize={"130px"}
              cameraIcon={"20px"}
              name="avatar"
              className="avatar-uploader"
              showUploadList={false}
              size={133}
              // size={126}
              beforeUpload={beforeUpload}
              userIcon={"78px"}
              offset={[-6, 100]}
              image_src={state?.image_src}
              username={`${userDetails?.firstName || ""} ${
                userDetails?.lastName || ""
              }`}
              badgeSize={"46px"}
            />
            {state?.firstName}
          </StyledUploadAvtarWrapper>
          {params.id && (
            <StyledUploadAvtarTextWrapper>
              <h5>
                {userDetails.firstName} {userDetails.lastName}
              </h5>
              <span>3 hour ago</span>
            </StyledUploadAvtarTextWrapper>
          )}
          <StyledFormItemWrapper id={params.id}>
            <FormItem
              label="First Name"
              name="firstName"
              type="text"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput
                onChange={() => {
                  setDataChange(true);
                }}
                placeholder="Enter first name"
              />
            </FormItem>
            <FormItem
              label="Last Name"
              name="lastName"
              type="text"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput
                onChange={() => {
                  setDataChange(true);
                }}
                placeholder="Enter last name"
              />
            </FormItem>
            <FormItem
              label="Email"
              name="email"
              type="text"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput
                onChange={() => {
                  setDataChange(true);
                }}
                placeholder="Enter your email address"
              />
            </FormItem>
            <FormItem
              label="Member Number"
              name="memberNumber"
              type="text"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <FormInput
                onChange={() => {
                  setDataChange(true);
                }}
                placeholder="Enter your member number"
              />
            </FormItem>
            <FormItem
              label="Location"
              name="location.address"
              type="text"
              rules={[]}
            >
              <FormInput
                onChange={() => {
                  setDataChange(true);
                }}
                placeholder="Enter location"
              />
            </FormItem>
            <FormItem label="Email Verified" name="emailVerified" type="text">
              <ToggleSwitch
                checked={toggleData}
                onChange={(e) => handleStatus(e)}
              />
            </FormItem>
            {/* <FormItem
              label="Password"
              name="password"
              type="text"
              rules={
                params.id
                  ? []
                  : [
                      {
                        required: true,
                        message: "This is required",
                      },
                      {
                        min: 8,
                        message: "password should contain minimum 8 characters",
                      },
                    ]
              }
            >
              <FormInputPassword placeholder="Enter your password"   onChange={() => {setDataChange(true)}}/>
            </FormItem>
            <FormItem
              label="Confirm Password"
              name="cpassword"
              dependencies={["password"]}
              // hasFeedback
              rules={
                params.id
                  ? []
                  : [
                      {
                        required: true,
                        message: "This is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]
              }
            >
              <FormInputPassword placeholder="Enter your password again"  onChange={() => {setDataChange(true)}} />
            </FormItem> */}
            <FormItem>
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                Save
              </PrimaryButton>
            </FormItem>
          </StyledFormItemWrapper>
        </Form>
      </StyledEditUserCard>
    </Container>
  );
};

export default InviteUser;
