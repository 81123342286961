import styled from "styled-components";
import { Card as uiCard } from "../../../../ui/Card";
import { Input, Card, Modal, Col } from "antd";
import { Link } from "react-router-dom";
import FileViewer from "react-file-viewer";

export const UserPostCard = styled(uiCard)`
  margin-bottom: 30px;

  @media (max-width: 991px) {
    padding: 15px;
  }
`;

export const PostInfoWrapper = styled.div`
  display: flex;
`;

export const UserImageWrapper = styled.div`
  display: flex;
  margin-right: 16px;
`;

export const StyledCol = styled(Col)`
  padding-left: 6px !important;
  padding-right: 6px !important;
`;

export const PostTextWrapper = styled.div`
  display: flex;
  flex-flow: column;

  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #232333;
    margin-bottom: 11px;
  }

  span {
    font-size: 14px;
    line-height: 14px;
    color: #747487;
    margin-bottom: 13px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #747487;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const PostTextMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: inline-block;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #747487;
    margin-bottom: ${({ mb }) => mb || "8"}px;
  }
`;

export const PostImageWrapper = styled.div`
  display: flex;
  /* background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top; */

  /* margin-bottom: 10px; */
  .height-one {
    padding-top: ${({ ho }) => ho || "35"}%;
    /* padding-top: 35%; */
  }
  .height-two {
    padding-top: 35%;
  }
  .height-three {
    padding-top: 25%;
  }
  .ant-image {
    width: 100%;
  }
  .slide {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
    max-height: 408px;
    object-fit: cover;
    border-radius: 10px;
  }
`;
export const PostAttachmentWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: max-content;

  svg {
    font-size: 18px;
    color: ${(props) => props.theme.colors.secondary};
    cursor: pointer;
    /* margin-right: 10px; */
  }
  p {
    cursor: pointer;
    margin: 0;
    font-size: 14px;
    color: #747487;
  }
`;

export const LinkCommentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0px;
  position: relative;
  margin-bottom: 10px;

  /* ::before {
    content: "";
    top: 0;
    background: #e5e5e5;
    border-radius: 10px;
    width: 100%;
    height: 1px;
    position: absolute;
  }

  ::after {
    content: "";
    bottom: 0;
    background: #e5e5e5;
    border-radius: 10px;
    width: 100%;
    height: 1px;
    position: absolute;
  } */
`;

export const LinkIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 20px;
  background: none;
  border: none;
  box-shadow: none;
  .ant-btn {
    color: ${(props) => props.theme.colors.secondary};
    border: none;
    box-shadow: none;
    margin-right: 10px;
    line-height: 30px;
    /* background: ${(props) => props.theme.colors.secondary}; */
    .like_icon {
      background: none;
      /* font-size: 20px;
    line-height: 23px; */
      /* color: ${(props) => props.theme.colors.secondary}; */
      /* background: ${(props) => props.theme.colors.secondary}; */
    }
  }
  .ant-btn:hover {
    color: ${(props) => props.theme.colors.secondary};
  }

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
  }
`;
export const CommentIconWrapper = styled.div`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  box-shadow: none;
  .ant-btn {
    color: ${(props) => props.theme.colors.secondary};
    border: none;
    box-shadow: none;
    pointer-events: none;
    margin-right: 10px;
    /* background: ${(props) => props.theme.colors.secondary}; */

    display: flex;
    align-items: center;
    justify-content: center;

    .comment_icon {
      font-size: 23px;
      line-height: 23px;
      /* color: #666666; */
      /* color: ${(props) => props.theme.colors.secondary}; */
    }
  }

  span {
    font-weight: 600;
    font-size: 18px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
  }
`;

export const StyledWriteCommentInput = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;

  .ant-upload-list {
    /* display: none; */
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: ${({ theme }) => theme.colors.primary} !important;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:hover {
    border-color: #f0f0f0;
  }
`;

export const InputWrapper = styled(Input)`
  border-radius: 10px;
  background: #f7f7f7;
  border: 1px solid #f0f0f0;
  min-height: 48px;
  margin-bottom: ${({ mb }) => mb || 0}px;
  input {
    background: #f6f7f9;
    font-size: 14px;
    line-height: 14px;
    color: #9aa2ab;
  }
`;

export const StyledPreviewLink = styled.div`
  .LowerContainer h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #232333 !important;
    margin-bottom: 11px;
  }
  .LowerContainer span {
    display: none;
  }
`;

export const StyledPreviewModal = styled(Modal)`
  width: auto !important;
  height: 100vh;
  top: 0;

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    text-align: center;
    /* height: 100%; */
    height: 100vh;
  }

  .ant-modal-body {
    /* height: 100%; */
    height: 100vh;
    padding: 0;
  }

  .ant-image {
    /* height: 100%; */
    height: 100vh;
  }

  .ant-image img {
    height: 100vh;
    object-fit: contain;
    /* height: 100%; */
  }

  .video-react {
    width: 100% !important;
    height: 100vh !important;
  }

  .audio_warpper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
`;

// export const StyledPreviewModal = styled(Modal)`
//   width: fit-content !important;
//   padding: 30px;

//   height: 100vh;
//   top: 0;

//   .ant-modal-body {
//     padding: 0px;
//   }

//   .ant-modal-content {
//     width: auto;
//     height: 100%;

//     display: flex;
//     align-items: center;

//     background-color: transparent;
//     box-shadow: none;

//     .video-react {
//       width: 400px !important;
//       max-height: 600px !important;
//     }

//     .video-react-video {
//       object-fit: cover;
//     }

//     .preview-content {
//       padding: 40px;
//       overflow: auto;
//       border: 2px solid black;

//       border: none !important;
//       padding: 0px;
//     }
//     .preview-bar-left {
//       display: none;
//     }
//     .preview-file img {
//       /* height:400px !important; */
//       width: 400px !important;
//     }

//     .fileIcon {
//       /* border: solid;
//       padding: 40px;
//       display: flex; */

//       color: white;
//       font-size: 20px;
//     }
//     .preview-icons {
//       display: none;
//     }
//   }
// `;

export const StyledFileViewerOther = styled(Modal)`
  max-width: 1000px;
  width: 100% !important;

  padding-bottom: 0px;

  .ant-modal-body {
    padding: 0px;
  }

  /* react-grid-Main */

  .react-grid-Grid {
    min-height: calc(100vh - 200px) !important;
  }

  /* width:fit-content !important; */

  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;

    .pg-viewer {
      border: none !important;
    }

    .pg-viewer-wrapper .pg-viewer .pg-driver-view {
      /* width: 300px;
      height: 500px; */
    }

    .pg-viewer-wrapper {
      /* overflow-x: hidden; */

      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      .react-grid-Container {
        width: auto !important;
      }
      .pg-viewer {
        text-align: -webkit-center;
        /* padding: 50px; */
        border: 2px solid black;
      }
    }

    /* Hide scrollbar for Chrome, Safari and Opera */
    .pg-viewer-wrapper::-webkit-scrollbar {
      display: none;
    }

    /* svg {
      position: absolute;
      right: 35px;
      bottom: 35px;
      font-size: 25px;
    } */
  }
`;

export const StyledModelWrapper = styled.div``;

export const StyledImagePreview = styled.div``;

export const StyledPdfViewWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const StyledPdfControlerWrapper = styled.div`
  position: fixed;
  bottom: 5%;

  .bg_style {
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    border-radius: 3px;
  }

  .page_details {
    color: white;
    margin: 0 10px;
  }

  .left_page {
    pointer-events: ${({ pageNumber }) =>
      pageNumber <= 1 ? "none" : "visible"};
    opacity: ${({ pageNumber }) => (pageNumber <= 1 ? "0.6" : "1")};
    cursor: pointer;

    color: white;
    font-size: 20px;
  }

  .right_page {
    pointer-events: ${({ pageNumber, numPages }) =>
      pageNumber >= numPages ? "none" : "visible"};
    opacity: ${({ pageNumber, numPages }) =>
      pageNumber >= numPages ? "0.6" : "1"};
    cursor: pointer;

    color: white;
    font-size: 20px;
  }
`;

export const StylePdfWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  background: red;
  z-index: 1111;
  height: 50px;

  svg {
    font-size: 20px;
    color: white;
    cursor: pointer;
  }
`;

export const StyledZipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h4 {
    margin-right: 10px;
  }

  h4,
  svg {
    font-size: 20px;
    color: white;
  }
`;

export const StyledModelHeader = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1111;
  height: 50px;
  padding-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  svg {
    font-size: 20px;
    color: white;
    cursor: pointer;
  }

  path {
    stroke: white;
  }

  .downloadsvg {
    margin-right: 22px;
  }
`;

export const CommentWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const CommentImageWrapper = styled.div`
  display: flex;
  margin-right: 15px;

  @media (max-width: 991px) {
    margin-right: 10px;
  }
`;

export const CommentTextCardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
  min-width: 200px;
`;

export const CommentTextCard = styled(Card)`
  background: #f7f7f7;
  border: 1px solid #f0f0f0;
  border-radius: 15px;

  .ant-card-head {
    border-bottom: none;
    padding: 0 14px;
    min-height: unset;
    min-width: 250px;
  }

  .ant-card-body {
    padding: 0px 14px;
  }

  .ant-card-head-title {
    padding: 5px 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #232333;
    word-break: break-word;
    white-space: normal;
    /* &:hover {
      text-decoration-line: underline;
      cursor: pointer;
    } */
  }

  .preview-file img {
    height: 200px;
    width: 200px;
  }

  .ant-card-body p {
    font-size: 14px;
    line-height: 18px;
    color: #747487;
  }
`;
export const CommentImageWrap = styled.div`
  margin-top: 5px;
  .grid-container {
    padding: 0;
  }
  .height-one {
    width: 80%;
    padding-top: 80%;
  }
  .slide {
    display: none;
  }
`;
export const StyledCommentLike = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4px 6px 4px 4px;
  width: 54px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  position: absolute;
  right: 0;
  bottom: 0px;

  .comment_like_icon {
    font-size: 14px;
    /* color: ${({ theme }) => theme.colors.secondary}; */
  }

  .ant-btn-circle {
    height: 22px;
    width: 22px;
    min-width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    background: ${(props) => props.theme.colors.secondary};
  }
`;

export const CommentLikeWrapper = styled.div`
  display: flex;

  .unlike_text {
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: #747487;
    margin-left: 5px;
    margin-right: 16px;
  }

  .like_text {
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.primary};
    margin-left: 5px;
    margin-right: 16px;
  }

  .reply_text {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: #747487;
    margin-right: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
  }

  .reply_text::before {
    position: absolute;
    content: "";
    left: -10px;
    width: 3px;
    height: 3px;
    background-color: #747487;
  }

  .reply_text::after {
    position: absolute;
    content: "";
    right: -10px;
    width: 3px;
    height: 3px;
    background-color: #747487;
  }

  .comment_at {
    font-size: 12px;
    line-height: 22px;
    color: #747487;
  }
`;

export const ViewMoreCommentWrapper = styled.div`
  display: flex;

  .ant-btn-link {
    padding: 0;
  }

  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #747487;
  }
`;

export const LinkTo = styled(Link)`
  font-size: 14px;
  line-height: 22px;
  color: #232333;
  word-break: break-word;
  white-space: normal;
  &:hover {
    color: #232333;
    text-decoration-line: underline !important;
  }
`;
