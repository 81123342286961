import styled from "styled-components";
import { EarthSvg } from "../../../assets/svgs";
import { Image, Input, Tabs, List } from "antd";
import { Card } from "../../../ui/Card";
import { Heading } from "../../../ui/Heading";
import { Flex } from "../../../ui/Layout/Flex";

export const GroupInfoCard = styled(Card)`
  padding: ${({ padding }) => padding && padding};
  margin-bottom: 30px;
`;

export const CardContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  p {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
    color: #747487;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
  }

  .read-or-hide {
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
  }
`;

export const CardImage = styled(Image)`
  width: 100%;
  height: 300px;
  object-fit: ${({ objectFit }) => objectFit || "cover"};
  border-radius: 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  @media (max-width: 1240px) {
    height: 265px;
  }
  @media (max-width: 991px) {
    height: 245px;
  }
`;

export const CardTextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0 5px;
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #747487;
  }
`;

export const StyledEarthIcon = styled(EarthSvg)`
  width: ${({ iconWidth }) => (iconWidth ? iconWidth : "18px")};
  height: ${({ iconHeight }) => (iconHeight ? iconHeight : "18px")};
  margin-right: 5px;

  @media (max-width: 1399px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    width: 10px;
    height: 10px;
  }

  @media (max-width: 575px) {
    width: 12px;
    height: 12px;
  }
`;

export const MemberInfoCard = styled(Card)`
  padding: ${({ padding }) => padding && padding};
`;

export const MemberText = styled.h4`
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  color: #232333;
  margin-bottom: 30px;
`;

export const MemberInput = styled(Input)`
  border: none;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0px;
  padding: 7px 11px 7px 0px;
  box-shadow: none;
  margin-bottom: 15px;

  .search_icon {
    font-size: 18px;
    color: #747487;
  }

  .ant-input-prefix {
    margin-right: 20px;
  }

  &:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #e5e5e5;
  }

  :focus {
    box-shadow: none;
    border-color: #e5e5e5;
  }
`;

export const StyledTabWrapper = styled.div`
  .ant-tabs-top > .ant-tabs-nav {
    margin: 0 0 27px 0;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: unset;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    padding: 0;
    background: #ffffff;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    // border-radius: 50px;
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #949494;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background: ${(props) => props.theme.colors.secondary} !important;
  }
`;

export const StyledListWrapper = styled(List)`
  .header_view_more_wrap {
    margin-top: 12px;
  }

  .header_view_more_wrap .ant-btn-link span {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const StyledListItem = styled(List.Item)`
  align-items: center;
  border-bottom: none !important;

  .ant-list-item-meta-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #232333;
  }

  .ant-list-item-meta-description {
    font-size: 14px;
    line-height: 22px;
    color: #747487;
  }

  .header_notification_option_button {
    font-size: 30px;
    line-height: 30px;
    color: #949494;
    cursor: pointer;
  }
  .ant-list-item-action {
    align-self: flex-start;
  }
`;

export const ActionIconWrapper = styled.div`
  display: flex;

  .check {
    cursor: pointer;
    margin-right: 20px;
    font-weight: 900;
    font-size: 22px;
    line-height: 22px;
    color: ${({ color }) => color || "#949494"};
  }

  .cancel {
    cursor: pointer;
    font-weight: 900;
    font-size: 22px;
    line-height: 22px;
    color: ${({ color }) => color || "#949494"};
  }

  .check:hover,
  .cancel:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const MobileTabWrapper = styled.div`
  display: none;
  ${({ isMobile }) => isMobile(false)}
  @media (max-width: 991px) {
    ${({ isMobile }) => isMobile(true)}
    display: block;

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: none;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      overflow: unset;
    }

    .ant-tabs-tab-btn {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #949494;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${(props) => props.theme.colors.primary};
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
      background: #ededed;
      border-radius: 10px 10px 0px 0px;
      border: none;
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
      background: transparent;
      border-bottom-color: transparent;
      border: none;
      border-radius: 10px 10px 0px 0px;
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 10px;
      border-radius: 10px 10px 0px 0px;
      border: none;
    }
  }
`;

export const HeadingWrapper = styled(Flex)`
  height: 100%;
  min-height: 63px;
`;

export const AddMemberButtonWrap = styled.div`
  justify-content: space-between;
  display: flex;
  button svg {
    font-size: 27px;
  }
`;
