import React from "react";
import { GroupInfo, MemberInfo } from "./RightCards";

export const TeamRight = ({ data, reloadTeam }) => {
  return (
    <>
      <GroupInfo data={data} />
      <MemberInfo data={data} reloadTeam={reloadTeam} />
    </>
  );
};
