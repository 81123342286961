import styled from "styled-components";
export const FloatButton = styled.div`
  display: none;

  .plus_icon {
    font-size: 34px;
  }

  @media (max-width: 991.98px) {
    display: block;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }
`;

export const StyledTabButtonWraps = styled.div`
  display: inline-block;
  width: 100%;

  .ant-tabs {
    overflow: unset;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 27px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .ant-tabs-nav-wrap {
    padding: 0 30px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    // max-width: 1158px;
    width: 100%;
    height: 88px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 7%);
    border-radius: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    @media (max-width: 767px) {
      height: 36px;
      max-width: 340px;
      min-width: 264px;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 46px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: unset;
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #949494;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-tabs-ink-bar {
    height: 2px;
    background: ${(props) => props.theme.colors.primary} !important;
    border-radius: 50px;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      max-width: 362px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 24px;
    }

    .ant-tabs-tab-btn {
      font-size: 14px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 14px;
    }
  }

  @media (max-width: 991.98px) {
    .ant-tabs-top > .ant-tabs-nav {
      display: flex;
      // flex-flow: column;
      // justify-content: center;
      // align-items: flex-end;
      margin-bottom: 80px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      justify-content: space-evenly;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      // margin-bottom: 35px;

      width: 100%;
      // justify-content: center;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 14px;
    }
  }

  @media (max-width: 767px) {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }

    .ant-tabs-tab-btn {
      font-weight: normal;
      font-size: 12px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: normal;
      font-size: 12px;
    }

    .ant-tabs-top > .ant-tabs-nav {
      padding: 0 15px;
    }
  }
  @media (max-width: 451px) {
    .ant-tabs-top > .ant-tabs-nav {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const AddNewRelations = styled.div`
  //   display: inline-block;
  margin-bottom: 30px;
  margin-right: 14px;
  display: flex;
  align-items: center;
  .plus_icon {
    font-size: 34px;
  }

  .ant-btn-circle {
    margin-right: 20px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 700;
    color: #232333;
  }

  @media (max-width: 991.98px) {
    display: none;
  }
`;
