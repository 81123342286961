import React, { useEffect, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { FileUploadAvtar } from "../ui/Form/FileUploadAvtar";
import { adminRoutes, authRoutes } from "helpers/adminRoutes";
import { useDispatch, useSelector } from "react-redux";
import { register } from "store/reducers/user.reducer";
import PortalAPI from "api/http/portal";

const StyledForgotPassword = styled.ul`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
`;

const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }
  margin-top: 30px;
  width: 100%;
  height: 48px;
  background: ${(props) => props.theme.colors.secondary};
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  line-height: 18px;
  @media screen and (max-width: 991px) {
    margin-top: 26px;
  }
`;
const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 474px;
  max-width: 100%;
`;

const StyledForms = styled.div`
  // padding-top: 32px;
  margin-top: -50px;
  @media screen and (max-width: 991px) {
    margin-top: 16px;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }

  .ant-input-affix-wrapper {
    min-height: 48px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 0 11px;
  }
  .ant-input {
    background-color: transparent;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }
`;
const StyledFormsLabel = styled.div`
  width: max-content;
  line-height: 34px;
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 14px;
  border-bottom: 1px solid #000;
  font-family: Mulish;
  font-style: normal;
  font-size: 34px;
  line-height: 28px;
  color: #232333;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;

const StyledLink = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #747487;
  font-size: 14px;
  color: #747487;
  margin-left: 4px;
  font-weight: 600;
`;

const StyledFormFirstLastNameItem = styled.div`
  display: flex;
  flex-direction: row;

  gap: 20px;

  @media screen and (max-width: 595px) {
    /* flex-direction: column; */
  }
`;

const StyledFirstNameFormItem = styled.div`
  margin-right: 30px;
  width: 100%;
  @media screen and (max-width: 595px) {
    margin-right: 0px;
  }
  .ant-form-item {
    display: flex;
    flex-direction: column;
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 220px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        max-width: unset;
        width: 100%;
      }
    }
  }
`;

const StyledLastNameFormItem = styled.div`
  width: 100%;
  .ant-form-item {
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 220px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        max-width: unset;
        width: 100%;
      }
    }
  }
`;

const StyledFormItem = styled.div`
  .ant-form-item {
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 470px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
`;

const StyledFormUploadItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 991px) {
    justify-content: center;
  }
`;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

const getMenuLink = (key) => {
  switch (key) {
    case "dashboard":
      return adminRoutes.DASHBOARD;
    case "sources":
      return adminRoutes.SOURCE;
    case "relationships":
      return adminRoutes.RELATIONSHIP;
    case "find-a-team":
      return adminRoutes.TEAM;
    // case "create-a-team":
    //   return { onClick: () => setModalState(true) };
    case "watch-videos":
      return adminRoutes.WATCH_VIDEO;
    case "manage-videos":
      return adminRoutes.MANAGEVIDEO;
    case "go-live":
      return adminRoutes.DASHBOARD;
    case "my-event":
      return adminRoutes.MYEVENTS;
    case "find-an-event":
      return adminRoutes.FINDEVENTS;
    default:
      return null;
  }
};

const Registration = () => {
  const history = useHistory();
  const [state, setState] = useState({
    image: "",
    image_src: "",
  });
  const dispatch = useDispatch();
  const { error, isLoggedIn, loading, emailVerification } = useSelector(
    (state) => state.user
  );
  const { configData } = useSelector((state) => state.configData);
  const onFinish = (values) => {
    values.email = values.email.toLowerCase();
    if (values.memberNumber) {
      console.log("values.memberNumber", values.memberNumber);
    } else {
      delete values.memberNumber;
    }
    console.log(values);
    const formData = {
      ...values,
      avatar: state.image,
      emailVerified: emailVerification === true ? false : true,
    };

    const formdata = new FormData();
    Object.keys(formData).map((k) => formdata.append(k, formData[k]));

    dispatch(register(formdata)).then((data) => {
      if (emailVerification === true) {
        message.success(
          "User created successfully. Please go confirm your email before logging in."
        );
        history.push(authRoutes.LOGIN);
      } else {
        console.log("data?.payload?.accessToken", data?.payload?.accessToken);
        localStorage.setItem("token", data?.payload?.accessToken);
        localStorage.setItem("userId", data?.payload?.user?._id);
        PortalAPI.getPortalListDetail(localStorage.getItem("portalId"))
          .then((data) => {
            if (data?.data?.defaultPage) {
              let dta = getMenuLink(data?.data?.defaultPage);
              history.push(dta);
            } else {
              history.push(adminRoutes.DASHBOARD);
            }
          })
          .catch((err) => {
            console.log(err.message);
            // message.error(err.message);
          });
      }
    });
  };

  // useEffect(() => {
  //   if (error) {
  //     message.error(error.message);
  //   } else if (isLoggedIn) {
  //     // message.success("Logged In successfully");
  //     history.push(authRoutes.LOGIN)    }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [error, isLoggedIn]);

  const beforeUpload = (file) => {
    const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error("Image must smaller than 50 MB!");
      return false;
    }
    getBase64(file, (base64) =>
      setState((s) => ({
        ...s,
        image_src: base64,
        image: file,
      }))
    );
    return false;
  };

  return (
    <StyledForm>
      <StyledFormsLabel>Join</StyledFormsLabel>
      <StyledForms>
        <Form
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          requiredMark={"optional"}
        >
          <StyledFormUploadItem>
            <FileUploadAvtar
              uploadSize={"64px"}
              cameraIcon={"10px"}
              name="avatar"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              size={64}
              userIcon={"36px"}
              offset={[-6, 52]}
              image_src={state?.image_src}
              badgeSize={"24px"}
            />
          </StyledFormUploadItem>

          <StyledFormFirstLastNameItem>
            <StyledFirstNameFormItem>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: "This is required" }]}
              >
                <Input placeholder="Enter first name" />
              </Form.Item>
            </StyledFirstNameFormItem>
            <StyledLastNameFormItem>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: "This is required" }]}
              >
                <Input placeholder="Enter last name" />
              </Form.Item>
            </StyledLastNameFormItem>
          </StyledFormFirstLastNameItem>
          <StyledFormItem>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your email address" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem>
            <Form.Item
              name="memberNumber"
              label="Member Number"
              // rules={[
              //     { required: true, message: "This is required" },
              // ]}
            >
              <Input placeholder="Enter member number" />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem>
            <Form.Item
              name="password"
              label="Password"
              className="password-w-icon"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
                {
                  min: 8,
                  message: "password should contain minimum 8 characters",
                },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </StyledFormItem>

          <StyledFormItem>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              // hasFeedback
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </StyledFormItem>
          <Form.Item>
            <StyledButton htmlType="submit" loading={loading}>
              Register
            </StyledButton>
          </Form.Item>
          <Form.Item>
            <StyledLink>
              Already have an account?
              {"   "}{" "}
              <Link className="" to={"login"}>
                <StyledForgotPassword>Login</StyledForgotPassword>
              </Link>
            </StyledLink>
          </Form.Item>
        </Form>
      </StyledForms>
    </StyledForm>
  );
};

export default Registration;
