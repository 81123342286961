import React, { useCallback, useEffect, useState } from "react";
import { SecondaryButton } from "../../../../ui/Button";
import { Card } from "../../../../ui/Card";
import { Datatable } from "../../../../ui/Datatable";
import { Heading } from "../../../../ui/Heading";
import { Container } from "../../../../ui/Layout/Container";
import Actions from "../../../../ui/Datatable/Actions";
import { ToggleSwitch } from "../../../../ui/Form";
import { settingsRoutes } from "../../../../helpers/adminRoutes";
import { useHistory } from "react-router-dom";
import TypeAPI from "api/http/type";
import { message } from "antd";
import { paginationObj, querystring, searchObj } from "utils/fn";
import { SecondaryFloatButton } from "ui/Button/index";

const Events = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {},
    sorter: {},
  });

  const getTypeList = useCallback((pagination) => {
    setLoading(true);
    TypeAPI.getTypeList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData(data?.data);
        setPagination((p) => ({ ...p, total: data?.total }));
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getTypeList(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTypeList]);

  const handleClick = () => {
    history.push(settingsRoutes.TYPE_ADD);
  };

  const updateStatus = (typeId, status) => {
    TypeAPI.updateType(typeId, { status })
      .then(() => {
        getTypeList(pagination);
        message.success("Type updated successfully");
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const handleDelete = (id) => {
    TypeAPI.deleteType(id, { id })
      .then(() => {
        getTypeList(pagination);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <ToggleSwitch
            checked={_}
            onChange={(value) => updateStatus(record._id, value)}
          />
        );
      },
      fixed: "left",
      width: 70,
    },
    {
      title: "Type Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(settingsRoutes.TYPE_EDIT.replace(":id", item));
        break;
      case "delete":
        handleDelete(item);
        break;

      default:
        break;
    }
  };

  const filterChangeHandler = ({ search, ...page }, filters, sorter) => {
    const filter = searchObj(["name"], search);
    setPagination((p) => ({ ...p, ...page, filter, sorter }));
    getTypeList({ ...pagination, ...page, filter, sorter });
  };

  return (
    <Container>
      <Heading>Type</Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          loading={loading}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <SecondaryFloatButton
              onClick={handleClick}
              buttonText="Create Type"
            />
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default Events;
