import { httpAPI } from "api";

const RelationshipAPI = {
  addRelationship(params) {
    return httpAPI(`/relationship`, params, "post");
  },

  getRelationshipList(query) {
    return httpAPI(`/relationship?${query || ""}`, "", "get");
  },
  getRelationshipListDetail(id) {
    return httpAPI(`/relationship/${id}`, "", "get");
  },
  updateRelationship(id, params) {
    return httpAPI(`/relationship/${id}`, params, "patch");
  },
  deleteRelationship(id, params) {
    return httpAPI(`/relationship/${id}`, params, "delete");
  },
};

export default RelationshipAPI;
