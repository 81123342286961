import styled from "styled-components";
import { Tag, Badge } from "antd";
import { Card } from "../../ui/Card";
import { PrimaryButton, SecondaryButton } from "../../ui/Button";

export const StyledUploadWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledProfileEditWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledProfileSmallCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  a {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-decoration-line: underline;
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const StyledCardWrapper = styled.div`
  margin-bottom: 30px;
`;

export const StyledVideoCardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;
`;

export const StyledVideoCard = styled(Card)`
  display: flex;
  flex-flow: column;
  padding: 0;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  cursor: pointer;
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  height: 100%;
  margin-bottom: 38px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-image {
    display: inline-block;
    width: 100%;
  }
  .ant-image-img {
    border-radius: 10px 10px 0px 0px;
  }
`;

export const StyledVideoIconWrapper = styled.div`
  position: absolute;
  svg {
    font-size: 48px;
    color: white;
  }
`;

export const StyledVideoProgressWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  /* bottom: -28px; */
  bottom: -28px;
`;

export const StyledVideoContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0px 15px 15px 15px;

  h3 {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #232333;
    min-height: ${({ mh }) => mh || 40}px;
    margin-bottom: 10px;
  }
  h4 {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    margin-bottom: 13px;
  }
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    min-height: 14px;
  }
`;

export const StledCardInputWrapper = styled.div`
  margin-bottom: 80px;
`;

export const StyledTags = styled(Tag)`
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  align-items: center;
`;

export const StyledButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

export const StyledWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 34.77px;

  .ant-avatar {
    margin-bottom: 15px;
    border: 1px solid ${(props) => props.theme.colors.primary};
  }
`;

// START USER PROFILE DETAILS

export const StyledEventWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 303px;
`;

export const StyledUserProfileCard = styled(Card)`
  position: relative;
  margin-bottom: 30px;
`;

export const StyledUserInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 170px;

  @media (max-width: 575px) {
    margin-left: 0;
    margin-top: 60px;
  }
`;

export const EditProfileButton = styled(PrimaryButton)`
  @media (max-width: 991.98px) {
    min-width: 80px !important;
    height: 36px !important;
    margin-left: 0;
  }
`;

export const StyledUserInfo = styled.div`
  display: flex;

  h5 {
    font-weight: bold;
    font-size: 18px;
    color: #232333;
    margin-bottom: 0px;
  }
`;

export const StyledUserDetailsWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const JoinMyTeamUserButton = styled(SecondaryButton)`
  margin-bottom: 30px;

  @media (max-width: 991.98px) {
    min-width: 80px !important;
    height: 36px !important;
    margin-left: 0;
  }
`;

export const StyledUserDetails = styled.div`
  display: inline-block;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0px;
  }
  h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #232333;
  }

  span {
    font-size: 16px;
    line-height: 24px;
    color: #747487;
  }
`;

export const StledEventCardWrapper = styled.div`
  display: flex;
`;

export const StyledEditUserCard = styled(Card)`
  padding: 25px 30px 15px;
  position: relative;
`;

export const StyledUploadAvtarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: -70px;
  color: ${({ theme }) => theme.colors.primary};
  font-size: "calc(${({ size }) => size}px / 2.3)";
  .ant-avatar {
    /* background: #f6f7f9; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* outline: 2px solid ${(props) => props.theme.colors.primary};
    outline-offset: -1px; */

    /* border: 2px solid ${(props) => props.theme.colors.primary}; */
    /* iOS fix */
    /* background-color: ${(props) => props.theme.colors.primary}; */
    background-color: #cccccc;

    .ant-avatar-string {
      z-index: 1;
    }

    &:after {
      position: absolute;
      content: "";
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background-color: #ffffff;
      border-radius: 50%;
      z-index: 0;
    }
    /* // iOS fix */

    > img {
      border-radius: 50%;
      padding: 2px; // iOS Fix
      z-index: 1; // iOS Fix
    }
  }

  @media (max-width: 575px) {
    justify-content: center;
    left: 0;
    right: 0;
  }
`;

export const StyledUploadEditAvtarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: -70px;

  .ant-avatar {
    /* background: #f6f7f9; */
    display: flex;
    align-items: center;
    justify-content: center;

    /* iOS fix */
    /* background-color: ${(props) => props.theme.colors.primary}; */
    background-color: #cccccc;

    /* .ant-avatar-string {
      z-index: 1;
      font-size: calc(${({ size }) => size || 32}px / 2.3);
    } */

    &:after {
      position: absolute;
      content: "";
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background-color: #ffffff;
      border-radius: 50%;
      z-index: 0;
    }
    /* // iOS fix */

    > img {
      border-radius: 50%;
      padding: 2px; // iOS Fix
      z-index: 1; // iOS Fix
    }
  }

  @media (max-width: 575px) {
    justify-content: center;
    left: 0;
    right: 0;
  }
`;

export const StyledFormItemWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: ${(props) => (props.id ? "65px" : "65px")};
`;

export const StyledUploadAvtarTextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 170px;
  margin-bottom: 30px;
  h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #232333;
  }
  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
  }

  @media (max-width: 575px) {
    margin-left: 0;
    margin-top: 60px;
  }
`;

export const StyledActionButton = styled(PrimaryButton)`
  margin-left: auto;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 991.98px) {
    min-width: 80px !important;
    height: 36px !important;
    margin-left: 0;
  }
`;

export const ProfileMobileTabWrapper = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: none;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      overflow: unset;
    }

    .ant-tabs-tab-btn {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: #949494;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${(props) => props.theme.colors.primary};
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
      background: #ededed;
      border-radius: 10px 10px 0px 0px;
      border: none;
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
      background: transparent;
      border-bottom-color: transparent;
      border: none;
      border-radius: 10px 10px 0px 0px;
    }

    .ant-tabs-top > .ant-tabs-nav {
      margin-bottom: 0;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
      margin-left: 10px;
      border-radius: 10px 10px 0px 0px;
      border: none;
    }
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  button {
    margin-right: 15px;
  }
  button svg {
    font-size: 34px;
  }
  @media (max-width: 991px) {
    display: none;
  }
`;


export const StyledBadge = styled(Badge)`
  .badge_style {
    width: ${({ badgeSize }) => (badgeSize ? badgeSize : "20px")};
    height: ${({ badgeSize }) => (badgeSize ? badgeSize : "20px")};
    background: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:110px;
    margin-left:10px;
    z-index: 2; // iOS Fix
  }
`;