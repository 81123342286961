import styled from "styled-components";

export const Container = styled.div`
  min-height: ${({ minH }) => minH || "calc(100vh - 122px)"};
  padding: ${({ padding }) => padding || "17px 30px 30px"};
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.colors.third};

  @media (max-width: 991.98px) {
    padding: ${({ paddings }) => paddings || "15px"};
  }
`;
