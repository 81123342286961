import { Form, Button } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router";
import { PrimaryButton } from "ui/Button";
import { Card } from "ui/Card";
import { CardTitle } from "ui/Card/Card";
import { FormInput, FormItem } from "ui/Form";
import { Container } from "ui/Layout/Container";
import { HiPlus } from "react-icons/hi";
import { AddNewField, StyledInputWrapper, DeleteField } from "./style";
import { DeleteSvg } from "../../../../assets/svgs";
import Icon from "@ant-design/icons";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";

const DeleteIcon = (props) => {
  return <Icon size={10} component={DeleteSvg} {...props} />;
};
const AddReward = () => {
  const params = useParams();
  const [defaulState, setDefaultState] = useState(false);
  const [dataChange, setDataChange] = useState(false);
  const [form] = Form.useForm();
  const [showPrompt, setShowPrompt] = React.useState(false);

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });
  return (
    <Container>
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Card padding="25px 30px 15px">
        <CardTitle>{params.id ? "Edit Rewards" : "Rewards"}</CardTitle>
        <Container bgColor="white">
          <Form
            form={form}
            layout="vertical"
            onFinish={(v) => {
              setShowPrompt(false);
              console.log(v, "v");
            }}
            requiredMark={false}
            initialValues={{
              rewards: [{ name: "", point: 0 }],
            }}
            onValuesChange={() => {
              setShowPrompt(true);
            }}
          >
            <Form.List name="rewards">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }, idx) => (
                    <StyledInputWrapper key={key}>
                      <FormItem
                        {...restField}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "This is required",
                          },
                        ]}
                        maxWidth={"420px"}
                      >
                        <FormInput type="text" placeholder="Enter name" />
                      </FormItem>

                      <FormItem
                        label="Point"
                        {...restField}
                        name={[name, "point"]}
                        fieldKey={[fieldKey, "point"]}
                        rules={[
                          {
                            required: true,
                            message: "This is required",
                          },
                        ]}
                        maxWidth={"238px"}
                      >
                        <FormInput type="number" placeholder="Enter point" />
                      </FormItem>

                      {idx === 0 ? (
                        <AddNewField>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<HiPlus className="plus_icon" />}
                            onClick={() => add()}
                          />
                        </AddNewField>
                      ) : (
                        <DeleteField>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<DeleteIcon />}
                            onClick={() => remove(name)}
                          />
                        </DeleteField>
                      )}
                    </StyledInputWrapper>
                  ))}
                </>
              )}
            </Form.List>
            <FormItem>
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                Save
              </PrimaryButton>
            </FormItem>
          </Form>
        </Container>
      </Card>
    </Container>
  );
};

export default AddReward;
