import React, { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import { SecondaryButton } from "ui/Button";
import { Card } from "ui/Card";
import { Datatable, UserAvatarAndFullName } from "ui/Datatable";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import Actions from "ui/Datatable/Actions";
import { ToggleSwitch } from "ui/Form";
import { useHistory } from "react-router-dom";
import { settingsRoutes } from "helpers/adminRoutes";
import TeamAPI from "api/http/team";
import {
  getDynamicMenuString,
  imagePath,
  paginationObj,
  querystring,
  searchObj,
} from "utils/fn";
import { DescriptionLongText } from "ui/Datatable/DescriptionLongText";
import { teamStatus } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { me } from "store/reducers/user.reducer";
import { SecondaryFloatButton } from "ui/Button/index";

const Team = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const { configData } = useSelector((state) => state.configData);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {},
    sorter: {},
    populate: [
      { path: "admins", select: ["firstName", "lastName", "avatar"] },
      { path: "memberCount", match: { status: teamStatus.joined } },
    ],
  });

  const getTeams = useCallback((pagination) => {
    setLoading(true);
    TeamAPI.getTeamList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData(data && data.data);
        setPagination((p) => ({ ...p, total: data.total }));
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getTeams(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeams]);

  const handleDelete = (id) => {
    TeamAPI.deleteTeam(id, { id })
      .then(() => {
        getTeams(pagination);
        dispatch(me());
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const handleClick = () => {
    history.push(settingsRoutes.TEAM_ADD);
  };

  const handleStatus = (e, id) => {
    TeamAPI.updateTeamAdmin(id, { status: e })
      .then(({ data }) => {})
      .catch((error) => {
        if (error) {
          // message.error(error.message);
        }
      });
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e, record) => {
        return (
          <ToggleSwitch
            key={record._id}
            defaultChecked={e}
            onChange={(e) => handleStatus(e, record._id)}
          />
        );
      },
      fixed: "left",
      width: 70,
    },
    {
      title: "Community",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    },
    {
      title: "Name",
      dataIndex: "admins",
      key: "admins",
      sorter: true,
      render: (_, record) => {
        return (
          <UserAvatarAndFullName
            name={`${record?.admins[0]?.firstName || ""} ${
              record?.admins[0]?.lastName || ""
            }`}
            image={
              record?.admins[0]?.avatar?.path
                ? imagePath(record?.admins[0]?.avatar?.path)
                : ""
            }
          />
        );
      },
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) =>
        a.description.toLowerCase().localeCompare(b.description.toLowerCase()),
      render: (_) => {
        return <DescriptionLongText>{_}</DescriptionLongText>;
      },
    },
    {
      title: "Members",
      dataIndex: "memberCount",
      key: "memberCount",
      sorter: (a, b) => a.memberCount - b.memberCount,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  const actionHandler = ({ key, item }) => {
    console.log(key, "key", item);
    switch (key) {
      case "edit":
        history.push(settingsRoutes.TEAM_EDIT.replace(":id", item), {
          from: settingsRoutes.TEAM,
          id: item,
        });
        break;
      case "delete":
        handleDelete(item);
        break;

      default:
        break;
    }
  };

  const filterChangeHandler = ({ search, ...page }, filters, sorter) => {
    const filter = searchObj(["name"], search);
    setPagination((p) => ({ ...p, ...page, filter, sorter }));
    getTeams({ ...pagination, ...page, filter, sorter });
  };

  return (
    <Container>
      <Heading>
        {getDynamicMenuString(configData?.[0], ["connect", "team"])}
      </Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          loading={loading}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <SecondaryFloatButton
              onClick={handleClick}
              buttonText={
                "Create" +
                getDynamicMenuString(configData?.[0], ["connect", "team"])
              }
            />
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default Team;
