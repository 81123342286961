import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Input, message } from "antd";
import { Row, Col } from "antd";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";

import {
  StyledContentTitle,
  StyledFormControl,
  StyledFormItem,
  StyledButton,
} from "./TheRelationship.styles";
import { PrimaryButton } from "../../ui/Button/Button";
import "./style.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { adminRoutes } from "../../helpers/adminRoutes";
import UserAPI from "api/http/user";
import RelationshipAPI from "api/http/relationship";
import RelationshipContent from "./RelationshipContent";
import RelationshipTabAPI from "api/http/relationshipTab";
import { set } from "lodash";

const AddRelationshipModal = ({ onClick, modalState, ...props }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [visible, setVisible] = useState(modalState);
  const [addEdit, setAddEdit] = useState(false);
  const [tab, settab] = useState({ key: props.tabkey });
  const { user } = useSelector((state) => state.user);
  const [tabData, setTabData] = useState(props.tabDatas);
  const [dataChange, setDataChange] = useState(false);
  const { configData } = useSelector((state) => state.configData);

  useEffect(() => {
    setTabData(props.tabDatas || []);
    return () => {};
  }, [props.tabDatas]);

  useEffect(() => {
    if (props.tabkey !== undefined) {
      settab({ key: props.tabkey });
    }
  }, [props.tabkey]);

  const onFinish = (values) => {
    setDataChange(false);
    if (props.edit === true) {
      RelationshipAPI.updateRelationship(props.id._id, values)
        .then(({ data }) => {
          setAddEdit(true);
          setVisible(false);
          onClick(false);
          form.resetFields();
        })
        .catch((error) => {
          if (error) {
            message.error(error.message);
          }
        });
    } else {
      if (tab.key === props.tabFirstId) {
        values.claimed = false;
      } else {
        values.claimed = true;
        values.members = [user._id];
      }
      values.claimedBy = user._id;
      values.type = tab.key;
      values.contacted = false;
      values.activities = {
        text: `came in at`,
        name: `${values.firstName ? values.firstName : ""}`,
      };
      RelationshipAPI.addRelationship(values)
        .then(({ data }) => {
          setVisible(false);
          onClick(false);
          setAddEdit(true);
          form.resetFields();
        })
        .catch((error) => {
          if (error) {
            message.error(error.message);
          }
        });
    }
  };

  const getRelationshipTabList = useCallback(() => {
    RelationshipTabAPI.getRelationshipTabList()
      .then(({ data }) => {
        function compare(a, b) {
          const bandA = b.sequence;
          const bandB = a.sequence;

          let comparison = 0;
          if (bandB > bandA) {
            comparison = 1;
          } else if (bandB < bandA) {
            comparison = -1;
          }
          return comparison;
        }

        data.sort(compare);
        setTabData(data);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  }, []);

  useEffect(() => {
    // getRelationshipTabList();
  }, [getRelationshipTabList]);

  if (props.edit === true) {
    form.setFieldsValue({
      firstName: props.id && props.id.firstName,
      lastName: props.id && props.id && props.id.lastName,
      email: props.id && props.id.email,
      phoneNumber: props.id && props.id.phoneNumber,
      "location.address":
        props.id && props.id.location ? props.id.location.address : "",
    });
  }

  const openPrompt = (message) => {
    var answer = window.confirm("inside yes");
    if (answer == true) {
      console.log("inside yes");
      onClick(false);
    } else {
    }
  };

  return (
    <>
      {setVisible !== false && (
        <Modal
          visible={modalState}
          onCancel={
            () => (!dataChange && modalState ? onClick(false) : onClick(false))
            //  openPrompt(
            //     "You will lose all changes if you navigate away from this page without saving"
            //   )
          }
          width={"960px"}
          footer={null}
          // onOk={form.submit}
          style={{ borderRadius: "5px" }}
          className="modal_wrapper"
          closeIcon={<IoIosClose className="modal_close_icon" />}
          maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
          focusTriggerAfterClose={false}
        >
          <StyledContentTitle>
            Add New {configData?.[0].module?.[1]?.children?.[1]?.name}
          </StyledContentTitle>
          <StyledFormControl>
            <Row gutter={30}>
              <Form
                requiredMark={"optional"}
                form={form}
                layout="vertical"
                onFinish={(e) => onFinish(e)}
              >
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <StyledFormItem>
                    <Form.Item
                      className="custom-textbox"
                      name="firstName"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "This is required",
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Enter first name"></Input>
                    </Form.Item>
                  </StyledFormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <StyledFormItem>
                    <Form.Item
                      className="custom-textbox"
                      name="lastName"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "This is required",
                        },
                      ]}
                    >
                      <Input type="text" placeholder="Enter last name"></Input>
                    </Form.Item>
                  </StyledFormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <StyledFormItem>
                    <Form.Item
                      className="custom-textbox"
                      name="email"
                      label="Email"
                    >
                      <Input
                        type="text"
                        placeholder="Enter email address"
                      ></Input>
                    </Form.Item>
                  </StyledFormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <StyledFormItem>
                    <Form.Item
                      className="custom-textbox"
                      name="phoneNumber"
                      label="Phone Number"
                    >
                      <Input
                        type="text"
                        placeholder="Enter phone number"
                      ></Input>
                    </Form.Item>
                  </StyledFormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <StyledFormItem>
                    <Form.Item
                      className="custom-textbox"
                      name="location.address"
                      label="Address"
                    >
                      <Input type="text" placeholder="Enter address"></Input>
                    </Form.Item>
                  </StyledFormItem>
                </Col>
                <StyledButton>
                  <PrimaryButton htmlType="submit">Save</PrimaryButton>
                </StyledButton>
              </Form>
            </Row>
            {/* <RelationshipContent addEdit={addEdit}></RelationshipContent> */}
          </StyledFormControl>
        </Modal>
      )}
    </>
  );
};

export default AddRelationshipModal;
