import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Popover, List, Skeleton, Button, message } from "antd";
import {
  StyledListWrapper,
  StyledListItem,
  StyledNotificationWrapper,
  StyledNotificationInline,
  StyledText,
} from "./TheHeader.styles";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { NotificationBellSvg } from "../../../assets/svgs";
import { MdMoreVert } from "react-icons/md";
import "./style.css";
import NotificationAPI from "api/http/notification";
import {
  imagePath,
  paginationObj,
  querystring,
  shortenDateFromNow,
  _merge,
} from "utils/fn";
import useSocket from "utils/hooks/useSocket";
import config from "helpers/config";
import { BadgeCount } from "ui/Badge";
import ShowMoreMenu from "ui/Menu/ShowMore";
import { ScrollView } from "ui/ScrollView";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { adminRoutes } from "helpers/adminRoutes";
import { Link } from "react-router-dom";
import { UserAvatarRelationship } from "ui/Avatar/index";
import { useProgressiveImage } from "utils/hooks/useProgressiveImage";
import NotificationRedireact from "./NotificationRedireact";
import TeamAPI from "api/http/team";
import { teamStatus } from "utils/constants";
import { LinkTo } from "Components/Team/TeamDetail/LeftCards/styled";
import { SocketContext } from "utils/context/socket";

const NotificationBellIcon = (props) => {
  return <NotificationBellSvg {...props} />;
};

const HeaderNotification = () => {
  const [data, setData] = useState([]);
  const [scroll, setScroll] = useState(false);
  const [badge, setBadge] = useState(0);
  const { width } = useWindowSize();
  const history = useHistory();
  const [modalState, setModalState] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { user, faviconImage } = useSelector((state) => state.user);
  const favImage = useProgressiveImage(faviconImage);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 6,
    total: 0,
    filter: {
      userId: (user && user?._id) || localStorage.getItem("userId"),
    },
    sorter: {
      order: "descend",
      field: "_id",
    },
    populate: [
      { path: "userBy", select: ["firstName", "lastName", "avatar"] },
      { path: "typeId" },

      // {
      //   path: "badgeCount",
      //   match: {
      //     userId: user?._id || localStorage.getItem("userId"),
      //     isRead: false,
      //   },
      // },
    ],
  });

  const socket = useContext(SocketContext);
  const getNotificationList = useCallback((pagination, merge = false) => {
    NotificationAPI.getNotificationList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData((d) =>
          merge ? _merge(d, [...(data?.data || [])], "_id") : data && data.data
        );
        setPagination((p) => ({
          ...p,
          total: data.total,
          filter: {
            userId: (user && user?._id) || localStorage.getItem("userId"),
          },
          // populate: { path: "userBy" },
        }));
        updateBadgeCount();
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      })
      .finally(() => {});
  }, []);

  const handleReadAllNotification = (notification) => {
    NotificationAPI.updateNotification(
      null,
      { isBadge: true },
      { userId: user?._id }
    ).then((data) => {
      getNotificationList(pagination, true);
    });
  };

  const handleReadNotification = (notification) => {
    if (notification && notification._id) {
      // notificationClick(notification);
      NotificationAPI.updateNotification(notification._id, { isRead: true })
        .then(({ data }) => {
          message.success("Marked as Read");
          setData((notifications) => {
            const idx = notifications.findIndex((n) => n._id === data._id);
            notifications[idx]["isRead"] = true;
            return notifications;
          });
        })
        .catch((error) => {});
    }
  };

  const handleUnReadNotification = (notification) => {
    if (notification && notification._id) {
      NotificationAPI.updateNotification(notification._id, { isRead: false })
        .then(({ data }) => {
          message.success("Marked as unread");
          setData((notifications) => {
            const idx = notifications.findIndex((n) => n._id === data._id);
            notifications[idx]["isRead"] = false;
            return notifications;
          });
          updateBadgeCount();
        })
        .catch((error) => {});
    }
  };

  const handleLoadMore = () => {
    setPagination((p) => ({
      ...p,
      current: +pagination.current + 1,
    }));
    getNotificationList(
      { ...pagination, current: +pagination.current + 1 },
      true
    );
  };

  const updateBadgeCount = useCallback(() => {
    // if (badge > 0) {
    NotificationAPI.getNotificationList(
      querystring({
        userId: (user && user?._id) || localStorage.getItem("userId"),
        isBadge: false,
      })
    ).then(({ data }) => {
      // console.log(data);
      setBadge(data && data.total);
    });
    // }
  }, [user]);

  useEffect(() => {
    updateBadgeCount();
  }, [updateBadgeCount]);

  useEffect(() => {
    if (socket && socket?.disconnected) {
      socket.connect();
    }
    if (socket) {
      socket.emit("join", "notifications");
      socket.on("notification-updated", (datas) => {
        if (datas.action !== "patch") {
          getNotificationList(pagination);
          setPagination((p) => ({
            ...p,
            current: 1,
            pageSize: +p.current * 6,
          }));
          // let datass = [...data];
          // let objIndex = datass.findIndex((x) => x._id === datas?.data?._id);

          // if (objIndex !== -1) {
          //   //  datas.splice(objIndex, 1);
          //   datass[objIndex] = datas?.data;
          //   setData((s) => ({ ...s, data }));
          // }
        }
      });

      // setData((s) => ({ ...s, data }));
      socket.on("reconnect", () => {
        socket.emit("join", "notifications");
      });
      return () => {
        socket.emit("leave", "notifications");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const handleDelete = (notification) => {
    NotificationAPI.deleteNotification(notification._id, {
      id: notification._id,
    })
      .then(({ data }) => {
        message.success("Deleted successfully");
        getNotificationList(pagination);
      })
      .catch((error) => {});
  };
  const loadMore = (
    <div className="header_view_more_wrap">
      {data.length < pagination.total ? (
        <Button onClick={handleLoadMore}>View More</Button>
      ) : (
        ""
      )}
    </div>
  );
  const actionOptions = [
    {
      label: "Mark as read",
      value: "read",
      isDanger: false,
    },
    {
      label: "Mark as unread",
      value: "unread",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: false,
    },
  ];

  useEffect(() => {
    getNotificationList(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNotificationList]);

  const actionHandler = (type, notification) => {
    switch (type) {
      case "read":
        handleReadNotification(notification);
        break;
      case "unread":
        handleUnReadNotification(notification);
        break;
      case "delete":
        handleDelete(notification);
        break;

      default:
        break;
    }
  };

  const isIncludedInTeam = (item) =>
    user.teams
      .map((id) => id.team)
      .map((id) => id._id)
      .includes(item?.typeId?._id);

  const truncatedDateForMobile = useCallback(
    (str) => {
      if (width < 768) {
        return shortenDateFromNow(str);
      } else {
        return str;
      }
    },
    [width]
  );
  const [state, setstate] = useState(false);
  const handleVisibleChange = (visible) => {
    setstate({ visible });
    setPopoverVisible(visible);
  };
  const hide = () => {
    setstate({
      visible: false,
    });
  };

  const [teamData, setTeamData] = useState([]);

  const [teamId, setTeamId] = useState("");
  const [postId, setPostId] = useState("");
  const [commentId, setCommentId] = useState("");
  const defaultPagination = React.useMemo(
    () => ({
      current: 1,
      pageSize: 5,
      total: 0,
      filter: {},
      populate: [
        {
          path: "user",
          select: ["firstName", "lastName", "avatar"],
        },
        {
          path: "team",
        },
        {
          path: "totalComments",
        },
        {
          path: "isLiked",
          match: { likers: user?._id || localStorage.getItem("userId") },
        },
      ],
    }),
    [user?._id]
  );

  const refreshPost = () => {
    getPost(teamId, postId);
  };

  const getPost = (teamId, postId) => {
    TeamAPI.getPost(
      teamId,
      postId,
      querystring(paginationObj(defaultPagination))
    )
      .then((data) => {
        setTeamData(data.data);
        setModalState(true);
      })
      .catch((error) => {
        message.info("Post has been deleted");
        setModalState(false);
        console.log("error", error);
      });
  };
  const defaultQuery = React.useMemo(
    () => ({
      $populate: [
        { path: "memberCount", match: { status: teamStatus.joined } },
      ],
    }),
    []
  );
  const getTeam = useCallback(
    (teamId, postId) => {
      TeamAPI.getTeam(teamId, querystring(defaultQuery))
        .then(({ data }) => {
          getPost(teamId, postId);
        })
        .catch((error) => {
          console.log(error);
          setModalState(false);
          message.error(error.message);
          if (error?.code === 403) {
            history.push(adminRoutes.TEAM);
          }
        });
    },
    [defaultQuery, history]
  );

  const notificationClick = (item) => {
    // getPost(item.typeId._id, item.extra ? item.extra.postId : "");
    // getTeam(item.typeId._id);

    if (item.type === "videoComment" || item.type === "videoCommentLike") {
      hide();
      history.push(adminRoutes.PLAYLIST.replace(":id", item?.extra?.growId));
    }
    setTeamId(item?.typeId?._id);
    if (item.extra) {
      setPostId(item.extra.postId);
      setCommentId(item.extra.commentId);
    }
    if (
      item.type === "teamPost" ||
      item.type === "teamPostComment" ||
      item.type === "teamPostLike" ||
      item.type === "teamPostCommentLike"
    ) {
      // setModalState(true);
      getTeam(item?.typeId?._id, item.extra ? item?.extra?.postId : "");
      hide();
    } else {
    }
    // hide();
    if (item.type === "teamRequest") {
      // getTeamMembers({
      //   ...pagination,
      //   filter: {
      //     team: team?._id,
      //     initiateByRole: "user",
      //     status: "requested",
      //   },
      //   populate: [{
      //     path: "user",
      //     select: ["firstName", "lastName", "avatar", "location"]}],
      // });
      //   <MemberInfo
      //   data={data}
      //   reloadTeam={(withStore = false) =>
      //     getTeam(params.name, withStore)
      //   }
      // />
    }
    if (
      item.type === "team" ||
      item.type === "teamRequest" ||
      item.type === "teamAccept"
      //  ||
      // item.type === "teamPost" ||
      // item.type === "teamPostComment" ||
      // item.type === "teamPostLike" ||
      // item.type === "teamPostCommentLike"
    ) {
      hide();
      history.push(adminRoutes.TEAMDETAILS.replace(":name", item?.typeId?._id));
    } else if (
      item.type === "relationshipAssigned" ||
      item.type === "relationship" ||
      item.type === "relationshipConnect"
    ) {
      hide();
      history.push(adminRoutes.RELATIONSHIP.replace(":name", item?.typeId));
    } else if (item.type === "adminInvitation") {
      hide();
      history.push({
        pathname: adminRoutes.TEAM,
        search: `?q=${item?.typeId?._id}`,
        state: {
          team: item?.typeId,
        },
      });
    }
  };

  const updatePostLike = () => {
    const updatedCommentIdx = teamData.findIndex((d) => d._id === postId);
    const uData = [...teamData];
    uData[updatedCommentIdx]["likers"] = uData[updatedCommentIdx]["isLiked"]
      ? uData[updatedCommentIdx]["likers"].filter((l) => l !== user?._id)
      : [...new Set([...uData[updatedCommentIdx]["likers"], user?._id])];
    uData[updatedCommentIdx]["isLiked"] = uData[updatedCommentIdx]["isLiked"]
      ? 0
      : 1;
    setTeamData(uData);
  };

  const postAction = (postIds, isLike) => {
    TeamAPI.updatePost(
      teamId,
      postId,
      {
        [isLike ? "$addToSet" : "$pull"]: {
          likers: user?._id || localStorage.getItem("userId"),
        },
      },
      false
    )
      .then(() => {
        refreshPost();
        // updatePostLike(postId);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const commentAction = (postId, { _id: commentId, isLiked }, cb) => {
    TeamAPI.updateComment(
      postId,
      commentId,
      {
        [!isLiked ? "$addToSet" : "$pull"]: {
          likers: user?._id || localStorage.getItem("userId"),
        },
      },
      false
    )
      .then(() => {
        // getPostList(team, pagination);
        if (typeof cb === "function") {
          cb(null, { teamId, postId, comment: commentId });
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const deletePostAction = (post, _id, cb) => {
    TeamAPI.deletePost(teamId, _id, post, {
      id: _id,
    })
      .then(() => {
        message.success("Post deleted");
        // refreshPost();
        // getPostList(team, defaultPagination, cb, false);
        // setPagination((p) => ({ ...p, current: 1 }));
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const updatePostData = (postId, postData) => {
    const updatedCommentIdx = teamData.findIndex((d) => d._id === postId);
    const uData = [...teamData];
    uData[updatedCommentIdx]["image"] = postData?.image;
    uData[updatedCommentIdx]["text"] = postData?.text;
    setTeamData(uData);
  };

  const updatePostAction = (
    postIds,
    data,
    cb,
    isJson = false,
    showMessage = true
  ) => {
    TeamAPI.updatePost(teamId, postId, data, !isJson)
      .then(({ data: resData }) => {
        refreshPost();
        if (showMessage) {
          message.success("Post updated successfully");
        }
        // updatePostData(postId, resData);
        if (typeof cb === "function") {
          cb(null, { teamId, postId, data });
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        if (typeof cb === "function") cb(error.message);
      });
  };

  const userPostActions = (
    { event, props },
    cb,
    isJson = false,
    showMessage = true
  ) => {
    switch (event) {
      case "+like":
        postAction(props._id, true);
        break;
      case "-like":
        postAction(props._id, false);
        break;
      case "comment-like":
        commentAction(props.postId, props.comment, cb);
        break;
      case "refreshPost":
        refreshPost(cb);

        break;
      case "delete":
        deletePostAction(props.val, props._id);
        break;
      case "update":
        updatePostAction(props._id, props.post, cb, isJson, showMessage);
        break;
      // case "comment":
      //   createComment(props._id, {
      //     comment: props.comment,
      //     image: props.image,
      //   });
      //   break;
      default:
        break;
    }
  };

  const RenderItem = useCallback((item) => {
    return (
      <StyledListItem
        onClick={(e) => {
          notificationClick(item);
        }}
        actions={[
          <ShowMoreMenu
            Icon={MdMoreVert}
            options={
              item.isRead
                ? [
                    {
                      label: "Mark as unread",
                      value: "unread",
                      isDanger: false,
                    },
                    {
                      label: "Delete",
                      value: "delete",
                      isDanger: false,
                    },
                  ]
                : [
                    {
                      label: "Mark as read",
                      value: "read",
                      isDanger: false,
                    },
                    {
                      label: "Delete",
                      value: "delete",
                      isDanger: false,
                    },
                  ]
            }
            item={item}
            onChange={({ key, item }) => {
              actionHandler(key, item);
            }}
          />,
        ]}
        notificationRead={item.isRead}
      >
        <Skeleton avatar title={false} loading={item.loading} active>
          <List.Item.Meta
            onClick={(e) => {
              // eslint-disable-next-line no-unused-expressions
              e.defaultPrevented ? "" : notificationClick(item);
              e.stopPropagation();
              // eslint-disable-next-line no-unused-expressions
              item.isRead ? "" : handleReadNotification(item);
            }}
            avatar={
              <LinkTo
                to={adminRoutes.PROFILE_VIEW.replace(":id", item.userBy?._id)}
              >
                <UserAvatarRelationship
                  size={27}
                  src={
                    item.typeId?.avatar?.path
                      ? imagePath(item?.typeId?.avatar?.path)
                      : // : "/logo192.png"
                        favImage
                  }
                  // fullName={`${item.userBy?.firstName || ""} ${
                  //   item.userBy?.lastName || ""
                  // }`}
                />
              </LinkTo>
            }
            // title={item.name}
            description={
              <>
                {item.type === "team" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {item.linkTo} {/* </Link> */}
                    {item.extraText}
                  </StyledNotificationInline>
                )}
                {item.type === "teamRequest" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.PROFILE_VIEW.replace(
                    ":id",
                    item.userBy?._id
                  )}
                > */}{" "}
                    {item.linkTo}
                    {/* </Link> */} {item.extraText}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.TEAMDETAILS.replace(
                    ":name",
                    item.typeId._id
                  )}
                > */}{" "}
                    {item.secondLinkTo}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "teamAccept" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.TEAMDETAILS.replace(
                    ":name",
                    item.typeId._id
                  )}
                > */}{" "}
                    {item.linkTo}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "teamPost" && (
                  <StyledNotificationInline>
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.PROFILE_VIEW.replace(
                    ":id",
                    item.userBy?._id
                  )}
                > */}{" "}
                    {item.linkTo}
                    {/* </Link>{" "} */} {item.extraText}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.TEAMDETAILS.replace(
                    ":name",
                    item.typeId._id
                  )}
                > */}{" "}
                    {item.secondLinkTo}
                    {": "}
                    {item.text}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "teamPostComment" && (
                  <StyledNotificationInline>
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.PROFILE_VIEW.replace(
                    ":id",
                    item.userBy?._id
                  )}
                > */}{" "}
                    {item.linkTo}
                    {/* </Link>{" "} */} {item.extraText}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.TEAMDETAILS.replace(
                    ":name",
                    item.typeId._id
                  )}
                > */}{" "}
                    {item.addedText}
                    {item.secondLinkTo}
                    {item.addedText}
                    {": "}
                    {item?.text}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "videoComment" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.PROFILE_VIEW.replace(
                    ":id",
                    item.userBy?._id
                  )}
                > */}{" "}
                    {item.linkTo}
                    {/* </Link>{" "} */} {item.extraText}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.TEAMDETAILS.replace(
                    ":name",
                    item.typeId._id
                  )}
                > */}{" "}
                    {item.addedText}
                    {item.secondLinkTo}
                    {item.addedText}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "teamPostLike" && (
                  <StyledNotificationInline>
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.PROFILE_VIEW.replace(
                    ":id",
                    item.userBy?._id
                  )}
                > */}{" "}
                    {item.linkTo} {/* </Link> */}
                    {item.extraText}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.TEAMDETAILS.replace(
                    ":name",
                    item.typeId._id
                  )}
                > */}{" "}
                    {item.secondLinkTo}
                    {": "}
                    {item.text}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "teamPostCommentLike" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.PROFILE_VIEW.replace(
                    ":id",
                    item.userBy?._id
                  )}
                > */}{" "}
                    {item.linkTo} {/* </Link> */}
                    {item.extraText}
                    {/* <Link
                  onClick={() => notificationClick(item)}
                  // to={adminRoutes.TEAMDETAILS.replace(
                  //   ":name",
                  //   item.typeId._id
                  // )}
                > */}{" "}
                    {item?.addedText}
                    {item.secondLinkTo}
                    {item?.addedText}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "videoCommentLike" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.PROFILE_VIEW.replace(
                    ":id",
                    item.userBy?._id
                  )}
                > */}{" "}
                    {item.linkTo} {/* </Link> */}
                    {item.extraText}
                    {/* <Link
                  onClick={() => notificationClick(item)}
                  // to={adminRoutes.TEAMDETAILS.replace(
                  //   ":name",
                  //   item.typeId._id
                  // )}
                > */}{" "}
                    {item?.addedText}
                    {item.secondLinkTo}
                    {item?.addedText}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "relationshipAssigned" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.RELATIONSHIP.replace(
                    ":name",
                    item.typeId
                  )}
                > */}{" "}
                    {item.linkTo}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "relationship" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.RELATIONSHIP.replace(
                    ":name",
                    item.typeId
                  )}
                > */}{" "}
                    {item.linkTo}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "relationshipConnect" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.RELATIONSHIP.replace(
                    ":name",
                    item.typeId
                  )}
                > */}{" "}
                    {item.linkTo}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
                {item.type === "adminInvitation" && (
                  <StyledNotificationInline>
                    {item.text}
                    {"  "}
                    {/* <Link
                  onClick={hide}
                  to={adminRoutes.PROFILE_VIEW.replace(
                    ":id",
                    item?.userBy?._id
                  )}
                > */}{" "}
                    {item?.linkTo} {/* </Link>{" "} */}
                    {item?.extraText}
                    {/* <Link
                  onClick={hide}
                  to={{
                    pathname: adminRoutes.TEAM,
                    search: `?q=${item?.typeId?._id}`,
                    state: {
                      team: item?.typeId,
                    },
                  }}
                  //   to={{
                  //     pathname: isIncludedInTeam(item)
                  //       ? adminRoutes.TEAMDETAILS.replace(
                  //           ":name",
                  //           item.typeId._id
                  //         )
                  //       : adminRoutes.TEAM,
                  //     search: isIncludedInTeam(item)
                  //       ? ""
                  //       : `?q=${item?.typeId?._id}`,
                  //     state: {
                  //       ...(isIncludedInTeam(item)
                  //         ? { team: item?.typeId }
                  //         : {}),
                  //     },
                  //   }}
                > */}{" "}
                    {item?.secondLinkTo}
                    {/* </Link> */}
                  </StyledNotificationInline>
                )}
              </>
            }
          />
          <span className="header_notification_time">
            {truncatedDateForMobile(dayjs(item.createdAt).fromNow())}
          </span>
        </Skeleton>
      </StyledListItem>
    );
  }, []);

  const ScrollViewComponent = () => {
    const datasource = useMemo(() => data, [data]);

    return (
      <ScrollView maxHeight={"500px"}>
        <StyledListWrapper
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={datasource}
          renderItem={RenderItem}
        />
      </ScrollView>
    );
  };

  useEffect(() => {
    var x = window.scrollX;
    var y = window.scrollY;
    if (popoverVisible) {
      if (mouseIn) {
        window.onscroll = function () {
          window.scrollTo(x, y);
        };
      } else {
        window.onscroll = function () {};
        document.body.style.overflow = "";
      }
    }
  }, [mouseIn, popoverVisible]);

  // useEffect(() => {
  //   // if (state.visible === false) {
  //   //   setPagination((p) => ({
  //   //     ...p,
  //   //     current: 1,
  //   //   }));
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state]);
  return (
    <>
      <StyledNotificationWrapper
        onMouseEnter={() => setMouseIn(true)}
        onMouseLeave={() => setMouseIn(false)}
      >
        <Popover
          placement="bottomRight"
          content={ScrollViewComponent}
          onscroll={() => setScroll(true)}
          onClick={handleReadAllNotification}
          trigger="click"
          overlayClassName="header_popover_wrap"
          visible={state.visible}
          onVisibleChange={handleVisibleChange}
        >
          <BadgeCount count={badge} size={"small"}>
            <NotificationBellIcon
              className="bell_icon"
              width="26"
              height="26"
            />
          </BadgeCount>
        </Popover>
      </StyledNotificationWrapper>
      {modalState && (
        <NotificationRedireact
          onCancel={(value) => setModalState(value)}
          modalState={modalState}
          from={adminRoutes.TEAM}
          teamId={teamId}
          postId={postId}
          commentId={commentId}
          userPostActions={userPostActions}
          // onSubmit={() => {
          //   refreshTeams();
          //   setModalStates(true);
          // }}
          teamData={teamData}
          isModal={true}
        />
      )}
    </>
  );
};

export default HeaderNotification;
