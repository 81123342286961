const configs = JSON.parse(localStorage.getItem("config"));

const config = {
  env: process.env.NODE_ENV,
  publicUrl: process.env.PUBLIC_URL,
  api: {
    url: configs?.apiUrl,
  },
};

export default config;
