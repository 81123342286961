import React from "react";
import { Form, message } from "antd";
import { PrimaryButton } from "../../ui/Button";
import { FormInput, FormItem } from "../../ui/Form/Input";
import { Container } from "../../ui/Layout/Container";
import { Card, CardTitle } from "../../ui/Card";
import UserAPI from "api/http/user";
import { useSelector } from "react-redux";
import { Heading } from "ui/Heading/Heading";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";

const ManagePassword = () => {
  const { user } = useSelector((state) => state.user);
  const [showPrompt, setShowPrompt] = React.useState(false);

  const onFinish = (values) => {
    setShowPrompt(false);

    UserAPI.updateUser(user._id, values)
      .then(({ data }) => {
        message.success("Password changed");
      })
      .catch((error) => {
        if (error) {
          console.log("error", error);
          message.error(error.message);
        }
      });
  };

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });
  return (
    <Container>
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Heading>Manage Password</Heading>
      <Card>
        <Form
          layout="vertical"
          requiredMark="optional"
          onFinish={onFinish}
          onValuesChange={() => {
            setShowPrompt(true);
          }}
        >
          <FormItem
            label="Current Password"
            name="currentPassword"
            type="text"
            placeholder="Enter current password"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormInput />
          </FormItem>
          <FormItem
            label="New Password"
            name="newPassword"
            type="text"
            placeholder="Enter new Password"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormInput />
          </FormItem>
          <FormItem
            label="Confirm New Password"
            name="confirmNewPassword"
            type="text"
            placeholder="Enter confirm new password"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormInput />
          </FormItem>

          <PrimaryButton type="submit" htmlType="submit">
            Submit
          </PrimaryButton>
        </Form>
      </Card>
    </Container>
  );
};

export default ManagePassword;
