import { httpAPI } from "api";

const NotificationAPI = {
  addNotification(params) {
    return httpAPI(`/notification`, params, "post");
  },

  getNotificationList(query) {
    return httpAPI(`/notification?${query || ""}`, "", "get");
  },
  getNotificationListDetail(id) {
    return httpAPI(`/notification/${id}`, "", "get");
  },
  updateNotification(id, params) {
    return httpAPI(`/notification/${id}`, params, "patch");
  },
  deleteNotification(id, params) {
    return httpAPI(`/notification/${id}`, params, "delete");
  },
};

export default NotificationAPI;
