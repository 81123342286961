import { Breadcrumb, Dropdown } from "antd";
import React, { useState, useEffect, useCallback, useContext } from "react";
import DashboardContent from "./RelationshipContent";
import { HiPlus } from "react-icons/hi";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import { Tabs, Select, Button, message } from "antd";
import AddRelationshipModal from "./AddRelationshipModal";
import { Heading } from "../../ui/Heading";
import { Container } from "../../ui/Layout/Container";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";

import {
  AddNewRelation,
  StyleLdSortingWrapper,
  StyledSortingIconWrapper,
  StyleLayout,
  StyleBreadCrumbsWrapper,
  FloatButton,
  StyledTabButtonWrap,
  StyledItemPageSelect,
  StyleTabWrapper,
} from "./TheRelationship.styles";
import {
  ItemPerPageText,
  ItemPerPageTrigger,
  OptionMenu,
  OptionMenuItem,
  Label,
} from "ui/Datatable/datatable.styles";
import { ItemPerPageContainer } from "ui/Datatable/datatable.styles";
import ItemPerPageSelect from "ui/Datatable/ItemPerPageSelect";
import RelationshipTabAPI from "api/http/relationshipTab";
import RelationshipContent from "./RelationshipContent";
import { SecondaryButton } from "../../ui/Button";
import { joinGroup, leftGroup, subscribeSocketEvent } from "utils/socket";
import { joinAndSubscribe } from "utils/socket/index";
import useSocket from "utils/hooks/useSocket";
import { useSelector } from "react-redux";

import config from "helpers/config";
import { randomNumber } from "utils/fn";
import { Tab } from "ui/Tab/index";
import { SocketContext } from "utils/context/socket";

const { TabPane } = Tabs;
const { Option } = Select;

const Relationship = (props) => {
  const [state, setState] = useState({
    dispalyMenu: false,
    showActivity: false,
    showMove: false,
    showContact: false,
    showDash: true,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [tabstate, setTabState] = useState({ key: "" });
  const [currentType, setCurrentType] = useState("");
  const [tabData, setTabData] = useState();
  const [tabAllData, setAllTabData] = useState([]);
  const [filterData, setFilter] = useState("Show All");

  const [modalState, setModalState] = useState(false);
  const [socketCall, setSocketCall] = useState(false);
  const { configData } = useSelector((state) => state.configData);

  const [pageSize, setPageSize] = useState(50);

  function callback(key) {
    let objIndex = tabData.findIndex((x) => x._id == key);
    if (objIndex !== -1) {
      setCurrentType(tabData[objIndex].type);
    }
    setTabState({ key: key });
  }

  const OptionMenuList = ({ options = ["Show All"], pageSize, onClick }) => {
    return (
      <OptionMenu onClick={({ key }) => onClick(key)} selectedKeys={[pageSize]}>
        {options.map((key) => (
          <OptionMenuItem key={key}>{key}</OptionMenuItem>
        ))}
      </OptionMenu>
    );
  };

  useEffect(() => {
    const compare = (a, b) => {
      const bandA = b.sequence;
      const bandB = a.sequence;

      let comparison = 0;
      if (bandB > bandA) {
        comparison = 1;
      } else if (bandB < bandA) {
        comparison = -1;
      }
      return comparison;
    };

    RelationshipTabAPI.getRelationshipTabList()
      .then(({ data }) => {
        data.sort(compare);
        setTabData(data);
        setTabState({ key: data?.[0]._id });
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  }, []);

  const pageSizeChangehandler = (value) => {
    setIsOpen(false);
    setPageSize(value);
  };

  const filterChangehandler = (value) => {
    setIsOpen(false);
    setFilter(value);
  };

  const OperationsSlot = {
    left: (
      <AddNewRelation>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
          onClick={() => setModalState(true)}
          height={"52px"}
          width={"52px"}
        />
      </AddNewRelation>
    ),
    right: (
      <StyleLdSortingWrapper>
        {/*   <label>View By:</label> */}
        {/* <Select
          defaultValue="50"
          onChange={handleChange}
          suffixIcon={<HiOutlineChevronDown className="select_down_arrow" />}
        >
          <Option value="10">10</Option>
          <Option value="20">20</Option>
          <Option value="50">50</Option>
        </Select>
        <StyledSortingIconWrapper>
          <FaLongArrowAltUp className="sorting_up_icon" />
          <FaLongArrowAltDown className="sorting_down_icon" />
        </StyledSortingIconWrapper> */}
        <StyledItemPageSelect>
          <ItemPerPageSelect
            backgrounds="white"
            label="Show"
            borderRadius="50px"
            defaultPageSize={pageSize}
            onChange={pageSizeChangehandler}
            height={"52px"}
          />
        </StyledItemPageSelect>

        <ItemPerPageContainer>
          <Label style={{ alignItems: "center" }}>Filter By:</Label>
          <Dropdown
            visible={isOpen}
            overlay={
              <OptionMenuList
                options={["Show All", "Show Assigned", "Show Manual Uploads"]}
                pageSize={filterData}
                onClick={filterChangehandler}
              />
            }
            onVisibleChange={setIsOpen}
            trigger="click"
          >
            <ItemPerPageTrigger
              backgrounds={"white"}
              borderRadius={"50px"}
              height={"52px"}
              label="Show"
              defaultPageSize={"Show All"}
              onChange={pageSizeChangehandler}
              padding={"0px 15px"}
              width={"auto"}
            >
              <ItemPerPageText style={{ marginRight: "10px" }}>
                {filterData}
              </ItemPerPageText>
              {/* <HiOutlineChevronUp /> */}
              {isOpen ? <HiOutlineChevronUp /> : <HiOutlineChevronDown />}
            </ItemPerPageTrigger>
          </Dropdown>
        </ItemPerPageContainer>
      </StyleLdSortingWrapper>
    ),
  };

  function handleChange(value) {}

  const handleTabChange = (tab) => {
    callback(tab);
  };

  /** Socket Config */
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (socket && socket?.disconnected) {
      socket.connect();
    }
    if (socket) {
      socket.emit("join", "relationships");
      socket.emit("join", "relationship-tab");
      socket.on("lead-updated", (data) => {
        setSocketCall(data);
      });
      socket.on("stage-updated", (data) => {
        // getRelationshipTabList();
      });
      socket.on("reconnect", () => {
        socket.emit("join", "relationships");
        socket.emit("join", "relationship-tab");
      });
      return () => {
        socket.emit("leave", "relationships");
        socket.emit("leave", "relationship-tab");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  /** -- Socket Config -- */

  return (
    <>
      <StyleLayout>
        <StyleBreadCrumbsWrapper>
          <div className="breadcrumbs">
            <Breadcrumb>
              <Breadcrumb.Item className="breadcrumbsLink">
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumbsLink">
                Connect
              </Breadcrumb.Item>
              <Breadcrumb.Item className="breadcrumbsLink">
                {configData?.[0].module?.[1]?.children?.[1]?.name}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{currentType}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </StyleBreadCrumbsWrapper>
        <Container minH="100%" padding="17px 30px 0">
          <Heading>{configData?.[0].module?.[1]?.children?.[1]?.name}</Heading>
        </Container>
        {/* <StyledTabButtonWrap> */}

        {/* {tabData && (
          <Tab
            tabBarExtraContent={OperationsSlot}
            onChange={callback}
            activeKey={tabstate.key}
            tabPanes={tabData}
            width={"max-content"}
          />
        )} */}

        <StyleTabWrapper>
          <Tab
            tabPanes={tabData}
            tabBarExtraContent={OperationsSlot}
            onChange={callback}
            activeKey={tabstate.key}
          />
        </StyleTabWrapper>

        {/* {tabData &&
              tabData.length > 0 &&
              tabData.map((ks, index) => (
                <TabPane tab={ks.type} key={ks.type}></TabPane>
              ))} */}

        {/* </Tab> */}
        {/* </StyledTabButtonWrap> */}
        <FloatButton>
          <SecondaryButton
            shape="circle"
            icon={<HiPlus className="plus_icon" />}
            onClick={() => setModalState(true)}
          />
        </FloatButton>
      </StyleLayout>
      {/* <AddRelationshipModal
        onClick={(value) => setModalState(value)}
        modalState={modalState}
      /> */}
      <AddRelationshipModal
        tabkey={tabstate.key}
        tabFirstId={tabData && tabData[0]._id}
        tabDatas={tabData}
        show={state}
        setShow={setState}
        setTabState={handleTabChange}
        onClick={(value) => setModalState(value)}
        modalState={modalState}
      />
      <DashboardContent
        tabkey={tabstate.key}
        tabFirstId={tabData && tabData[0]._id}
        tabDatas={tabData}
        show={state}
        setShow={setState}
        filter={filterData}
        socketCall={socketCall}
        pageSize={pageSize}
        modalState={modalState}
        setTabState={handleTabChange}
      />
    </>
  );
};

// useEffect(() => {
//   if (first && props.tabFirstId) {
//     let arr = [];
//     RelationshipAPI.getRelationshipList(
//       querystring(
//         paginationObj({
//           ...pagination,
//           filter: {
//             $or: [
//               {
//                 type: props.tabFirstId,

//                 blockedUsers: {
//                   $nin: [localStorage.getItem("userId") || user?._id],
//                 },
//               },
//               {
//                 $and: [
//                   {
//                     blockedUsers: {
//                       $nin: [localStorage.getItem("userId") || user?._id],
//                     },
//                   },
//                   {
//                     type: {
//                       $ne: props.tabFirstId,
//                     },
//                   },
//                   {
//                     claimedBy: {
//                       $ne: user?._id || localStorage.getItem("userId"),
//                     },
//                   },
//                 ],
//               },
//             ],
//             $sort: {
//               ...(!tab.keys ? { claimed: 1 } : {}),
//               createdAt: -1,
//             },
//           },

//           ...(props.pageSize ? { pageSize: props.pageSize } : {}),
//         })
//       )
//     )
//       .then(({ data }) => {
//         setStateData({ data: data.data });
//         for (const datas of data.data) {
//           if (
//             (tab.key === tabData[0]._id && datas.sourceId === null) ||
//             datas?.members.length === 0 ||
//             !datas.members.includes(user?._id)
//           ) {
//             console.log("mansi source null");
//           } else {
//             console.log("mansi inside data", datas);
//             let obj = datas;
//             let diff = dayjs().diff(dayjs(datas.createdAt));
//             let duration = msToTime(diff);

//             obj.duration = duration.time;
//             obj.minutes = duration.minutes;
//             let cntct = datas.sourceId && datas.sourceId.contact;

//             if (cntct)
//               for (const dta of cntct) {
//                 if (dta.fieldName === "firstName") {
//                   obj.firstNameVisibility = dta.visibility;
//                 }
//                 if (dta.fieldName === "lastName") {
//                   obj.lastNameVisibility = dta.visibility;
//                 }
//                 if (dta.fieldName === "email") {
//                   obj.emailVisibility = dta.visibility;
//                 }
//                 if (dta.fieldName === "address") {
//                   obj.addressVisibility = dta.visibility;
//                 }
//                 if (dta.fieldName === "phoneNumber") {
//                   obj.phoneNumberVisibility = dta.visibility;
//                 }
//               }
//             if (
//               datas &&
//               datas.type !== props.tabFirstId &&
//               datas.claimedBy &&
//               datas.claimedBy._id !==
//                 (localStorage.getItem("userId") || user?._id)
//             ) {
//               obj.type = props.tabFirstId;
//             }
//             var temp = [];
//             let arrs = [];

//             if (datas && datas.members && datas.members.length > 0) {
//               for (const mebr of datas.members) {
//                 arrs.push(mebr._id);
//               }
//             }
//             if (datas.sourceId) {
//               if (datas.sourceId.visibilityType === "All users") {
//                 arr.push(obj);
//               } else if (
//                 datas.sourceId.visibilityType === "Only assigned contacts"
//               ) {
//                 if (datas && datas.members && datas.members.length > 0) {
//                   if (arrs.includes(localStorage.getItem("userId"))) {
//                     arr.push(obj);
//                   } else if (
//                     user &&
//                     user.permissions &&
//                     user.permissions.length > 0 &&
//                     user.permissions.includes("admin")
//                     //   ||
//                     // (localStorage.getItem("userId") || user?._id) ===
//                     //   datas.sourceId.userId
//                   ) {
//                     arr.push(obj);
//                   }
//                 } else if (
//                   (user &&
//                     user.permissions &&
//                     user.permissions.includes("admin")) ||
//                   (localStorage.getItem("userId") || user?._id) ===
//                     datas.sourceId.userId
//                 ) {
//                   arr.push(obj);
//                 } else {
//                 }
//               } else {
//                 arr.push(obj);
//               }
//             } else if (datas && datas.members && datas.members.length > 0) {
//               if (arrs.includes(localStorage.getItem("userId"))) {
//                 arr.push(obj);
//               }
//             }

//             setStateData({ data: arr });
//             setPagination((p) => ({
//               ...p,
//               pageSize: data.limit,
//               total: arr.total,
//               filter: {
//                 $or: [
//                   {
//                     type: tab.key,
//                     blockedUsers: {
//                       $nin: [localStorage.getItem("userId") || user?._id],
//                     },
//                   },
//                   tab.key === props.tabFirstId
//                     ? {
//                         $and: [
//                           {
//                             blockedUsers: {
//                               $nin: [
//                                 localStorage.getItem("userId") || user?._id,
//                               ],
//                             },
//                           },
//                           {
//                             type: {
//                               $ne: props.tabFirstId,
//                             },
//                           },
//                           {
//                             claimedBy: {
//                               $ne:
//                                 user?._id || localStorage.getItem("userId"),
//                             },
//                           },
//                         ],
//                       }
//                     : {},
//                 ],
//                 $sort: {
//                   ...(tab.key === props.tabFirstId ? { claimed: 1 } : {}),
//                   createdAt: -1,
//                 },
//               },
//               populate: [
//                 {
//                   path: "sourceId",
//                   match: {
//                     ...(props.filter === "Mine"
//                       ? {
//                           userId: user?._id || localStorage.getItem("userId"),
//                         }
//                       : {}),
//                     // userId: user?._id || localStorage.getItem("userId"),
//                   },
//                 },
//                 { path: "claimedBy" },
//                 { path: "members" },
//               ],
//             }));
//           }
//           setFirst(false);
//         }
//       })
//       .catch((error) => {
//         if (error) {
//           message.error(error.message);
//         }
//       });
//     // })
//     // .catch((error) => {
//     //   if (error) {
//     //     message.error(error.message);
//     //   }
//     // });
//   }
// }, []);

export default Relationship;
