export const UserProfileEventData = [
  {
    eventName: "Introduction To Young Living",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event1.png",
    type: "Interested",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 6,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 7,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 8,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 9,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Culinary Tasting Party",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event2.png",
    type: "I'm Going",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 6,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 7,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Vibe Barbecue",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event3.png",
    type: "Interested",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 6,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Convenation Recap Meeting",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event4.png",
    type: "Interested",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Culinary Tasting Party",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event2.png",
    type: "Next Time",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 6,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 7,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Convenation Recap Meeting",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event4.png",
    type: "Next Time",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Introduction To Young Living",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event1.png",
    type: "I'm Going",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 6,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 7,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 8,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 9,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Culinary Tasting Party",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event3.png",
    type: "Next Time",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 6,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 7,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
];
