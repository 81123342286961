import { httpAPI } from "api";

const VideoAPI = {
  uploadVideo: ({ video, ...data }) => {
    const formdata = new FormData();
    Object.keys(data).forEach((k) => {
      if (data[k]) formdata.append(k, data[k]);
    });
    if (video) formdata.append("video", video);
    return httpAPI(`/videos`, formdata, "POST", true);
  },

  updateVideo(id, params, query) {
    return httpAPI(`/videos/${id}?${query || ""}`, params, "patch");
  },


  addVideo(params) {
    return httpAPI(`/videos`, params, "post");
  },
  getVideoList(query) {
    return httpAPI(`/videos?${query || ""}`, "", "get");
  },
  getVideoDetail(id, query) {
    return httpAPI(`/videos/${id}?${query || ""}`, "", "get");
  },

  deleteVideo(id, params) {
    return httpAPI(`/videos/${id}`, params, "delete");
  },
};

export default VideoAPI;
