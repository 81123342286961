import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Input, Row, Avatar, Button, Col, Card } from "antd";
import { getInitials, paginationObj, querystring } from "utils/fn";
import { FiSearch } from "react-icons/fi";
import {
  AssignCard,
  StyledButton,
  InputWrapper,
  StyledContentTitle,
  CardWrapper,
  UserAvatarWrapper,
  AvatarWrapper,
  StyledFormControl,
} from "./TheRelationship.styles";
import { useHistory } from "react-router-dom";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";

import UserAPI from "api/http/user";
import { SmallCard } from "ui/Card/Card";
import { PrimaryButton } from "ui/Button/Button";
import { imagePath } from "utils/fn";
import RelationshipAPI from "api/http/relationship";
import NotificationAPI from "api/http/notification";
import { useSelector } from "react-redux";
import { UserAvatar, UserAvatarRelationship } from "ui/Avatar/index";
import { Skeleton } from "../../../node_modules/antd/lib/index";
import RelationshipContent from "./RelationshipContent";
import { useProgressiveImage } from "utils/hooks/useProgressiveImage";

const { Search } = Input;

const AssignContact = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataColor, setDataColor] = useState([]);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [visible, setVisible] = useState(props.modalState);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 200,
    total: 0,
    filter: {},
    sorter: {},
  });
  const { user, faviconImage } = useSelector((state) => state.user);
  const favImage = useProgressiveImage(faviconImage);

  const handleCancel = (values) => {
    setDataColor([]);
    setVisible(false);
    props.setModelState(false);
  };
  const onFinish = (values) => {
    if (props.id) {
      RelationshipAPI.updateRelationship(props.id, {
        members: members,
        users: user,
        category: "members",
        sourceId: props.data.sourceId,
      });
      setVisible(false);
      props.setModelState(false);
    }
  };

  useEffect(() => {
    let arr = [];
    if (props.modalState) {
      UserAPI.getUserList(querystring(paginationObj(pagination)))
        .then(({ data: userList }) => {
          RelationshipAPI.getRelationshipListDetail(props.id).then(
            ({ data }) => {
              if (data && data.members && data.members.length > 0) {
                setData(userList.data);
                for (const d of userList.data) {
                  let obj = {
                    ...d,
                    selected:
                      data.members && data.members.includes(d._id)
                        ? true
                        : false,
                  };
                  arr.push(obj);
                }
                setMembers(data.members);
              } else {
                for (const d of userList.data) {
                  let obj = {
                    ...d,
                    selected:
                      data.members && data.members.includes(d._id)
                        ? true
                        : false,
                  };
                  arr.push(obj);
                }
                setMembers([]);
                setDataColor([]);
              }
              setDataColor(arr);
            }
          );
          setPagination((p) => ({ ...p, total: data?.total }));
        })
        .catch((error) => {
          console.log(error);
        });
      setVisible(props.modalState);
      setMembers([]);
      setDataColor([]);
    }
  }, [props.modalState]);

  const handleMembers = (memberId, index, item) => {
    let member = members;
    if (members.includes(memberId)) {
      member.splice(member.indexOf(memberId), 1);
      RelationshipAPI.updateRelationship(props.id, {
        $addToSet: {
          activities: {
            userId: user?._id,
            name: `${user?.firstName}`,
            text: `removed ${item?.firstName}`,
          },
        },
      });
    } else {
      member.push(memberId);
      RelationshipAPI.updateRelationship(props.id, {
        $addToSet: {
          activities: {
            userId: user?._id,
            name: `${user?.firstName}`,
            text: `assigned to ${item?.firstName}`,
          },
        },
      });
    }
    setMembers([]);
    setDataColor([]);

    RelationshipAPI.updateRelationship(props.id, {
      members: member,
      users: user,
      category: "members",
      sourceId: props.data.sourceId,
    }).then({});
  };

  const getUserList = useCallback((pagination) => {
    setLoading(true);
    if (props.id) {
      UserAPI.getUserList(querystring(paginationObj(pagination)))
        .then(({ data: userList }) => {
          let arr = [];
          RelationshipAPI.getRelationshipListDetail(props.id).then(
            ({ data }) => {
              if (data && data.members && data.members.length > 0) {
                setData(userList.data);
                for (const d of userList.data) {
                  let obj = {
                    ...d,
                    selected:
                      data.members && data.members.includes(d._id)
                        ? true
                        : false,
                  };
                  arr.push(obj);
                }
                setMembers(data.members);
              } else {
                for (const d of userList.data) {
                  let obj = {
                    ...d,
                    selected:
                      data.members && data.members.includes(d._id)
                        ? true
                        : false,
                  };
                  arr.push(obj);
                }
              }
              setDataColor(arr);
            }
          );

          setPagination((p) => ({
            ...p,
            total: data.total,
            filter: {},
          }));
        })
        .catch((error) => {
          if (error) {
            // message.error(error.message);
          }
        })
        .finally(() => {});
    }
  }, []);

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchTerm && searchTerm.length > 0
        ? setPagination((p) => ({
            ...p,
            filter: { firstName: { $regex: `${searchTerm}`, $options: "igm" } },
          }))
        : setPagination((p) => ({ ...p, filter: {} }));

      getUserList({
        ...pagination,

        filter:
          searchTerm && searchTerm.length > 0
            ? { firstName: { $regex: `${searchTerm}`, $options: "igm" } }
            : {},
      });
    }, 100);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={() => handleCancel()}
        width={"960px"}
        // onOk={() => onFinish()}
        footer={null}
        destroyOnClose={true}
        style={{ borderRadius: "5px" }}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
        focusTriggerAfterClose={false}
      >
        <StyledContentTitle>Assign Contact</StyledContentTitle>
        <InputWrapper
          placeholder="Search..."
          onChange={(e) => setSearchTerm(e.target.value)}
          prefix={<FiSearch />}
          // onSearch={(e) => handleSearch(e)}
          // enterButton
        />

        {/* <Search
          placeholder="Search..."
          onSearch={(e) => handleSearch(e)}
          enterButton
        /> */}

        <Form
          requiredMark={"optional"}
          form={form}
          layout="vertical"
          onFinish={(e) => onFinish(e)}
        >
          <Row gutter={[30, 30]}>
            {/* {loading &&
              <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
              <CardWrapper>
                <AssignCard>
                <UserAvatarWrapper  size={65}  ><Skeleton.Image /></UserAvatarWrapper>
                </AssignCard>
                </CardWrapper>               
             </Col>
             } */}

            {dataColor.map((item, index) => (
              <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                {
                  <CardWrapper
                    onClick={() => handleMembers(item._id, index, item)}
                    // selectColor={members.includes(item._id)}
                  >
                    <AssignCard selectColor={item.selected}>
                      {/* {members.includes(item._id) ? "included" : "no"} */}
                      <UserAvatarWrapper
                        size={65}
                        src={
                          item.avatar?.path
                            ? imagePath(item.avatar?.path)
                            : item?.firstName && item?.lastName
                            ? ""
                            : //  "/logo192.png"
                              favImage
                        }
                        fullName={`${item?.firstName || ""} ${
                          item?.lastName || ""
                        }`}
                      />
                      <h5>
                        {item?.firstName || ""} {item?.lastName || ""}
                      </h5>
                    </AssignCard>
                  </CardWrapper>
                }
              </Col>
            ))}
          </Row>
          {/* <StyledButton>
            <PrimaryButton htmlType="submit">Save</PrimaryButton>
          </StyledButton> */}
        </Form>
      </Modal>
    </div>
  );
};

export default AssignContact;
