import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { authRoutes } from "../../helpers/adminRoutes";

// Pages
import Login from "../../Components/Login";
import Registration from "../../Components/Registration";
import ForgetPassword from "../../Components/ForgetPassword";
import ResetPassword from "Components/ResetPassword";
import EmailVerify from "Components/EmailVerify";

const nav = [
  {
    name: "Login",
    component: Login,
    exact: true,
    path: authRoutes.LOGIN,
  },
  {
    name: "Register",
    component: Registration,
    exact: true,
    path: authRoutes.REGISTRATION,
  },
  {
    name: "ForgotPassword",
    component: ForgetPassword,
    exact: true,
    path: authRoutes.FORGOT_PASSWORD,
  },
  {
    name: "ResetPassword",
    component: ResetPassword,
    exact: true,
    path: `${authRoutes.RESET_PASSWORD}/:token`,
  },
  {
    name: "ResetPassword",
    component: ResetPassword,
    exact: true,
    path: `${authRoutes.CREATE_PASSWORD}/:token`,
  },
  {
    name: "EmailVerify",
    component: EmailVerify,
    exact: true,
    path: `${authRoutes.EMAILVERIFY}/:id`,
  },
];

const AuthRoutes = () => {
  return (
    <Switch>
      {nav.map(({ name, ...props }) => (
        <Route key={name} {...props} />
      ))}
      <Redirect to={authRoutes.LOGIN} />
    </Switch>
  );
};

export default AuthRoutes;
