import styled from "styled-components";
import { Button } from "antd";

export const StyledImages = styled.div`
margin-right:20px;
.ant-image-img{
  height:100px;
 
}
.selected{
border: ${(props) => "5px solid" + props.theme.colors.primary}
   }
`
export const AddNewField = styled(Button)`
/* display: inline-block;

width: 48px;
height: 48px;
background: linear-gradient(
  276.7deg,
  ${(props) => props.theme.colors.primary} 0%,
  ${(props) => props.theme.colors.primary2} 100%
);
box-shadow: 0px 10px 15px ${(props) => props.theme.colors.primary}59;
display: flex;
justify-content: center;
align-items: center;
 border: none;

.plus_icon {
  font-size: 34px;
  color: white;
}
:hover {
  background: linear-gradient(
    276.7deg,
    ${(props) => props.theme.colors.primary} 0%,
    ${(props) => props.theme.colors.primary2} 100%
  );
} */

border: 1px solid transparent;
background: linear - gradient(
  276.7deg,
  ${(props) => props.theme.colors.primary} 0 %,
  ${(props) => props.theme.colors.primary2} 100 %
  );
box - shadow: 0px 10px 15px ${(props) => props.theme.colors.primary} 59;
color: #ffffff;
display: flex;
align - items: center;
justify - content: center;
font - weight: bold;
font - size: 14px;
line - height: 18px;
  : hover,
  :focus {
  color: ${(props) => props.theme.colors.primary};
  border: 1px solid ${(props) => props.theme.colors.primary};
}

  &.ant - btn - circle {
  height: ${({ height }) => height || "48px"};
  width: ${({ width }) => width || "48px"};
}

  .plus_icon {
  font - size: 34px;
}
`;
