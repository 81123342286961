import { Button, Dropdown, Menu, Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { MdMoreHoriz } from "react-icons/md";
import styled, { useTheme } from "styled-components";

const ShowMoreMenu = ({
  triggerComponent,
  Icon = MdMoreHoriz,
  item,
  options = [],
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const theme = useTheme();
  const handleChange = (val) => {
    // setSelectedKeys([val.key]);
    setSelectedKeys();
    onChange(val);
    setIsOpen(false);
  };
  return (
    <Dropdown
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      overlay={
        <OptionMenuList
          options={options}
          item={item}
          selectedKeys={selectedKeys}
          onClick={handleChange}
        />
      }
      visible={isOpen}
      onVisibleChange={setIsOpen}
      trigger="click"
    >
      {triggerComponent || (
        <ActionButton
          type="text"
          shape="circle"
          icon={
            isOpen ? (
              <LinearGradient
                colors={[
                  { hex: theme.colors.primary, offset: "100%" },
                  { hex: theme.colors.primary2, offset: "100%" },
                ]}
                Icon={Icon}
              />
            ) : (
              <Icon color={"#D3D6DD"} />
            )
          }
        />
      )}
    </Dropdown>
  );
};

const OptionMenuList = ({
  options = [],
  item = null,
  selectedKeys = [],
  onClick,
}) => {
  return (
    <ActionMenu selectedKeys={selectedKeys}>
      {options.map(({ label, value, isDanger }) =>
        isDanger ? (
          <Popconfirm
            key={value}
            title="Are you sure?"
            onConfirm={() => onClick({ key: value, item })}
            okText="Yes"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <ActionMenuItem key={value}> {label}</ActionMenuItem>
          </Popconfirm>
        ) : (
          <ActionMenuItem
            key={value}
            onClick={(e) => {
              onClick({ key: value, item });
              e.domEvent.stopPropagation();
            }}
          >
            {label}
          </ActionMenuItem>
        )
      )}
    </ActionMenu>
  );
};

const ActionButton = styled(Button)`
  &:active,
  &:hover {
    background: transparent;
  }
  &.ant-btn-icon-only > * {
    font-size: 26px;
  }
`;

const ActionMenu = styled(Menu)`
  background: #fff;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 35%);
  min-width: 180px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

const ActionMenuItem = styled(Menu.Item)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  background-color: #ffffff;

  &.ant-menu-item-active {
    color: #666666;
  }

  &.ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.primary};
    background-color: #ffffff !important;
  }
  &.ant-menu-item {
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      background-color: #ffffff;
    }
  }
`;

export const LinearGradient = ({ colors = [], Icon, iconProps }) => (
  <>
    <svg width="0" height="0">
      <linearGradient id="primary-gradient" x1="100%" y1="100%" x2="0%" y2="0%">
        {colors.map((color, idx) => (
          <stop key={idx} stopColor={color.hex} offset={color.offset} />
        ))}
      </linearGradient>
    </svg>
    <Icon
      {...iconProps}
      style={{
        stroke: "url(#primary-gradient)",
        fill: "url(#primary-gradient)",
      }}
    />
  </>
);

export default ShowMoreMenu;
