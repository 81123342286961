import { Button } from "antd";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 100%;
  min-height: 100vh;

  /* height: 100vh; */

  @media screen and (max-width: 991px) {
    flex-direction: column;

    min-height: unset;
    height: 100vh;
  }
`;

export const StyledRightContainer = styled.div`
  display: flex;
  min-height: 100vh;

  /* height: 100vh; */

  padding: 38px;
  width: 45%;
  background-color: white;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 11;
  @media screen and (max-width: 991px) {
    width: 100%;
    padding: 14px;

    min-height: unset;
    height: auto;
  }
`;

export const StyledFormItem = styled.div`
  .ant-form-item {
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 470px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
`;

export const StyledLeftContainer = styled.div`
  display: flex;
  position: relative;

  /* height: 100vh; */

  min-height: 100vh;

  @media screen and (min-width: 960px) and (max-width: 5000px) {
    width: 55%;
    /* min-height: 100vh; */
  }
  @media screen and (max-width: 991px) {
    width: 100%;

    min-height: 333px;
    height: 333px;

    /* min-height: unset;
    height: 100vh; */
  }
`;

export const StyledBg = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-repeat: no-repeat;

  @media screen and (min-width: 960px) and (max-width: 5000px) {
    /* height: 100vh; */
    /* min-height: calc(${({ minH }) => minH + (minH * 5) / 100}px);  */
    min-height: 920px;
  }
  background-position: center;

  background-size: cover;

  background-color: ${(props) => props.theme.colors.primary};
  box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}80;
  background-position: initial;
  animation: fadeIn 5s;
  -webkit-animation: fadeIn 5s;
  -moz-animation: fadeIn 5s;
  -o-animation: fadeIn 5s;
  -ms-animation: fadeIn 5s;
  @keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 991px) {
    /* height: auto; */
    height: 100vh;
    min-height: unset;
  }
`;

export const StyledLoginSignButtonWrapper = styled.div`
  position: absolute;
  top: 161px;
  right: -130px;
  left: unset;
  display: flex;
  transform: rotate(-90deg);
  .ant-btn-tab {
    margin: 0 10px;
    height: 68px;
    width: 140px;
    z-index: 10;
    background-color: #ededed;
    background: #ededed;
    border-radius: 10px 10px 0px 0px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #747487;
    &.active {
      background: #ffffff;
      color: ${(props) => props.theme.colors.primary};
    }
    @media screen and (max-width: 991px) {
      margin-right: 20px;
      width: 140px;
      height: 58px;
    }
  }

  @media screen and (max-width: 991px) {
    left: 50%;
    transform: rotate(0deg);
    bottom: 0;
    top: unset;
    right: unset;
    transform: translate(-50%, 0);
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 48px;
  background: ${(props) => (props.btnColor ? props.btnColor : "#F8C319")};
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: white;
`;
export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 474px;
  max-width: 100%;
`;

export const StyledForms = styled.div`
  padding-top: 32px;

  .ant-form-item {
    margin-bottom: 14px;
  }

  .ant-input-affix-wrapper {
    min-height: 48px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .ant-input {
    background-color: transparent;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }
  @media screen and (max-width: 991px) {
    padding-top: 0px;
  }
`;
export const StyledFormsLabel = styled.div`
  width: max-content;
  line-height: 34px;
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 14px;
  border-bottom: 1px solid #000;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;
export const StyledLogoLabel = styled.div`
  display: inline-block;
  width: 100%;
  /* height: 100%; */
  /* padding-top: calc((100vh / 2) - 60px); */

  img {
    width: 100%;
    padding: 20% 20%;
    padding-top: 105px;
  }

  @media screen and (max-width: 991px) {
    img {
      padding-top: 55px;
    }
  }

  @media screen and (max-width: 767px) {
    img {
      padding-top: 75px;
    }
  }

  @media screen and (max-width: 575px) {
    img {
      padding-top: 90px;
    }
  }
`;
export const StyledLink = styled.div`
  font-size: 14px;
  color: #747487;
  margin-left: 4px;
  font-weight: 600;
`;

export const StyledForgotPassword = styled.ul`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
`;
