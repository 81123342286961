import React from "react";

const earth = (props) => {
  return (
    <svg
      width="10"
      height="10"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128.5 130.9"
      {...props}
    >
      <g>
        <path
          class="st0"
          d="M18.7,20.2C31.1,7.8,46.2,1.6,63.9,1.6c17.7,0,32.8,6.2,45.2,18.7c12.4,12.5,18.7,27.5,18.7,45.2
            c0,17.7-6.2,32.8-18.7,45.2c-12.5,12.4-27.5,18.7-45.2,18.7c-17.7,0-32.8-6.2-45.2-18.7C6.2,98.2,0,83.1,0,65.4
            C0,47.8,6.2,32.7,18.7,20.2z M33.5,37.9c0,0.9,0.3,1.5,0.9,2.1c0.6,0.5,1.2,0.8,1.9,0.8h4.1c0.9,0,1.5-0.3,2.1-0.8l2.6-2.3
            c0.5-0.7,0.8-1.4,0.8-2.1v-4.1c0-0.9-0.3-1.6-0.9-2.2c-0.6-0.6-1.3-0.9-2.1-0.9c-0.8,0-1.4,0.3-1.9,1l-6.7,6.4
            C33.7,36.3,33.5,37,33.5,37.9z M66.5,117c9.1-0.5,17.4-3.2,24.9-8c7.5-4.8,13.3-11.1,17.6-18.8h-3.3c-1.4,0-2.5-0.4-3.3-1.3
            l-4.6-4.4c-1.5-1.7-3.4-2.6-5.7-2.6h-4.9L76,72.4c-2.1-1.9-4.6-2.8-7.5-2.8h-8.2c-2.1,0-4,0.6-5.9,1.8l-11.1,6.7
            c-3.8,2.2-5.7,5.6-5.7,10v6.2c0,3.8,1.5,6.9,4.6,9.3l5.7,4.4c2.7,1.9,5.8,2.8,9.3,2.8h5.2c1.2,0,2.2,0.4,3,1.3
            c0.8,0.9,1.2,1.8,1.2,2.8V117z M115.4,65.4c0-14.3-5-26.4-15.1-36.4c-10-10-22.2-15.1-36.4-15.1c-0.2,0-0.5,0-1,0
            c-0.2,0-0.3,0-0.5,0l-7.5,5.7c-0.5,0.3-0.8,0.9-0.8,1.5v5.2c0,1.4,0.7,2.1,2.1,2.1h4.1c1.4,0,2.1-0.7,2.1-2.1v-2.1l4.1-4.1h5.4
            c0.7,0,1.3,0.3,1.9,0.9c0.6,0.6,0.9,1.3,0.9,2.1s-0.3,1.5-0.8,2.2L67,32c-0.3,0.3-0.7,0.6-1,0.8l-10.3,3.3c-1,0.3-1.5,1-1.5,2.1
            c0,1.7-0.6,3.3-1.8,4.6l-5.2,5.2c-0.9,0.7-1.3,1.6-1.3,2.8v6.4c0,1.2,0.4,2.2,1.2,3s1.8,1.2,3,1.2h5.7c1.7,0,2.9-0.7,3.6-2.1
            l2.6-4.9c0.3-0.9,0.9-1.3,1.8-1.3h0.8c1.4,0,2.1,0.7,2.1,2.1c0,1.4,0.7,2.1,2.1,2.1h4.1c1.4,0,2.1-0.7,2.1-2.1v-0.5
            c0-1,0.5-1.6,1.5-1.8l8-2.8c1.9-0.7,2.8-2,2.8-3.9v-1.3c0-1,0.4-2,1.2-2.8c0.8-0.9,1.8-1.3,3-1.3h9.5c0.7,0,1.3,0.3,1.9,0.9
            c0.6,0.6,0.9,1.3,0.9,2.2v2.3c0,0.9-0.3,1.5-0.9,2.1c-0.6,0.5-1.2,0.8-1.9,0.8h-8.2c-0.9,0-1.5,0.3-2.1,1l-2.6,2.3
            c-0.5,0.5-0.8,1.2-0.8,2.1c0,0.9,0.3,1.5,0.9,2.1c0.6,0.5,1.2,0.8,1.9,0.8H94c0.9,0,1.5,0.3,2.1,1l2.6,2.3c0.5,0.5,0.8,1.2,0.8,2.1
            v2.3L96.3,68c-1.5,1.5-1.5,3,0,4.4l8.2,8.5c0.7,0.7,1.6,1,2.8,1h5.2C114.5,76.6,115.4,71.1,115.4,65.4z"
          fill="#676766"
        />
      </g>
    </svg>
  );
};

export default earth;
