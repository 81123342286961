import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SearchBar } from "../../../ui/SearchBar";
import { CommentBar } from "../../../ui/CommentBar";
import { UserPost } from "./LeftCards";
import TeamAPI from "api/http/team";
import { message, Spin } from "antd";
import { paginationObj, querystring, _merge } from "utils/fn";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Flex } from "ui/Layout/Flex";
import useSocket from "utils/hooks/useSocket";
import config from "helpers/config";
import { StickyMessage } from "ui/StickyMessage/index";
import { useStickyMessage } from "utils/hooks/useStickyMessage";
import { useBeforeunload } from "react-beforeunload";
import { SocketContext } from "utils/context/socket";

export const TeamLeft = ({ teamId, ...props }) => {
  const team = useMemo(() => teamId, [teamId]);
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const userId = React.useMemo(() => localStorage.getItem("userId"), []);
  const [admins, setAdmins] = React.useState([]);

  const defaultPagination = useMemo(
    () => ({
      current: 1,
      pageSize: 5,
      total: 0,
      filter: {},
      populate: [
        {
          path: "user",
          select: ["firstName", "lastName", "avatar"],
        },
        {
          path: "totalComments",
        },
        {
          path: "isLiked",
          match: { likers: user?._id || localStorage.getItem("userId") },
        },
      ],
      sorter: {
        order: "descend",
        field: "_id",
      },
    }),
    [user?._id]
  );
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 2,
    total: 0,
    filter: {},
    populate: [
      {
        path: "user",
        select: ["firstName", "lastName", "avatar"],
      },
      {
        path: "totalComments",
      },
      // {
      //   path: "comments",
      //   limit: 3,
      //   populate: [
      //     {
      //       path: "isLiked",
      //       match: { likers: user?._id || localStorage.getItem("userId") },
      //     },
      //     {
      //       path: "post",
      //       select: ["team"],
      //       // populate: [
      //       //   {
      //       //     path: "team",
      //       //     select: ["admins"],
      //       //   },
      //       // ],
      //     },
      //   ],
      // },
      {
        path: "isLiked",
        match: { likers: user?._id || localStorage.getItem("userId") },
      },
    ],
    sorter: {
      order: "descend",
      field: "_id",
    },
  });

  const socket = useContext(SocketContext);

  const {
    isActive,
    message: stickyMessage,
    isAutoHide,
    showStickyMessage,
    close,
  } = useStickyMessage();

  const getPostList = useCallback((id, pagination, cb, merge = true) => {
    TeamAPI.getPostList(id, querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData((d) => (merge ? _merge(d, data?.data, "_id") : data?.data));

        setPagination((p) => ({ ...p, total: data?.total }));
        if (typeof cb === "function") cb(null);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  }, []);

  const updatePostdata = useCallback(
    (post, isCreate = false, isDelete = false) => {
      setData((dt) => {
        const idx = dt.findIndex((i) => i._id === post._id);
        const d = [...dt];
        if (isDelete) {
          return d.filter((dd) => dd._id !== post._id);
        }
        if (idx > -1) {
          d[idx]["text"] = post?.text;
          d[idx]["image"] = post?.image;
          d[idx]["likers"] = post?.likers;
          d[idx]["isLiked"] = (post?.likers || []).includes(user?._id || userId)
            ? 1
            : 0;
        } else {
          if (isCreate) {
            d[d.length] = post;
          }
        }
        return d;
      });
    },
    [user?._id, userId]
  );

  useEffect(() => {
    if (socket && socket?.disconnected) {
      socket.connect();
    }
    if (socket) {
      socket.emit("join", team);
      socket.on("post-updated", ({ action, data }) => {
        switch (action) {
          case "patch":
          case "update":
            updatePostdata(data);
            refreshPost();
            break;
          case "create":
            showStickyMessage("New post added. Click to refresh");
            break;
          case "remove":
            updatePostdata(data, false, true);
            refreshPost();
            break;

          default:
            break;
        }
      });
      socket.on("reconnect", () => {
        socket.emit("join", team);
      });
      return () => {
        socket.emit("leave", team);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (team) getPostList(team, defaultPagination, null, false);
    setPagination(defaultPagination);
  }, [defaultPagination, getPostList, team]);

  const searchHandler = (v) => {
    console.log(v);

    TeamAPI.getPostList(
      team,
      querystring(
        paginationObj({
          ...pagination,
          current: 1,
          filter:
            v && v.length > 0
              ? { text: { $regex: `${v}`, $options: "igm" } }
              : {},
        })
      )
    ).then(({ data }) => {
      setData(data?.data);
    });
    setPagination((p) => ({ ...p, current: 1 }));

    // getUserList({
    //   ...pagination,

    //   filter:
    //     searchTerm && searchTerm.length > 0
    //       ? { firstName: { $regex: `${searchTerm}`, $options: "igm" } }
    //       : {},
    // })
  };

  useEffect(() => {
    TeamAPI.getTeam(team).then((data) => {
      setAdmins(data?.data?.admins);
    });
  }, [team]);
  const onPostCreateHandler = (data, cb) => {
    console.log("dataaaaa", data);

    if (data?.text?.length > 5000) {
      getPostList(team, defaultPagination, cb, false);
      close();
      return message.error("Text too long");
    } else {
      TeamAPI.createPost(team, data)
        .then(() => {
          message.success("Post created");
          getPostList(team, defaultPagination, cb, false);
          setPagination(defaultPagination);
          close();
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
          if (typeof cb === "function") cb(error.message);
        });
    }
  };

  const updatePostLike = (postId) => {
    const updatedCommentIdx = data.findIndex((d) => d._id === postId);
    const uData = [...data];
    uData[updatedCommentIdx]["likers"] = uData[updatedCommentIdx]["isLiked"]
      ? uData[updatedCommentIdx]["likers"].filter((l) => l !== user?._id)
      : [...new Set([...uData[updatedCommentIdx]["likers"], user?._id])];
    uData[updatedCommentIdx]["isLiked"] = uData[updatedCommentIdx]["isLiked"]
      ? 0
      : 1;
    setData(uData);
  };

  const updatePostData = (postId, postData) => {
    const updatedCommentIdx = data.findIndex((d) => d._id === postId);
    const uData = [...data];
    uData[updatedCommentIdx]["image"] = postData?.image;
    uData[updatedCommentIdx]["text"] = postData?.text;
    setData(uData);
  };

  const postAction = (postId, isLike) => {
    TeamAPI.updatePost(
      team,
      postId,
      {
        [isLike ? "$addToSet" : "$pull"]: {
          likers: user?._id || localStorage.getItem("userId"),
        },
      },
      false
    )
      .then(() => {
        refreshPost();
        // updatePostLike(postId);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const updatePostAction = (
    postId,
    data,
    cb,
    isJson = false,
    showMessage = true
  ) => {
    TeamAPI.updatePost(team, postId, data, !isJson)
      .then(({ data: resData }) => {
        if (showMessage) {
          message.success("Post updated successfully");
        }
        updatePostData(postId, resData);
        if (typeof cb === "function") {
          cb(null, { team, postId, data });
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        if (typeof cb === "function") cb(error.message);
      });
  };

  const commentAction = (postId, { _id: commentId, isLiked }, cb) => {
    TeamAPI.updateComment(
      postId,
      commentId,
      {
        [!isLiked ? "$addToSet" : "$pull"]: {
          likers: user?._id || localStorage.getItem("userId"),
        },
      },
      false
    )
      .then(() => {
        // getPostList(team, pagination);
        if (typeof cb === "function") {
          cb(null, { team, postId, comment: commentId });
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  // const getCommentList = useCallback((id, pagination) => {
  //   TeamAPI.getCommentList(id, querystring(paginationObj(pagination)))
  //     .then((resp) => {
  //       const currentPost = data.find((p) => p._id === id);
  //       const currentPostIndex = data.findIndex((p) => p._id === id);
  //       currentPost["pagination"] = {
  //         ...(currentPost?.pagination || {}),
  //         pageSize: 10,
  //         total: resp.data.total,
  //         current: (currentPost?.pagination?.current || 1) + 1,
  //         populate: [
  //           {
  //             path: "isLiked",
  //             match: { likers: user?._id || localStorage.getItem("userId") },
  //           },
  //         ],
  //       };
  //       const commentData = [...currentPost.comments, ...resp.data.data];
  //       const udata = [...data];
  //       udata[currentPostIndex] = {
  //         ...currentPost,
  //         comments: commentData,
  //       };

  //       setData(udata);
  //       // setCommentPagination((p) => ({ ...p, total: data?.total }));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       message.error(error.message);
  //     });
  // }, []);

  // const createComment = (postId, payload) => {
  //   const currentPost = data.find((p) => p._id === postId);
  //   currentPost["pagination"] = {
  //     ...(currentPost?.pagination || {}),
  //     pageSize: 10,
  //     current: (currentPost?.pagination?.current || 0) + 1,
  //   };

  //   TeamAPI.createComment(postId, payload)
  //     .then(() => {
  //       getCommentList(
  //         postId,
  //         currentPost?.pagination || {
  //           ...(currentPost?.pagination || {}),
  //           pageSize: 10,
  //           current: (currentPost?.pagination?.current || 0) + 1,
  //           populate: [
  //             {
  //               path: "isLiked",
  //               match: { likers: user?._id || localStorage.getItem("userId") },
  //             },
  //           ],
  //         }
  //       );
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       message.error(error.message);
  //     });
  // };

  const refreshPost = (cb) => {
    getPostList(team, pagination, cb);
  };

  const deletePostAction = (post, _id, cb) => {
    TeamAPI.deletePost(teamId, _id, post, {
      id: _id,
    })
      .then(() => {
        message.success("Post deleted");
        // refreshPost();
        // getPostList(team, defaultPagination, cb, false);
        // setPagination((p) => ({ ...p, current: 1 }));
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const userPostActions = (
    { event, props },
    cb,
    isJson = false,
    showMessage = true
  ) => {
    switch (event) {
      case "+like":
        postAction(props._id, true);
        break;
      case "-like":
        postAction(props._id, false);
        break;
      case "comment-like":
        commentAction(props._id, props.comment, cb);
        break;
      case "refreshPost":
        refreshPost(cb);
        break;
      case "delete":
        deletePostAction(props.val, props._id);
        break;
      case "update":
        updatePostAction(props._id, props.post, cb, isJson, showMessage);
        break;
      // case "comment":
      //   createComment(props._id, {
      //     comment: props.comment,
      //     image: props.image,
      //   });
      //   break;
      default:
        break;
    }
  };

  const fetchMorePostData = (props) => {
    console.log(props);
    getPostList(
      team,
      { ...pagination, current: pagination.current + 1 },
      null,
      true
    );
    setPagination((p) => ({ ...p, current: p.current + 0.5 }));
  };
  // const [loadValue,setLoadValue] =useState(false);

  //   const pageLoad = (val) => {
  //     if (val.length > 1) {
  //       setLoadValue(true)
  //     }
  //   };
  //   useBeforeunload((event) => {
  //     if (loadValue) {
  //       event.preventDefault();
  //     }
  //   });
  const [isFormChange, setIsFormChange] = React.useState(false);

  function pageLoad() {
    setIsFormChange(true);
  }
  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  return (
    <>
      <StickyMessage
        isActive={isActive}
        message={stickyMessage}
        isAutoHide={isAutoHide}
        onClick={() => {
          close();
          getPostList(team, defaultPagination, null, false);
          setPagination(defaultPagination);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      <SearchBar
        placeholder="Search..."
        onChange={(value) => searchHandler(value)}
      />

      <CommentBar
        placeholder="Write a comment..."
        onSubmit={onPostCreateHandler}
        team={team}
        // onFormChange={pageLoad}
      />
      {data.map((item) => (
        <UserPost
          key={item._id}
          teamOfId={team}
          adminUsers={admins}
          postUserId={item?.user?._id}
          {...item}
          actions={userPostActions}
        />
      ))}
      <InfiniteScroll
        dataLength={data.length}
        next={fetchMorePostData}
        scrollThreshold={0.7}
        hasMore={pagination.total > pagination.current * pagination.pageSize}
        loader={
          <Flex justifyContent="center">
            <Spin />
          </Flex>
        }
        endMessage={
          <p
            style={{ textAlign: "center", fontSize: "14px", color: "#747487" }}
          >
            <span>
              {data.length > 0
                ? "Yay! You have all caught up!"
                : "No posts. Share something to kick off the conversation!"}
            </span>
          </p>
        }
      >
        {/* {data.map((item) => (
          <UserPost
            key={item._id}
            teamOfId={team}
            {...item}
            actions={userPostActions}
          />
        ))} */}
      </InfiniteScroll>
    </>
  );
};
