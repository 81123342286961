import React, { useState, useEffect, useMemo } from "react";
import { Heading } from "../../ui/Heading";
import { GiTwoCoins } from "react-icons/gi";
import { useHistory } from "react-router-dom";
import { UserAvatar } from "ui/Avatar";

import {
  StyledUserInfo,
  StyledUploadAvtarWrapper,
  StyledUserProfileCard,
  StyledUserInfoWrapper,
  StyledUserDetailsWrapper,
  JoinMyTeamUserButton,
  StyledUserDetails,
  EditProfileButton,
} from "./style";

import { getInitials, imagePath } from "utils/fn";
import { adminRoutes } from "helpers/adminRoutes";
import { useTheme } from "styled-components";

const UserProfileDetails = ({ data, userId }) => {
  //   const [state, setState] = useState(UserProfileData);
  const user = useMemo(() => data, [data]);
  const [state, setState] = useState({
    image: "",
    image_src: "",
  });

  const history = useHistory();

  const theme = useTheme();
  useEffect(() => {
    setState((s) => ({
      ...s,
      image_src: user?.avatar?.path ? imagePath(user?.avatar?.path) : "",
    }));
  }, [user]);

  const handleEdit = (item) => {
    history.push(adminRoutes.PROFILE_EDIT);
    history.push(adminRoutes.PROFILE_EDIT.replace(":id", user._id));
  };
  const avatarProps = {
    size: 137,
    ...(state?.image_src
      ? { src: state?.image_src }
      : {
          fullName: `${user?.firstName || ""} ${user?.lastName || ""}`,
        }),
  };
  return (
    <>
      <Heading fontSize={"34px"} color={"#232333"} marginBottom={"105px"}>
        Profile
      </Heading>

      <StyledUserProfileCard>
        <StyledUploadAvtarWrapper size={137}>
          {/* <Avatar {...avatarProps} /> */}
          <UserAvatar {...avatarProps} />
        </StyledUploadAvtarWrapper>

        <StyledUserInfoWrapper>
          <StyledUserInfo>
            <h5>
              {`${user?.firstName} ${user?.lastName} `}
              <span style={{ color: theme.colors.primary }}>
                [{user?.totalEarnedPoints || 0} <GiTwoCoins />]
              </span>
            </h5>
          </StyledUserInfo>
          {!userId && (
            <EditProfileButton onClick={handleEdit}>Edit</EditProfileButton>
          )}
        </StyledUserInfoWrapper>
        {user?.joinLink && (
          <JoinMyTeamUserButton>
            <a
              href={user?.joinLink || "/profile"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Join My Community
            </a>
          </JoinMyTeamUserButton>
        )}
        <StyledUserDetailsWrapper>
          <StyledUserDetails>
            <h4>Email</h4>
            <span> {user?.email}</span>
          </StyledUserDetails>
          <StyledUserDetails>
            <h4>Location</h4>
            <span> {user?.location?.address || ""} </span>
          </StyledUserDetails>
        </StyledUserDetailsWrapper>
      </StyledUserProfileCard>
    </>
  );
};

export default UserProfileDetails;
