import { Breadcrumb } from "antd";
import React, { useState, useEffect } from "react";
import { HiPlus } from "react-icons/hi";
import { FaLongArrowAltUp, FaLongArrowAltDown } from "react-icons/fa";
import { Tabs, Select, Button, message, Row, Col } from "antd";
import { Heading } from "../../ui/Heading";
import { Container } from "../../ui/Layout/Container";
import { HiOutlineChevronDown } from "react-icons/hi";
import EventSection from "./EventsSections/EventSection";
import { tabs, data } from "./data";

import {
  AddNewRelation,
  StyleLdSortingWrapper,
  StyledSortingIconWrapper,
  StyleLayout,
  StyleBreadCrumbsWrapper,
  FloatButton,
  StyledTabButtonWrap,
  StyledItemPageSelect,
} from "../../Components/Relationship/TheRelationship.styles";
import { ItemPerPageContainer } from "ui/Datatable/datatable.styles";
import ItemPerPageSelect from "ui/Datatable/ItemPerPageSelect";
import RelationshipTabAPI from "api/http/relationshipTab";
import { SecondaryButton } from "../../ui/Button";
import { joinGroup, leftGroup, subscribeSocketEvent } from "utils/socket";
import { joinAndSubscribe } from "utils/socket/index";
import useSocket from "utils/hooks/useSocket";
import config from "helpers/config";
import { randomNumber } from "utils/fn";
import { Tab } from "ui/Tab/index";
import { UserProfileEventData } from "Components/FindEvents/EventsData";

const { TabPane } = Tabs;
const { Option } = Select;

const Relationship = (props) => {
  const [state, setState] = useState({
    dispalyMenu: false,
    showActivity: false,
    showMove: false,
    showContact: false,
    showDash: true,
  });
  const [tabstates, setTabStates] = useState({ key: "Upcoming" });
  const [tabData, setTabData] = useState();

  const [loading, setLoading] = useState(false);

  const [pageSize, setPageSize] = useState(50);

  function callback(key) {
    setTabStates({ key: key });
  }

  const OperationsSlot = {
    left: (
      <AddNewRelation>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
        />
      </AddNewRelation>
    ),
  };

  function callback(key) {
    setTabStates({ key: key });
  }

  useEffect(() => {
    setTabStates({ key: "Upcoming" });
  }, []);

  /** -- Socket Config -- */

  return (
    <>
      <StyleLayout>
        <Container minH="100%" padding="17px 30px 0">
          <Heading>My Events</Heading>
        </Container>
        {/* <StyledTabButtonWrap> */}
        <Tab
          tabBarExtraContent={OperationsSlot}
          onChange={callback}
          activeKey={tabstates.key}
          tabPanes={tabs}
          // mb={55}
        />
        {/* {tabData &&
              tabData.length > 0 &&
              tabData.map((ks, index) => (
                <TabPane tab={ks.type} key={ks.type}></TabPane>
              ))} */}

        {/* </Tab> */}
        {/* </StyledTabButtonWrap> */}
        <FloatButton>
          <SecondaryButton
            shape="circle"
            icon={<HiPlus className="plus_icon" />}
          />
        </FloatButton>
        <Container>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <EventSection
                tabKey={tabstates.key}
                UserProfileEventData={UserProfileEventData}
                // loading={loading}
              />
            </Col>
          </Row>
        </Container>
      </StyleLayout>
    </>
  );
};

export default Relationship;
