import { httpAPI } from "api";

const VideoStatisticsAPI = {
  addVideoStatistics(params) {
    return httpAPI(`/video-statistics`, params, "post");
  },

  getVideoStatisticsList(query) {
    return httpAPI(`/video-statistics?${query || ""}`, "", "get");
  },
  getVideoStatisticsListDetail(id) {
    return httpAPI(`/video-statistics/${id}`, "", "get");
  },
  updateVideoStatistics(id, params) {
    return httpAPI(`/video-statistics/${id}`, params, "patch");
  },
  deleteVideoStatistics(id, params) {
    return httpAPI(`/video-statistics/${id}`, params, "delete");
  },
};

export default VideoStatisticsAPI;
