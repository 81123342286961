import { Col, Form, message, Row } from "antd";
import EventAPI from "api/http/events";
import TypeAPI from "api/http/type";
import UserAPI from "api/http/user";
import { settingsRoutes } from "helpers/adminRoutes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { PrimaryButton } from "ui/Button";
import { Card, CardTitle } from "ui/Card";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import {
  FileUpload,
  FormDatePicker,
  FormInput,
  FormItem,
  FormSelect,
  FormTextArea,
  FormTimePicker,
} from "ui/Form";
import { FormSelectSearch } from "ui/Form/index";
import { Container } from "ui/Layout/Container";
import {
  getDynamicMenuString,
  getImageBase64,
  imagePath,
  paginationObj,
  querystring,
  searchObj,
} from "utils/fn";
import moment from "moment";
import { useSelector } from "react-redux";

const AddEvent = () => {
  const params = useParams();
  const history = useHistory();
  const [state, setState] = useState({
    image: "",
    image_src: "",
  });
  const [organizers, setOrganizers] = useState([]);
  const [types, setTypes] = useState([]);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [form] = Form.useForm();
  const { configData } = useSelector((state) => state.configData);

  const eventId = useMemo(() => params?.id, [params?.id]);
  const createOptions = (data = []) => {
    return data.map((u) => ({
      avatar: u?.avatar
        ? { src: imagePath(u?.avatar?.path) }
        : { fullName: `${u?.firstName || ""} ${u?.lastName || ""}` },
      label: `${u.firstName} ${u.lastName} `,
      value: u._id,
    }));
  };
  const getUserList = useCallback(({ filter = {} }) => {
    return UserAPI.getUserList(querystring(paginationObj({ filter })))
      .then(({ data }) => {
        setOrganizers(createOptions(data.data));
        return createOptions(data.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        return [];
      });
  }, []);
  const getTypeList = useCallback(({ filter = {} }) => {
    return TypeAPI.getTypeList(querystring(paginationObj({ filter })))
      .then(({ data }) => {
        const options = (data.data || []).map((d) => ({
          label: d.name,
          value: d._id,
        }));
        setTypes(options);
        return options;
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        return [];
      });
  }, []);
  const getEvent = useCallback((id) => {
    EventAPI.getEvent(id)
      .then(({ data }) => {
        form.setFieldsValue({
          ...data,
          "location.address": data?.location?.address,
          startDate: moment(data.startDate),
          endDate: moment(data.endDate),
          startTime: moment(data.startTime, "HH:mm:ss"),
          endTime: moment(data.endTime, "HH:mm:ss"),
        });

        if (data.image) {
          setState((s) => ({
            ...s,
            image: {
              ...data.image,
              name: data.image?.originalName,
              url: imagePath(data?.image?.url || data.image.path),
            },
            image_src: imagePath(data.image.path),
          }));
        }
        getUserList({ filter: { $or: [{ _id: { $in: data.organizers } }] } });
        getTypeList({ filter: { $or: [{ _id: { $in: data.types } }] } });
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eventId) {
      getEvent(eventId);
    } else {
      getTypeList({});
      getUserList({});
    }
  }, [getEvent, eventId, getTypeList, getUserList]);

  const eventPhoto = {
    name: "image",
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
        return false;
      }
      getImageBase64(file, (base64) =>
        setState((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setState({
        image: "",
        image_src: "",
      });
    },
    fileList: state.image ? [state.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleSubmit = (data) => {
    setShowPrompt(false);
    console.log({ data });
    const payload = {
      ...data,
      image: state.image?.url ? "" : state?.image,
      startDate: data.startDate.format("YYYY-MM-DD"),
      endDate: data.endDate.format("YYYY-MM-DD"),
      startTime: data.startTime.format("HH:mm:ss"),
      endTime: data.endTime.format("HH:mm:ss"),
    };
    console.log({ payload });

    if (eventId) {
      EventAPI.updateEvent(eventId, payload)
        .then(() => {
          message.success("Event updated successfully");
          history.push(settingsRoutes.EVENT);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    } else {
      EventAPI.addEvent(payload)
        .then(() => {
          message.success("Event added successfully");

          history.push(settingsRoutes.EVENT);
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    }
  };

  const searchUsers = (search) => {
    const filter = searchObj(["email", "firstName", "lastName"], search);
    return getUserList({ filter });
  };
  const searchTypes = (search) => {
    const filter = searchObj(["name"], search);
    return getTypeList({ filter });
  };

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  return (
    <Container>
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Card padding="25px 30px 15px">
        <CardTitle containerStyle={{ padding: "10px 0 30px 0" }}>
          {params.id
            ? "Edit " +
              getDynamicMenuString(configData?.[0], ["focus", "my-events"])
            : getDynamicMenuString(configData?.[0], ["focus", "my-events"])}
        </CardTitle>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
          form={form}
          initialValues={{
            visibility: "public",
          }}
          onValuesChange={() => {
            setShowPrompt(true);
          }}
        >
          <FormItem
            label="Event Name"
            name="name"
            type="text"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormInput placeholder="Enter event name" />
          </FormItem>
          <FormItem
            label="Organizer"
            name="organizers"
            type="text"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormSelectSearch
              placeholder="Enter Organizers name"
              mode="multiple"
              filterOption={false}
              defaultOptions={organizers}
              onSearch={searchUsers}
            />
          </FormItem>
          <FormItem
            label="Location"
            name="location.address"
            type="text"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormInput placeholder="Enter location" />
          </FormItem>

          <FormItem label="Event Photo" maxWidth={"822px"}>
            <FileUpload {...eventPhoto} />
          </FormItem>
          <FormItem
            label="Description"
            name="description"
            type="text"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormTextArea placeholder="Enter description text" />
          </FormItem>

          <Row gutter={30} style={{ maxWidth: "852px" }}>
            <Col sm={24} md={12} lg={12}>
              <FormItem
                label="Start Date"
                name="startDate"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormDatePicker placeholder="Select date" />
              </FormItem>
            </Col>
            <Col sm={24} md={12} lg={12}>
              <FormItem
                label="Start Time"
                name="startTime"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormTimePicker placeholder="Select time" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={30} style={{ maxWidth: "852px" }}>
            <Col style={{ width: "100%" }} sm={24} md={12} lg={12}>
              <FormItem
                label="End Date"
                name="endDate"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormDatePicker placeholder="Select date" />
              </FormItem>
            </Col>
            <Col style={{ width: "100%" }} sm={24} md={12} lg={12}>
              <FormItem
                label="End Time"
                name="endTime"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormTimePicker placeholder="Select time" />
              </FormItem>
            </Col>
          </Row>

          <FormItem
            label="Visibility"
            name="visibility"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormSelect
              options={[
                { label: "Publically available", value: "public" },
                { label: "Community only", value: "onlyTeam" },
                { label: "Only Me", value: "onlyMe" },
              ]}
            />
          </FormItem>
          <FormItem
            label="Event Type"
            name="types"
            type="text"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormSelectSearch
              placeholder="Enter Event type"
              mode="multiple"
              filterOption={false}
              defaultOptions={types}
              onSearch={searchTypes}
            />
          </FormItem>
          <FormItem>
            <PrimaryButton margin="15px 0 0 0" htmlType="submit">
              Save
            </PrimaryButton>
          </FormItem>
        </Form>
      </Card>
    </Container>
  );
};

export default AddEvent;
