import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAPI } from "api/index";
import { imagePath, querystring } from "utils/fn";
import { message } from "antd";
import { teamStatus } from "utils/constants";
import axios from "axios";

const initialState = {
  tabs:''
};

 

export const tabDatas = createAsyncThunk(
  "tabDatas",
  async (data, thunkAPI) => {
    try {
 
      const response = await httpAPI(`/relationshipTab`, data, "GET");

      const compare = (a, b) => {
        const bandA = b.sequence;
        const bandB = a.sequence;
  
        let comparison = 0;
        if (bandB > bandA) {
          comparison = 1;
        } else if (bandB < bandA) {
          comparison = -1;
        }
        return comparison;
      };
  
       response?.data?.sort(compare);
       return { ...response.data  };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const counterSlice = createSlice({
    name: "tabs",
    initialState,
   extraReducers: {
    [tabDatas.fulfilled]: (state, { payload }) => {
         state.tabs = payload;
       
      }
      
}
});
 
export default counterSlice.reducer;
 