import React, { useCallback, useState, useEffect } from "react";
import { FormInput, FormItem } from "ui/Form/Input";
import { FileUpload } from "ui/Form/FileUpload";
import { Container } from "ui/Layout/Container";
import { Card, CardTitle } from "ui/Card";
import { ToggleSwitch } from "ui/Form/ToggleSwitch";
import { HiPlus } from "react-icons/hi";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Menu,
  message,
  Dropdown,
  Checkbox,
  Col,
  Row,
  Image,
  Spin,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { DownOutlined, InboxOutlined } from "@ant-design/icons";
import { PrimaryButton } from "ui/Button/index";
import { FormSelect, FormSelectSearch, FormTextArea } from "ui/Form/index";
import {
  convertJSONToFormData,
  getImageBase64,
  imagePath,
  paginationObj,
  querystring,
  searchObj,
} from "utils/fn";
import GrowAPI from "api/http/grow";
import { adminRoutes, settingsRoutes } from "helpers/adminRoutes";
import { useParams } from "react-router";
import UserAPI from "api/http/user";
import { Heading } from "ui/Heading/Heading";
import { AddNewField, StyledImages } from "./style";
import VideoAPI from "api/http/videos";
import { useDispatch, useSelector } from "react-redux";
import FileUploadAPI from "api/http/fileupload";
import { Prompt } from "react-router";
import { useBeforeunload } from "react-beforeunload";
import { configDatas } from "store/reducers/configData.reducer";

const { Dragger } = Upload;

const AddVideo = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const config = JSON.parse(localStorage.getItem("config"));
  const [video, setVideo] = useState("");
  const [data, setData] = useState();
  const [state, setState] = useState();
  const [premiumContentToggle, setPremiumContentToggle] = useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);

  const [playlistToggle, setPlaylistToggle] = useState(false);
  const [rewardableToggle, setRewardableToggle] = useState(false);
  const [thumbnailImages, setThumbnailImages] = useState([]);
  const [playList, setPlayList] = useState([]);
  const [users, setUsers] = useState([]);
  const [thumbnail, setThumbnail] = useState("");
  const [oldFilename, setOldfileName] = useState("");
  const [stateVideoData, setStateVideoData] = useState("");
  const [searchUser, setsearchUser] = useState([]);
  const { user } = useSelector((state) => state.user);
  const { configData } = useSelector((state) => state.configData);

  const handleFinish = (values) => {
    setShowPrompt(false);
    setLoading(true);
    //   let fileName = stateVideoData?.filename.replace(/\.[^/.]+$/, "") + ".mp4";
    //  const formdata = convertJSONToFormData(values);
    const formdata = new FormData();
    if (params.id) {
      let id = values.uploadedBy;
      if (/\s/.test(id)) {
        // it's an ObjectID
        delete values.uploadedBy;
      } else {
        // nope
      }
    }
    Object.keys(values).forEach((k) => {
      if (values[k]) formdata.append(k, values[k]);
    });
    if (video?.video && !video.video?.url) {
      formdata.append("oldFilename", oldFilename);
      if (!state?.image) {
        formdata.append("thumbnailImages", thumbnailImages);
        formdata.append("thumbnail", thumbnailImages[0]);
      }
    }
    console.log("thumbnailImages", thumbnailImages);
    if (state?.image && !state?.image?.url) {
      formdata.append("customThumbnail", state?.image);
      formdata.append("thumbnailImages", thumbnailImages);
      formdata.append("thumbnail", thumbnailImages[0]);
    }
    //  else {
    //   formdata.append("thumbnailImages", thumbnailImages);
    //   formdata.append("thumbnail", thumbnailImages[0]);
    // }
    if (params.id) {
      // formdata.append("thumbnail", thumbnail);
      VideoAPI.updateVideo(params.id, formdata).then((data) => {
        console.log("data", data?.data);
        setLoading(false);
        history.push(adminRoutes.MANAGEVIDEO);
      });
    } else {
      formdata.append("numberOfVideos", 1);
      formdata.append("isPartOfPlaylist", false);
      formdata.append("current", true);
      GrowAPI.addGrow(formdata).then((data) => {
        console.log("data", data);
        formdata.append("playlist", data?.data?._id);
        formdata.append("allowCustom", false);
        VideoAPI.addVideo(formdata).then((data) => {
          setLoading(false);
          history.push(adminRoutes.MANAGEVIDEO);
        });
      });
    }
  };

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });
  const createOptions = (data = []) => {
    return data.map((u) => ({
      avatar: u?.avatar
        ? { src: imagePath(u?.avatar?.path) }
        : { fullName: `${u?.firstName || ""} ${u?.lastName || ""}` },
      label: `${u.firstName} ${u.lastName}`,
      value: u._id,
    }));
  };

  const getUserList = useCallback(({ filter = {} }) => {
    return UserAPI.getUserList(querystring(paginationObj({ filter })))
      .then(({ data }) => {
        setUsers(createOptions(data?.data));
        return createOptions(data?.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        return [];
      });
  }, []);
  const searchUsers = (search) => {
    const filter = searchObj(["email", "firstName", "lastName"], search);
    return getUserList({ filter });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  useEffect(() => {
    if (user && user?._id) {
      form.setFieldsValue({
        uploadedBy: [user?._id || localStorage.getItem("userId")],
      });
    }
  }, []);

  useEffect(() => {
    console.log("useEffect");
    const formdata = new FormData();

    if (video?.video && !video.video?.url) {
      setShowPrompt(true);

      formdata.append("video", video?.video);
      FileUploadAPI.addFile(formdata).then((data) => {
        if (data?.data) {
          console.log("data?.data?.video", data?.data?.video);
          setStateVideoData(data?.data?.video);
          setTimeout(() => {
            setOldfileName(data?.data.video.filename);
            setThumbnailImages([
              `/uploads/frames/${data?.data.video.filename}`.replace(
                /(\.[\w\d_-]+)$/i,
                "_1.jpg"
              ),
            ]);
            setLoadings(false);
          }, 2000);
          // FileUploadAPI.getVideoDetail(data?.data?._id).then((data) => {

          // });
        }
      });
    }
  }, [video]);

  useEffect(() => {
    setShowPrompt(false);

    if (params.id) {
      VideoAPI.getVideoDetail(
        params.id,
        querystring(paginationObj({ populate: [{ path: "uploadedBy" }] }))
      )
        .then(({ data }) => {
          form.setFieldsValue({
            name: data.name,
            description: data.description,
            availability: data.availability,
            // uploadedBy:
            //   data.uploadedBy?.firstName + " " + data.uploadedBy?.lastName,
            isPartOfPlaylist: data.isPartOfPlaylist,
            allowCustom: data?.allowCustom,
            visibility: data?.visibility,
          });

          getUserList({ filter: { $or: [{ _id: { $in: data.uploadedBy } }] } });

          setData(data);
          // if (data?.customThumbnail) {
          //   setState((s) => ({
          //     ...s,
          //     image: {
          //       ...data.customThumbnail,
          //       name: data.customThumbnail?.originalName,
          //       url: imagePath(data.customThumbnail.path),
          //     },
          //     image_src: imagePath(data.customThumbnail.path),
          //   }));
          // }
          setThumbnail(data?.thumbnail);
          setThumbnailImages(data?.thumbnailImages);
          setRewardableToggle(data.rewardable);
          setPremiumContentToggle(data.premiumContent);
          setPlaylistToggle(data.isPartOfPlaylist);
          if (data?.video) {
            setVideo((s) => ({
              ...s,
              video: {
                ...data.video,
                name: data.video?.originalName,
                url: imagePath(data.video.path),
              },
              video_src: imagePath(data.video.path),
            }));
          }
        })
        .catch((error) => {
          if (error) {
            message.error();
          }
        });
    } else {
      getUserList({
        filter: {
          $or: [{ _id: { $in: user?._id || localStorage.getItem("userId") } }],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const VideoUpload = {
    name: "video",
    multiple: false,
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isVideo = /video\/.+/gi.test(file.type);
      if (!isVideo) {
        message.error("You can only upload video file");
        return false;
      }
      setLoadings(true);
      // const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isLt2M) {
      //   message.error("Video must smaller than 10MB!");
      //   return false;
      // }
      getBase64(file, (base64) =>
        setVideo((s) => ({
          ...s,
          video_src: base64,
          video: file,
        }))
      );

      return false;
    },
    onRemove: (file) => {
      setVideo({
        video: "",
        video_src: "",
      });
    },
    fileList: video?.video ? [video?.video] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const thumbnailImageUpload = {
    name: "customThumbnail",
    multiple: false,
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      // const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isLt2M) {
      //   message.error("Video must smaller than 10MB!");
      //   return false;
      // }
      getImageBase64(file, (base64) =>
        setState((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setState({
        image: "",
        image_src: "",
      });
    },
    fileList: state?.image ? [state?.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleRewardableToggle = (values) => {
    setRewardableToggle(values);
  };

  const handleplaylistToggle = (values) => {
    setPlaylistToggle(values);
  };

  const imagePath = (url) => {
    return `${config?.apiUrl}${url}`;
  };

  return (
    <Container>
      {/* {dataChange === true && defaulState === false && (
          <Beforeunload
            onBeforeunload={() =>
              "You will lose all changes if you navigate away from this page without saving"
            }
          />
        )} */}
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Heading>{configData?.[0].module?.[2]?.children?.[1]?.name}</Heading>
      <Card padding="10px 15px 15px">
        <Row>
          <Col span={16}>
            <Form
              layout="vertical"
              onFinish={(e) => handleFinish(e)}
              requiredMark={false}
              onValuesChange={() => {
                setShowPrompt(true);
              }}
              form={form}
              // initialValues={{ e }}
            >
              <FormItem
                label="Video Name"
                name="name"
                type="text"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormInput placeholder="Enter video name" />
              </FormItem>
              <FormItem
                label="Description"
                name="description"
                type="text"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <FormTextArea placeholder="Enter description text" />
              </FormItem>
              <FormItem label="Uploaded By" name="uploadedBy">
                <FormSelectSearch
                  placeholder="Select Users"
                  mode="multiple"
                  filterOption={false}
                  defaultOptions={users}
                  onSearch={searchUsers}
                />
              </FormItem>
              <FormItem label="Visibility" name="visibility" type="text">
                <FormSelect
                  placeholder="Select visibility"
                  maxWidth={"850px"}
                  options={[
                    { label: "Public", value: "Public" },
                    {
                      label: "Only Me",
                      value: "Only Me",
                    },
                  ]}
                />
              </FormItem>
              {/* <FormItem label="Choose a playlist" name="playlist" type="text">
                <Row gutter={14}>
                  <Col span={22}>
                    <FormSelect
                      placeholder="Select playlist"
                      maxWidth={"850px"}
                      options={[
                        { label: "All users", value: "All users" },
                        {
                          label: "Only assigned contacts",
                          value: "Only assigned contacts",
                        },
                      ]}
                    />
                  </Col>

                  <Col span={2}>
                    <AddNewField
                      shape="circle"
                      icon={<HiPlus className="plus_icon" />}
                      onClick={(e) => console.log(e)}
                    />
                  </Col>
                </Row>
              </FormItem> */}
              {playList &&
                playList.length > 0 &&
                playList.map((e, index) => (
                  <>
                    <FormItem>
                      <FormInput></FormInput>
                    </FormItem>
                  </>
                ))}
              <FormItem
                label="Is This Rewardable"
                name="rewardable"
                type="text"
              >
                <ToggleSwitch
                  checked={rewardableToggle}
                  onChange={(e) => handleRewardableToggle(e)}
                />
              </FormItem>
              <FormItem
                label="Premium Content"
                name="premiumContent"
                type="text"
              >
                <ToggleSwitch
                  checked={premiumContentToggle}
                  onChange={(e) => setPremiumContentToggle(e)}
                />
              </FormItem>
              {/* <FormItem
                label="Is It Part of Playlist"
                name="isPartOfPlaylist"
                type="text"
              >
                <ToggleSwitch
                  checked={playlistToggle}
                  onChange={(e) => handleplaylistToggle(e)}
                />
              </FormItem> */}
              <FormItem label="Video Upload" maxWidth={"822px"}>
                <FileUpload {...VideoUpload} text={"Upload"} />
              </FormItem>
              <FormItem label="Video Upload Thumbnail">
                {state && state.image_src ? (
                  <StyledImages key={1}>
                    <Image
                      preview={false}
                      onClick={() => {
                        setThumbnail(state.image_src);
                      }}
                      className={"selected"}
                      src={state.image_src}
                    />
                  </StyledImages>
                ) : (
                  thumbnailImages &&
                  thumbnailImages.length > 0 &&
                  thumbnailImages.map((img, idx) => (
                    <StyledImages key={idx}>
                      <Image
                        preview={false}
                        onClick={() => {
                          setThumbnail(img);
                        }}
                        className={
                          params.id
                            ? thumbnail && thumbnail == img
                              ? "selected"
                              : ""
                            : "selected"
                        }
                        src={imagePath(img)}
                      />
                    </StyledImages>
                  ))
                )}
                {loadings && <Spin />}
              </FormItem>
              <Upload
                {...thumbnailImageUpload}
                listType="picture-card"
                // onPreview={this.handlePreview}
                // onChange={this.handleChange}
              >
                {uploadButton}
              </Upload>

              <FormItem>
                <PrimaryButton
                  margin="15px 0 0 0"
                  htmlType="submit"
                  loading={loading}
                  disabled={loadings}
                >
                  Save
                </PrimaryButton>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default AddVideo;
