import React, { useCallback, useEffect, useState } from "react";
import { SecondaryButton } from "../../../../ui/Button";
import { Card } from "../../../../ui/Card";
import { Datatable, UserAvatarAndFullName } from "../../../../ui/Datatable";
import { Heading } from "../../../../ui/Heading";
import { Container } from "../../../../ui/Layout/Container";
import Actions from "../../../../ui/Datatable/Actions";
import { ToggleSwitch } from "../../../../ui/Form";
import { settingsRoutes } from "../../../../helpers/adminRoutes";
import { useHistory } from "react-router-dom";
import {
  getDynamicMenuString,
  imagePath,
  paginationObj,
  querystring,
  searchObj,
} from "utils/fn";
import EventAPI from "api/http/events";
import { message } from "antd";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { SecondaryFloatButton } from "ui/Button/index";
import { StyledLink } from "./events.styles";
import { useSelector } from "react-redux";
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);

const Events = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const { configData } = useSelector((state) => state.configData);

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {},
    sorter: {},
    populate: [
      {
        path: "organizers",
        select: ["firstName", "lastName", "avatar", "location"],
      },
    ],
  });

  const getEventList = useCallback((pagination) => {
    setLoading(true);
    EventAPI.getEventList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData(data?.data);
        setPagination((p) => ({ ...p, total: data?.total }));
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getEventList(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEventList]);

  const handleClick = () => {
    history.push(settingsRoutes.EVENT_ADD);
  };

  const handleDelete = (id) => {
    EventAPI.deleteEvent(id, { id })
      .then(() => {
        getEventList(pagination);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const updateStatus = (userId, status) => {
    EventAPI.updateEvent(userId, { status })
      .then(() => {
        getEventList(pagination);
        message.success("User updated successfully");
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <ToggleSwitch
            checked={_}
            onChange={(value) => updateStatus(record._id, value)}
          />
        );
      },
      fixed: "left",
      width: 70,
    },
    {
      title: "Event Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Preview Link",
      dataIndex: "link",
      key: "link",
      sorter: false,
      render: (_, record) => {
        return (
          <StyledLink
            href={record.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Preview Link
          </StyledLink>
        );
      },
    },
    {
      title: "Organized By",
      dataIndex: "organizers",
      key: "organizers",
      sorter: true,
      render: (_, record) => {
        return (
          <UserAvatarAndFullName
            name={`${record?.organizers[0]?.firstName || ""} ${
              record?.organizers[0]?.lastName || ""
            }`}
            image={
              record.organizers[0]?.avatar?.path
                ? imagePath(record.organizers[0]?.avatar?.path)
                : ""
            }
          />
        );
      },
    },
    {
      title: "Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: true,
      render: (_, render) => {
        return dayjs(render.startDate).isSame(dayjs(render.endDate))
          ? dayjs(render.startDate).format("MMM Do YYYY")
          : `${dayjs(render.startDate).format("MMM Do YYYY")} to ${dayjs(
              render.endDate
            ).format("MMM Do YYYY")}`;
      },
    },
    {
      title: "Time",
      dataIndex: "startTime",
      key: "startTime",
      sorter: true,
      render: (_, record) =>
        `${dayjs(_, "HH:mm:ss").format("hh:mm A")} - ${dayjs(
          record.endTime,
          "HH:mm:ss"
        ).format("hh:mm A")}`,
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      sorter: true,
      render: () => 0,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(settingsRoutes.EVENT_EDIT.replace(":id", item));
        break;
      case "delete":
        handleDelete(item);
        break;

      default:
        break;
    }
  };

  const filterChangeHandler = ({ search, ...page }, filters, sorter) => {
    const filter = searchObj(
      ["name", "description", "location.address"],
      search
    );
    setPagination((p) => ({ ...p, ...page, filter, sorter }));
    getEventList({ ...pagination, ...page, filter, sorter });
  };

  return (
    <Container>
      <Heading>
        {getDynamicMenuString(configData?.[0], ["focus", "my-events"])}
      </Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          loading={loading}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <SecondaryFloatButton
              onClick={handleClick}
              buttonText="Schedule Event"
            />
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default Events;
