import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { useSelector } from "react-redux";
import { CupSvg } from "../../assets/svgs";
import config from "helpers/config";

import {
  StyleCardDropdownWrapper,
  StyledWrapper,
  StyledListBox,
  StyledImg,
  StyledCloseButton,
  StyledOpenMenuWrapper,
  StyledListContentDescription,
  StyledContactDescriptionWrapper,
  StyledTimeAndImageWrapper,
  StyleImageWrapper,
  ConnectButtonWrapper,
  StyledShowContact,
  StyledViewActivityWrapper,
  StyledShowActivity,
  StyledMoveToULDetail,
  ContactButtonStyle,
  ContactRow,
  ContactText,
  StyledCardHeaderWrapper,
  AvatarWrapper,
  StyledAvatarGroupWrapper,
  StyledAvatarGroup,
  StyledAvatarGroupAllWrapper,
  AnimationCard,
} from "./TheRelationship.styles";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaLock } from "react-icons/fa";
import { TiLockOpen } from "react-icons/ti";
import { IoIosClose } from "react-icons/io";

import { IoCallSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { ImArrowLeft2 } from "react-icons/im";
import dayjs from "dayjs";
import { imagePath, paginationObj, querystring, randomNumber } from "utils/fn";
import { TextSvg } from "../../assets/svgs";
import {
  Row,
  message,
  Col,
  Dropdown,
  Menu,
  Button,
  Progress,
  Avatar,
} from "antd";
import ConnectButton from "./ConnectionButton";
import RelationshipAPI from "api/http/relationship";
import AddRelationshipModal from "./AddRelationshipModal";
import AssignContact from "./AssignContact";
import { UserAvatar } from "ui/Avatar/index";
import { useProgressiveImage } from "utils/hooks/useProgressiveImage";
import RelationshipLoaderSkeleton from "./RelationshipLoaderSkeleton";
import RelationshipTabAPI from "api/http/relationshipTab";
import useSocket from "utils/hooks/useSocket";
import useSound from "use-sound";
import StickyMessage from "ui/StickyMessage/StickyMessage";
import { useStickyMessage } from "utils/hooks/useStickyMessage";
import Content from "containers/TheLayout/Content";
import { SocketContext } from "utils/context/socket";

const RelationshipContent = ({ setShow, modalState, socketCall, ...props }) => {
  const [state, setState] = useState({
    dispalyMenu: false,
    showActivity: false,
    showMove: false,
    showContact: false,
    showDash: true,
    ...props.show,
  });

  const [modalEdit, setModalEdit] = useState(modalState);
  const [moveTab, setMoveTab] = useState(false);
  const bgImage = useProgressiveImage("../../images/listArrow.png");
  const [isFirst, setIsFirst] = useState(true);

  const [tab, settab] = useState({ key: props.tabkey });
  const [animationRandomNumber, setAnimationRandomNumber] = useState(3);
  const [stateFilter, setFilter] = useState("");
  const [timer, setTimer] = useState({ duration: "" });
  const { user, faviconImage } = useSelector((state) => state.user);
  const [addEdit, setAddEdit] = useState(props.addEdit);
  const [assignVisible, setAssignVisisble] = useState(false);
  const [stateSocket, setCallSocket] = useState(false);
  const [loading, setLoading] = useState(false);
  const [first, setFirst] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [leadSound, setLeadSound] = useState(false);
  const [pulse, setPulse] = useState();
  const [sadSound, setSadSound] = useState();
  const [socketCallId, setsocketCallId] = useState();
  const [animation, setAnimation] = useState({
    dispalyClock: true,
    showActivity: false,
    ...props.show,
  });

  const animationArr = [
    "https://assets6.lottiefiles.com/packages/lf20_tkeaajkc.json",
    "https://assets2.lottiefiles.com/packages/lf20_h7q0t9pm.json",
    "https://assets6.lottiefiles.com/packages/lf20_tyoFSt.json",
    "https://assets6.lottiefiles.com/packages/lf20_tyoFSt.json",
    "https://assets2.lottiefiles.com/packages/lf20_wwhhicx3.json",
    "https://assets9.lottiefiles.com/packages/lf20_wwhhicx3.json",
    "https://assets2.lottiefiles.com/packages/lf20_2pr9x3si.json",
    "https://assets6.lottiefiles.com/private_files/lf30_vzqrg39e.json",
    "https://assets10.lottiefiles.com/packages/lf20_tiviyc3p.json",
    "https://assets6.lottiefiles.com/packages/lf20_1fkswvsu.json",
    "https://assets6.lottiefiles.com/private_files/lf30_citn1bi3.json",
    "https://assets9.lottiefiles.com/private_files/lf30_kvdn44jg.json",
    "https://assets8.lottiefiles.com/packages/lf20_H5fsdG.json",
    "https://assets4.lottiefiles.com/packages/lf20_kqll5jif.json",
    "https://assets7.lottiefiles.com/packages/lf20_zsmmmni7.json",
  ];

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: props.pageSize ? props.pageSize : 5,
    total: 0,
    populate: [
      {
        path: "sourceId",
      },
      { path: "claimedBy" },
      { path: "members" },
    ],
  });
  const favImage = useProgressiveImage(faviconImage);
  const {
    isActive,
    message: stickyMessage,
    isAutoHide,
    showStickyMessage,
    close,
  } = useStickyMessage();
  const [isSleep, setIsSleep] = useState(false);
  const sleepInMs = useCallback(
    (ms = 1000) => {
      let sl;
      if (isSleep) {
        sl = setTimeout(() => {
          setIsSleep(false);
        }, ms);
      }

      return () => {
        if (sl) {
          clearTimeout(sl);
        }
      };
    },
    [isSleep]
  );

  const socketRef = useRef();
  let i = 0;
  const [stateData, setStateData] = useState({ data: [] });

  const [IndexState, IndexSetState] = useState({
    index: null,
  });
  const [IndexStateId, IndexSetStateId] = useState({
    index: null,
  });
  const [tabData, setTabData] = useState(props.tabDatas || []);

  const socket = useContext(SocketContext);

  /** Sound On Animation - Start */
  const [play, { stop }] = useSound("/audio/tada.mp3");
  const [playBack] = useSound("/audio/lead.wav");
  const [playSad] = useSound("/audio/sad.ogg");
  useEffect(() => {
    if (isAnimating) {
      play();
    }
    return () => {
      stop();
    };
  }, [isAnimating, play, stop]);

  useEffect(() => {
    if (leadSound) {
      playBack();
    }
    return () => {
      stop();
    };
  }, [leadSound]);

  useEffect(() => {
    if (sadSound) {
      playSad();
    }
    return () => {
      stop();
    };
  }, [sadSound]);

  useEffect(() => {
    if (tab.key === tabData?.[0]?._id) {
      getRelationshipList({
        ...pagination,
        ...(props.pageSize ? { pageSize: props.pageSize } : {}),
      });
    } else {
    }
    getRelationshipLists({
      ...pagination,
      ...(props.pageSize ? { pageSize: props.pageSize } : {}),
    });
  }, [props.pageSize]);

  const getRelationshipLists = useCallback(
    (pagination) => {
      let arr = [];
      setLoading(true);
      setCallSocket(false);
      const d = new Date();
      let currentDate = d.toISOString();
      if (tab.key) {
        RelationshipAPI.getRelationshipList(
          querystring(
            paginationObj({
              ...pagination,
              filter: {
                ...pagination.filter,
                $sort: {
                  ...(tab.key === props.tabFirstId ? { claimed: 1 } : {}),
                  createdAt: -1,
                },
              },
              ...(props.pageSize ? { pageSize: props.pageSize } : {}),
            })
          )
        )
          .then(({ data }) => {
            setStateData({ data: data.data });
            for (const datas of data.data) {
              let obj = datas;

              if (tab.key === tabData[1]._id) {
                var tab2Diff = dayjs().diff(dayjs(datas.movedtoSecondTab));
                var durations = msToTime(tab2Diff);
                obj.duration = reverseTimer(durations.time, 2 * 60 * 1000);
                let [hours, minutes, seconds] = obj.duration.split(":");
                obj.minutes = seconds * 1000 + minutes * 60 * 1000;
                obj.temporary = "yes";
                if (durations.minutes >= 2) {
                  obj.duration = "00:00:00";
                  obj.minutes = 0;
                }
              } else if (tab.key === tabData[0]._id) {
                var diff = dayjs().diff(dayjs(datas.createdAt));
                var duration = msToTime(diff);
                obj.duration = duration.time;
                obj.minutes = duration.minutes;
              } else {
                var diff = dayjs().diff(
                  dayjs(
                    datas.contactedTimer
                      ? datas.contactedTimer
                      : datas.createdAt
                  )
                );
                var duration = msToTime(diff);
                obj.duration = duration.time;
                obj.minutes = duration.minutes;
              }

              let cntct = datas.sourceId && datas.sourceId.contact;

              if (cntct)
                for (const dta of cntct) {
                  if (dta.fieldName === "firstName") {
                    obj.firstNameVisibility = dta.visibility;
                  }
                  if (dta.fieldName === "lastName") {
                    obj.lastNameVisibility = dta.visibility;
                  }
                  if (dta.fieldName === "email") {
                    obj.emailVisibility = dta.visibility;
                  }
                  if (dta.fieldName === "address") {
                    obj.addressVisibility = dta.visibility;
                  }
                  if (dta.fieldName === "phoneNumber") {
                    obj.phoneNumberVisibility = dta.visibility;
                  }
                }
              if (
                datas &&
                datas.type !== props.tabFirstId &&
                datas.claimedBy &&
                datas.claimedBy._id !==
                  (localStorage.getItem("userId") || user?._id)
              ) {
                obj.type = props.tabFirstId;
              }
              var temp = [];
              let arrs = [];
              for (const mebr of datas.members) {
                arrs.push(mebr._id);
              }
              arr.push(obj);
            }

            setStateData({ data: arr });
            setPagination((p) => ({
              ...p,
              filter: {
                ...pagination.filter,
                $sort: {
                  ...(tab.key === props.tabFirstId ? { claimed: 1 } : {}),
                  createdAt: -1,
                },
              },
              ...(props.pageSize ? { pageSize: props.pageSize } : {}),
            }));
          })
          .catch((error) => {
            if (error) {
              message.error(error.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [props.pageSize, tab.key]
  );

  const getRelationshipList = useCallback(
    (pagination) => {
      let arr = [];
      setCallSocket(false);
      const d = new Date();
      let currentDate = d.toISOString();

      if (tab.key) {
        console.log(">>>>>>>>>>>getRelationshipListttttttt");
        setLoading(true);
        if (tab.key && tab.key === props.tabFirstId) {
          RelationshipAPI.getRelationshipList(
            querystring(
              paginationObj({
                ...pagination,
                filter: {
                  blockedUsers: {
                    $nin: [localStorage.getItem("userId") || user?._id],
                  },

                  $or: [
                    {
                      // $and: [
                      //   {
                      //     type: tab.key,
                      //     source: { $exists: false },
                      //   },
                      //   {
                      $or: [
                        {
                          "source.visibilityType": "All users",
                          type: tab.key,
                        },
                        {
                          type: tab.key,
                          source: { $exists: false },
                        },
                        {
                          $and: [
                            {
                              "source.visibilityType": "Only assigned contacts",
                            },
                            {
                              $or: [
                                {
                                  "source.userId":
                                    localStorage.getItem("userId") || user?._id,
                                },
                                {
                                  members: {
                                    $in: [localStorage.getItem("userId")],
                                  },
                                },
                              ],
                            },
                          ],
                        },
                        {
                          claimedBy:
                            localStorage.getItem("userId") || user?._id,
                        },
                        // {
                        //   members: {
                        //     $in: [localStorage.getItem("userId") || user?._id],
                        //   },
                        // },
                      ],
                    },
                    //   ],
                    // },
                    tab.key === props.tabFirstId
                      ? {
                          $and: [
                            {
                              type: {
                                $ne: props.tabFirstId,
                              },
                            },
                            {
                              claimedBy: {
                                $ne:
                                  user?._id || localStorage.getItem("userId"),
                              },
                            },
                          ],
                        }
                      : {},
                  ],

                  $sort: {
                    ...(tab.key === props.tabFirstId ? { claimed: 1 } : {}),
                    createdAt: -1,
                  },
                },

                ...(props.pageSize ? { pageSize: props.pageSize } : {}),
              })
            )
          )
            .then(({ data }) => {
              setStateData({ data: data.data });
              for (const datas of data.data) {
                let obj = datas;

                if (tab.key === tabData[1]._id) {
                  var tab2Diff = dayjs().diff(dayjs(datas.movedtoSecondTab));
                  var durations = msToTime(tab2Diff);
                  obj.duration = reverseTimer(durations.time, 2 * 60 * 1000);
                  let [hours, minutes, seconds] = obj.duration.split(":");
                  obj.minutes = seconds * 1000 + minutes * 60 * 1000;
                  obj.temporary = "yes";
                  if (durations.minutes >= 2) {
                    obj.duration = "00:00:00";
                    obj.minutes = 0;
                  }
                } else if (tab.key === tabData[0]._id) {
                  var diff = dayjs().diff(dayjs(datas.createdAt));
                  var duration = msToTime(diff);
                  obj.duration = duration.time;
                  obj.minutes = duration.minutes;
                } else {
                  var diff = dayjs().diff(
                    dayjs(
                      datas.contactedTimer
                        ? datas.contactedTimer
                        : datas.createdAt
                    )
                  );
                  var duration = msToTime(diff);
                  obj.duration = duration.time;
                  obj.minutes = duration.minutes;
                }

                let cntct = datas.sourceId && datas.sourceId.contact;

                if (cntct)
                  for (const dta of cntct) {
                    if (dta.fieldName === "firstName") {
                      obj.firstNameVisibility = dta.visibility;
                    }
                    if (dta.fieldName === "lastName") {
                      obj.lastNameVisibility = dta.visibility;
                    }
                    if (dta.fieldName === "email") {
                      obj.emailVisibility = dta.visibility;
                    }
                    if (dta.fieldName === "address") {
                      obj.addressVisibility = dta.visibility;
                    }
                    if (dta.fieldName === "phoneNumber") {
                      obj.phoneNumberVisibility = dta.visibility;
                    }
                  }
                if (
                  datas &&
                  datas.type !== props.tabFirstId &&
                  datas.claimedBy &&
                  datas.claimedBy._id !==
                    (localStorage.getItem("userId") || user?._id)
                ) {
                  obj.type = props.tabFirstId;
                }
                var temp = [];
                let arrs = [];
                for (const mebr of datas.members) {
                  arrs.push(mebr._id);
                }
                arr.push(obj);

                setStateData({ data: arr });
                setPagination((p) => ({
                  ...p,
                  filter: {
                    blockedUsers: {
                      $nin: [localStorage.getItem("userId") || user?._id],
                    },

                    $or: [
                      {
                        // $and: [
                        //   {
                        //     type: tab.key,
                        //     source: { $exists: false },
                        //   },
                        //   {
                        $or: [
                          {
                            "source.visibilityType": "All users",
                            type: tab.key,
                          },
                          {
                            type: tab.key,
                            source: { $exists: false },
                          },
                          {
                            $and: [
                              {
                                "source.visibilityType":
                                  "Only assigned contacts",
                              },
                              {
                                $or: [
                                  {
                                    "source.userId":
                                      localStorage.getItem("userId") ||
                                      user?._id,
                                  },
                                  {
                                    members: {
                                      $in: [localStorage.getItem("userId")],
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                          {
                            claimedBy:
                              localStorage.getItem("userId") || user?._id,
                          },
                          // {
                          //   members: {
                          //     $in: [localStorage.getItem("userId") || user?._id],
                          //   },
                          // },
                        ],
                      },
                      //   ],
                      // },
                      tab.key === props.tabFirstId
                        ? {
                            $and: [
                              {
                                type: {
                                  $ne: props.tabFirstId,
                                },
                              },
                              {
                                claimedBy: {
                                  $ne:
                                    user?._id || localStorage.getItem("userId"),
                                },
                              },
                            ],
                          }
                        : {},
                    ],

                    $sort: {
                      ...(tab.key === props.tabFirstId ? { claimed: 1 } : {}),
                      createdAt: -1,
                    },
                  },

                  ...(props.pageSize ? { pageSize: props.pageSize } : {}),
                }));
              }
            })
            .catch((error) => {
              if (error) {
                message.error(error.message);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        } else if (tab.key && tab.key !== props.tabFirstId) {
          RelationshipAPI.getRelationshipList(
            querystring(
              paginationObj({
                ...pagination,
                filter: {
                  blockedUsers: {
                    $nin: [localStorage.getItem("userId") || user?._id],
                  },
                  type: tab.key,

                  // ...(user.permissions.includes("admin")
                  //   ? {}
                  //   :
                  //  {
                  members: {
                    $in: [localStorage.getItem("userId") || user?._id],
                  },
                  // }),

                  $sort: {
                    ...(tab.key === props.tabFirstId ? { claimed: 1 } : {}),
                    createdAt: -1,
                  },
                },

                ...(props.pageSize ? { pageSize: props.pageSize } : {}),
              })
            )
          )
            .then(({ data }) => {
              setStateData({ data: data.data });
              for (const datas of data.data) {
                let obj = datas;

                if (tab.key === tabData[1]._id) {
                  var tab2Diff = dayjs().diff(dayjs(datas.movedtoSecondTab));
                  var durations = msToTime(tab2Diff);
                  obj.duration = reverseTimer(durations.time, 2 * 60 * 1000);
                  let [hours, minutes, seconds] = obj.duration.split(":");
                  obj.minutes = seconds * 1000 + minutes * 60 * 1000;
                  obj.temporary = "yes";
                  if (durations.minutes >= 2) {
                    obj.duration = "00:00:00";
                    obj.minutes = 0;
                  }
                } else if (tab.key === tabData[0]._id) {
                  var diff = dayjs().diff(dayjs(datas.createdAt));
                  var duration = msToTime(diff);
                  obj.duration = duration.time;
                  obj.minutes = duration.minutes;
                } else {
                  var diff = dayjs().diff(
                    dayjs(
                      datas.contactedTimer
                        ? datas.contactedTimer
                        : datas.createdAt
                    )
                  );
                  var duration = msToTime(diff);
                  obj.duration = duration.time;
                  obj.minutes = duration.minutes;
                }

                let cntct = datas.sourceId && datas.sourceId.contact;

                if (cntct)
                  for (const dta of cntct) {
                    if (dta.fieldName === "firstName") {
                      obj.firstNameVisibility = dta.visibility;
                    }
                    if (dta.fieldName === "lastName") {
                      obj.lastNameVisibility = dta.visibility;
                    }
                    if (dta.fieldName === "email") {
                      obj.emailVisibility = dta.visibility;
                    }
                    if (dta.fieldName === "address") {
                      obj.addressVisibility = dta.visibility;
                    }
                    if (dta.fieldName === "phoneNumber") {
                      obj.phoneNumberVisibility = dta.visibility;
                    }
                  }
                if (
                  datas &&
                  datas.type !== props.tabFirstId &&
                  datas.claimedBy &&
                  datas.claimedBy._id !==
                    (localStorage.getItem("userId") || user?._id)
                ) {
                  obj.type = props.tabFirstId;
                }
                var temp = [];
                let arrs = [];
                for (const mebr of datas.members) {
                  arrs.push(mebr._id);
                }
                arr.push(obj);
              }
              console.log("arr>>>>", arr);
              setStateData({ data: arr });
              setPagination((p) => ({
                ...p,
                filter: {
                  blockedUsers: {
                    $nin: [localStorage.getItem("userId") || user?._id],
                  },
                  type: tab.key,

                  // ...(user.permissions.includes("admin")
                  //   ? {}
                  //   : {
                  members: {
                    $in: [localStorage.getItem("userId") || user?._id],
                  },
                  // }),

                  $sort: {
                    ...(tab.key === props.tabFirstId ? { claimed: 1 } : {}),
                    createdAt: -1,
                  },
                },

                ...(props.pageSize ? { pageSize: props.pageSize } : {}),
                // query: {
                //   $sort: { claimedBy: -1 },
                // },
              }));
            })
            .catch((error) => {
              if (error) {
                message.error(error.message);
              }
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    },
    [props.pageSize, tab.key]
  );

  const phoneNumberHandler = async (e, phoneNumber, type, data) => {
    if (tab.key == tabData[1]?._id) {
      if (type === "phone") {
        RelationshipAPI.updateRelationship(data._id, {
          contacted: true,
          contactedTimer: new Date(),
          type: tabData[2]?._id,
          $addToSet: {
            activities: {
              userId: user?._id,
              name: `${user?.firstName}`,
              type: "callLead",
              text: `called`,
            },
          },
        }).then((data) => {
          props.setTabState(tabData[2]?._id);

          setShow({
            dispalyMenu: false,
            showActivity: false,
            showContact: false,
            showMove: false,
            showDash: true,
          });
          // IndexSetState({ index: index });
          IndexSetStateId({ index: data._id });
        });

        window.open(`tel:${phoneNumber}`, "_self");
      } else if (type === "text") {
        // if (data.activities) {
        //   data.activities.push(activity);
        // } else {
        //   data.activities = { userId: user?._id,name:`${user?.firstName}`, text: `Texted` };
        // }
        // RelationshipAPI.updateRelationship(data._id, data)
        RelationshipAPI.updateRelationship(data._id, {
          contacted: true,
          contactedTimer: new Date(),

          claimed: true,
          type: tabData[2]?._id,
          $addToSet: {
            activities: {
              userId: user?._id,
              type: "textLead",
              name: `${user?.firstName}`,
              text: `texted`,
            },
          },
        }).then((data) => {
          props.setTabState(tabData[2]?._id);

          setShow({
            dispalyMenu: false,
            showActivity: false,
            showContact: false,
            showMove: false,
            showDash: true,
          });
          // IndexSetState({ index: index });
          IndexSetStateId({ index: data._id });
        });

        window.open(`sms:${phoneNumber}`, "_blank");
      } else if (type === "email") {
        // var activity = { userId: user?._id,name:`${user?.firstName}`, text: `Emailed` };
        // if (data.activities) {
        //   data.activities.push(activity);
        // } else {
        //   data.activities = { userId: user?._id,name:`${user?.firstName}`, text: `Emailed` };
        // }
        // RelationshipAPI.updateRelationship(data._id, data)
        RelationshipAPI.updateRelationship(data._id, {
          contacted: true,
          contactedTimer: new Date(),
          type: tabData[2]?._id,
          $addToSet: {
            activities: {
              userId: user?._id,
              name: `${user?.firstName}`,
              type: "emailLead",
              text: `emailed`,
            },
          },
        }).then((data) => {
          props.setTabState(tabData[2]?._id);

          setShow({
            dispalyMenu: false,
            showActivity: false,
            showContact: false,
            showMove: false,
            showDash: true,
          });
          // IndexSetState({ index: index });
          IndexSetStateId({ index: data._id });
        });

        //   <a
        //   href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${k.email}`}
        //   target="_blank"
        // >
        window.open(
          `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${phoneNumber}`,
          "_blank"
        );
      }
    } else {
      if (type === "phone") {
        // var activity = { userId: user?._id,name:`${user?.firstName}`, text: `Called` };
        // if (data.activities) {
        //   data.activities.push(activity);
        // } else {
        //   data.activities = { userId: user?._id,name:`${user?.firstName}`, text: `${user.firstName} Called` };
        // }
        RelationshipAPI.updateRelationship(data._id, {
          contacted: true,
          contactedTimer: new Date(),
          $addToSet: {
            activities: {
              userId: user?._id,
              name: `${user?.firstName}`,
              type: "callLead",
              text: `called`,
            },
          },
        });

        window.open(`tel:${phoneNumber}`, "_self");
      } else if (type === "text") {
        // var activity = { userId: user?._id,name:`${user?.firstName}`, text: ` Texted` };
        // if (data.activities) {
        //   data.activities.push(activity);
        // } else {
        //   data.activities = { userId: user?._id,name:`${user?.firstName}`, text: `Texted` };
        // }
        // RelationshipAPI.updateRelationship(data._id, data)
        RelationshipAPI.updateRelationship(data._id, {
          contacted: true,
          contactedTimer: new Date(),
          $addToSet: {
            activities: {
              userId: user?._id,
              name: `${user?.firstName}`,
              type: "textLead",
              text: `texted`,
            },
          },
        });

        window.open(`sms:${phoneNumber}`, "_blank");
      } else if (type === "email") {
        // var activity = { userId: user?._id,name:`${user?.firstName}`, text: `Emailed` };
        // if (data.activities) {
        //   data.activities.push(activity);
        // } else {
        //   data.activities = { userId: user?._id,name:`${user?.firstName}`, text: `Emailed` };
        // }
        // RelationshipAPI.updateRelationship(data._id, data)
        RelationshipAPI.updateRelationship(data._id, {
          contacted: true,
          contactedTimer: new Date(),
          $addToSet: {
            activities: {
              userId: user?._id,
              type: "emailLead",
              name: `${user?.firstName}`,
              text: `emailed`,
            },
          },
        });

        //   <a
        //   href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${k.email}`}
        //   target="_blank"
        // >
        window.open(
          `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${phoneNumber}`,
          "_blank"
        );
      }
    }
  };

  function checkDigit(digit) {
    var valAsString = digit.toString();
    if (valAsString.length === 1) {
      return "0" + digit;
    } else {
      return digit;
    }
  }

  function msToTime(s) {
    var ms = s % 1000;
    s = (s - ms) / 1000;
    var secs = checkDigit(s % 60);
    s = (s - secs) / 60;
    var mins = checkDigit(s % 60);
    var hrs = checkDigit((s - mins) / 60);

    return {
      time: hrs + ":" + mins + ":" + secs,
      minutes: hrs > 0 ? 60 : mins,
    };
  }

  useEffect(() => {
    if (props.tabkey) {
      settab({ key: props.tabkey });
      setAssignVisisble(false);
    }
  }, [props.tabkey]);

  useEffect(() => {
    // if(first == false){

    if (!isAnimating) {
      // if (user?.permissions?.includes("admin")) {
      //   getRelationshipList({
      //     ...pagination,
      //     filter: {
      //       type: tab.key,
      //     },
      //   });
      // } else {
      if (
        props.filter === "Show All" &&
        socketCall &&
        socketCall?.action !== "patch" &&
        (tab.key === tabData?.[0]?._id || tab.key === tabData?.[1]?._id)
      ) {
        console.log(">>>>>>>>>>inside33333333333");

        getRelationshipList(pagination);
      }
      // }
    }
    // }
  }, [getRelationshipList, isAnimating, tab.key, props.filter]);

  useEffect(() => {
    if (
      socketCall &&
      // tab.key === tabData[0]?._id &&
      socketCall?.action === "create"
    ) {
      let obj = socketCall.data;

      console.log("socketCall.data>>>>>", socketCall.data);

      let cntct =
        socketCall.data?.sourceId && socketCall.data?.sourceId.contact;
      if (cntct) {
        for (const dta of socketCall.data?.sourceId.contact) {
          if (dta.fieldName === "firstName") {
            obj.firstNameVisibility = dta.visibility;
          }
          if (dta.fieldName === "lastName") {
            obj.lastNameVisibility = dta.visibility;
          }
          if (dta.fieldName === "email") {
            obj.emailVisibility = dta.visibility;
          }
          if (dta.fieldName === "address") {
            obj.addressVisibility = dta.visibility;
          }
          if (dta.fieldName === "phoneNumber") {
            obj.phoneNumberVisibility = dta.visibility;
          }
        }
      }
      if (!obj.sourceId) {
        if (
          (localStorage.getItem("userId") || user?._id) === obj.claimedBy?._id
        ) {
          console.log("inside first", obj);
          setStateData((s) => ({ data: [obj, ...s.data] }));
        }
      } else if (
        // user &&
        // user.permissions &&
        // user.permissions.includes("admin") &&
        obj.sourceId
      ) {
        setStateData((s) => ({ data: [obj, ...s.data] }));
      }
    } else if (socketCall && socketCall?.action === "patch") {
      if (stateSocket) {
        getRelationshipList(pagination);
      } else {
        // if (
        //   !socketCall?.data?.claimedBy &&
        //   socketCall?.data?.type === tabData[0]._id
        // ) {
        //   if (
        //     (socketCall?.data?.blockedUsers[
        //       socketCall?.data?.blockedUsers.length - 1
        //     ]).toString() === user?._id
        //   ) {
        //     message.info(
        //       "Sorry you didn't contact them quick enough! Make sure you only click Connect if you are able to contact them within 2 minutes."
        //     );

        //     setSadSound(true);
        //     setTimeout(() => {
        //       setSadSound(false);
        //     }, 2000);
        //   }
        // }

        if (
          socketCallId !== socketCall?.data?._id &&
          socketCall?.data?.claimedBy &&
          socketCall?.data?.claimedBy?._id !== user?._id &&
          socketCall?.data?.type === tabData[1]._id
        ) {
        }

        let datas = [...stateData.data];
        let objIndex = datas.findIndex((x) => x._id === socketCall?.data?._id);

        if (objIndex !== -1 && !moveTab) {
          //  datas.splice(objIndex, 1);
          datas[objIndex] = socketCall.data;
          setStateData((s) => ({ ...s, data: datas }));
        }
      }
    } else if (socketCall && socketCall?.action === "remove") {
      let datas = stateData.data;
      let objIndex = datas.findIndex((x) => x._id === socketCall?.data?._id);
      if (objIndex !== -1) {
        datas.splice(objIndex, 1);
        setStateData({ data: datas });
      }
    }
  }, [socketCall]);

  useEffect(() => {
    setState(props.show);
    // Currently it's static to demostrate
    IndexSetState({ index: 0 });
  }, [props.show]);

  useEffect(() => {
    setFilter(props.filter);
    if (props.filter === "Show All" && tab.key === tabData?.[0]?._id) {
      getRelationshipList(pagination);
    } else if (props.filter === "Show All" && tab.key !== tabData?.[1]?._id) {
      getRelationshipList(pagination);
    } else {
      console.log(">>>>>>>>>>inside222222222");
      if (props.filter === "Show All" && tab.key) {
        if (user?.permissions?.includes("admin")) {
          setPagination((p) => ({
            ...p,
            filter: {
              blockedUsers: {
                $nin: [localStorage.getItem("userId") || user?._id],
              },
              $or: [
                {
                  type: tab.key,
                },
                tab.key === props.tabFirstId
                  ? {
                      $and: [
                        {
                          type: {
                            $ne: props.tabFirstId,
                          },
                        },
                      ],
                    }
                  : {},
              ],

              $sort: {
                ...(tab.key === props.tabFirstId ? { claimed: 1 } : {}),
                createdAt: -1,
              },
            },
            populate: [
              {
                path: "sourceId",
              },
              { path: "claimedBy" },
              { path: "members" },
            ],
          }));
          getRelationshipList({
            ...pagination,
            filter: {
              blockedUsers: {
                $nin: [localStorage.getItem("userId") || user?._id],
              },
              $or: [
                {
                  type: tab.key,
                },
                tab.key === props.tabFirstId
                  ? {
                      $and: [
                        {
                          type: {
                            $ne: props.tabFirstId,
                          },
                        },
                      ],
                    }
                  : {},
              ],

              $sort: {
                ...(tab.key === props.tabFirstId ? { claimed: 1 } : {}),
                createdAt: -1,
              },
            },
            populate: [
              {
                path: "sourceId",
              },
              { path: "claimedBy" },
              { path: "members" },
            ],
          });
        } else {
          setPagination((p) => ({
            ...p,
            filter: {
              blockedUsers: {
                $nin: [localStorage.getItem("userId") || user?._id],
              },
              $and: [
                {
                  type: tab.key,
                },
                {
                  members: {
                    $in: [localStorage.getItem("userId") || user?._id],
                  },
                },
              ],

              $sort: {
                claimed: 1,
                createdAt: -1,
              },
            },
            populate: [
              {
                path: "sourceId",
              },
              { path: "claimedBy" },
              { path: "members" },
            ],
          }));
          getRelationshipLists({
            ...pagination,
            filter: {
              blockedUsers: {
                $nin: [localStorage.getItem("userId") || user?._id],
              },
              $and: [
                {
                  type: tab.key,
                },
                {
                  members: {
                    $in: [localStorage.getItem("userId") || user?._id],
                  },
                },
              ],

              $sort: {
                claimed: 1,
                createdAt: -1,
              },
            },
            populate: [
              {
                path: "sourceId",
              },
              { path: "claimedBy" },
              { path: "members" },
            ],
          });
        }
      } else if (props.filter === "Show Assigned") {
        setPagination((p) => ({
          ...p,
          filter: {
            members: { $in: [localStorage.getItem("userId") || user?._id] },
            // claimedBy: user?._id || localStorage.getItem("userId"),
            // claimed: false,
            blockedUsers: {
              $nin: [localStorage.getItem("userId") || user?._id],
            },

            type: tab.key,

            $sort: {
              claimed: 1,
              createdAt: -1,
            },
          },
        }));
        getRelationshipLists({
          ...pagination,
          filter: {
            members: { $in: [localStorage.getItem("userId") || user?._id] },
            // claimedBy: user?._id || localStorage.getItem("userId"),
            // claimed: false,
            blockedUsers: {
              $nin: [localStorage.getItem("userId") || user?._id],
            },

            type: tab.key,

            $sort: {
              claimed: 1,
              createdAt: -1,
            },
          },
        });
      } else if (props.filter === "Show Manual Uploads") {
        setPagination((p) => ({
          ...p,
          filter: {
            claimedBy: localStorage.getItem("userId") || user?._id,
            blockedUsers: {
              $nin: [localStorage.getItem("userId") || user?._id],
            },
            type: tab.key,
            sourceId: { $exists: false },
            $sort: {
              claimed: 1,
              createdAt: -1,
            },
          },
        }));
        getRelationshipLists({
          ...pagination,
          filter: {
            claimedBy: localStorage.getItem("userId") || user?._id,
            blockedUsers: {
              $nin: [localStorage.getItem("userId") || user?._id],
            },
            sourceId: { $exists: false },
            type: tab.key,
            $sort: {
              claimed: 1,
              createdAt: -1,
            },
          },
        });
      }
    }
  }, [props.filter, tab.key]);

  const reverseTimer = (time, ms) => {
    const [hours, minutes, seconds] = time.split(":");
    const currentMs =
      seconds * 1000 + minutes * 60 * 1000 + hours * 60 * 60 * 1000;
    return msToTime(ms - currentMs)["time"];
  };

  const getRelationshipListTimerData = () => {
    let data = stateData.data;
    const d = new Date();
    let currentDate = d.toISOString();
    var arr = [];
    if (data && data.length > 0) {
      for (const timeData of data) {
        let obj = timeData;

        if (tab.key === tabData[1]._id) {
          var tab2Diff = dayjs(currentDate).diff(
            dayjs(timeData.movedtoSecondTab)
          );
          var durations = msToTime(tab2Diff);
          obj.duration = reverseTimer(durations.time, 2 * 60 * 1000);
          let [hours, minutes, seconds] = obj.duration.split(":");
          obj.minutes = seconds * 1000 + minutes * 60 * 1000;
          obj.temporary = "yes";
          if (durations.minutes >= 2) {
            // var diff = dayjs(currentDate).diff(dayjs(timeData.createdAt));
            // var duration = msToTime(diff);
            // obj.duration = duration.time;
            // obj.minutes = duration.minutes;
            obj.duration = "00:00:00";
            obj.minutes = 0;
          }
        } else if (tab.key === tabData[0]._id) {
          var diff = dayjs(currentDate).diff(dayjs(timeData.createdAt));
          var duration = msToTime(diff);
          obj.duration = duration.time;
          obj.minutes = duration.minutes;
        } else {
          var diff = dayjs().diff(
            dayjs(
              timeData.contactedTimer
                ? timeData.contactedTimer
                : timeData.createdAt
            )
          );
          var duration = msToTime(diff);
          obj.duration = duration.time;
          obj.minutes = duration.minutes;
        }

        arr.push(obj);
      }
    } else {
      arr = stateData.data;
    }
    // arr.sort(function (a, b) {
    //   return a.claimed - b.claimed;
    // });
    // arr.sort(function (a, b) {
    //   return b.createdAt - a.createdAt;
    // });
    // if(props.tabkey){
    //   var intervalID = setInterval(
    //     () =>
    //    1000
    //   );
    //   return () => {
    //     clearInterval(intervalID);
    //     setStateData({ data: arr });
    //     setPagination((p) => ({ ...p, total: arr.total }));
    //   };
    // }
    // else{
    setStateData({ data: arr });
    setPagination((p) => ({ ...p, total: arr.total }));
    // }
  };

  useEffect(() => {
    if (modalEdit !== true) {
      var intervalID = setInterval(() => getRelationshipListTimerData(), 1000);
      return () => {
        clearInterval(intervalID);
        setAssignVisisble(false);
      };
    }
  }, [getRelationshipListTimerData]);

  const handleClose = () => {
    setState({
      dispalyMenu: false,
      showActivity: false,
      showContact: false,
      showMove: false,
    });
    IndexSetState({ index: null });
    IndexSetStateId({ index: null });
  };

  const handleModalOpen = () => {
    setModalEdit(true);
    setCallSocket(true);
  };
  const handleClick = (e, index) => {
    setState({
      dispalyMenu: true,
      showActivity: false,
      showContact: false,
      showMove: false,
    });
    IndexSetState({ index: index });
    let data = stateData.data[index];
    IndexSetStateId({ index: data && data._id });
  };

  const handleMove = (e) => {
    setState({
      dispalyMenu: false,
      showActivity: false,
      showContact: false,
      showMove: true,
      showDash: false,
    });
    let data = stateData.data[IndexState.index];
    IndexSetState({ index: IndexState.index });
    IndexSetStateId({ index: data && data._id });
  };

  const handleActivity = () => {
    setState({
      dispalyMenu: false,
      showActivity: true,
      showContact: false,
      showMove: false,
      showDash: false,
    });
  };
  const handleAssignContact = () => {
    let data = stateData.data[IndexState.index];
    IndexSetState({ index: IndexState.index });
    IndexSetStateId({ index: data && data._id });
    setAssignVisisble(true);
    setCallSocket(true);
  };

  const handleContact = (e, index) => {
    setShow({
      dispalyMenu: false,
      showActivity: false,
      showContact: true,
      showMove: false,
      showDash: false,
    });
    // getRelationshipList(pagination);
    let data = stateData.data[IndexState.index];
    IndexSetState({ index: IndexState.index });
    IndexSetStateId({ index: data && data._id });
  };

  const handleContactButton = (e, index, id) => {
    setShow({
      dispalyMenu: false,
      showActivity: false,
      showContact: true,
      showMove: false,
      showDash: false,
    });
    IndexSetState({ index: IndexState.index });
    IndexSetStateId({ index: id });
  };

  const getFormattedDate = (date) => {
    let d3 = dayjs(date);
    return d3.format("MM/DD/YY");
  };
  const getFormattedTime = (date) => {
    let d3 = dayjs(date);
    return d3.format("h:mm a");
  };
  const handleDelete = (e, index) => {
    RelationshipAPI.deleteRelationship(stateData.data[IndexState.index]?._id, {
      id: stateData.data && stateData.data[IndexState.index]?._id,
    })
      .then(({ data }) => {
        getRelationshipList(pagination);
        // IndexSetState({ index: index });
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const handleContactEarnedButton = (e, index) => {
    let data = stateData.data[index];
    setShow({
      dispalyMenu: false,
      showActivity: false,
      showContact: true,
      showMove: false,
      showDash: false,
    });
    IndexSetState({ index: index });
    IndexSetStateId({ index: data._id });
  };

  const getRelationshipTabList = useCallback((pagination) => {
    RelationshipTabAPI.getRelationshipTabList()
      .then(({ data }) => {
        function compare(a, b) {
          const bandA = b.sequence;
          const bandB = a.sequence;

          let comparison = 0;
          if (bandB > bandA) {
            comparison = 1;
          } else if (bandB < bandA) {
            comparison = -1;
          }
          return comparison;
        }

        data.sort(compare);
        setTabData(data);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  });

  useEffect(() => {
    setTabData(props.tabDatas || []);
    return () => {};
  }, [props.tabDatas]);

  const handleContactConnect = (e, index, type) => {
    setAnimationRandomNumber(Math.floor(Math.random() * animationArr.length));

    if (type == "contact") {
      setIsAnimating(true);
      setAnimation({
        dispalyClock: false,
        showActivity: true,
      });
    }
    let dataId = stateData.data[index];
    IndexSetStateId({ index: dataId._id });

    if (type == "connect") {
      getRelationshipTabList();
      let data = stateData.data[index];
      IndexSetState({ index: index });
      IndexSetStateId({ index: data._id });
      setShow({
        dispalyMenu: false,
        showActivity: false,
        showContact: true,
        showMove: false,
        showDash: false,
      });
      // data.claimed = true;
      // data.type = tabData[2]?._id;
      // data.claimedBy = localStorage.getItem("userId");
      // data.users = user;
      // data.category = "contact";
      // data.members = [user?._id];
      // data.contactedTimer = new Date();

      // delete data.activities;
      // RelationshipAPI.updateRelationship(data._id, data);
      // RelationshipAPI.updateRelationship(data._id, {
      //   $addToSet: {
      //     activities: {
      //       userId: user?._id,
      //       name: `${user?.firstName}`,
      //       text: `moved to ${tabData[2]?.type}`,
      //     },
      //   },
      // })
      //   .then(({ datas }) => {
      //     setPagination((p) => ({
      //       ...p,
      //       filter: {
      //         blockedUsers: {
      //           $nin: [localStorage.getItem("userId") || user?._id],
      //         },
      //         type: tabData[2]?._id,
      //         claimedBy: user?._id || localStorage.getItem("userId"),

      //         $sort: {
      //           createdAt: -1,
      //         },
      //       },
      //     }));
      //     IndexSetState({ index: index });
      //     IndexSetStateId({ index: data._id });
      //     props.setTabState(tabData[2]?._id);
      //   })
      //   .catch((error) => {
      //     if (error) {
      //       message.error(error.message);
      //     }
      //   });
    } else if (type == "contact") {
      setTimeout(() => {
        // getRelationshipTabList();
        props.setTabState(tabData[1]?._id);
        setAnimation({
          dispalyClock: true,
          showActivity: false,
        });

        setIsAnimating(false);
        setShow({
          dispalyMenu: false,
          showActivity: false,
          showContact: true,
          showMove: false,
          showDash: false,
        });
      }, 2000);
      let data = stateData.data[index];
      data.claimed = true;
      data.type = tabData[1]?._id;
      data.movedtoSecondTab = new Date();
      data.claimedBy = localStorage.getItem("userId");
      data.users = user;
      data.category = "contact";
      data.members = [user?._id];
      //  var activity = { userId: user?._id,name:`${user?.firstName}`, text: `Moved to ${tabData && tabData[1]?.type}` };
      //  if (data.activities) {
      //    data.activities.push(activity);
      //  } else {
      //    data.activities = { userId: user?._id,name:`${user?.firstName}`, text: `Moved to ${tabData && tabData[1]?.type}` };

      // }

      // delete data?.activities;

      //  RelationshipAPI.updateRelationship(data._id, data).then((data)=>{
      //   })
      delete data.activities;
      RelationshipAPI.updateRelationship(data._id, {
        ...data,
        $addToSet: {
          activities: {
            userId: user?._id,
            name: `${user?.firstName}`,
            type: "moveLead",
            text: `moved to ${tabData[1]?.type}`,
          },
        },
      })
        .then(({ datas }) => {
          setPagination((p) => ({
            ...p,
            filter: {
              blockedUsers: {
                $nin: [localStorage.getItem("userId") || user?._id],
              },
              type: tabData[1]?._id,
              claimedBy: user?._id || localStorage.getItem("userId"),

              $sort: {
                createdAt: -1,
              },
            },
          }));
          // getRelationshipList((p) => ({
          //   ...p,
          //   filter: {
          //     blockedUsers: {
          //       $nin: [localStorage.getItem("userId") || user?._id],
          //     },
          //     type: tabData[1]?._id,
          //     claimedBy: user?._id || localStorage.getItem("userId"),

          //     $sort: {
          //       createdAt: -1,
          //     },
          //   },
          // }));
          IndexSetState({ index: index });
          IndexSetStateId({ index: data._id });
        })
        .catch((error) => {
          if (error) {
            message.error(error.message);
          }
        });
    }
  };

  useEffect(() => {
    if (props.tabkey !== undefined) {
      settab({ key: props.tabkey });
    }
  }, [props.tabkey]);

  const handleMoveTab = (e, id, type, datass) => {
    if (tabData[1]?._id === type) {
      datass.claimed = true;
    } else {
      datass.claimed = true;
      datass.connected = true;
    }
    datass.type = id;

    delete datass?.activities;
    delete datass?.contactedTimer;
    if (tabData[0]._id == id) {
      RelationshipAPI.updateRelationship(datass._id, {
        $unset: { claimedBy: "" },
      })
        .then((datas) => {
          RelationshipAPI.updateRelationship(datass._id, {
            $addToSet: {
              activities: {
                userId: user?._id,
                name: `${user?.firstName}`,
                type: "moveLead",
                text: `moved to ${type}`,
              },
            },
            type: id,
            claimed: false,
            connected: false,
            contacted: false,
          });
        })
        .then((dat) => {
          props.setTabState(id);
        });
    } else {
      RelationshipAPI.updateRelationship(datass._id, datass).then((data) => {
        RelationshipAPI.updateRelationship(datass._id, {
          $addToSet: {
            activities: {
              userId: user?._id,
              name: `${user?.firstName}`,
              type: "moveLead",
              text: `moved to ${type}`,
            },
          },
        }).then((dta) => {
          props.setTabState(id);
        });
      });
    }

    setShow({
      dispalyMenu: false,
      showActivity: false,
      showContact: false,
      showMove: false,
      showDash: true,
    });
    // IndexSetState({ index: index });
    IndexSetStateId({ index: datass._id });
  };

  const menu = (
    <StyleCardDropdownWrapper>
      <Menu>
        {props.tabkey !== props.tabFirstId && (
          <Menu.Item key="0">
            <a href onClick={handleMove}>
              Move
            </a>
          </Menu.Item>
        )}
        <Menu.Item key="1">
          <a href onClick={handleAssignContact}>
            Assign Contact
          </a>
        </Menu.Item>
        {props.tabkey !== props.tabFirstId && (
          <Menu.Item key="2">
            <a href onClick={handleActivity}>
              View Activity
            </a>
          </Menu.Item>
        )}
        {props.tabkey !== props.tabFirstId && (
          <Menu.Item key="3">
            <a href onClick={handleContact}>
              Contact
            </a>
          </Menu.Item>
        )}
        <Menu.Item key="4">
          <a
            href
            onClick={() =>
              props.tabkey !== props.tabFirstId
                ? handleModalOpen()
                : message.error(
                    "Sorry you can't edit until it is moved to the next step"
                  )
            }
          >
            Edit
          </a>
        </Menu.Item>
        <Menu.Item key="5">
          <a
            href
            onClick={() =>
              props.tabkey !== props.tabFirstId
                ? handleDelete()
                : message.error(
                    "Sorry you can't delete until it is moved to the next step"
                  )
            }
          >
            Delete
          </a>
        </Menu.Item>
      </Menu>
    </StyleCardDropdownWrapper>
  );
  // const data = [];

  return (
    <>
      <StickyMessage
        isActive={isActive}
        message={stickyMessage}
        isAutoHide={isAutoHide}
        onClick={() => {
          props.setTabState(tabData[0]?._id);
          close();
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      {loading && first ? (
        <>
          <RelationshipLoaderSkeleton />
        </>
      ) : (
        <>
          <StyledWrapper>
            <Row>
              {stateData &&
                stateData &&
                stateData.data.length > 0 &&
                stateData.data.map((k, index) =>
                  k.type === tab.key && k.type !== props.tabFirstId ? (
                    //  &&
                    // k.claimedBy &&
                    // k.claimedBy._id ===
                    //   (localStorage.getItem("userId") || (user && user?._id))
                    <>
                      <Col
                        className="card_wrap"
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={8}
                        xxl={6}
                      >
                        <StyledListBox
                          className={
                            state &&
                            state.showActivity === true &&
                            IndexStateId.index === k._id
                              ? "view_activity_bg_class"
                              : ""
                          }
                          key={index}
                        >
                          {
                            <StyledImg>
                              <CupSvg />
                            </StyledImg>
                          }
                          <StyledCardHeaderWrapper>
                            {
                              <StyledAvatarGroupAllWrapper>
                                <StyledAvatarGroupWrapper>
                                  {state.showActivity &&
                                  k._id == IndexStateId.index
                                    ? ""
                                    : k.members &&
                                      k.members &&
                                      k.members.length > 0
                                    ? k.members.slice(0, 4).map((ks, index) => (
                                        <StyledAvatarGroup
                                          maxCount={0}
                                          key={index}
                                        >
                                          <UserAvatar
                                            size={40}
                                            src={
                                              ks && ks.avatar && ks.avatar?.path
                                                ? imagePath(ks.avatar?.path)
                                                : ""
                                            }
                                            fullName={`${ks?.firstName || ""} ${
                                              ks?.lastName || ""
                                            }`}
                                          />
                                        </StyledAvatarGroup>
                                      ))
                                    : ""}
                                  {k.members && k.members.length >= 5 && (
                                    <Avatar
                                      size={40}
                                      fullName=""
                                      style={{
                                        color: "#0061A4",
                                        backgroundColor: "#F3F5F4",
                                        fontWeight: "bold",
                                        display: "flex",
                                        alignItems: "center",
                                        lineHeight: "15px",
                                        marginLeft: "-10px",
                                        zIndex: 1,
                                        border: "2px solid #747487",
                                      }}
                                    >
                                      {k.members.length > 99
                                        ? "+99"
                                        : `+${k.members.length - 4}`}
                                    </Avatar>
                                  )}
                                </StyledAvatarGroupWrapper>
                              </StyledAvatarGroupAllWrapper>
                            }

                            {state &&
                            state.showDash === false &&
                            IndexStateId.index === k._id ? (
                              <StyledCloseButton
                                className={
                                  state &&
                                  state.showActivity === true &&
                                  IndexStateId.index === k._id
                                    ? "show_activity_left_arrow"
                                    : ""
                                }
                              >
                                {state &&
                                state.showActivity === true &&
                                IndexStateId.index === k._id &&
                                tab.key ? (
                                  <ImArrowLeft2
                                    className="card_close_icon"
                                    onClick={(e) => handleClose(e)}
                                  />
                                ) : (
                                  IndexStateId.index === k._id && (
                                    <IoIosClose
                                      className="card_close_icon"
                                      onClick={(e) => handleClose(e)}
                                    />
                                  )
                                )}
                              </StyledCloseButton>
                            ) : (
                              <StyledOpenMenuWrapper>
                                <Dropdown
                                  overlay={menu}
                                  trigger={["click"]}
                                  style={{ marginLeft: "auto" }}
                                >
                                  <a
                                    className="ant_dropdown_link"
                                    href
                                    onClick={(e) => handleClick(e, index)}
                                  >
                                    <BsThreeDotsVertical className="three_dots_vertical" />
                                  </a>
                                </Dropdown>
                              </StyledOpenMenuWrapper>
                            )}
                          </StyledCardHeaderWrapper>

                          {state &&
                          state.showActivity === true &&
                          IndexStateId.index === k._id ? null : (
                            <StyledListContentDescription>
                              <StyledContactDescriptionWrapper>
                                {(k.firstName || k.lastName) && (
                                  <h5
                                    className={
                                      k.firstNameVisibility === false &&
                                      tab.key === props.tabFirstId
                                        ? "reguler_text"
                                        : "reguler_text"
                                    }
                                  >
                                    {k.firstName} {k.lastName}
                                  </h5>
                                )}

                                {k.location && k.location.address && (
                                  <h5
                                    className={
                                      k.connected
                                        ? "reguler_location_text"
                                        : "reguler_location_text"
                                    }
                                  >
                                    {k.location && k.location.address}
                                  </h5>
                                )}

                                <h5 className="source_name">
                                  {k?.sourceId?.source
                                    ? k?.sourceId?.source
                                    : k.claimedBy
                                    ? k.claimedBy.firstName +
                                      " " +
                                      k.claimedBy.lastName
                                    : ""}
                                </h5>
                              </StyledContactDescriptionWrapper>

                              <StyledTimeAndImageWrapper>
                                <h5>
                                  {k.temporary === "yes"
                                    ? "Contact Quick!"
                                    : k.temporary !== "yes" &&
                                      tab.key !== tabData[0]?._id
                                    ? "Last Contacted:"
                                    : "Came In"}
                                </h5>
                                <h3>{k.duration}</h3>
                                <span>
                                  {k.temporary === "yes"
                                    ? ""
                                    : tab.key !== tabData[0]?._id
                                    ? ""
                                    : "ago"}
                                </span>

                                <StyleImageWrapper>
                                  {k.claimed === false &&
                                    user &&
                                    user.permissions &&
                                    !user?.permissions?.includes("admin") && (
                                      <Progress
                                        type="circle"
                                        showInfo={false}
                                        strokeWidth="6"
                                        strokeColor={{
                                          "10%": "#FC3C3C",
                                          "10%": "#AD1B1B",
                                        }}
                                        width={160}
                                        percent={parseInt(k.minutes / 60) * 100}
                                      />
                                    )}
                                  {k.claimed === true &&
                                    tab.key === tabData[1]?._id && (
                                      <Progress
                                        type="circle"
                                        showInfo={false}
                                        strokeWidth="6"
                                        strokeColor={{
                                          "0%": "#5BFCB9",
                                          "100%": "#1BAD70",
                                        }}
                                        // percent={100}

                                        percent={
                                          k.temporary === "yes"
                                            ? parseFloat(k.minutes) / 1200
                                            : (parseInt(k.minutes) / 60) * 100 <
                                              0.5
                                            ? 0.5
                                            : (parseInt(k.minutes) / 60) * 100
                                        }
                                        width={160}
                                      />
                                    )}
                                  {tab.key !== tabData[1]?._id && (
                                    <Progress
                                      type="circle"
                                      showInfo={false}
                                      strokeWidth="6"
                                      strokeColor={{
                                        "0%": "#5BFCB9",
                                        "100%": "#1BAD70",
                                      }}
                                      // percent={100}
                                      percent={
                                        (parseInt(k.minutes) / 60) * 100 < 0.5
                                          ? 0.5
                                          : (parseInt(k.minutes) / 60) * 100
                                      }
                                      width={160}
                                    />
                                  )}
                                </StyleImageWrapper>
                              </StyledTimeAndImageWrapper>
                              <ConnectButtonWrapper>
                                {k.type === tabData[1]?._id && (
                                  <Button
                                    onClick={(e) =>
                                      handleContactConnect(e, index, "connect")
                                    }
                                    className="earned_button"
                                    shape="round"
                                    size="large"
                                    icon={
                                      <TiLockOpen className="earned_lock_button" />
                                    }
                                  >
                                    Contact
                                  </Button>
                                )}
                                {k.type !== tabData[1]?._id && (
                                  <Button
                                    onClick={(e) =>
                                      handleContactConnect(e, index, "connect")
                                    }
                                    className="earned_button"
                                    shape="round"
                                    size="large"
                                    icon={
                                      <TiLockOpen className="earned_lock_button" />
                                    }
                                  >
                                    Contact
                                  </Button>
                                )}
                              </ConnectButtonWrapper>
                            </StyledListContentDescription>
                          )}
                          <div
                            className={
                              (state &&
                                state.showMove &&
                                IndexStateId.index === k._id) ||
                              (state &&
                                state.showContact &&
                                IndexStateId.index === k._id)
                                ? "view_move_to_bg"
                                : ""
                            }
                          >
                            {state &&
                              state.showActivity === false &&
                              state &&
                              state.showContact === true &&
                              state &&
                              state.showMove === false &&
                              IndexStateId.index === k._id && (
                                <Row
                                  style={{ height: "100%" }}
                                  gutter={4}
                                  justify="space-around"
                                  align="middle"
                                >
                                  <Col sm={{ span: 24 }} md={{ span: 24 }}>
                                    <StyledShowContact>
                                      <StyledContactDescriptionWrapper
                                        height={"125px"}
                                      >
                                        {(k.firstName || k.lastName) && (
                                          <h5 className={"reguler_text"}>
                                            {k.firstName} {k.lastName}
                                          </h5>
                                        )}

                                        {/* {k.email && (
                                    <h5  className={"reguler_text"}>
                                      {k.email}
                                    </h5>
                                  )} */}
                                      </StyledContactDescriptionWrapper>
                                      {k.phoneNumber && (
                                        <ContactRow
                                          gutter={4}
                                          justify="space-around"
                                          align="middle"
                                          onClick={(e) =>
                                            phoneNumberHandler(
                                              e,
                                              k.phoneNumber,
                                              "phone",
                                              k
                                            )
                                          }
                                        >
                                          <Col flex="none">
                                            <ContactButtonStyle
                                              type="primary"
                                              shape="circle"
                                              size="large"
                                              icon={
                                                <IoCallSharp className="connect_button_icon" />
                                              }
                                            />
                                          </Col>
                                          <Col
                                            flex="auto"
                                            style={{ display: "flex" }}
                                          >
                                            <ContactText>Call Now</ContactText>
                                          </Col>
                                        </ContactRow>
                                      )}
                                      {k.email && (
                                        <ContactRow
                                          gutter={4}
                                          justify="space-around"
                                          align="middle"
                                          onClick={(e) =>
                                            phoneNumberHandler(
                                              e,
                                              k.email,
                                              "email",
                                              k
                                            )
                                          }
                                        >
                                          <Col flex="none">
                                            <ContactButtonStyle
                                              type="primary"
                                              shape="circle"
                                              size="large"
                                              icon={
                                                <MdEmail className="connect_button_icon" />
                                              }
                                            />
                                          </Col>
                                          <Col
                                            flex="auto"
                                            style={{ display: "flex" }}
                                          >
                                            <ContactText>Email Now</ContactText>
                                          </Col>
                                        </ContactRow>
                                      )}
                                      {k.phoneNumber && (
                                        <ContactRow
                                          gutter={4}
                                          justify="space-around"
                                          align="middle"
                                          onClick={(e) =>
                                            phoneNumberHandler(
                                              e,
                                              k.phoneNumber,
                                              "text",
                                              k
                                            )
                                          }
                                        >
                                          <Col flex="none">
                                            <ContactButtonStyle
                                              type="primary"
                                              shape="circle"
                                              size="large"
                                              icon={
                                                <TextSvg className="contact_text_icon" />
                                              }
                                            />
                                          </Col>
                                          <Col
                                            flex="auto"
                                            style={{ display: "flex" }}
                                          >
                                            <ContactText>Text Now</ContactText>
                                          </Col>
                                        </ContactRow>
                                      )}
                                    </StyledShowContact>
                                  </Col>
                                </Row>
                              )}

                            {state &&
                              state.showActivity === true &&
                              state &&
                              state.showContact === false &&
                              state &&
                              state.showMove === false &&
                              IndexStateId.index == k._id && (
                                <StyledViewActivityWrapper>
                                  {k.activities &&
                                    k.activities.map((ks, index) => (
                                      <StyledShowActivity key={index}>
                                        <div className="activity_name_call_info">
                                          {/* <h4>{ks.name}</h4> */}
                                          {/* <span>{ks.name}</span>
                                        <span>{ks.text}</span> */}

                                          <p className="user_details">
                                            <span>{ks.name}</span> {ks.text}
                                          </p>

                                          <div className="create_duration_wrapper">
                                            <p className="create_duration">
                                              {getFormattedTime(ks.createdAt)}
                                            </p>
                                            <span>on</span>
                                            <p className="create_duration">
                                              {getFormattedDate(ks.createdAt)}
                                            </p>
                                          </div>

                                          {/* <br></br>
                                        <span> { getFormattedTime(ks.createdAt)} </span>  
                                        <span> { getFormattedDate(ks.createdAt)} </span> */}
                                        </div>
                                        {/* <p>
                                    {ks.time}
                                    {" on "}
                                    {ks.date}
                                  </p> */}
                                      </StyledShowActivity>
                                    ))}
                                </StyledViewActivityWrapper>
                              )}
                            {state &&
                              state.showMove === true &&
                              IndexStateId.index === k._id && (
                                <StyledMoveToULDetail>
                                  {tabData &&
                                    tabData.map(
                                      (ks, index) =>
                                        ks.type !== props.tabFirstId && (
                                          <li
                                            onClick={(e) =>
                                              handleMoveTab(
                                                e,
                                                ks._id,
                                                ks.type,
                                                k
                                              )
                                            }
                                          >
                                            Move To {ks.type}
                                          </li>
                                        )
                                    )}
                                </StyledMoveToULDetail>
                              )}
                          </div>
                        </StyledListBox>
                      </Col>
                    </>
                  ) : !loading &&
                    k.type === props.tabFirstId &&
                    tab.key === props.tabFirstId &&
                    (k.claimedBy === undefined ||
                      (k.claimedBy &&
                        k.claimedBy._id !==
                          (localStorage.getItem("userId") ||
                            (user && user?._id))) ||
                      !k.sourceId) ? (
                    <Col
                      className="card_wrap"
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={8}
                      xxl={6}
                    >
                      <StyledListBox
                        className={
                          (state &&
                          state.showActivity === true &&
                          IndexState.index == index
                            ? "view_activity_bg_class"
                            : "") || k?._id == pulse
                            ? "setPulse"
                            : "" ||
                              (k.claimed === true &&
                              !user?.permissions?.includes("admin")
                                ? "touched_lock_class"
                                : "")
                        }
                        key={index}
                      >
                        <StyledImg>
                          {state &&
                          state.showActivity === true &&
                          IndexStateId.index === k._id ? (
                            <img
                              src="../../images/brownListArrow.svg"
                              alt="img"
                            />
                          ) : tab.key === props.tabFirstId &&
                            k.claimed === false &&
                            k.connected === false ? (
                            <img
                              src={bgImage || "../../images/listArrow.png"}
                              alt="img"
                            />
                          ) : (
                            ""
                          )}
                          {tab.key === props.tabFirstId &&
                            k.claimed === true &&
                            !user?.permissions?.includes("admin") && (
                              <img src="../../images/lock.png" alt="img" />
                            )}
                        </StyledImg>
                        {/* <div className={ k?._id == pulse ?"setPulse":""}> */}

                        <StyledCardHeaderWrapper>
                          {k.claimed ? (
                            <AvatarWrapper>
                              <UserAvatar
                                size={40}
                                src={
                                  k.claimedBy &&
                                  k.claimedBy.avatar &&
                                  k.claimedBy.avatar?.path
                                    ? imagePath(k.claimedBy.avatar?.path)
                                    : k.claimedBy?.firstName &&
                                      k.claimedBy?.lastName
                                    ? ""
                                    : // : "/logo192.png"
                                      favImage
                                }
                                fullName={`${k.claimedBy?.firstName || ""} ${
                                  k.claimedBy?.lastName || ""
                                }`}
                              />
                            </AvatarWrapper>
                          ) : k.members && k.members && k.members.length > 0 ? (
                            <StyledAvatarGroupAllWrapper>
                              <StyledAvatarGroupWrapper>
                                {k.members && k.members && k.members.length > 0
                                  ? k.members.slice(0, 4).map((ks, index) => (
                                      <StyledAvatarGroup
                                        maxCount={0}
                                        key={index}
                                      >
                                        <UserAvatar
                                          key={index}
                                          size={40}
                                          src={
                                            ks && ks.avatar && ks.avatar?.path
                                              ? imagePath(ks.avatar?.path)
                                              : ""
                                          }
                                          fullName={`${ks?.firstName || ""} ${
                                            ks?.lastName || ""
                                          }`}
                                        />
                                      </StyledAvatarGroup>
                                    ))
                                  : ""}
                                {k.members && k.members.length >= 5 && (
                                  <Avatar
                                    size={40}
                                    style={{
                                      color: "#0061A4",
                                      backgroundColor: "#F3F5F4",
                                      fontWeight: "bold",
                                      display: "flex",
                                      alignItems: "center",
                                      lineHeight: "15px",
                                      marginLeft: "-10px",
                                      zIndex: 1,
                                      border: "2px solid #747487",
                                    }}
                                    fullName=""
                                  >
                                    {k.members.length > 99
                                      ? "+99"
                                      : `+${k.members.length - 4}`}
                                  </Avatar>
                                )}
                              </StyledAvatarGroupWrapper>
                            </StyledAvatarGroupAllWrapper>
                          ) : (
                            ""
                          )}
                          {state &&
                          state.showDash === false &&
                          IndexStateId.index === k._id ? (
                            <StyledCloseButton
                              className={
                                state &&
                                state.showActivity === true &&
                                IndexStateId.index === k._id
                                  ? "show_activity_left_arrow"
                                  : ""
                              }
                            >
                              {state &&
                              state.showActivity === true &&
                              IndexStateId.index === k._id ? (
                                <ImArrowLeft2
                                  className="card_close_icon"
                                  onClick={(e) => handleClose(e)}
                                />
                              ) : (
                                IndexStateId.index === k._id && (
                                  <IoIosClose
                                    className="card_close_icon"
                                    onClick={(e) => handleClose(e)}
                                  />
                                )
                              )}
                            </StyledCloseButton>
                          ) : (
                            <StyledOpenMenuWrapper>
                              <Dropdown
                                overlay={menu}
                                trigger={["click"]}
                                style={{ marginLeft: "auto" }}
                              >
                                <a
                                  className="ant_dropdown_link"
                                  href
                                  onClick={(e) => handleClick(e, index)}
                                >
                                  <BsThreeDotsVertical className="three_dots_vertical" />
                                </a>
                              </Dropdown>
                            </StyledOpenMenuWrapper>
                          )}
                        </StyledCardHeaderWrapper>
                        {state &&
                        state.showActivity === true &&
                        IndexStateId.index === k._id ? null : (
                          <StyledListContentDescription>
                            {k.claimed === false ? (
                              <StyledContactDescriptionWrapper>
                                {(k.firstName || k.lastName) && (
                                  <h5
                                    className={
                                      k.firstNameVisibility === false &&
                                      tab.key === props.tabFirstId
                                        ? "blur_reguler_text"
                                        : "reguler_text"
                                    }
                                  >
                                    {k.firstName} {"  "}
                                    {k.lastName?.charAt(0)}
                                    {"."}
                                  </h5>
                                )}

                                {k.email && (
                                  <h5
                                    className={
                                      k.emailVisibility === false &&
                                      tab.key === props.tabFirstId
                                        ? "blur_reguler_text"
                                        : "reguler_text"
                                    }
                                  >
                                    {k.email}
                                  </h5>
                                )}

                                {k.phoneNumber && (
                                  <h5
                                    className={
                                      k.phoneNumberVisibility === false &&
                                      tab.key === props.tabFirstId
                                        ? "blur_reguler_text"
                                        : "reguler_text"
                                    }
                                  >
                                    {k.phoneNumber}
                                  </h5>
                                )}

                                {k.location && k.location.address && (
                                  <h5
                                    className={
                                      k.addressVisibility === false &&
                                      tab.key === props.tabFirstId
                                        ? "reguler_location_blur_text"
                                        : "reguler_location_text"
                                    }
                                  >
                                    {k.location && k.location.address}
                                  </h5>
                                )}

                                <h5 className="source_name">
                                  {k?.sourceId?.source
                                    ? k?.sourceId?.source
                                    : k.claimedBy
                                    ? k.claimedBy.firstName +
                                      " " +
                                      k.claimedBy.lastName
                                    : ""}
                                </h5>
                              </StyledContactDescriptionWrapper>
                            ) : (
                              <StyledContactDescriptionWrapper>
                                {/* {members.includes(item._id) ? "included" : "no"} */}

                                {(k.firstName || k.lastName) && (
                                  <h5
                                    className={
                                      k.firstNameVisibility === false &&
                                      tab.key === props.tabFirstId
                                        ? "blur_reguler_text"
                                        : "blur_reguler_text"
                                    }
                                  >
                                    {k.firstName} {k.lastName}
                                  </h5>
                                )}
                                {k.email && (
                                  <h5
                                    className={
                                      k.connected
                                        ? "blur_reguler_text"
                                        : "blur_reguler_text"
                                    }
                                  >
                                    {k.email}
                                  </h5>
                                )}
                                {k.phoneNumber && (
                                  <h5
                                    className={
                                      k.connected
                                        ? "blur_reguler_text"
                                        : "blur_reguler_text"
                                    }
                                  >
                                    {k.phoneNumber}
                                  </h5>
                                )}

                                {k.location && k.location.address && (
                                  <h5
                                    className={
                                      k.connected
                                        ? "reguler_location_blur_text"
                                        : "reguler_location_blur_text"
                                    }
                                  >
                                    {k.location && k.location.address}
                                  </h5>
                                )}

                                <h5 className="source_name">
                                  {k?.sourceId?.source
                                    ? k?.sourceId?.source
                                    : k.claimedBy
                                    ? k.claimedBy.firstName +
                                      " " +
                                      k.claimedBy.lastName
                                    : ""}
                                </h5>
                              </StyledContactDescriptionWrapper>
                            )}
                            {/* {animation.dispalyClock && ( */}
                            <StyledTimeAndImageWrapper>
                              <h5>Came In</h5>

                              <h3>{k.duration}</h3>
                              <span>ago</span>
                              <StyleImageWrapper>
                                {k.claimed === false &&
                                  parseInt(k.minutes) < 60 && (
                                    <Progress
                                      type="circle"
                                      showInfo={false}
                                      strokeWidth="6"
                                      strokeColor={{
                                        "0%": "red",
                                        "30%": "red",
                                        "80%": "orange",
                                        "100%": "#ffcc2f",
                                      }}
                                      width={160}
                                      percent={
                                        (parseInt(k.minutes) / 60) * 100 < 0.5
                                          ? 0.5
                                          : (parseInt(k.minutes) / 60) * 100
                                      }
                                    />
                                  )}
                                {k.claimed === false &&
                                  parseInt(k.minutes) >= 60 && (
                                    <Progress
                                      type="circle"
                                      showInfo={false}
                                      strokeWidth="6"
                                      strokeColor={{
                                        "100%": "#ffcc2f",
                                      }}
                                      width={160}
                                      percent={(parseInt(k.minutes) / 60) * 100}
                                    />
                                  )}
                                {k.claimed === true && (
                                  <Progress
                                    type="circle"
                                    showInfo={false}
                                    strokeWidth="6"
                                    strokeColor={{
                                      "0%": "#FC3C3C",
                                      "100%": "#AD1B1B",
                                    }}
                                    percent={100}
                                    width={160}
                                  />
                                )}
                              </StyleImageWrapper>
                            </StyledTimeAndImageWrapper>
                            {/* )} */}

                            <ConnectButtonWrapper>
                              {/* animation.dispalyClock &&  */}
                              {(k.claimed === false ||
                                user?.permissions?.includes("admin")) && (
                                <ConnectButton
                                  onClick={(e) =>
                                    handleContactConnect(e, index, "contact")
                                  }
                                />
                              )}

                              {k.claimed === true &&
                                !user?.permissions?.includes("admin") && (
                                  <Button
                                    className="claimed_button"
                                    shape="round"
                                    size="large"
                                    disabled={true}
                                    icon={
                                      <FaLock className="claimed_lock_button" />
                                    }
                                  >
                                    Claimed
                                  </Button>
                                )}
                            </ConnectButtonWrapper>
                          </StyledListContentDescription>
                        )}
                        <div
                          className={
                            (state &&
                              state.showMove &&
                              IndexStateId.index === k._id) ||
                            (state &&
                              state.showContact &&
                              IndexStateId.index === k._id)
                              ? "view_move_to_bg"
                              : ""
                          }
                        >
                          {state &&
                            state.showActivity == false &&
                            state &&
                            state.showContact == true &&
                            state &&
                            state.showMove === false &&
                            IndexStateId.index === k._id && (
                              <Row
                                style={{ height: "100%" }}
                                gutter={4}
                                justify="space-around"
                                align="middle"
                              >
                                <Col sm={{ span: 24 }} md={{ span: 24 }}>
                                  <StyledShowContact>
                                    <StyledContactDescriptionWrapper>
                                      {/* {(k?.firstName || "") &&
                                      (k?.lastName || "") && (
                                        <h5 className={"reguler_text"}>
                                          {k?.firstName || ""}{" "}
                                          {k?.lastName || ""}
                                        </h5>
                                      )} */}

                                      {(k.firstName || k.lastName) && (
                                        <h5 className={"reguler_text"}>
                                          {k.firstName} {k.lastName}
                                        </h5>
                                      )}

                                      {k.email && (
                                        <h5 className={"reguler_text"}>
                                          {k.email}
                                        </h5>
                                      )}
                                    </StyledContactDescriptionWrapper>
                                    <ContactRow
                                      gutter={4}
                                      justify="space-around"
                                      align="middle"
                                    >
                                      <Col flex="none">
                                        <ContactButtonStyle
                                          type="primary"
                                          shape="circle"
                                          size="large"
                                          icon={
                                            <IoCallSharp className="connect_button_icon" />
                                          }
                                        />
                                      </Col>
                                      <Col
                                        flex="auto"
                                        style={{ display: "flex" }}
                                      >
                                        <ContactText>Call Now</ContactText>
                                      </Col>
                                    </ContactRow>
                                    <ContactRow
                                      gutter={4}
                                      justify="space-around"
                                      align="middle"
                                    >
                                      <Col flex="none">
                                        <ContactButtonStyle
                                          type="primary"
                                          shape="circle"
                                          size="large"
                                          icon={
                                            <MdEmail className="connect_button_icon" />
                                          }
                                        />
                                      </Col>
                                      <Col
                                        flex="auto"
                                        style={{ display: "flex" }}
                                      >
                                        <ContactText>Email Now</ContactText>
                                      </Col>
                                    </ContactRow>
                                    <ContactRow
                                      gutter={4}
                                      justify="space-around"
                                      align="middle"
                                    >
                                      <Col flex="none">
                                        <ContactButtonStyle
                                          type="primary"
                                          shape="circle"
                                          size="large"
                                          icon={
                                            <TextSvg className="connect_button_icon" />
                                          }
                                        />
                                      </Col>
                                      <Col
                                        flex="auto"
                                        style={{ display: "flex" }}
                                      >
                                        <ContactText>Text Now</ContactText>
                                      </Col>
                                    </ContactRow>
                                  </StyledShowContact>
                                </Col>
                              </Row>
                            )}
                          {state &&
                            state.showActivity == true &&
                            state &&
                            state.showContact == false &&
                            state &&
                            state.showMove == false &&
                            IndexState.index == index && (
                              <StyledViewActivityWrapper>
                                {k.activity &&
                                  k.activity.map((ks, index) => (
                                    <StyledShowActivity key={index}>
                                      <div className="activity_name_call_info">
                                        <h4>{ks.name}</h4>
                                        <span>{ks.description}</span>
                                      </div>
                                      <p>
                                        {ks.time}
                                        {" on "}
                                        {ks.date}
                                      </p>
                                    </StyledShowActivity>
                                  ))}
                              </StyledViewActivityWrapper>
                            )}
                          {state &&
                            state.showMove === true &&
                            IndexStateId.index === k._id && (
                              <StyledMoveToULDetail>
                                {tabData &&
                                  tabData.map((ks, index) => (
                                    <li
                                      onClick={(e) =>
                                        handleMoveTab(e, ks._id, ks.type, k)
                                      }
                                    >
                                      Move To {ks.type}
                                    </li>
                                  ))}
                              </StyledMoveToULDetail>
                            )}
                        </div>
                      </StyledListBox>
                    </Col>
                  ) : animation &&
                    animation.showActivity == true &&
                    animation.dispalyClock == false &&
                    IndexStateId.index === k._id ? (
                    <Col
                      className="card_wrap"
                      xs={24}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={8}
                      xxl={6}
                    >
                      <AnimationCard>
                        <lottie-player
                          autoplay
                          loop
                          mode="normal"
                          src={animationArr[animationRandomNumber]}
                          style={{ width: "100%", marginTop: "120px" }}
                        ></lottie-player>
                      </AnimationCard>
                    </Col>
                  ) : (
                    ""
                  )
                )}
            </Row>

            <AddRelationshipModal
              onClick={(value) => setModalEdit(value)}
              modalState={modalEdit}
              edit={true}
              id={stateData.data[IndexState.index]}
            />

            {/* <Relationship pagination={pagination}></Relationship> */}
          </StyledWrapper>
          <AssignContact
            modalState={assignVisible}
            setModelState={setAssignVisisble}
            id={
              stateData &&
              stateData.data &&
              stateData.data[IndexState.index] &&
              stateData.data[IndexState.index]?._id
            }
            data={stateData.data[IndexState.index]}
          ></AssignContact>
        </>
      )}
    </>
  );
};

export default RelationshipContent;
