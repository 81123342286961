import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { Container } from "../../../../ui/Layout/Container";
import { Heading } from "../../../../ui/Heading";
import { Card } from "../../../../ui/Card";
import { Form, message, Col, Row, Modal, Input, Checkbox } from "antd";
import { PrimaryButton, SecondaryButton } from "ui/Button";
import { FormInput, FormItem } from "ui/Form";
import { HiPlus } from "react-icons/hi";
import {
  AddNewField,
  DeleteField,
  StyledAllInputWrapper,
  StyledInputIconWrapper,
  StyledInputWrapper,
  StyledSortable,
  StyledSourceName,
} from "./style";
import RelationshipTabAPI from "api/http/relationshipTab";
import { DeleteSvg } from "../../../../assets/svgs";
import Icon from "@ant-design/icons";
import { IoIosClose } from "react-icons/io";
import { Prompt } from "react-router";
import { useBeforeunload } from "react-beforeunload";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import { StyledContentTitle } from "Components/Relationship/TheRelationship.styles";
import { FormSelect } from "ui/Form/index";
import { paginationObj, querystring } from "utils/fn";
import RelationshipAPI from "api/http/relationship";
import { AiOutlineMenu } from "react-icons/ai";
import useSocket from "utils/hooks/useSocket";
import config from "helpers/config";
import { getDynamicMenuString } from "utils/fn";
import { useSelector } from "react-redux";
import { SocketContext } from "utils/context/socket";

const Relationship = () => {
  const [form] = Form.useForm();
  const [originData, setOriginData] = useState();
  const [tabData, setTabData] = useState([]);
  const [dataChange, setDataChange] = useState(false);

  const [oldData, setOldData] = useState([]);
  const [tabAllData, setAllTabData] = useState([]);
  const [defaulState, setDefaultState] = useState(false);
  const [data, setData] = useState();
  const [tabWiseData, setTabWiseData] = useState();
  const [visible, setVisible] = useState(false);
  const [optionsData, setOptionsData] = useState([]);
  const [current, setCurrent] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [convertedData, setConvertData] = useState([]);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const { configData } = useSelector((state) => state.configData);

  const [pagination, setPagination] = useState({});
  const [paginations, setPaginations] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
  });
  const [activeInput, setActiveInput] = useState("");

  const SortableContainer = sortableContainer(({ children }) => {
    return <StyledAllInputWrapper>{children}</StyledAllInputWrapper>;
  });
  const SortableItem = sortableElement(({ value }) => (
    <StyledSortable>{value}</StyledSortable>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let arr = tabData;
    // var b = arr[oldIndex];
    // arr[oldIndex] = arr[newIndex];
    // arr[newIndex] = b;
    setDataChange(true);
    if (newIndex >= arr.length) {
      var k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    let newDataIndex = [];
    let i = 1;
    for (const a of arr) {
      let obj = {
        type: a.type,
        sequence: i++,
        _id: a._id,
      };
      newDataIndex.push(obj);
    }

    function compare(a, b) {
      const bandA = b.sequence;
      const bandB = a.sequence;

      let comparison = 0;
      if (bandB > bandA) {
        comparison = 1;
      } else if (bandB < bandA) {
        comparison = -1;
      }
      return comparison;
    }

    newDataIndex.sort(compare);
    setTabData(newDataIndex);
  };

  // /* Socket Config /
  const socket = useContext(SocketContext);

  // /* -- Socket Config -- /

  useEffect(() => {
    getRelationshipTabList();
  }, []);

  const DeleteIcon = (props) => <Icon component={DeleteSvg} {...props} />;

  const getRelationshipTabList = useCallback((pagination) => {
    RelationshipTabAPI.getRelationshipTabList()
      .then(({ data }) => {
        setOriginData(data);
        let arr = [];
        let datas = data;
        let arrs = [];
        let newArr = [];
        for (const d of data) {
          // if (d.type !== "Potentials") {
          let obj = { label: d.type, value: d._id };
          arrs.push(obj);
          // }
        }

        setOptionsData(arrs);
        function compare(a, b) {
          const bandA = b.sequence;
          const bandB = a.sequence;

          let comparison = 0;
          if (bandB > bandA) {
            comparison = 1;
          } else if (bandB < bandA) {
            comparison = -1;
          }
          return comparison;
        }

        datas.sort(compare);

        setOldData(datas);
        setTabData(datas);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  }, []);

  // useEffect(() => {
  //   socket.connect();
  //   socket.emit("join", "relationshipTab");
  //   socket.on("stage-updated", (data) => {
  //      getRelationshipTabList()
  //   });
  //   return () => {
  //     socket.emit("leave", "relationshipTab");
  //   };

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getRelationshipTabList,socket]);

  const onFinish = (values) => {
    setShowPrompt(false);
    if (tabWiseData.length === convertedData.length) {
      // message.success("Success");
      RelationshipTabAPI.deleteRelationshipTab(deleteId, { deleteId });

      for (const dta of convertedData) {
        if (dta && dta.id) {
          RelationshipAPI.updateRelationship(dta.id, { type: dta.type });

          let i = 1;
          for (const dataas of tabData) {
            if (dataas && dataas._id) {
              let obj = { sequence: i++ };
              RelationshipTabAPI.updateRelationshipTab(dataas._id, obj);
            }
          }
          setVisible(false);
        }
      }
      getRelationshipTabList();
    } else {
      message.error("Please select all");
    }
  };

  const handleInput = (e) => {
    setShowPrompt(true);
    setData({ type: e.target.value, sequence: tabData.length + 1 });
  };
  const handleAddTabPlus = (e) => {
    // setShowPrompt(false);
    if (data !== undefined) {
      let arr = tabData;
      setDataChange(true);
      arr.push(data);
      setTabData(arr);
      setData(undefined);
    } else {
      message.error("Please add relationship stage");
    }
  };
  const handleDelete = (e, type, _id) => {
    if (_id) {
      RelationshipAPI.getRelationshipList(
        querystring(
          paginationObj({
            ...pagination,
            filter: { type: _id },
            populate: [{ path: "sourceId" }],
          })
        )
      ).then(({ data }) => {
        setDeleteId(_id);
        if (data && data.data.length > 0) {
          setTabWiseData(data?.data);
          let datas = [...optionsData];

          let objIndex = datas.findIndex((x) => x.label == type);
          if (objIndex !== -1) {
            datas.splice(objIndex, 1);
            setOptionsData(datas);
          }
          setVisible(true);
          // getRelationshipTabList();
        } else {
          let tabArr = [...tabData];
          let objIndex = tabArr.findIndex((x) => x.type == type);
          if (objIndex !== -1) {
            tabArr.splice(objIndex, 1);
          }

          setTabData(tabArr);

          RelationshipTabAPI.deleteRelationshipTab(_id, { _id });
          //  getRelationshipTabList();
        }
      });
    } else {
      let tabArr = [...tabData];

      let objIndex = tabArr.findIndex((x) => x.type == type);
      if (objIndex !== -1) {
        tabArr.splice(objIndex, 1);

        setTabData(tabArr);
      }
    }
  };

  const handleChange = (value, id) => {
    let arr = convertedData;
    let objIndex = arr.findIndex((x) => x.id == id);
    if (objIndex >= 0) {
      arr.splice(objIndex, 1);
    }
    let obj = { id: id, type: value };
    arr.push(obj);
    setConvertData(arr);
  };

  const handleStageChange = (e, id, index) => {
    setShowPrompt(true);
    console.log(e.target.value);
    let datas = [...tabData];
    let objIndex = datas.findIndex((x) => x._id == id);
    if (objIndex) {
      datas[objIndex].type = e.target.value;
    } else {
      datas[index].type = e.target.value;
    }
    setTabData(datas);
    setActiveInput(id);
  };

  const handleAddTab = (e) => {
    setShowPrompt(false);
    setDataChange(false);
    for (const d of tabData) {
      RelationshipTabAPI.getRelationshipTabList()
        .then(({ data }) => {
          setOriginData(data);
          let datas = data;

          function compare(a, b) {
            const bandA = b.sequence;
            const bandB = a.sequence;

            let comparison = 0;
            if (bandB > bandA) {
              comparison = 1;
            } else if (bandB < bandA) {
              comparison = -1;
            }
            return comparison;
          }

          datas.sort(compare);

          setOldData(datas);
          let objIndex = datas.findIndex((x) => x.sequence == d.sequence);

          if (objIndex !== -1 && datas?.[objIndex]._id) {
            RelationshipTabAPI.updateRelationshipTab(datas?.[objIndex]._id, {
              type: d.type,
            });
          } else {
            delete d._id;
            RelationshipTabAPI.addRelationshipTab(d).then((data) => {});
          }
        })
        .catch((error) => {
          if (error) {
            message.error(error.message);
          }
        });

      // if (dataas && dataas._id) {
      //   RelationshipTabAPI.updateRelationshipTab(dataas._id, obj);
      // } else if (dataas && !dataas._id) {
      //   RelationshipTabAPI.addRelationshipTab(obj);
      // }
    }

    message.success("Stage updated successfully");
    // getRelationshipTabList();
  };

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  return (
    <Container>
      {/* {dataChange === true && defaulState === false && (
        <Beforeunload
          onBeforeunload={() =>
            "You will lose all changes if you navigate away from this page without saving"
          }
        />
      )} */}
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Heading>
        {getDynamicMenuString(configData?.[0], ["connect", "relationships"])}
      </Heading>
      <Card padding="10px 15px 15px">
        <Row>
          <Col span={16}>
            <Form
              form={form}
              layout="vertical"
              onFinish={(e) => onFinish(e)}
              requiredMark={false}
            >
              <FormItem
                mb="35px"
                label={getDynamicMenuString(configData?.[0], [
                  "connect",
                  "relationships",
                ])}
                type="text"
                rules={[
                  {
                    required: true,
                    message: "This is required",
                  },
                ]}
              >
                <Row gutter={14}>
                  <Col span={22}>
                    <FormInput
                      placeholder="Add relationship stage name"
                      value={data?.type || ""}
                      maxWidth={"100%"}
                      onChange={(e) => handleInput(e)}
                    />
                  </Col>

                  <Col span={2}>
                    <AddNewField
                      shape="circle"
                      icon={<HiPlus className="plus_icon" />}
                      onClick={(e) => handleAddTabPlus(e)}
                    />
                  </Col>
                </Row>
              </FormItem>
              <SortableContainer onSortEnd={onSortEnd} disableAutoscroll={true}>
                {(tabData || []).map((ks, index) => (
                  <Row gutter={14}>
                    <Col span={22}>
                      <SortableItem
                        axis={"x"}
                        key={`item-${ks.type}`}
                        index={index}
                        value={
                          <Form
                            form={form}
                            layout="vertical"
                            requiredMark={false}
                          >
                            <FormItem type="text" name={ks.type}>
                              <StyledInputIconWrapper>
                                <AiOutlineMenu
                                  size={"20px"}
                                  style={{
                                    color: "rgb(0 0 0 / 48%)",
                                    marginRight: "10px",
                                  }}
                                />
                                <FormInput
                                  placeholder="Add relationship stage name"
                                  value={ks.type}
                                  onChange={(e) => {
                                    handleStageChange(e, ks._id, index);
                                  }}
                                  autoFocus={activeInput === ks?._id}
                                  maxWidth={"100%"}
                                />
                              </StyledInputIconWrapper>
                            </FormItem>
                          </Form>
                        }
                      />
                    </Col>
                    <Col span={2}>
                      <DeleteField
                        onClick={(e) => handleDelete(e, ks.type, ks._id)}
                      >
                        <SecondaryButton shape="circle" icon={<DeleteIcon />} />
                      </DeleteField>
                    </Col>
                  </Row>
                ))}
              </SortableContainer>
              <FormItem>
                <PrimaryButton
                  margin="15px 0 0 0"
                  htmlType="submit"
                  onClick={handleAddTab}
                >
                  Save
                </PrimaryButton>
              </FormItem>
            </Form>
          </Col>
          <Col span={8} />
        </Row>
      </Card>
      <>
        <Modal
          visible={visible}
          onCancel={() => setVisible(false)}
          width={"960px"}
          footer={null}
          destroyOnClose={true}
          style={{ borderRadius: "5px" }}
          className="modal_wrapper"
          closeIcon={<IoIosClose className="modal_close_icon" />}
          maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
          focusTriggerAfterClose={false}
          bodyStyle={{ overflowY: "scroll" }}
        >
          <StyledContentTitle>Manage Relationship Stage</StyledContentTitle>
          <Form
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            form={form}
          >
            <Row gutter={[0, 30]}>
              {tabWiseData &&
                tabWiseData.length > 0 &&
                tabWiseData.map((ks, index) => (
                  <>
                    <Col span={18} push={6}>
                      <FormSelect
                        options={optionsData}
                        style={{ marginRight: "10px" }}
                        onChange={(e) => handleChange(e, ks._id)}
                      />
                    </Col>
                    <Col span={6} pull={18}>
                      <StyledSourceName>
                        {ks?.firstName} {ks?.lastName}{" "}
                        {ks?.sourceId ? `(${ks?.sourceId?.source})` : ""}
                      </StyledSourceName>
                    </Col>

                    {/* <div style={{ display: "flex", marginTop: "20px" }}>
                    
                    
                  </div> */}
                  </>
                ))}
            </Row>
            <FormItem>
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                Save
              </PrimaryButton>
            </FormItem>
          </Form>
        </Modal>
      </>
    </Container>
  );
};

export default Relationship;
