import React, { useCallback, useEffect, useState } from "react";
import { Container } from "ui/Layout/Container";
import { Heading } from "ui/Heading";
import { HiPlus } from "react-icons/hi";
import { SearchBar } from "ui/SearchBar";
import { HeroCard } from "ui/Card";
import {
  StyledHeaderTextwrapper,
  StyledAddButton,
  StyledActionButton,
  AcceptDeniedButton,
} from "./style";
import { PaginationBar } from "ui/Pagination";
import { imagePath, paginationObj, querystring, searchObj } from "utils/fn";
import TeamAPI from "api/http/team";
import { message, Skeleton, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { teamStatus, teamVisiblity } from "utils/constants";
import { me } from "store/reducers/user.reducer";
import AddTeamModal from "../AddTeam/AddTeam";
import { adminRoutes } from "helpers/adminRoutes";
import {
  StyledSubHeading,
  MobileActionButton,
  StyledHeading,
  StyledCardHeadingWrapper,
  StyledHeroCardWrapper,
  StyledHeroCardContentWrapper,
  StyledImageWrapper,
  StyledUserHeaderTextWrap,
  StyledCardRightSideWrapper,
} from "ui/Card/HeroCard";
import { ActionIconWrapper, StyledEarthIcon } from "../TeamDetail/styled";
import { useHistory, useLocation } from "react-router-dom";
import { SecondaryButton } from "ui/Button/Button";
import { FloatButton } from "Components/Relationship/TheRelationship.styles";
import { FaCheck, FaTimes } from "react-icons/fa";
import AddTeamMembers from "../AddTeam/AddTeamMembers";

// import {SecondaryButton} from "../../"

const Team = () => {
  const history = useHistory();

  const [data, setData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const { configData } = useSelector((state) => state.configData);

  const userId = localStorage.getItem("userId");
  const defaultFilter = {
    status: true,
    visibility: {
      $ne: teamVisiblity.hidden,
    },
    _id: {
      $nin: (user?.teams || [])
        .map((t) => t?.team !== null && t?.team?._id)
        .filter((t) => t),
    },
  };
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {
      ...defaultFilter,
      visibility: {
        $ne: teamVisiblity.hidden,
      },
      _id: {
        $nin: (user?.teams || [])
          .map((t) => t?.team !== null && t?.team?._id)
          .filter((t) => t),
      },
    },
    populate: [
      {
        path: "admins",
        select: ["firstName", "lastName", "avatar", "location"],
      },
      { path: "memberCount", match: { status: teamStatus.joined } },
      { path: "joined", match: { user: user?._id || userId } },
    ],
  });

  const getTeamList = useCallback((pagination) => {
    setLoading(true);
    TeamAPI.getTeamList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData(data?.data);
        setPagination((p) => ({ ...p, total: data?.total }));
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (user?.teams) {
      getTeamList({
        ...pagination,
        filter: {
          ...defaultFilter,
          visibility: {
            $ne: teamVisiblity.hidden,
          },
          _id: {
            ...(location.state?.team &&
            !(user?.teams || [])
              .map((t) => t?.team && t?.team?._id)
              .filter((t) => t)
              .includes(location.state?.team?._id)
              ? { $in: [location.state?.team?._id] }
              : {
                  $nin: (user?.teams || [])
                    .map((t) => t?.team && t?.team?._id)
                    .filter((t) => t),
                }),
          },
        },
        populate: [
          {
            path: "admins",
            select: ["firstName", "lastName", "avatar", "location"],
          },
          { path: "memberCount", match: { status: teamStatus.joined } },
          { path: "joined", match: { user: user?._id || userId } },
        ],
      });
      setPagination({
        ...pagination,
        filter: {
          ...defaultFilter,
          visibility: {
            $ne: teamVisiblity.hidden,
          },
          _id: {
            $nin: (user?.teams || []).map((t) => t?.team?._id).filter((t) => t),
          },
        },
        populate: [
          {
            path: "admins",
            select: ["firstName", "lastName", "avatar", "location"],
          },
          { path: "memberCount", match: { status: teamStatus.joined } },
          { path: "joined", match: { user: user?._id || userId } },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamList, user?.teams]);

  const paginationChangeHandler = (page, pageSize, search) => {
    const filter = searchObj(["name"], search);
    setPagination((p) => ({
      ...p,
      current: page,
      filter: { ...defaultFilter, ...filter },
    }));
    getTeamList({
      ...pagination,
      current: page,
      filter: { ...defaultFilter, ...filter },
    });
  };

  const joinActionHandler = (team) => {
    TeamAPI.joinTeam({
      team: team?._id,
      user: user?._id || userId,
      status:
        team?.visibility === "public"
          ? teamStatus.joined
          : teamStatus.requested,
    })
      .then(() => {
        refreshTeams();
        if (team?.visibility === "public") {
          history.push(adminRoutes.TEAMDETAILS.replace(":name", team?._id));
        }
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };
  const [admin, setAdmin] = useState("");
  const [teamId, setTeamId] = useState("");
  const teamIdData = (teamIdUser, admin) => {
    setTeamId(teamIdUser);
    setAdmin(admin);
  };
  const refreshTeams = () => {
    dispatch(me()).then(() => {
      getTeamList(pagination);
    });
  };
  const getStatusMessage = (type) => {
    switch (type) {
      case "accept":
        return "accepted";
      case "reject":
        return "rejected";
      default:
        return "Updated";
    }
  };

  const getStatus = (type) => {
    switch (type) {
      case "accept":
        return teamStatus.joined;
      case "reject":
        return teamStatus.rejected;

      default:
        return "";
    }
  };

  const actionHandler = (type, team) => {
    switch (type) {
      case "accept":
      case "reject":
        TeamAPI.updateTeamMember(team?.joined._id, {
          status: getStatus(type),
          team: team?._id,
        })
          .then((data) => {
            if (getStatus(type) === "Joined") {
              history.push(adminRoutes.TEAMDETAILS.replace(":name", team?._id));
            } else {
              history.push(adminRoutes.TEAM);
            }
            dispatch(me());
            // refreshTeams();
            message.success(
              type === "reject"
                ? " Request declined successfully"
                : `Request ${getStatusMessage(type)} successfully`
            );
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
        break;
      default:
        break;
    }
  };

  const handleAcceptReject = (e, item, type) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === "accept") {
      actionHandler("accept", item);
    } else {
      actionHandler("reject", item);
    }
  };
  return (
    <>
      <Container>
        <StyledHeaderTextwrapper>
          {/* <StyledAddButton
            shape="circle"
            icon={<HiPlus className="plus_icon" />}
            onClick={() => setModalState(true)}
          /> */}
          <SecondaryButton
            shape="circle"
            icon={<HiPlus className="plus_icon" />}
            onClick={() => {
              setModalState(true);
            }}
            height={"52px"}
            width={"52px"}
          />

          <Heading marginBottom={"0px"}>
            Find A {configData?.[0].module?.[1]?.children?.[2]?.name}
          </Heading>
        </StyledHeaderTextwrapper>
        <FloatButton>
          <SecondaryButton
            shape="circle"
            icon={<HiPlus className="plus_icon" />}
            onClick={() => {
              setModalState(true);
            }}
          />
        </FloatButton>
        <SearchBar
          placeholder="Search..."
          onChange={(value) =>
            paginationChangeHandler(
              pagination.current,
              pagination.pageSize,
              value
            )
          }
        />

        {!loading &&
          data.map((item) => (
            <>
              {item.joined ? (
                item.joined.status === "Joined" ? (
                  ""
                ) : (
                  <HeroCard
                    iconWidth={"16px"}
                    key={item._id}
                    imageSrc={
                      item?.avatar?.path ? imagePath(item?.avatar?.path) : ""
                    }
                    // imageWidth={"337px"}
                    // imageHeight={"100%"}
                    cardHeading={item?.name}
                    cardGroup={item?.visibility}
                    cardMember={item?.memberCount}
                    isClickable={false}
                    actionButton={
                      !(
                        !!item?.joined &&
                        [teamStatus.rejected].includes(item?.joined?.status)
                      ) && (
                        <>
                          {item?.joined?.initiateByRole === "admin" ? (
                            <AcceptDeniedButton>
                              <button
                                className="greenBtn"
                                onClick={(e) =>
                                  handleAcceptReject(e, item, "accept")
                                }
                              >
                                <FaCheck
                                  className="check"
                                  // onClick={() => actionHandler("accept", item)}
                                />
                              </button>
                              <button
                                className="redBtn"
                                onClick={(e) =>
                                  handleAcceptReject(e, item, "reject")
                                }
                              >
                                <FaTimes
                                  className="cancel"
                                  // onClick={() => actionHandler("reject", item)}
                                />
                              </button>
                            </AcceptDeniedButton>
                          ) : (
                            <StyledActionButton
                              onClick={() => joinActionHandler(item)}
                              disabled={!!item?.joined}
                            >
                              {item?.joined
                                ? item?.joined?.initiateByRole === "user"
                                  ? item?.joined?.status
                                  : ""
                                : item?.visibility === "public"
                                ? "Join"
                                : "Request"}
                            </StyledActionButton>
                          )}
                        </>
                      )
                    }
                    cardDescription={item?.description}
                    users={item.admins}
                  />
                )
              ) : (
                <HeroCard
                  iconWidth={"16px"}
                  key={item._id}
                  imageSrc={
                    item?.avatar?.path ? imagePath(item?.avatar?.path) : ""
                  }
                  // imageWidth={"337px"}
                  // imageHeight={"100%"}
                  cardHeading={item?.name}
                  cardGroup={item?.visibility}
                  cardMember={item?.memberCount}
                  isClickable={false}
                  actionButton={
                    !(
                      !!item?.joined &&
                      [teamStatus.rejected].includes(item?.joined?.status)
                    ) && (
                      <>
                        {item?.joined?.initiateByRole === "admin" ? (
                          <AcceptDeniedButton>
                            <button className="greenBtn">
                              <FaCheck
                                className="check"
                                onClick={() => actionHandler("accept", item)}
                              />
                            </button>
                            <button className="redBtn">
                              <FaTimes
                                className="cancel"
                                onClick={() => actionHandler("reject", item)}
                              />
                            </button>
                          </AcceptDeniedButton>
                        ) : (
                          <StyledActionButton
                            onClick={() => joinActionHandler(item)}
                            disabled={!!item?.joined}
                          >
                            {item?.joined
                              ? item?.joined?.initiateByRole === "user"
                                ? item?.joined?.status
                                : ""
                              : item?.visibility === "public"
                              ? "Join"
                              : "Request"}
                          </StyledActionButton>
                        )}
                      </>
                    )
                  }
                  cardDescription={item?.description}
                  users={item.admins}
                />
              )}
            </>
          ))}
        {loading && (
          <StyledHeroCardWrapper>
            <StyledHeroCardContentWrapper>
              <StyledImageWrapper mr={"25px"}>
                <Skeleton.Image
                  style={{ width: 300, height: 300 }}
                ></Skeleton.Image>
              </StyledImageWrapper>
              <StyledCardRightSideWrapper>
                <StyledUserHeaderTextWrap>
                  <StyledCardHeadingWrapper className="heading_wrap">
                    <StyledHeading className="heading">
                      <Skeleton.Input
                        style={{
                          // marginLeft: 20,
                          marginRight: 20,
                          width: 200,
                          height: 20,
                        }}
                        active={true}
                      ></Skeleton.Input>
                      <MobileActionButton>
                        <Skeleton.Button></Skeleton.Button>
                      </MobileActionButton>
                    </StyledHeading>

                    <StyledSubHeading className="sub_heading" mt={"15px"}>
                      <StyledEarthIcon

                      // iconHeight={earthIconSize}
                      // iconWidth={earthIconSize}
                      />
                      <StyledHeading className="heading">
                        <Skeleton.Input
                          style={{
                            marginLeft: 20,
                            marginRight: 20,
                            width: 200,
                            height: 20,
                          }}
                          active={true}
                        ></Skeleton.Input>
                        <MobileActionButton>
                          <Skeleton.Button></Skeleton.Button>
                        </MobileActionButton>
                      </StyledHeading>
                    </StyledSubHeading>
                  </StyledCardHeadingWrapper>
                </StyledUserHeaderTextWrap>
                <Skeleton paragraph={{ rows: 4 }} active={true}></Skeleton>
              </StyledCardRightSideWrapper>
            </StyledHeroCardContentWrapper>
          </StyledHeroCardWrapper>
        )}
        {!!data.length && (
          <PaginationBar onChange={paginationChangeHandler} {...pagination} />
        )}
      </Container>
      <AddTeamModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        from={adminRoutes.TEAM}
        onSubmit={() => {
          refreshTeams();
          setModalStates(true);
        }}
        teamIdUser={teamIdData}
        isModal={true}
      />
      {(admin || []).includes(localStorage.getItem("userId") || user?._id) && (
        <AddTeamMembers
          onCancel={(value) => setModalStates(value)}
          modalState={modalStates}
          from={adminRoutes.TEAM}
          teamId={teamId}
          // data={data}
          // onSubmit={() => refreshTeams()}
        />
      )}
    </>
  );
};

export default Team;
