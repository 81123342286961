import styled from "styled-components";
import { Card } from "../../ui/Card";
import { Row } from "antd";
import { hexToRgb } from "utils/fn";

export const StyledVideoCardWrapper = styled.div``;

export const StyledVideoCardWrapperRow = styled(Row)`
  display: flex;

  /* margin-bottom: 30px; */

  /* margin-left: ${({ ml }) => ml || 15}px; */

  // width: ${({ wd }) => wd || 98}%;

  // @media (max-width: 991px) {
  //   margin-left: 0;
  //   width: 100%;
  // }
`;

export const StyledVideoCard = styled(Card)`
  display: flex;
  flex-flow: column;
  border-radius: 15pt;
  box-shadow: 0px 10px 15px 0px hsba(0, 0%, 0%, 0.06);
  cursor: pointer;

  /* padding: 15px; */

  margin-bottom: 30px;

  .video-react {
    width: 100% !important;
    border-radius: 5px;
    height: 387px !important;

 
  }

  .video-react-video {
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }

  .video-react.video-react-fullscreen .video-react-video {
    object-fit: contain;
  }

  /* .ant-skeleton-image-svg {
    display: none;
  } */

  /* Video Controls  */
  .video-react .video-react-poster {
    background-size: cover !important;
    border-radius: 10px !important;
  }

  .video-react-control-bar {
    height: 59px;

    background-color: rgba(153, 153, 153, 0.6);

    

    /* background: #99999999; */
    /* rgba(153, 153, 153, 0.6) */
  }

  .video-react-paused::before, .video-react-playing::before {
    position: absolute;
    height: 30px;
    width: 30px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: white;
    color: rgba(153, 153, 153, 1);
    border-radius: 30px;
  }

  .video-react-volume-menu-button-horizontal::before, 
  .video-react-icon-fullscreen::before, 
  .video-react-icon-fullscreen-exit::before {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .video-react .video-react-menu-button-inline:hover .video-react-menu,
  .video-react-menu-content,
  .video-react-current-time,
  .video-react-time-control,
  .video-react-duration,
  .video-react-volume-menu-button-horizontal
  {
    display: flex;
    align-items: center;
  }

  .video-react .video-react-play-progress {
    background-color: ${({ theme }) => theme.colors.primary};
  }

   .video-react-play-progress::before {
    color: ${({ theme }) => theme.colors.primary};
    /* background-color: rgb(41 131 194 / 51%); */
    background-color: ${({ theme }) => `rgb(${hexToRgb(theme.colors.primary)} / 51% )`};
    padding: 5px;
    border-radius: 30px;

    top: -0.9em;
  }

  .video-react .video-react-progress-control:hover .video-react-play-progress::before {
    top: -0.7em;
}

.video-react .video-react-slider:focus {
  box-shadow: none;
}

.video-react-current-time-display, .video-react-duration  {
  font-size: 14px;
  color: white;
  font-family: 'Mulish';
}

.video-react-time-divider {
  font-size: 14px;
}

  .video-react-load-progress div {
    background: white;
    box-shadow: 0px 2px 3px 0px #00000033 inset;
  }
`;

// export const StyleIframeVideo = styled.div`
//   position: relative;
//   padding-bottom: 56.25%;
//   height: 0;
//   overflow: hidden;
//   max-width: 100%;
//   iframe {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     border-radius: 10px;
//   }
// `;

export const StyledImageWrapper = styled.div`
  /* display: flex;
  height: 100%;
  margin-bottom: 38px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-image {
    display: inline-block;
    width: 100%;
  }
  .ant-image-img {
    border-radius: 10px 10px 0px 0px;
  } */

  display: flex;
  position: relative;

  .video-react {
    width: 100% !important;
    height: 180px !important;
    border-radius: 15pt 15pt 0px 0px;
  }

  .video-react-video {
    border-radius: 15pt 15pt 0px 0px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }

  .video-react .video-react-poster {
    background-size: cover !important;
    border-radius: 10px !important;
  }
`;

export const StyledVideoProgressWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -28px;
`;

export const StyledVideoTimer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  min-height: 20px;
  border-radius: 4px;
  max-width: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: white;
    font-size: 12px;
    font-weight: 700;
  }
`;

// export const StyledVideoContentWrapper = styled.div`
//   display: flex;
//   flex-flow: column;
//   padding: 15px;

//   h3 {
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 20px;
//     color: #232333;
//     margin-bottom: 0;
//     /* min-height: 40px; */
//   }

//   h4 {
//     font-size: 14px;
//     line-height: 14px;
//     letter-spacing: -0.01em;
//     color: #747487;
//     margin-bottom: 0px;
//   }

//   span {
//     font-weight: 600;
//     font-size: 14px;
//     line-height: 14px;
//     letter-spacing: -0.01em;
//     color: #747487;
//     min-height: 40px;
//     display: flex;
//     align-items: center;
//   }

//   .comment_at {
//     font-size: 12px;
//     line-height: 22px;
//     color: #747487;
//   }

//   span .user_name {
//     margin-left: 15px;
//   }

//   .text a {
//     display: table;
//     color: #0061a4;
//     margin-top: 33px;
//   }
// `;

export const StyledVideoContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-top:10px;

  /* padding: 25px; */
  padding: ${({ padding }) => padding || "25px"};

  h3 {
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: #232333;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 11px;
  }

  h4 {
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    margin-bottom: 13px;
  }

  .comment_at {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #747487;
    align-items: center;
    gap: 5px;
    margin: 0 0 14px;
  }

  .number_video {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #747487;

    min-height: 14px;
  }

  .comment_at span {
    border-right: 1px solid #747487;
    padding-right: 5px;
  }

  .avatar_wrap {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
  }

  .user_name {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #747487;
  }

  .video_description {

    .read-or-hide {
      color: #0061a4;
      font-size: 14;
      font-weight: 700;
      display: flex;
      margin-top: 33px;
    }
    span, p {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #747487;
    }
    p {
      display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    }
  
  }


  /* .showThree p{
      display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
    } */


 
`;
