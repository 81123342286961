export const fallbackImage = "/logo512.png";
export const fallbackImageLite = "/logo310.png";

export const teamStatus = {
  list: ["Joined", "Requested", "Rejected"],
  joined: "Joined",
  requested: "Requested",
  rejected: "Rejected",
};

export const userRole = {
  list: ["admin", "user"],
  admin: "admin",
  user: "user",
};

export const teamVisiblity = {
  list: ["public", "private", "hidden"],
  public: "public",
  private: "private",
  hidden: "hidden",
};

export const pointActions = [
  { label: "Create Source", value: "createSource", group: "sources" },
  { label: "Add Lead", value: "addLead", group: "relationships" },
  {
    label: "Lead comes in from your specific Source",
    value: "leadFromSpecificSource",
    group: "relationships",
  },
  {
    label: "Assign a lead to someone",
    value: "assignLead",
    group: "relationships",
  },
  {
    label: "Move lead between stages",
    value: "moveLead",
    group: "relationships",
  },
  { label: "Call Lead", value: "callLead", group: "relationships" },
  { label: "Connect Lead", value: "connectLead", group: "relationships" },
  { label: "Email Lead", value: "emailLead", group: "relationships" },
  { label: "Text Lead", value: "textLead", group: "relationships" },
  // { label: "Generate Lead", value: "generateLead", group: "relationships" },
  { label: "Create a Group", value: "createGroup", group: "team" },
  { label: "Invite users to group", value: "inviteInGroup", group: "team" },
  {
    label: "Request to join a group",
    value: "requestToGroup",
    group: "team",
  },
  { label: "Join Group", value: "joinGroup", group: "team" },
  { label: "Post in a group", value: "makePost", group: "team" },
  { label: "Like a post in a group", value: "likePost", group: "team" },
  { label: "Comment on a post", value: "commentOnPost", group: "team" },
  { label: "Post a video", value: "postVideo", group: "grow" },
  { label: "Go 'Live'", value: "goLive", group: "grow" },
  { label: "Watch Video", value: "watchVideo", group: "grow" },
  { label: "Comment on a video", value: "commentOnVideo", group: "grow" },
  { label: "Create an event", value: "createEvent", group: "focus" },
  {
    label: "RSVP to an event (Yes)",
    value: "eventRSVPPositive",
    group: "focus",
  },
  {
    label: "RSVP to an event (No)",
    value: "eventRSVPNegative",
    group: "focus",
  },
  { label: "Open Mobile App", value: "openApp", group: "other" },
  { label: "Open Web App", value: "openWebApp", group: "other" },
  { label: "Hit a Streak in the system", value: "hitStreak", group: "other" },
  {
    label: "Hit a new Tier in the system",
    value: "hitNewTier",
    group: "other",
  },
];
