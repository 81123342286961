import { httpAPI } from "api";

const FileUploadAPI = {
    addFile(params) {
        return httpAPI(`/fileuploads`, params, "post");
    },
    getDetail(id, query) {
        return httpAPI(`/fileuploads/${id}?${query || ""}`, "", "get");
    },
    getVideoDetail(id, query) {
        return httpAPI(`/fileuploads/${id}?${query || ""}`, "", "get");
    },

};

export default FileUploadAPI;
