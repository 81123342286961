import { Input, Row, Button, Image, message, Upload, Tooltip, Col } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "../Card";
import { useSelector } from "react-redux";
import { imagePath } from "utils/fn";
import styled, { useTheme } from "styled-components";
import { ImagePreview } from "ui/ImagePreview";
import { MdSend } from "react-icons/md";
import { ImAttachment } from "react-icons/im";
import { MdDeleteOutline } from "react-icons/md";
import { Spacer } from "containers/TheLayout/TheLayout.styles";
import { UserAvatar } from "ui/Avatar";
import { useParams } from "react-router-dom";
import { StyledTitle } from "Components/Admin/ModuleSetting/Team/AddTeam";
import { Prompt } from "react-router";
import { useBeforeunload } from "react-beforeunload";
import { DeleteSvg } from "../../assets/svgs";
import Icon from "@ant-design/icons";
import { ToggleSwitch } from "ui/Form/index";
import TeamAPI from "api/http/team";
import { LinkPreview } from "@dhaiwat10/react-link-preview";

const { TextArea } = Input;

export const StyledCommentCard = styled(Card)`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "30px"};
`;

export const StyledInput = styled(TextArea)`
  min-height: 48px !important;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "100%")};
  background: #f6f7f9;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  color: #747487;
  padding-top: 12px;
  &:hover {
    border-color: #f0f0f0;
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}23;
  }
`;

export const StyledInputCommentWrapper = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 30px;

  ::after {
    content: "";
    position: absolute;
    bottom: -18px;
    background: #f0f0f0;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
    width: 100%;
    height: 1px;
    border-radius: 10px;
  }

  .userImage {
    margin-right: 7px;
  }
`;

export const StyledUploadPreviewWrapper = styled(Row)`
  display: ${({ isPhoto }) => (!isPhoto ? "none" : "flex")};
  flex-wrap: wrap;
  margin-bottom: 10px;

  /* float: left; */
  /* gap: 4px; */

  .ant-image {
    width: 100% !important;
    height: 200px;
  }

  .video_preview {
    width: 100%;
    height: 200px;
  }

  .ant-image-mask {
    border-radius: 10px;
  }

  img {
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
    padding: 5px;
  }

  /* @media (max-width: 575px) {
    .ant-image {
      width: 100% !important;
    }
    img {
      width: 100%;
    }
  } */
`;
export const AttachmentWrapper = styled.div`
  // display: ${({ isAttachment }) => (!isAttachment ? "none" : "flex")};

  /* display: none; */
  .ant-upload {
    display: none;
  }
`;
export const AttachmentPreviewWrapper = styled.div`
  display: ${({ isAttachment }) => (!isAttachment ? "none" : "flex")};
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  .attachement_icon {
    font-size: 14px;
  }

  .attachement_text {
    font-size: 14px;
  }

  .toggle_switch {
    display: flex;
    margin-left: 10px;
    gap: 10px;
  }

  .toggle_switch svg {
    font-size: 18px;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #747487;
  background: transparent;
  border: none;
  box-shadow: none;
  text-shadow: none;
  padding: 4px 9px;
  &:hover,
  &:focus {
    &:not(:disabled) {
      background: #f7f7f7;
      color: #747487;
    }
  }
  &:disabled {
    background: transparent;
    svg {
      fill: #00000040;
    }
  }

  .ant-image,
  svg {
    margin-right: 10px;
  }

  .ant-image img {
    height: 24px;
    width: 24px;
    object-fit: contain;
  }

  @media (max-width: 575px) {
    span {
      display: none;
    }
  }

  /* @media (max-width: 475px) {
    .ant-image,
    .image_icon {
      display: none;
    }
  } */
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;

  @media (max-width: 575px) {
    justify-content: space-around;
  }
`;

export const StyledVideoButtonWrapper = styled.div`
  display: flex;
  margin-right: 30px;

  @media (max-width: 575px) {
    margin-right: 10px;
  }
`;

export const CommentBar = ({
  // pageLoad = console.log,
  onFormChange = console.log,
  onSubmitUpdate,
  onSubmit,
  isEdit = false,
  ...props
}) => {
  const { user } = useSelector((state) => state.user);
  const theme = useTheme();
  const [state, setState] = useState([]);
  const [loading, setloading] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState([]);
  const [attachmentFileLength, setAttachmentFileLength] = useState([]);
  const [updateTeamAttachment, setUpdateTeamAttachment] = useState(false);
  const [downloadable, setDownloadable] = useState([]);
  const [loadings, setLoadings] = useState(false);

  const [postText, setPostText] = useState(props.postText);
  const DeleteIcon = (props) => <Icon component={DeleteSvg} {...props} />;
  const upload = React.useRef(null);
  const attachment = React.useRef(null);
  const params = useParams();
  const avatarProps = useMemo(
    () => ({
      ...(user?.avatar?.path
        ? { src: imagePath(user?.avatar?.path) }
        : {
            fullName: `${user?.firstName || ""} ${user?.lastName || ""}`,
          }),
    }),
    [user]
  );
  useEffect(() => {
    setLoadings(false);
    setTimeout(() => {
      setLoadings(true);
    }, 5000);
  }, [postText]);

  useEffect(() => {
    if (isEdit) {
      setState(() =>
        (props?.PostData?.image || []).map((img) => ({
          ...img,
        }))
      );

      setAttachmentFile(props?.PostData?.attachment);
      setDownloadable(props?.PostData?.isDownloadable);
      setAttachmentFileLength([props?.PostData?.attachment]);
    }
    return () => {
      setState([]);
    };
  }, [props.edit, props.PostData]);

  const fileChangeHandler = (event) => {
    setIsFormChange(true);
    console.log(event.target.files);
    setisReadyToUpdate(true);
    setState((s) => [
      ...s,
      ...Array.from(event.target.files).map((file) => ({
        image: file,
        path: URL.createObjectURL(file),
      })),
    ]);
  };

  const isReadyToPost = useMemo(() => {
    return !!postText || state.length || attachmentFileLength.length;
  }, [postText, state, attachmentFileLength]);
  const [isReadyToUpdate, setisReadyToUpdate] = useState(false);

  const clearPost = () => {
    setPostText("");
    setState([]);
    upload.current.value = null;
  };

  useEffect(() => {
    if (!isEdit) {
      clearPost();
    }
  }, [params.name, props.edit]);

  const onPostHandler = () => {
    setIsFormChange(false);
    setloading(true);
    setAttachmentFile([]);
    setAttachmentFileLength([]);
    const payload = {
      text: postText,
      image: state.map((i) => i.image),
      attachment: attachmentFile,
      isDownloadable: downloadable,
    };
    onSubmit(payload, (err) => {
      setloading(false);
      if (err) {
      } else {
        clearPost();
      }
    });
  };

  const onPostUpdateHandler = () => {
    setisReadyToUpdate(false);
    onFormChange(false);
    setloading(true);

    const payload = {
      text: postText,
      image: state.filter((i) => i.image).map((i) => i.image),
      attachment: attachmentFile.filter((i) => !i.url).map((i) => i),
      isDownloadable: downloadable,
    };
    onSubmitUpdate(
      payload,
      (err) => {
        if (err) {
        } else {
          clearPost();
          setloading(false);
        }
      },
      true
    );
  };
  const [isFormChange, setIsFormChange] = React.useState(false);
  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  const handleDownload = (e, index) => {
    let attach = [...downloadable];
    attach[index] = e;
    setDownloadable(attach);

    if (isEdit) {
      setisReadyToUpdate(true);
    }
  };

  const teamAttachment = {
    name: "attachment",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setUpdateTeamAttachment(true);
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      setIsFormChange(true);
      const isJpgOrPng = ["application/x-msdownload"].includes(file.type);
      if (isJpgOrPng) {
        message.error("You can not upload EXE file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        message.error("File must smaller than 50 MB!");
        return false;
      }
      setAttachmentFile((f) => [...f, file]);
      setDownloadable((f) => [...f, true]);
      setAttachmentFileLength([file]);
      setisReadyToUpdate(true);

      return false;
    },

    onRemove: (file) => {
      setisReadyToUpdate(true);
      setAttachmentFile([]);
      setAttachmentFileLength([]);
    },
    fileList: [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      <Prompt
        when={isFormChange}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <StyledCommentCard>
        {isEdit ? <StyledTitle>Edit Post</StyledTitle> : ""}
        <StyledInputCommentWrapper>
          <Row align="middle" justify="center">
            <UserAvatar {...avatarProps} className="userImage" />
          </Row>
          <StyledInput
            autoSize
            {...props}
            value={postText}
            // value={props.PostData ? props.PostData.text : postText}
            onChange={(e) => {
              // pageLoad(e.target.value);
              setPostText(e.target.value);
              setisReadyToUpdate(true);
              onFormChange(true);
              setIsFormChange(e.target.textLength === 0 ? false : true);
            }}
          ></StyledInput>
        </StyledInputCommentWrapper>
        {loadings && postText?.match(/(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g) && (
          <LinkPreview
            url={postText.match(/(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g)}
            width="auto"
            height="auto"
            // descriptionLength={50}
          />
        )}
        <StyledUploadPreviewWrapper isPhoto={state.length} gutter={[10, 10]}>
          {state.map((img, idx) =>
            img?.image?.type.includes("video/") ? (
              <Col xs={12} sm={8}>
                <video
                  className="video_preview"
                  // style={{ width: "fit-content" }}
                  src={img.image ? img.path : imagePath(img.path)}
                />
              </Col>
            ) : (
              <Col xs={12} sm={8}>
                <ImagePreview
                  key={idx}
                  src={img.image ? img.path : imagePath(img.path)}
                  height={200}
                  width={200}
                  onDelete={() => {
                    setisReadyToUpdate(true);
                    onFormChange(true);
                    const st = [...state];
                    st.splice(idx, 1);
                    setState(st);
                    onSubmitUpdate(
                      {
                        image: st.filter((s) => !s.image),
                      },
                      (err) => {
                        if (err) {
                        } else {
                          // clearPost();
                        }
                      },
                      false,
                      false
                    );

                    // upload.current.value = null;
                  }}
                />
              </Col>
            )
          )}
        </StyledUploadPreviewWrapper>
        {/* {attachmentFileLength.length  ? */}
        {attachmentFile &&
          attachmentFile.length > 0 &&
          attachmentFile?.map((attach, idx) => (
            <AttachmentPreviewWrapper
              isAttachment={attachmentFileLength.length}
            >
              <Tooltip
                placement="topLeft"
                title="Click to download file"
                arrowPointAtCenter
              >
                <div className="attachement_icon">
                  <ImAttachment />
                </div>
              </Tooltip>
              <Tooltip
                placement="topLeft"
                title="Click to download file"
                arrowPointAtCenter
              >
                {" "}
                <span className="attachement_text">{attach?.name}</span>
              </Tooltip>

              <div className="toggle_switch">
                <Tooltip
                  placement="topLeft"
                  title="Turn on/off for download"
                  arrowPointAtCenter
                >
                  <ToggleSwitch
                    onChange={(e) => {
                      handleDownload(e, idx);
                      setisReadyToUpdate(true);
                    }}
                    checked={downloadable[idx]}
                  />
                </Tooltip>

                <Tooltip
                  placement="topLeft"
                  title="Delete file"
                  arrowPointAtCenter
                >
                  <MdDeleteOutline
                    onClick={() => {
                      setisReadyToUpdate(true);
                      onFormChange(true);
                      const st = [...attachmentFile];
                      const dt = [...downloadable];
                      st.splice(idx, 1);
                      dt.splice(idx, 1);
                      setAttachmentFile(st);
                      setDownloadable(dt);
                      onSubmitUpdate(
                        {
                          attachment: st,
                          isDownloadable: dt,
                        },
                        (err) => {
                          if (err) {
                          } else {
                            console.log("st", st);
                            // clearPost();
                          }
                        },
                        false,
                        false
                      );

                      // upload.current.value = null;
                    }}
                  />
                </Tooltip>
              </div>
            </AttachmentPreviewWrapper>
          ))}
        {/* } */}

        <AttachmentWrapper isAttachment={attachmentFileLength.length}>
          <Upload
            // listType="picture-card"
            // accept="image/*"
            style={{ display: "none" }}
            {...teamAttachment}
          >
            <Button
              maxCount={1}
              style={{ display: "none" }}
              ref={attachment}
            ></Button>
          </Upload>
        </AttachmentWrapper>

        <Row align="middle">
          <Col span={18}>
            <StyledButtonsWrapper>
              <StyledButton
                type="primary"
                icon={
                  <Image
                    src="/images/videoIcon.png"
                    className="image_icon"
                    preview={false}
                  />
                }
              >
                Live Video
              </StyledButton>

              <StyledButton
                icon={
                  <Image
                    src="/images/photoIcon.png"
                    className="image_icon"
                    preview={false}
                  />
                }
                onClick={(e) => upload.current.click()}
              >
                Photo / Video
              </StyledButton>

              <StyledButton
                icon={
                  <ImAttachment
                    className="image_icon"
                    style={{ fontSize: "20px" }}
                  />
                }
                onClick={(e) => attachment.current.click()}
              >
                Attachment
              </StyledButton>
              <input
                accept="image/*,video/*"
                id="myInput"
                multiple={"multiple"}
                type="file"
                ref={upload}
                style={{ display: "none" }}
                onChange={fileChangeHandler}
              />
            </StyledButtonsWrapper>
          </Col>

          {/* <Spacer /> */}

          <Col span={6}>
            <Row justify="end">
              {isEdit ? (
                <StyledButton
                  icon={<MdSend fontSize={24} color={theme.colors.primary} />}
                  onClick={onPostUpdateHandler}
                  loading={loading}
                  disabled={!isReadyToUpdate}
                >
                  Update
                </StyledButton>
              ) : (
                <StyledButton
                  icon={<MdSend fontSize={24} color={theme.colors.primary} />}
                  onClick={onPostHandler}
                  disabled={!isReadyToPost}
                  loading={loading}
                >
                  Post
                </StyledButton>
              )}
            </Row>
          </Col>
          {/* <StyledButton
          icon={<MdSend fontSize={24} color={theme.colors.primary} />}
          onClick={onPostHandler}
          disabled={!isReadyToPost}
        >
          {props.edit ? "Update" : "Post"}
        </StyledButton> */}
        </Row>
      </StyledCommentCard>
    </>
  );
};
