import React from "react";

const grow = ({
  size = "24",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 23"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12.2924 22.0406V15.4421C13.7302 15.3409 16.3144 14.8832 18.0975 12.9861C20.6325 10.2892 20.4339 5.86771 20.4243 5.68079C20.3959 5.13262 19.9845 4.69488 19.4691 4.66465C19.2935 4.65423 15.1372 4.44312 12.6023 7.13991C12.3325 7.42686 12.0941 7.73347 11.8827 8.05223C11.5113 6.46464 10.8134 4.69093 9.50879 3.30304C6.48086 0.0818365 1.49817 0.335101 1.28764 0.347595C0.772354 0.377826 0.360881 0.815563 0.332464 1.36374C0.320854 1.58785 0.0826509 6.88859 3.11065 10.1099C5.32899 12.4698 8.59594 12.9648 10.2674 13.0559V22.0406C10.6027 22.0005 10.9407 21.9798 11.2799 21.9798C11.6191 21.9798 11.9571 22.0006 12.2924 22.0406Z" />
    </svg>
  );
};

export default grow;
