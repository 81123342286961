import { Row, Col, message, Tabs } from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { MobileTabWrapper, HeadingWrapper } from "./styled";
import { TeamRight, TeamLeft } from "../TeamDetail";
import { Heading } from "../../../ui/Heading";
import { Container } from "../../../ui/Layout/Container";
import TeamAPI from "api/http/team";
import { useParams, useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { MemberInfo, GroupInfo } from "./RightCards";
import { teamStatus } from "utils/constants";
import { querystring } from "utils/fn";
import { SecondaryButton } from "ui/Button";
import { adminRoutes } from "helpers/adminRoutes";
import AddTeamModal from "../AddTeam/AddTeam";
import { useDispatch, useSelector } from "react-redux";
import { me } from "store/reducers/user.reducer";
import useSocket from "utils/hooks/useSocket";
import config from "helpers/config";
import { SocketContext } from "utils/context/socket";

const TeamDetails = () => {
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [_isMobile, setIsMobile] = useState(false);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const userId = localStorage.getItem("userId");
  const { TabPane } = Tabs;
  const defaultQuery = React.useMemo(
    () => ({
      $populate: [
        { path: "memberCount", match: { status: teamStatus.joined } },
      ],
    }),
    []
  );
  const getTeam = useCallback(
    (id, withStore = false) => {
      TeamAPI.getTeam(id, querystring(defaultQuery))
        .then(({ data }) => {
          setData(data);
          if (withStore) {
            dispatch(me());
          }
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
          if (error?.code === 403) {
            history.push(adminRoutes.TEAM);
          }
        });
    },
    [defaultQuery, dispatch, history]
  );
  const socket = useContext(SocketContext);
  useEffect(() => {
    if (params.name) {
      getTeam(params.name);
    }
  }, [getTeam, params.name]);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [pathname]);

  useEffect(() => {
    if (socket && socket?.disconnected) {
      socket.connect();
    }
    if (socket) {
      socket.emit("join", params.name);
      socket.on("team-user-update", (data) => {
        if (data?.action === "patch" && data?.data?.status === "Rejected") {
          if (data?.data?.user === user?._id) {
            history.push(adminRoutes.TEAM);
          }
        }
      });

      return () => {
        socket.emit("leave", params.name);
      };
    }
  }, [socket]);

  return (
    <Container>
      <HeadingWrapper justifyContent="space-between" paddingBottom={15}>
        <Heading>{data?.name}</Heading>
        {(data?.admins || []).includes(user?._id) && (
          <SecondaryButton onClick={() => setOpen(true)}>Edit</SecondaryButton>
        )}
      </HeadingWrapper>
      <Row gutter={[30, 30]}>
        <Col xs={0} sm={0} md={0} lg={14} xl={15} xxl={16}>
          <TeamLeft teamId={params.name} />
        </Col>
        <Col xs={0} sm={0} md={0} lg={10} xl={9} xxl={8}>
          <TeamRight
            data={data}
            reloadTeam={(withStore = false) => getTeam(params.name, withStore)}
          />
        </Col>
      </Row>

      <MobileTabWrapper isMobile={setIsMobile}>
        {_isMobile && (
          <Tabs type="card" destroyInactiveTabPane={true}>
            <TabPane tab="Posts" key="1">
              <TeamLeft teamId={params.name} />
            </TabPane>
            <TabPane tab="Members" key="2">
              <MemberInfo
                data={data}
                reloadTeam={(withStore = false) =>
                  getTeam(params.name, withStore)
                }
              />
            </TabPane>
            <TabPane tab="Description" key="3">
              <GroupInfo data={data} />
            </TabPane>
          </Tabs>
        )}
      </MobileTabWrapper>
      <AddTeamModal
        onCancel={(value) => setOpen(value)}
        isEdit={true}
        _id={params?.name || ""}
        modalState={open}
        from={adminRoutes.TEAMDETAILS.replace(":name", params?.name || "")}
        onSubmit={() => getTeam(params?.name)}
      />
    </Container>
  );
};

export default TeamDetails;
