import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAPI } from "api/index";
import { imagePath, querystring } from "utils/fn";
import { message } from "antd";
import { teamStatus, userRole } from "utils/constants";
import axios from "axios";

const initialState = {
  user: null,
  accessToken: "",
  loading: false,
  isLoggedIn: false,
  error: null,
  faviconImage: "",
  landingPageLogo: "",
  landingPageBackground: "",
  homePageBackground: "",
  primary: "",
  secondary: "",
  third: "",
  emailVerification: false,
  modules: {},
};

export const login = createAsyncThunk("user/login", async (data, thunkAPI) => {
  try {
    const response = await httpAPI("/authentication", data);
    return { ...response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const register = createAsyncThunk(
  "user/register",
  async (data, thunkAPI) => {
    try {
      const response = await httpAPI("/users", data, "POST", true);
      return { ...response.data };
    } catch (error) {
      if (error.message === "email: value already exists.") {
        let messages = "Email already exists";
        message.error(messages);
        return thunkAPI.rejectWithValue("");
      }
    }
  }
);

export const me = createAsyncThunk("user/me", async (data, thunkAPI) => {
  try {
    let id = localStorage.getItem("userId");
    const response = await httpAPI(
      `/users/${id}?${querystring({
        $populate: {
          path: "teams",
          select: ["team", "status"],
          match: {
            user: id,
            status: { $in: [teamStatus.rejected, teamStatus.joined] },
          },
          populate: {
            path: "team",
            match: { status: true },
            populate: [
              {
                path: "admins",
                select: ["firstName", "lastName", "avatar", "location"],
              },
              { path: "memberCount", match: { status: teamStatus.requested, initiateByRole: userRole.user } },
            ],
          },
        },
      })}`,
      data,
      "GET"
    );
    return { ...response.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const portal = createAsyncThunk(
  "user/configDetails",
  async (data, thunkAPI) => {
    try {
      let id = localStorage.getItem("userId");

      const response = await httpAPI(`/portals`, data, "GET");

      // const responses = await axios.get(
      //   `${process.env.ANT_URL}/client/?_id=${response.data?.data[0]?._id}`
      // );
      // let portalData = response.data?.data[0];
      // let modules = responses.data?.data[0].module;
      return { ...response.data?.data[0] };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const counterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("version");
      localStorage.removeItem("userId");
      state.user = null;
      state.accessToken = "";
      state.loading = false;
      state.isLoggedIn = false;
      state.error = null;
    },
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      localStorage.setItem("token", payload.accessToken);
      localStorage.setItem("userId", payload.user._id);
      localStorage.setItem("emailVerified", payload.user.emailVerified);

      state.user = payload.user;
      state.faviconImage = initialState.faviconImage;
      state.landingPageLogo = initialState.landingPageLogo;
      state.landingPageBackground = initialState.landingPageBackground;
      state.homePageBackground = initialState.homePageBackground;
      state.primary = initialState.primary;
      state.secondary = initialState.secondary;
      state.third = initialState.third;
      state.emailVerification = initialState.emailVerification;
      state.modules = initialState.modules;
      state.accessToken = payload.accessToken;
      state.isLoggedIn = true;
      state.loading = false;
      state.error = null;
    },
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
    },
    [register.fulfilled]: (state, { payload }) => {
      // localStorage.setItem("token", payload.accessToken);
      // localStorage.setItem("userId", payload.user._id);
      // localStorage.setItem("emailVerified", payload.user.emailVerified);
      // state.user = payload.user;
      // state.faviconImage = initialState.faviconImage;
      // state.landingPageLogo = initialState.landingPageLogo;
      // state.landingPageBackground = initialState.landingPageBackground;
      // state.homePageBackground = initialState.homePageBackground;
      // state.primary = initialState.primary;
      // state.secondary = initialState.secondary;
      // state.third = initialState.third;
      // state.emailVerification = initialState.emailVerification;
      // state.modules = initialState.modules;
      // state.accessToken = payload.accessToken;
      // state.isLoggedIn = false;
      state.loading = false;
      state.error = null;
    },
    [me.fulfilled]: (state, { payload }) => {
      state.user = payload;
      // state.faviconImage = initialState.faviconImage;
      // state.landingPageLogo = initialState.landingPageLogo;
      // state.backgroundImage = initialState.backgroundImage;
      // state.homePageLogo = initialState.homePageLogo;
    },

    [portal.fulfilled]: (state, { payload }) => {
      state.faviconImage = imagePath(payload?.faviconImage.path).replace(
        /\\/g,
        "/"
      );
      state.landingPageLogo = imagePath(payload?.landingPageLogo.path).replace(
        /\\/g,
        "/"
      );
      state.landingPageBackground = imagePath(
        payload?.landingPageBackground.path
      ).replace(/\\/g, "/");
      state.homePageBackground = imagePath(
        payload?.homePageBackground.path
      ).replace(/\\/g, "/");
      state.primary = payload?.primary;
      state.clientId = payload?._id;
      state.name = payload?.name;
      state.secondary = payload?.secondary;
      state.third = payload?.third;
      state.emailVerification = payload?.emailVerification;
      state.modules = payload?.modules;
      localStorage.setItem("businessName", payload?.name)
      localStorage.setItem("portalId", payload?._id)
      localStorage.setItem("faviconImage", imagePath(payload?.faviconImage.path).replace(
        /\\/g,
        "/"
      ))
    },

    [register.pending]: (state) => {
      state.loading = true;
    },
    [register.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.isLoggedIn = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { logout } = counterSlice.actions;

export default counterSlice.reducer;
