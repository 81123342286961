import styled from "styled-components";
import { Pagination } from "antd";

export const PaginationBar = ({ justifyContent = "flex-end", ...props }) => {
  return (
    <PaginationWrapper justifyContent={justifyContent}>
      <StyledPagination {...props} />
    </PaginationWrapper>
  );
};

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const StyledPagination = styled(Pagination)`
  &.ant-pagination {
    li {
      width: 48px !important;
      height: 48px !important;
      background: #ffffff;
      border: 1px solid #f0f0f0;
      box-sizing: border-box;
      box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
      border-radius: 50%;
      border: 1px solid #f0f0f0;
      &.ant-pagination-prev {
        margin-right: 8px;
      }
      &.ant-pagination-item {
        margin-right: 8px;
        &.ant-pagination-item-active {
          background: linear-gradient(
            276.7deg,
            ${({ theme }) => theme.colors.secondary} 0%,
            ${({ theme }) => theme.colors.secondary} 100%
          );
          box-shadow: 0px 10px 15px ${({ theme }) => theme.colors.secondary}59;
          border: none;
          a {
            color: #ffffff;
          }
        }
      }
      .ant-pagination-item-link,
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: normal;
        font-size: 14px;
        color: #949494;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: 0;
      }
    }
  }
`;
