import React, { useEffect } from "react";
import {
  Form,
  Button,
  message,
  Tabs,
  Row,
  Col,
  Skeleton,
  Space,
  Divider,
  Switch,
  Radio,
} from "antd";
import { adminRoutes } from "../../helpers/adminRoutes";
import { useHistory } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash, FaRegEdit } from "react-icons/fa";
import { GrPowerReset, GrDrag } from "react-icons/gr";
import { CgAsterisk } from "react-icons/cg";
import { CopySvg, DeleteSvg } from "../../assets/svgs";
import { FormInput, FormSelect, FormTextArea } from "ui/Form";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { Card } from "ui/Card";
import { AiOutlineMenu } from "react-icons/ai";
import { useBeforeunload } from "react-beforeunload";

import { PrimaryButton } from "ui/Button";
import { useParams } from "react-router";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

import { StarOutlined } from "@ant-design/icons";

import {
  LinkCreators,
  StyledFormItemButton,
  StyledTabButtonWrap,
  StyledFormItemWrapper,
  StyledInputItemWrapper,
  StyledFormItemOkButton,
  StyledLink,
  StyledTextWrapperNoData,
  StyledSubmitButtonWrapper,
  StyledTextWrapper,
  StyledFormWrapper,
  StyledLogo,
  StyledVideo,
  StyledContentPreview,
  StyledRowWrapper,
  StyledPreviewWrapper,
  StyledDeleteButton,
  StyledEyeButton,
  StyledAsteriskButton,
} from "./style";
import SourceAPI from "api/http/source";
import { Flex } from "ui/Layout/Flex";
import { SecondaryButton } from "ui/Button/index";

import { useProgressiveImage } from "utils/hooks/useProgressiveImage";
import { FormItem } from "ui/Form/index";
import { useSelector } from "react-redux";
import { Tab } from "ui/Tab/index";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";

const { TabPane } = Tabs;

const LinkCreator = () => {
  const history = useHistory();
  const params = useParams();
  const [form] = Form.useForm();
  const [defaulState, setDefaultState] = React.useState(false);
  const [dataChange, setDataChange] = React.useState(false);
  const [tabstate, setTabState] = React.useState({ key: "Generate" });
  const [showPrompt, setShowPrompt] = React.useState(false);
  const { configData } = useSelector((state) => state.configData);

  const [types, setTypes] = React.useState(null);
  const [state, setState] = React.useState({
    dynamicFields: [],
  });
  const { landingPageLogo, landingPageBackground } = useSelector(
    (state) => state.user
  );
  const [loading, setLoading] = React.useState(false);
  const loadingLogo = useProgressiveImage(landingPageLogo);
  const bgImage = useProgressiveImage(landingPageBackground);

  const [stateValue, setStateValue] = React.useState();
  const [stateEditSlug, setEditSlug] = React.useState(false);

  const [stateData, setStateData] = React.useState({
    dynamicFieldsData: [],
  });

  const SortableItems = SortableElement(({ value }) => <div>{value}</div>);

  const SortList = SortableContainer(({ items }) => {
    return (
      <Row>
        {items.map((k, index) => (
          <Col span={24}>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "space-between",
              }}
            >
              {/* <Col span={18}> */}
              <SortableItems
                key={`item-${k.fieldName}`}
                index={index}
                // axis={"x"}
                // value={k.fieldName}
                value={
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <h6>
                      {(k.fieldName == "firstName" && "First name") ||
                        (k.fieldName == "lastName" && "Last name") ||
                        (k.fieldName == "email" && "Email") ||
                        (k.fieldName == "phoneNumber" && "Phone number") ||
                        (k.fieldName == "address" && "Address")}
                    </h6>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <GrDrag size={"30px"} /> */}
                      <AiOutlineMenu
                        size={"30px"}
                        style={{ color: "rgb(0 0 0 / 48%)" }}
                      />

                      <FormInput
                        type="text"
                        disabled={true}
                        // className="dynamicAddOn"
                        style={{
                          background: "transparent",
                          border: "none",
                        }}
                        onChange={(e) => handleInput(e, index)}
                        placeholder={
                          (k.fieldName == "firstName" && "First name") ||
                          (k.fieldName == "lastName" && "Last name") ||
                          (k.fieldName == "email" && "Email") ||
                          (k.fieldName == "phoneNumber" && "Phone number") ||
                          (k.fieldName == "address" && "Address")
                        }
                        // value={k.value ? k.value : ""}
                        // maxWidth={"709px"}
                        maxWidth={"100%"}
                      />
                    </div>
                  </div>
                }
              />
              {/* </Col> */}

              {/* <Col span={6}> */}
              <div
                style={{ display: "flex", alignItems: "end", height: "100%" }}
              >
                <Button
                  className={k.required === true ? "asteriskTrue" : "asterisk"}
                  shape="circle"
                  icon={<CgAsterisk />}
                  onClick={(e) => {
                    handleRequired(e, index);
                  }}
                />

                <StyledEyeButton
                  // className="eye"
                  shape="circle"
                  icon={k.visibility == true ? <FaRegEye /> : <FaRegEyeSlash />}
                  onClick={(e) => handleVisibility(e, index)}
                />

                <StyledDeleteButton
                  // className="delete"
                  shape="circle"
                  icon={<DeleteSvg />}
                  onClick={(e) => handleDelete(e, index)}
                />
              </div>
              {/* </Col> */}
            </div>
          </Col>
        ))}
      </Row>
    );
  });
  const onSortEnd = ({ oldIndex, newIndex }) => {
    let items = stateData.dynamicFieldsData;

    setStateData({ dynamicFieldsData: arrayMove(items, oldIndex, newIndex) });
  };

  const onFinish = (values) => {
    setShowPrompt(false);

    setDataChange(false);
    values.contact = stateData.dynamicFieldsData;
    if (values.source) {
      let data = values.source;
    }
    if (params.id) {
      SourceAPI.updateSource(params.id, values)
        .then(() => {
          message.success("Source updated successfully");
          // const { from } = history.location.state;
          // history.push(from ? from : adminRoutes.SOURCE);
          history.push(adminRoutes.SOURCE);
        })
        .catch((error) => {
          if (error) {
            message.error(error.response && error.response.data.message);
          }
        });
    } else {
      SourceAPI.addSource(values)
        .then(() => {
          message.success("Source added successfully");
          const { from } = history.location?.state || {};
          history.push(from ? from : adminRoutes.SOURCE);
        })
        .catch((error) => {
          if (error) {
            message.error(error.message);
          }
        });
    }
  };

  const handleChange = (value) => {
    setDataChange(true);

    let dynFields = value;
    let arr = [];
    for (const a of value) {
      arr.push({
        fieldName: a,
        value: "",
        visibility: false,
        required: false,
      });
    }
    setState({ dynamicFields: dynFields });
    setStateData({ dynamicFieldsData: arr });
  };

  useEffect(() => {
    if (params.id) {
      SourceAPI.getSourceListDetail(params.id)
        .then(({ data }) => {
          setStateData({ dynamicFieldsData: data.contact });
          setStateValue(data);
          let arr = [];
          for (const a of data.contact) {
            arr.push(a.fieldName);
          }
          setState({ dynamicFields: arr });
          form.setFieldsValue({
            source: data.source,
            videoEmbed: data.videoEmbed,
            header: data.header,
            visibilityType: data.visibilityType,
            description: data.description,
            contact: arr,
            link: data?.link,
          });
        })

        .catch((error) => {
          if (error) {
            message.error();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleVisibility = (e, index) => {
    setDataChange(true);

    let dynFields = stateData.dynamicFieldsData;
    dynFields[index].visibility =
      !stateData.dynamicFieldsData[index].visibility;
    setStateData({ dynamicFieldsData: dynFields });
  };
  const handleRequired = (e, index) => {
    setDataChange(true);

    let dynFields = stateData.dynamicFieldsData;
    dynFields[index].required = !stateData.dynamicFieldsData[index].required;
    setStateData({ dynamicFieldsData: dynFields });
  };
  const handleDelete = (e, index) => {
    setDataChange(true);

    let dynFields = stateData.dynamicFieldsData;
    dynFields.splice(index, 1);
    setStateData({ dynamicFieldsData: dynFields });
  };

  const handleCodeValue = () => {
    let copyValue = stateValue.code;
    navigator.clipboard.writeText(copyValue);
    message.success("copied to clipboard");
  };
  const handleLinkValue = () => {
    let copyValue = stateValue.link;
    navigator.clipboard.writeText(copyValue);
    message.success("copied to clipboard");
  };

  const handleHeader = (e) => {
    setStateValue((s) => ({
      ...s,
      header: e.target.value,
    }));
  };

  const handleDescription = (e) => {
    setDataChange(true);
    setStateValue((s) => ({
      ...s,
      description: e.target.value,
    }));
  };

  const handleVideoEmbed = (e) => {
    setDataChange(true);

    setStateValue((s) => ({
      ...s,
      videoEmbed: e.target.value,
    }));
  };

  const handleInput = (e, index) => {
    setDataChange(true);
    let dynFieldsData = stateData.dynamicFieldsData;

    dynFieldsData[index].value = e.target.value;
    setStateData({ dynamicFieldsData: dynFieldsData });
  };
  function callback(key) {
    setTabState({ key: key });
  }

  const handleVisibilityType = (e) => {};
  const convertToPlayer = (source, link) => {
    switch (source) {
      case "youtube":
        return link.replace("watch?v=", "embed/");
      case "vimeo":
        return link.replace("vimeo.com", "player.vimeo.com/video");
      case "gdrive":
        return link.replace("/view", "/preview");

      default:
        return link;
    }
  };
  const configs = JSON.parse(localStorage.getItem("config"));

  const fetchPlayerLink = (link) => {
    if (`${link}`.search("youtube") > -1) {
      return convertToPlayer("youtube", link);
    } else if (`${link}`.search("vimeo") > -1) {
      return convertToPlayer("vimeo", link);
    } else if (`${link}`.search("drive.google") > -1) {
      return convertToPlayer("gdrive", link);
    } else {
      return convertToPlayer("other", link);
    }
  };
  const handleEditSlug = (e, action, type) => {
    setDataChange(true);

    if (action === "edit" && type === "code") {
      setStateValue((s) => ({
        ...s,
        link: stateValue.link,
        slug: stateValue.code,
        code: stateValue.code.replace(stateValue.link, stateValue.link),
      }));
      setTypes("Code");
      setEditSlug(!stateEditSlug);
    } else if (action === "edit" && type === "link") {
      setStateValue((s) => ({
        ...s,
        link: stateValue.link,
        slug: stateValue.link,
        // .replace(window.location.origin + "/landing/source/", ""),
        code: stateValue.code.replace(stateValue.link, stateValue.link),
      }));

      setTypes("Link");

      setEditSlug(!stateEditSlug);
    } else if (action === "reset") {
      if (stateValue.slug !== stateValue.originalSlug) {
        let link = stateValue.link.replace(
          stateValue.slug,
          stateValue.originalSlug
        );
        setStateValue((s) => ({
          ...s,
          link: link,
          slug: stateValue.originalSlug,
          code: `<iframe width="1000" height="1000" src="${link}" title="Welcome!" frameborder="0"></iframe>`,
        }));
      }
      setTypes(null);
      setEditSlug(false);
    }
  };
  const handleSlugChange = (e) => {
    setDataChange(true);

    setStateValue({
      code: stateValue.code,
      link: stateValue.link,
      slug: e.target.value,
    });
  };

  console.log("window.location.origin", window.location.origin);
  const handlelinkCodeUpdate = (e) => {
    setDataChange(false);
    setLoading(true);
    let link = window.location.origin + "/landing/source/" + stateValue.slug;
    let slug = stateValue.slug;
    // let code = `<iframe width="1000" height="1000" src="${link}" title="Welcome!" frameborder="0"></iframe>`;
    let code = stateValue.code.replace(stateValue.link, link);
    var obj = {};
    if (types === "Code") {
      if (stateValue.slug.includes(stateValue.link)) {
        obj = { code: stateValue.slug };
      } else {
        return message.error("Please do not change the source link");
      }
    } else {
      obj = { link, slug, code };
    }

    SourceAPI.updateSource(params.id, obj)
      .then(() => {
        message.success("Source updated successfully");
        // const { from } = history.location.state;
        // history.push(from ? from : adminRoutes.SOURCE);
        setLoading(false);
        history.push(adminRoutes.SOURCE);
      })
      .catch((error) => {
        if (error) {
          message.error(error && error.message);
        }
      });
  };

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  return (
    <LinkCreators>
      {/* { 
         (
          <Beforeunload
            onBeforeunload={() =>
              "You will lose all changes if you navigate away from this page without saving"
            }
          />
        )}
        */}
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Container minH="100%" padding={"19px 30px 0 30px"}>
        <Heading>
          {params.id
            ? "Edit " + configData?.[0].module?.[1]?.children?.[0]?.name
            : configData?.[0].module?.[1]?.children?.[0]?.name}
        </Heading>
        {/* <StyledTabButtonWrap >
        <Tabs onChange={callback} activeKey={tabstate.key} >
          <TabPane tab="Generate" key="Generate"></TabPane>
          <TabPane tab="Details" key="Details"></TabPane>
        </Tabs>
      </StyledTabButtonWrap> */}
      </Container>
      <Tab
        padding={"0 30px"}
        onChange={callback}
        activeKey={tabstate.key}
        tabPanes={[{ type: "Generate" }, { type: "Details" }]}
      />
      <Container padding={"17px 30px 30px"}>
        <Card>
          <StyledRowWrapper gutter={[20, 30]}>
            <Col xs={{ span: 24 }} xl={{ span: 10 }}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={() => {
                  setShowPrompt(true);
                }}
                onFinish={(e) => onFinish(e)}
                requiredMark={false}
                // labelCol={{
                //   xs: { span: 24 },
                //   sm: { span: 24 },
                //   md: { span: 4 },
                //   lg: { span: 4 },
                //   xl: { span: 3 },
                //   xxl: { span: 2 },
                // }}
                // wrapperCol={{
                //   xs: { span: 24 },
                //   sm: { span: 24 },
                //   md: { span: 20 },
                //   lg: { span: 20 },
                //   xl: { span: 21 },
                //   xxl: { span: 22 },
                // }}

                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                  xl: { span: 24 },
                  xxl: { span: 24 },
                }}
                // wrapperCol={{
                //   xs: { span: 24 },
                //   sm: { span: 24 },
                //   md: { span: 20 },
                //   lg: { span: 20 },
                //   xl: { span: 21 },
                //   xxl: { span: 21 },
                // }}
              >
                {tabstate.key === "Generate" && (
                  <>
                    <StyledFormItemWrapper>
                      <StyledInputItemWrapper
                        name="source"
                        label="Source"
                        rules={[
                          {
                            required: true,
                            message: "This is required",
                          },
                        ]}
                      >
                        <FormInput
                          type="text"
                          // maxWidth={"850px"}
                          onChange={() => {
                            setDataChange(true);
                          }}
                          maxWidth={"100%"}
                          placeholder="Enter source name"
                        />
                      </StyledInputItemWrapper>

                      <StyledInputItemWrapper
                        name="videoEmbed"
                        label="Video"
                        onChange={(e) => handleVideoEmbed(e)}
                      >
                        <FormInput
                          onChange={() => {
                            setDataChange(true);
                          }}
                          type="text"
                          placeholder="Start typing the video title if the video is in the system or copy and paste a video link from any other website like youtube"
                          // maxWidth={"850px"}
                          maxWidth={"100%"}
                        />
                      </StyledInputItemWrapper>

                      <StyledInputItemWrapper
                        name="header"
                        label="Header"
                        onChange={(e) => handleHeader(e)}
                      >
                        <FormInput
                          onChange={() => {
                            setDataChange(true);
                          }}
                          type="text"
                          // maxWidth={"850px"}
                          maxWidth={"100%"}
                          placeholder="Enter header"
                        />
                      </StyledInputItemWrapper>

                      <StyledInputItemWrapper
                        name="description"
                        label="Description"
                        onChange={(e) => handleDescription(e)}
                      >
                        <FormTextArea
                          onChange={() => {
                            setDataChange(true);
                          }}
                          type="text"
                          placeholder="Enter paragraph text"
                          // maxWidth={"850px"}
                          maxWidth={"100%"}
                        />
                      </StyledInputItemWrapper>
                      <StyledInputItemWrapper
                        name="visibilityType"
                        label="Visibility"
                        rules={[
                          {
                            required: true,
                            message: "Please select visibility type",
                          },
                        ]}
                      >
                        <FormSelect
                          placeholder="Select type"
                          onChange={(e) => handleVisibilityType(e)}
                          // maxWidth={"850px"}
                          maxWidth={"100%"}
                          options={[
                            { label: "All users", value: "All users" },
                            {
                              label: "Only assigned contacts",
                              value: "Only assigned contacts",
                            },
                            // { label: "Only me", value: "Only me" },
                          ]}
                        />
                      </StyledInputItemWrapper>

                      <StyledInputItemWrapper
                        name="contact"
                        label="Contact"
                        rules={[
                          { required: true, message: "Please select type!" },
                        ]}
                      >
                        <FormSelect
                          mode="multiple"
                          clickable={false}
                          placeholder="Select type"
                          clearIcon={true}
                          onChange={handleChange}
                          // maxWidth={"850px"}
                          maxWidth={"100%"}
                          // defaultValue={state.dynamicFields}
                          options={[
                            { label: "First name", value: "firstName" },
                            { label: "Last name", value: "lastName" },
                            { label: "Email", value: "email" },
                            { label: "Phone number", value: "phoneNumber" },
                            { label: "Address", value: "address" },
                          ]}
                        />
                      </StyledInputItemWrapper>
                      <SortList
                        items={stateData.dynamicFieldsData}
                        onSortEnd={onSortEnd}
                        disableAutoscroll={true}
                        lockAxis={"y"}
                      />
                      {params.id && (
                        <>
                          <StyledInputItemWrapper
                            name="link"
                            label="Preview Link"
                          >
                            <StyledLink
                              href={stateValue && stateValue.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {stateValue && stateValue.link}
                            </StyledLink>
                          </StyledInputItemWrapper>
                        </>
                      )}
                      {/* <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 20, offset: 4 }}
                      lg={{ span: 20, offset: 4 }}
                      xl={{ span: 21, offset: 3 }}
                      xxl={{ span: 22, offset: 2 }}
                    > */}
                      <StyledFormItemButton>
                        <PrimaryButton htmlType="submit">
                          Generate
                        </PrimaryButton>
                      </StyledFormItemButton>
                      {/* </Col> */}
                    </StyledFormItemWrapper>
                  </>
                )}
                {tabstate.key == "Details" && (
                  <>
                    <StyledInputItemWrapper name="code" label="Code">
                      <FormInput
                        // disabled={true}
                        value={stateValue?.code}
                        // maxWidth={"786px"}
                        maxWidth={"100%"}
                      />
                      <Flex>
                        <Button
                          onClick={handleCodeValue}
                          type="text"
                          shape="circle"
                          icon={<CopySvg />}
                        />
                        {params.id && (
                          <Button
                            type="text"
                            shape="circle"
                            onClick={(e) => handleEditSlug(e, "edit", "code")}
                            icon={<FaRegEdit className="edit_pencil" />}
                          />
                        )}
                        <Button
                          type="text"
                          shape="circle"
                          onClick={(e) => handleEditSlug(e, "reset", "code")}
                          icon={<GrPowerReset className="reset_icon" />}
                        />
                      </Flex>
                    </StyledInputItemWrapper>

                    <StyledInputItemWrapper name="link" label="Link">
                      <FormInput
                        // disabled={true}
                        value={stateValue?.link}
                        // maxWidth={"786px"}
                        maxWidth={"100%"}
                      />
                      <Flex>
                        <Button
                          onClick={handleLinkValue}
                          type="text"
                          shape="circle"
                          icon={<CopySvg />}
                        />
                        {params.id && (
                          <Button
                            type="text"
                            shape="circle"
                            onClick={(e) => handleEditSlug(e, "edit", "link")}
                            icon={<FaRegEdit className="edit_pencil" />}
                          />
                        )}
                        <Button
                          type="text"
                          shape="circle"
                          onClick={(e) => handleEditSlug(e, "reset", "link")}
                          icon={<GrPowerReset className="reset_icon" />}
                        />
                      </Flex>
                    </StyledInputItemWrapper>
                    {stateEditSlug && (
                      <StyledInputItemWrapper label="Edit">
                        <FormInput
                          type="text"
                          value={stateValue?.slug}
                          onChange={(e) => handleSlugChange(e)}
                          // maxWidth={"786px"}
                          maxWidth={"100%"}
                        ></FormInput>
                      </StyledInputItemWrapper>
                    )}

                    <StyledFormItemOkButton>
                      <PrimaryButton
                        width={"80px"}
                        htmlType="button"
                        onClick={(e) => handlelinkCodeUpdate(e)}
                        loading={loading}
                      >
                        Save
                      </PrimaryButton>
                    </StyledFormItemOkButton>
                  </>
                )}
              </Form>
            </Col>

            {tabstate.key == "Generate" ? (
              <Col sm={{ span: 30 }} xl={{ span: 14 }}>
                <StyledContentPreview backgroundImg={bgImage}>
                  {stateValue && (
                    <>
                      <Row gutter={[0, 30]}>
                        {/* <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 12, offset: 6 }}
                      lg={{ span: 12, offset: 6 }}
                      xl={{ span: 12, offset: 6 }}
                      xxl={{ span: 12, offset: 6 }}
                    > */}
                        <StyledLogo>
                          {loadingLogo && (
                            <img src={loadingLogo} preview={false} />
                          )}
                        </StyledLogo>

                        {stateValue && stateValue.videoEmbed && (
                          <StyledVideo>
                            {
                              <iframe
                                width="100%"
                                height="400"
                                src={
                                  stateValue &&
                                  stateValue.videoEmbed &&
                                  fetchPlayerLink(
                                    stateValue && stateValue.videoEmbed
                                  )
                                }
                                title="Video Player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            }
                          </StyledVideo>
                        )}
                        {/* </Col> */}
                      </Row>

                      <Row justify="center">
                        {/* <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 12, offset: 6 }}
                      lg={{ span: 12, offset: 6 }}
                      xl={{ span: 12, offset: 6 }}
                      xxl={{ span: 12, offset: 6 }}
                    > */}
                        <StyledTextWrapper>
                          <h1 className="heading">
                            {stateValue && stateValue.header}
                          </h1>
                        </StyledTextWrapper>
                        {/* </Col> */}
                      </Row>

                      <Row justify="center">
                        {/* <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 24, offset: 0 }}
                      md={{ span: 12, offset: 6 }}
                      lg={{ span: 12, offset: 6 }}
                      xl={{ span: 12, offset: 6 }}
                      xxl={{ span: 12, offset: 6 }}
                    > */}
                        <StyledTextWrapper>
                          <p className="description">
                            {stateValue && stateValue.description}
                          </p>
                        </StyledTextWrapper>
                        {/* </Col> */}
                      </Row>

                      <StyledFormWrapper>
                        <Form
                          form={form}
                          layout="vertical"
                          onFinish={(e) => onFinish(e)}
                          requiredMark={true}
                          onValuesChange={() => {
                            setShowPrompt(true);
                          }}
                        >
                          <Row justify="center">
                            {stateData.dynamicFieldsData &&
                              stateData.dynamicFieldsData.length > 0 &&
                              stateData.dynamicFieldsData.map((item, index) => (
                                <>
                                  <Col
                                    span={24}
                                    // xs={{ span: 24 }}
                                    // sm={{ span: 24 }}
                                    // md={{ span: 24 }}
                                    // lg={{ span: 24 }}
                                    // xl={{ span: 12, offset: 1, pull: 1 }}
                                    // xxl={{ span: 12, offset: 1, pull: 1 }}
                                  >
                                    <FormItem
                                      label={
                                        (item.fieldName === "firstName" &&
                                          "First Name") ||
                                        (item.fieldName === "lastName" &&
                                          "Last Name") ||
                                        (item.fieldName === "email" &&
                                          "Email") ||
                                        (item.fieldName === "phoneNumber" &&
                                          "Phone Number") ||
                                        (item.fieldName === "address" &&
                                          "Address")
                                      }
                                      name={
                                        item.fieldName === "address"
                                          ? "location.address"
                                          : item.fieldName
                                      }
                                      type="text"
                                      color="#ffff"
                                      // rules={[
                                      //   {
                                      //     required: item.required,
                                      //     message: "This is required",
                                      //   },
                                      // ]}
                                    >
                                      <FormInput maxWidth={"100%"} />
                                    </FormItem>
                                  </Col>
                                </>
                              ))}
                          </Row>
                          <StyledSubmitButtonWrapper>
                            <SecondaryButton htmlType="submit">
                              Submit
                            </SecondaryButton>
                          </StyledSubmitButtonWrapper>
                        </Form>
                      </StyledFormWrapper>
                    </>
                  )}
                </StyledContentPreview>
              </Col>
            ) : (
              ""
            )}
          </StyledRowWrapper>
        </Card>
      </Container>
    </LinkCreators>
  );
};

export default LinkCreator;
