export const DynamicConfig = {
    apiUrl: "https://vivo-myapi.xeromedia.com",
    environment: "DEV" | "TST" | "AKC" | "PROD"
}

export const defaultConfig = {
    apiUrl: "http://localhost:8080/undefinedApiUrl",
    environment: "DEV"
};

export const dynamicConfigUrl = "/config1.json";