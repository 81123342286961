import { httpAPI } from "api";

const GrowAPI = {
  addGrow(params) {
    return httpAPI(`/grow`, params, "post");
  },

  getGrowList(query) {
    return httpAPI(`/grow?${query || ""}`, "", "get");
  },
  getGrowListDetail(id, query) {
    return httpAPI(`/grow/${id}?${query || ""}`, "", "get");
  },
  updateGrow(id, params, query) {
    return httpAPI(`/grow/${id}?${query || ""}`, params, "patch");
  },
  deleteGrow(id, params) {
    return httpAPI(`/grow/${id}`, params, "delete");
  },
};

export default GrowAPI;
