import React, { useState, useCallback, useEffect } from "react";
import { Card } from "ui/Card";
import { Datatable } from "ui/Datatable";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import Actions from "ui/Datatable/Actions";
import { ToggleSwitch } from "ui/Form";
import { useHistory } from "react-router";
import { settingsRoutes } from "helpers/adminRoutes";
import { SecondaryFloatButton } from "ui/Button/index";
import PointsAPI from "api/http/points";
import { paginationObj, querystring, searchObj } from "utils/fn";
import { message } from "antd";
import { startCase } from "lodash";

const Points = () => {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const updateStatus = (typeId, status) => {
    PointsAPI.updatePoints(typeId, { status })
      .then(() => {
        getPointList(pagination);
        message.success("Type updated successfully");
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const handleDelete = (id) => {
    PointsAPI.deletePoints(id, { id })
      .then(() => {
        getPointList(pagination);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  useEffect(() => {
    getPointList(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (_, record) => {
        return (
          <ToggleSwitch
            checked={_}
            onChange={(value) => updateStatus(record._id, value)}
          />
        );
      },
      fixed: "left",
      width: 70,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      sorter: (a, b) => a.action.localeCompare(b.action),
    },
    {
      title: "Points Awarded",
      dataIndex: "pointsAwarded",
      key: "pointsAwarded",
      sorter: (a, b) => a.pointsAwarded.localeCompare(b.pointsAwarded),
    },
    {
      title: "Time Modifier",
      dataIndex: "timeModifier",
      key: "timeModifier",
      sorter: true,
      render: (_) => startCase(`${_ ? "Yes" : "No"}`),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(settingsRoutes.POINT_EDIT.replace(":id", item));
        break;
      case "delete":
        handleDelete(item);
        break;

      default:
        break;
    }
  };
  const filterChangeHandler = ({ search, ...page }, filters, sorter) => {
    const filter = searchObj(["name"], search);
    setPagination((p) => ({ ...p, ...page, filter, sorter }));
    getPointList({ ...pagination, ...page, filter, sorter });
  };

  const getPointList = useCallback((pagination) => {
    setLoading(true);
    PointsAPI.getPointsList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData(data?.data);
        setPagination((p) => ({ ...p, total: data?.total }));
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  const onhandleClick = () => {
    history.push(settingsRoutes.POINT_ADD);
  };

  return (
    <Container>
      <Heading>Points</Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          loading={loading}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <SecondaryFloatButton
              onClick={onhandleClick}
              buttonText="Create Points"
            />
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default Points;
