import { combineReducers } from "@reduxjs/toolkit";
import user from "./user.reducer";
import tab from "./tab.reducer"
import configData from "./configData.reducer"
// import theme from "./theme.reducer";

const appReducer = combineReducers({
  user,
  tab,
  configData
  //   theme,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
