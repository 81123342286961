import React from "react";

const DeleteSvg = (props) => {
  return (
   <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg"
         {...props}
>
<path d="M0.75 11.375C0.75 12.0078 1.24219 12.5 1.875 12.5L8.625 12.5C9.23438 12.5 9.75 12.0078 9.75 11.375L9.75 3.5L0.75 3.5L0.75 11.375ZM7.125 5.375C7.125 5.1875 7.28906 5 7.5 5C7.6875 5 7.875 5.1875 7.875 5.375L7.875 10.625C7.875 10.8359 7.6875 11 7.5 11C7.28906 11 7.125 10.8359 7.125 10.625L7.125 5.375ZM4.875 5.375C4.875 5.1875 5.03906 5 5.25 5C5.4375 5 5.625 5.1875 5.625 5.375L5.625 10.625C5.625 10.8359 5.4375 11 5.25 11C5.03906 11 4.875 10.8359 4.875 10.625L4.875 5.375ZM2.625 5.375C2.625 5.1875 2.78906 5 3 5C3.1875 5 3.375 5.1875 3.375 5.375L3.375 10.625C3.375 10.8359 3.1875 11 3 11C2.78906 11 2.625 10.8359 2.625 10.625L2.625 5.375ZM10.125 1.25L7.3125 1.25L7.07812 0.828125C6.98438 0.640625 6.79688 0.5 6.58594 0.5L3.89063 0.5C3.67969 0.5 3.49219 0.640625 3.39844 0.828125L3.1875 1.25L0.375 1.25C0.164063 1.25 0 1.4375 0 1.625L0 2.375C0 2.58594 0.164063 2.75 0.375 2.75L10.125 2.75C10.3125 2.75 10.5 2.58594 10.5 2.375V1.625C10.5 1.4375 10.3125 1.25 10.125 1.25Z" fill="white"/>
</svg>
  );
};

export default DeleteSvg;