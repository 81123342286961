import React from "react";
import styled from "styled-components";

const StickyMessage = ({
  isActive = false,
  message = "",
  onClick = console.log,
}) => {
  return (
    <StickyContainer isActive={isActive} onClick={onClick}>
      <Message>{message}</Message>
    </StickyContainer>
  );
};

const StickyContainer = styled.div`
  visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: ${({ isActive }) =>
    isActive
      ? "visibility 0s linear 0s, opacity 300ms"
      : "visibility 0s linear 300ms, opacity 300ms"};
  min-width: 210px;
  margin-left: -125px;
  text-align: center;
  /* padding: 16px; */
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 90px;
  cursor: pointer;
  /*Like-Ant Message */
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  pointer-events: all;
`;
const Message = styled.div`
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
`;

export default StickyMessage;
