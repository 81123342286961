import styled from "styled-components";
import { Menu } from "antd";

// Sidebar Styles
export const SidebarContainer = styled.div`
  /* width: 225px;
  background: #fff;
  box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.06);
  display: block;
  padding: 15px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transition: ease-in-out 0.5s;
  overflow-y: auto;
  overflow-x: hidden; */

  @media (max-width: 991.98px) {
    z-index: 111;
  }

  // scroll css
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 10px;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 57px;

  .ant-image {
    width: 100%;
  }
  .ant-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const Navigation = styled.div`
  margin-top: 45px;
  /* position: fixed; */
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
`;

export const MenuIconWrap = styled.div`
  transition: box-shadow 0.1s;
  height: 54px;
  width: 54px;
  background: #f6f7f9;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const SidebarMenu = styled(Menu)`
  border-right: none !important;
  margin-bottom: ${({ mb }) => mb || ""};
  .ant-menu-inline {
    border-right: none !important;
    background: transparent;
  }
`;
export const MenuDivider = styled(Menu.Divider)``;
export const MenuItem = styled(Menu.Item)`
  overflow: initial !important;
  //   padding-left: 0.2rem !important;

  &:not(.ant-menu-item-only-child) {
    height: 60px !important;
    color: #666666;
  }

  .ant-menu-title-content {
    font-weight: bold;
    font-size: 14px;
    line-height: 46px;
    color: #666666;
    a {
      font-weight: bold;
      font-size: 14px;
      line-height: 46px;
      color: #666666;
    }
  }

  &:hover {
    .ant-menu-title-content {
      a {
        color: #666666;
      }
    }
  }

  &.ant-menu-item-selected {
    background: transparent !important;
    overflow: initial !important;
    &:after {
      border-right: none !important;
    }
    .ant-menu-item-icon {
      background: linear-gradient(
        276.7deg,
        ${({ theme }) => theme.colors.primary} 0%,
        ${({ theme }) => theme.colors.primary2} 100%
      );
      box-shadow: 0px 10px 15px ${({ theme }) => theme.colors.primary}59;
      height: 54px;
      width: 54px;
      border-radius: 50px;
      svg {
        fill: #ffffff;
      }
    }
    .ant-menu-title-content {
      a {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;
export const SubMenu = styled(Menu.SubMenu)`
  overflow: initial !important;
  &.sub-children {
    .ant-menu-submenu-title {
      height: 40px !important;
      .ant-menu-title-content {
        color: #666666;
      }
      .ant-menu-submenu-arrow {
        color: #666666;
      }
    }
  }

  .ant-menu-submenu-title {
    font-weight: bold;
    height: 60px !important;
    overflow: initial !important;

    &:active {
      background: transparent !important;
    }
    &:hover {
      color: #666666;
    }
  }

  .ant-menu-sub {
    // padding-left: calc(0.5rem * ${({ level }) => level || 1}) !important;
    .ant-menu-item {
      color: inherit;
      background: transparent !important;
      overflow: initial !important;
      &:hover {
        color: #666666;
      }
      &:after {
        border-right: none !important;
      }
    }
  }

  &:not(.ant-menu-submenu-selected) {
    color: #666666;
  }

  &:hover {
    color: #666666;
    .ant-menu-title-content {
      color: #666666;
    }
    .ant-menu-submenu-arrow {
      color: #666666 !important;
    }
  }

  &.ant-menu-submenu-selected {
    color: ${({ theme }) => theme.colors.primary};
    .ant-menu-item-icon {
      background: linear-gradient(
        276.7deg,
        ${({ theme }) => theme.colors.primary} 0%,
        ${({ theme }) => theme.colors.primary2} 100%
      );
      box-shadow: 0px 10px 15px ${(props) => props.theme.colors.primary}59;
      height: 54px;
      width: 54px;
      border-radius: 50px;
      svg {
        fill: #ffffff;
      }
    }
    .ant-menu-title-content {
      color: ${({ theme }) => theme.colors.primary};
    }
    .ant-menu-submenu-arrow {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const SideMenuGroup = styled.div``;

export const Spacer = styled.div`
  transition: ease-in-out 0.5s;
  flex: ${({ flex }) => flex || "auto"};
`;

// Sidebar Styles END

// Breadcrumb
export const BreadCrumbsWrapper = styled.div`
  display: inline-block;
  width: 100%;
  /* margin-top: 17px; */
  padding: 17px 30px 17px 35px;
  border-bottom: 1px solid #f0f0f0;
  background-color: ${(props) => props.theme.colors.third};

  .ant-breadcrumb {
    .ant-breadcrumb-link {
      margin-left: -3px;
      margin-right: -3px;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      color: ${(props) => props.theme.colors.primary};
    }
    > :last-child {
      color: #747487 !important;
      a {
        color: #747487 !important;
      }
    }
    > :not(:last-child) {
      color: ${(props) => props.theme.colors.primary} !important;
      a {
        color: ${(props) => props.theme.colors.primary} !important;
        &:hover {
          color: ${(props) => props.theme.colors.primary};
        }
      }
    }
    .ant-breadcrumb-separator {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .breadcrumbs_label {
    color: ${(props) => props.theme.colors.primary};
  }

  /* @media (max-width: 991px) {
    padding: 0px 15px 17px 15px;
  } */
`;
// Breadcrumb END

export const TextWithBadgeWrapper = styled.div`
  /* display: flex;
  justify-content: space-between;
  align-items: center; */

  /* display: inline-block;
  width: 100%; */

  /* position: relative; */

  /* display: inline-block; */
  display: flex;
  width: 100%;

  .nameeee {
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 20px;
  }
`;

export const StyledBadge = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
`;
