import React, { useState } from "react";
import { Select, Row } from "antd";
import styled from "styled-components";
import { UserAvatar } from "ui/Avatar";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";

export const FormSelect = ({ options = [], ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownHandler = (v) => {
    setIsOpen(v);
  };

  return (
    <StyledSelect
      {...props}
      suffixIcon={
        isOpen ? (
          <HiOutlineChevronUp className="select_icon" />
        ) : (
          <HiOutlineChevronDown className="select_icon" />
        )
      }
      onDropdownVisibleChange={dropdownHandler}
      dropdownRender={(menu) => (
        <StyledSelectWrapper>{menu}</StyledSelectWrapper>
      )}
    >
      {props?.hasGroup
        ? Object.keys(options).map((group) => (
            <SelectOptGroup key={group} label={group}>
              {options[group].map(({ avatar, label, value }, idx) => (
                <SelectOption
                  key={value + idx}
                  className="f-select"
                  label={label}
                  value={value}
                >
                  <SelectOptionContent>
                    {avatar ? (
                      <Row align="middle" justify="center">
                        <UserAvatar
                          border={"0"}
                          {...avatar}
                          className="userImage"
                          size={22}
                        />
                      </Row>
                    ) : (
                      ""
                    )}

                    <span>{label}</span>
                  </SelectOptionContent>
                </SelectOption>
              ))}
            </SelectOptGroup>
          ))
        : options.map(({ avatar, label, value }, idx) => (
            <SelectOption
              key={value + idx}
              className="f-select"
              label={label}
              value={value}
            >
              <SelectOptionContent>
                {avatar ? (
                  <Row align="middle" justify="center">
                    <UserAvatar
                      border={"0"}
                      {...avatar}
                      className="userImage"
                      size={22}
                    />
                  </Row>
                ) : (
                  ""
                )}

                <span>{label}</span>
              </SelectOptionContent>
            </SelectOption>
          ))}
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  &.ant-select {
    &.ant-select-focused {
      .ant-select-selector {
        border-color: ${({ theme }) => theme.colors.primary} !important;
      }
    }
    width: 100%;
    /* max-width: 822px; */
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "822px")};

    .ant-select-selection-item {
      background: ${({ bg }) => (bg ? bg : "initial")};
    }

    /* .ant-select.ant-select-multiple .ant-select-selection-item {

  } */

    .ant-select-selector {
      background: #f6f7f9;
      border-color: #e5e5e5;
      .ant-select-selection-item {
        line-height: 48px;
        color: #747487;
        display: flex;
        align-items: center;
        /* > div {
          margin: 0 !important;
        } */
      }
      .ant-select-selection-search {
        input {
          background: #f6f7f9;
          color: #747487;
          min-height: 48px;
        }
      }
      &:hover {
        border-color: #e5e5e5;
      }
      border: 1px solid #e5e5e5;
      border-radius: 5px;
      /* height: 48px; */
      min-height: 48px;
    }
    &:hover {
      border-color: #e5e5e5;
    }
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
    }

    .ant-select-arrow {
      font-size: 18px;
      height: 18px;
      width: 18px;
    }

    &.ant-select-multiple {
      .ant-select-selection-item {
        height: 34px;
        align-items: center;
      }

      .ant-select-selection-overflow-item {
        display: flex;
        align-items: center;
        height: 44px;
      }

      .ant-select-selector .ant-select-selection-search input {
        min-height: 34px;
      }
    }
  }

  span.ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`;

export const StyledSelectWrapper = styled.div`
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${({ theme }) => theme.colors.secondary};
    /* background-color: white; */
  }

  .anticon-check {
    display: none;
  }
`;

export const SelectOption = styled(Select.Option)`
  span {
    color: ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary};
  }
`;

export const SelectOptGroup = styled(Select.OptGroup)``;

export const SelectOptionContent = styled.div`
  height: 48px;
  margin-top: -5px;
  margin-bottom: -5px;
  color: #747487;
  display: flex;
  align-items: center;

  .userImage {
    margin-right: 7px;
    border: 1px solid white;
  }
`;
