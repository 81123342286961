import { Breadcrumb, Progress } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "antd/lib/radio";
import { useHistory } from "react-router-dom";
import { FieldTimeOutlined } from "@ant-design/icons";

const StyleLayout = styled.div`
  padding: 15px;
  background: #f6f7f9;
  border-radius: 20px 20px 0px 0px;
  @media screen and (max-width: 959px) {
    padding: 15px;
  }
`;

const StyledListBOX = styled.section`
  max-width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  position: relative;
  .ant-radio-button-wrapper {
    border: none;
    content: none;
    height: inherit;
    // position: absolute;
    padding: 0px;
    right: 0%;
    span:nth-child(2) {
      display: flex;
      flex-direction: row;
    }
    // span:first-child {
    //     margin-right: 10px;
    // }
    .ant-radio-button-wrapper {
      margin-right: 15px;
      width: 40%;
      span {
        border-radius: 5px;
        width: 100%;
        iframe {
          border-radius: 5px;
          width: 100%;
          border: 0px;
        }
      }
    }
  }
  .ant-radio-button-wrapper:before {
    content: none;
  }
`;

const StyledVideoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 58px;
`;

const StyledVideoList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 40%;
  min-width: 40%;
  margin-right: 30px;
`;

const StyledVideoDetail = styled.div`
  width: 100%;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  height: fit-content;
  .ant-radio-button-wrapper {
    border: none;
    content: none;
    height: inherit;
    // position: absolute;
    padding: 15px;
    right: 0%;
    border-radius: 10px;
  }
  .ant-radio-button-wrapper span:nth-child(2) {
    display: flex;
    flex-direction: column;
    iframe {
      max-height: 400px;
      min-height: 200px;
      height: 100%;
      width: 100%;
      margin-bottom: 12px;
      border-radius: 10px;
      border: 0px;
    }
  }
  .ant-radio-button-wrapper:before {
    content: none;
  }
`;

const StyledWelcomeLabel = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #232333;
  margin-bottom: 30px;
`;

const StyledVideoListDetail = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;

const StyledVideHeading = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.primary};
  margin-bottom: 10px;
`;

const StyledVideoByName = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #747487;
  margin-bottom: 10px;
  img {
    margin-right: 8px;
    border-radius: 50%;
  }
`;

const StyledVideoTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  span {
    margin-right: 5px;
  }
`;

const StyledVideoTime = styled.label`
  font-family: Font Awesome 5 Free;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #747487;
`;

const StyledVideDetailHeading = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 22px;
  color: #232333;
  margin-bottom: 11px;
`;

const StyledVideViewTimeDetail = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #747487;
  margin-bottom: 14px;
`;

const StyledVideoDescription = styled.label`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #747487;
`;

const data = [
  {
    id: 1,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 2,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 3,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 4,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 5,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
  {
    id: 6,
    video: "https://www.youtube.com/embed/tgbNymZ7vqY",
    name: "Juliana McElroy",
    total: 10,
  },
];

const VideoDetail = () => {
  const [state, setState] = useState({
    index: 0,
  });

  const handleVideoById = (e, i) => {
    console.log("index------------------", i);
    setState({ index: i });
  };

  return (
    <div>
      {console.log(
        "statess",
        state.showActivity,
        state.showContact,
        state.showMove
      )}
      <StyleLayout>
        <div className="breadcrumbs">
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Grow</Breadcrumb.Item>
            <Breadcrumb.Item>Watch Video</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <StyledVideoWrapper>
          <StyledVideoList>
            <StyledWelcomeLabel>Welcome</StyledWelcomeLabel>
            {data.map((k, index) => (
              <StyledListBOX key={index}>
                <Button onClick={(e) => handleVideoById(e, index)}>
                  <Button>
                    <iframe src={k.video}></iframe>
                  </Button>

                  <StyledVideoListDetail>
                    <StyledVideHeading>
                      {index + 1}. Why Young Living?
                    </StyledVideHeading>
                    <StyledVideoByName>
                      {" "}
                      <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=30" />{" "}
                      By {k.name}{" "}
                    </StyledVideoByName>
                    <StyledVideoTimeWrapper>
                      <FieldTimeOutlined />
                      <StyledVideoTime> 1 Hours </StyledVideoTime>
                    </StyledVideoTimeWrapper>
                    <Progress strokeLinecap="square" percent={75} />
                  </StyledVideoListDetail>
                </Button>
              </StyledListBOX>
            ))}
          </StyledVideoList>

          <StyledVideoDetail>
            <Button>
              <iframe src={data[state.index].video}></iframe>
              <StyledVideDetailHeading>
                {state.index + 1}. Why young living?
              </StyledVideDetailHeading>
              <StyledVideViewTimeDetail>
                6042 Views | <FieldTimeOutlined /> 1 Hours
              </StyledVideViewTimeDetail>
              <StyledVideoByName>
                {" "}
                <img src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=30" />{" "}
                Julian{" "}
              </StyledVideoByName>
              <StyledVideoDescription>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </StyledVideoDescription>
            </Button>
          </StyledVideoDetail>
        </StyledVideoWrapper>
      </StyleLayout>
    </div>
  );
};

export default VideoDetail;
