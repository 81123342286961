import { Button } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useProgressiveImage } from "utils/hooks/useProgressiveImage";
import { authRoutes } from "../../helpers/adminRoutes";
import {
  StyledBg,
  StyledContainer,
  StyledLeftContainer,
  StyledLoginSignButtonWrapper,
  StyledLogoLabel,
  StyledRightContainer,
} from "./AuthLayout.styles";
import AuthRoutes from "./AuthRoutes";
import { useDispatch, useSelector } from "react-redux";
import { login, portal } from "store/reducers/user.reducer";
import { useWindowSize } from "utils/hooks/useWindowSize";

const AuthLayout = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { height } = useWindowSize();

  const { landingPageLogo, landingPageBackground } = useSelector(
    (state) => state.user
  );
  const bgImage = useProgressiveImage(landingPageBackground);
  const logoImage = useProgressiveImage(landingPageLogo);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => { };
  }, [pathname]);

  useEffect(() => {
    dispatch(portal());
  }, []);
  return (
    <StyledContainer>
      <StyledLeftContainer>
        <StyledBg minH={height} backgroundImg={bgImage}>
          <StyledLogoLabel>
            {!!logoImage && <img src={logoImage} alt="cover" />}
          </StyledLogoLabel>
        </StyledBg>
        {[authRoutes.LOGIN, authRoutes.REGISTRATION].includes(pathname) && (
          <StyledLoginSignButtonWrapper>
            <Button
              size="large"
              className={`ant-btn-tab ${pathname === authRoutes.LOGIN ? "active" : ""
                }`}
            >
              <Link to={authRoutes.LOGIN}>Login</Link>
            </Button>
            <Button
              size="large"
              className={`ant-btn-tab ${pathname === authRoutes.REGISTRATION ? "active" : ""
                }`}
            >
              <Link to={authRoutes.REGISTRATION}>Join</Link>
            </Button>
          </StyledLoginSignButtonWrapper>
        )}
      </StyledLeftContainer>
      <StyledRightContainer>
        <AuthRoutes />
      </StyledRightContainer>
    </StyledContainer>
  );
};

export default AuthLayout;
