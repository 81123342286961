import styled from "styled-components";

export const StyledLink = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #747487;
  :hover {
    color: ${(props) => props.theme.colors.primary};
    text-decoration-line: underline;
  }
`;

export const StyledTabButtonWrap = styled.div`
  display: inline-block;
  width: 100%;
  margin-left: -30px;
  .ant-tabs {
    overflow: unset;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 35px;
    padding: 0 30px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 276px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 50px;
    justify-content: center;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 46px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: unset;
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #949494;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-tabs-ink-bar {
    height: 2px;
    background: ${(props) => props.theme.colors.primary} !important;
    border-radius: 50px;
  }

  @media (max-width: 991.98px) {
    .ant-tabs-top > .ant-tabs-nav {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 80px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      justify-content: space-evenly;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      margin-bottom: 35px;

      width: 100%;
      justify-content: center;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 14px;
    }
  }

  @media (max-width: 575.98px) {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }

    .ant-tabs-tab-btn {
      font-weight: normal;
      font-size: 12px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: normal;
      font-size: 12px;
    }

    .ant-tabs-top > .ant-tabs-nav {
      padding: 0 15px;
    }
  }
`;

export const StyledFormItem = styled.div`
  width: 100%;
  max-width: 100%;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f6f7f9;
    // border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 5px;
    height: 48px;
  }
  .linkAddOn .ant-input-group-addon {
    width: 80px;
    min-width: 120px;
    height: 48px;
    background: ${(props) => props.theme.colors.secondary};
    box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
    border-radius: 0px 5px 5px 0px;

    font-size: 14px;
    color: ${(props) => (props.color ? props.color : "#FFFFFF")};

    .ant-btn:active,
    .ant-btn {
      background: ${(props) => props.theme.colors.secondary};
      border: none;
    }
  }

  .dynamicButtonInput {
    display: flex;
  }

  .dynamicAddOn .ant-input-group-addon {
    background: transparent;
    border: none;

    .eye {
      margin-left: 10px;
      background: linear-gradient(
        276.7deg,
        ${(props) => props.theme.colors.primary} 0%,
        ${(props) => props.theme.colors.primary2} 100%
      );
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .delete {
      margin-left: 10px;
      background: linear-gradient(142.07deg, #fc3c3c 13.94%, #ad1b1b 86.39%);
    }
  }
  .ant-form-item {
    display: flex;
    flex-direction: row;
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600px;
      font-size: 14px;
      line-height: 18px;
      width: 140px;
      font-weight: bold;
      color: #232333;
    }

    input,
    textarea {
      background: #f6f7f9;
      // border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 5px;
      height: 48px;
      /* height: auto; */
    }
    textarea {
      height: 128px;
    }
  }
`;

export const StyledFormItemButton = styled.div`
  button {
    margin-left: 140px;
  }
`;
