import { httpAPI } from "api";

const TypeAPI = {
  addType(params) {
    return httpAPI(`/types`, params, "POST");
  },
  getTypeList(query) {
    return httpAPI(`/types?${query || ""}`, "", "GET");
  },
  getType(id = "", query = "") {
    return httpAPI(`/types/${id}?${query}`, "", "GET");
  },
  updateType(id, params) {
    return httpAPI(`/types/${id}`, params, "PATCH");
  },
  deleteType(id, params) {
    return httpAPI(`/types/${id}`, params, "DELETE");
  },
};

export default TypeAPI;
