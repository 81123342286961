import React, { useEffect, useState } from "react";
import { Modal, Form } from "antd";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import AddTeamForm from "../../Admin/ModuleSetting/Team/AddTeam";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
const AddTeamModal = ({
  teamIdUser,
  modalState,
  onCancel = console.log,
  onSubmit = console.log,
  from = "",
  _id = "",
  isEdit = false,
  ...props
}) => {
  const [isFormChange, setIsFormChange] = React.useState(false);
  const onSubmitForm = () => {
    onCancel(false);
    onSubmit();
  };

  function fromChanges() {
    setIsFormChange(true);
  }

  const dData = (data, admin) => {
    teamIdUser(data, admin);
  };

  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
      // props.setModalStat(false);
    } else {
    }
  };

  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  return (
    <>
      {/* {
        <Beforeunload
          onBeforeunload={() =>
            "You will lose all changes if you navigate away from this page without saving"
          }
        />
      }
      <Prompt
        when={isFormChange}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt> */}

      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          !isFormChange
            ? onCancel(false)
            : openPrompt(
                "You will lose all changes if you navigate away from this page without saving"
              );
        }}
        width={"960px"}
        footer={null}
        style={{ borderRadius: "5px" }}
        className="modal_wrapper"
        closeIcon={
          <IoIosClose
            className="modal_close_icon"
            // onClick={() =>
            //   !isFormChange
            //     ? onCancel(false)
            //     : openPrompt(
            //         "You will lose all changes if you navigate away from this page without saving"
            //       )
            // }
          />
        }
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        <AddTeamForm
          isModal={true}
          _id={_id}
          onFormChange={fromChanges}
          isEdit={isEdit}
          onBack={onSubmitForm}
          from={from}
          teamIdUser={dData}
        />
      </Modal>
    </>
  );
};

export default AddTeamModal;
