import { StyledActionButton } from "./style";

export const data = {
  organizedBy: "Katie Richards",
  date: "Sept 18th 2021",
  time: "4:00pm - 6:00pm",
  categories: ["Category sample", "Category", "Another category"],
  team: [
    { image: "/images/profile-pic.svg", name: "Phil Weber" },
    { image: "/images/profile-pic.svg", name: "Matt McDonald" },
    { image: "/images/profile-pic.svg", name: "Katie Richards" },
    { image: "/images/profile-pic.svg", name: "Phil Weber" },
    { image: "/images/profile-pic.svg", name: "Matt McDonald" },
    { image: "/images/profile-pic.svg", name: "Katie Richards" },
  ],
};
export const options = ["I'm Going"];

const ActionButton = ({ text }) => (
  <StyledActionButton>{text}</StyledActionButton>
);

export const TeamData = [
  {
    cardImg: "/images/tracter.png",
    cardHeading: "YL Rockstars",
    cardGroup: "Public",
    cardMembers: 10249,
    actionButton: <ActionButton text={"Join"} />,
    cardDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    users: [
      {
        firstName: "Julianne",
        lastName: "McElroy",
        avatar: { path: "/images/profile-pic.png", isStatic: true },
        location: { address: "Baltimore, MD" },
      },
      {
        firstName: "Katie",
        lastName: "Richards",
        avatar: { path: "/images/profile-pic.png", isStatic: true },
        location: { address: "Baltimore, MD" },
      },
      {
        firstName: "Matt",
        lastName: "McDonald",
        avatar: { path: "/images/profile-pic.png", isStatic: true },
        location: { address: "Baltimore, MD" },
      },
    ],
  },
  {
    cardImg: "/images/tracter.png",
    cardHeading: "YL Hacks",
    cardGroup: "Private",
    cardMembers: 11789,
    actionButton: <ActionButton text={"Request"} />,
    cardDescription:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    users: [
      {
        firstName: "Julianne",
        lastName: "McElroy",
        avatar: { path: "/images/profile-pic.png", isStatic: true },
        location: { address: "Baltimore, MD" },
      },
      {
        firstName: "Katie",
        lastName: "Richards",
        avatar: { path: "/images/profile-pic.png", isStatic: true },
        location: { address: "Baltimore, MD" },
      },
      {
        firstName: "Matt",
        lastName: "McDonald",
        avatar: { path: "/images/profile-pic.png", isStatic: true },
        location: { address: "Baltimore, MD" },
      },
    ],
  },
];

export const UserProfileData = {
  image: "/images/profile-pic.svg",
  name: "Julianne McElroy",
  createAt: "3 hour ago",
  email: "julianne@gmail.com",
  location: "Baltimore, MD",
};

export const UserProfileEventData = [
  {
    eventName: "Introduction To Young Living",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event1.png",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 6,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 7,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 8,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 9,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Culinary Tasting Party",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event2.png",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 6,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 7,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Vibe Barbecue",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event3.png",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 6,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
  {
    eventName: "Convenation Recap Meeting",
    eventAt: "Sept 18th 2021 | 4:00 pm",
    eventBy: "By Julianne McElroy",
    image: "/images/event/event4.png",
    users: [
      {
        id: 1,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 2,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 3,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 4,
        avatar: "https://i.pravatar.cc/50",
      },
      {
        id: 5,
        avatar: "https://i.pravatar.cc/50",
      },
    ],
  },
];
export const PlayListPageData = {
  image: "/images/videos/video1.png",
  videoName: "1. Why Young Living?",
  videoBy: "By Julianne McElroy",
  createdAt: "2021-12-13",
  barImage: "/images/videos/bar1.png",
  views: "502",
  c: "/images/videos/bar1.png",
  discription:
    " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",
  user: {
    firstName: "Jayen ",
    lastName: "McElroy",
    avatar: {
      path: "https://i.pravatar.cc/50",
    },
  },
};

export const UserProfileVideosData = [
  {
    _id: "1",
    image: "/images/videos/video1.png",
    videoName: "1. Why Young Living?",
    videoBy: "By Julianne McElroy",
    createdAt: "2021-12-13",
    watched: "100",
    barImage: "/images/videos/bar1.png",
    views: "502",
    c: "/images/videos/bar1.png",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",
    user: {
      firstName: "Jayen ",
      lastName: "McElroy",
      avatar: {
        path: "https://i.pravatar.cc/50",
      },
    },
    comments: [
      {
        _id: 2,
        status: true,
        video: "",
        title: "Health Life",
        user: {
          firstName: "John",
          lastName: "Manhar",
          avatar: {
            path: "https://i.pravatar.cc/40",
          },
        },
        details: "30K views",
        createdAt: "",
        Availability: "Available To Team",
        comment: "After sun spray! Just in case:)",
        likers: [1, 1, 1, 1],
        createdAt: "2021-12-10",
      },
      {
        _id: 3,
        status: true,
        video: "",
        title: "Breathing",
        user: {
          firstName: "Julianne",
          lastName: "Devid",
          avatar: {
            path: "https://i.pravatar.cc/30",
          },
        },
        details: "1.5K views",
        πcreatedAt: "",
        Availability: "Publicly Available",
        comment: "Bug repellant and hand sanitizer!!!!! ",
        likers: [1, 1],
        createdAt: "2021-12-13",
      },
      {
        _id: 4,
        status: true,
        video: "",
        title: "Sleep",
        user: {
          firstName: "John",
          lastName: "Vornar",
          avatar: {
            path: "https://i.pravatar.cc/60",
          },
        },
        details: "04K views",
        createdAt: "",
        Availability: "Available To Team",
        comment: "Awesome",
        likers: [],
        createdAt: "2021-08-10",
      },
    ],
  },
  {
    _id: "2",
    image: "/images/videos/video2.png",
    videoName: "2. How Vivo Vibe Works",
    videoBy: "By Julianne McElroy",
    createdAt: "2021-12-10",
    watched: "100",
    views: "102",
    barImage: "/images/videos/bar2.png",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",

    user: {
      firstName: "John",
      lastName: "Manhar",
      avatar: {
        path: "https://i.pravatar.cc/40",
      },
    },
    comments: [
      {
        _id: 2,
        status: true,
        video: "",
        title: "Breathing",
        user: {
          firstName: "Julianne",
          lastName: "Devid",
          avatar: {
            path: "https://i.pravatar.cc/30",
          },
        },
        details: "1.5K views",
        πcreatedAt: "",
        Availability: "Publicly Available",
        comment: "Bug repellant and hand sanitizer!!!!! ",
        likers: [1, 1],
        createdAt: "2021-12-13",
      },
      {
        _id: 3,
        video: "",
        title: "Sleep",
        user: {
          firstName: "John",
          lastName: "Vornar",
          avatar: {
            path: "https://i.pravatar.cc/60",
          },
        },
        details: "04K views",
        createdAt: "",
        Availability: "Available To Team",
        comment: "Awesome",
        likers: [],
        createdAt: "2021-08-10",
      },
      {
        _id: 4,
        status: true,
        video: "",
        title: "Sleep",
        user: {
          firstName: "John",
          lastName: "Vornar",
          avatar: {
            path: "https://i.pravatar.cc/60",
          },
        },
        details: "04K views",
        createdAt: "",
        Availability: "Available To Team",
        comment: "Awesome",
        likers: [],
        createdAt: "2021-08-10",
      },
    ],
  },
  {
    _id: "3",
    image: "/images/videos/video3.png",
    videoName: "3. How And Why To Qualify For The ",
    videoBy: "By Julianne McElroy",
    createdAt: "2021-12-07",
    barImage: "/images/videos/bar3.png",
    views: "520",
    watched: "25",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",

    user: {
      firstName: "Julianne",
      lastName: "Devid",
      avatar: {
        path: "https://i.pravatar.cc/30",
      },
    },
    comments: [
      {
        _id: 2,
        status: true,
        video: "",
        title: "Sleep",
        user: {
          firstName: "John",
          lastName: "Vornar",
          avatar: {
            path: "https://i.pravatar.cc/60",
          },
        },
        details: "04K views",
        createdAt: "",
        Availability: "Available To Team",
        comment: "Awesome",
        likers: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
        createdAt: "2021-08-10",
      },
      {
        _id: 3,
        status: true,
        video: "",
        title: "Breathing",
        user: {
          firstName: "Julianne",
          lastName: "Devid",
          avatar: {
            path: "https://i.pravatar.cc/30",
          },
        },
        details: "1.5K views",
        πcreatedAt: "",
        Availability: "Publicly Available",
        comment: "Bug repellant and hand sanitizer!!!!! ",
        likers: [1, 1, 1, 1, 1, 1, 1, 1, 1],
        createdAt: "2021-12-13",
      },
    ],
  },
  {
    image: "/images/videos/video4.png",
    videoName: "4. Ready To Sign Up?",
    videoBy: "By Julianne McElroy",
    createdAt: "2021-10-13",
    watched: "",
    barImage: "/images/videos/bar4.png",
    views: "50",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",
    _id: "4",

    user: {
      firstName: "John",
      lastName: "Manhar",
      avatar: {
        path: "https://i.pravatar.cc/40",
      },
    },
  },
  {
    image: "/images/videos/video3.png",
    videoName: "5. Young Living Member Groups.",
    videoBy: "By Julianne McElroy",
    createdAt: "2021-12-01",
    watched: "",
    barImage: "/images/videos/bar3.png",
    views: "720",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",
    _id: "5",

    user: {
      firstName: "John",
      lastName: "Vornar",
      avatar: {
        path: "https://i.pravatar.cc/60",
      },
    },
  },
  {
    image: "/images/videos/video1.png",
    videoName: "6. YL Member Education In Less Than 1hr/Month.",
    videoBy: "By Julianne McElroy",
    createdAt: "2021-12-01",
    watched: "",
    _id: "6",

    barImage: "/images/videos/bar1.png",
    views: "5070",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video2.png",
    videoName: "Video #201 Mind Health Life",
    videoBy: "By Julianne McElroy",
    createdAt: "5/10",
    watched: "",
    views: "5200",
    _id: "7",

    barImage: "/images/videos/bar2.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video3.png",
    videoName: "Acapulco #3",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    watched: "",
    _id: "8",

    barImage: "/images/videos/bar3.png",
    views: "780",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video1.png",
    videoName: "Video #202 Mind Health Life",
    videoBy: "By Julianne McElroy",
    createdAt: "5/9",
    _id: "9",

    barImage: "/images/videos/bar1.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video4.png",
    videoName: "The 15 Basics",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    _id: "10",
    barImage: "/images/videos/bar4.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video3.png",
    videoName: "Acapulco #3",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    _id: "11",
    barImage: "/images/videos/bar3.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },

  {
    image: "/images/videos/video1.png",
    videoName: "Video #202 Mind Health Life",
    videoBy: "By Julianne McElroy",
    createdAt: "5/9",
    _id: "12",
    barImage: "/images/videos/bar1.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
];
export const UserProfileVideosDataHome = [
  {
    _id: "1",
    image: "/images/videos/video1.png",
    videoName: "Video #202 Mind Health Life?",
    videoBy: "By Julianne McElroy",
    createdAt: "5/9",
    watched: "45",
    barImage: "/images/videos/bar1.png",
    views: "502",
    c: "/images/videos/bar1.png",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",
    user: {
      firstName: "Jayen ",
      lastName: "McElroy",
      avatar: {
        path: "https://i.pravatar.cc/50",
      },
    },
  },
  {
    _id: "2",
    image: "/images/videos/video2.png",
    videoName: "Video #201 Mind Health Life",
    videoBy: "By Julianne McElroy",
    createdAt: "3/10",
    watched: "30",
    views: "102",
    barImage: "/images/videos/bar2.png",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",

    user: {
      firstName: "John",
      lastName: "Manhar",
      avatar: {
        path: "https://i.pravatar.cc/40",
      },
    },
  },
  {
    _id: "3",
    image: "/images/videos/video3.png",
    videoName: "Acapulco #3",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    barImage: "/images/videos/bar3.png",
    views: "520",
    watched: "45",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",

    user: {
      firstName: "Julianne",
      lastName: "Devid",
      avatar: {
        path: "https://i.pravatar.cc/30",
      },
    },
  },
  {
    image: "/images/videos/video4.png",
    videoName: "The 15 Basics",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    watched: "20",
    barImage: "/images/videos/bar4.png",
    views: "50",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",
    _id: "4",

    user: {
      firstName: "John",
      lastName: "Manhar",
      avatar: {
        path: "https://i.pravatar.cc/40",
      },
    },
  },
  {
    image: "/images/videos/video3.png",
    videoName: "Breathing",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    watched: "45",
    barImage: "/images/videos/bar3.png",
    views: "720",
    discription:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam efficitur iaculis sem non blandit. Fusce leo lacus, maximus vitae erat at, dictum bibendum felis. Nunc in massa quis eros volutpat scelerisque id quis felis.",
    _id: "5",

    user: {
      firstName: "John",
      lastName: "Vornar",
      avatar: {
        path: "https://i.pravatar.cc/60",
      },
    },
  },
  {
    image: "/images/videos/video1.png",
    videoName: "Finances",
    videoBy: "By Julianne McElroy",
    createdAt: "10/10",
    watched: "100",
    _id: "6",

    barImage: "/images/videos/bar1.png",
    views: "5070",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video2.png",
    videoName: "Meditation",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    watched: "45",
    views: "5200",
    _id: "7",

    barImage: "/images/videos/bar2.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video3.png",
    videoName: "Video #110 Mind Health Life",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    watched: "20",
    _id: "8",

    barImage: "/images/videos/bar3.png",
    views: "780",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video1.png",
    videoName: "Sleep",
    videoBy: "By Julianne McElroy",
    createdAt: "4/10",
    watched: "45",

    _id: "9",

    barImage: "/images/videos/bar1.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video4.png",
    videoName: "Personal Growth Plan",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    watched: "100",

    _id: "10",
    barImage: "/images/videos/bar4.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
  {
    image: "/images/videos/video3.png",
    videoName: "Video #202 Mind Health Life",
    videoBy: "By Julianne McElroy",
    createdAt: "",
    watched: "45",

    _id: "11",
    barImage: "/images/videos/bar3.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },

  {
    image: "/images/videos/video1.png",
    videoName: "Video #201 Mind Health Life",
    videoBy: "By Julianne McElroy",
    createdAt: "7/10",
    watched: "75",

    _id: "12",
    barImage: "/images/videos/bar1.png",
    avatar: {
      path: "https://i.pravatar.cc/30",
    },
  },
];
