import { adminRoutes } from "helpers/adminRoutes";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SecondaryFloatButton } from "ui/Button";
import { Card } from "ui/Card/Card";
import { Datatable, UserAvatarAndFullName } from "ui/Datatable";
import Actions from "ui/Datatable/Actions";
import { ToggleSwitch } from "ui/Form";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { imagePath, paginationObj, querystring } from "utils/fn";
import { videosData } from "../dummy.data";
import { UserProfileVideosData } from "Components/Profile/ProfileData";
import ImagePreview from "ui/ImagePreview/ImagePreview";
import GrowAPI from "api/http/grow";
import { useDispatch, useSelector } from "react-redux";
import VideoAPI from "api/http/videos";
import { Image } from "../../../../node_modules/antd/lib/index";
import { StyledLink } from "Components/SourceList/TheSourceList.styles";
import Images from "Components/FbCustomImageGallery/Images";

const ManageVideos = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { configData } = useSelector((state) => state.configData);
  const config = JSON.parse(localStorage.getItem("config"));
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: { uploadedBy: user?._id || localStorage.getItem("userId") },
    populate: [
      {
        path: "uploadedBy",
      },
    ],
    sorter: {},
  });

  const [realData, setRealData] = useState(UserProfileVideosData);

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (_, record) => {
        return (
          <ToggleSwitch
            key={record._id}
            defaultChecked={_}
            onChange={(e) => handleStatus(e, record._id)}
          />
        );
      },

      fixed: "left",
      width: 100,
    },
    {
      title: "Video",
      dataIndex: "video",
      key: "video",
      sorter: false,
      width: 200,
      render: (_, row) => {
        return (
          <Images
            images={[`${config?.apiUrl}${row?.thumbnail}`]}
            // images={image.map((img) =>  imagePath(img.path))}
            previewImages={[`${config?.apiUrl}${row?.thumbnail}`]}
          ></Images>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Uploaded By",
      dataIndex: "uploadedBy",
      key: "uploadedBy",
      width: 200,
      sorter: false,
      render: (_, row) => {
        return (
          <UserAvatarAndFullName
            name={`${row?.uploadedBy?.firstName} ${row?.uploadedBy?.lastName}`}
            image={
              row?.uploadedBy?.avatar?.path
                ? imagePath(row?.uploadedBy?.avatar?.path)
                : ""
            }
          />
        );
      },
    },
    {
      title: "Views",
      dataIndex: "views",
      key: "details",
      width: 150,
      render: (_, row) => {
        return <div>{row?.watchedUsers?.length} views</div>;
      },
    },
    {
      title: "Source",
      dataIndex: "",
      key: "source",
      width: 150,
      render: (_, record) => {
        return (
          <span>
            <StyledLink
              href={
                window.location.origin + "/grow/watch/video/" + record.playlist
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview Link
            </StyledLink>
          </span>
        );
      },
    },
    {
      title: "Availability",
      dataIndex: "visibility",
      key: "visibility",
      width: 150,
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  const getVideoList = useCallback((pagination) => {
    VideoAPI.getVideoList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setPagination((p) => ({ ...p, total: data?.total }));
        let arr = [];
        // for(const dta of  data?.data){
        //     if(dta?.playlist)
        // }
        setData(data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // message.error(error.message);
      });
  }, []);

  useEffect(() => {
    getVideoList(pagination);
    return () => {
      setLoading(false);
    };
  }, []);

  const handleStatus = (e, id) => {
    VideoAPI.updateVideo(id, { status: e })
      .then(({ data }) => {
        getVideoList(pagination);
      })
      .catch((error) => {
        if (error) {
          // message.error(error.message);
        }
      });
  };

  function findInValues(arr, value) {
    value = String(value).toLowerCase();
    return arr.filter((o) =>
      Object.entries(o).some((entry) =>
        String(entry[1]).toLowerCase().includes(value)
      )
    );
  }

  const handleDelete = (id) => {
    VideoAPI.deleteVideo(id, { id })
      .then(() => {
        getVideoList(pagination);
      })
      .catch((error) => {
        if (error) {
          // message.error(error.message);
        }
      });
  };

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(adminRoutes.EDITVIDEO.replace(":id", item));
        break;
      case "delete":
        handleDelete(item);

        break;
      default:
        break;
    }
  };

  const filterChangeHandler = (value) => {
    console.log("filter", value);
    if (value && value.search) {
      let datas = findInValues(videosData, value.search);
      setData(datas);
    } else {
      setData(videosData);
    }
    setPagination((p) => ({ ...p, ...value }));
  };

  const handleClick = () => {
    history.push(adminRoutes.ADDVIDEO);
  };

  return (
    <Container>
      <Heading>{configData?.[0].module?.[2]?.children?.[1]?.name}</Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          loading={loading}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <SecondaryFloatButton
              onClick={handleClick}
              buttonText="Add Video"
            />
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default ManageVideos;
