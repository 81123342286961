import styled from "styled-components";

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;

  button {
    margin-right: 14px;
  }
`;

export const FieldWrapper = styled.span`
  display: inline-block;

  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: ${({ textColor }) => (textColor ? textColor : "black")};
`;
