import { Tabs } from "antd";
import styled from "styled-components";
const { TabPane } = Tabs;

// export const StyledTab = styled.div`
//   display: inline-block;
//   width: 100%;

//   .ant-tabs {
//     overflow: unset;
//   }

//   .ant-tabs-top > .ant-tabs-nav {
//     margin-bottom: ${({ mb }) => mb || 95}px;
//     padding: 0 30px;
//   }
//   .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
//     display: none;
//   }

//   .ant-tabs-top > .ant-tabs-nav::before {
//     border-bottom: none;
//   }
//   .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
//     max-width: ${({ width }) => (width ? width : "276px")};
//     width: 100%;
//     height: 48px;
//     background: #ffffff;
//     box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
//     border-radius: 50px;
//     justify-content: center;

//     .ant-tabs-tab {
//       margin: 0px 20px;
//     }

//     @media (max-width: 767px) {
//       height: 36px;
//       max-width: 340px;
//       min-width: 264px;
//       .ant-tabs-tab {
//         margin: 0px 15px;
//       }
//     }

//     @media (max-width: 345px) {
//       .ant-tabs-tab {
//         margin: 0px 10px;
//       }
//     }
//   }

//   .ant-tabs-tab + .ant-tabs-tab {
//     // margin: 0 0 0 46px;
//     // margin: 0px 30px;
//   }

//   .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
//     overflow: unset;
//   }

// .ant-tabs-tab-btn {
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 28px;
//   color: #949494;
// }

// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 28px;
//   color: ${(props) => props.theme.colors.primary};
// }

//   .ant-tabs-ink-bar {
//     height: 2px;
//     background: ${(props) => props.theme.colors.primary} !important;
//     border-radius: 50px;
//   }

//   @media (min-width: 992px) and (max-width: 1199.98px) {
//     .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
//       max-width: 362px;
//     }
//     .ant-tabs-tab + .ant-tabs-tab {
//       margin: 0 0 0 24px;
//     }

//     .ant-tabs-tab-btn {
//       font-size: 14px;
//     }

//     .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//       font-size: 14px;
//     }
//   }

//   @media (max-width: 991.98px) {
//     .ant-tabs-top > .ant-tabs-nav {
//       display: flex;
//       // flex-flow: column;
//       // justify-content: center;
//       // align-items: flex-end;
//       /* margin-bottom: 80px; */
//       margin-bottom: ${({ mb }) => mb || 80}px;
//     }

//     .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
//       justify-content: space-evenly;
//     }

//     .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
//       // margin-bottom: 35px;

//       width: 100%;
//       // justify-content: center;
//     }

//     .ant-tabs-tab + .ant-tabs-tab {
//       // margin: 0 0 0 14px;
//     }
//   }

//   @media (max-width: 767px) {
//     .ant-tabs-tab + .ant-tabs-tab {
//       margin: 0;
//     }

//     .ant-tabs-tab-btn {
//       font-weight: normal;
//       font-size: 12px;
//     }

//     .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//       font-weight: normal;
//       font-size: 12px;
//     }

//     .ant-tabs-top > .ant-tabs-nav {
//       padding: 0 15px;
//     }
//   }
//   @media (max-width: 451px) {
//     .ant-tabs-top > .ant-tabs-nav {
//       flex-direction: column;
//       align-items: flex-start;
//     }
//   }
// `;

export const StyledTabWrapper = styled.div`
  display: inline-block;
  width: 100%;
  padding: ${({ padding }) => padding || "0 20px"};
  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }

  @media (max-width: 991px) {
    padding: 0 15px;
  }
`;

export const StyledTab = styled(Tabs)`
  max-width: ${({ width }) => (width ? width : "max-content")};
  overflow: unset;

  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-nav-wrap {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 50px;
    margin-right: 20px;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    // margin: 0px;
    margin: 0px 20px;
  }

  .ant-tabs-tab {
    // padding: 12px 20px;
    margin: 0px 20px;
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #949494;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-tabs-ink-bar {
    height: 2px;
    background: ${(props) => props.theme.colors.primary} !important;
    border-radius: 50px;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  @media (max-width: 575px) {
    .ant-tabs-nav-wrap {
      margin-right: 0px;
    }
  }
`;

const Tab = ({ padding, tabPanes, mb, width = "276px", ...props }) => {
  return (
    <>
      {/* <StyledTab mb={mb} width={width}>
      <Tabs {...props} tabPosition={"top"} >
        {tabPanes.map((ks) => (
          <TabPane tab={ks.type} key={ks._id}></TabPane>
        ))}
      </Tabs>
    </StyledTab> */}

      <StyledTabWrapper padding={padding}>
        {/* <StyledTab {...props} tabPosition={"top"} style={{ height: 150 }}> */}
        <StyledTab {...props} tabPosition={"top"}>
          {tabPanes?.map((i) => (
            <TabPane tab={i.type} key={i && i._id ? i._id : i.type} />
          ))}
        </StyledTab>
      </StyledTabWrapper>
    </>
  );
};

export default Tab;
