import { httpAPI } from "api";

const ApiConnectsAPI = {
  addApiConnects(params) {
    return httpAPI(`/api-connects`, params, "post");
  },

  getApiConnectsList(query) {
    return httpAPI(`/api-connects?${query || ""}`, "", "get");
  },
  getApiConnectsListDetail(id = "", query = "") {
    return httpAPI(`/api-connects/${id}?${query}`, "", "get");
  },
   
  updateApiConnects(id, params) {
    return httpAPI(`/api-connects/${id}`, params, "patch");
  },
  deleteApiConnects(id, params) {
    return httpAPI(`/api-connects/${id}`, params, "delete");
  },
};

export default ApiConnectsAPI;
