import { httpAPI } from "api";

const GrowCommentAPI = {
  addGrowComment(params) {
    return httpAPI(`/grow-comment`, params, "post");
  },

  getGrowCommentList(query) {
    return httpAPI(`/grow-comment?${query || ""}`, "", "get");
  },
  getGrowCommentListDetail(id) {
    return httpAPI(`/grow-comment/${id}`, "", "get");
  },
  updateGrowComment(id, params) {
    return httpAPI(`/grow-comment/${id}`, params, "patch");
  },
  deleteGrowComment(id, params) {
    return httpAPI(`/grow-comment/${id}`, params, "delete");
  },
};

export default GrowCommentAPI;
