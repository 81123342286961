import styled from "styled-components";

export const ResetDailyText = styled.span`
  font-size: 14px;
  color: #949494;
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 822px;
  gap: 20px;

  @media (max-width: 575px) {
    flex-wrap: wrap;
    flex-flow: column;
  }
`;
