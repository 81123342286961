import React from "react";

const connect = ({
  size = "24",
  bgColor = "#ffffff",
  color = "#666666",
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 24 24`}
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path d="M12 7.05566C10.0616 7.05566 8.48438 5.47846 8.48438 3.54004C8.48438 1.60162 10.0616 0.0244141 12 0.0244141C13.9384 0.0244141 15.5156 1.60162 15.5156 3.54004C15.5156 5.47846 13.9384 7.05566 12 7.05566Z" />
        <path d="M0.469984 20.1095C-0.498875 18.4307 0.078578 16.2767 1.75745 15.3071C3.47078 14.3188 5.60509 14.9412 6.55984 16.5939C7.53119 18.2753 6.95547 20.4244 5.27308 21.3963C3.58745 22.3683 1.44072 21.7899 0.469984 20.1095Z" />
        <path d="M18.7269 21.3963C17.0457 20.425 16.4683 18.2763 17.4402 16.5939C18.3949 14.9413 20.5291 14.3187 22.2426 15.3072C23.9214 16.2767 24.4989 18.4307 23.53 20.1095C22.5605 21.7878 20.415 22.3698 18.7269 21.3963Z" />
        <path d="M2.87309 12.9184L1.4696 12.8415C1.65223 9.5154 3.42931 6.43646 6.22255 4.60449L6.99434 5.78002C4.57254 7.36829 3.03237 10.0373 2.87309 12.9184Z" />
        <path d="M21.1269 12.9184C20.9676 10.0372 19.4274 7.36824 17.0056 5.78002L17.7774 4.60449C20.5707 6.43646 22.3477 9.5154 22.5303 12.8415L21.1269 12.9184Z" />
        <path d="M12.0001 23.9765C10.3274 23.9765 8.7282 23.5961 7.24573 22.847L7.88018 21.5918C10.4482 22.8909 13.5519 22.8909 16.1199 21.5918L16.7544 22.847C15.2719 23.5961 13.6728 23.9765 12.0001 23.9765Z" />
    </svg>
  );
};

export default connect;
