import React, { useEffect, useState } from "react";
import { Image } from "antd";
import {
  LogoWrapper,
  MenuIconWrap,
  MenuItem,
  Navigation,
  SidebarContainer,
  SidebarMenu,
  SubMenu,
  SideMenuGroup,
  Spacer,
  MenuDivider,
  TextWithBadgeWrapper,
  StyledBadge,
} from "./TheLayout.styles";
import { adminRoutes, settingsRoutes } from "../../helpers/adminRoutes";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import {
  ConnectSvg,
  DashboardSvg,
  FocusSvg,
  GrowSvg,
  SettingSvg,
} from "../../assets/svgs";
import { useSelector, useDispatch } from "react-redux";
import {
  getDynamicMenuString,
  nFormatter,
  slugify,
  _get,
  _set,
} from "utils/fn";
import { teamStatus, teamVisiblity } from "utils/constants";
import AddTeamModal from "Components/Team/AddTeam/AddTeam";
import AddTeamMembers from "Components/Team/AddTeam/AddTeamMembers";
import { useProgressiveImage } from "utils/hooks/useProgressiveImage";
import { BadgeCount } from "ui/Badge/Badge";
import PortalAPI from "api/http/portal";
import UserAPI from "api/http/user";

const Sidebar = () => {
  const dispatch = useDispatch();

  const { configData } = useSelector((state) => state.configData);
  const [openKeys, setOpenKeys] = useState([]);
  const [activeKey, setActiveKey] = useState("");
  const [newMenu, setNewMenu] = useState([]);
  const location = useLocation();
  const { user, homePageBackground } = useSelector((state) => state.user);

  const menu = [
    {
      name: "",
      key: "dashboard",
      component: MenuItem,
      icon: DashboardSvg,
      to: adminRoutes.DASHBOARD,
    },
    {
      name: "",
      key: "connect",
      component: SubMenu,
      icon: ConnectSvg,
      children: [
        {
          name: "Broadcasts",
          key: "sources",
          component: MenuItem,
          to: adminRoutes.SOURCE,
        },
        {
          name: "Relationships",
          key: "relationships",
          component: MenuItem,
          to: adminRoutes.RELATIONSHIP,
        },
        {
          name: "Communities",
          key: "team",
          component: SubMenu,
          children: [
            {
              name: "Find A Community",
              key: "find-a-team",
              component: MenuItem,
              to: adminRoutes.TEAM,
            },
          ],
        },
      ],
    },
    {
      name: "",
      key: "grow",
      component: SubMenu,
      icon: GrowSvg,
      children: [
        {
          name: "Watch Videos",
          key: "watch-videos",
          component: MenuItem,
          to: adminRoutes.WATCH_VIDEO,
        },
        {
          name: "Manage Videos",
          key: "manage-videos",
          component: MenuItem,
          to: adminRoutes.MANAGEVIDEO,
        },
        {
          name: "Go Live",
          key: "go-live",
          component: MenuItem,
          to: adminRoutes.DASHBOARD,
        },
      ],
    },
    {
      name: "",
      key: "focus",
      component: SubMenu,
      icon: FocusSvg,
      children: [
        {
          name: "My Events",
          key: "my-event",
          component: MenuItem,
          to: adminRoutes.MYEVENTS,
        },
        {
          name: "Find Events",
          key: "find-an-event",
          component: MenuItem,
          to: adminRoutes.FINDEVENTS,
        },
      ],
    },
  ];

  const adminMenu = [
    {
      name: "Admin",
      key: "admin",
      component: SubMenu,
      icon: SettingSvg,
      children: [
        {
          name: "General",
          key: "general-settings",
          component: SubMenu,
          children: [
            {
              name: "Portal",
              key: "portal",
              component: MenuItem,
              to: settingsRoutes.PORTAL,
            },
            {
              name: "Users",
              key: "user",
              component: MenuItem,
              to: settingsRoutes.USER,
            },
            {
              name: "API",
              key: "api",
              component: MenuItem,
              to: settingsRoutes.API,
            },
          ],
        },
        {
          name: "Modules",
          key: "module-settings",
          component: SubMenu,
          children: [
            {
              name: "Broadcasts",
              key: "sources",
              component: MenuItem,
              to: settingsRoutes.SOURCE,
            },
            {
              name: "Relationships",
              key: "relationships",
              component: MenuItem,
              to: settingsRoutes.RELATIONSHIP,
            },
            {
              name: "Grow",
              key: "grow",
              component: MenuItem,
              to: settingsRoutes.GROW,
            },
            {
              name: "Events",
              key: "settings-events",
              component: MenuItem,
              to: settingsRoutes.EVENT,
            },
            {
              name: "Type",
              key: "settings-type",
              component: MenuItem,
              to: settingsRoutes.TYPE,
            },
            {
              name: "Communities",
              key: "team",
              component: MenuItem,
              to: settingsRoutes.TEAM,
            },
          ],
        },
        {
          name: "Incentives",
          key: "incentive-settings",
          component: SubMenu,
          children: [
            {
              name: "Points",
              key: "settings-points",
              component: MenuItem,
              to: settingsRoutes.POINT,
            },
            {
              name: "Rewards",
              key: "settings-Rewards",
              component: MenuItem,
              to: settingsRoutes.REWARD,
            },
            {
              name: "Streaks",
              key: "settings-streaks",
              component: MenuItem,
              to: settingsRoutes.STREAK,
            },
          ],
        },
        {
          name: "Communication",
          key: "notification-settings",
          component: MenuItem,
          to: adminRoutes.SETTING,
        },
      ],
    },
  ];

  const [topMenu, setTopMenu] = useState(menu);
  const [bottomMenu, setBottomMenu] = useState(adminMenu);
  const [userTeams, setUserTeams] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);

  const sidebarLogo = useProgressiveImage(homePageBackground);

  useEffect(() => {
    const findKey = (menu, parent = "dashboard") => {
      for (const item of menu) {
        if (item.children) {
          findKey(item.children, `${parent}.${item.key}`);
        } else {
          if (item.to === location.pathname) {
            let path = parent.split(".");
            setActiveKey(item.key);
            setOpenKeys(path.slice(1));
            break;
          }
        }
      }
      return [];
    };
    // findKey(menu.concat(adminMenu), menu[0].key);
    // eslint-disable-next-line no-undef
    findKey(topMenu.concat(adminMenu), topMenu[0].key);
    return () => {};
  }, [location.pathname]);

  useEffect(() => {
    setUserTeams(user?.teams || []);
  }, [user]);

  const sliderIcon = (key) => {
    switch (key) {
      case "dashboard":
        return DashboardSvg;
      case "connect":
        return ConnectSvg;
      case "grow":
        return GrowSvg;
      case "focus":
        return FocusSvg;
      default:
        return null;
    }
  };

  const getMenuLink = (key) => {
    switch (key) {
      case "dashboard":
        return { to: adminRoutes.DASHBOARD };
      case "sources":
        return { to: adminRoutes.SOURCE };
      case "relationships":
        return { to: adminRoutes.RELATIONSHIP };
      case "find-a-team":
        return { to: adminRoutes.TEAM };
      case "create-a-team":
        return { onClick: () => setModalState(true) };
      case "watch-videos":
        return { to: adminRoutes.WATCH_VIDEO };
      case "manage-videos":
        return { to: adminRoutes.MANAGEVIDEO };
      case "go-live":
        return { to: adminRoutes.DASHBOARD };
      case "my-event":
        return { to: adminRoutes.MYEVENTS };
      case "find-an-event":
        return { to: adminRoutes.FINDEVENTS };
      default:
        return null;
    }
  };

  const getComponentForMenu = (key) => {
    if (key === "menu") {
      return MenuItem;
    } else if (key === "submenu") {
      return SubMenu;
    } else if (key === "devider") {
      return MenuDivider;
    } else {
      return null;
    }
  };

  useEffect(() => {
    let dynamicAdminMenu = adminMenu;
    console.log("configData?.[0]", configData?.[0]);
    let moduleMenu = dynamicAdminMenu?.[0].children?.[1]?.children;
    moduleMenu &&
      moduleMenu.forEach((data, idx) => {
        data?.key === "sources" ||
        data?.key === "relationships" ||
        data?.key === "team"
          ? (moduleMenu[idx].name = getDynamicMenuString(configData?.[0], [
              data?.key === "sources" ||
              data?.key === "relationships" ||
              data?.key === "team"
                ? "connect"
                : "",

              data?.key,
            ]))
          : data?.key === "grow"
          ? (moduleMenu[idx].name = getDynamicMenuString(configData?.[0], [
              data?.key,
            ]))
          : (moduleMenu[idx].name = data?.name);
        setBottomMenu(dynamicAdminMenu);
      });
  }, [configData]);

  useEffect(() => {
    const createMenu = ({ children, key, name, to }) => {
      let menuLink = getMenuLink(key);
      return {
        name: name,
        key: `${key}`,
        component: getComponentForMenu(
          key === "team-devider"
            ? "devider"
            : children && children.length
            ? "submenu"
            : "menu"
        ),
        icon: sliderIcon(key),
        ...(to ? { to } : {}),
        ...(menuLink ? { ...menuLink } : {}),
        ...(children && children.length
          ? { children: children.map((child) => createMenu(child)) }
          : {}),
      };
    };

    const NameWithBadge = ({ team }) => {
      if (
        team?.visibility === teamVisiblity.private &&
        team?.admins.some(function (el) {
          return el._id === user?._id;
        })
      ) {
        return (
          <TextWithBadgeWrapper>
            <span className="nameeee">
              {team?.name}

              <StyledBadge>
                <BadgeCount
                  count={nFormatter(team?.memberCount || 0)}
                  size={"small"}
                />
              </StyledBadge>
            </span>
          </TextWithBadgeWrapper>
        );
      } else return team?.name;
    };

    const mergeTeamMenu = (mainMenu, teams) => {
      if (mainMenu.key === "connect") {
        const connectMenu = { ...mainMenu };
        const data = _get({ ...mainMenu }, "children", []).find(
          (m) => m.key === "team"
        );
        if (!data) {
          return mainMenu;
        }

        const mergeTeamData =
          // ...data,
          teams && teams.length
            ? teams
                .filter(
                  (t) => t?.status === teamStatus.joined && t?.team !== null
                )
                .sort(function (a, b) {
                  if (a.team.name.toLowerCase() < b.team.name.toLowerCase()) {
                    return -1;
                  }
                  if (a.team.name.toLowerCase() > b.team.name.toLowerCase()) {
                    return 1;
                  }
                  return 0;
                })
                .map(({ team, status }, idx) => {
                  return {
                    name: <NameWithBadge team={team} />,
                    key: `${slugify(team?.name || "team_")}_${idx}`,
                    to: adminRoutes.TEAMDETAILS.replace(":name", team?._id),
                  };
                })
            : [];
        if (mergeTeamData.length) {
          const teamChildren = _get(data, "children", []);
          const teamLength = mergeTeamData.length;
          _set(mergeTeamData, `${teamLength}`, {
            name: "devider",
            key: "team-devider",
          });
          teamChildren.forEach((tc, idx) => {
            _set(mergeTeamData, `${teamLength + idx + 1}`, tc);
          });

          const modifiedConnectedMenu = {
            ...connectMenu,
            children: [
              ...connectMenu.children.map((connectChild) => {
                if (connectChild.key === "team") {
                  return {
                    ...connectChild,
                    children: mergeTeamData,
                  };
                } else {
                  return connectChild;
                }
              }),
            ],
          };
          return modifiedConnectedMenu;
        }
      }
      return mainMenu;
    };

    let rebuildMenu = [];

    if (configData && userTeams) {
      configData &&
        configData?.[0]?.module.forEach((data, idx) => {
          // _set(rebuildMenu, idx, createMenu(data));
          _set(rebuildMenu, idx, createMenu(mergeTeamMenu(data, userTeams)));
        });

      setTopMenu(() => {
        let oldMenu = [...rebuildMenu];
        return oldMenu;
      });
    }

    return () => {};
  }, [configData, userTeams]);

  function onOpenChange(keys) {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    const rootSubmenuKeys = topMenu.concat(bottomMenu).map((m) => m.key);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }

  const isOpen = openKeys.includes("admin");

  useEffect(() => {
    // console.log(">>>>>>>location.pathname", location.pathname);
    UserAPI.updateUser(user?._id, {
      defaultPage: location.pathname,
    })
      .then((data) => {
        // message.success("Information saved successfully");
      })
      .catch((err) => {
        console.log(err.message);
        // message.error(err.message);
      });
  }, [location.pathname]);

  const renderMenu = (menu, className = "") => {
    return menu.map(
      (
        { name, key, to, onClick, component: Item, icon: Icon, children },
        idx
      ) => {
        return (
          <Item
            key={key}
            className={className}
            icon={
              Icon && (
                <MenuIconWrap>
                  <Icon />
                </MenuIconWrap>
              )
            }
            title={children && name}
            level={idx + 1}
          >
            {children ? (
              renderMenu(children, `sub-children ${key}`)
            ) : to ? (
              <Link to={to}>{name}</Link>
            ) : onClick ? (
              <Link onClick={onClick}>{name}</Link>
            ) : (
              name
            )}
          </Item>
        );
      }
    );
  };
  const [teamId, setTeamId] = useState("");
  const teamIdData = (teamIdUser) => {
    setTeamId(teamIdUser);
  };

  return (
    <SidebarContainer className="left-panel">
      <LogoWrapper>
        {sidebarLogo && <Image src={sidebarLogo} alt="" preview={false} />}
      </LogoWrapper>
      <Navigation>
        <SideMenuGroup>
          <SidebarMenu
            mode="inline"
            openKeys={openKeys}
            selectedKeys={activeKey}
            onOpenChange={onOpenChange}
          >
            {renderMenu(topMenu)}
          </SidebarMenu>
        </SideMenuGroup>
        <Spacer flex={isOpen ? "0" : "auto"} />
        <SideMenuGroup>
          {(user?.permissions || []).includes("admin") && (
            <SidebarMenu
              mb="20px"
              mode="inline"
              openKeys={openKeys}
              selectedKeys={activeKey}
              onOpenChange={onOpenChange}
            >
              {renderMenu(bottomMenu)}
            </SidebarMenu>
          )}
        </SideMenuGroup>
      </Navigation>
      <AddTeamModal
        onCancel={(value) => setModalState(value)}
        modalState={modalState}
        from={adminRoutes.TEAM}
        onSubmit={() => setModalStates(true)}
        teamIdUser={teamIdData}
      />
      <AddTeamMembers
        onCancel={(value) => setModalStates(value)}
        modalState={modalStates}
        from={adminRoutes.TEAM}
        teamId={teamId}
      />
    </SidebarContainer>
  );
};

export default React.memo(Sidebar);
