import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, message, Image, Tooltip, Modal, Icon, Col } from "antd";
import {
  FaRegThumbsUp,
  FaThumbsUp,
  FaRegComments,
  FaComments,
  FaRegCommentAlt,
  FaCommentAlt,
} from "react-icons/fa";

import { GrClose } from "react-icons/gr";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

import { Document, Page, pdfjs } from "react-pdf";

// import { XmlSvg } from "../../assets/svgs";

import { FiPaperclip } from "react-icons/fi";
import { FcDocument } from "react-icons/fc";
import { VscJson } from "react-icons/vsc";
import { AiFillHtml5, AiFillFilePpt } from "react-icons/ai";
import { FileZipOutlined } from "@ant-design/icons";
import { BsDownload } from "react-icons/bs";
import {
  paginationObj,
  querystring,
  shortenDateFromNow,
  downloadFile,
} from "utils/fn";
import { useSelector } from "react-redux";
import TeamAPI from "api/http/team";
import ShowMoreMenu from "ui/Menu/ShowMore";
import { MdMoreVert } from "react-icons/md";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import {
  Player,
  BigPlayButton,
  ControlBar,
  ReplayControl,
  ForwardControl,
  VolumeMenuButton,
} from "video-react";
import {
  UserPostCard,
  PostInfoWrapper,
  UserImageWrapper,
  PostTextWrapper,
  PostImageWrapper,
  PostAttachmentWrapper,
  LinkCommentWrapper,
  LinkIconWrapper,
  CommentIconWrapper,
  CommentWrapper,
  CommentImageWrapper,
  CommentTextCardWrapper,
  CommentTextCard,
  CommentImageWrap,
  StyledCommentLike,
  CommentLikeWrapper,
  ViewMoreCommentWrapper,
  LinkTo,
  PostTextMobile,
  StyledPreviewLink,
  StyledFileViewer,
  StyledFileViewerOther,
  StyledPreviewModal,
  StyledImagePreview,
  StyledModelHeader,
  StyledModelWrapper,
  StyledZipWrapper,
  StylePdfWrapper,
  StyledPdfViewWrapper,
  StyledPdfControlerWrapper,
  StyledCol,
} from "./styled";
import { imagePath, nFormatter, _merge } from "utils/fn";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { UserAvatar } from "ui/Avatar";
import ImagePreview from "ui/ImagePreview/ImagePreview";
import { adminRoutes } from "helpers/adminRoutes";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { Spacer } from "ui/Layout/Spacer";
import EditPost from "./EditPost/EditPost";
import FbImageLibrary from "react-fb-image-grid";
import EditComment from "./EditComment/EditComment";
import CommentBar from "ui/PostCommentBar/CommentBar";
import useSocket from "utils/hooks/useSocket";
import config from "helpers/config";
import { Link } from "react-router-dom";
import { Container } from "ui/Layout/Container";
import Images from "./../../../FbCustomImageGallery/Images";
import ModalComponent from "./../../../FbCustomImageGallery/Modal";
import { StyledImageWrapper } from "Components/Grow/PlayListVideoSection.style";
import { StyledImages } from "Components/Grow/ManageVideos/style";
// import ReactJson from "react-json-view";
import FilePreviewerImage from "react-file-previewer";
import FileViewerOther from "react-file-viewer";
import { XmlSvg } from "assets/svgs/index";
import { Flex } from "ui/Layout/Flex";
import { SocketContext } from "utils/context/socket";
dayjs.extend(relativeTime);
const XMLIcon = (props) => {
  return <Icon size={10} component={XmlSvg} {...props} />;
};
export const UserPost = ({
  _id,
  user: { firstName = "", lastName = "", avatar = {}, _id: authorId },
  image,
  attachment,
  isDownloadable,
  createdAt,
  text,
  likers = [],
  isLiked = false,
  comments = [],
  totalComments = 0,
  actions = console.log,
  onCancel = console.log,
  onSubmit = console.log,
  teamOfId,
  adminUsers,
  postUserId,
  onFormChange = console.log,
}) => {
  // image comment

  const [postData, setPostData] = useState("");
  const [open, setOpen] = useState(false);
  const [updateComment, setUpdateComment] = useState(false);
  const [commentData, setCommentData] = useState("");
  const [isFormChange, setIsFormChange] = React.useState(false);
  const [previewAttachment, setPreviewAttachment] = React.useState(null);
  const [preview, setPreview] = React.useState(false);
  const [stateDownload, setStateDownload] = React.useState(false);
  const [stateImages, setstateImages] = React.useState([]);

  const { user: loggedInUser } = useSelector((state) => state.user);

  const commentRef = useRef();

  const datas = React.useMemo(() => comments, [comments]);
  const [data, setData] = useState(datas);
  const [dataChange, setDataChange] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3,
    total: 0,
    filter: {
      post: _id,
    },
    populate: [
      {
        path: "user",
        select: ["firstName", "lastName", "avatar"],
      },
      {
        path: "post",
        select: ["team"],
        populate: [
          {
            path: "team",
            select: ["admins"],
          },
        ],
      },
      {
        path: "isLiked",
        match: { likers: loggedInUser?._id || localStorage.getItem("userId") },
      },
    ],
    sorter: {
      order: "descend",
      field: "_id",
    },
  });

  const socket = useContext(SocketContext);
  // Add comment

  const getCommentList = useCallback(
    (id, pagination, isLoadMore = true, idx = null) => {
      TeamAPI.getCommentList(
        id,
        querystring(
          paginationObj({
            ...pagination,
            current: idx
              ? Math.ceil(+pagination.current / +pagination.pageSize)
              : pagination.current,
          })
        )
      )
        .then(({ data }) => {
          setData(
            (d) =>
              pagination.current === 1
                ? data?.data || []
                : _merge(d, data?.data || [], "_id")
            // [...new Set([...d, ...data?.data])]
          );

          const current = idx
            ? Math.ceil(+pagination.current / +pagination.pageSize) + 1
            : +pagination.current + 1;
          setPagination((p) => ({
            ...p,
            ...(isLoadMore ? { current } : { pageSize: 10 }),
            total: data?.total,
          }));
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    },
    []
  );

  useEffect(() => {
    getCommentList(_id, { ...pagination, pageSize: 3 }, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCommentList]);

  const mergeComments = useCallback(
    (comment, isCreate = false, isDelete = false) => {
      setData((dt) => {
        const idx = dt.findIndex((i) => i._id === comment._id);
        const d = [...dt];
        if (isDelete) {
          return d.filter((dd) => dd._id !== comment._id);
        }
        if (idx > -1) {
          d[idx]["comment"] = comment?.comment;
          d[idx]["image"] = comment?.image;
          d[idx]["likers"] = comment?.likers;
          d[idx]["isLiked"] = (comment?.likers || []).includes(
            loggedInUser?._id
          )
            ? 1
            : 0;
        } else {
          if (isCreate) {
            if (_id === comment?.post?._id) d.unshift(comment);
          }
        }
        return d;
      });
    },
    [loggedInUser]
  );

  useEffect(() => {
    if (socket && socket?.disconnected) {
      socket.connect();
    }
    if (socket) {
      socket.emit("join", _id);
      socket.on("comment-updated", ({ action, data }) => {
        switch (action) {
          case "patch":
          case "update":
            mergeComments(data);
            actions({ event: "refreshPost", props: { _id } }, () => {});
            break;
          case "create":
            mergeComments(data, true);
            actions({ event: "refreshPost", props: { _id } }, () => {});
            break;
          case "remove":
            mergeComments(data, false, true);
            actions({ event: "refreshPost", props: { _id } }, () => {});
            break;

          default:
            actions({ event: "refreshPost", props: { _id } }, () => {});
            break;
        }
      });
      socket.on("reconnect", () => {
        socket.emit("join", _id);
      });
      return () => {
        socket.emit("leave", _id);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const createComment = (postId, payload) => {
    TeamAPI.createComment(postId, payload)
      .then(({ data: comment }) => {
        setDataChange(false);

        // setData((d) => [...new Set([{ ...comment, user: loggedInUser }])]);
        // actions({ event: "refreshPost", props: { _id } }, () => {
        // getCommentList(_id, { ...pagination, pageSize: 3 }, false);
        // });
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  // const removeFromData = (id) => {
  //   const uData = [...data];
  //   const updatedData = uData.filter((d) => d._id !== id);
  //   setData(updatedData);
  // };
  const actionHandler = (postId, commentId, idx, { event }) => {
    switch (event) {
      case "delete":
        TeamAPI.deleteComment(postId, commentId, {
          id: commentId,
        })
          .then(() => {
            message.success("Comment deleted");
            // removeFromData(commentId);
            // actions({ event: "refreshPost", props: { _id } }, () => {
            //   getCommentList(_id, pagination, true, idx);
            // });
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
        break;
      case "edit":
        TeamAPI.getPostComment(postId._id, commentId)
          .then((data) => {
            setCommentData(data.data);
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
        setUpdateComment(true);
        break;
      default:
        break;
    }
  };
  const onSubmitForm = () => {
    onCancel(false);
    onSubmit();
    setUpdateComment(false);
  };
  // const { user } = useSelector((state) => state.user);

  const updatePostComment = (result, datas) => {
    const updatedCommentIdx = data.findIndex((d) => d._id === result);
    const uData = [...data];
    console.log({ updatedCommentIdx }, data, result, datas, comments);
    if (updatedCommentIdx > -1) {
      uData[updatedCommentIdx]["comment"] = datas.comment;
      uData[updatedCommentIdx]["image"] = datas.image;
      uData[updatedCommentIdx]["likers"] = datas.likers;

      setData(uData);
    }
  };
  const onSubmitUpdate = (
    _id,
    data,
    cb,
    keepOpen = false,
    showMessage = true
  ) => {
    TeamAPI.updateComment(commentData.post, commentData._id, data, keepOpen)
      .then((resp) => {
        if (showMessage) {
          message.success("Comment successfully updated");
        }
        //     // getPostList(team, pagination);
        if (typeof cb === "function") cb();
        if (!keepOpen) onSubmitForm();
        // updatePostComment(commentData._id, resp.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        if (typeof cb === "function") cb(error.message);
      });
  };

  const actionPostOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const actionPostOptionsAdmin = [
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const actionOptionsEdit = [
    {
      label: "Delete",
      value: "delete",
      isDanger: false,
    },
  ];
  const actionOptionsDelete = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: false,
    },
  ];

  const commentLikeActionHandler = (comment) => {
    actions(
      { event: "comment-like", props: { _id, comment } },
      (err, result) => {
        // const updatedCommentIdx = data.findIndex(
        //   (d) => d._id === result.comment
        // );
        // const uData = [...data];
        // uData[updatedCommentIdx]["likers"] = uData[updatedCommentIdx]["isLiked"]
        //   ? uData[updatedCommentIdx]["likers"].filter(
        //       (l) => l !== loggedInUser?._id
        //     )
        //   : [
        //       ...new Set([
        //         ...uData[updatedCommentIdx]["likers"],
        //         loggedInUser?._id,
        //       ]),
        //     ];
        // uData[updatedCommentIdx]["isLiked"] = uData[updatedCommentIdx][
        //   "isLiked"
        // ]
        //   ? 0
        //   : 1;
        // setData(uData);
      }
    );
  };

  const { width } = useWindowSize();
  const truncatedDateForMobile = useCallback(
    (str) => {
      if (width < 768) {
        return shortenDateFromNow(str);
      } else {
        return str;
      }
    },
    [width]
  );

  const getPostdetails = (postId, cb) => {
    TeamAPI.getPost(teamOfId, postId.item)
      .then((data) => {
        setPostData(data.data);
        if (typeof cb === "function") cb(null, data.data);
      })
      .catch((err) => {
        message.error(err.message);
        if (typeof cb === "function") cb(err);
      });
  };

  const updatePostAction = (
    { event, props },
    cb,
    isJson,
    showMessage = true
  ) => {
    switch (event) {
      case "edit":
        getPostdetails(props.val, (err, result) => {
          if (!err) {
            setOpen(true);
          }
        });
        break;
      default:
        actions({ event, props }, cb, isJson, showMessage);
        break;
    }
  };

  // const openPrompt = (message) => {
  //   var answer = window.confirm(message);
  //   if (answer == true) {
  //     console.log("inside yes");
  //     setOpen(false);
  //   } else {
  //   }
  // };

  useEffect(() => {
    if (image) {
      setstateImages(image.filter((i) => !i?.mimetype?.includes("mp4")));
    }
  }, [image]);

  return (
    <>
      {preview ? (
        (previewAttachment.mimetype &&
          previewAttachment.mimetype.includes("image/")) ||
        previewAttachment?.name.includes("rar") ||
        previewAttachment?.name.includes("zip") ||
        previewAttachment?.name.includes("json") ||
        previewAttachment?.name.includes("xls") ||
        previewAttachment?.name.includes("bz") ||
        previewAttachment?.name.includes("doc") ||
        previewAttachment?.name.includes("json") ||
        previewAttachment?.name.includes("xls") ||
        previewAttachment?.name.includes("ppt") ||
        previewAttachment?.name.includes("xml") ||
        previewAttachment?.name.includes("html") ||
        // previewAttachment?.name.includes("pdf") ||
        previewAttachment?.mimetype.includes("video") ||
        previewAttachment?.mimetype.includes("audio") ? (
          <>
            <StyledModelWrapper id="modalMount" class="modal-mount">
              {stateDownload ? (
                <StyledModelHeader>
                  <BsDownload
                    className="downloadsvg"
                    onClick={() =>
                      downloadFile(
                        imagePath(previewAttachment.path),
                        previewAttachment.mimetype,
                        previewAttachment.name
                      )
                    }
                  />

                  <GrClose onClick={() => setPreview(false)} />
                </StyledModelHeader>
              ) : (
                ""
              )}
            </StyledModelWrapper>

            <StyledPreviewModal
              visible={true}
              footer={false}
              onCancel={() => setPreview(false)}
              closable={false}
              getContainer={() => document.querySelector("#modal-container")}
              maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.85)" }}

              // getContainer={() => document.querySelector("#modal-container")}
              // getContainer="#modalMount"

              // className="modal_wrapper"
              // onClick={(e) => e.preventDefault()}
            >
              {/* <Fragment> */}

              {previewAttachment.mimetype &&
              previewAttachment.mimetype.includes("image/") ? (
                //    ||
                // previewAttachment.name.includes("pdf")

                <>
                  {/* <FilePreviewerImage
                      file={{
                        url: imagePath(previewAttachment.path),
                      }}
                    /> */}

                  <Image
                    src={imagePath(previewAttachment.path)}
                    alt="jdbb"
                    preview={false}
                  />
                </>
              ) : previewAttachment &&
                previewAttachment?.mimetype &&
                (previewAttachment?.name.includes("rar") ||
                  previewAttachment?.name.includes("zip") ||
                  previewAttachment?.name.includes("bz")) ? (
                <StyledZipWrapper>
                  <h4>{previewAttachment?.filename}</h4>
                  <FileZipOutlined />
                </StyledZipWrapper>
              ) : previewAttachment &&
                previewAttachment?.mimetype &&
                (previewAttachment?.name.includes("doc") ||
                  previewAttachment?.name.includes("json") ||
                  previewAttachment?.name.includes("xls") ||
                  previewAttachment?.name.includes("xml") ||
                  previewAttachment?.name.includes("ppt") ||
                  previewAttachment?.name.includes("html")) ? (
                <StyledZipWrapper>
                  {previewAttachment?.name.includes("doc") ? (
                    <FcDocument
                      style={
                        {
                          // marginRight: "380px",
                          // position: "absolute",
                          // marginBottom: "35px",
                        }
                      }
                    />
                  ) : previewAttachment?.name.includes("html") ? (
                    <AiFillHtml5
                      style={
                        {
                          // marginRight: "300px",
                          // position: "absolute",
                          // marginBottom: "35px",
                        }
                      }
                    />
                  ) : previewAttachment?.name.includes("ppt") ? (
                    <AiFillFilePpt
                      style={
                        {
                          // marginRight: "420px",
                          // position: "absolute",
                          // marginBottom: "35px",
                        }
                      }
                    />
                  ) : previewAttachment?.name.includes("json") ? (
                    <VscJson
                      style={
                        {
                          // marginRight: "420px",
                          // position: "absolute",
                          // marginBottom: "35px",
                        }
                      }
                    />
                  ) : previewAttachment?.name.includes("xml") ? (
                    <XMLIcon />
                  ) : (
                    ""
                  )}
                  <h4>{previewAttachment?.filename}</h4>
                </StyledZipWrapper>
              ) : previewAttachment &&
                previewAttachment?.mimetype &&
                previewAttachment?.mimetype.includes("audio") ? (
                <div className="audio_warpper">
                  <audio controls>
                    <source
                      src={`${imagePath(previewAttachment.path)}`}
                      type={previewAttachment?.mimetype}
                    />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              ) : (
                previewAttachment &&
                previewAttachment?.mimetype &&
                previewAttachment?.mimetype.includes("video") && (
                  <div
                  // className="stylePlayer"
                  // style={{ padding: "50px", border: "2px solid black" }}
                  >
                    {/* <div style={{ width: "500px", height: "500px" }}> */}
                    <Player
                      fluid={false}
                      // width={500}
                      // height={500}
                      src={`${imagePath(previewAttachment.path)}`}

                      // style={{ padding: "200px" }}
                    >
                      <BigPlayButton position="center" />
                    </Player>
                    {/* </div> */}
                  </div>
                )
              )}
            </StyledPreviewModal>
          </>
        ) : (
          <>
            <StyledModelWrapper id="modalMount" class="modal-mount">
              {stateDownload ? (
                <StyledModelHeader>
                  <BsDownload
                    className="downloadsvg"
                    onClick={() =>
                      downloadFile(
                        imagePath(previewAttachment.path),
                        previewAttachment.mimetype,
                        previewAttachment.name
                      )
                    }
                  />

                  <GrClose onClick={() => setPreview(false)} />
                </StyledModelHeader>
              ) : (
                ""
              )}
            </StyledModelWrapper>

            <StyledFileViewerOther
              visible={true}
              footer={false}
              onCancel={() => setPreview(false)}
              closable={false}
              maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.85)" }}
              getContainer={() => document.querySelector("#modal-container")}

              // width={1000}

              // getContainer="#hh"
            >
              {/* {stateDownload ? (
                <>
                  <BsDownload
                    className="downloadsvg"
                    onClick={() =>
                      downloadFile(
                        imagePath(previewAttachment.path),
                        previewAttachment.mimetype,
                        previewAttachment.name
                      )
                    }
                  />
                </>
              ) : (
                ""
              )} */}

              {previewAttachment.mimetype &&
              previewAttachment.name.includes("csv") ? (
                <>
                  <FileViewerOther
                    fileType={previewAttachment.name.split(".").pop()}
                    filePath={imagePath(previewAttachment.path)}
                  />
                </>
              ) : (
                ""
              )}

              {previewAttachment.mimetype &&
              previewAttachment.name.includes("pdf") ? (
                <StyledPdfViewWrapper>
                  {/* <FileViewerOther
                  fileType={previewAttachment.name.split(".").pop()}
                  filePath={imagePath(previewAttachment.path)}
                /> */}

                  <Document
                    file={imagePath(previewAttachment.path)}
                    options={{ workerSrc: "/pdf.worker.js" }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    renderMode={"svg"}
                  >
                    <Page pageNumber={pageNumber} />
                  </Document>

                  {numPages !== null && (
                    <StyledPdfControlerWrapper
                      pageNumber={pageNumber}
                      numPages={numPages}
                    >
                      <div className="bg_style">
                        <BsChevronLeft
                          onClick={previousPage}
                          className="left_page"
                        />

                        <p className="page_details">
                          Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                          {numPages || "--"}
                        </p>

                        <BsChevronRight
                          onClick={nextPage}
                          className="right_page"
                        />
                      </div>
                    </StyledPdfControlerWrapper>
                  )}

                  {/* {numPages !== null && (
                    <StyledPdfControlerWrapper
                      pageNumber={pageNumber}
                      numPages={numPages}
                    >
                      <button
                      type="button"
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                    >
                      Previous
                    </button>

                      <button
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </button>

                      <BsChevronLeft
                        onClick={previousPage}
                        fontSize="20px"
                        color="white"
                        className="left_page"
                      />

                      <p className="page_details">
                        Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                        {numPages || "--"}
                      </p>

                      <BsChevronRight
                        onClick={nextPage}
                        fontSize="20px"
                        color="white"
                        className="right_page"
                      />
                    </StyledPdfControlerWrapper>
                  )} */}
                </StyledPdfViewWrapper>
              ) : (
                ""
              )}
            </StyledFileViewerOther>
          </>
        )
      ) : (
        ""
      )}
      <UserPostCard padding="20px 30px 5px">
        <PostInfoWrapper>
          <UserImageWrapper>
            <Link
              to={
                postUserId == loggedInUser?._id
                  ? adminRoutes.PROFILE
                  : adminRoutes.PROFILE_VIEW.replace(":id", postUserId)
              }
            >
              <UserAvatar
                src={avatar?.path ? imagePath(avatar?.path) : ""}
                fullName={`${firstName} ${lastName}`}
                size={53}
              />
            </Link>
          </UserImageWrapper>
          <PostTextWrapper>
            <Link
              to={
                postUserId == loggedInUser?._id
                  ? adminRoutes.PROFILE
                  : adminRoutes.PROFILE_VIEW.replace(":id", postUserId)
              }
            >
              {" "}
              <h4>{`${firstName || ""} ${lastName || ""}`}</h4>
            </Link>
            <span>{dayjs(createdAt).fromNow()}</span>
            <p>
              {/* {text.replace(
                new RegExp(
                  "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+"
                ),
                ""
              )} */}
              <div
                dangerouslySetInnerHTML={{
                  __html: text
                    .replace(
                      new RegExp(
                        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+"
                      ),
                      ""
                    )
                    .replace(/(?:\r\n|\r|\n)/g, "<br>"),
                }}
              />
              <a
                href={text.match(/(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g)}
                target="_blank"
              >
                {" "}
                {text.match(/(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g)}
              </a>
            </p>
          </PostTextWrapper>
          <Spacer />
          {loggedInUser?._id === authorId ? (
            <ShowMoreMenu
              mw={50}
              Icon={MdMoreVert}
              options={actionPostOptions}
              item={_id}
              onChange={(val) => {
                updatePostAction({ event: val.key, props: { val, _id } });
              }}
            />
          ) : (
            adminUsers.includes(loggedInUser?._id) && (
              <ShowMoreMenu
                mw={50}
                Icon={MdMoreVert}
                options={actionPostOptionsAdmin}
                item={_id}
                onChange={(val) => {
                  updatePostAction({ event: val.key, props: { val, _id } });
                }}
              />
            )
          )}

          <EditPost
            onCancel={(value) => setOpen(value)}
            setModelState={(value) => setOpen(value)}
            PostData={postData}
            modalState={open}
            onSubmit={(data, cb, isJson, showMessage) => {
              updatePostAction(
                { event: "update", props: { post: data, _id } },
                cb,
                isJson,
                showMessage
              );
            }}
          />
        </PostInfoWrapper>
        <PostTextMobile>{text}</PostTextMobile>
        {text && text?.match(/(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g) ? (
          <StyledPreviewLink>
            <LinkPreview
              url={text.match(/(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g)}
              width="auto"
              height="auto"
              descriptionLength={1}
            />
          </StyledPreviewLink>
        ) : (
          ""
        )}
        <PostImageWrapper ho={(image || []).length === 1 ? "45" : "35"}>
          {(image || []).length ? (
            <>
              {stateImages &&
                image &&
                image.length > 0 &&
                // image.filter((i) => !i?.mimetype?.includes("mp4")) &&
                stateImages.length > 0 && (
                  <>
                    <Images
                      images={image
                        // .filter((i) => !i?.mimetype?.includes("mp4"))
                        .map((i) =>
                          //  imagePath(i.path)
                          {
                            if (i?.mimetype.split("/").includes("video")) {
                              return { ...i, url: imagePath(i.path) };
                            }
                            return imagePath(i.path);
                          }
                        )}
                      // images={image.map((img) => imagePath(img.path))}
                      previewImages={image
                        // .filter((i) => !i?.mimetype?.includes("mp4"))
                        .map((i) => {
                          if (i?.mimetype.split("/").includes("video")) {
                            return { ...i, url: imagePath(i.url) };
                          }
                          return imagePath(i.url);
                        })}
                    />
                  </>
                )}
              {/* {stateImages &&
                image
                  .filter((i) => i?.mimetype?.includes("mp4"))
                  .map((i) => (
                    <Player
                      fluid={false}
                      src={`${imagePath(i.url)}`}
                      poster={`${imagePath(i.url)}`}
                      style={{ paddingTop: "0px" }}
                    >
                      <BigPlayButton position="center" />
                    </Player>
                  ))} */}
            </>
          ) : (
            ""
          )}
        </PostImageWrapper>
        {attachment &&
          attachment.length > 0 &&
          attachment.map((attach, idx) => (
            <PostAttachmentWrapper
              align="middle"
              onClick={
                () => (
                  setPreview(true),
                  setPreviewAttachment(attach),
                  setStateDownload(isDownloadable[idx])
                )
                // <Fragment>
                //   <FileViewer
                //     fileType={attach.mimetype}
                //     filePath={imagePath(attach.path)}
                //   />
                // </Fragment>
              }
            >
              {/* <DocViewer
                // filename="image-1647935866096-1774f5d9010e8ccac4f82807b9070663.jpg"
                url="http://localhost:3030/uploads/posts/thumbs/image-1647935866096-1774f5d9010e8ccac4f82807b9070663.jpg"
              /> */}

              <StyledCol>
                <Tooltip
                  placement="topLeft"
                  title="Click here to download"
                  arrowPointAtCenter
                >
                  <FiPaperclip />
                </Tooltip>
              </StyledCol>

              <StyledCol>
                <Tooltip
                  placement="topRight"
                  title="Click here to download"
                  arrowPointAtCenter
                >
                  <PostTextWrapper>
                    <p>{attach?.name}</p>
                  </PostTextWrapper>
                  <PostTextMobile mb={"0"}>{attach?.name}</PostTextMobile>
                </Tooltip>
              </StyledCol>
            </PostAttachmentWrapper>
          ))}
        <LinkCommentWrapper>
          <LinkIconWrapper>
            <Button
              // type="primary"
              shape="circle"
              onClick={() =>
                actions({ event: isLiked ? "-like" : "+like", props: { _id } })
              }
              icon={
                isLiked ? (
                  <FaThumbsUp className="like_icon" />
                ) : (
                  <FaRegThumbsUp className="like_icon" />
                )
              }
            />
            <span>{nFormatter(likers?.length)}</span>
          </LinkIconWrapper>

          <CommentIconWrapper>
            {totalComments > 0 ? (
              <Button
                // type="primary"
                shape="circle"
                icon={<FaCommentAlt size={16} className="comment_icon" />}
              />
            ) : (
              <Button
                // type="primary"
                shape="circle"
                icon={<FaRegCommentAlt size={16} className="comment_icon" />}
              />
            )}
            {/* {totalComments > 0 ? (
              <Button
                // type="primary"
                shape="circle"
                icon={<FaComments className="comment_icon" />}
              />
            ) : (
              <Button
                // type="primary"
                shape="circle"
                icon={<FaRegComments className="comment_icon" />}
              />
            )} */}
            <span>{nFormatter(totalComments)}</span>
          </CommentIconWrapper>
        </LinkCommentWrapper>

        <CommentBar
          mb={30}
          _id={_id}
          onSubmit={createComment}
          ref={commentRef}
          // data={data}
          // actions={actions}
          // actionHandler={actionHandler}
          // totalComments={totalComments}
          // updateComment={updateComment}
          // getCommentList={getCommentList}
          // pagination={pagination}
        />

        <EditComment
          _id={_id}
          modalState={updateComment}
          commentRef={commentRef}
          onCancel={(value) => setUpdateComment(value)}
          isEdit={true}
          commentData={commentData}
          actions={actions}
          getCommentList={getCommentList}
          onSubmitUpdate={onSubmitUpdate}
        />

        {(data || []).map((comment, idx) => (
          <CommentWrapper key={idx}>
            <CommentImageWrapper>
              <Link
                to={adminRoutes.PROFILE_VIEW.replace(":id", comment.user?._id)}
              >
                <UserAvatar
                  src={
                    comment?.user?.avatar?.path
                      ? imagePath(comment?.user?.avatar?.path)
                      : ""
                  }
                  fullName={`${comment.user?.firstName} ${comment?.user?.lastName}`}
                  size={42}
                />
              </Link>
            </CommentImageWrapper>
            <CommentTextCardWrapper>
              <CommentTextCard
                title={
                  <Link
                    to={adminRoutes.PROFILE_VIEW.replace(
                      ":id",
                      comment.user?._id
                    )}
                  >
                    {`${comment.user?.firstName} ${comment?.user?.lastName}`}
                  </Link>
                }
              >
                <p>
                  {" "}
                  {comment?.comment?.replace(
                    new RegExp(
                      "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+"
                    ),
                    ""
                  )}{" "}
                  <a
                    href={comment?.comment?.match(
                      /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g
                    )}
                    target="_blank"
                  >
                    {" "}
                    {comment?.comment?.match(
                      /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g
                    )}
                    {comment?.comment &&
                    comment?.comment?.match(
                      /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g
                    ) ? (
                      <StyledPreviewLink>
                        <div style={{ marginTop: "20px" }}>
                          <LinkPreview
                            key={comment?.comment?.match(
                              /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g
                            )}
                            url={comment?.comment?.match(
                              /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g
                            )}
                            // width="200px"
                            height="200px"
                            showLoader={false}
                            openInNewTab={true}
                            descriptionLength={10}
                          />
                        </div>
                      </StyledPreviewLink>
                    ) : (
                      ""
                    )}
                  </a>
                </p>
                {/* <p>{comment?.comment}</p> */}
                {/* {comment?.image ? (
                  <ImagePreview
                    preview={false}
                    src={
                      comment?.image?.path
                        ? imagePath(comment?.image?.path)
                        : ""
                    }
                    height={200}
                    width={200}
                  />
                ) : (
                  ""
                )} */}
              </CommentTextCard>
              <CommentImageWrap>
                {comment?.image ? (
                  <>
                    <Images
                      className="classes-gallary"
                      images={[
                        comment?.image?.path
                          ? imagePath(comment?.image?.path)
                          : "",
                      ]}
                      previewImages={[
                        comment?.image?.path
                          ? imagePath(comment?.image?.url)
                          : "",
                      ]}
                      // countForm={3}
                      // renderOverlay={() => "Preview"}
                      // overlayBackgroundColor={"#0005"}
                    />
                    {/* <Image
                    preview={true}
                    src={
                      comment?.image?.path
                        ? imagePath(comment?.image?.path)
                        : ""
                    }
                    height={200}
                    width={200}
                    /> */}
                  </>
                ) : (
                  ""
                )}
              </CommentImageWrap>
              <StyledCommentLike>
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => commentLikeActionHandler(comment)}
                  icon={
                    comment?.isLiked ? (
                      <FaThumbsUp className="comment_like_icon" />
                    ) : (
                      <FaRegThumbsUp className="comment_like_icon" />
                    )
                  }
                />
                <span>{comment?.likers?.length || 0}</span>
              </StyledCommentLike>
              <CommentLikeWrapper>
                <span
                  className={comment?.isLiked ? "like_text" : "unlike_text"}
                  onClick={() => commentLikeActionHandler(comment)}
                >
                  Like
                </span>
                <span
                  className="reply_text"
                  onClick={() => commentRef.current.focus()}
                >
                  Reply
                </span>
                <span className="comment_at">
                  {truncatedDateForMobile(dayjs(comment?.createdAt).fromNow())}
                </span>
              </CommentLikeWrapper>
            </CommentTextCardWrapper>
            {comment.user._id === loggedInUser?._id ? (
              <ShowMoreMenu
                mw={50}
                Icon={MdMoreVert}
                options={actionOptionsDelete}
                item={comment}
                onChange={(val) => {
                  actionHandler(comment.post, comment._id, idx, {
                    event: val.key,
                    val: val,
                  });
                }}
              />
            ) : comment.post.team.admins.includes(loggedInUser?._id) ? (
              <ShowMoreMenu
                mw={50}
                Icon={MdMoreVert}
                options={actionOptionsEdit}
                item={comment}
                onChange={(val) => {
                  actionHandler(comment.post, comment._id, idx, {
                    event: val.key,
                    val: val,
                  });
                }}
              />
            ) : (
              ""
            )}
          </CommentWrapper>
        ))}

        {totalComments > data.length && (
          <ViewMoreCommentWrapper>
            <Button type="link" onClick={() => getCommentList(_id, pagination)}>
              View {totalComments - (data.length || 0)} More Comments
            </Button>
          </ViewMoreCommentWrapper>
        )}
      </UserPostCard>
    </>
  );
};
