export const tabs = [
  {
    type: "Upcoming",
    key: "Upcoming",
  },
  {
    type: "I'm Going",
    key: "I'm Going",
  },
  {
    type: "Interested",
    key: "Interested",
  },
  {
    type: "Next Time",
    key: "Next Time",
  },
];

export const data = [
  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    phoneNumber: "6453243232",
    timer: "00:04:10",
    address: "Baltimore,MD",
    time: 10,
    claimed: false,
    connected: false,
    type: "I'm Going",
    nameVisible: false,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: true,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },
  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    timer: "00:05:10",
    phoneNumber: "6453243232",
    address: "Baltimore,MD",
    time: 5,
    claimed: true,
    type: "Touched",
    connected: true,
    nameVisible: false,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: false,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },

  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    phoneNumber: "6453243232",
    timer: "00:05:10",
    address: "Baltimore,MD",
    time: 5,
    claimed: false,
    connected: false,
    type: "I'm Going",
    nameVisible: false,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: false,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },
  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    phoneNumber: "6453243232",
    timer: "00:05:10",
    address: "Baltimore,MD",
    time: 5,
    claimed: false,
    connected: false,
    type: "I'm Going",
    nameVisible: false,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: false,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },
  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    phoneNumber: "6453243232",
    timer: "00:05:10",
    address: "Baltimore,MD",
    time: 5,
    claimed: false,
    connected: false,
    type: "I'm Going",
    nameVisible: false,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: false,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },
  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    phoneNumber: "6453243232",
    timer: "00:05:10",
    address: "Baltimore,MD",
    time: 5,
    claimed: true,
    connected: true,
    type: "Next Time",
    nameVisible: false,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: false,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },
  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    phoneNumber: "6453243232",
    timer: "00:05:10",
    address: "Baltimore,MD",
    time: 5,
    claimed: true,
    connected: true,
    type: "Interested",
    nameVisible: false,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: false,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },
  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    phoneNumber: "6453243232",
    timer: "00:05:10",
    address: "Baltimore,MD",
    time: 5,
    claimed: false,
    connected: false,
    type: "I'm Going",
    nameVisible: false,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: false,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },
  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    phoneNumber: "6453243232",
    address: "Baltimore,MD",
    time: 20,
    timer: "00:05:10",
    claimed: true,
    type: "I'm Going",
    connected: false,
    nameVisible: true,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: false,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },
  {
    name: "YL Rockstars",
    email: "abc@gmail.com",
    phoneNumber: "6453243232",
    address: "Baltimore,MD",
    time: 20,
    timer: "00:05:10",
    claimed: true,
    type: "I'm Going",
    connected: false,
    nameVisible: true,
    emailVisible: false,
    phoneNumberVisible: false,
    addressVisible: false,
    source: "Website",
    activity: [
      {
        name: "Jesica",
        description: "called",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Moved to Interested",
        time: "3:30 pm",
        date: "2-2-21",
      },
      {
        name: "Jesica",
        description: "Emailed",
        time: "3:30 pm",
        date: "2-2-21",
      },
    ],
  },
];
