import React, { useState, useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import { adminRoutes, authRoutes } from "../helpers/adminRoutes";
import styled from "styled-components";
import EmailAPI from "api/http/email";
import { useParams, useLocation } from "react-router";
import { paginationObj, querystring } from "utils/fn";
import { useDispatch, useSelector } from "react-redux";
import { login, portal } from "store/reducers/user.reducer";
import UserAPI from "api/http/user";

const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }
  width: 100%;
  height: 48px;
  background: ${(props) => props.theme.colors.secondary};
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: white;
`;
const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 474px;
  max-width: 100%;
`;

const StyledForms = styled.div`
  padding-top: 32px;

  .ant-form-item {
    margin-bottom: 14px;
  }

  .ant-input-affix-wrapper {
    min-height: 48px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .ant-input {
    background-color: transparent;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }
  @media screen and (max-width: 959px) {
    padding-top: 0px;
  }
`;
const StyledFormsLabel = styled.div`
  width: max-content;
  line-height: 34px;
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 14px;
  border-bottom: 1px solid #000;
  @media screen and (max-width: 959px) {
    display: none;
  }
`;

const StyledFormItem = styled.div`
  .ant-form-item {
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 470px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
`;

const StyledLink = styled.div`
  font-size: 14px;
  color: #747487;
  margin-left: 4px;
  font-weight: 600;
`;

const StyledForgotPassword = styled.ul`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
`;

const ResetPassword = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [pagination, setPagination] = useState({
    filter: { name: { $ne: undefined } },
    sorter: {},
  });

  const onFinish = (values) => {
    values.token = params.token;

    if (location.pathname.split("/")[2] === "create_password") {
      localStorage.removeItem("token");
      localStorage.setItem("token", window.atob(values.token));
      EmailAPI.getEmail().then(({ data }) => {
        EmailAPI.resetPassword(data?.data[0]._id, { token: params.token })
          .then((datas) => {
            UserAPI.updateUser(datas.data, {
              password: values.password,
              emailVerified: true,
            });
            localStorage.setItem("userId", datas.data);
            history.push(adminRoutes.DASHBOARD);
          })
          .catch((error) => {
            if (error) {
              message.error(error.message);
            }
          });
      });
    } else {
      EmailAPI.getEmail().then(({ data }) => {
        EmailAPI.resetPassword(data?.data[0]._id, values)
          .then(({ data }) => {
            message.success("Password successfully reset");
            history.push("/");
          })
          .catch((error) => {
            if (error) {
              message.error(error.message);
            }
          });
      });
    }
  };

  return (
    <StyledForm>
      <StyledFormsLabel>
        {location.pathname.split("/")[2] === "create_password"
          ? "Create Password"
          : "Forgot Password"}
      </StyledFormsLabel>
      <StyledForms>
        <Form
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          requiredMark={"optional"}
        >
          <StyledFormItem>
            <Form.Item
              name="password"
              label="Password"
              className="password-w-icon"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
                // {
                //   min: 8,
                //   message: "password should contain minimum 8 characters",
                // },
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={
                  <img
                    src="/assets/images/password-icon.png"
                    alt=""
                    className="user-icon"
                  />
                }
              />
            </Form.Item>
          </StyledFormItem>
          <StyledFormItem>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "This is required",
                  message: "Please confirm your password!",
                },
                // ({ getFieldValue }) => ({
                //   validator(_, value) {
                //     if (!value || getFieldValue("password") === value) {
                //       return Promise.resolve();
                //     }

                //     return Promise.reject(
                //       new Error(
                //         "The two passwords that you entered do not match!"
                //       )
                //     );
                //   },
                // }),
              ]}
            >
              <Input.Password
                placeholder="Password"
                prefix={
                  <img
                    src="/assets/images/password-icon.png"
                    alt=""
                    className="user-icon"
                  />
                }
              />
            </Form.Item>
          </StyledFormItem>

          <Form.Item>
            <StyledButton
              // btnColor=""
              htmlType="submit"
            >
              {location.pathname.split("/")[2] === "create_password"
                ? "Set new password"
                : "Reset Password"}
            </StyledButton>
          </Form.Item>
        </Form>
      </StyledForms>
    </StyledForm>
  );
};

export default ResetPassword;
