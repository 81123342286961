import styled from "styled-components";

export const StyledLink = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #747487;
  :hover {
    color: ${(props) => props.theme.colors.primary};
    text-decoration-line: underline;
  }
`;
