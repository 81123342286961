import React, { useState } from "react";
import { SecondaryButton } from "ui/Button";
import { Card } from "ui/Card";
import { Datatable } from "ui/Datatable";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { users } from "./dummy.data";
import Actions from "ui/Datatable/Actions";
import { ToggleSwitch } from "ui/Form";
import { useHistory } from "react-router";
import { settingsRoutes } from "helpers/adminRoutes";
import { SecondaryFloatButton } from "ui/Button/index";

const Reward = () => {
  const [data, setData] = useState(users);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_) => {
        return <ToggleSwitch defaultChecked={_} onChange={console.log} />;
      },
      fixed: "left",
      width: 70,
    },

    {
      title: "Rewards",
      dataIndex: "rewardName",
      key: "rewardName",
      sorter: (a, b) => a.rewardName.localeCompare(b.rewardName),
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      sorter: (a, b) => a.points.localeCompare(b.points),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record.id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  function findInValues(arr, value) {
    value = String(value).toLowerCase();
    return arr.filter((o) =>
      Object.entries(o).some((entry) =>
        String(entry[1]).toLowerCase().includes(value)
      )
    );
  }

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(settingsRoutes.REWARD_EDIT.replace(":id", item));
        break;
      case "delete":
        break;

      default:
        break;
    }
  };

  const filterChangeHandler = (value) => {
    console.log("filter", value);
    if (value && value.search) {
      let datas = findInValues(users, value.search);
      setData(datas);
    } else {
      setData(users);
    }
    setPagination((p) => ({ ...p, ...value }));
  };

  const onhandleClick = () => {
    history.push(settingsRoutes.REWARD_ADD);
  };

  return (
    <Container>
      <Heading>Rewards</Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          loading={loading}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <SecondaryFloatButton
              onClick={onhandleClick}
              buttonText="Create Rewards"
            />
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default Reward;
