import styled from "styled-components";
import { Card } from "ui/Card";

export const StyledEditUserCard = styled(Card)`
  padding: 25px 30px 15px;
  position: relative;
`;

export const StyledUploadAvtarWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: -70px;

  @media (max-width: 575px) {
    justify-content: center;
    left: 0;
    right: 0;
  }
`;

export const StyledFormItemWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: ${(props) => (props.id ? "0px" : "65px")};
`;

export const StyledUploadAvtarTextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-left: 170px;
  margin-bottom: 30px;
  h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #232333;
  }
  span {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
  }

  @media (max-width: 575px) {
    margin-left: 0;
    margin-top: 60px;
  }
`;
