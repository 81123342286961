import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { httpAPI } from "api/index";
import { imagePath, querystring } from "utils/fn";
import { message } from "antd";
import { teamStatus } from "utils/constants";
import axios from "axios";

const initialState = {
  configData: null
};



export const configDatas = createAsyncThunk(
  "configDatas",
  async (data, thunkAPI) => {
    try {
      const configs = JSON.parse(localStorage.getItem("config"));

      return httpAPI(`/portals`, data, "GET").then(async (data) => {
        return axios.get(`${configs.antUrl}/client?_id=${data?.data?.data[0]?._id}`)
          .then((data) => {
            localStorage.setItem("version", data?.data.data[0]?.version)
            return data?.data.data;
          })

      })


    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const configSlice = createSlice({
  name: "configData",
  initialState,
  extraReducers: {
    [configDatas.fulfilled]: (state, { payload }) => {
      state.configData = payload;

    }

  }
});

export default configSlice.reducer;
