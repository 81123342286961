export const ADMIN_BASE_URL = "/";
export const authRoutes = {
  LOGIN: `${ADMIN_BASE_URL}auth/login`,
  REGISTRATION: `${ADMIN_BASE_URL}auth/register`,
  FORGOT_PASSWORD: `${ADMIN_BASE_URL}auth/forget_password`,
  LANDING: `${ADMIN_BASE_URL}landing`,
  RESET_PASSWORD: `${ADMIN_BASE_URL}auth/reset_password`,
  CREATE_PASSWORD: `${ADMIN_BASE_URL}auth/create_password`,
  EMAILVERIFY: `${ADMIN_BASE_URL}auth/emailVerify`,

};
export const landingRoutes = {
  LANDING: `${ADMIN_BASE_URL}landing/source/:id`,
};
export const adminRoutes = {
  DASHBOARD: `${ADMIN_BASE_URL}dashboard`,
  INVITE: `${ADMIN_BASE_URL}invite`,
  PERMISSION: `${ADMIN_BASE_URL}permission`,
  PROFILE: `${ADMIN_BASE_URL}profile`,
  PROFILE_VIEW: `${ADMIN_BASE_URL}profile/:id/view`,
  PROFILE_EDIT: `${ADMIN_BASE_URL}profile/:id`,
  SETTING: `${ADMIN_BASE_URL}communnication-setting`,
  MANAGEPASSWORD: `${ADMIN_BASE_URL}manage-password`,
  LINKCREATOR: `${ADMIN_BASE_URL}connect/broadcast/add`,
  SOURCE_EDIT: `${ADMIN_BASE_URL}connect/broadcast/:id`,
  SOURCE: `${ADMIN_BASE_URL}connect/broadcast`,
  RELATIONSHIP: `${ADMIN_BASE_URL}connect/relationship`,
  TEAM: `${ADMIN_BASE_URL}connect/team/find`,
  TEAMDETAILS: `${ADMIN_BASE_URL}connect/team/:name`,
  WATCH_VIDEO: `${ADMIN_BASE_URL}grow/watch`,
  PLAYLIST: `${ADMIN_BASE_URL}grow/watch/:id`,
  PLAYLISTPAGE: `${ADMIN_BASE_URL}grow/watch/video/:id`,
  MANAGEVIDEO: `${ADMIN_BASE_URL}grow/manage-video`,
  EDITVIDEO: `${ADMIN_BASE_URL}grow/video/:id`,
  USER: `${ADMIN_BASE_URL}manage_user`,
  VIDEO: `${ADMIN_BASE_URL}videos`,
  VIDEODETAIL: `${ADMIN_BASE_URL}video-detail`,
  GROW: `${ADMIN_BASE_URL}manage-grow`,
  ADDVIDEO: `${ADMIN_BASE_URL}grow/add-video`,
  RESET_PASSWORD: `${ADMIN_BASE_URL}reset-password`,
  MYEVENTS: `${ADMIN_BASE_URL}focus/events`,
  FINDEVENTS: `${ADMIN_BASE_URL}focus/findEvents`,
};

export const settingsRoutes = {
  PORTAL: `${ADMIN_BASE_URL}admin/general/portal`,
  USER: `${ADMIN_BASE_URL}admin/general/user`,
  USER_ADD: `${ADMIN_BASE_URL}admin/general/user/invite`,
  USER_EDIT: `${ADMIN_BASE_URL}admin/general/user/:id`,
  API: `${ADMIN_BASE_URL}admin/general/api`,
  ADD_API: `${ADMIN_BASE_URL}admin/general/add_api`,
  API_EDIT: `${ADMIN_BASE_URL}admin/general/api/:id`,
  SOURCE: `${ADMIN_BASE_URL}admin/module/broadcast`,
  RELATIONSHIP: `${ADMIN_BASE_URL}admin/module/relationship`,
  ADDVIDEO: `${ADMIN_BASE_URL}admin/module/grow/add-video`,
  EDITVIDEO: `${ADMIN_BASE_URL}admin/module/grow/video/:id`,
  MANAGEVIDEO: `${ADMIN_BASE_URL}admin/module/grow/manage-video`,
  SOURCE_ADD: `${ADMIN_BASE_URL}admin/module/broadcast/add`,
  SOURCE_EDIT: `${ADMIN_BASE_URL}admin/module/broadcast/:id`,
  EVENT: `${ADMIN_BASE_URL}admin/module/event`,
  EVENT_ADD: `${ADMIN_BASE_URL}admin/module/event/schedule`,
  EVENT_EDIT: `${ADMIN_BASE_URL}admin/module/event/:id`,
  TYPE: `${ADMIN_BASE_URL}admin/module/type`,
  TYPE_ADD: `${ADMIN_BASE_URL}admin/module/type/add`,
  TYPE_EDIT: `${ADMIN_BASE_URL}admin/module/type/:id`,
  TEAM: `${ADMIN_BASE_URL}admin/module/team`,
  TEAM_ADD: `${ADMIN_BASE_URL}admin/module/team/add`,
  TEAM_EDIT: `${ADMIN_BASE_URL}admin/module/team/:id`,
  GROW: `${ADMIN_BASE_URL}admin/module/grow`,
  POINT: `${ADMIN_BASE_URL}admin/incentive/point`,
  POINT_ADD: `${ADMIN_BASE_URL}admin/incentive/point/add`,
  POINT_EDIT: `${ADMIN_BASE_URL}admin/incentive/point/:id`,
  STREAK: `${ADMIN_BASE_URL}admin/incentive/streak`,
  REWARD: `${ADMIN_BASE_URL}admin/incentive/reward`,
  REWARD_ADD: `${ADMIN_BASE_URL}admin/incentive/reward/add`,
  REWARD_EDIT: `${ADMIN_BASE_URL}admin/incentive/reward/:id`,
  MYEVENTS: `${ADMIN_BASE_URL}focus/events`,
};
