import { Form, message, Popconfirm } from "antd";
import TeamAPI from "api/http/team";
import UserAPI from "api/http/user";
import { adminRoutes, settingsRoutes } from "helpers/adminRoutes";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { me } from "store/reducers/user.reducer";
import { PrimaryButton } from "ui/Button";
import { Card, CardTitle } from "ui/Card";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import { QuestionCircleOutlined } from "@ant-design/icons";

import {
  FileUpload,
  FormInput,
  FormItem,
  FormSelect,
  FormTextArea,
  FormSelectSearch,
} from "ui/Form";
import { Container } from "ui/Layout/Container";
import styled from "styled-components";
import {
  getDynamicMenuString,
  imagePath,
  paginationObj,
  querystring,
  searchObj,
} from "utils/fn";
import { IoIosClose } from "react-icons/io";
import { SecondaryButton } from "ui/Button/index";
import AddTeamMembers from "Components/Team/AddTeam/AddTeamMembers";

const AddTeam = ({
  isModal,
  onBack = "",
  isEdit = false,
  _id = "",
  teamIdUser = console.log,
  onFormChange = console.log,
}) => {
  const params = useParams();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [dataChange, setDataChange] = useState(false);
  const [state, setState] = useState({
    image: "",
    image_src: "",
  });
  const [users, setUsers] = useState([]);
  const { configData } = useSelector((state) => state.configData);

  const [addedTeamId, setAddedTeamId] = useState();
  const [modalState, setModalState] = useState(false);
  const [modalStates, setModalStates] = useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [form] = Form.useForm();
  // const [teamIdUser, setTeamIdUser] = useState("");
  // const teamId = useMemo(
  //   () => (isEdit ? params?.id || _id : null),
  //   [params?.id, _id, isEdit]
  // );
  const teamId = useMemo(() => params?.id || _id, [params?.id, _id]);
  const getTeam = useCallback((id) => {
    TeamAPI.getTeam(id)
      .then(({ data }) => {
        form.setFieldsValue(data);
        if (data.avatar) {
          setState((s) => ({
            ...s,
            image: {
              ...data.avatar,
              name: data.avatar?.originalName,
              url: imagePath(data.avatar.path),
            },
            image_src: imagePath(data.avatar.path),
          }));
        }
        getUserList({ filter: { $or: [{ _id: { $in: data.admins } }] } });
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createOptions = (data = []) => {
    return data.map((u) => ({
      avatar: u?.avatar
        ? { src: imagePath(u?.avatar?.path) }
        : { fullName: `${u?.firstName || ""} ${u?.lastName || ""}` },
      label: `${u.firstName} ${u.lastName}`,
      value: u._id,
    }));
  };

  useEffect(() => {
    if (dataChange) {
      onFormChange(true);
    }
    return () => {
      console.log("closed");
    };
  }, [dataChange]);

  const getUserList = useCallback(({ filter = {} }) => {
    return UserAPI.getUserList(querystring(paginationObj({ filter })))
      .then(({ data }) => {
        setUsers(createOptions(data.data));
        return createOptions(data.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        return [];
      });
  }, []);

  const searchUsers = (search) => {
    const filter = searchObj(["email", "firstName", "lastName"], search);
    return getUserList({ filter });
  };
  useEffect(() => {
    if (teamId) {
      getTeam(teamId);
    } else {
      form.resetFields();
      getUserList({ filter: { $or: [{ _id: { $in: user._id } }] } });
    }
  }, [getTeam, teamId]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const teamPhoto = {
    name: "avatar",
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        message.error("Image must smaller than 50 MB!");
        return false;
      }
      getBase64(file, (base64) =>
        setState((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setState({
        image: "",
        image_src: "",
      });
    },
    fileList: state.image ? [state.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const dData = (data, admin) => {
    teamIdUser(data, admin);
  };

  const handleSubmit = (data) => {
    setDataChange(false);
    setAddedTeamId(teamId);
    setShowPrompt(false);
    if (teamId) {
      TeamAPI.updateTeam(teamId, {
        ...data,
        avatar: state.image?.url ? "" : state?.image,
      })
        .then(() => {
          message.success("Community updated successfully");
          dispatch(me());
          if (typeof onBack === "function") {
            onBack();
          } else {
            setModalStates(true);
            // history.push(settingsRoutes.TEAM);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    } else {
      TeamAPI.addTeam({ ...data, avatar: state?.image })
        .then((data) => {
          setAddedTeamId(data?.data?._id);
          dData(data.data._id, data.data?.admins);
          message.success("Community added successfully");
          dispatch(me());
          if (typeof onBack === "function") {
            onBack();
          } else {
            setModalStates(true);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    }
  };

  const onFinish = (data) => {
    setDataChange(false);
    setAddedTeamId(teamId);
    setShowPrompt(false);
    if (teamId) {
      TeamAPI.updateTeam(teamId, {
        ...data,
        avatar: state.image?.url ? "" : state?.image,
      })
        .then(() => {
          message.success("Community updated successfully");
          dispatch(me());
          if (typeof onBack === "function") {
            onBack();
          } else {
            setModalStates(true);
            // history.push(settingsRoutes.TEAM);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    } else {
      TeamAPI.addTeam({ ...data, avatar: state?.image })
        .then((data) => {
          setAddedTeamId(data?.data?._id);
          dData(data.data._id, data.data?.admins);
          message.success("Community added successfully");
          dispatch(me());
          if (typeof onBack === "function") {
            onBack();
          } else {
            setModalStates(true);
          }
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    }
    history.push(settingsRoutes.TEAM);
  };

  console.log("modalstatesss,", modalStates);

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  function confirm() {
    handleDeleteTeam();
  }

  const handleDeleteTeam = () => {
    history.push(adminRoutes.TEAM);
    TeamAPI.deleteTeam(teamId, { id: teamId }).then((data) => {
      dispatch(me());
    });
  };

  useEffect(() => {
    if (user && user._id) {
      form.setFieldsValue({
        admins: [user._id],
      });
    }
  }, [form, user]);

  return (
    <Container paddings={"0"} padding={isModal ? "0" : ""}>
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Card padding={isModal ? "" : "25px 30px 15px"}>
        {isModal !== true && (
          <CardTitle containerStyle={{ padding: "10px 0 30px 0" }}>
            {teamId || isEdit
              ? "Edit " + configData?.[0].module?.[1]?.children?.[2]?.name
              : configData?.[0].module?.[1]?.children?.[2]?.name}
          </CardTitle>
        )}

        {isModal && (
          <StyledTitle>
            {teamId || isEdit
              ? "Edit " +
                getDynamicMenuString(configData?.[0], ["connect", "team"])
              : getDynamicMenuString(configData?.[0], ["connect", "team"])}
          </StyledTitle>
        )}

        <Form
          form={form}
          onValuesChange={() => {
            setDataChange(true);
            setShowPrompt(true);
            onFormChange(true);
          }}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark={false}
          // initialValues={{
          //   // admins: [user !== null && user._id],
          //   admins: [user._id],
          // }}
        >
          <FormItem
            label="Community Name"
            name="name"
            type="text"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormInput placeholder="Enter community name" />
          </FormItem>
          <FormItem
            label="Admin Name"
            name="admins"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormSelectSearch
              placeholder="Select Admins"
              mode="multiple"
              filterOption={false}
              defaultOptions={users}
              onSearch={searchUsers}
            />
          </FormItem>
          <FormItem label="Community Photo" maxWidth={"822px"}>
            <FileUpload {...teamPhoto} />
          </FormItem>
          <FormItem
            label="Description"
            name="description"
            type="text"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormTextArea placeholder="Enter description text" />
          </FormItem>

          <FormItem
            label="Visibility"
            name="visibility"
            rules={[
              {
                required: true,
                message: "This is required",
              },
            ]}
          >
            <FormSelect
              options={[
                { label: "Publically available", value: "public" },
                { label: "Private", value: "private" },
                { label: "Only Me", value: "hidden" },
              ]}
            />
          </FormItem>

          {/* {isModal !== true ||
            (isEdit && (
              <FormItem>
                <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                  Save
                </PrimaryButton>
              </FormItem>
            ))} */}

          {/* {isModal && isEdit === false && ( */}
          <StyledButton
            style={
              isEdit
                ? { marginLeft: "10px", marginRight: "30px" }
                : { marginLeft: "105px" }
            }
          >
            <PrimaryButton
              htmlType="submit"
              style={
                isModal
                  ? { marginRight: "50px" }
                  : { marginLeft: "-700px", marginRight: "50px" }
              }
            >
              {params.id ? "Update" : "Finish"}
            </PrimaryButton>
            {/* {(teamId || isEdit) && (
              <SecondaryButton onClick={() => handleSubmit()}>
                Invite
              </SecondaryButton>
            )} */}
            <Popconfirm
              placement="topRight"
              title={"Are you sure to delete this team"}
              onConfirm={confirm}
              okText="Yes"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <SecondaryButton
                margin="15px 0 0 0"
                style={{ marginLeft: "20px" }}
              >
                {isEdit && "Delete"}
              </SecondaryButton>
            </Popconfirm>
          </StyledButton>

          {/* )} */}
        </Form>
        <AddTeamMembers
          onCancel={(value) => setModalStates(value)}
          modalState={modalStates}
          from={settingsRoutes.TEAM}
          teamId={teamId ? teamId : addedTeamId}
        />
      </Card>
      {/* </Container> */}
    </Container>
  );
};

export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 30px;
`;

export const StyledTitle = styled.h3`
  font-weight: bold;
  font-size: 34px;
  line-height: 28px;
  color: #232333;
  text-align: center;
  /* margin-top: 39px;
  margin-bottom: 40px; */
  width: 100%;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export default AddTeam;
