import React, { useCallback, useRef, useEffect, useContext } from "react";
import { StyledVideoCardWrapper, StyledVideoCard } from "../VideoSection.style";
import styled from "styled-components";
import { Input, Card, Button, message, Modal } from "antd";
import { AiFillCamera, AiFillSmile } from "react-icons/ai";
import { UserAvatar } from "ui/Avatar";
import { FaRegThumbsUp, FaThumbsUp } from "react-icons/fa";
import { imagePath } from "utils/fn.js";
import dayjs from "dayjs";
import CommentBar from "ui/PostCommentBar/CommentBar";
import ShowMoreMenu from "ui/Menu/ShowMore";
import { IoIosClose } from "react-icons/io";

import { useWindowSize } from "utils/hooks/useWindowSize";
import { paginationObj, querystring, shortenDateFromNow } from "utils/fn";
import {
  CommentWrapper,
  CommentImageWrapper,
  CommentTextCardWrapper,
  CommentTextCard,
  StyledCommentLike,
  CommentLikeWrapper,
  ViewMoreCommentWrapper,
  LinkTo,
  CommentImageWrap,
} from "../../Team/TeamDetail/LeftCards/styled";
import { MdMoreVert } from "react-icons/md";
import FbImageLibrary from "react-fb-image-grid";
import { adminRoutes } from "helpers/adminRoutes";
import { useSelector } from "react-redux";
import GrowAPI from "api/http/grow";
import GrowCommentAPI from "api/http/growComment";
import useSocket from "utils/hooks/useSocket";
import { StyledTitle } from "Components/Admin/ModuleSetting/Team/AddTeam";
import config from "helpers/config";
import { SocketContext } from "utils/context/socket";

const PlayListComment = ({
  ml,
  wd,
  videosData,
  videoValue,
  actions = console.log,
  _id,
  // totalComments = 8,
}) => {
  const commentRef = useRef();
  const { width } = useWindowSize();
  const { user: loggedInUser } = useSelector((state) => state.user);
  const [visible, setVisible] = React.useState(false);
  const [videosDatas, setvideosData] = React.useState(videosData);
  const [editCommentData, setEditCommentData] = React.useState();
  const [totalComment, settotalComments] = React.useState(0);

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 4,
    total: 0,
    filter: {},
    sorter: {},
    populate: [
      {
        path: "userId",
      },
    ],
  });

  const truncatedDateForMobile = useCallback(
    (str) => {
      if (width < 768) {
        return shortenDateFromNow(str);
      } else {
        return str;
      }
    },
    [width]
  );

  const actionOptionsDelete = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: false,
    },
  ];

  const actionOptionsEdit = [
    {
      label: "Delete",
      value: "delete",
      isDanger: false,
    },
  ];

  const getGrowCommentList = useCallback((pagination) => {
    GrowCommentAPI.getGrowCommentList(
      querystring(
        paginationObj({
          ...pagination,

          filter: {
            videoId: videoValue?._id,
            $sort: { createdAt: -1 },
          },
        })
      )
    ).then((data) => {
      settotalComments(data?.data?.total);
      setvideosData(data?.data?.data);
    });
  });

  useEffect(() => {
    getGrowCommentList(pagination);
  }, [videoValue, pagination]);

  const socket = useContext(SocketContext);

  useEffect(() => {
    if (socket && socket?.disconnected) {
      socket.connect();
    }
    if (socket) {
      socket.emit("join", "grow-comment");
      socket.on("grow-comment-updated", (data) => {
        settotalComments(data?.data?.total);
        setvideosData(data?.data?.data);
        if (data?.action === "patch") {
          setVisible(false);
        }
      });

      return () => {
        socket.emit("leave", "grow-comment");
      };
    }
  }, [socket]);

  const createComment = (videoId, payload) => {
    const formdata = new FormData();
    if (videoId) formdata.append("videoId", videoId);
    if (payload?.comment) formdata.append("comment", payload?.comment);
    if (payload?.image) formdata.append("image", payload?.image);
    GrowCommentAPI.addGrowComment(formdata);
    // getGrowCommentList(pagination)
  };

  const actionHandler = (commentId, comment, idx, { event }) => {
    switch (event) {
      case "delete":
        GrowCommentAPI.deleteGrowComment(commentId, { _id: commentId })
          .then(() => {
            message.success("Comment deleted");
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
        break;
      case "edit":
        setEditCommentData(comment);
        setVisible(true);
        break;
      default:
        break;
    }
  };

  const onSubmitUpdates = (id, data) => {
    const formdata = new FormData();

    if (data?.comment) formdata.append("comment", data?.comment);
    if (data?.image) formdata.append("image", data?.image);
    GrowCommentAPI.updateGrowComment(editCommentData?._id, formdata);
  };
  const commentLikeActionHandler = (comment, type) => {
    if (type == "push") {
      GrowCommentAPI.updateGrowComment(comment?._id, {
        $addToSet: { likers: loggedInUser?._id },
      });
    } else if (type == "pull") {
      GrowCommentAPI.updateGrowComment(comment?._id, {
        $pull: { likers: loggedInUser?._id },
      });
    }
  };
  return (
    <StyledVideoCardWrapper ml={ml} wd={wd}>
      <StyledVideoCard>
        <CommentBar
          mb={30}
          _id={videoValue?._id}
          onSubmit={createComment}
          ref={commentRef}
        />
        {(videosDatas && videosDatas.length > 0
          ? videosDatas
          : videosData || []
        ).map((comment, idx) => (
          <CommentWrapper key={idx}>
            <CommentImageWrapper>
              <UserAvatar
                src={
                  comment?.userId?.avatar?.path
                    ? // ? imagePath(comment?.user?.avatar?.path)
                      imagePath(comment?.userId?.avatar?.path)
                    : ""
                }
                fullName={`${comment.userId?.firstName} ${comment?.userId?.lastName}`}
                size={42}
              />
            </CommentImageWrapper>
            <CommentTextCardWrapper>
              <CommentTextCard
                title={
                  <LinkTo
                    to={adminRoutes.PROFILE_VIEW.replace(
                      ":id",
                      comment.userId?._id
                    )}
                  >
                    {`${comment.userId?.firstName} ${comment?.userId?.lastName}`}
                  </LinkTo>
                }
              >
                <p>{comment?.comment}</p>
              </CommentTextCard>
              <CommentImageWrap>
                {comment?.image ? (
                  <>
                    {console.log(comment?.image?.path)}
                    <FbImageLibrary
                      className="classes-gallary"
                      images={
                        comment?.image?.path
                          ? [
                              imagePath(
                                `${comment?.image?.path}`.replace(/\\+/g, "/")
                              ),
                            ]
                          : []
                      }

                      // countForm={3}
                      // renderOverlay={() => "Preview"}
                      // overlayBackgroundColor={"#0005"}
                    />
                    {/* <Image
                    preview={true}
                    src={
                      comment?.image?.path
                        ? imagePath(comment?.image?.path)
                        : ""
                    }
                    height={200}
                    width={200}
                    /> */}
                  </>
                ) : (
                  ""
                )}
              </CommentImageWrap>
              <StyledCommentLike>
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() =>
                    commentLikeActionHandler(
                      comment,
                      comment?.likers?.includes((loggedInUser?._id).toString())
                        ? "pull"
                        : "push"
                    )
                  }
                  icon={
                    comment?.likers?.includes(
                      (loggedInUser?._id).toString()
                    ) ? (
                      <FaThumbsUp className="comment_like_icon" />
                    ) : (
                      <FaRegThumbsUp className="comment_like_icon" />
                    )
                  }
                />
                <span>{comment?.likers?.length || 0}</span>
              </StyledCommentLike>
              <CommentLikeWrapper>
                <span
                  className={
                    comment?.likers?.includes(loggedInUser?._id)
                      ? "like_text"
                      : "unlike_text"
                  }
                  onClick={() =>
                    commentLikeActionHandler(
                      comment,
                      comment?.likers?.includes(loggedInUser?._id)
                        ? "pull"
                        : "push"
                    )
                  }
                >
                  Like
                </span>
                <span
                  className="reply_text"
                  onClick={() => commentRef.current.focus()}
                >
                  Reply
                </span>
                <span className="comment_at">
                  {truncatedDateForMobile(dayjs(comment?.createdAt).fromNow())}
                </span>
              </CommentLikeWrapper>
            </CommentTextCardWrapper>
            {comment?.userId?._id === loggedInUser?._id && (
              <ShowMoreMenu
                mw={50}
                Icon={MdMoreVert}
                options={actionOptionsDelete}
                item={comment}
                onChange={(val) => {
                  actionHandler(comment._id, comment, idx, {
                    event: val.key,
                    val: val,
                  });
                }}
              />
            )}
            {/* {comment.user._id === loggedInUser?._id ? (
              <ShowMoreMenu
                mw={50}
                Icon={MdMoreVert}
                options={actionOptionsDelete}
                item={comment}
                onChange={(val) => {
                  console.log(comment.post, comment._id, idx, {
                    event: val.key,
                    val: val,
                  });
                }}
              />
            ) : comment.post.team.admins.includes(loggedInUser?._id) ? (
              <ShowMoreMenu
                mw={50}
                Icon={MdMoreVert}
                options={actionOptionsEdit}
                item={comment}
                onChange={(val) => {
                  console.log(comment.post, comment._id, idx, {
                    event: val.key,
                    val: val,
                  });
                }}
              />
            ) : (
              ""
            )} */}
          </CommentWrapper>
        ))}
        {totalComment - videosDatas.length > 0 && (
          <ViewMoreCommentWrapper>
            <Button
              type="link"
              onClick={() =>
                setPagination((p) => ({
                  ...p,
                  pageSize: pagination?.pageSize + 4,
                }))
              }
            >
              View {totalComment - videosDatas.length} More Comments
            </Button>
          </ViewMoreCommentWrapper>
        )}
      </StyledVideoCard>
      {visible && (
        <Modal
          getContainer={() => document.querySelector("#modal-container")}
          visible={visible}
          onCancel={() => setVisible(false)}
          width={"960px"}
          footer={null}
          style={{ borderRadius: "5px" }}
          className="modal_wrapper"
          closeIcon={
            <IoIosClose
              className="modal_close_icon"
              // onClick={() => onCancel(false)}
            />
          }
          maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
          focusTriggerAfterClose={false}
          destroyOnClose={true}
        >
          <StyledTitle>Edit Comment</StyledTitle>
          <CommentBar
            // onFormChange={fromChanges}
            // onCancel={() => onCancel(false)}
            mb={52}
            commentData={editCommentData}
            onSubmit={onSubmitUpdates}
            // isEdit={true}
          />
        </Modal>
      )}
    </StyledVideoCardWrapper>
  );
};

export default PlayListComment;

const StyledWriteCommentInput = styled.div`
  display: flex;
  width: 100%;
  /* margin-bottom: 30px; */

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: ${({ theme }) => theme.colors.primary} !important;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:hover {
    border-color: #f0f0f0;
  }
  @media (max-width: 991px) {
    margin-bottom: 15px;
  }
`;

const InputWrapper = styled(Input)`
  border-radius: 10px;
  background: #f7f7f7;
  border: 1px solid #f0f0f0;
  min-height: 48px;

  input {
    background: #f6f7f9;
    font-size: 14px;
    line-height: 14px;
    color: #9aa2ab;
  }
`;

// const CommentWrapper = styled.div`
//   display: flex;
//   margin-bottom: 15px;
// `;

// const CommentImageWrapper = styled.div`
//   display: flex;
//   margin-right: 15px;

//   @media (max-width: 991px) {
//     margin-right: 10px;
//   }
// `;

// const CommentTextCardWrapper = styled.div`
//   display: flex;
//   flex-flow: column;
//   position: relative;
//   min-width: 200px;
// `;

// const CommentTextCard = styled(Card)`
//   background: #f7f7f7;
//   border: 1px solid #f0f0f0;
//   border-radius: 15px;

//   .ant-card-head {
//     min-height: 40px;
//     border-bottom: none;
//     padding: 0 14px;
//     @media (max-width: 991px) {
//       min-height: 30px;
//     }
//   }

//   .ant-card-body {
//     padding: 0px 14px;
//   }

//   .ant-card-head-title {
//     padding: 9px 0;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 22px;
//     color: #232333;
//     word-break: break-word;
//     white-space: normal;
//     @media (max-width: 991px) {
//       padding: 4px 0;
//     }
//   }

//   .ant-card-body p {
//     font-size: 14px;
//     line-height: 18px;
//     color: #747487;
//     @media (max-width: 991px) {
//       line-height: 13px;
//       font-size: 12px;
//     }
//   }
// `;

// const StyledCommentLike = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   padding: 4px 6px 4px 4px;
//   width: 54px;
//   height: 30px;
//   background: #ffffff;
//   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
//   border-radius: 20px;
//   position: absolute;
//   right: 0;
//   bottom: 0px;

//   .comment_like_icon {
//     font-size: 14px;
//   }

//   .ant-btn-circle {
//     height: 22px;
//     width: 22px;
//     min-width: 22px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: linear-gradient(276.7deg, #0061a4 0%, #3798db 100%);
//   }
// `;

// const CommentLikeWrapper = styled.div`
//   display: flex;

//   .unlike_text {
//     cursor: pointer;
//     font-weight: bold;
//     font-size: 12px;
//     line-height: 22px;
//     color: #747487;
//     margin-left: 16px;
//     margin-right: 16px;
//     cursor: pointer;
//   }

//   .like_text {
//     cursor: pointer;
//     font-weight: bold;
//     font-size: 12px;
//     line-height: 22px;
//     color: ${({ theme }) => theme.colors.primary};
//     margin-left: 16px;
//     margin-right: 16px;
//     cursor: pointer;
//   }

//   .reply_text {
//     font-weight: bold;
//     font-size: 12px;
//     line-height: 22px;
//     color: #747487;
//     margin-right: 16px;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     position: relative;
//   }

//   .reply_text::before {
//     position: absolute;
//     content: "";
//     left: -10px;
//     width: 3px;
//     height: 3px;
//     background-color: #747487;
//   }

//   .reply_text::after {
//     position: absolute;
//     content: "";
//     right: -10px;
//     width: 3px;
//     height: 3px;
//     background-color: #747487;
//   }

//   .comment_at {
//     font-size: 12px;
//     line-height: 22px;
//     color: #747487;
//   }
// `;

// const ViewMoreCommentWrapper = styled.div`
//   display: flex;

//   .ant-btn-link {
//     padding: 0;
//   }

//   span {
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 22px;
//     text-decoration-line: underline;
//     color: #747487;
//   }
// `;
