import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Tabs, List, Button } from "antd";
import {
  MemberInfoCard,
  MemberText,
  MemberInput,
  StyledTabWrapper,
  StyledTabs,
  StyledListWrapper,
  StyledListItem,
  ActionIconWrapper,
  AddMemberButtonWrap,
} from "../styled";
import { FiSearch } from "react-icons/fi";
import { FaCheck, FaTimes } from "react-icons/fa";
import { MdMoreVert } from "react-icons/md";
import {
  imagePath,
  nFormatter,
  paginationObj,
  querystring,
  _merge,
} from "utils/fn";
import TeamAPI from "api/http/team";
import { message } from "antd";
import { teamStatus, teamVisiblity, userRole } from "utils/constants";
import { UserAvatar } from "ui/Avatar";
import { useDispatch, useSelector } from "react-redux";
import ShowMoreMenu from "ui/Menu/ShowMore";
import useDebounce from "utils/hooks/useDebounce";
import { Flex } from "ui/Layout/Flex";
import { BadgeCount } from "ui/Badge";
import { useHistory } from "react-router-dom";
import { adminRoutes } from "helpers/adminRoutes";
import { HiPlus } from "react-icons/hi";
import { SecondaryButton } from "ui/Button/Button";
import AddTeamMembers from "Components/Team/AddTeam/AddTeamMembers";
import { me } from "store/reducers/user.reducer";
import useSocket from "utils/hooks/useSocket";
import config from "helpers/config";
import { LinkTo } from "../LeftCards/styled";
import { SocketContext } from "utils/context/socket";
const { TabPane } = Tabs;

export const MemberInfo = ({ data, reloadTeam = console.log }) => {
  const team = useMemo(() => data, [data]);
  const [list, setList] = useState({
    [teamStatus.joined]: [],
    [teamStatus.requested]: [],
    [userRole.admin]: [],
  });
  const [activeTab, setActiveTab] = useState(teamStatus.joined);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [modalState, setModalState] = useState(false);
  const [stateType, setType] = useState("");

  const defaultFilter = React.useMemo(
    () => ({
      team: team?._id,
      ...(activeTab === teamStatus.requested
        ? { initiateByRole: userRole.user }
        : activeTab === userRole.admin
        ? { initiateByRole: userRole.admin }
        : {}),
      status: activeTab === userRole.admin ? teamStatus.requested : activeTab,
    }),
    [team?._id, activeTab]
  );
  const defaultPopulate = (userMatch) => {
    return [
      {
        path: "user",
        select: ["firstName", "lastName", "avatar", "location"],
        ...(userMatch ? { match: userMatch } : {}),
      },
    ];
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: {
      [teamStatus.joined]: 0,
      [teamStatus.requested]: 0,
      [userRole.admin]: 0,
    },
    filter: defaultFilter,
    populate: defaultPopulate(),
  });
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 800);
  const inputRef = useRef(null);
  const getTeamMembers = useCallback((pagination, merge = false) => {
    setLoading(true);
    TeamAPI.getTeamMemberList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        let currentTab =
          pagination?.filter?.initiateByRole === userRole.admin
            ? userRole.admin
            : pagination?.filter?.status || activeTab;
        setList((l) => ({
          ...l,
          [currentTab]: merge
            ? _merge(l[currentTab], data.data || [], "_id")
            : data?.data.filter((d) => typeof d.user === "object") || [],
        }));
        setPagination((p) => ({
          ...p,
          ...(merge ? {} : { current: 1 }),
          total: {
            ...p.total,
            [pagination?.filter?.initiateByRole === userRole.admin
              ? userRole.admin
              : pagination?.filter?.status || activeTab]: data?.total,
          },
        }));
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const socket = useContext(SocketContext);

  const searchMembers = (searchValue) => {
    setLoading(true);

    TeamAPI.getTeamMemberList(
      querystring(
        paginationObj({
          ...pagination,
          filter: {
            ...defaultFilter,
            team: team?._id,
            ...(searchValue
              ? {
                  $text: {
                    $search: searchValue,
                  },
                }
              : {}),
          },
          populate: defaultPopulate(),
        })
      )
    )
      .then(({ data }) => {
        setList((l) => ({
          ...l,
          [activeTab]:
            data?.data.filter((d) => d.user && typeof d.user === "object") ||
            [],
        }));
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    console.log({ search: debouncedSearchValue });
    if (team?._id) {
      searchMembers(debouncedSearchValue);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (team?._id) {
      getTeamMembers({
        ...pagination,
        filter: {
          ...defaultFilter,
          status:
            activeTab === userRole.admin ? teamStatus.requested : activeTab,
        },
        populate: defaultPopulate(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamMembers, team?._id, activeTab]);

  useEffect(() => {
    if (team?._id) {
      getTeamMembers({
        ...pagination,
        filter: {
          ...defaultFilter,
          status: teamStatus.requested,
          initiateByRole: userRole.user,
        },
        populate: defaultPopulate(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team?._id, getTeamMembers]);

  const getStatus = (type) => {
    switch (type) {
      case "accept":
        return teamStatus.joined;
      case "delete":
      case "reject":
      case "leave":
        return teamStatus.rejected;

      default:
        return "";
    }
  };

  const getStatusMessage = (type) => {
    switch (type) {
      case "accept":
        return "accepted";
      case "delete":
        return "removed";
      case "reject":
        return "rejected";
      case "leave":
        return "left";

      default:
        return "Updated";
    }
  };

  const actionHandler = (type, teamData) => {
    setType(type);
    switch (type) {
      case "accept":
      case "delete":
      case "reject":
      case "leave":
        type === "leave" &&
          TeamAPI.updateTeamAdmin(team?._id, {
            $pull: { admins: user?._id },
          }).then((data) => {
            if (data?.data) {
              if (data?.data?.admins.length === 0) {
                TeamAPI.deleteTeam(team?._id, { id: team?._id });
              }
            }
          });

        TeamAPI.updateTeamMember(teamData._id, {
          status: getStatus(type),
          team: teamData.team,
          ...(type === "accept"
            ? { beforeStatus: getStatusMessage(type) }
            : {}),
        })
          .then(() => {
            message.success(
              type === "reject"
                ? " User join request declined "
                : `User ${getStatusMessage(type)} successfully`
            );

            if (["leave"].includes(type)) {
              history.push(adminRoutes.TEAM);
              dispatch(me());
            } else {
              getTeamMembers({
                ...pagination,
                filter: {
                  team: teamData.team,
                  status:
                    activeTab === userRole.admin
                      ? teamStatus.requested
                      : activeTab,
                  ...(activeTab === teamStatus.requested
                    ? { initiateByRole: userRole.user }
                    : {}),
                },
              });
              reloadTeam(true);
            }
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
        break;
      case "view":
        history.push(
          adminRoutes.PROFILE_VIEW.replace(":id", teamData?.user?._id)
        );
        break;

      default:
        break;
    }
  };

  const loadMore = pagination.total[activeTab] >
    pagination.current * pagination.pageSize && (
    <div className="header_view_more_wrap">
      <Button
        type="link"
        onClick={() => {
          getTeamMembers(
            {
              ...pagination,
              filter: { ...defaultFilter, team: team?._id },
              current: pagination.current + 1,
            },
            true
          );
          setPagination((p) => ({
            ...p,
            filter: { ...defaultFilter, team: team?._id },
            current: p.current + 1,
          }));
        }}
      >
        See More
      </Button>
    </div>
  );

  const onButtonClick = () => {
    inputRef.current.focus();
  };

  const actionOptions = (post) => [
    {
      label: "View Profile",
      value: "view",
      isDanger: false,
    },
    ...((team?.admins || []).includes(user?._id) ||
    post?.user?._id === user?._id
      ? [
          {
            label: post?.user?._id === user?._id ? "Leave" : "Remove",
            value: post?.user?._id === user?._id ? "leave" : "delete",
            isDanger: true,
          },
        ]
      : []),
  ];

  const actionFinish = () => {
    if (team?._id) {
      getTeamMembers({
        ...pagination,
        filter: {
          ...defaultFilter,
          team: team?._id,
          status:
            activeTab === userRole.admin ? teamStatus.requested : activeTab,
        },
        populate: defaultPopulate(),
      });
    }
  };

  useEffect(() => {
    if (socket && socket?.disconnected) {
      socket.connect();
    }
    if (socket) {
      socket.emit("join", "notifications");
      socket.on("notification-updated", (data) => {
        dispatch(me());
        if (data?.data?.type === "teamRequest") {
          getTeamMembers({
            ...pagination,
            filter: {
              ...defaultFilter,
              team: data?.data?.typeId,
              status: "Requested",
            },
            populate: defaultPopulate(),
          });
        }
      });
      socket.on("reconnect", () => {
        socket.emit("join", "notifications");
      });
      return () => {
        socket.emit("leave", "notifications");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <>
      <MemberInfoCard padding={"20px"}>
        <AddMemberButtonWrap>
          <MemberText>Members: {nFormatter(team?.memberCount)}</MemberText>
          {(data?.admins || []).includes(user?._id) ? (
            <SecondaryButton
              shape="circle"
              icon={<HiPlus className="plus_icon" />}
              onClick={() => setModalState(true)}
              height={"35px"}
              width={"35px"}
            />
          ) : (
            //  (
            //   team?.visibility === teamVisiblity.private &&
            //   list[teamStatus.joined].some(function (el) {
            //     return el.user._id === user?._id;
            //   }) && (
            //     <SecondaryButton
            //       shape="circle"
            //       icon={<HiPlus className="plus_icon" />}
            //       onClick={() => setModalState(true)}
            //       height={"35px"}
            //       width={"35px"}
            //     />
            //   )
            // )
            ""
          )}
          <AddTeamMembers
            onCancel={(value) => setModalState(value)}
            modalState={modalState}
            from={adminRoutes.TEAM}
            data={data}
            teamId={data?._id}
            onSubmit={() => actionFinish()}
          />
        </AddMemberButtonWrap>
        <MemberInput
          placeholder="Search..."
          ref={inputRef}
          onChange={(e) => setSearchValue(e.target.value)}
          prefix={<FiSearch className="search_icon" onClick={onButtonClick} />}
        />

        <StyledTabWrapper>
          <StyledTabs
            defaultActiveKey={teamStatus.joined}
            activeKey={activeTab}
            onChange={(tabKey) => {
              // setList([]);
              setPagination((p) => ({ ...p, current: 1 }));
              setActiveTab(tabKey);
            }}
          >
            <TabPane tab="Active" key={teamStatus.joined}>
              <StyledListWrapper
                itemLayout="horizontal"
                loadMore={loadMore}
                dataSource={list[teamStatus.joined]}
                renderItem={(item) => (
                  <StyledListItem
                    actions={[
                      <ShowMoreMenu
                        Icon={MdMoreVert}
                        options={actionOptions(item)}
                        item={item}
                        onChange={({ key, item }) => actionHandler(key, item)}
                      />,
                    ]}
                    // notificationRead={item.read}
                  >
                    <List.Item.Meta
                      avatar={
                        <LinkTo
                          to={adminRoutes.PROFILE_VIEW.replace(
                            ":id",
                            item?.user?._id
                          )}
                        >
                          <UserAvatar
                            fullName={`${item?.user?.firstName || ""} ${
                              item?.user?.lastName || ""
                            }`}
                            src={
                              item?.user?.avatar?.path
                                ? imagePath(item?.user?.avatar?.path)
                                : ""
                            }
                            size={53}
                          />
                        </LinkTo>
                      }
                      title={
                        <LinkTo
                          to={adminRoutes.PROFILE_VIEW.replace(
                            ":id",
                            item.user?._id
                          )}
                        >
                          {`${item.user?.firstName} ${item?.user?.lastName}`}
                        </LinkTo>
                      }
                      description={
                        item?.user?.location?.address === "undefined"
                          ? ""
                          : item?.user?.location?.address || ""
                      }
                    />
                  </StyledListItem>
                )}
              />
            </TabPane>
            {(team?.admins || []).includes(user?._id) &&
            team.visibility !== teamVisiblity.public ? (
              <>
                <TabPane
                  tab={
                    <Flex>
                      <span style={{ marginRight: "0.3em" }}>Requests</span>
                      {pagination.total[teamStatus.requested] > 0 && (
                        <BadgeCount
                          count={pagination.total[teamStatus.requested]}
                          size={"small"}
                        />
                      )}
                    </Flex>
                  }
                  key={teamStatus.requested}
                >
                  <StyledListWrapper
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={list[teamStatus.requested]}
                    renderItem={(item) => (
                      <StyledListItem
                        actions={[
                          <ActionIconWrapper>
                            <FaCheck
                              className="check"
                              onClick={() => actionHandler("accept", item)}
                            />
                            <FaTimes
                              className="cancel"
                              onClick={() => actionHandler("reject", item)}
                            />
                          </ActionIconWrapper>,
                        ]}
                        // notificationRead={item.read}
                      >
                        <List.Item.Meta
                          avatar={
                            <UserAvatar
                              fullName={`${item?.user?.firstName || ""} ${
                                item?.user?.lastName || ""
                              }`}
                              src={
                                item?.user?.avatar?.path
                                  ? imagePath(item?.user?.avatar?.path)
                                  : ""
                              }
                              size={53}
                            />
                          }
                          title={`${item?.user?.firstName || ""} ${
                            item?.user?.lastName || ""
                          }`}
                          description={
                            item?.user?.location?.address === "undefined"
                              ? ""
                              : item?.user?.location?.address || ""
                          }
                        />
                      </StyledListItem>
                    )}
                  />
                </TabPane>
                <TabPane
                  tab={
                    <Flex>
                      <span style={{ marginRight: "0.3em" }}>Invites</span>
                    </Flex>
                  }
                  key={userRole.admin}
                >
                  <StyledListWrapper
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={list[userRole.admin]}
                    renderItem={(item) => (
                      <StyledListItem>
                        <List.Item.Meta
                          avatar={
                            <UserAvatar
                              fullName={`${item?.user?.firstName || ""} ${
                                item?.user?.lastName || ""
                              }`}
                              src={
                                item?.user?.avatar?.path
                                  ? imagePath(item?.user?.avatar?.path)
                                  : ""
                              }
                              size={53}
                            />
                          }
                          title={`${item?.user?.firstName || ""} ${
                            item?.user?.lastName || ""
                          }`}
                          description={
                            item?.user?.location?.address === "undefined"
                              ? ""
                              : item?.user?.location?.address || ""
                          }
                        />
                      </StyledListItem>
                    )}
                  />
                </TabPane>
              </>
            ) : (team?.admins || []).includes(user?._id) ? (
              <TabPane
                tab={
                  <Flex>
                    <span style={{ marginRight: "0.3em" }}>Invites</span>
                  </Flex>
                }
                key={userRole.admin}
              >
                <StyledListWrapper
                  itemLayout="horizontal"
                  loadMore={loadMore}
                  dataSource={list[userRole.admin]}
                  renderItem={(item) => (
                    <StyledListItem>
                      {item.initiateByRole === "admin"}
                      <List.Item.Meta
                        avatar={
                          <UserAvatar
                            fullName={`${item?.user?.firstName || ""} ${
                              item?.user?.lastName || ""
                            }`}
                            src={
                              item?.user?.avatar?.path
                                ? imagePath(item?.user?.avatar?.path)
                                : ""
                            }
                            size={53}
                          />
                        }
                        title={`${item?.user?.firstName || ""} ${
                          item?.user?.lastName || ""
                        }`}
                        description={
                          item?.user?.location?.address === "undefined"
                            ? ""
                            : item?.user?.location?.address || ""
                        }
                      />
                    </StyledListItem>
                  )}
                />
              </TabPane>
            ) : (
              ""
            )}
          </StyledTabs>
        </StyledTabWrapper>
      </MemberInfoCard>
    </>
  );
};
