import React, { useState, useEffect, useCallback } from "react";
import { Container } from "ui/Layout/Container";
import { PlayListVideoWrapper } from "../PlayListVideoSection.style";
import PlayListComment from "./PlayListComment";
import PlayListVideo from "./PlayListVideo";
// import { videosData } from "../dummy.data";
// import { PlayListPageData } from "Components/Profile/ProfileData";
import {
  Tabs,
  Row,
  Col,
  Skeleton,
  Slider,
  Modal,
  Input,
  Form,
  message,
} from "antd";
import { IoIosClose } from "react-icons/io";
import { HiPlus } from "react-icons/hi";
import { imagePath, paginationObj, querystring, searchObj } from "utils/fn";

import GrowAPI from "api/http/grow";
import GrowCommentAPI from "api/http/growComment";
import { PrimaryButton, SecondaryButton } from "ui/Button/index";
import { useDispatch, useSelector } from "react-redux";
import {
  FileUpload,
  FormInput,
  FormItem,
  FormSelect,
  FormSelectSearch,
  FormTextArea,
  ToggleSwitch,
} from "ui/Form/index";
import { useParams } from "react-router";
import UserAPI from "api/http/user";
import {
  StyledContentTitle,
  StyledFormControl,
} from "Components/Relationship/TheRelationship.styles";
import VideoAPI from "api/http/videos";
import { AddNewField } from "../ManageVideos/style";
const PlayListPage = (videosData, PlayListPageData) => {
  const params = useParams();
  const [form] = Form.useForm();

  const { user } = useSelector((state) => state.user);
  const [videosDatas, setvideosData] = React.useState(videosData);
  const [totalComment, settotalComments] = React.useState(videosData);
  const [commentData, setCommentData] = React.useState([]);
  const [modalState, setmodalState] = useState(false);
  const [users, setUsers] = useState([]);
  const [video, setVideo] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [data, setData] = useState();
  const [allowCustomThumbnail, setAllowCustomThumbnail] = useState(false);
  const [state, setState] = useState();
  const [playlistToggle, setPlaylistToggle] = useState(false);
  const [rewardableToggle, setRewardableToggle] = useState(false);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 4,
    total: 0,
    filter: {},
    sorter: {},
    populate: [
      {
        path: "userId",
      },
    ],
  });

  useEffect(() => {
    GrowAPI.getGrowListDetail(
      params.id,
      querystring(
        paginationObj({
          populate: [
            {
              path: "allVideos",
              populate: [
                {
                  path: "videoTimer",
                  match: { userId: localStorage.getItem("userId") },
                },
                { path: "uploadedBy" },
              ],
            },
          ],
        })
      )
    ).then((data) => {
      console.log("data", data);
      form.setFieldsValue({
        name: data?.data?.allVideos[0].name,
        description: data?.data?.allVideos[0].description,
        visibility: data?.data?.allVideos[0].visibility,
        // uploadedBy:
        //   data.uploadedBy?.firstName + " " + data.uploadedBy?.lastName,
        isPartOfPlaylist: data?.data?.allVideos[0].isPartOfPlaylist,
        allowCustom: data?.data?.allVideos[0]?.allowCustom,
      });

      getUserList({
        filter: {
          $or: [{ _id: { $in: data?.data?.allVideos[0]?.uploadedBy } }],
        },
      });

      // setAllowCustomThumbnail(data?.data?.allVideos[0]?.allowCustom);
      setData(data?.data?.allVideos[0]);
      // if (data?.data?.allVideos[0]?.customThumbnail) {
      //   setState((s) => ({
      //     ...s,
      //     image: {
      //       ...data?.data?.allVideos[0]?.customThumbnail,
      //       name: data?.data?.allVideos[0]?.customThumbnail?.originalName,
      //       url: imagePath(data?.data?.allVideos[0]?.customThumbnail.path),
      //     },
      //     image_src: imagePath(data?.data?.allVideos[0]?.customThumbnail.path),
      //   }));
      // }
      setThumbnail(data?.data?.allVideos[0]?.thumbnail);
      setRewardableToggle(data?.data?.allVideos[0]?.rewardable);
      setPlaylistToggle(data?.data?.allVideos[0]?.isPartOfPlaylist);
      if (data?.data?.allVideos[0]?.video) {
        setVideo((s) => ({
          ...s,
          video: {
            ...data?.data?.allVideos[0]?.video,
            name: data?.data?.allVideos[0]?.video?.originalName,
            url: imagePath(data?.data?.allVideos[0]?.video.path),
          },
          video_src: imagePath(data?.data?.allVideos[0]?.video.path),
        }));
      }

      setvideosData(data?.data?.allVideos);
      GrowCommentAPI.getGrowCommentList(
        querystring(
          paginationObj({
            ...pagination,

            filter: {
              videoId: data?.data?.allVideos?.[0]?._id,
              $sort: { createdAt: -1 },
            },
          })
        )
      ).then((data) => {
        settotalComments(data?.data?.total);
        setCommentData(data?.data?.data);
      });
    });
    window.scrollTo(0, 0);
    return () => {};
  }, [params.id, modalState]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const VideoUpload = {
    name: "video",
    multiple: false,
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isVideo = /video\/.+/gi.test(file.type);
      if (!isVideo) {
        message.error("You can only upload video file");
        return false;
      }
      // const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isLt2M) {
      //   message.error("Video must smaller than 10MB!");
      //   return false;
      // }
      getBase64(file, (base64) =>
        setVideo((s) => ({
          ...s,
          video_src: base64,
          video: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setVideo({
        video: "",
        video_src: "",
      });
    },
    fileList: video?.video ? [video?.video] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const createOptions = (data = []) => {
    return data.map((u) => ({
      avatar: u?.avatar
        ? { src: imagePath(u?.avatar?.path) }
        : { fullName: `${u?.firstName || ""} ${u?.lastName || ""}` },
      label: `${u.firstName} ${u.lastName}`,
      value: u._id,
    }));
  };
  const getUserList = useCallback(({ filter = {} }) => {
    return UserAPI.getUserList(querystring(paginationObj({ filter })))
      .then(({ data }) => {
        setUsers(createOptions(data.data));
        return createOptions(data.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        return [];
      });
  }, []);

  const handleFinish = (values) => {
    //  const formdata = convertJSONToFormData(values);
    const formdata = new FormData();
    if (params.id) {
      let id = values.uploadedBy;
      if (/\s/.test(id)) {
        // it's an ObjectID
        delete values.uploadedBy;
      } else {
        // nope
      }
    }
    Object.keys(values).forEach((k) => {
      if (values[k]) formdata.append(k, values[k]);
    });
    if (video?.video && !video.video?.url) {
      formdata.append("video", video?.video);
    }
    if (state?.image && !state?.image?.url) {
      formdata.append("customThumbnail", state?.image);
    }

    formdata.append("thumbnail", thumbnail);
    VideoAPI.updateVideo(data?._id, formdata).then((datas) => {
      setmodalState(false);
      if (video?.video && !video.video?.url) {
        VideoAPI.updateVideo(data?._id, {
          thumbnail: `/uploads/frames/${datas?.data?.video?.filename}`.replace(
            /(\.[\w\d_-]+)$/i,
            "_1.jpg"
          ),
          thumbnailImages: [
            `/uploads/frames/${datas?.data?.video?.filename}`.replace(
              /(\.[\w\d_-]+)$/i,
              "_1.jpg"
            ),
          ],
        }).then((data) => {});
      }
    });
  };
  const searchUsers = (search) => {
    const filter = searchObj(["email", "firstName", "lastName"], search);
    return getUserList({ filter });
  };

  useEffect(() => {
    if (user && user?._id) {
      form.setFieldsValue({
        uploadedBy: [user?._id || localStorage.getItem("userId")],
      });
    }
    getUserList({
      filter: {
        $or: [{ _id: { $in: user?._id || localStorage.getItem("userId") } }],
      },
    });
  }, [user]);

  return (
    <>
      <Container minH={"100%"}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ fontWeight: "bold" }}>Welcome</h2>
          {videosData?.[0]?.uploadedBy?._id === user?._id ||
            (videosDatas?.[0]?.uploadedBy?._id === user?._id && (
              <SecondaryButton onClick={() => setmodalState(true)}>
                Edit
              </SecondaryButton>
            ))}
        </div>
      </Container>
      <Container>
        <PlayListVideoWrapper wd={100}>
          <PlayListVideo ml={0.01} wd={100} videoValue={videosDatas?.[0]} />
          <PlayListComment
            ml={0.01}
            wd={100}
            videosData={commentData}
            videoValue={videosDatas?.[0]}
            totalComment={totalComment}
          />
        </PlayListVideoWrapper>
      </Container>

      {modalState !== false && (
        <Modal
          visible={modalState}
          onCancel={
            () => setmodalState(false)
            //  openPrompt(
            //     "You will lose all changes if you navigate away from this page without saving"
            //   )
          }
          width={"960px"}
          footer={null}
          // onOk={form.submit}
          style={{ borderRadius: "5px" }}
          className="modal_wrapper"
          closeIcon={<IoIosClose className="modal_close_icon" />}
          maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
          focusTriggerAfterClose={false}
        >
          <StyledContentTitle>Edit Video</StyledContentTitle>
          <StyledFormControl>
            <Row gutter={30}>
              <Form
                layout="vertical"
                onFinish={(e) => handleFinish(e)}
                requiredMark={false}
                form={form}
                // initialValues={{ e }}
                onValuesChange={() => {
                  // setShowPrompt(true);
                }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem
                    label="Video Name"
                    name="name"
                    type="text"
                    rules={[
                      {
                        required: true,
                        message: "This is required",
                      },
                    ]}
                  >
                    <FormInput placeholder="Enter video name" />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem
                    label="Description"
                    name="description"
                    type="text"
                    rules={[
                      {
                        required: true,
                        message: "This is required",
                      },
                    ]}
                  >
                    <FormTextArea placeholder="Enter description text" />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem label="Uploaded By" name="uploadedBy" type="text">
                    <FormSelectSearch
                      placeholder="Select Users"
                      mode="multiple"
                      filterOption={false}
                      defaultOptions={users}
                      onSearch={searchUsers}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem label="Visibility" name="visibility" type="text">
                    <FormSelect
                      placeholder="Select visibility"
                      maxWidth={"850px"}
                      options={[
                        { label: "All users", value: "All users" },
                        {
                          label: "Only assigned contacts",
                          value: "Only assigned contacts",
                        },
                      ]}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem
                    label="Choose a playlist"
                    name="playlist"
                    type="text"
                  >
                    <Row gutter={14}>
                      <Col span={22}>
                        <FormSelect
                          placeholder="Select playlist"
                          maxWidth={"850px"}
                          options={[
                            { label: "All users", value: "All users" },
                            {
                              label: "Only assigned contacts",
                              value: "Only assigned contacts",
                            },
                          ]}
                        />
                      </Col>

                      <Col span={2}>
                        <AddNewField
                          shape="circle"
                          icon={<HiPlus className="plus_icon" />}
                          onClick={(e) => console.log(e)}
                        />
                      </Col>
                    </Row>
                  </FormItem>
                </Col>
                {/* {playList &&
                        playList.length > 0 &&
                        playList.map((e, index) => (
                          <>
                            <FormItem>
                              <FormInput></FormInput>
                            </FormItem>
                          </>
                        ))} */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem
                    label="Is This Rewardable"
                    name="rewardable"
                    type="text"
                  >
                    <ToggleSwitch
                    // checked={rewardableToggle}
                    // onChange={(e) => handleRewardableToggle(e)}
                    />
                  </FormItem>
                </Col>
                {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem
                      label="Is It Part of Playlist"
                      name="isPartOfPlaylist"
                      type="text"
                    >
                      <ToggleSwitch
                        checked={playlistToggle}
                        onChange={(e) => handleplaylistToggle(e)}
                      />
                    </FormItem>
                  </Col> */}
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem label="Video Upload" maxWidth={"822px"}>
                    <FileUpload {...VideoUpload} />
                  </FormItem>
                </Col>

                <FormItem style={{ marginLeft: "85px" }}>
                  <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                    Save
                  </PrimaryButton>
                </FormItem>
              </Form>
            </Row>
            {/* <RelationshipContent addEdit={addEdit}></RelationshipContent> */}
          </StyledFormControl>
        </Modal>
      )}
    </>
  );
};

export default PlayListPage;
