import React, { useEffect, useState } from "react";
import {
  AntTable,
  DatatableHeader,
  DatatableHeaderLeft,
  DatatableHeaderRight,
} from "./datatable.styles";
import SearchBar from "./SearchBar";
import ItemPerPageSelect from "./ItemPerPageSelect";
import { Col, Row } from "antd";
import useDebounce from "utils/hooks/useDebounce";

const Datatable = ({
  search = true,
  showItemPerPage = true,
  headerContentLeft,
  headerContentRight,
  onSearch = console.log,
  onChangeItemPerPage = console.log,
  ...props
}) => {
  const [searchValue, setSearchValue] = useState(null);
  const debouncedSearch = useDebounce(searchValue, 800);
  useEffect(() => {
    if (searchValue !== null)
      onSearch({
        current: 1,
        search: `${debouncedSearch}`.trim(),
      });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <>
      <DatatableHeader>
        <Row gutter={4}>
          <Col
            xs={{ span: 15, order: 1 }}
            sm={{ span: 16, order: 1 }}
            md={{ span: 18, order: 1 }}
            lg={18}
            xl={18}
          >
            <DatatableHeaderLeft>
              {search && (
                <SearchBar onChange={(e) => setSearchValue(e.target.value)} />
              )}
              {showItemPerPage && (
                <ItemPerPageSelect
                  boxShadow="none"
                  label="Show"
                  onChange={(v) =>
                    onChangeItemPerPage({ current: 1, pageSize: v })
                  }
                />
              )}
              {headerContentLeft}
            </DatatableHeaderLeft>
          </Col>
          <Col
            xs={{ span: 9, order: 2 }}
            sm={{ span: 8, order: 2 }}
            md={{ span: 6, order: 2 }}
            lg={6}
            xl={6}
          >
            <DatatableHeaderRight>{headerContentRight}</DatatableHeaderRight>
          </Col>
        </Row>
      </DatatableHeader>
      <AntTable
        size="small"
        scroll={{ x: 768 }}
        {...props}
        showSorterTooltip={false}
      />
    </>
  );
};

export default Datatable;
