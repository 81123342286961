import React, { useState, useRef, useCallback } from "react";
import { BiTimeFive } from "react-icons/bi";
import { UserAvatar } from "ui/Avatar";
import { Link } from "react-router-dom";
import { Button } from "antd";
import {
  StyledVideoCardWrapper,
  StyledVideoCard,
  StyledVideoContentWrapper,
  StyleIframeVideo,
} from "../VideoSection.style";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { adminRoutes } from "helpers/adminRoutes";
import { Slider } from "antd";
// import { SliderBar } from "./PlayListVideoSection.style";
import { useWindowSize } from "utils/hooks/useWindowSize";
import {
  Player,
  BigPlayButton,
  ControlBar,
  ReplayControl,
  ForwardControl,
  VolumeMenuButton,
} from "video-react";
import { useEffect } from "react";
import { videosData } from "../dummy.data";
import userReducer from "store/reducers/user.reducer";
import VideoStatisticsAPI from "api/http/videoStatistics";
import { imagePath, paginationObj, querystring } from "utils/fn";
import GrowAPI from "api/http/grow";
import { StyledVideoProgressWrapper } from "Components/Profile/style";
import { SliderBar } from "../PlayListVideoSection.style";
import VideoAPI from "api/http/videos";
import { message } from "../../../../node_modules/antd/lib/index";
import { useScript } from "utils/hooks/useScript";
const PlayListVideo = ({ videoValue, ml, wd }) => {
  const videoPlayerRef = useRef(null);
  const { user } = useSelector((state) => state.user);
  const config = JSON.parse(localStorage.getItem("config"));
  const [currentTime, setCurrentTime] = useState(null);
  const [seek, setSeek] = useState(false);
  const [messageSeek, setmessageSeek] = useState(false);
  const [videoValues, setVideoValue] = useState(null);
  const [playerTime, setPlayerTime] = useState(null);
  const [updatedRecord, setUpdatedRecord] = useState(false);
  const { width } = useWindowSize();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {},
    sorter: {},
  });

  const ReadMore = () => {
    const text = videoValue?.description ? videoValue?.description : " ";
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    return (
      <>
        {!isReadMore ? <span>{text}</span> : <p>{text}</p>}

        {text?.length > 700 && (
          <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? "Read more" : "Show less"}
          </span>
        )}
      </>
    );
  };

  const getPlayerTimer = useCallback(() => {
    var { player } = videoPlayerRef.current.getState();

    // if (!player.userActivity && !player.paused)
    if (!seek) {
      if (!player.paused) {
        setUpdatedRecord(false);
        setCurrentTime(player.currentTime);
        setPlayerTime(player.duration);
      } else if (!updatedRecord) {
        if (currentTime > 0) {
          let datas = {
            watchedTime: Math.round(currentTime),
            videoId: videoValue?._id,
            userId: user?._id || localStorage.getItem("userId"),
            videoTime: playerTime,
          };
          VideoStatisticsAPI.getVideoStatisticsList(
            querystring(
              paginationObj({
                ...pagination,
                filter: {
                  videoId: videoValue?._id,
                  userId: user?._id || localStorage.getItem("userId"),
                },
              })
            )
          ).then((data) => {
            if (data?.data?.total === 0) {
              VideoStatisticsAPI.addVideoStatistics(datas).then((data) => {
                VideoAPI.updateVideo(videoValue?._id, {
                  videoTime: playerTime,
                  $addToSet: {
                    watchedUsers: user?._id || localStorage.getItem("userId"),
                  },
                });
              });
            } else {
              VideoStatisticsAPI.updateVideoStatistics(
                data?.data?.data[0]._id,
                {
                  watchedTime: currentTime,
                  videoTime: playerTime,
                }
              );
              VideoAPI.updateVideo(videoValue?._id, {
                $addToSet: {
                  watchedUsers: user?._id || localStorage.getItem("userId"),
                },
              });
            }
            VideoStatisticsAPI.getVideoStatisticsList(
              querystring(
                paginationObj({
                  ...pagination,
                  filter: {
                    videoId: videoValue?._id,
                    userId: user?._id || localStorage.getItem("userId"),
                  },
                })
              )
            ).then((data) => {
              setVideoValue(data?.data?.data[0]);
            });
            setUpdatedRecord(true);
          });
        }
      }
    }
  });

  const getSeekingTime = useCallback(() => {
    var { player } = videoPlayerRef.current.getState();
    if (currentTime + 0.9 <= player.seekingTime && !messageSeek) {
      setmessageSeek(true);
      message.error(
        "Hey just FYI you won't get the full points if you fast forward too much of the video",
        5
      );
      setSeek(true);
    }
  });

  // useEffect(() => {
  //   var intervalID = setInterval(() => getSeekingTime(), 10);
  //   return () => {
  //     clearInterval(intervalID);
  //   };
  // }, [getSeekingTime]);

  const handleplay = () => {
    var { player } = videoPlayerRef.current.getState();
    console.log("mansi player.videoWidth", player.videoWidth);
    if (player.videoWidth === 0 && player.currentTime <= 0) {
      message.info("video is in process");
    }
  };
  const { jwplayer: jwPlayer } = useScript(
    "https://cdn.jwplayer.com/libraries/WMhvTu6F.js",
    "jwplayer"
  );
  console.log("jwPlayer>>>>>", jwPlayer);
  const jwPlayerSetup = (url, img) => {
    // jwPlayer &&
    //   jwPlayer("myPlayer").setup({
    //     playlist: [
    //       {
    //         file: url,
    //       },
    //     ],
    //   });

    jwPlayer && jwPlayer("myPlayer").setup({ file: url, image: img });
  };

  useEffect(() => {
    if (jwPlayer && videoValue?.video?.url)
      jwPlayerSetup(
        `${config?.apiUrl}${videoValue?.video?.url}`,
        `${config?.apiUrl}${videoValue?.thumbnail}`
      );
    return () => {};
  }, [jwPlayer, videoValue?.video?.url]);

  // useEffect(() => {
  //   var intervalID = setInterval(() => getPlayerTimer(), 1000);
  //   return () => {
  //     clearInterval(intervalID);
  //   };
  // }, [getPlayerTimer]);

  return (
    <StyledVideoCard>
      <div id="myPlayer"> </div>
      {/* <div onClick={() => handleplay()}> */}
      {/* <Player
        // autoPlay={true}
        ref={videoPlayerRef}
        fluid={false}
        poster={`${config?.apiUrl}${videoValue?.thumbnail}`}
        startTime={
          videoValues?.watchedTime
            ? videoValues?.watchedTime
            : videoValue?.videoTimer?.watchedTime
        }
        src={`${config?.apiUrl}${videoValue?.video?.url}`}
      >
        <BigPlayButton position="center" />
      </Player> */}

      {/* </div> */}
      {/* <StyledVideoProgressWrapper>
        <SliderBar mr={"10px 7px 10px 10px"} wd={100} ml={30} pr={0.9}>
          <>
            <Slider
              tipFormatter={null}
              defaultValue={100}
              value={
                videoValue?.watchedUsers?.includes(user?._id)
                  ? getTimerData(
                      videoValues ? videoValues : videoValue?.videoTimer
                    )
                  : 0
              }
            />

            <div className="slider-text">
              <span>
                {" "}
                {videoValue?.watchedUsers?.includes(user?._id)
                  ? getTimerData(
                      videoValues ? videoValues : videoValue?.videoTimer
                    )
                  : 0}
                %
              </span>
            </div>
          </>
        </SliderBar>
      </StyledVideoProgressWrapper> */}
      {/* <Link to={adminRoutes.PLAYLISTPAGE}> */}
      <StyledVideoContentWrapper padding="0px">
        <h3>{videoValue?.name}</h3>

        <p className="comment_at">
          <span>
            {videoValue?.views} Views {videoValue?.watchedUsers?.length}
          </span>
          <BiTimeFive size={18} />
          {dayjs(videoValue?.createdAt).fromNow(true)} ago
        </p>
        <span className="avatar_wrap">
          <UserAvatar
            src={
              videoValue?.uploadedBy?.avatar?.path
                ? imagePath(videoValue?.uploadedBy?.avatar?.path)
                : ""
            }
            fullName={`${videoValue?.uploadedBy?.firstName} ${videoValue?.uploadedBy?.lastName}`}
            size={42}
          />

          <span className="user_name">
            {`${videoValue ? videoValue.uploadedBy?.firstName : ""} ${
              videoValue ? videoValue?.uploadedBy?.lastName : ""
            }`}
          </span>
        </span>

        <div className="video_description ">
          <ReadMore>{videoValue?.description}</ReadMore>
        </div>
      </StyledVideoContentWrapper>
      {/* </Link> */}
    </StyledVideoCard>
  );
};

export default PlayListVideo;
