import { httpAPI } from "api";

const TeamAPI = {
  getTeamList(query) {
    return httpAPI(`/teams?${query || ""}`, "", "GET");
  },
  getTeam(id, query = "") {
    return httpAPI(`/teams/${id}?${query}`, "", "GET");
  },
  addTeam({ avatar, ...data }) {
    const formdata = new FormData();
    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((ele, index) => {
          const tempFormKey = `${key}[${index}]`;
          formdata.append(`${tempFormKey}`, ele);
        });
      } else if (typeof data[key] === "object") {
        for (let subKey in data[key]) {
          formdata.append(`${key}.${subKey}`, data[key][subKey]);
        }
      } else {
        if (data[key]) formdata.append(key, data[key]);
      }
    }
    if (avatar) formdata.append("avatar", avatar);
    return httpAPI(`/teams`, formdata, "POST", true);
  },
  updateTeam(id, { avatar, ...data }) {
    const formdata = new FormData();
    const includeBlankFields = ["location.address"];
    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((ele, index) => {
          const tempFormKey = `${key}[${index}]`;
          formdata.append(`${tempFormKey}`, ele);
        });
      } else if (typeof data[key] === "object") {
        for (let subKey in data[key]) {
          formdata.append(`${key}.${subKey}`, data[key][subKey]);
        }
      } else {
        if (includeBlankFields.includes(key) || data[key])
          formdata.append(key, data[key]);
      }
    }
    if (avatar) formdata.append("avatar", avatar);
    return httpAPI(`/teams/${id}`, formdata, "PATCH", true);
  },
  updateTeamAdmin(id, params) {
    return httpAPI(`/teams/${id}`, params, "patch");
  },
  deleteTeam(id, params) {
    return httpAPI(`/teams/${id}`, params, "delete");
  },
  // Team Actions - [Members]
  joinTeam(data) {
    return httpAPI(`/team-users`, data);
  },
  getTeamMemberList(query) {
    return httpAPI(`/team-users?${query || ""}`, "", "GET");
  },
  updateTeamMember(id, data = {}) {
    return httpAPI(`/team-users/${id}`, data, "PATCH");
  },
  deleteTeamMember(id) {
    return httpAPI(`/team-users/${id}`, { _id: id }, "DELETE");
  },
  // Team Actions - [Post]
  createPost(teamId, { image, text, attachment, isDownloadable }) {
    const formdata = new FormData();
    if (text) formdata.append("text", text);
    if (image && image.length) {
      image.forEach((img) => {
        formdata.append(`image`, img);
      });
    }
    // if (attachment) {
    //   formdata.append(`attachment`, attachment);
    // }




    if (attachment && attachment.length) {
      attachment.forEach((img) => {
        formdata.append(`attachment`, img);
      });
    }

    if (isDownloadable || isDownloadable === false) {
      isDownloadable.forEach((img) => {
        formdata.append(`isDownloadable`, img);
      });
    }
    return httpAPI(`/team/${teamId}/posts`, formdata, "POST", true);
  },
  deletePost(teamId, postId, params) {
    return httpAPI(`/team/${teamId}/posts/${postId}`, params, "DELETE");
  },
  updatePost(
    teamId,
    postId,
    { image, attachment, ...data },
    withFormData = true
  ) {
    // Includes like, unlike


    console.log("withFormData", withFormData)
    console.log("attachment", attachment)
    const formdata = new FormData();
    const includeBlankFields = [];
    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((ele, index) => {
          const tempFormKey = `${key}[${index}]`;
          formdata.append(`${tempFormKey}`, ele);
        });
      } else if (typeof data[key] === "object") {
        for (let subKey in data[key]) {
          formdata.append(`${key}.${subKey}`, data[key][subKey]);
        }
      } else {
        if (includeBlankFields.includes(key) || data[key])
          formdata.append(key, data[key]);
      }
    }



    if (image && image.length) {
      image.forEach((img) => {
        formdata.append(`image`, img);
      });
    }
    console.log("isDownloadable data", data)
    if (attachment && attachment.length) {
      attachment.forEach((img) => {
        formdata.append(`attachment`, img);
      });
    }

    return httpAPI(
      `/team/${teamId}/posts/${postId}`,
      withFormData
        ? formdata
        : { ...data, ...(Array.isArray(image) ? { image } : {}), ...(Array.isArray(attachment) ? { attachment } : {}) },
      "PATCH",
      withFormData
    );
  },
  getPost(teamId, postId, query) {
    return httpAPI(`/team/${teamId}/posts/${postId}?${query || ""}`, "", "GET");
  },
  updatePostActions(teamId, postId, data) {
    return httpAPI(`/team/${teamId}/posts/${postId}/action`, data, "PATCH");
  },
  getPostList(teamId, query) {
    return httpAPI(`/team/${teamId}/posts?${query || ""}`, "", "GET");
  },

  getCommentList(postId, query) {
    return httpAPI(`/post/${postId}/comments?${query || ""}`, "", "GET");
  },
  getPostComment(postId, commentId, query) {
    return httpAPI(
      `/post/${postId}/comments/${commentId}?${query || ""}`,
      "",
      "GET"
    );
  },
  deleteComment(postId, commentId, params) {
    return httpAPI(`/post/${postId}/comments/${commentId}`, params, "DELETE");
  },
  // Team Actions - [Post] [Comments]
  createComment(postId, data) {
    const formdata = new FormData();
    if (data?.comment) formdata.append("comment", data?.comment);
    if (data?.image) formdata.append("image", data?.image);
    return httpAPI(`/post/${postId}/comments`, formdata, "POST", true);
  },
  updateComment(postId, commentId, { image, ...data }, withFormData = true) {
    const formdata = new FormData();
    const includeBlankFields = [];
    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((ele, index) => {
          const tempFormKey = `${key}[${index}]`;
          formdata.append(`${tempFormKey}`, ele);
        });
      } else if (typeof data[key] === "object") {
        for (let subKey in data[key]) {
          formdata.append(`${key}.${subKey}`, data[key][subKey]);
        }
      } else {
        if (includeBlankFields.includes(key) || data[key])
          formdata.append(key, data[key]);
      }
    }
    if (image) formdata.append("image", image);
    return httpAPI(
      `/post/${postId}/comments/${commentId}`,
      withFormData ? formdata : data,
      "PATCH",
      withFormData
    );
  },
};

export default TeamAPI;
