import client from "socket.io-client";
const API_URL = localStorage.getItem("config")


let socket;

export const initializeSocket = () => {
  socket = client(API_URL?.apiUrl, {
    transports: ["websocket", "polling"],
    auth: (cb) => {
      cb(localStorage.getItem("token"));
    },
  });
  socket.on("connect", () => {
    console.log("connected", socket.id);
  });
  socket.on("message", (data) => {
    console.log("info", data);
  });
  socket.on("disconnect", () => {
    console.log("Disconnected");
  });
};

export const joinGroup = (group) => {
  initializeSocket();
  socket.emit("join", group);
};

export const leftGroup = (group) => {
  socket.emit("leave", group);
};

export const sendToSocketEvent = (event, data = "") => {
  socket.emit(event, data);
};

export const subscribeSocketEvent = (event, cb) => {
  console.log("socket", socket);
  if (!socket) return true;
  socket.on(event, (msg) => {
    console.log(event, msg);
    return cb(null, msg);
  });
};

export const disconnectSocket = () => {
  socket.disconnect();
};

export const joinAndSubscribe = (group, event, cb) => {
  initializeSocket();
  socket.emit("join", group);
  socket.on(`${event}`, (msg) => {
    console.log(event, msg);
    return cb(null, msg);
  });
};

export default socket;
