import React, { useState, useEffect } from "react";
import { Breadcrumb } from "antd";
import { StyleLayout } from "Components/Relationship/TheRelationship.styles";
import { tabs, data } from "./data";
import {
  StyledTabButtonWraps,
  AddNewRelations,
  FloatButton,
} from "./TheFindEvents.style";
import { UserProfileEventData } from "./EventsData";
import { Tabs, message, Col, Row, Skeleton, Avatar, Form } from "antd";

import { SecondaryButton } from "../../ui/Button";
import { HiPlus } from "react-icons/hi";
import RelationshipTabAPI from "api/http/relationshipTab";
import EventSection from "./EventsSections/EventSection";
import { Container } from "ui/Layout/Container";
import { HeadingWithIconButton } from "ui/Heading/Heading";
import {
  StyledAvatarGroup,
  StyledImageWrapper,
  StyledEventCardWrapper,
  StyledAvatarGroupAllWrapper,
  StyledAvatarGroupWrapper,
  StyledAllContentCardWrapper,
  StyledRsvpButtonWrapper,
  StyledContentCardWrapper,
  StyledSelectItemWrapper,
} from "ui/Card/EventCard";
import { FormSelect } from "ui/Form";

const FindEvents = () => {
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(false);

  const OperationsSlot = {
    left: (
      <Container minH="100%">
        <HeadingWithIconButton
          buttonProps={{
            type: "secondary",
          }}
        >
          Find Events
        </HeadingWithIconButton>
      </Container>
    ),
  };

  const [tabstate, setTabState] = useState({ key: "Upcoming" });
  const [tabData, setTabData] = useState();

  function callback(key) {
    setTabState({ key: key });
  }

  useEffect(() => {
    var intervalID = setInterval(() => setLoading(true), 1000);
    return () => {
      clearInterval(intervalID);
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    // setTabState({ key: tabs.data.type });
  }, []);

  return (
    <StyleLayout>
      <StyledTabButtonWraps>
        <Tabs
          tabBarExtraContent={OperationsSlot}
          onChange={callback}
          activeKey={tabstate.key}
        >
          {/* <TabPane tab={<FindEventCompo />}></TabPane> */}
          {/* {tabs &&
            tabs.length > 0 &&
            tabs.map((ks, index) => (
              <TabPane tab={ks.type} key={ks.type}></TabPane>
            ))} */}
        </Tabs>
      </StyledTabButtonWraps>
      <Container>
        {!loading && (
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
              <StyledEventCardWrapper>
                {/* <StyledImageWrapper> */}
                <Skeleton.Image
                  size={"medium"}
                  active={true}
                  style={{ width: "100%", height: "200px" }}
                ></Skeleton.Image>
                {/* </StyledImageWrapper> */}
                <StyledAllContentCardWrapper>
                  <StyledContentCardWrapper>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                  </StyledContentCardWrapper>

                  <StyledAvatarGroupAllWrapper>
                    <StyledAvatarGroupWrapper>
                      <StyledAvatarGroup maxCount={0}>
                        <Avatar />
                      </StyledAvatarGroup>
                    </StyledAvatarGroupWrapper>
                  </StyledAvatarGroupAllWrapper>
                  <Form layout="vertical">
                    <StyledSelectItemWrapper name="RSVP" label="RSVP">
                      <FormSelect
                        placeholder="Select type"
                        options={[
                          { label: "I'm Going", value: "I'm Going" },
                          {
                            label: "Interested",
                            value: "Interested",
                          },
                          { label: "Next Time", value: "Next Time" },
                        ]}
                      />
                    </StyledSelectItemWrapper>
                  </Form>
                  <StyledRsvpButtonWrapper>
                    <Skeleton.Button></Skeleton.Button>
                  </StyledRsvpButtonWrapper>
                </StyledAllContentCardWrapper>
              </StyledEventCardWrapper>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
              <StyledEventCardWrapper>
                {/* <StyledImageWrapper> */}
                <Skeleton.Image
                  size={"medium"}
                  active={true}
                  style={{ width: "100%", height: "200px" }}
                ></Skeleton.Image>
                {/* </StyledImageWrapper> */}
                <StyledAllContentCardWrapper>
                  <StyledContentCardWrapper>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                  </StyledContentCardWrapper>

                  <StyledAvatarGroupAllWrapper>
                    <StyledAvatarGroupWrapper>
                      <StyledAvatarGroup maxCount={0}>
                        <Avatar />
                      </StyledAvatarGroup>
                    </StyledAvatarGroupWrapper>
                  </StyledAvatarGroupAllWrapper>
                  <Form layout="vertical">
                    <StyledSelectItemWrapper name="RSVP" label="RSVP">
                      <FormSelect
                        placeholder="Select type"
                        options={[
                          { label: "I'm Going", value: "I'm Going" },
                          {
                            label: "Interested",
                            value: "Interested",
                          },
                          { label: "Next Time", value: "Next Time" },
                        ]}
                      />
                    </StyledSelectItemWrapper>
                  </Form>
                  <StyledRsvpButtonWrapper>
                    <Skeleton.Button></Skeleton.Button>
                  </StyledRsvpButtonWrapper>
                </StyledAllContentCardWrapper>
              </StyledEventCardWrapper>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
              <StyledEventCardWrapper>
                {/* <StyledImageWrapper> */}
                <Skeleton.Image
                  size={"medium"}
                  active={true}
                  style={{ width: "100%", height: "200px" }}
                ></Skeleton.Image>
                {/* </StyledImageWrapper> */}
                <StyledAllContentCardWrapper>
                  <StyledContentCardWrapper>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                  </StyledContentCardWrapper>

                  <StyledAvatarGroupAllWrapper>
                    <StyledAvatarGroupWrapper>
                      <StyledAvatarGroup maxCount={0}>
                        <Avatar />
                      </StyledAvatarGroup>
                    </StyledAvatarGroupWrapper>
                  </StyledAvatarGroupAllWrapper>
                  <Form layout="vertical">
                    <StyledSelectItemWrapper name="RSVP" label="RSVP">
                      <FormSelect
                        placeholder="Select type"
                        options={[
                          { label: "I'm Going", value: "I'm Going" },
                          {
                            label: "Interested",
                            value: "Interested",
                          },
                          { label: "Next Time", value: "Next Time" },
                        ]}
                      />
                    </StyledSelectItemWrapper>
                  </Form>
                  <StyledRsvpButtonWrapper>
                    <Skeleton.Button></Skeleton.Button>
                  </StyledRsvpButtonWrapper>
                </StyledAllContentCardWrapper>
              </StyledEventCardWrapper>{" "}
              <StyledEventCardWrapper>
                {/* <StyledImageWrapper> */}
                <Skeleton.Image
                  size={"medium"}
                  active={true}
                  style={{ width: "100%", height: "200px" }}
                ></Skeleton.Image>
                {/* </StyledImageWrapper> */}
                <StyledAllContentCardWrapper>
                  <StyledContentCardWrapper>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                  </StyledContentCardWrapper>

                  <StyledAvatarGroupAllWrapper>
                    <StyledAvatarGroupWrapper>
                      <StyledAvatarGroup maxCount={0}>
                        <Avatar />
                      </StyledAvatarGroup>
                    </StyledAvatarGroupWrapper>
                  </StyledAvatarGroupAllWrapper>
                  <Form layout="vertical">
                    <StyledSelectItemWrapper name="RSVP" label="RSVP">
                      <FormSelect
                        placeholder="Select type"
                        options={[
                          { label: "I'm Going", value: "I'm Going" },
                          {
                            label: "Interested",
                            value: "Interested",
                          },
                          { label: "Next Time", value: "Next Time" },
                        ]}
                      />
                    </StyledSelectItemWrapper>
                  </Form>
                  <StyledRsvpButtonWrapper>
                    <Skeleton.Button></Skeleton.Button>
                  </StyledRsvpButtonWrapper>
                </StyledAllContentCardWrapper>
              </StyledEventCardWrapper>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
              <StyledEventCardWrapper>
                {/* <StyledImageWrapper> */}
                <Skeleton.Image
                  size={"medium"}
                  active={true}
                  style={{ width: "100%", height: "200px" }}
                ></Skeleton.Image>
                {/* </StyledImageWrapper> */}
                <StyledAllContentCardWrapper>
                  <StyledContentCardWrapper>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                    <Skeleton.Input
                      active={true}
                      style={{ width: 180, height: 20, marginBottom: 20 }}
                    ></Skeleton.Input>
                  </StyledContentCardWrapper>

                  <StyledAvatarGroupAllWrapper>
                    <StyledAvatarGroupWrapper>
                      <StyledAvatarGroup maxCount={0}>
                        <Avatar />
                      </StyledAvatarGroup>
                    </StyledAvatarGroupWrapper>
                  </StyledAvatarGroupAllWrapper>
                  <Form layout="vertical">
                    <StyledSelectItemWrapper name="RSVP" label="RSVP">
                      <FormSelect
                        placeholder="Select type"
                        options={[
                          { label: "I'm Going", value: "I'm Going" },
                          {
                            label: "Interested",
                            value: "Interested",
                          },
                          { label: "Next Time", value: "Next Time" },
                        ]}
                      />
                    </StyledSelectItemWrapper>
                  </Form>
                  <StyledRsvpButtonWrapper>
                    <Skeleton.Button></Skeleton.Button>
                  </StyledRsvpButtonWrapper>
                </StyledAllContentCardWrapper>
              </StyledEventCardWrapper>
            </Col>
          </Row>
        )}
        {loading && (
          <Row>
            <Col xs={0} sm={0} md={0} lg={24}>
              <EventSection
                tabKey={tabstate.key}
                UserProfileEventData={UserProfileEventData}
              />
            </Col>
          </Row>
        )}
      </Container>
      <FloatButton>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
        />
      </FloatButton>
    </StyleLayout>
  );
};

export default FindEvents;
