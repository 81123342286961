import React, { useEffect, useState, useCallback } from "react";
import { Col, Form, message, Row, Input, Modal, Tabs, Radio } from "antd";
import { PrimaryButton } from "ui/Button";
import { FormInput, FormItem } from "ui/Form/Input";
import { ColorPicker } from "ui/Form/ColorPicker";
import { FileUpload } from "ui/Form/FileUpload";
import { Container } from "ui/Layout/Container";
import { Card, CardTitle } from "ui/Card";
import { useSelector, useDispatch } from "react-redux";
import PortalAPI from "api/http/portal";
import { convertJSONToFormData, imagePath, _get, _set } from "utils/fn";
import { portal } from "store/reducers/user.reducer";
import { ToggleSwitch } from "ui/Form/ToggleSwitch";
import axios from "axios";
import { useHistory, useParams, Prompt } from "react-router";

import { useBeforeunload } from "react-beforeunload";
import FormDataTable from "ui/Datatable/FormDataTable";
import Actions from "ui/Datatable/Actions";
import {
  StyledContentTitle,
  StyledFormControl,
} from "Components/Relationship/TheRelationship.styles";
import {
  StyledButton,
  StyledFormItem,
} from "containers/AuthLayout/AuthLayout.styles";
import { IoIosClose } from "react-icons/io";
import { StyledTabButtonWrap } from "Components/AddSource/style";
import { StyledTabButtonWraps } from "Components/FindEvents/TheFindEvents.style";
import Tab from "ui/Tab/Tab";
import { configDatas } from "store/reducers/configData.reducer";
const { TabPane } = Tabs;

const Portal = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const dispatch = useDispatch();
  const [defaulState, setDefaultState] = useState(false);
  const [dataChange, setDataChange] = useState(false);
  const [datas, setDatas] = useState();

  const { primary, secondary, third, modules, clientId } = useSelector(
    (state) => state.user
  );
  const { configData } = useSelector((state) => state.configData);
  const [colors, setColors] = useState({
    primary: primary,
    secondary: secondary,
    third: third,
  });

  const [stateId, setStateId] = useState();
  const [statemodules, setModules] = useState();
  const [ids, setIds] = useState();
  const [landingPageLogo, setlandinPageLogo] = useState();
  const [landingPageBackground, setlandingPageBackground] = useState();
  const [homePageBackground, sethomePageBackground] = useState();
  const [faviconImage, setfaviconImage] = useState();
  const [toggleData, setToggleData] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [defaultPage, setDefaultPage] = React.useState("dashboard");
  const [tabstate, setTabState] = React.useState({ key: "Portal Setting" });
  const [frontendPath, setFrontendPath] = React.useState("");

  function callback(key) {
    setTabState({ key: key });
  }

  const handleColorChange = (hex, type) => {
    setColors((c) => ({ ...c, [type]: hex }));
    setDataChange(true);
  };

  const onhandleEdit = (key, item) => {
    setVisible(true);
    setEditData(item);
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
  ];

  // const changeInputHadler = (olddata, item, newValue) => {
  //   let child = {data: [...olddata]};
  //   // let innerChild = _get(child, "2.children", []);
  //   // console.log('>>>>> children', child, innerChild);
  //   console.log(">>>>> prev", item.key, _get(child, "data.2.children.0.name", "NA"));
  //   if (item.key === "find-a-team") {
  //     _set(child, "data.2.children.0.name", newValue);
  //     // innerChild[0] = { ...innerChild[0], name: e.target.value };
  //   } else if (item.key === "create-a-team") {
  //     _set(child, "data.2.children.1.name", newValue);
  //     // innerChild[1] = { ...innerChild[1], name: e.target.value };
  //   }
  //   // _set(child, '2.children', innerChild);
  //   console.log(">>>>> child", child.data);
  //   return child.data;
  // };

  const handleParentChange = (e, item, type) => {
    if (item.key && type == "child") {
      let child = [...editData.children];
      let innerChild = [...child[2].children];
      if (item.key === "find-a-team") {
        innerChild[0] = { ...innerChild[0], name: e.target.value };
      } else if (item.key === "create-a-team") {
        innerChild[1] = { ...innerChild[1], name: e.target.value };
      }

      child.splice(2, 0, { ...child[2], children: innerChild });
      child.splice(3, 1);
      // child[2].push({
      //     children:[...innerChild]
      //   })
      setEditData((p) => ({
        ...p,
        children: child,
      }));
    } else if (item.key) {
      let objIndex = editData.children.findIndex((x) => x.key == item.key);
      let child = [...editData.children];
      child[objIndex] = { ...item, name: e.target.value };

      console.log("child", child);
      setEditData((p) => ({
        ...p,
        children: child,
      }));
    } else {
      setEditData((p) => ({
        ...p,
        name: e.target.value,
      }));
    }
  };

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        onhandleEdit(key, item);
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: "Module Name",
      dataIndex: "name",
      key: "name",
      width: 600,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          ["dashboard", "connect", "grow", "focus"].includes(record.key) && (
            <Actions
              options={actionOptions}
              item={record}
              onChange={actionHandler}
            />
          )
        );
      },
    },
    {
      title: "Default Homepage",
      dataIndex: "default_homepage",
      key: "default_homepage",
      render: (_, record) => {
        return (
          !["connect", "grow", "focus"].includes(record.key) && (
            <Radio
              value={record.key}
              checked={defaultPage === record.key ? true : false}
              onChange={() => {
                setDefaultPage(record.key);
              }}
            ></Radio>
          )
        );
      },
    },
  ];

  useEffect(() => {
    PortalAPI.getPortalList().then((datas) => {
      let data = datas?.data?.data[0];
      setStateId(datas?.data?.data[0]._id);

      if (data?.faviconImage) {
        setfaviconImage((s) => ({
          ...s,
          image: {
            ...data.faviconImage,
            name: data.faviconImage?.originalName,
            url: imagePath(data.faviconImage.path),
          },
          image_src: imagePath(data.faviconImage.path),
        }));
      }
      setToggleData(data?.emailVerification);
      form.setFieldsValue({
        name: data?.name,
      });
      if (data?.landingPageBackground) {
        setlandingPageBackground((s) => ({
          ...s,
          image: {
            ...data.landingPageBackground,
            name: data.landingPageBackground?.originalName,
            url: imagePath(data.landingPageBackground.path),
          },
          image_src: imagePath(data.landingPageBackground.path),
        }));
      }

      if (data?.landingPageLogo) {
        setlandinPageLogo((s) => ({
          ...s,
          image: {
            ...data.landingPageLogo,
            name: data.landingPageLogo?.originalName,
            url: imagePath(data.landingPageLogo.path),
          },
          image_src: imagePath(data.landingPageLogo.path),
        }));
      }

      console.log("data?.defaultPage", data?.defaultPage);
      if (data?.defaultPage) {
        setDefaultPage(data?.defaultPage);
      }

      if (data?.homePageBackground) {
        sethomePageBackground((s) => ({
          ...s,
          image: {
            ...data.homePageBackground,
            name: data.homePageBackground?.originalName,
            url: imagePath(data.homePageBackground.path),
          },
          image_src: imagePath(data.homePageBackground.path),
        }));
      }

      if (!colors.primary) {
        setColors({
          primary: data.primary,
          secondary: data.secondary,
          third: data.third,
        });
      }
    });
  }, []);

  const handlefrontendPath = (e) => {
    setFrontendPath(e.target.values);
  };
  useEffect(() => {
    PortalAPI.getPortalList().then((datas) => {
      let data = datas?.data?.data[0];
      setStateId(datas?.data?.data[0]._id);
      console.log("datas?.data?.data[0]?", datas?.data?.data[0]);
      if (datas?.data?.data[0]?.frontendPath) {
        setFrontendPath(datas?.data?.data[0]?.frontendPath);
        form.setFieldsValue({
          frontendPath: datas?.data?.data[0]?.frontendPath,
        });
      }
    });
  }, [tabstate.key]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const LogoUpload = {
    name: "landingPageLogo",
    multiple: false,
    onChange(info) {
      const { status } = info.file;
      setDataChange(true);

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        message.error("Image must smaller than 50 MB!");
        return false;
      }
      getBase64(file, (base64) =>
        setlandinPageLogo((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setlandinPageLogo({
        image: "",
        image_src: "",
      });
    },
    fileList: landingPageLogo?.image ? [landingPageLogo?.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const backgroundImageUpload = {
    name: "landingPageBackground",
    multiple: false,
    onChange(info) {
      setDataChange(true);

      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        message.error("Image must smaller than 50 MB!");
        return false;
      }
      getBase64(file, (base64) =>
        setlandingPageBackground((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setlandingPageBackground({
        image: "",
        image_src: "",
      });
    },
    fileList: landingPageBackground?.image
      ? [landingPageBackground?.image]
      : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const homePageImageUpload = {
    name: "homePageBackground",
    multiple: false,
    onChange(info) {
      setDataChange(true);

      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        message.error("Image must smaller than 50 MB!");
        return false;
      }
      getBase64(file, (base64) =>
        sethomePageBackground((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      sethomePageBackground({
        image: "",
        image_src: "",
      });
    },
    fileList: homePageBackground?.image ? [homePageBackground?.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const faviconImageUpload = {
    name: "faviconImage",
    multiple: false,
    onChange(info) {
      setDataChange(true);

      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        message.error("Image must smaller than 50 MB!");
        return false;
      }
      getBase64(file, (base64) =>
        setfaviconImage((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setfaviconImage({
        image: "",
        image_src: "",
      });
    },
    fileList: faviconImage?.image ? [faviconImage?.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const configs = JSON.parse(localStorage.getItem("config"));

  const handleStatus = (values) => {
    setDataChange(true);
    setToggleData(values);
  };

  const handleDeployment = async (values) => {
    setShowPrompt(false);
    setDataChange(false);
    PortalAPI.updatePortal(stateId, values).then((data) => {
      message.success("Information saved successfully");
      dispatch(portal());
    });
  };
  const handleFinish = async (values) => {
    setShowPrompt(false);
    setDataChange(false);
    // let toggle = { emailVerification: toggleData };
    let data = { ...values, ...colors };

    const formdata = convertJSONToFormData(data);
    if (!toggleData) {
      formdata.append("emailVerification", toggleData);
    }

    let obj = {};
    if (landingPageLogo?.image === "" && landingPageLogo?.image_src === "") {
      obj.landingPageLogo = "";
    }
    if (
      landingPageBackground.image === "" &&
      landingPageBackground.image_src === ""
    ) {
      obj.landingPageBackground = "";
    }
    if (
      homePageBackground.image === "" &&
      homePageBackground.image_src === ""
    ) {
      obj.homePageBackground = "";
    }
    if (faviconImage.image === "" && faviconImage.image_src === "") {
      obj.faviconImage = "";
    }

    if (obj !== {}) {
      PortalAPI.updatePortal(stateId, obj)
        .then((data) => {
          // message.success("Information saved successfully");

          dispatch(portal());
        })
        .catch((err) => {
          console.log(err.message);
          message.error(err.message);
        });
    }
    if (landingPageLogo?.image && !landingPageLogo.image?.url) {
      formdata.append("landingPageLogo", landingPageLogo?.image);
    }

    if (landingPageBackground?.image && !landingPageBackground.image?.url) {
      formdata.append("landingPageBackground", landingPageBackground?.image);
    }
    if (homePageBackground?.image && !homePageBackground.image?.url) {
      formdata.append("homePageBackground", homePageBackground?.image);
    }
    if (faviconImage?.image && !faviconImage.image?.url) {
      formdata.append("faviconImage", faviconImage?.image);
    }
    PortalAPI.updatePortal(stateId, formdata)
      .then((data) => {
        message.success("Information saved successfully");
        dispatch(portal());
      })
      .catch((err) => {
        console.log(err.message);
        message.error(err.message);
      });
    await axios
      .patch(`${configs.antUrl}/client/${clientId}`, {
        module: statemodules,
      })
      .then((responses) => {
        return { ...responses.data?.data[0] };
      });
  };

  const handleClick = async (values) => {
    await axios
      .patch(`${configs.antUrl}/client/${clientId}`, {
        module: datas,
        defaultPage: defaultPage,
      })
      .then((responses) => {
        PortalAPI.updatePortal(stateId, {
          module: datas,
          defaultPage: defaultPage,
        });
        message.success("Modules changed successfully");
        dispatch(configDatas());
        return { ...responses.data?.data[0] };
      });
  };

  // const handleModules = (e, id) => {
  //   setSearchTerm(e.target.value);
  //   setIds(id);
  // };

  // useEffect(() => {
  //   if (searchTerm && searchTerm.length > 0) {
  //     const delayDebounceFn = setTimeout(() => {
  //       var datas = [...modules];
  //       let objIndex = datas.findIndex((x) => x._id === ids);
  //       if (objIndex !== -1) {
  //         datas[objIndex] = { ...datas[objIndex], name: searchTerm };
  //       }
  //       setModules(datas);
  //     }, 100);
  //     return () => clearTimeout(delayDebounceFn);
  //   }
  // }, [searchTerm]);

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  const onModalSubmit = (values) => {
    let dta = [...datas];
    let key =
      editData.key == "dashboard"
        ? 0
        : editData.key == "connect"
        ? 1
        : editData.key == "grow"
        ? 2
        : 3;
    dta[key] = { ...editData };
    setDatas(dta);
    setVisible(false);
  };

  useEffect(() => {
    setDatas(configData?.[0].module);
  }, [configData]);

  return (
    <Container>
      {/* {dataChange === true && defaulState === false && (
        <Beforeunload
          onBeforeunload={() =>
            "You will lose all changes if you navigate away from this page without saving"
          }
        />
      )} */}
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>

      <Tab
        tabPanes={[
          { type: "Portal Setting", key: "Portal Setting" },
          { type: "Manage Modules", key: "Manage Modules" },
          { type: "Deployment", key: "Deployment" },
        ]}
        // tabBarExtraContent={OperationsSlot}
        onChange={callback}
        activeKey={tabstate.key}
      />
      <Card padding="25px 30px 15px">
        {tabstate.key == "Portal Setting" && (
          <Form
            layout="vertical"
            onFinish={(e) => handleFinish(e)}
            requiredMark={false}
            form={form}
            initialValues={{ emailVerification: toggleData }}
            onValuesChange={() => {
              setShowPrompt(true);
            }}
          >
            <FormItem
              label="Client Business Name"
              name="name"
              type="text"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput placeholder="Client Business Name" />
            </FormItem>
            <Row gutter={4} style={{ maxWidth: "882px" }}>
              <Col sm={24} md={12} lg={8} xl={8} xxl={8}>
                <FormItem label="Primary Brand Color">
                  <ColorPicker
                    name="primary"
                    title="Primary Brand Color"
                    color={colors.primary}
                    onChangeComplete={({ hex }) =>
                      handleColorChange(hex, "primary")
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} md={12} lg={8} xl={8} xxl={8}>
                <FormItem label="Secondary Brand Color">
                  <ColorPicker
                    name="secondary"
                    title="Secondary Brand Color"
                    color={colors.secondary}
                    onChangeComplete={({ hex }) =>
                      handleColorChange(hex, "secondary")
                    }
                  />
                </FormItem>
              </Col>
              <Col sm={24} md={12} lg={8} xl={8} xxl={8}>
                <FormItem label="Third Brand Color">
                  <ColorPicker
                    name="third"
                    title="Third Brand Color"
                    color={colors.third}
                    onChangeComplete={({ hex }) =>
                      handleColorChange(hex, "third")
                    }
                  />
                </FormItem>
              </Col>
            </Row>
            <FormItem
              label="Email Verification"
              name="emailVerification"
              type="text"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <ToggleSwitch
                checked={toggleData}
                onChange={(e) => {
                  handleStatus(e);
                }}
              />
            </FormItem>
            <FormItem label="Landing Page Logo" maxWidth={"822px"}>
              <FileUpload {...LogoUpload} />
            </FormItem>
            <FormItem label="Landing Page Background" maxWidth={"822px"}>
              <FileUpload {...backgroundImageUpload} />
            </FormItem>
            <FormItem label="Homepage Logo" maxWidth={"822px"}>
              <FileUpload {...homePageImageUpload} />
            </FormItem>
            <FormItem label="Favicon Image" maxWidth={"822px"}>
              <FileUpload {...faviconImageUpload} />
            </FormItem>

            <FormItem>
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                Save
              </PrimaryButton>
            </FormItem>
          </Form>
        )}

        {tabstate.key === "Manage Modules" && datas && (
          <div>
            <FormDataTable
              columns={columns}
              dataSource={datas}
              pagination={false}
              showItemPerPage={false}
              search={false}
            />
            <FormItem>
              <PrimaryButton
                margin="15px 0 0 0"
                type="button"
                onClick={handleClick}
              >
                Save
              </PrimaryButton>
            </FormItem>
          </div>
        )}
        {tabstate.key == "Deployment" && (
          <Form
            layout="vertical"
            onFinish={(e) => handleDeployment(e)}
            requiredMark={false}
            form={form}
            // initialValues={}
            onValuesChange={() => {
              setShowPrompt(true);
            }}
          >
            <FormItem
              label="Frontend path"
              name="frontendPath"
              type="text"
              onChange={(e) => handlefrontendPath(e)}
              value={frontendPath}
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput placeholder="Frontend path" />
            </FormItem>

            <FormItem>
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                Save
              </PrimaryButton>
            </FormItem>
          </Form>
        )}
        {visible && (
          <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            // width={"960px"}
            footer={null}
            onOk={form.submit}
            style={{ borderRadius: "5px" }}
            className="modal_wrapper"
            closeIcon={<IoIosClose className="modal_close_icon" />}
            maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
            focusTriggerAfterClose={false}
          >
            <StyledFormControl>
              <Row gutter={30} style={{ maxWidth: "852px" }}>
                {editData && (
                  <Form initialValues={{ [editData.key]: editData.name }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <FormItem
                        className="custom-textbox"
                        name={editData.key}
                        label={"Parent"}
                      >
                        <FormInput
                          maxWidth={"290px"}
                          type="text"
                          onChange={(e) =>
                            setEditData((p) => ({
                              ...p,
                              name: e.target.value,
                            }))
                          }
                        />
                      </FormItem>
                    </Col>
                  </Form>
                )}
                {editData &&
                  editData?.children &&
                  editData.children.map((item, i) => {
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        key={item.key}
                      >
                        <StyledFormItem>
                          <Form
                            initialValues={{
                              [item.key]: item.name,
                            }}
                          >
                            <Form.Item
                              className="custom-textbox"
                              name={item.key}
                              // label="First Name"
                            >
                              <Input
                                type="text"
                                onChange={(e) => handleParentChange(e, item)}
                              />
                            </Form.Item>
                          </Form>
                        </StyledFormItem>
                        {item &&
                          item?.children &&
                          item?.children.map((item, i) => {
                            return (
                              <StyledFormItem style={{ marginLeft: "100px" }}>
                                <Form
                                  initialValues={{
                                    [item.key]: item.name,
                                  }}
                                >
                                  <Form.Item
                                    className="custom-textbox"
                                    name={item.key}
                                    // label="First Name"
                                  >
                                    <Input
                                      type="text"
                                      onChange={(e) =>
                                        handleParentChange(e, item, "child")
                                      }
                                    />
                                  </Form.Item>
                                </Form>
                              </StyledFormItem>
                            );
                          })}
                      </Col>
                    );
                  })}

                <PrimaryButton
                  style={{ marginLeft: "130px" }}
                  htmlType="submit"
                  onClick={() => onModalSubmit()}
                >
                  Save
                </PrimaryButton>
                {/* </Form> */}
              </Row>
            </StyledFormControl>
          </Modal>
        )}
      </Card>
    </Container>
  );
};

export default Portal;
