import styled from "styled-components";
import { Button } from "antd";

export const AddNewField = styled(Button)`
  /* display: inline-block;

  width: 48px;
  height: 48px;
  background: linear-gradient(
    276.7deg,
    ${(props) => props.theme.colors.primary} 0%,
    ${(props) => props.theme.colors.primary2} 100%
  );
  box-shadow: 0px 10px 15px ${(props) => props.theme.colors.primary}59;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  .plus_icon {
    font-size: 34px;
    color: white;
  }
  :hover {
    background: linear-gradient(
      276.7deg,
      ${(props) => props.theme.colors.primary} 0%,
      ${(props) => props.theme.colors.primary2} 100%
    );
  } */

  border: 1px solid transparent;
  background: linear-gradient(
    276.7deg,
    ${(props) => props.theme.colors.primary} 0%,
    ${(props) => props.theme.colors.primary2} 100%
  );
  box-shadow: 0px 10px 15px ${(props) => props.theme.colors.primary}59;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.primary};
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  &.ant-btn-circle {
    height: ${({ height }) => height || "48px"};
    width: ${({ width }) => width || "48px"};
  }

  .plus_icon {
    font-size: 34px;
  }
`;

export const StyledSourceName = styled.span`
  display: flex;
  height: 100%;
  align-items: center;
`;
export const DeleteField = styled.div`
  display: inline-block;
  /* margin-top: 16px;
  margin-left: 20px; */

  .ant-btn-circle {
    width: 48px;
    height: 48px;
    /* background: linear-gradient(142.07deg, #fc3c3c 13.94%, #ad1b1b 86.39%); */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(142.07deg, #fc3c3c 13.94%, #ad1b1b 86.39%);
    box-shadow: 0px 10px 12px 0px #fc5b5b59;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
`;

export const StyledAllInputWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const StyledSortable = styled.div`
  display: inline-block;
  width: 100%;
`;

export const StyledInputIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
