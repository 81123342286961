import {
  Form,
  message,
  Popconfirm,
  Popover,
  Menu,
  Upload,
  Dropdown,
} from "antd";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { PrimaryButton } from "ui/Button";
import { FormInput, FormItem } from "ui/Form";
import { FileUploadAvtar } from "ui/Form/FileUploadAvtar";
import { Heading } from "ui/Heading/Heading";
import { Container } from "ui/Layout/Container";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { CameraSvg } from "../../assets/svgs";

import {
  StyledEditUserCard,
  StyledUploadEditAvtarWrapper,
  StyledFormItemWrapper,
  StyledUploadAvtarTextWrapper,
  StyledUploadAvtarWrapper,
  StyledBadge,
} from "./style";
import { me } from "store/reducers/user.reducer";
import UserAPI from "api/http/user";
import { adminRoutes } from "helpers/adminRoutes";
import { getImageBase64, imagePath } from "utils/fn";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";
import UserProfileDetails from "./UserProfileDetails";
import { UserAvatar } from "ui/Avatar/index";
import {
  StyledHeaderDropdownContentWrappeer,
  StyledHeaderDropdownMenuItem,
} from "containers/TheLayout/Header/TheHeader.styles";
import { Button } from "../../../node_modules/antd/lib/index";

const ProfileEdit = () => {
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [stateUpload, setStateUpload] = React.useState(false);
  const [stateDelete, setStateDelete] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const [state, setState] = useState({
    image: "",
    image_src: "",
  });

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function confirm() {
    handleDeleteProfilepic();
  }

  const handleDeleteProfilepic = () => {
    UserAPI.updateUser(user?._id, {
      avatar: { $unset: "" },
    });
    dispatch(me());
    setVisible(false);
  };
  useEffect(() => {
    form.setFieldsValue({
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      email: user ? user.email : "",
      memberNumber: user ? user.memberNumber : "",
      "location.address": user?.location?.address || "",
      joinLink: user?.joinLink || "",
    });
    setState((s) => ({
      ...s,
      image_src: user?.avatar?.path ? imagePath(user?.avatar?.path) : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const addHttp = (link) => {
    const regex = new RegExp(/^https?:\/\//i);
    if (regex.test(link)) {
      return link;
    } else {
      return `http://${link}`;
    }
  };

  const onFinish = (values) => {
    setShowPrompt(false);
    if (params.id) {
      UserAPI.updateUser(params.id, {
        ...values,
        ...(values.joinLink ? { joinLink: addHttp(values.joinLink) } : {}),
        avatar: state.image,
      })
        .then(() => {
          message.success("Profile updated successfully");
          dispatch(me());
          history.push(adminRoutes.PROFILE);
        })
        .catch((error) => {
          if (error) {
            if (error.message === "email: value already exists.") {
              let messages = "Email already exists";
              message.error(messages);
            } else {
              message.error(error.message);
            }
          }
        });
    }
  };

  const onClick = ({ key }) => {
    if (key === "1") {
      uploadRef.current.click();
    } else {
      handleDeleteProfilepic();
    }
  };

  const menu = (
    <Menu onClick={onClick}>
      <Menu.Item key="1">Update</Menu.Item>
      {user?.avatar?.path && <Menu.Item key="2">Remove</Menu.Item>}
    </Menu>
  );

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadRef = useRef();

  const ProfilePicUpload = {
    name: "ProfilePicUpload",
    multiple: false,
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isJpgOrPng = ["image/jpeg", "image/png"].includes(file.type);
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file");
        return false;
      }

      getImageBase64(file, (base64) =>
        setState((s) => ({
          ...s,
          image_src: base64,
          image: file,
        }))
      );
      setVisible(false);
      return false;
    },
    onRemove: (file) => {
      setState({
        image: "",
        image_src: "",
      });
    },
    fileList: state?.image ? [state?.image] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  const fileChangeHandler = (event) => {
    setState({
      image: event.target.files[0],
      image_src: URL.createObjectURL(event.target.files[0]),
    });
  };

  const content = (
    <>
      {/* <p>
        <Upload showUploadList={false} {...ProfilePicUpload}>
          Update
        </Upload>
      </p> */}
      <p>
        <Popconfirm
          placement="topRight"
          title={"Are you sure to delete this team"}
          onConfirm={confirm}
          okText="Yes"
          cancelText="No"
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        >
          Remove
        </Popconfirm>
      </p>
    </>
  );

  return (
    <Container>
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Heading marginBottom={"85px"}>Edit Profile Details</Heading>
      <StyledEditUserCard padding="25px 30px 15px">
        {/* <CardTitle containerStyle={{ padding: "10px 0 30px 0" }}>
          {params.id ? "Edit User Details" : "Invite User"}
        </CardTitle> */}
        <Form
          form={form}
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          requiredMark={false}
          onValuesChange={() => {
            setShowPrompt(true);
          }}
        >
          <StyledUploadEditAvtarWrapper>
            {/* <Avatar {...avatarProps} /> */}
            {/* <Popover
              placement="rightBottom"
              content={content}
              trigger="click"
              // overlayClassName="header_popover_wrap"
              visible={visible}
            > */}
            {/* </Popover> */}
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <StyledBadge
                  badgeSize={"30px"}
                  size="default"
                  count={
                    <div
                      className="badge_style"
                      onClick={() => {
                        setShowMenu(true);
                        setVisible(true);
                      }}
                    >
                      <CameraSvg height={"12px"} width={"12px"} />
                    </div>
                  }
                  // offset={offset}
                >
                  <UserAvatar
                    onClick={() => {
                      setShowMenu(true);
                      setVisible(true);
                    }}
                    size={133}
                    image_src={state?.image_src}
                    src={
                      state?.image_src
                        ? state?.image_src
                        : user?.avatar?.path
                        ? imagePath(user.avatar?.path)
                        : ""
                    }
                    fullName={`${user?.firstName || ""} ${
                      user?.lastName || ""
                    }`}
                  />
                </StyledBadge>
              </a>
            </Dropdown>
            <input
              type="file"
              accept="image/*"
              ref={uploadRef}
              onChange={fileChangeHandler}
              // {...ProfilePicUpload}
              style={{ display: "none" }}
            />
          </StyledUploadEditAvtarWrapper>
          {params.id && (
            <StyledUploadAvtarTextWrapper></StyledUploadAvtarTextWrapper>
          )}
          <StyledFormItemWrapper id={params.id}>
            <FormItem
              label="First Name"
              name="firstName"
              type="text"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput placeholder="Enter first name" />
            </FormItem>
            <FormItem
              label="Last Name"
              name="lastName"
              type="text"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput placeholder="Enter last name" />
            </FormItem>
            <FormItem
              label="Email"
              name="email"
              type="text"
              rules={[
                {
                  required: true,
                  message: "This is required",
                },
              ]}
            >
              <FormInput placeholder="Enter your email address" />
            </FormItem>
            <FormItem
              label="Member Number"
              name="memberNumber"
              type="text"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <FormInput placeholder="Enter your member number" />
            </FormItem>
            <FormItem
              label="Location"
              name="location.address"
              type="text"
              rules={[]}
            >
              <FormInput placeholder="Enter location" />
            </FormItem>
            <FormItem name="joinLink" label="Join Link">
              <FormInput placeholder="Enter join link" />
            </FormItem>
            <FormItem>
              <PrimaryButton margin="15px 0 0 0" htmlType="submit">
                Save
              </PrimaryButton>
            </FormItem>
          </StyledFormItemWrapper>
        </Form>
      </StyledEditUserCard>
    </Container>
  );
};

export default ProfileEdit;
