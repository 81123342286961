// App.js
import "./App.less";
import React, { useEffect, useMemo } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { store } from "./store";
import { AuthLayout } from "./containers/AuthLayout";
import { ThemeProvider } from "styled-components";
import { Layout } from "./containers/TheLayout";
import { hexToRgb, rgbaToHexify } from "utils/fn";
import Generate from "Components/AddSource/Generate";
import "@lottiefiles/lottie-player";
import ResetTokenComponent from "Components/ResetTokenComponent";
import axios from "axios";
import { dynamicConfigUrl } from "./configuration/config";
import { useConfig } from "./configuration/useConfig";
import { Helmet } from "react-helmet";
import ConfigContextProvider from "./configuration/useConfig";
import io from "socket.io-client";
import { SocketContext } from "utils/context/socket";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse">Loading...</div>
  </div>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        // && (localStorage.getItem("emailVerified") === true)

        <Component {...props} />
      ) : (
        // <Redirect to="/auth/login" />
        <ResetTokenComponent />
      )
    }
  />
);

const AppStore = (props) => (
  <Provider store={store}>
    <App />
    <div id="modal-container"></div>
  </Provider>
);

const App = () => {
  const { setConfig, config } = useConfig();

  const {
    primary = "#02162",
    secondary = "#9BD4CE",
    third = "#f6f7f8",
  } = useSelector((state) => state.user);
  const [configLoadingState, setConfigLoadingState] = React.useState("loading");

  useEffect(() => {
    axios
      .get(dynamicConfigUrl)
      .then((response) => {
        setConfig(response.data);
        localStorage.setItem("config", JSON.stringify(response.data));
        console.log("Global config fetched: ", response.data);
        setConfigLoadingState("ready");
      })
      .catch((e) => {
        setConfigLoadingState("error");
      });
  }, [setConfig]);

  const theme = React.useMemo(
    () => ({
      colors: {
        primary: primary,
        ...(primary
          ? {
              primary2: rgbaToHexify(
                `rgba(${hexToRgb(primary).join(", ")}, 0.75)`
              ),
            }
          : {}),
        secondary: secondary,
        ...(secondary
          ? {
              secondary2: rgbaToHexify(
                `rgba(${hexToRgb(secondary).join(", ")}, 0.8)`
              ),
            }
          : {}),
        ...(secondary
          ? {
              secondary3: rgbaToHexify(
                `rgba(${hexToRgb(secondary).join(", ")}, 0)`
              ),
            }
          : {}),
        third: third,
        yellow: "#f2d53c",
        red: "#c80e13",
      },
      fonts: {
        body: "Arial, Helvetica, sans-serif",
      },
    }),
    [primary, secondary, third]
  );

  const businessName = localStorage.getItem("businessName");
  const faviconImage = localStorage.getItem("faviconImage");

  const API_URL = JSON.parse(localStorage.getItem("config"));
  const t = localStorage.getItem("token");
  const token = useMemo(() => t, [t]);

  let socket = useMemo(
    () =>
      token && API_URL?.apiUrl
        ? io(API_URL?.apiUrl, {
            transports: ["websocket", "polling"],
            autoConnect: false,
            query: {
              email: localStorage.getItem("userId"),
              token,
            },
          })
        : false,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );
  // const [socket] = useSocket(API_URL?.apiUrl, {
  //   transports: ["websocket", "polling"],
  //   autoConnect: false,
  // });
  useEffect(() => {
    console.log({ API_URL });
    if (API_URL?.apiUrl) {
      if (socket && socket.disconnected) {
        socket.connect();
      }
      if (socket) {
        socket.on("connect", () => {
          console.log("====> socket connect", socket.id);
        });
        socket.on("message", (msg) => {
          // console.log("====> socket message", msg);
        });
        socket.on("error", (err) => {
          console.log("====> socket error", err);
        });
        socket.on("reconnect_attempt", (count) => {
          console.log("====> socket reconnect_attempt:", count);
        });
        socket.on("reconnect_error", (err) => {
          console.log("====> socket reconnect_error:", err);
        });
        socket.on("reconnect_failed", () => {
          console.log("====> socket reconnect_failed");
        });
        socket.on("pong", (latency) => {
          console.log("====> socket ping", latency);
        });
        socket.on("disconnect", () => {
          console.log("====> socket disconnect");
          // socket.connect();
        });
      } else {
        console.log("====> socket not found <=====");
      }
    }
    return () => {
      socket && socket.removeAllListeners();
      socket && socket.close();
    };
  }, [API_URL?.apiUrl]);

  return configLoadingState === "loading" ? (
    <p></p>
  ) : configLoadingState === "error" ? (
    <h2 style={{ color: "red", textAlign: "center" }}>
      Error while fetching global config{" "}
    </h2>
  ) : (
    <SocketContext.Provider value={socket}>
      <ConfigContextProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <Route path="/landing" component={Generate} />
                <PrivateRoute path="/" component={Layout} />
              </Switch>
            </React.Suspense>
          </Router>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{businessName}</title>
            <link
              rel="icon"
              type="image/png"
              href={faviconImage}
              sizes="16x16"
            />
          </Helmet>
        </ThemeProvider>
      </ConfigContextProvider>
    </SocketContext.Provider>
  );
};

export default AppStore;
