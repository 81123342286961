import React from "react";
import { Button, Form, Input } from "antd";
import { Link, useHistory } from "react-router-dom";
import { adminRoutes, authRoutes } from "../helpers/adminRoutes";
import styled from "styled-components";
import EmailAPI from "api/http/email";
import { message } from "../../node_modules/antd/lib/index";

const StyledButton = styled(Button)`
  :hover,
  :focus {
    color: ${(props) => props.theme.colors.primary};
    border-color: ${(props) => props.theme.colors.primary};
  }
  width: 100%;
  height: 48px;
  background: ${(props) => props.theme.colors.secondary};
  box-shadow: 0px 8px 17px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  color: white;
`;
const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 474px;
  max-width: 100%;
`;

const StyledForms = styled.div`
  padding-top: 32px;

  .ant-form-item {
    margin-bottom: 14px;
  }

  .ant-input-affix-wrapper {
    min-height: 48px;
    background: #f6f7f9;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
  .ant-input {
    background-color: transparent;
  }
  .ant-input::placeholder {
    font-size: 14px;
    color: #949494;
  }

  .label-ant {
    font-size: 62px;
    line-height: 62px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }
  @media screen and (max-width: 959px) {
    padding-top: 0px;
  }
`;
const StyledFormsLabel = styled.div`
  width: max-content;
  line-height: 34px;
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 14px;
  border-bottom: 1px solid #000;
  @media screen and (max-width: 959px) {
    display: none;
  }
`;

const StyledFormItem = styled.div`
  .ant-form-item {
    .ant-form-item-label {
      font-family: Mulish;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: #232333;
    }
    input {
      max-width: 470px;
      width: 100%;
      height: 48px;
      background: #f6f7f9;
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 5px;
      @media screen and (max-width: 595px) {
        width: 100%;
      }
    }
  }
`;


const StyledRegisterWrapper = styled.div`
  display: flex;

  span {
    font-size: 14px;
    line-height: 24px;
    color: #747487;
    word-spacing: 0.5px;
  }

  .register_link {
    font-size: 14px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
    margin-left: 4px;
  }
`;


const StyledLink = styled.div`
  font-size: 14px;
  color: #747487;
  margin-left: 4px;
  font-weight: 600;
`;

const StyledForgotPassword = styled.ul`
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
`;

const Registration = () => {
  const history = useHistory();

  const onFinish = (values) => {
    values.type='reset_password'
    values.email=(values.email).toLowerCase()
    EmailAPI.addEmail(values).then((data)=>{
      message.success("Password reset email sent successfully")
        history.push("/");
    })
  };

  return (
    <StyledForm>
      <StyledFormsLabel>{"Forgot Password"}</StyledFormsLabel>
      <StyledForms>
        <Form
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          requiredMark={"optional"}
        >
          <StyledFormItem>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input placeholder="Enter your email address" />
            </Form.Item>
          </StyledFormItem>

          <Form.Item>
            <StyledButton
              // btnColor=""
              htmlType="submit"
            >
              Forgot Password
            </StyledButton>
          </Form.Item>
          <StyledRegisterWrapper>
            <span>New? Click here to</span>
            <Link className="register_link" to={"register"}>
              Register
            </Link>
          </StyledRegisterWrapper>
        </Form>
        
      </StyledForms>
    </StyledForm>
  );
};

export default Registration;
