import React, { useEffect } from "react";
import { Form, Tabs, Col } from "antd";
import { settingsRoutes } from "./../../../../helpers/adminRoutes";
import { useHistory } from "react-router-dom";
import { FormInput, FormSelect, FormTextArea } from "ui/Form";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { Card } from "ui/Card";

import { PrimaryButton } from "ui/Button";
import { useParams } from "react-router";
import {
  StyledFormItemButton,
  StyledFormItemWrapper,
  StyledInputItemWrapper,
  StyledRowWrapper,
} from "./../../../AddSource/style";
import ApiConnectsAPI from "api/http/apiConnects";
import { querystring } from "utils/fn";
import { message } from "../../../../../node_modules/antd/lib/index";
import { Prompt } from "react-router";
import { useBeforeunload } from "react-beforeunload";

const AddAPI = () => {
  const [defaulState, setDefaultState] = React.useState(false);
  const [dataChange, setDataChange] = React.useState(false);
  const [stateData, setStateData] = React.useState("");
  const [optionsData, setOptionsData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [currntValue, setCurrentValue] = React.useState("");
  const [showPrompt, setShowPrompt] = React.useState(false);
  const params = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    setShowPrompt(false);
    setDataChange(false);
    const payload = Object.keys(values).map((k) => ({
      key: k,
      value: values[k],
    }));

    ApiConnectsAPI.updateApiConnects(stateData._id, { fields: payload });
    history.push(settingsRoutes.API);
  };

  const handleChange = (value) => {
    ApiConnectsAPI.getApiConnectsListDetail(
      "1",
      querystring({ name: value })
    ).then(({ data }) => {
      (data?.fields || []).forEach((f) => {
        form.setFieldsValue({
          [f.key]: f.value,
        });
      });
      if (data?.fields[0].value) {
        message.error("Sorry you already created an API for this");
      } else {
        setStateData(data);
        setCurrentValue(value);
      }
      setDataChange(true);
    });
  };

  useEffect(() => {
    setLoading(true);
    if (params.id) {
      ApiConnectsAPI.getApiConnectsListDetail(
        "1",
        querystring({ _id: params.id })
      ).then(({ data }) => {
        setCurrentValue(data.name);
        setStateData(data);
        (data?.fields || []).forEach((f) => {
          form.setFieldsValue({
            [f.key]: f.value,
          });
        });
      });
    } else {
      ApiConnectsAPI.getApiConnectsList()
        .then(({ data }) => {
          let arr = [];
          for (const d of data.data) {
            let obj = { label: d.name, value: d.name };
            // if(!params.id){
            //   if(!d.fields[0].value){
            //     arr.push(obj)
            //   }
            // }else{
            arr.push(obj);
            // }
          }
          setOptionsData(arr);
        })
        .finally(() => {});
    }
    setLoading(false);
  }, []);

  useBeforeunload((event) => {
    if (showPrompt) {
      event.preventDefault();
    }
  });

  return (
    <Container>
      {/* {   <Beforeunload
                    onBeforeunload={() => "You will lose all changes if you navigate away from this page without saving"} />} */}
      <Prompt
        when={showPrompt}
        message="You will lose all changes if you navigate away from this page without saving"
      ></Prompt>
      <Heading>{params.id ? "Edit API" : "API"}</Heading>
      <Card>
        <StyledRowWrapper gutter={[20, 30]}>
          <Col xs={{ span: 18 }} xl={{ span: 10 }}>
            <Form
              form={form}
              layout="vertical"
              onFinish={(e) => onFinish(e)}
              requiredMark={false}
              onValuesChange={() => {
                setShowPrompt(true);
              }}
              labelCol={{
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 24 },
                xl: { span: 24 },
                xxl: { span: 24 },
              }}
            >
              <>
                <StyledFormItemWrapper>
                  <StyledInputItemWrapper>
                    <FormSelect
                      value={currntValue ? currntValue : "Select API"}
                      options={optionsData}
                      onChange={handleChange}
                    />
                  </StyledInputItemWrapper>

                  {stateData &&
                    stateData.fields &&
                    stateData.fields.length > 0 &&
                    stateData.fields.map((item) => (
                      <StyledInputItemWrapper
                        name={item.key}
                        type="text"
                        color="#ffff"
                        rules={[
                          {
                            required: true,
                            message: "This is required",
                          },
                        ]}
                      >
                        <FormInput
                          onChange={() => {
                            setDataChange(true);
                          }}
                          maxWidth={"822px"}
                          placeholder={item.key}
                          value={item.value}
                        />
                      </StyledInputItemWrapper>
                    ))}

                  <StyledFormItemButton>
                    {stateData !== "" && (
                      <PrimaryButton htmlType="submit">
                        {params.id ? "Update" : "Add"}
                      </PrimaryButton>
                    )}
                  </StyledFormItemButton>
                </StyledFormItemWrapper>
              </>
            </Form>
          </Col>
        </StyledRowWrapper>
      </Card>
    </Container>
  );
};

export default AddAPI;
