import EmailAPI from "api/http/email";
import UserAPI from "api/http/user";
import { adminRoutes, authRoutes } from "helpers/adminRoutes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { paginationObj, querystring } from "utils/fn";

const ResetTokenComponent = () => {
  const { user } = useSelector((state) => state.user);
  const history = useHistory();

  useEffect(() => {
    if (user?.refreshToken) {
      EmailAPI.getEmail(
        querystring(
          paginationObj({
            filter: { type: "accessToken", refreshToken: user?.refreshToken },
          })
        )
      ).then((data) => {
        console.log("data", data?.data?.token);
        localStorage.setItem("token", window.atob(data?.data?.token));
        localStorage.setItem("userId", data?.data?.user?.data[0]?._id);
        history.push(adminRoutes.DASHBOARD);
      });
    } else {
      history.push(authRoutes.LOGIN);
    }

    return () => {};
  }, []);

  return <div></div>;
};

export default ResetTokenComponent;
