import React, { useState, useEffect } from "react";
import {
  PlayListCardWrapper,
  StyledVideoCard,
  StyledImageWrapper,
  StyledVideoContentWrapper,
  PlayListVideoWrapper,
  StyledVideoIconWrapper,
  VideoPlayList,
  SliderBar,
  WelcomeComo,
  WelcomeComoMobile,
  PlayListCardWrapperRow,
  StyledPlayerWarpper,
  StyledVideoTimer,
  StyledImage,
} from "./PlayListVideoSection.style";
import { Image, Row, Col, Progress } from "antd";
import { BsPlayBtnFill } from "react-icons/bs";
import PlayListVideo from "./PlayListPage/PlayListVideo";
import PlayListComment from "./PlayListPage/PlayListComment";
import { videosData } from "./dummy.data";
import { PlayListPageData } from "Components/Profile/ProfileData";
import dayjs from "dayjs";
import { Slider } from "antd";
import { UserAvatar } from "ui/Avatar";
import { Container } from "ui/Layout/Container";
import { BiTimeFive } from "react-icons/bi";

import { useLocation } from "react-router";
import {
  Player,
  BigPlayButton,
  ControlBar,
  ReplayControl,
  ForwardControl,
  VolumeMenuButton,
} from "video-react";
import GrowCommentAPI from "api/http/growComment";
import { useSelector } from "react-redux";
import { imagePath, paginationObj, querystring } from "utils/fn";
const PlayListVideoSection = ({
  UserProfileVideosData,
  watchedTime,
  totalTime,
}) => {
  const videoData = React.useMemo(
    () => UserProfileVideosData,
    [UserProfileVideosData]
  );
  const config = JSON.parse(localStorage.getItem("config"));
  const [videoValue, setVideoValue] = useState({});
  const [outline, setOutline] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {},
    sorter: {},
    populate: [
      {
        path: "userId",
      },
    ],
  });

  const [playlistCompletion, setPlaylistCompletion] = React.useState(20);
  const { user, faviconImage } = useSelector((state) => state.user);

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (videoData?.length > 0) {
      setOutline(videoData?.[0]._id);
      setVideoValue(videoData?.[0]);
      GrowCommentAPI.getGrowCommentList(
        querystring(
          paginationObj({
            ...pagination,
            filter: {
              videoId: videoData?.[0]?._id,
            },
          })
        )
      ).then((data) => {
        setCommentData(data?.data?.data);
      });
    }
    return () => {
      setOutline("");
    };
  }, [pathname, videoData]);

  const getTimerData = (data) => {
    if (
      data?.userId &&
      data?.userId.toString() == (localStorage.getItem("userId") || user?._id)
    ) {
      return (
        (parseInt(data?.watchedTime) / parseInt(data.videoTime)) *
        100
      ).toFixed(2);
    }
  };

  return (
    <Row gutter={[30, 30]}>
      <Col xs={{ span: 24, order: 2 }} lg={{ span: 10, order: 1 }}>
        {/* <WelcomeComoMobile>
          <Container minH={"100%"} paddings={"4px 0px"}>
            <div>
              <h2 style={{ fontWeight: "bold" }}>Welcome</h2>
            </div>
          </Container>
          <Container minH={"100%"} paddings={"15px 0px 20px"}>
            <SliderBar mr={"0px 0px 5px 0px"} ml={playlistCompletion}>
              <Slider
                tipFormatter={null}
                defaultValue={20}
                value={playlistCompletion}
                onChange={(v) => {
                  setPlaylistCompletion(v);
                }}
              />
              <div className="slider-text">
                <span>Completed {playlistCompletion}%</span>
              </div>
            </SliderBar>
          </Container>
        </WelcomeComoMobile> */}

        <WelcomeComo>
          <h2>Welcome</h2>

          <SliderBar
            mr={"0px 0px 5px 0px"}
            ml={((watchedTime / totalTime) * 100).toFixed(2)}
            wd="100"
            marginBottom="60px"
          >
            <Slider
              tipFormatter={null}
              defaultValue={20}
              value={((watchedTime / totalTime) * 100).toFixed(2)}
              // onChange={(v) => {
              //   setPlaylistCompletion(v);
              // }}
            />
            <div className="slider-text">
              <span>
                Completed{" "}
                {watchedTime > 0
                  ? ((watchedTime / totalTime) * 100).toFixed(2)
                  : 0}
                %
              </span>
            </div>
          </SliderBar>
        </WelcomeComo>

        <Row gutter={[30, 30]}>
          {videoData &&
            videoData.map((item) => (
              <Col span={24}>
                <StyledVideoCard
                  outline={outline === item._id}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    });
                    setVideoValue(item);
                    GrowCommentAPI.getGrowCommentList(
                      querystring(
                        paginationObj({
                          ...pagination,
                          filter: {
                            videoId: item?._id,
                          },
                        })
                      )
                    ).then((data) => {
                      setCommentData(data?.data?.data);
                    });
                    setCommentData(item?.comments);
                    setOutline(item?._id);
                  }}
                >
                  <PlayListCardWrapperRow gutter={15}>
                    <Col span={10}>
                      <StyledPlayerWarpper>
                        <StyledImage
                          src={`${config?.apiUrl}/uploads/frames/${item.video.filename}`.replace(
                            /(\.[\w\d_-]+)$/i,
                            "_1.jpg"
                          )}
                          preview={false}
                        />
                        {/* <StyledImage
                          src="https://vivo-api.xeromedia.com/uploads/portals/landingPageBackground-1643841396830-YLBackgroudn.jpg"
                          preview={false}
                        /> */}

                        <StyledVideoIconWrapper>
                          <BsPlayBtnFill size={40} />
                        </StyledVideoIconWrapper>

                        {item?.videoTimer?.watchedTime && (
                          <StyledVideoTimer>
                            <span>
                              {item?.videoTimer?.watchedTime?.toFixed(2)}
                            </span>
                          </StyledVideoTimer>
                        )}
                      </StyledPlayerWarpper>
                    </Col>

                    <Col span={14}>
                      <StyledVideoContentWrapper active={outline === item._id}>
                        <h3>{item.name}</h3>

                        <span className="avatar_wrap">
                          <UserAvatar
                            src={
                              item.uploadedBy?.avatar?.path
                                ? imagePath(item.uploadedBy?.avatar?.path)
                                : ""
                            }
                            fullName={`${item.uploadedBy?.firstName} ${item?.uploadedBy?.lastName}`}
                            size={36}
                          />

                          <span className="user_name">{`${
                            item.uploadedBy ? item.uploadedBy?.firstName : ""
                          } ${
                            item.uploadedBy ? item?.uploadedBy?.lastName : ""
                          }`}</span>
                        </span>

                        <span className="user_upload">
                          <BiTimeFive size={18} />
                          {dayjs(item?.createdAt).fromNow(true)} ago
                        </span>

                        <SliderBar
                          wd={100}
                          ml={
                            item?.watchedUsers?.includes(
                              user?._id || localStorage.getItem("userId")
                            )
                              ? getTimerData(item?.videoTimer)
                              : 0
                          }
                          mr={"22px 8px 3px 0px"}
                        >
                          <>
                            <Slider
                              tipFormatter={null}
                              value={
                                item?.watchedUsers?.includes(
                                  user?._id || localStorage.getItem("userId")
                                )
                                  ? getTimerData(item?.videoTimer)
                                  : 0
                              }
                            />
                            <div className="slider-text">
                              <span>
                                {item?.watchedUsers?.includes(
                                  user?._id || localStorage.getItem("userId")
                                )
                                  ? getTimerData(item?.videoTimer)
                                  : 0}
                                %
                              </span>
                            </div>
                          </>
                        </SliderBar>
                      </StyledVideoContentWrapper>
                    </Col>
                  </PlayListCardWrapperRow>
                </StyledVideoCard>
              </Col>
            ))}
        </Row>
      </Col>

      <Col xs={{ span: 24, order: 1 }} lg={{ span: 14, order: 2 }}>
        <PlayListVideoWrapper>
          <PlayListVideo videoValue={videoValue} />

          <PlayListComment
            videosData={commentData}
            totalComments={commentData.length}
            videoValue={videoValue}
          />
        </PlayListVideoWrapper>
      </Col>
    </Row>
  );
};

export default PlayListVideoSection;
