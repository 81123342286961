import styled from "styled-components";
import { Menu, Badge, List } from "antd";
import { Link, useHistory } from "react-router-dom";

export const MenuItem = styled(Menu.Item)`
  &:hover {
    svg {
      fill: ${(props) => props.theme.colors.primary};
    }
  }
`;

export const StyledNotificationInline = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  a {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export const StyledText = styled.p`
  display: inline-block;
`;

// HEADER ALL WRAPPER
export const StyledHeaderTop = styled.div`
  /* position: relative; */
  /* width: 100%; */
  height: 65px;
  /* transition: ease-in-out 0.5s; */
  background-color: ${(props) => props.theme.colors.primary};
  background-image: url("/images/headerbg.png");
  background-size: cover;
  background-position: 100% 100%;
  display: flex;
  padding: 0 30px;

  position: fixed;
  z-index: 22;

  /* z-index: 1111; */
  /* width: calc(100% - 225px); */

  width: ${({ openSideBar }) => (openSideBar ? "calc(100% - 225px)" : "100%")};

  @media (max-width: 991.98px) {
    padding: 0 15px;
    width: 100%;
  }
`;

// SIDEBAR MENU
export const StyledLeftSideMenu = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  color: #ffffff;

  .header_hamburger_menu {
    color: white;
    font-size: 21px;
    cursor: pointer;
  }
`;

// RIGHT SIDE HEADER
export const StyledRightSideMenu = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: flex-end;

  .hand_icon {
    display: inline-block;
    margin-right: 13px;
    width: 29px;
    height: 29px;
  }

  .user_profile_wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .header_user_profile_image {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    margin-right: 20px;
    border: 1px solid white;
  }

  .ant-dropdown-trigger {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  .header_dropdown_menu_icon {
    color: white;
    font-size: 21px;
  }

  .header_user_mobile_image {
    display: none;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 20px;
    /* &.ant-avatar {
      margin-right: 20px;
      @media (max-width: 350px) {
        margin-right: 10px;
      }
      color: ${({ theme }) => theme.colors.primary};
    } */
  }

  .user_profile_wrapper {
    .ant-dropdown-trigger {
      span {
        margin-right: 20px;
        color: white;
        font-size: 14px;
        line-height: 14px;
        white-space: nowrap;
      }
    }
    .ant-avatar {
      margin-right: 20px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  @media (max-width: 575.98px) {
    .hand_icon {
      display: none;
    }
  }

  @media (max-width: 991.98px) {
    .hand_icon {
      margin-right: 20px;
    }
    .user_profile_wrapper {
      display: none;
    }
    .header_dropdown_menu_icon {
      display: none;
    }
    .header_user_mobile_image {
      display: flex;
    }
  }
`;

// HEADER DROPDOWN MENU
export const StyledHeaderDropdownContentWrappeer = styled(Menu)`
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #d3d6dd;
  width: 197px;
  top: 18px;

  .ant-dropdown-menu-item {
    padding: 10px 0px 10px 17.16px;
  }

  .ant-dropdown-menu-item:hover .ant-dropdown-menu-title-content > a {
    color: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: 991.98px) {
    top: 13px;
  }
`;

// HEADER DROPDOWN ITEM
export const StyledHeaderDropdownMenuItem = styled(MenuItem)`
  .ant-dropdown-menu-item-icon {
    color: #949494;
    font-size: 16px;
    margin-right: 12px;
  }
  .ant-dropdown-menu-title-content > a {
    color: #949494;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }

  /* :first-child {
    display: none;
  } */

  /* :first-child span {
    color: #949494;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  } */

  /* @media (max-width: 991.98px) {
    :first-child {
      display: block;
    }
  } */
`;
// HEADER TREE SELECT WRAPPER
export const StyledHeaderSearch = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 522px;
  z-index: 1;
  /* margin-top: 2px; */
  .ant-select-selector {
    height: auto;
    background-color: transparent;
    border: 0;
    box-shadow: none !important;
  }
  .ant-select {
    width: 80% !important;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }

  .ant-select-auto-complete {
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
  .ant-select-clear {
    opacity: 1;
    background: none;
    font-size: 16px;
    color: white;
  }
  // .ant-select-selection-item {
  //   background: none;
  //   border: none;
  // }
  // .ant-select-selection-item-remove {
  //   color: #ffffff;
  //   font-size: 15px;
  // }
  // .ant-select-selection-overflow-item {
  //   // display: none;
  // }

  .header_search_icon {
    font-size: 20px;
    margin-bottom: 4px;
    cursor: pointer;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
  }

  input::placeholder {
    color: white;
    font-size: 14px;
    line-height: 14px;
  }
  @media (max-width: 991.98px) {
    margin-left: 10px;
  }
`;

// HEADER NOTIFICATION WRAPPER
export const StyledNotificationWrapper = styled.div`
  display: flex;
  @media (max-width: 991.98px) {
    margin-right: 20px;
  }
  @media (max-width: 350px) {
    margin-right: 10px;
  }
  // margin-right: 20px;
  .bell_icon {
    font-size: 21px;
    color: white;
    cursor: pointer;
  }
`;

// HEADER NOTIFICATION BADGE
export const StyledNotificationBadge = styled(Badge)`
  .ant-badge-count {
    top: 2px;
    right: 1px;
    box-shadow: none;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    font-size: 12px;
    background: linear-gradient(142.07deg, #fc3c3c 13.94%, #ad1b1b 86.39%);
  }
`;

// HEADER NOTIFICATION LIST WRAPPER
export const StyledListWrapper = styled(List)`
  .header_view_more_wrap {
    text-align: center;
    margin-top: 12px;
  }

  .header_view_more_wrap Button {
    border: none;
    padding: 0;
    height: 0;
  }

  .header_view_more_wrap Button span {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: underline;
    color: #747487;
  }
`;
// HEADER NOTIFICATION ITEMS
export const StyledListItem = styled(List.Item)`
  background: ${(props) => (props.notificationRead ? "#F2F2F2" : "#FFFFFF")};
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 12px 5px 12px 13px;
  align-items: flex-start;
  align-items: center;
  cursor: pointer;

  .ant-list-item-meta {
    align-items: center;
  }

  .ant-list-item-action {
    margin-left: 0px;
  }

  .ant-list-item-meta-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #232333;
  }

  .ant-list-item-meta-description {
    font-size: 14px;
    line-height: 18px;
    color: #747487;
  }

  .header_notification_time {
    font-style: italic;
    font-size: 12px;
    line-height: 18px;
    color: #747487;
  }

  .header_notification_option_button {
    font-size: 24px;
    line-height: 24px;
    color: #747487;
    cursor: pointer;
  }

  @media (max-width: 991.98px) {
    .header_notification_time {
      font-size: 12px;
    }

    .ant-list-item-meta-title {
      font-weight: bold;
    }

    .ant-list-item-meta-description {
      font-size: 12px;
    }
  }
`;
