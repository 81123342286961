import React from "react";
import { Form, Button, Tabs } from "antd";
import { adminRoutes } from "../../../../helpers/adminRoutes";
import { useHistory } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { CopySvg, DeleteSvg } from "../../../../assets/svgs";
import Icon from "@ant-design/icons";
import { FormInput, FormSelect, FormTextArea } from "ui/Form";
import { Heading } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import { Card } from "ui/Card";
import { PrimaryButton } from "ui/Button";
import { useSelector } from "react-redux";
import { getDynamicMenuString } from "utils/fn";
import {
  StyledFormItem,
  StyledFormItemButton,
  StyledTabButtonWrap,
} from "./TheSourceList.styles";

const DeleteIcon = (props) => {
  return <Icon size={10} component={DeleteSvg} {...props} />;
};
const { TabPane } = Tabs;

const AddSource = () => {
  const history = useHistory();
  const [tabstate, setTabState] = React.useState({ key: "Details" });
  const { configData } = useSelector((state) => state.configData);

  const [state, setState] = React.useState({
    dynamicFields: [],
  });

  const [stateData, setStateData] = React.useState({
    dynamicFieldsData: [],
  });
  const [stateCopyValue, setStateCopyValue] = React.useState({
    copyVal: "",
  });

  const onFinish = (values) => {
    console.log("values", values);

    history.push(adminRoutes.SOURCE);
  };

  const handleChange = (value) => {
    let dynFields = value;
    let arr = [];
    for (const a of value) {
      arr.push({
        fieldName: a,
        value: "",
        visibility: true,
      });
    }
    setState({ dynamicFields: dynFields });
    setStateData({ dynamicFieldsData: arr });
  };

  const handleVisibility = (e, index) => {
    let dynFields = stateData.dynamicFieldsData;
    dynFields[index].visibility =
      !stateData.dynamicFieldsData[index].visibility;
    setStateData({ dynamicFieldsData: dynFields });
  };
  const handleDelete = (e, index) => {
    let dynFields = stateData.dynamicFieldsData;
    dynFields.splice(index, 1);
    setStateData({ dynamicFieldsData: dynFields });
  };

  const handleCodeInput = (e) => {
    setStateCopyValue({ copyVal: e.target.value });
  };

  const handleLinkInput = (e) => {
    setStateCopyValue({ copyVal: e.target.value });
  };

  const handleCodeValue = () => {
    let copyValue = stateCopyValue.copyVal;
    navigator.clipboard.writeText(copyValue);
  };
  const handleLinkValue = () => {
    let copyValue = stateCopyValue.copyVal;
    navigator.clipboard.writeText(copyValue);
  };

  const handleInput = (e, index) => {
    let dynFieldsData = stateData.dynamicFieldsData;

    dynFieldsData[index].value = e.target.value;

    setStateData({ dynamicFieldsData: dynFieldsData });
  };
  function callback(key) {
    setTabState({ key: key });
  }

  const handleVisibilityType = (e) => {};

  return (
    <Container>
      <Heading>
        Edit {getDynamicMenuString(configData?.[0], ["connect", "sources"])}
      </Heading>
      <StyledTabButtonWrap>
        <Tabs onChange={callback} activeKey={tabstate.key}>
          <TabPane tab="Details" key="Details"></TabPane>
          <TabPane tab="Generate" key="Generate"></TabPane>
        </Tabs>
      </StyledTabButtonWrap>
      <Card width={"1158px"}>
        <Form
          layout="vertical"
          onFinish={(e) => onFinish(e)}
          requiredMark={"optional"}
        >
          {tabstate.key == "Details" && (
            <div>
              {" "}
              <StyledFormItem>
                <Form.Item
                  name="source"
                  label="Source"
                  rules={[
                    {
                      required: true,
                      message: "This is required",
                    },
                  ]}
                >
                  <FormInput placeholder="Enter source name"></FormInput>
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem>
                <Form.Item
                  name="videoEmbed"
                  label="Video Embed"
                  rules={[
                    {
                      required: true,
                      message: "This is required",
                    },
                  ]}
                >
                  <FormInput
                    type="text"
                    placeholder="Start typing the video title if the video is in the system or copy and paste an embed code from any other website like youtube"
                  ></FormInput>
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem>
                <Form.Item
                  name="header"
                  label="Header"
                  rules={[
                    {
                      required: true,
                      message: "This is required",
                    },
                  ]}
                >
                  <FormInput type="text" placeholder="Enter header"></FormInput>
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "This is required",
                    },
                  ]}
                >
                  <FormTextArea
                    type="text"
                    placeholder="Enter paragraph text"
                  ></FormTextArea>
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem>
                <Form.Item
                  name="visibilityType"
                  label="Visibility"
                  rules={[
                    {
                      required: true,
                      message: "Please select visibility type",
                    },
                  ]}
                >
                  <FormSelect
                    placeholder="Select visibility type"
                    onChange={(e) => handleVisibilityType(e)}
                    options={[
                      { label: "All Users", value: "allUsers" },
                      {
                        label: "Only Assigned Users",
                        value: "onlyAssignedUsers",
                      },
                      { label: "Only me", value: "onlyme" },
                    ]}
                  ></FormSelect>
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem>
                <Form.Item
                  name="contact"
                  label="Contact"
                  rules={[{ required: true, message: "Please select type!" }]}
                >
                  <FormSelect
                    mode="multiple"
                    placeholder="Select type"
                    onChange={handleChange}
                    options={[
                      { label: "First Name", value: "firstName" },
                      { label: "Last name", value: "lastName" },
                      { label: "Email", value: "email" },
                      { label: "PhoneNumber", value: "phoneNumber" },
                      { label: "Address", value: "address" },
                    ]}
                  ></FormSelect>
                </Form.Item>
              </StyledFormItem>
              {stateData.dynamicFieldsData &&
                stateData.dynamicFieldsData.length > 0 &&
                stateData.dynamicFieldsData.map((k, index) => (
                  <div>
                    <StyledFormItem>
                      <Form.Item
                        name={k.fieldName}
                        label={
                          (k.fieldName == "firstName" && "First Name") ||
                          (k.fieldName == "lastName" && "Last Name") ||
                          (k.fieldName == "email" && "Email") ||
                          (k.fieldName == "phoneNumber" && "Phone Number") ||
                          (k.fieldName == "address" && "Address")
                        }
                        rules={[
                          {
                            required: true,
                            message: "This is required",
                          },
                        ]}
                      >
                        <FormInput
                          className="dynamicAddOn"
                          type="text"
                          onChange={(e) => handleInput(e, index)}
                          placeholder={
                            (k.fieldName == "firstName" &&
                              "Enter first name") ||
                            (k.fieldName == "lastName" && "Enter last name") ||
                            (k.fieldName == "email" && "Enter email") ||
                            (k.fieldName == "phoneNumber" &&
                              "Enter phone number") ||
                            (k.fieldName == "address" && "Enter address")
                          }
                          addonAfter={
                            <div className="dynamicButtonInput">
                              <Button
                                className="eye"
                                shape="circle"
                                icon={
                                  k.visibility == true ? (
                                    <FaRegEye />
                                  ) : (
                                    <FaRegEyeSlash />
                                  )
                                }
                                onClick={(e) => handleVisibility(e, index)}
                              />
                              <Button
                                className="delete"
                                shape="circle"
                                icon={<DeleteIcon />}
                                onClick={(e) => handleDelete(e, index)}
                              />
                            </div>
                          }
                          defaultValue=""
                        />
                      </Form.Item>
                    </StyledFormItem>
                  </div>
                ))}{" "}
              <StyledFormItemButton>
                <Form.Item>
                  <PrimaryButton htmlType="submit">Save</PrimaryButton>
                </Form.Item>
              </StyledFormItemButton>
            </div>
          )}
          {tabstate.key == "Generate" && (
            <div>
              <StyledFormItem>
                <Form.Item
                  name="code"
                  label="Code"
                  rules={[
                    {
                      required: true,
                      message: "This is required",
                    },
                  ]}
                >
                  <FormInput onChange={handleCodeInput} />
                  <Button
                    onClick={handleCodeValue}
                    type="text"
                    shape="circle"
                    icon={<CopySvg />}
                  />
                </Form.Item>
              </StyledFormItem>
              <StyledFormItem>
                <Form.Item
                  name="link"
                  label="Link"
                  rules={[
                    {
                      required: true,
                      message: "This is required",
                    },
                  ]}
                >
                  <FormInput onChange={handleLinkInput}></FormInput>

                  <Button
                    onClick={handleLinkValue}
                    type="text"
                    shape="circle"
                    icon={<CopySvg />}
                  />
                </Form.Item>
              </StyledFormItem>
            </div>
          )}
        </Form>
      </Card>
    </Container>
  );
};

export default AddSource;
