import styled from "styled-components";
import { Button, Menu } from "antd";
import { Row, Input, Card, Avatar } from "antd";
import { UserAvatar } from "../../ui/Avatar";

// RELATIONSHIP FILE STYLED START

export const ItemPerPageContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemPerPageTrigger = styled.div`
  width: 95px;
  height: 48px;
  background: #f6f7f9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 15px;

  svg {
    color: #949494;
  }

  @media (max-width: 768px) {
    margin-right: 20px;
    width: 75px;
  }
  @media (max-width: 575px) {
    margin: 0 0 0 5px;
    width: 65px;
    height: 36px;
  }
`;

export const ItemPerPageText = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #949494;
`;

export const Label = styled.span`
  white-space: nowrap;
`;

export const AddNewRelation = styled.div`
  display: inline-block;
  margin-right: 22px;
  margin-left: 10px;

  .plus_icon {
    font-size: 34px;
  }

  @media (max-width: 991.98px) {
    display: none;
  }
`;

export const StyleLdSortingWrapper = styled.div`
  float: right;
  display: flex;
  align-items: center;

  /* flex-wrap: wrap; */

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #232333;
    margin-right: 15px;
    @media (max-width: 767px) {
      margin-right: 5px;
    }
  }

  .ant-select {
    margin-right: 30.72px;
    min-width: 95px;
    @media (max-width: 767px) {
      margin-right: 10px;
      min-width: 50px;
    }
  }

  .select_down_arrow {
    font-size: 16px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 48px;
    padding: 8px 11px;
    @media (max-width: 767px) {
      height: 36px;
      padding: 3px 10px;
    }
  }

  .sorting_up_icon {
    margin-right: 20px;
    color: #232333;
    font-size: 20px;
    cursor: pointer;
    @media (max-width: 767px) {
      margin-right: 5px;
    }
  }

  .sorting_down_icon {
    color: #232333;
    font-size: 20px;
    cursor: pointer;
  }

  @media (max-width: 767.98px) {
    label {
      font-size: 12px;
    }
    margin-top: 20px;
  }
  @media (max-width: 451px) {
    /* margin-top: 10px; */
  }
`;

export const StyledItemPageSelect = styled.div`
  /* @media (max-width: 575px) {
    display: none;
  } */
`;
export const StyleTabWrapper = styled.div`
  @media (max-width: 767px) {
    .ant-tabs-nav {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
`;

export const StyledSortingIconWrapper = styled.div`
  display: inline-block;
`;

export const StyleLayout = styled.div`
  float: left;
  width: 100%;
  background-color: ${(props) => props.theme.colors.third};
`;

export const StyleBreadCrumbsWrapper = styled.div`
  display: inline-block;
  width: 100%;
  /* margin-top: 17px; */
  border-bottom: 1px solid #f0f0f0;
  padding: 17px 30px 17px 35px;
  background-color: ${(props) => props.theme.colors.third};
  /* margin-bottom: 20px; */
  /* margin-bottom: 40px; */
  .breadcrumbs {
    float: left;
  }
  .breadcrumbsLink {
    margin-left: -3px;
    margin-right: -3px;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: 575.98px) {
    padding: 0px 15px 17px 15px;

    .breadcrumbsLink {
      font-size: 12px;
    }

    .ant-breadcrumb > span:last-child {
      font-size: 12px;
    }
  }
`;

export const StyledUploadAvtarWrapper = styled.div``;
export const FloatButton = styled.div`
  display: none;

  .plus_icon {
    font-size: 34px;
  }

  @media (max-width: 991.98px) {
    display: block;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }
`;

export const StyledTabButtonWrap = styled.div`
  display: inline-block;
  width: 100%;

  .ant-tabs {
    overflow: unset;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 95px;
    padding: 0 30px;
  }

  .ant-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: "tnum", "tnum";
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1;
    display: block;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    max-width: 476px;
    width: 100%;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 50px;
    justify-content: center;

    @media (max-width: 767px) {
      height: 36px;
      max-width: 340px;
      min-width: 264px;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 46px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: unset;
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #949494;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-tabs-ink-bar {
    height: 2px;
    background: ${(props) => props.theme.colors.primary} !important;
    border-radius: 50px;
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      max-width: 362px;
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 24px;
    }

    .ant-tabs-tab-btn {
      font-size: 14px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: 14px;
    }
  }

  @media (max-width: 991.98px) {
    .ant-tabs-top > .ant-tabs-nav {
      display: flex;
      // flex-flow: column;
      // justify-content: center;
      // align-items: flex-end;
      margin-bottom: 80px;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      justify-content: space-evenly;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
      // margin-bottom: 35px;

      width: 100%;
      // justify-content: center;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 14px;
    }
  }

  @media (max-width: 767px) {
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }

    .ant-tabs-tab-btn {
      font-weight: normal;
      font-size: 12px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: normal;
      font-size: 12px;
    }

    .ant-tabs-top > .ant-tabs-nav {
      padding: 0 15px;
    }
  }
  @media (max-width: 451px) {
    .ant-tabs-top > .ant-tabs-nav {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
// RELATIONSHIP FILE STYLED END

// ADD-RELATIONSHIP-MODAL FILE STYLED START
export const StyledContentTitle = styled.h3`
  font-weight: bold;
  font-size: 34px;
  line-height: 28px;
  color: #232333;
  text-align: center;
  margin-top: 39px;
  margin-bottom: 40px;
  width: 100%;

  @media (max-width: 575.98px) {
    font-size: 20px;
  }
`;

export const StyledFormControl = styled.div`
  margin: 0px 45px;
  .ant-form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }

  .custom-textbox {
    display: flex;
    flex-direction: column;
    .ant-form-item-label {
      align-self: flex-start;
    }
  }

  @media (max-width: 575.98px) {
    margin: 0;
  }
`;

export const StyledFormItem = styled.div`
  @media (max-width: 991.98px) {
    width: 100%;
  }
`;

export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0 36px 0;
`;

// ADD-RELATIONSHIP-MODAL FILE STYLED END

// RELATIONSHIP-CONTENT FILE STYLED START
export const StyleCardDropdownWrapper = styled.div`
  float: left;
  z-index: 1;
  .ant-menu-root.ant-menu-vertical {
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
    margin-left: -90px;
    width: 140px;
    border: 1px solid #e5e5e5;
  }
  .ant-menu {
    border-radius: 5px;
  }

  .ant-menu-item a {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #747487;
  }

  .ant-menu-item a:hover {
    color: ${(props) => props.theme.colors.primary};
    transition: background 0.3s ease;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }
`;

export const StyledWrapper = styled.div`
  float: left;
  width: 100%;
  padding-top: 82px;
  background-color: ${(props) => props.theme.colors.third};
  min-height: calc(100vh - 250px);

  .setPulse {
    animation: pulse 2s infinite;
  }

  .view_activity_bg_class {
    background: #f2f2f2;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.06);
  }

  .touched_lock_class {
    background: #f2f2f2;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.04);
  }

  .card_wrap {
    padding: 0 30px 95px 30px;
  }

  @media (max-width: 575.98px) {
    .card_wrap {
      padding: 0 15px 95px 15px;
    }
  }
`;

export const StyledListBox = styled.section`
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  display: inline-block;
  min-height: 608px;
  max-height: 608px;
  width: 100%;

  .view_move_to_bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    padding: 20px;
    .ant-row-middle {
      align-items: flex-start;
      padding-top: 10px;
    }
  }

  .show_activity_left_arrow {
    position: absolute;
    //right: 0;
    left: 0;
    padding-left: 20px;
  }

  :last-child {
    margin-right: 0;
  }
  .ant-menu {
    left: 479px !important;
  }
`;

export const StyledImg = styled.div`
  position: absolute;
  top: -55px;
  left: 0;
  right: 0;
  width: 82px;
  margin: 0 auto;
  z-index: 11;
  img {
    display: inline-block;
  }
`;

export const OptionMenu = styled(Menu)`
  background: #fff;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 35%);
  min-height: 70px;
  min-width: 95px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

export const OptionMenuItem = styled(Menu.Item)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  background-color: #ffffff;

  &.ant-menu-item-active {
    color: #666666;
  }

  &.ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.primary};
    background-color: #ffffff !important;
  }
  &.ant-menu-item {
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      background-color: #ffffff;
    }
  }
`;

export const StyledCloseButton = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  padding-right: 20px;

  .card_close_icon {
    font-size: 30px;
    cursor: pointer;
    color: #747487;
    line-height: 34px;
  }
`;

export const StyledOpenMenuWrapper = styled.div`
  // display: flex;
  // justify-content: end;
  // width: 100%;
  position: absolute;
  // z-index: 1;
  right: 0;
  padding-right: 20px;

  .three_dots_vertical {
    font-size: 34px;
    line-height: 40px;
    color: #747487;
  }

  .three_dots_vertical:hover {
    color: ${(props) => props.theme.colors.primary};
    background: -webkit-linear-gradient(
      ${(props) => props.theme.colors.primary},
      ${(props) => props.theme.colors.primary2}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background 0.3s ease;
  }
`;

export const StyledListContentDescription = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  // margin-top: 10px;
  width: 100%;

  .locked_text_blur {
    filter: blur(8px);
    -webkit-filter: blur(8px);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
`;

export const StyledContactDescriptionWrapper = styled.div`
  // display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  width: 100%;
  height: ${({ height }) => height || "165px"};
  /* height: 120px; */
  text-align: center;
  // align-items: center;
  //   margin-bottom: 6px;
  //   width: 100%;
  //   text-align: center;

  padding-top: 50px;

  .reguler_text {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #232333;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .blur_reguler_text {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #232333;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    filter: blur(8px);
    -webkit-filter: blur(8px);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  /* .reguler_uppercase_text {
    font-weight: bold;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    color: #232333;
    text-transform: uppercase;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  } */

  .reguler_location_text {
    /* font-weight: bold; */
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #747487;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .reguler_location_blur_text {
    /* font-weight: bold; */
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #747487;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    filter: blur(8px);
    -webkit-filter: blur(8px);
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .source_name {
    // margin-bottom: 43px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #747487;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StyledTimeAndImageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  margin-bottom: 71px;
  margin-top: 76px;
  width: 100%;

  h5 {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #747487;
  }

  h3 {
    font-weight: 900;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.04em;
    background: -webkit-linear-gradient(
      ${(props) => props.theme.colors.primary},
      ${(props) => props.theme.colors.primary2}
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #747487;
  }
`;

export const StyleImageWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -36px;
  text-align: center;
`;

export const ConnectButtonWrapper = styled.div`
  float: left;
  margin: 0 auto;
  // width: 180px;
  height: 58px;
  .ant-btn {
    border: none;
  }

  .earned_button {
    background: linear-gradient(142.07deg, #5bfcb9 13.94%, #1bad70 86.39%);
    box-shadow: 0px 10px 12px rgba(27, 173, 112, 0.35);
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 12px;
    border-radius: 5px;
    /* width: 230px;
    height: 58px; */
    padding: 28px 28px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .earned_button .earned_lock_button {
    margin-right: 10px;
    font-size: 27px;
    transform: rotateY(180deg);
  }

  .claimed_button {
    background: linear-gradient(98.7deg, #d3d6dd 9.29%, #a0a5af 89.16%);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    /* width: 170px;
    height: 58px; */
    padding: 28px 39px;
    font-weight: 900;
    font-size: 18px;
    line-height: 12px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .claimed_button .claimed_lock_button {
    margin-right: 10px;
    font-size: 18px;
  }

  .claimed_button:hover {
    background: linear-gradient(98.7deg, #d3d6dd 9.29%, #a0a5af 89.16%);
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
    color: white;
  }
`;

export const StyledShowContact = styled.div`
  height: 100%;
  width: 225px;
  margin: auto;
  @media (max-width: 767px) {
    width: 185px;
  }
  /* .connect_button_icon_wrap{
    display: flex;
    flex-flow: column;
  }
  
  .connect_button_text_wrap {
    display: flex;
    flex-flow: column;
  }

  .connect_button_text_wrap span {
    font-weight: 800;
    font-size: 24px;
    line-height: 12px;
    color: #232333;
    margin-bottom: 25px;
  } */
`;

export const StyledViewActivityWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: 22px;
  max-height: 455px;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 10px;
  }
`;

export const StyledShowActivity = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 15px;
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #747487;
  }

  .activity_name_call_info {
    // display: flex;
  }

  .activity_name_call_info h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #232333;
    margin-right: 5px;
  }

  .user_details {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #747487;
     margin: 0 0 0px;


  }

  .user_details span {
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: #232333;
  }

  .create_duration_wrapper {
    display: flex;
  }

  .create_duration_wrapper span {
    font-weight: normal;
    font-style: italic;
    font-size: 16px;
    line-height: 28px;
    color: #747487;
    margin: 0 5px;
  }

  .create_duration {
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #747487;
  }

  // .activity_name_call_info span {
  //   font-weight: normal;
  //   font-size: 18px;
  //   line-height: 28px;
  //   color: #747487;
  // }

  @media (max-width: 767.98px) {
    // .activity_name_call_info span {
    //   font-size: 1em;
    // }

    .activity_name_call_info h4 {
      font-size: 1em;
    }

    p {
      font-size: 1em;
    }
  }
`;

export const StyledMoveToULDetail = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  list-style-type: none;
  width: 100%;
  height: 472px;
  overflow: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 10px;
  }

  li {
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    color: #232333;
    margin-bottom: 38px;
    cursor: pointer;
  }

  li:hover {
    color: ${(props) => props.theme.colors.primary};
    transition: background 0.3s ease;
  }

  @media (max-width: 767.98px) {
    li {
      font-size: 20px;
    }
  }
`;
// RELATIONSHIP-CONTENT FILE STYLED END

// CONNECTION BUTTON FILE STYLED START
export const ConnectButtonWrap = styled.div`
  .ant-btn {
    border: none;
  }

  .connect_button {
    background: linear-gradient(
      276.7deg,
      ${(props) => props.theme.colors.secondary} 0%,
      ${(props) => props.theme.colors.secondary} 100%
    );

    box-shadow: ${(props) => props.theme.colors.secondary2};
    animation: pulse 2s infinite;

    font-weight: 900;
    font-size: 18px;
    font-style: normal;
    border-radius: 5px;
    /* width: 180px;
    height: 58px; */
    padding: 28px 39px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 ${(props) => props.theme.colors.secondary2};
      box-shadow: 0 0 0 0 ${(props) => props.theme.colors.secondary2};
    }
    70% {
      -moz-box-shadow: 0 0 0 10px ${(props) => props.theme.colors.secondary3};
      box-shadow: 0 0 0 10px ${(props) => props.theme.colors.secondary3};
    }
    100% {
      -moz-box-shadow: 0 0 0 0 ${(props) => props.theme.colors.secondary3};
      box-shadow: 0 0 0 0 ${(props) => props.theme.colors.secondary3};
    }
  }

  .connect_button .connect_lock_button {
    margin-right: 10px;
    font-size: 18px;
  }
`;
// CONNECTION BUTTON FILE STYLED END

// contact styles

export const ContactRow = styled(Row)`
  margin-bottom: 20px;
  align-items: center !important;

  :hover button {
    background: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  :hover button {
    svg {
      color: #ffffff;
    }
  }

  :hover span {
    color: ${({ theme }) => theme.colors.secondary};
  }

  .contact_text_icon path {
    fill: ${({ theme }) => theme.colors.secondary};
  }
  :hover .contact_text_icon path {
    fill: #ffffff;
  }
`;

export const ContactButtonStyle = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-color: #ffffff;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  svg {
    font-size: 25px !important;
    color: ${({ theme }) => theme.colors.secondary};
    @media (max-width: 767px) {
      font-size: 20px !important;
    }
  }
  &.ant-btn-lg {
    &.ant-btn-circle {
      height: 68px;
      width: 68px;
      @media (max-width: 767px) {
        height: 48px;
        width: 48px;
      }
    }
    svg {
      font-size: 25px !important;
      color: ${({ theme }) => theme.colors.secondary};
      @media (max-width: 767px) {
        font-size: 20px !important;
      }
    }
  }

  // :hover {
  //   svg {
  //     color: #ffffff;
  //   }
  // }

  // :hover {
  //   background: ${({ theme }) => theme.colors.secondary};
  // }
`;

export const ContactText = styled.span`
  font-weight: 800;
  font-size: 24px;
  line-height: 12px;
  color: #232333;
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 20px;
  }
`;

// AssignContact styled start
export const InputWrapper = styled(Input)`
  border: none;
  background: #f6f7f9;
  min-height: 48px;
  border-radius: 5px;
  margin-bottom: 40px;
  /* padding: 13px 11px; */

  .ant-input-affix-wrapper {
    &:focus {
      box-shadow: none !important;
    }
  }

  input {
    &.ant-input {
      background: #f6f7f9;
      color: #949494;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      padding-left: 7px;
      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .ant-input-prefix {
    svg {
      font-size: 18px;
      color: #d3d6dd;
    }
  }
`;

export const CardWrapper = styled.div`
  /* position: relative; */
  width: 100%;
  display: inline-block;
  text-align: center;
`;

export const UserAvatarWrapper = styled(UserAvatar)`
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledCardHeaderWrapper = styled.div`
  display: flex;
  min-height: 60px;
  /* justify-content: space-between; */
`;

export const AvatarWrapper = styled.div`
  /* margin-left: 1px; */
  display: flex;
  position: absolute;
  z-index: 1;
  left: 0;
  padding-left: 20px;
`;

export const StyledAvatarGroupAllWrapper = styled.div`
  display: flex;
  min-height: 60px;
`;

export const StyledAvatarGroupWrapper = styled.div`
  display: flex;
  .ant-avatar-group:first-child {
    margin-left: 0px;
  }
`;

export const StyledAvatarGroup = styled(Avatar.Group)`
  margin-left: -10px;
  z-index: 1;
`;

// export const AvatarWrapper = styled(Avatar)`
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: -50%;
//   margin: 0 auto;
//   z-index: 1;

// outline: 1px solid
//   ${({ selectColor, theme }) =>
//     selectColor ? `${theme.colors.primary}` : "transparent"};

//   outline-offset: -1px;
// `;

export const AssignCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid
    ${({ selectColor, theme }) =>
    selectColor ? `${theme.colors.primary}` : "#e5e5e5"};
  height: 168px;
  background: #ffffff;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  /* position: relative; */

  .ant-card-body {
    word-break: break-word;
    white-space: normal;
    padding: 0;
    /* padding: 0px 15px 20px; */
  }

  h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #232333;
  }
`;

// AssignContact styled end

export const AnimationCard = styled.div`
  margin: -70px;
`;
