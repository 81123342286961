import styled from "styled-components";
import { Card } from "../../ui/Card";
import { Row, Image } from "antd";

export const PlayListCardWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: 30px;
  width: 40%;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const StyledVideoIconWrapper = styled.div`
  position: absolute;
  top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    
    align-items: center;
  svg {
    font-size: 28px;
    color: white;
  }
`;

export const StyledVideoCard = styled(Card)`
  /* display: flex; */
  display: inline-block;
  padding: 10px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-height: 175px;
  outline: ${({ outline, theme }) => (outline && `2px solid ${theme.colors.primary}`) || "none"};
`;

export const PlayListCardWrapperRow = styled(Row)`
.video-react {
    width: 100% !important;
    border-radius: 5px;
    height: 155px !important;
  }
  .video-react-video {
    border-radius: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    
  }


  
  .video-react.video-react-fullscreen  .video-react-video {
    object-fit: contain;
  }
`

export const StyledPlayerWarpper = styled.div`

  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;

  .ant-image {
  position: absolute;
  top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}



.ant-image img {
    height: 100%;
    object-fit: cover;
}


 /* width: 100%;
height: 100%;
position: relative; */

/* .ant-image {
    width: 100%;

    height: 100%;
    background-size: cover;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
} */

/* .react-thumbnail-generator {
  display: inline-block;
  width: 100%;
    height: 100%;
} */

/* .react-thumbnail-generator img {
  width: 100%;
  height: 100%;
} */
`
export const StyledImage = styled(Image)``

export const StyledVideoTimer = styled.div`
position: absolute;
right: 10px;
bottom: 10px;
background-color: ${({ theme }) => theme.colors.primary};
min-height: 20px;
border-radius: 4px;
max-width: 38px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;

span {
  color: white;
  font-size: 12px;
  font-weight: 700;
}
`

export const StyledImageWrapper = styled.div`
  display: flex;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  width: 40%;
  /* height: 100%; */
  height: 165px;
  max-width: 244px;
  /* max-width: 180px; */

  .ant-image {
    width: 100%;

    height: 100%;
    background-size: cover;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    /* background-image: url(/images/videos/video1.png); */
    background-image: url(${({ sr }) => sr});
    border-radius: 10px;
  }
  .ant-image-img {
    border-radius: 10px;
  }
`;

export const StyledVideoContentWrapper = styled.div`
  display: flex;
  flex-flow: column;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: ${({ active, theme }) => (active && `${theme.colors.primary}`) || "#232333"} ;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    margin-top: 0px;
  }

  .avatar_wrap {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
    min-height: 14px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .user_upload {
    display: flex;
    align-items: center;
    gap: 5px;

    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #747487;
  }

  .user_name {
    padding-left: 10px;
  }

`;



export const PlayListVideoWrapper = styled.div`
display: inline-block;
width: 100%;
`;

export const SliderBar = styled.div`
  width: ${({ wd }) => wd || 40}%;

  margin-bottom: ${({ marginBottom }) => marginBottom || "0px"};

  .ant-slider {
    color: white;
    margin: ${({ mr }) => mr || "10px 0px 10px 0px"};
  }
  span {
    font-size: 12px;
    color: #747487;
    font-weight: 400;
  }

  .slider-text {
    /* margin-left: calc(${({ ml }) => ml * 0.8}%); */
    margin-left: calc(${({ ml, pr }) => ml * pr || ml * 0.8}%);
  }
  .ant-slider-rail {
    height: 7px;
  }
  .ant-slider-step {
    background: #e4e4ed;
  }

  .ant-slider-track {
    z-index: 7;
    height: 7px;
    background: linear-gradient(
      276.7deg,
      ${({ theme }) => theme.colors.primary} 100%,
      ${({ theme }) => theme.colors.primary2} 100%
    );
  }
  .ant-slider-handle {
    width: 15px;
    height: 15px;
    margin-top:-5px;
    margin-left:7px;
    border: solid 1px ${({ theme }) => theme.colors.primary};
    background: linear-gradient(
      276.7deg,
      ${({ theme }) => theme.colors.primary} 100%,
      ${({ theme }) => theme.colors.primary2} 100%
    );
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  .ant-slider-handle .ant-tooltip-open {
    border-color: ${({ theme }) => theme.colors.primary2};
  }
  .ant-slider-handle:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 1260px) {
    .slider-text {
      margin-left: calc(${({ ml }) => ml * 0.72}%);
    }
  }
  @media (max-width: 375px) {
    .slider-text {
      margin-left: calc(${({ ml }) => ml * 0.65}%);
    }
  }
`;

export const WelcomeComo = styled.div`
display: inline-block;
width: 100%;


  h2 {
    font-size: 24px;
    color: #232333;
    font-weight: 700;
    margin-top: 0px;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;


export const WelcomeComoMobile = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: contents;
  }
`;
