import { Input, Menu, Table } from "antd";
import styled from "styled-components";

export const DatatableHeader = styled.div`
  max-width: 100%;
  padding: 32px 0;
  border-radius: 10px 10px 0px 0px;
  @media (max-width: 375px) {
    .ant-row {
      flex-direction: column-reverse;
      .ant-col {
        max-width: 100%;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0;
    .ant-row {
      display: block !important;
      .ant-col-md-order-1 {
        max-width: 100%;
      }
    }
  }
`;
export const DatatableHeaderLeft = styled.div`
  display: flex;

  @media (max-width: 767px) {
    padding: 16px 0;
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 575px) {
    // flex-direction: column;
    > * {
      width: 100% !important;
      // justify-content: flex-end;
      padding: 0;
      margin-bottom: 4px;
      justify-content: end;
    }
  }
  @media (max-width: 425px) {
    flex-direction: row;
    max-width: 100%;
    padding-top: 16px;
    padding-bottom: 8px;
  }
  @media (max-width: 375px) {
    flex-direction: row;
    padding: 8px 0;
  }
`;
export const DatatableHeaderRight = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 767px) {
    padding: 16px 0;
  }
  @media (max-width: 375px) {
    padding: 8px 0;
  }
`;

export const Label = styled.span`
  white-space: nowrap;
  @media (max-width: 575px) {
    display: none;
  }
`;

export const Search = styled(Input)`
  width: 351px;
  border: none;
  background: #f6f7f9;
  padding: 13px 11px;
  border-radius: 5px;
  margin-right: 40px;
  .ant-input-affix-wrapper {
    &:focus {
      box-shadow: none !important;
    }
  }
  input {
    &.ant-input {
      background: #f6f7f9;
      color: #949494;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      padding-left: 7px;
      &:focus {
        box-shadow: none !important;
      }
    }
  }
  .ant-input-prefix {
    svg {
      font-size: 18px;
      color: #d3d6dd;
    }
  }
  @media (max-width: 767px) {
    margin-right: 20px;
  }
  @media (max-width: 575px) {
    margin-right: 5px;
    /* height: 36px; */
    padding: 0 8px;
  }
`;

export const ItemPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const ItemPerPageTrigger = styled.div`
  width: ${({ width }) => (width ? width : "95px")};
  height: ${({ height }) => (height ? height : "48px")};
  background-color: ${({ backgrounds }) =>
    backgrounds ? backgrounds : "#f6f7f9"};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "5px")};
  box-shadow: ${({ boxShadow }) => boxShadow || "0px 4px 10px rgb(0 0 0 / 7%)"};
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 15px;
  padding: ${({ padding }) => (padding ? padding : "0px")};

  svg {
    color: #949494;
  }

  /* @media (max-width: 767px) {
    margin-right: 20px;
    width: 75px;
  } */

  /* @media (max-width: 575px) {
    margin: 0 0 0 5px;
    width: 65px;
    height: 36px;
  } */
`;
export const ItemPerPageText = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #949494;
`;

export const OptionMenu = styled(Menu)`
  background: #fff;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 35%);
  min-height: 70px;
  min-width: 95px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
`;

export const OptionMenuItem = styled(Menu.Item)`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  background-color: #ffffff;

  &.ant-menu-item-active {
    color: #666666;
  }

  &.ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.primary};
    background-color: #ffffff !important;
  }
  &.ant-menu-item {
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      background-color: #ffffff;
    }
  }
`;

export const AntTable = styled(Table)`
  .ant-table {
    color: #747487;
    font-size: 14px;
    .ant-table-container {
      .ant-table-content {
        border-radius: 5px 5px 0px 0px !important;
        .ant-table-thead {
          background: #949494 !important;
          border-radius: 5px 5px 0px 0px !important;
          .ant-table-cell {
            background: #949494 !important;
            // padding: 0px 16px;
            font-weight: bold;
            font-size: 14px;
            line-height: 28px;
            color: #ffffff;

            &.ant-table-column-sort {
              background: transparent;
            }
          }
          .ant-table-tbody {
          }
        }
      }
    }
  }

  .ant-pagination-options {
    display: none;
  }

  .ant-pagination.mini .ant-pagination-jump-next {
    margin-right: 8px;
  }

  .ant-pagination-item-container {
    width: 100%;
  }

  .ant-pagination {
    li {
      width: 48px !important;
      height: 48px !important;
      background: #ffffff;
      border: 1px solid #f0f0f0;
      box-sizing: border-box;
      box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
      border-radius: 50%;
      border: 1px solid #f0f0f0;
      &.ant-pagination-prev {
        margin-right: 8px;
      }
      &.ant-pagination-item {
        margin-right: 8px;
        &.ant-pagination-item-active {
          background: linear-gradient(
            276.7deg,
            ${({ theme }) => theme.colors.secondary} 0%,
            ${({ theme }) => theme.colors.secondary} 100%
          );
          box-shadow: 0px 10px 15px ${({ theme }) => theme.colors.secondary}59;
          border: none;
          a {
            color: #ffffff;
          }
        }
      }
      .ant-pagination-item-link,
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: normal;
        font-size: 14px;
        color: #949494;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: 0;
      }
    }
  }
`;
