import React, { Component } from "react";
// import { Image, Grid, Row, Col } from "react-bootstrap";
import Modal from "./Modal";
import PropTypes from "prop-types";
import { Player, BigPlayButton } from "video-react";
import { VideoPlayerWrapper, VideoPlayerCol } from "./style";
import { randomNumber } from "utils/fn";
import { Row, Col } from "antd";

export class VideoPlayer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <VideoPlayerWrapper>
                <Player
                    videoId={"video-" + randomNumber(1, 9999)}
                    fluid={this.props?.fluid || false}
                    src={this.props?.src && this.props?.src}
                // src={"https://media.w3.org/2010/05/sintel/trailer_hd.mp4"}
                >
                    {/* {this.props?.children} */}
                </Player>
            </VideoPlayerWrapper>
        );
    }
}

class Images extends Component {
    static defaultProps = {
        images: [],
        previewImages: [],
        hideOverlay: false,
        renderOverlay: () => "Preview Image",
        overlayBackgroundColor: "#222222",
        onClickEach: null,
        countFrom: 5,
    };

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            countFrom:
                props.countFrom > 0 && props.countFrom < 5 ? props.countFrom : 5,
            conditionalRender: false,
        };

        this.openModal = this.openModal.bind(this);
        this.onClose = this.onClose.bind(this);

        if (props.countFrom <= 0 || props.countFrom > 5) {
            console.warn("countFrom is limited to 5!");
        }
    }

    openModal(index) {
        const { onClickEach, images, previewImages } = this.props;

        if (onClickEach) {
            return onClickEach({ src: images[index], index });
        }

        this.setState({ modal: true, url: images[index], index });
    }

    onClose() {
        this.setState({ modal: false });
    }

    renderOne() {
        const { images } = this.props;
        const { countFrom } = this.state;
        const overlay =
            images.length > countFrom && countFrom == 1
                ? this.renderCountOverlay(true)
                : this.renderOverlay();

        return (
            <>
                <Row gutter={[3, 3]}>
                    {typeof images[0] === "string" ? (
                        <Col
                            span={24}
                            className={`border height-one background`}
                            onClick={this.openModal.bind(this, 0)}
                            style={{ background: `url(${images[0]})` }}
                        >
                            {overlay}
                        </Col>
                    ) : (
                        <Col span={24} onClick={this.openModal.bind(this, 0)}>
                            <VideoPlayer fluid={false} src={images[0]["url"]}>
                                <BigPlayButton position="center" />
                            </VideoPlayer>
                            {overlay}
                        </Col>
                    )}
                </Row>
            </>
        );
    }

    renderTwo() {
        const { images } = this.props;
        const { countFrom } = this.state;
        const overlay =
            images.length > countFrom && [2, 3].includes(+countFrom)
                ? this.renderCountOverlay(true)
                : this.renderOverlay();
        const conditionalRender =
            [3, 4].includes(images.length) ||
            (images.length > +countFrom && [3, 4].includes(+countFrom));

        return (
            <>
                <Row gutter={[3, 3]}>
                    {conditionalRender && typeof images[1] === "string" && (
                        <Col
                            span={12}
                            className="border height-two background"
                            onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
                            style={{
                                background: `url(${conditionalRender ? images[1] : images[0]})`,
                            }}
                        >
                            {this.renderOverlay()}
                        </Col>
                    )}
                    {conditionalRender && typeof images[1] !== "string" && (
                        <Col
                            span={12}
                            onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
                        >
                            <VideoPlayer fluid={false} src={images[1]["url"]}>
                                {/* <source src={images[1]["url"]} type={images[1]["mimetype"]} /> */}
                                <BigPlayButton position="center" />
                            </VideoPlayer>

                            {this.renderOverlay()}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[0] === "string" && (
                        <Col
                            span={12}
                            className="border height-two background"
                            onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
                            style={{
                                background: `url(${conditionalRender ? images[1] : images[0]})`,
                            }}
                        >
                            {this.renderOverlay()}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[0] !== "string" && (
                        <Col
                            span={12}
                            onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
                        >
                            <VideoPlayer fluid={false} src={images[0]["url"]}>
                                {/* <source src={images[0]["url"]} type={images[0]["mimetype"]} />{" "} */}
                                <BigPlayButton position="center" />
                            </VideoPlayer>
                            {this.renderOverlay()}
                        </Col>
                    )}

                    {conditionalRender && typeof images[2] === "string" && (
                        <Col
                            span={12}
                            className="border height-two background"
                            onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
                            style={{
                                background: `url(${conditionalRender ? images[2] : images[1]})`,
                            }}
                        >
                            {overlay}
                        </Col>
                    )}
                    {conditionalRender && typeof images[2] !== "string" && (
                        <Col
                            span={12}
                            onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
                        >
                            <VideoPlayer fluid={false} src={images[2]["url"]}>
                                <BigPlayButton position="center" />
                            </VideoPlayer>

                            {overlay}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[1] === "string" && (
                        <Col
                            span={12}
                            className="border height-two background"
                            onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
                            style={{
                                background: `url(${conditionalRender ? images[2] : images[1]})`,
                            }}
                        >
                            {overlay}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[1] !== "string" && (
                        <Col
                            span={12}
                            // className="border height-two background"
                            onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
                        >
                            <VideoPlayer fluid={false} src={images[1]["url"]}>
                                {/* <source src={images[1]["url"]} type={images[1]["mimetype"]} /> */}
                                <BigPlayButton position="center" />
                            </VideoPlayer>

                            {overlay}
                        </Col>
                    )}
                </Row>
            </>
        );
    }

    renderThree(more) {
        const { images } = this.props;
        const { countFrom } = this.state;
        const conditionalRender =
            images.length == 4 || (images.length > +countFrom && +countFrom == 4);
        const overlay =
            !countFrom ||
                countFrom > 5 ||
                (images.length > countFrom && [4, 5].includes(+countFrom))
                ? this.renderCountOverlay(true)
                : this.renderOverlay(conditionalRender ? 3 : 4);

        return (
            <>
                <Row gutter={[3, 3]}>
                    {conditionalRender && typeof images[1] === "string" && (
                        <Col
                            span={8}
                            className="border height-three background"
                            onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
                            style={{
                                background: `url(${conditionalRender ? images[1] : images[2]})`,
                            }}
                        >
                            {this.renderOverlay(conditionalRender ? 1 : 2)}
                        </Col>
                    )}
                    {conditionalRender && typeof images[1] !== "string" && (
                        <Col
                            span={8}
                            // className="border height-two background"
                            onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
                        >
                            <VideoPlayer fluid={false} src={images[1]["url"]}>
                                {/* <source src={images[1]["url"]} type={images[1]["mimetype"]} /> */}
                                <BigPlayButton position="center" />
                            </VideoPlayer>

                            {this.renderOverlay(conditionalRender ? 1 : 2)}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[2] === "string" && (
                        <Col
                            span={8}
                            className="border height-three background"
                            onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
                            style={{
                                background: `url(${conditionalRender ? images[1] : images[2]})`,
                            }}
                        >
                            {this.renderOverlay(conditionalRender ? 1 : 2)}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[2] !== "string" && (
                        <Col
                            span={8}
                            onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
                        >
                            {this.renderOverlay(conditionalRender ? 1 : 2)}

                            <VideoPlayer fluid={false} src={images[2]["url"]}>
                                {/* <source src={images[1]["url"]} type={images[2]["mimetype"]} /> */}
                                <BigPlayButton position="center" />
                            </VideoPlayer>

                            {this.renderOverlay(conditionalRender ? 1 : 2)}
                        </Col>
                    )}










                    {conditionalRender && typeof images[2] === "string" && (
                        <Col

                            span={8}
                            className="border height-three background"
                            onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
                            style={{
                                background: `url(${conditionalRender ? images[2] : images[3]})`,
                            }}
                        >
                            {this.renderOverlay(conditionalRender ? 2 : 3)}
                        </Col>
                    )}
                    {conditionalRender && typeof images[2] !== "string" && (
                        <Col

                            span={8}
                            onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
                        >
                            <VideoPlayer fluid={false} src={images[2]["url"]}>
                                <BigPlayButton position="center" />
                            </VideoPlayer>

                            {this.renderOverlay(conditionalRender ? 2 : 3)}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[3] === "string" && (
                        <Col

                            span={8}
                            className="border height-three background"
                            onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
                            style={{
                                background: `url(${conditionalRender ? images[2] : images[3]})`,
                            }}
                        >
                            {this.renderOverlay(conditionalRender ? 2 : 3)}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[3] !== "string" && (
                        <Col

                            span={8}
                            onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
                        >
                            <VideoPlayer fluid={false} src={images[3]["url"]}>
                                <BigPlayButton position="center" />
                            </VideoPlayer>

                            {this.renderOverlay(conditionalRender ? 2 : 3)}
                        </Col>
                    )}

                    {conditionalRender && typeof images[3] === "string" && (
                        <Col
                            span={8}

                            className="border height-three background"
                            onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
                            style={{
                                background: `url(${conditionalRender ? images[3] : images[4]})`,
                            }}
                        >
                            {overlay}
                        </Col>
                    )}
                    {conditionalRender && typeof images[3] !== "string" && (
                        <Col

                            span={8}
                            onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
                        >
                            <VideoPlayer fluid={false} src={images[3]["url"]}>
                                <BigPlayButton position="center" />
                            </VideoPlayer>

                            {overlay}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[4] === "string" && (
                        <Col

                            span={8}
                            className="border-three background"
                            onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
                            style={{
                                background: `url(${conditionalRender ? images[3] : images[4]})`,
                            }}
                        >
                            {overlay}
                        </Col>
                    )}
                    {!conditionalRender && typeof images[4] !== "string" && (
                        <Col
                            span={8}

                            onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
                        >
                            <VideoPlayer fluid={false} src={images[4]["url"]}>
                                <BigPlayButton position="center" />
                            </VideoPlayer>

                            {overlay}
                        </Col>
                    )}
                </Row>
            </>
        );
    }

    renderOverlay(id) {
        const { hideOverlay, renderOverlay, overlayBackgroundColor } = this.props;

        if (hideOverlay) {
            return false;
        }

        return [
            <div
                key={`cover-${id}`}
                className="cover slide"
                style={{ backgroundColor: overlayBackgroundColor }}
            ></div>,
            <div
                key={`cover-text-${id}`}
                className="cover-text slide animate-text"
                style={{ fontSize: "100%" }}
            >
                {renderOverlay()}
            </div>,
        ];
    }

    renderCountOverlay(more) {
        const { images } = this.props;
        const { countFrom } = this.state;
        const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);

        return [
            more && <div key="count" className="cover"></div>,
            more && (
                <div
                    key="count-sub"
                    className="cover-text"
                    style={{ fontSize: "200%" }}
                >
                    <p>+{extra}</p>
                </div>
            ),
        ];
    }

    render() {
        const { modal, index, countFrom } = this.state;
        const { images, previewImages } = this.props;
        const imagesToShow = [...images];
        if (countFrom && images.length > countFrom) {
            imagesToShow.length = countFrom;
        }

        return (
            <div className="grid-container">
                {[1, 3, 4].includes(imagesToShow.length) && this.renderOne()}
                {imagesToShow.length >= 2 &&
                    imagesToShow.length != 4 &&
                    this.renderTwo()}
                {imagesToShow.length >= 4 && this.renderThree()}

                {modal && (
                    <Modal onClose={this.onClose} index={index} images={previewImages} />
                )}
            </div>
        );
    }
}

Images.propTypes = {
    images: PropTypes.array.isRequired,
    hideOverlay: PropTypes.bool,
    renderOverlay: PropTypes.func,
    overlayBackgroundColor: PropTypes.string,
    onClickEach: PropTypes.func,
    countFrom: PropTypes.number,
};

export default Images;
