import React, { useState } from "react";
import {
  StyledVideoCardWrapper,
  StyledVideoCard,
  // StyledImageWrapper,
  // StyledVideoContentWrapper,
  StyledVideoIconWrapper,
  // StyledVideoProgressWrapper,
} from "Components/Profile/style";
import { Image, Row, Col, Progress } from "antd";
import { FaPlayCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { adminRoutes } from "helpers/adminRoutes";
import { Slider } from "antd";
import { SliderBar } from "./PlayListVideoSection.style";
import { fallbackImageLite } from "utils/constants";
import dayjs from "dayjs";
import {
  Player,
  BigPlayButton,
  ControlBar,
  ReplayControl,
  ForwardControl,
  VolumeMenuButton,
} from "video-react";
import { imagePath } from "utils/fn";
import PlayList from "./PlayList";
import PlayListPage from "./PlayListPage/PlayListPage";
import { useSelector } from "react-redux";
import {
  StyledVideoCardWrapperRow,
  StyledImageWrapper,
  StyledVideoProgressWrapper,
  StyledVideoTimer,
  StyledVideoContentWrapper,
} from "./VideoSection.style";

const VideoSection = ({ UserProfileVideosData }) => {
  // const [record, setClickRecord] = useState([]);
  const { user, faviconImage } = useSelector((state) => state.user);
  const config = JSON.parse(localStorage.getItem("config"));
  const getTimerData = (data) => {
    if (
      data?.userId &&
      data?.userId.toString() == (localStorage.getItem("userId") || user?._id)
    ) {
      return (
        (parseInt(data?.watchedTime) / parseInt(data.videoTime)) *
        100
      ).toFixed(2);
    }
  };

  const videoPlayTime = (time) => {
    return (
      Math.floor(time / 60) + ":" + ("0" + Math.floor(time % 60)).slice(-2)
    );
  };

  return (
    <StyledVideoCardWrapperRow gutter={[30, 30]}>
      {UserProfileVideosData.map((items, i) => (
        <Col xs={24} sm={12} md={8} lg={8} xl={6}>
          {
            <Link
              to={
                items?.isPartOfPlaylist
                  ? adminRoutes.PLAYLIST.replace(":id", items._id)
                  : adminRoutes.PLAYLISTPAGE.replace(":id", items._id)
              }
            >
              {items?.allVideos?.map(
                (item, i) =>
                  item.current == true && (
                    <StyledVideoCard>
                      <StyledImageWrapper>
                        {/* <Image
                            src={item.image}
                            preview={false}
                            fallback={fallbackImageLite}
                          /> */}

                        <Player
                          fluid={false}
                          src={`${config?.apiUrl}/${item.video.url}`}
                          poster={`${config?.apiUrl}/${item?.thumbnail}`}
                          style={{ paddingTop: "0px" }}
                        >
                          <BigPlayButton position="center" />
                          <ControlBar autoHide={false}>
                            <ReplayControl seconds={5} order={2.1} />
                            <ForwardControl seconds={5} order={3.1} />
                            <VolumeMenuButton />
                            <VolumeMenuButton vertical />
                          </ControlBar>
                        </Player>

                        {item?.videoTimer?.watchedTime && (
                          <StyledVideoTimer>
                            <span>
                              {/* {item?.videoTimer?.watchedTime?.toFixed(2)} */}
                              {videoPlayTime(item?.videoTimer?.watchedTime)}
                            </span>
                          </StyledVideoTimer>
                        )}

                        <StyledVideoProgressWrapper>
                          <SliderBar
                            mr={"10px 7px 10px 0px"}
                            wd={100}
                            ml={
                              item?.watchedUsers?.includes(
                                user?._id || localStorage.getItem("userId")
                              )
                                ? getTimerData(item?.videoTimer)
                                : 0
                            }
                            pr={0.9}
                          >
                            <>
                              <Slider
                                tipFormatter={null}
                                defaultValue={100}
                                value={
                                  item?.watchedUsers?.includes(
                                    user?._id || localStorage.getItem("userId")
                                  )
                                    ? getTimerData(item?.videoTimer)
                                    : 0
                                }
                              />
                              <div className="slider-text">
                                <span>
                                  {item?.watchedUsers?.includes(
                                    user?._id || localStorage.getItem("userId")
                                  )
                                    ? getTimerData(item?.videoTimer)
                                    : 0}
                                  %
                                </span>
                              </div>
                            </>
                          </SliderBar>
                        </StyledVideoProgressWrapper>
                      </StyledImageWrapper>

                      <StyledVideoContentWrapper mh={"0"}>
                        <h3>{item.name}</h3>
                        <h4>
                          {"By "}
                          {item?.uploadedBy?.firstName}{" "}
                          {item?.uploadedBy?.lastName}
                        </h4>

                        <span className="number_video">
                          {items?.numberOfVideos > 1 && (
                            <>
                              {item?.sequence}
                              {"/"}
                              {items?.numberOfVideos}
                            </>
                          )}
                        </span>
                      </StyledVideoContentWrapper>
                    </StyledVideoCard>
                  )
              )}
            </Link>
          }
        </Col>
      ))}
    </StyledVideoCardWrapperRow>
  );
};

export default VideoSection;
