import React from "react";
import { Input, Row } from "antd";
import { Card } from "../Card";
import { FiSearch } from "react-icons/fi";
import styled from "styled-components";
import useDebounce from "utils/hooks/useDebounce";

export const StyledSearchCard = styled(Card)`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : "30px"};
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.06);
`;

export const StyledSearchWrapper = styled.div`
  display: flex;
  align-items: center;

  .search_icon {
    color: #d3d6dd;
    font-size: 20px;
    margin-right: 19.7px;
  }
`;

export const StyledInput = styled(Input)`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "100%")};
  background: #f6f7f9;
  input {
    background: #f6f7f9;
  }
  color: #747487;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  color: #747487;
  min-height: 48px;
  &:hover {
    border-color: #f0f0f0;
  }
  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary}23;
  }
`;

export const SearchBar = ({ marginBottom, onChange, ...props }) => {
  const [searchValue, setSearchValue] = React.useState(null);
  const debouncedSearch = useDebounce(searchValue, 800);
  React.useEffect(() => {
    if (searchValue !== null) onChange(`${debouncedSearch}`.trim());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);
  return (
    <StyledSearchCard marginBottom={marginBottom}>
      <StyledSearchWrapper>
        <Row align="middle" justify="center">
          <FiSearch className="search_icon" />
        </Row>
        <StyledInput
          {...props}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </StyledSearchWrapper>
    </StyledSearchCard>
  );
};
