import React, { useEffect } from "react";
import { Modal } from "antd";
import { IoIosClose } from "react-icons/io";
import { CommentBar } from "ui/CommentBar/CommentBar";
import { useBeforeunload } from "react-beforeunload";

const EditPost = ({
  modalState,
  onCancel = console.log,
  onSubmit = console.log,
  PostData,
  // onFormChange = console.log,
  ...props
}) => {
  const [isFormChange, setIsFormChange] = React.useState(false);

  const onPostUpdateHandler = (
    data,
    cb,
    isFormData = false,
    showMessage = true
  ) => {
    console.log("Edit Post isFormData", isFormData);
    console.log("Edit Post data", data);
    setIsFormChange(false);
    onSubmit(
      data,
      (err, result) => {
        if (!err) {
          if (typeof cb === "function") cb();
          if (isFormData) onCancel(false);
        }
      },
      !isFormData,
      showMessage
    );
  };
  // useEffect(() => {
  //   if (dataChange) {
  //     onFormChange(true);
  //   }
  //   return () => {
  //     console.log("closed");
  //   };
  // }, [dataChange]);
  function fromChanges() {
    setIsFormChange(true);
  }
  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
    } else {
    }
  };

  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });
  return (
    <>
      {
        <Modal
          getContainer={() => document.querySelector("#modal-container")}
          visible={modalState}
          onCancel={
            () =>
              !isFormChange
                ? onCancel(false)
                : openPrompt(
                    "You will lose all changes if you navigate away from this page without saving"
                  )
            // onCancel(false)
          }
          width={"960px"}
          footer={null}
          style={{ borderRadius: "5px" }}
          className="modal_wrapper"
          closeIcon={
            <IoIosClose
              className="modal_close_icon"
              // onClick={() => onCancel(false)}
            />
          }
          maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
          focusTriggerAfterClose={false}
          destroyOnClose={true}
        >
          <CommentBar
            onFormChange={fromChanges}
            placeholder="Write a comment..."
            onSubmitUpdate={onPostUpdateHandler}
            // onSubmit={(val) => console.log(val)}
            // formChange={setFormChange}
            PostData={PostData}
            postText={PostData.text}
            isEdit={true}
          />
        </Modal>
      }
    </>
  );
};

export default EditPost;
