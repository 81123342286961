import { Upload, Badge } from "antd";
import React from "react";
import styled from "styled-components";
import { CameraSvg } from "../../assets/svgs";
import { UserAvatar } from "ui/Avatar";

export const FileUploadAvtar = ({
  children,
  text = "",
  image_src,
  size,
  offset,
  height,
  cameraIcon,
  badgeSize,
  userIcon,
  username = "",
  ...props
}) => {
  const avatarProps = {
    ...(size ? { size: +size + 4 } : {}),
    ...(image_src
      ? { src: image_src }
      : {
          fullName: username,
        }),
  };
  return (
    <StyledFileUpload {...props}>
      {children && children}
      {!children && <p>{text}</p>}

      <StyledBadge
        badgeSize={badgeSize ? badgeSize : "24px"}
        size="default"
        count={
          <div className="badge_style">
            <CameraSvg
              height={cameraIcon ? cameraIcon : "12px"}
              width={cameraIcon ? cameraIcon : "12px"}
            />
          </div>
        }
        offset={offset}
      >
        {/* <Avatar {...avatarProps} /> */}
        <UserAvatar {...avatarProps} />
      </StyledBadge>
    </StyledFileUpload>
  );
};

const StyledFileUpload = styled(Upload)`
  margin-bottom: 20px;
  display: inline-block;

  .ant-avatar {
    /* background: #f6f7f9; */
    display: flex;
    align-items: center;
    justify-content: center;

    /* iOS fix */
    /* background-color: ${(props) => props.theme.colors.primary}; */
    background-color: #cccccc;

    /* .ant-avatar-string {
      z-index: 1;
      font-size: calc(${({ size }) => size || 32}px / 2.3);
    } */

    &:after {
      position: absolute;
      content: "";
      top: 2px;
      left: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background-color: #ffffff;
      border-radius: 50%;
      z-index: 0;
    }
    /* // iOS fix */

    > img {
      border-radius: 50%;
      padding: 2px; // iOS Fix
      z-index: 1; // iOS Fix
    }
  }
`;

const StyledBadge = styled(Badge)`
  .badge_style {
    width: ${({ badgeSize }) => (badgeSize ? badgeSize : "20px")};
    height: ${({ badgeSize }) => (badgeSize ? badgeSize : "20px")};
    background: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2; // iOS Fix
  }
`;
