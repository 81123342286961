import React, { useState, useEffect, useCallback, useRef } from "react";
import { Card } from "../../ui/Card";
import { Datatable } from "../../ui/Datatable";
import { Heading } from "../../ui/Heading";
import { Container } from "../../ui/Layout/Container";
import Actions from "../../ui/Datatable/Actions";
import { ToggleSwitch } from "../../ui/Form";
import { adminRoutes } from "../../helpers/adminRoutes";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { SecondaryFloatButton } from "../../ui/Button";
import { StyledLink } from "./TheSourceList.styles";
import SourceAPI from "api/http/source";
import { useSelector } from "react-redux";

import { paginationObj, querystring, searchObj } from "utils/fn";

const SourceList = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.user);
  const { configData } = useSelector((state) => state.configData);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {
      userId: user?._id || localStorage.getItem("userId"),
    },
    populate: [{ path: "relationships" }],

    sorter: {},
  });

  const getSourceList = useCallback((pagination) => {
    setLoading(true);
    SourceAPI.getSourceList(querystring(paginationObj(pagination)))
      .then(({ data }) => {
        setData(data && data.data);
        setPagination((p) => ({
          ...p,
          total: data.total,
          filter: {
            userId: user?._id || localStorage.getItem("userId"),
          },
          populate: [{ path: "relationships" }],
        }));
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getSourceList(pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSourceList]);

  const handleClick = () => {
    history.push(adminRoutes.LINKCREATOR);
  };
  const handleDelete = (id, item) => {
    SourceAPI.deleteSource(item, { id: item })
      .then(({ data }) => {
        getSourceList(pagination);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const handleStatus = (e, id) => {
    SourceAPI.updateSource(id, { status: e })
      .then(({ data }) => {
        getSourceList(pagination);
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const actionOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        return (
          <ToggleSwitch
            defaultChecked={_}
            onChange={(e) => handleStatus(e, record._id)}
          />
        );
      },
      fixed: "left",
      width: 70,
    },
    {
      title: "Title",
      dataIndex: "source",
      key: "source",
      width: 160,
      sorter: true,
    },
    {
      title: "Source",
      dataIndex: "",
      key: "source",
      width: 100,
      render: (_, record) => {
        return (
          <span>
            <StyledLink
              href={record.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Preview Link
            </StyledLink>
          </span>
        );
      },
    },
    {
      title: "Video Link",
      dataIndex: "videoEmbed",
      key: "videoEmbed",
      sorter: true,
      width: 300,
      render: (_, record) => {
        return (
          <span>
            <StyledLink
              href={record.videoEmbed}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record.videoEmbed}
            </StyledLink>
          </span>
        );
      },
    },
    {
      title: "Visibility",
      dataIndex: "visibilityType",
      key: "visibilityType",
      width: 100,
      sorter: true,
    },
    {
      title: "Relationship",
      dataIndex: "relationships",
      key: "relationships",
      width: 100,
      sorter: false,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "right",
      width: 60,
      render: (_, record) => {
        return (
          <Actions
            options={actionOptions}
            item={record._id}
            onChange={actionHandler}
          />
        );
      },
    },
  ];

  const actionHandler = ({ key, item }) => {
    switch (key) {
      case "edit":
        history.push(adminRoutes.SOURCE_EDIT.replace(":id", item));
        break;
      case "delete":
        handleDelete(":id", item);
        break;

      default:
        break;
    }
  };

  const filterChangeHandler = ({ search, ...page }, filters, sorter) => {
    const filter = searchObj(["source"], search);
    setPagination((p) => ({
      ...p,
      ...page,
      filter: {
        userId: user?._id || localStorage.getItem("userId"),
        source: { $regex: `${search}`, $options: "igm" },
      },
      populate: [{ path: "relationships" }],
      sorter,
    }));
    getSourceList({
      ...pagination,
      ...page,
      filter: {
        userId: user?._id || localStorage.getItem("userId"),
        ...filter,
      },
      populate: [{ path: "relationships" }],

      sorter,
    });
  };

  return (
    <Container padding="19px 30px 100px">
      <Heading>{configData?.[0].module?.[1]?.children?.[0]?.name}</Heading>
      <Card padding="0 15px 15px">
        <Datatable
          columns={columns}
          dataSource={data}
          loading={loading}
          onSearch={filterChangeHandler}
          onChangeItemPerPage={filterChangeHandler}
          headerContentRight={
            <>
              <SecondaryFloatButton
                onClick={handleClick}
                buttonText={configData?.[0].module?.[1]?.children?.[0]?.name}
              />
            </>
          }
          onChange={filterChangeHandler}
          pagination={pagination}
        />
      </Card>
    </Container>
  );
};

export default SourceList;
