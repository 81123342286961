import React from "react";
import { Image, Row, Col } from "antd";
import { Heading } from "../../../ui/Heading";
import { FaPlayCircle } from "react-icons/fa";
import {
  StyledVideoCardWrapper,
  StyledVideoCard,
  StyledImageWrapper,
  StyledVideoContentWrapper,
  StyledVideoIconWrapper,
  StyledVideoProgressWrapper,
  HeadingWrapper,
} from "../style";
import {
  StyledAddButton,
  StyledHeaderTextwrapper,
} from "Components/Team/FindTeam/style";
import { HiPlus } from "react-icons/hi";
import { SecondaryButton } from "ui/Button/Button";
import { FloatButton } from "Components/Relationship/TheRelationship.styles";

const VideoSection = ({ UserProfileVideosData }) => {
  return (
    <StyledVideoCardWrapper>
      <HeadingWrapper>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
          onClick={() => console.log("Video add")}
          height={"52px"}
          width={"52px"}
        />
        <Heading fontSize={"24px"} color={"#232333"} marginBottom={"0px"}>
          Videos
        </Heading>
      </HeadingWrapper>
      <Row gutter={[30, 30]}>
        {UserProfileVideosData.map((item) => (
          <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
            <StyledVideoCard>
              <StyledImageWrapper>
                <Image src={item.image} preview={false} />
                <StyledVideoIconWrapper>
                  <FaPlayCircle />
                </StyledVideoIconWrapper>
                <StyledVideoProgressWrapper>
                  <Image src={item.barImage} preview={false} />
                </StyledVideoProgressWrapper>
              </StyledImageWrapper>
              <StyledVideoContentWrapper>
                <h3>{item.videoName}</h3>
                <h4>{item.videoBy}</h4>
                <span>{item.createdAt}</span>
              </StyledVideoContentWrapper>
            </StyledVideoCard>
          </Col>
        ))}
      </Row>
      <FloatButton>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
          onClick={() => console.log("Video add")}
        />
      </FloatButton>
    </StyledVideoCardWrapper>
  );
};

export default VideoSection;
