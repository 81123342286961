import React, { useState, useRef, useEffect, useCallback } from "react";
import { StyledHeaderSearch } from "./TheHeader.styles";
import { TreeSelect, Tree, Input, AutoComplete, Icon, message } from "antd";
import { FiSearch } from "react-icons/fi";
import "./style.css";
import { paginationObj, querystring } from "utils/fn";
import RelationshipAPI from "api/http/relationship";
import { connect } from "react-redux";
import { adminRoutes } from "helpers/adminRoutes";
import TeamAPI from "api/http/team";
import SourceAPI from "api/http/source";
import { Link, useHistory } from "react-router-dom";
import { Select } from "../../../../node_modules/antd/lib/index";
import { useSelector } from "react-redux";
import { teamStatus } from "utils/constants";
import NotificationRedireact from "./NotificationRedireact";

const { TreeNode } = TreeSelect;
const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;
const HeaderSearch = () => {
  const history = useHistory();

  const [value, setValue] = useState(undefined);
  const [placeHolderVal, setPlaceHolderVal] = useState("");
  const inputRef = useRef(null);
  const [stateDatas, setStateDatas] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const [stateDataSource, setStateDataSource] = useState([]);
  const { user } = useSelector((state) => state.user);
  const [teamData, setTeamData] = useState([]);

  const [teamId, setTeamId] = useState("");
  const [postId, setPostId] = useState("");
  const [commentId, setCommentId] = useState("");
  const [modalState, setModalState] = useState(false);

  const defaultPagination = React.useMemo(
    () => ({
      current: 1,
      pageSize: 5,
      total: 0,
      filter: {},
      populate: [
        {
          path: "user",
          select: ["firstName", "lastName", "avatar"],
        },
        {
          path: "totalComments",
        },
        {
          path: "isLiked",
          match: { likers: user?._id || localStorage.getItem("userId") },
        },
      ],
    }),
    [user?._id]
  );
  const onButtonClick = () => {
    inputRef.current.focus();
  };
  const defaultQuery = React.useMemo(
    () => ({
      $populate: [
        { path: "memberCount", match: { status: teamStatus.joined } },
      ],
    }),
    []
  );

  // const userPostActions = (
  //   { event, props },
  //   cb,
  //   isJson = false,
  //   showMessage = true
  // ) => {
  //   switch (event) {
  //     case "+like":
  //       postAction(props._id, true);
  //       break;
  //     case "-like":
  //       postAction(props._id, false);
  //       break;
  //     case "comment-like":
  //       commentAction(props.postId, props.comment, cb);
  //       break;
  //     case "refreshPost":
  //       refreshPost(cb);

  //       break;
  //     case "delete":
  //       deletePostAction(props.val, props._id);
  //       break;
  //     case "update":
  //       updatePostAction(props._id, props.post, cb, isJson, showMessage);
  //       break;
  //     // case "comment":
  //     //   createComment(props._id, {
  //     //     comment: props.comment,
  //     //     image: props.image,
  //     //   });
  //     //   break;
  //     default:
  //       break;
  //   }
  // };

  function renderChildren(group, disp_title) {
    if (group && group.length === 0) {
      return;
    }
    let child = (group || [])
      .filter((data) => {
        if (data?.team && data?.team == null) {
          return false;
        } else if (data && data?.members && data?.admins) {
          let obj = data?.members.find(
            (o) => o.user.toString() === (user?._id).toString()
          );
          if (obj === undefined || data) {
            return false;
          }
        } else if (data && data?.team && data?.team?.members) {
          let obj = data?.team?.members.find(
            (o) =>
              o.user.toString() === (user?._id).toString() &&
              o.status === "Joined"
          );
          if (obj === undefined) {
            return false;
          }
        }
        return true;
      })
      .map((data) => (
        <Option value={JSON.stringify(data)} key={data?._id}>
          {data.email ||
            data.source ||
            data.name ||
            data.text ||
            data.firstName + " " + data?.lastName}
        </Option>
      ));
    return child;
  }
  const onSearch = (val) => {
    setSearchTerm(val);
  };

  const getPost = (teamId, postId) => {
    TeamAPI.getPost(
      teamId,
      postId,
      querystring(paginationObj(defaultPagination))
    )
      .then((data) => {
        setTeamData(data.data);
        setModalState(true);
      })
      .catch((error) => {
        message.info("Post has been deleted");
        setModalState(false);
        console.log("error", error);
      });
  };

  const getTeam = useCallback(
    (teamId, postId) => {
      TeamAPI.getTeam(teamId, querystring(defaultQuery))
        .then(({ data }) => {
          console.log("teamDtaa", data);
          getPost(teamId, postId);
        })
        .catch((error) => {
          console.log(error);
          setModalState(false);
          // message.error(error.message);
          if (error?.code === 403) {
            history.push(adminRoutes.TEAM);
          }
        });
    },
    [defaultQuery, history]
  );

  const getSearhData = (searchTerm) => {
    RelationshipAPI.getRelationshipList(
      querystring({ types: "search", search: searchTerm })
    )
      .then(({ data }) => {
        const children = data?.data.map((data) => (
          <Select.OptGroup key={data.title}>
            {renderChildren(data.children, data.disp_title)}
          </Select.OptGroup>
        ));
        setStateDataSource(children);
      })
      .catch((error) => {
        if (error) {
        }
      });
  };

  const onSelectValue = (val, selectValue) => {
    var selectedData = JSON.parse(selectValue.value);
    inputRef.current.blur();
    if (
      selectedData.firstName ||
      selectedData.lastName ||
      selectedData?.email ||
      selectedData?.phoneNumber
    ) {
      history.push(adminRoutes.RELATIONSHIP);
    } else if (selectedData.source) {
      history.push(adminRoutes.SOURCE_EDIT.replace(":id", selectValue.key));
    } else if (
      selectedData.name &&
      selectedData.visibility &&
      selectedData.description &&
      selectedData.admins
    ) {
      history.push(adminRoutes.TEAMDETAILS.replace(":name", selectValue.key));
    } else {
      setPostId(selectValue.key);
      getTeam(selectedData?.team?._id, selectValue.key);
      setSearchTerm("");
      // history.push(adminRoutes.TEAMDETAILS.replace(":name", selectedData.team));
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchTerm && searchTerm.length > 0 && getSearhData(searchTerm);
    }, 100);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <>
      <StyledHeaderSearch>
        <FiSearch className="header_search_icon" onClick={onButtonClick} />
        <AutoComplete
          ref={inputRef}
          onBlur={() => {
            console.log("inputRef.current", inputRef.current);
            inputRef.current.value = "";
          }}
          dataSource={stateDataSource}
          value={searchTerm}
          onSelect={onSelectValue}
          onSearch={onSearch}
          placeholder={"Search"}
        >
          {/* <Input suffix={<Icon type="search" />} /> */}
          {stateDataSource}
        </AutoComplete>
      </StyledHeaderSearch>

      {modalState && (
        <NotificationRedireact
          onCancel={(value) => setModalState(value)}
          modalState={modalState}
          from={adminRoutes.TEAM}
          teamId={teamId}
          postId={postId}
          commentId={commentId}
          // userPostActions={userPostActions}
          // onSubmit={() => {
          //   refreshTeams();
          //   setModalStates(true);
          // }}
          teamData={teamData}
          isModal={true}
        />
      )}
    </>
  );
};

export default HeaderSearch;
