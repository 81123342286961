import styled from "styled-components";
import { Form, Row, Button } from "antd";
import { Card } from "ui/Card";

export const LinkCreators = styled.div`
  background: ${(props) => props.theme.colors.third} !important;
`;

export const StyledTabButtonWrap = styled.div`
  display: inline-block;
  width: 100%;
  margin-left: -30px;
  .ant-tabs {
    overflow: unset;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 35px;
    padding: 0 30px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
    width: 276px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.07);
    border-radius: 50px;
    justify-content: center;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 46px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: unset;
  }

  .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #949494;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primary};
  }

  .ant-tabs-ink-bar {
    height: 2px;
    background: ${(props) => props.theme.colors.primary} !important;
    border-radius: 50px;
  }
`;

export const StyledFormItemWrapper = styled.div`
  display: flex;
  flex-flow: column;

  .linkAddOn .ant-input-group-addon {
    width: 80px;
    min-width: 120px;
    height: 48px;
    background: ${(props) => props.theme.colors.secondary};
    box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
    border-radius: 0px 5px 5px 0px;

    font-size: 14px;
    color: ${(props) => (props.color ? props.color : "#FFFFFF")};

    .ant-btn:active,
    .ant-btn {
      background: ${(props) => props.theme.colors.secondary};
      border: none;
    }
  }

  .dynamicButtonInput {
    display: flex;
  }

  .dynamicAddOn .ant-input-group-addon {
    background: transparent;
    border: none;

    input {
      display: none;
    }
  }

  .eye {
    margin-left: 15px;
    background: linear-gradient(
      276.7deg,
      ${(props) => props.theme.colors.primary} 0%,
      ${(props) => props.theme.colors.primary2} 100%
    );
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
  }
  .asterisk {
    /* margin-left: 15px; */
    background: linear-gradient(
      276.7deg,
      ${(props) => props.theme.colors.primary} 0%,
      ${(props) => props.theme.colors.primary2} 100%
    );
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
  }
  .asteriskTrue {
    /* margin-left: 15px; */
    background: linear-gradient(142.07deg, #fc3c3c 13.94%, #ad1b1b 86.39%);
    border: none;
    box-shadow: 0px 10px 12px rgba(252, 91, 91, 0.35);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
  }

  .delete {
    margin-left: 15px;
    background: linear-gradient(142.07deg, #fc3c3c 13.94%, #ad1b1b 86.39%);
    border: none;
    box-shadow: 0px 10px 12px rgba(252, 91, 91, 0.35);
  }

  @media (max-width: 575px) {
    .dynamicButtonInput {
      margin-top: 10px;
    }

    .asterisk {
      margin-left: 0px;
    }

    .asteriskTrue {
      margin-left: 0px;
    }
  }
`;

export const StyledDeleteButton = styled(Button)`
  margin-left: 15px;
  background: linear-gradient(142.07deg, #fc3c3c 13.94%, #ad1b1b 86.39%);
  border: none;
  box-shadow: 0px 10px 12px rgba(252, 91, 91, 0.35);
`;

export const StyledEyeButton = styled(Button)`
  margin-left: 15px;
  background: linear-gradient(
    276.7deg,
    ${(props) => props.theme.colors.primary} 0%,
    ${(props) => props.theme.colors.primary2} 100%
  );
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
`;

export const StyledAsteriskButton = styled(Button)`
  margin-left: 15px;
  background: linear-gradient(
    276.7deg,
    ${(props) => props.theme.colors.primary} 0%,
    ${(props) => props.theme.colors.primary2} 100%
  );
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  box-shadow: 0px 10px 15px rgba(0, 97, 164, 0.35);
`;

export const StyledLink = styled.a`
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  line-height: 18px;
  text-decoration-line: underline;
  color: #747487;
  :hover {
    color: ${(props) => props.theme.colors.primary};
    text-decoration-line: underline;
  }
  word-wrap: break-word;
`;

export const StyledFormItemButton = styled.div`
  display: inline-block;
  margin-top: 20px;
  /* margin-left: 20px; */
`;

export const StyledFormItemOkButton = styled.div`
  display: inline-block;
  margin-top: 20px;
  margin-left: 0px;
`;

export const StyledInputItemWrapper = styled(Form.Item)`
  display: flex;
  margin-bottom: 20px;
  /* margin-right: 100px; */

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
  .ant-form-item-label > label {
    padding-top: 15px;
  }

  .edit_pencil {
    font-size: 18px;
    color: #747487;
  }

  .reset_icon path {
    font-size: 18px;
    stroke: #747487;
  }

  // @media (max-width: 575px) {
  //   .ant-form-item-control-input-content {
  //     flex-flow: column;
  //     align-items: flex-start;
  //   }
  // }
`;

// START GENERATE STYLE
export const StyledContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 100vh;
  // height: 100%;
  background-color: ${(props) => props.theme.colors.primary};
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}80;
  /* background-position: center; */
  @media (max-width: 767px) {
    /* background-size: auto; */
    transition: none;
    overflow-y: hidden;
    padding-bottom: 4em;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
    &:-webkit-scrollbar {
      width: 0px !important;
      display: none;
    }
    &:-webkit-scrollbar-track {
      display: none;
    }
  }
`;

export const StyledContentPreview = styled.div`
  border-radius: 20px;
  pointer-events: none;
  display: flex;
  flex-flow: column;
  /* width: 50%; */
  min-height: 50vh;
  width: 100%;
  /* height: 100%; */
  background-color: ${(props) => props.theme.colors.primary};
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* margin-top: 12px; */
  justify-content: flex-start;
  /* justify-content: center; */
  box-shadow: inset 0 0 0 1000px ${(props) => props.theme.colors.primary}80;

  .headings {
    display: inline-block;
    font-weight: 800;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 27px;
    padding-bottom: 14px;
    border-bottom: 1px solid black;
  }
  .description {
    margin-top: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 33px;
    display: inline-block;
  }
`;

export const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 15px;
  padding: 0 12%;

  img {
    /* width: 400px; */
    /* width: 550px; */
    width: 100%;
  }
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 0 12%;
  word-wrap: break-word;
  width: 100%;
  .heading {
    display: inline-block;
    font-weight: 800;
    font-size: 34px;
    line-height: 48px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 27px;
    padding-bottom: 14px;
    border-bottom: 1px solid white;
    width: 100%;
    /* word-wrap: break-word; */
  }

  .description {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 33px;
    display: inline-block;
    width: 100%;
    /* word-wrap: break-word; */
  }

  @media (max-width: 991px) {
    .heading {
      font-size: 28px;
    }

    .description {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    padding: 0 15px;

    .heading {
      font-size: 34px;
    }
  }

  @media (max-width: 575px) {
    .heading {
      font-size: 26px;
    }
  }
`;

export const StyledTextWrapperNoData = styled.div`
  display: flex;
  justify-content: center;

  .description {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #ffffff;
    text-align: center;
    margin-top: 300px;
    display: inline-block;
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;

export const StyledFormWrapper = styled.div`
  /* display: inline-block;
  width: 100%; */
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0% 12%;

  .ant-form {
    .ant-form-item {
      .ant-form-item-label {
        & > {
          label {
            &.ant-form-item-required {
              &:after {
                display: inline-block;
                margin-right: 4px;
                color: #ff4d4f;
                font-size: 14px;
                font-family: SimSun, sans-serif;
                line-height: 1;
                content: "*";
              }
              &:before {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    padding: 0 15px;
  }
`;

export const StyledVideo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 12%;
  /* margin-top: 10px; */
  margin-bottom: 15px;

  @media (max-width: 991px) {
    padding: 0 15px;
  }
`;
export const StyledSubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 27px;
  margin-bottom: 50px;
`;

// END GENERATE STYLE

export const StyledRowWrapper = styled(Row)`
  @media (max-width: 1199px) {
    flex-flow: column;
  }
`;
