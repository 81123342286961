import React from "react";
import { Row, Col, Image } from "antd";
import { Container } from "../../ui/Layout/Container";
import { Heading } from "../../ui/Heading";
import { Card } from "ui/Card/Card";

const Dashboard = () => {
  return (
    <Container>
      <Row>
        <Col span={24}>
          <Heading>
            Welcome John Francis{" "}
            <Image
              src="/images/waving-hand.png"
              preview={false}
              height={30}
              width={30}
            />
          </Heading>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Image src="/images/dashboard-image.svg" preview={false} />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Image src="/images/dashboard-image2.png" preview={false} />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
