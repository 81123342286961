import React, { useState } from "react";
import { Image, Popconfirm } from "antd";
import styled from "styled-components";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FaRegEye, FaTrash } from "react-icons/fa";

const ImagePreview = ({ onDelete = console.log, ...props }) => {
  const [visible, setVisible] = useState(false);
  return (
    <ImageContainer
      preview={{
        visible: visible,
        onVisibleChange: (current, prev) => {
          prev && setVisible(false);
        },
        mask: (
          <Mask
            onPreview={() => setVisible(true)}
            onDelete={(e) => {
              // e.stopPropagation();
              onDelete(e);
            }}
          />
        ),
        maskClassName: "img-preview-mask",
      }}
      {...props}
    />
  );
};

const Mask = ({ onPreview, onDelete }) => {
  return (
    <div>
      <div onClick={onPreview}>
        <Icon>
          <FaRegEye />
        </Icon>{" "}
        <span>Preview</span>
      </div>
      <Popconfirm
        key={"del-c"}
        title="Are you sure?"
        onConfirm={(e) => onDelete(e)}
        okText="Yes"
        cancelText="No"
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      >
        <div>
          <Icon>
            <FaTrash />
          </Icon>{" "}
          <span>Delete</span>
        </div>
      </Popconfirm>
    </div>
  );
};

export const ImageContainer = styled(Image)`
  border-radius: 10px;
`;

export const Icon = styled.span`
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 32px;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
`;

export default ImagePreview;
