import React from "react";

const text = ({ color = "#666666", ...props }) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
<path d="M5.69531 21V18.522L7.9043 18.0972V3.56885H4.15186L3.92529 6.03271H0.795898V0.382812H19.1899V6.03271H16.0464L15.8198 3.56885H12.0391V18.0972L14.2622 18.522V21H5.69531Z"
 fill="#9BD4CE"/>
    </svg>
  );
};

export default text;
