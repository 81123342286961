import React, { useEffect } from "react";
import { Container } from "ui/Layout/Container";
import { Row, Col } from "antd";
// import { UserProfileVideosData } from "Components/Profile/ProfileData";
import PlayListVideoSection from "./PlayListVideoSection";
import { Slider } from "antd";
import { SliderBar, WelcomeComo } from "./PlayListVideoSection.style";
import GrowAPI from "api/http/grow";
import { useParams } from "react-router";
import { cosmiconfig } from "../../../node_modules/cosmiconfig/dist/index";
import { paginationObj, querystring } from "utils/fn";

const PlayList = () => {
  const [playlistCompletion, setPlaylistCompletion] = React.useState(20);
  const [UserProfileVideosData, setUserProfileVideosData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [watchedTime, setWatchedTime] = React.useState(0);
  const [totalTime, setTotalTime] = React.useState(0);

  const params = useParams();

  useEffect(() => {
    GrowAPI.getGrowListDetail(
      params.id,
      querystring(
        paginationObj({
          populate: [
            {
              path: "allVideos",
              populate: [
                {
                  path: "videoTimer",
                  match: { userId: localStorage.getItem("userId") },
                },
                { path: "uploadedBy" },
              ],
            },
          ],
        })
      )
    ).then((data) => {
      setUserProfileVideosData(data?.data?.allVideos);
      let totalTime = 0;
      let watchedTime = 0;
      for (const dta of data?.data?.allVideos) {
        if (dta?.videoTimer?.watchedTime) {
          watchedTime = watchedTime + parseInt(dta?.videoTimer?.watchedTime);
        } else {
          watchedTime = parseInt(watchedTime) + 0;
        }
        totalTime = totalTime + parseInt(dta?.videoTimer?.videoTime);
      }
      setTotalTime(totalTime);
      setWatchedTime(watchedTime);
    });
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <>
      <Container minH={"100%"} padding={"30px"}>
        <PlayListVideoSection
          UserProfileVideosData={UserProfileVideosData}
          watchedTime={watchedTime}
          totalTime={totalTime}
        />
      </Container>
    </>
  );
};

export default PlayList;
