import React, { useState,useEffect } from "react";
import { PrimaryButton } from "../../ui/Button";
import { Container } from "../../ui/Layout/Container";
import { Card, CardTitle } from "../../ui/Card";
import { FieldWrapper, FormWrapper } from "./style";
import { ToggleSwitch } from "../../ui/Form";
import { Heading } from "ui/Heading/Heading";
import {useDispatch, useSelector } from "react-redux";
import UserAPI from "api/http/user";
import { me } from "store/reducers/user.reducer";

const NotificationSetting = () => {
  const [state, setState] = useState({
    smsNotification: true,
    emailNotification: true,
    pushNotification: false,
    webNotification: true,
  });
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();



  useEffect(() => {
      setState({
    smsNotification: user?.smsNotification,
    emailNotification: user?.emailNotification,
    pushNotification: user?.pushNotification,
    webNotification: user?.webNotification,
    })
  },[])
  const changeToggleHandler = (values, type) => {
     setState((s) => ({ ...s, [type]: values }));  
  };



  const onFinish = () => {
    let data = state;
     UserAPI.updateUser(user?._id, 
     data
     )
       .then(() => {
         // message.success("Profile updated successfully");
         dispatch(me());
        })
  };

  return (
    <Container>
      <Heading>Communication Settings</Heading>
      <Card>
        <FormWrapper>
          <ToggleSwitch
            onChange={(e) => changeToggleHandler(e, "smsNotification")}
            defaultChecked={user?.smsNotification}
          ></ToggleSwitch>
          <FieldWrapper
            textColor={state.smsNotification ? "#232333" : "#747487"}
          >
            SMS Notification
          </FieldWrapper>
        </FormWrapper>
        <FormWrapper>
          <ToggleSwitch
                     defaultChecked={user?.emailNotification}
            onChange={(e) => changeToggleHandler(e, "emailNotification")}
          ></ToggleSwitch>
          <FieldWrapper
            textColor={state.emailNotification ? "#232333" : "#747487"}
          >
            Email Notification
          </FieldWrapper>
        </FormWrapper>
        <FormWrapper>
          <ToggleSwitch
                               defaultChecked={user?.pushNotification}

            onChange={(e) => changeToggleHandler(e, "pushNotification")}
          ></ToggleSwitch>
          <FieldWrapper
            textColor={state.pushNotification ? "#232333" : "#747487"}
          >
            Push Notification
          </FieldWrapper>
        </FormWrapper>
        <FormWrapper>
          <ToggleSwitch
                     defaultChecked={user?.webNotification}

            onChange={(e) => changeToggleHandler(e, "webNotification")}
          ></ToggleSwitch>
          <FieldWrapper
            textColor={state.webNotification ? "#232333" : "#747487"}
          >
            Web Notification
          </FieldWrapper>
        </FormWrapper>

        <PrimaryButton onClick={onFinish} htmlType="submit">
          Save
        </PrimaryButton>
      </Card>
    </Container>
  );
};

export default NotificationSetting;
