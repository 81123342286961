import React, { useEffect, useState } from "react";
import { ProfileMobileTabWrapper } from "./style";
import { Row, Col, Tabs } from "antd";
import TeamSection from "./ProfileSections/TeamSection";
import VideoSection from "./ProfileSections/VideoSection";
import EventSection from "./ProfileSections/EventSection";
import { me } from "store/reducers/user.reducer";
import UserProfileDetails from "./UserProfileDetails";
import { Container } from "../../ui/Layout/Container";
import { useDispatch, useSelector } from "react-redux";
import { UserProfileEventData, UserProfileVideosData } from "./ProfileData";
import { useHistory, useParams } from "react-router-dom";
import UserAPI from "api/http/user";
import { querystring } from "utils/fn";
import { teamStatus } from "utils/constants";
import { message } from "antd";
import { adminRoutes } from "helpers/adminRoutes";
import { tabDatas } from "store/reducers/tab.reducer";

const Profile = () => {
  const { user } = useSelector((state) => state.user);
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const userId = params.id;
  const { TabPane } = Tabs;

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(me());
  }, [dispatch]);

  useEffect(() => {
    if (userId && user?._id !== userId) {
      UserAPI.getUser(
        userId,
        querystring({
          $populate: {
            path: "teams",
            select: ["team", "status"],
            match: {
              user: userId,
              status: { $in: [teamStatus.rejected, teamStatus.joined] },
            },
            populate: {
              path: "team",
              populate: [
                {
                  path: "admins",
                  select: ["firstName", "lastName", "avatar", "location"],
                },
                { path: "memberCount", match: { status: teamStatus.joined } },
                { path: "joined", match: { user: user?._id || userId } },
              ],
            },
          },
        })
      )
        .then(({ data }) => {
          setData(data);
        })
        .catch((err) => {
          console.log(err);
          message.error(err.message);
          history.push(adminRoutes.PROFILE);
        });
    } else {
      history.push(adminRoutes.PROFILE);
    }
    return () => {};
  }, [history, user?._id, userId]);

  function callback(key) {
    console.log(key);
  }

  return (
    <Container>
      <UserProfileDetails userId={userId} data={params.id ? data : user} />
      <Row>
        <Col xs={0} sm={0} md={0} lg={24}>
          <TeamSection user={params.id ? data : user} />
        </Col>
        <Col xs={0} sm={0} md={0} lg={24}>
          <VideoSection UserProfileVideosData={UserProfileVideosData} />
        </Col>
        <Col xs={0} sm={0} md={0} lg={24}>
          <EventSection UserProfileEventData={UserProfileEventData} />
        </Col>
      </Row>

      <ProfileMobileTabWrapper>
        <Tabs onChange={callback} type="card">
          <TabPane tab="Community" key="1">
            <TeamSection user={params.id ? data : user} />
          </TabPane>
          <TabPane tab="Videos" key="2">
            <VideoSection UserProfileVideosData={UserProfileVideosData} />
          </TabPane>
          <TabPane tab="Events" key="3">
            <EventSection UserProfileEventData={UserProfileEventData} />
          </TabPane>
        </Tabs>
      </ProfileMobileTabWrapper>
    </Container>
  );
};

export default Profile;
