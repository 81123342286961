import React, { Component } from "react";
// import { Image, Modal, Grid, Row, Col } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { Player, BigPlayButton } from "video-react";
// import { Modal } from "antd";
import { StyledModal } from "./style";

class ModalComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: props.images || [],
            currentImageIndex: props.index,
        };

        this.onMovePrevRequest = this.onMovePrevRequest.bind(this);
        this.onMoveNextRequest = this.onMoveNextRequest.bind(this);
    }

    onMovePrevRequest() {
        const { currentImageIndex, images } = this.state;

        this.setState({
            currentImageIndex:
                (currentImageIndex + images.length - 1) % images.length,
        });
    }

    onMoveNextRequest() {
        const { currentImageIndex, images } = this.state;

        this.setState({
            currentImageIndex: (currentImageIndex + 1) % images.length,
        });
    }

    render() {
        const { images, currentImageIndex } = this.state;
        const { onClose, index } = this.props;

        return typeof images[currentImageIndex] === "string" ? (
            <Lightbox
                mainSrc={images[currentImageIndex]}
                nextSrc={images[(currentImageIndex + 1) % images.length]}
                prevSrc={
                    images[(currentImageIndex + images.length - 1) % images.length]
                }
                onCloseRequest={onClose}
                onMovePrevRequest={this.onMovePrevRequest}
                onMoveNextRequest={this.onMoveNextRequest}
            />
        ) : (
            // <video width="100%" controls >
            //     <source src={images[currentImageIndex]["url"]} type={images[currentImageIndex]["mimetype"]} />

            //     Your browser does not support the video tag.
            // </video>

            <StyledModal
                visible={true}
                footer={false}
                closable={false}
                getContainer={() => document.querySelector("#modal-container")}

            // wrapClassName={"modalheightcss"}
            // className="modal_wrapper"
            // onClick={(e) => e.preventDefault()}
            >
                <button
                    type="button"
                    class="ril-prev-button ril__navButtons ril__navButtonPrev"
                    aria-label="Previous image"
                    title="Previous image"
                    onClick={() => {
                        console.log(
                            "images[(currentImageIndex + images.length - 1) % images.length]",
                            images[(currentImageIndex + images.length - 1) % images.length]
                        );
                        this.onMovePrevRequest();
                    }}
                ></button>

                <Player fluid={false} src={images[currentImageIndex]["url"]}>
                    <BigPlayButton position="center" />
                </Player>
                <button
                    type="button"
                    class="ril-next-button ril__navButtons ril__navButtonNext"
                    aria-label="Next image"
                    title="Next image"
                    onClick={() => {
                        console.log(
                            "images[(currentImageIndex + 1) % images.length]",
                            images[(currentImageIndex + 1) % images.length]
                        );

                        this.onMoveNextRequest();
                    }}
                ></button>
                <div class="ril-toolbar ril__toolbar">
                    <ul class="ril-toolbar-left ril__toolbarSide ril__toolbarLeftSide">
                        <li class="ril-toolbar__item ril__toolbarItem">
                            <span class="ril-toolbar__item__child ril__toolbarItemChild"></span>
                        </li>
                    </ul>
                    <ul class="ril-toolbar-right ril__toolbarSide ril__toolbarRightSide">
                        <li class="ril-toolbar__item ril__toolbarItem">
                            <button
                                onClick={() => onClose()}
                                type="button"
                                aria-label="Close lightbox"
                                title="Close lightbox"
                                class="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton"
                            ></button>
                        </li>
                    </ul>
                </div>
            </StyledModal>
        );
    }
}

export default ModalComponent;
