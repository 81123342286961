import React from "react";
import styled from "styled-components";

const ScrollView = ({ children, ...props }) => {
  return <ScrollViewWrapper {...props}>{children}</ScrollViewWrapper>;
};

const ScrollViewWrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  ${({ children, ...props }) => props}
  &::-webkit-scrollbar {
    width: 0em;
    @media (max-width: 767px) {
      width: 0 !important;
      display: none !important;
    }
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    @media (max-width: 767px) {
      box-shadow: none;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(97, 96, 96);
    outline: 1px solid rgb(112, 128, 144);
    border-radius: 10px;
    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export default ScrollView;
