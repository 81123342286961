export const videosData = [
  {
    _id: 1,
    status: true,
    video: "",
    title: "Work Life Balance",
    user: {
      firstName: "Jayen ",
      lastName: "McElroy",
      avatar: {
        path: "https://i.pravatar.cc/50",
      },
    },
    details: "8K views",
    createdAt: "",
    Availability: "Publicly Available",
    comment: "Good time to start Christmas shopping!",
    likers: [1, 1, 1],
    createdAt: "2021-11-21",
    isLiked: true,
    userComments: [],
  },
  {
    _id: 2,
    status: true,
    video: "",
    title: "Health Life",
    user: {
      firstName: "John",
      lastName: "Manhar",
      avatar: {
        path: "https://i.pravatar.cc/40",
      },
    },
    details: "30K views",
    createdAt: "",
    Availability: "Available To Team",
    comment: "After sun spray! Just in case:)",
    likers: [1, 1, 1, 1],
    createdAt: "2021-12-10",
  },
  {
    _id: 3,
    status: true,
    video: "",
    title: "Breathing",
    user: {
      firstName: "Julianne",
      lastName: "Devid",
      avatar: {
        path: "https://i.pravatar.cc/30",
      },
    },
    details: "1.5K views",
    πcreatedAt: "",
    Availability: "Publicly Available",
    comment: "Bug repellant and hand sanitizer!!!!! ",
    likers: [1, 1],
    createdAt: "2021-12-13",
  },
  {
    _id: 4,
    status: true,
    video: "",
    title: "Sleep",
    user: {
      firstName: "John",
      lastName: "Vornar",
      avatar: {
        path: "https://i.pravatar.cc/60",
      },
    },
    details: "04K views",
    createdAt: "",
    Availability: "Available To Team",
    comment: "Awesome",
    likers: [],
    createdAt: "2021-08-10",
  },
];
