import { httpAPI } from "api";

const SourceAPI = {
  addSource(params) {
    return httpAPI(`/source`, params, "post");
  },
  getSourceList(query) {
    return httpAPI(`/source?${query || ""}`, "", "get");
  },
  getSourceListDetail(id = "", query = "") {
    return httpAPI(`/source/${id}?${query}`, "", "get");
  },
  updateSource(id, params) {
    return httpAPI(`/source/${id}`, params, "patch");
  },
  deleteSource(id, params) {
    return httpAPI(`/source/${id}`, params, "delete");
  },
};

export default SourceAPI;
