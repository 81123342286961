import React, { useState } from "react";
import {
  CardTitleWrapper,
  StyledEarthIcon,
  CardContentWrapper,
  CardImage,
  GroupInfoCard,
  CardTextWrapper,
} from "../styled";
import { Capitalize, imagePath } from "utils/fn";
import { fallbackImage } from "utils/constants";

const ReadMore = ({ children }) => {
  const text = children || "";
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p>
      {isReadMore ? text.slice(0, 380) : text}
      {text?.length > 380 && (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...read more" : " show less"}
        </span>
      )}
    </p>
  );
};

export const GroupInfo = ({ data }) => {
  return (
    <>
      <GroupInfoCard padding={"15px"}>
        <CardContentWrapper>
          <CardImage
            src={data?.avatar?.path ? imagePath(data?.avatar?.path) : ""}
            preview={false}
            objectFit={data?.avatar?.path ? "cover" : "cover"}
            fallback={fallbackImage}
          />
          <CardTextWrapper>
            <CardTitleWrapper>
              <StyledEarthIcon />
              <span>{Capitalize(data?.visibility)} Community</span>
            </CardTitleWrapper>
            <ReadMore>{data?.description}</ReadMore>
          </CardTextWrapper>
        </CardContentWrapper>
      </GroupInfoCard>
    </>
  );
};
