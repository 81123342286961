// Content

import { Link, Redirect, Switch, useHistory } from "react-router-dom";
import Relationship from "../../Components/Relationship/Relationship";
import MyEvents from "../../Components/MyEvents/MyEvents";
import FindEvents from "../../Components/FindEvents/FindEvents";
import { adminRoutes, settingsRoutes } from "../../helpers/adminRoutes";
import React, { useContext, useEffect, useState } from "react";
import Dashboard from "../../Components/Dashboard/Dashboard";
import TeamDetails from "../../Components/Team/TeamDetail/TeamDetail";
import config from "helpers/config";
import useSocket from "utils/hooks/useSocket";

//  import TeamDetails from "./Components/TeamDetails";
import { Route, useLocation } from "react-router";
import { SourceList as Source } from "../../Components/SourceList";
import Profile from "../../Components/Profile/Profile";
import Notification from "../../Components/Setting/Setting";
import {
  ModuleSources,
  ModuleEvents,
  ModuleAddEvents,
  ModuleType,
  ModuleAddTypes,
  ModuleTeam,
  ModuleAddTeam,
  ModuleRelationship,
} from "../../Components/Admin/ModuleSetting";
import {
  IncentivePoints,
  IncentiveAddPoints,
  IncentiveReward,
  IncentiveAddReward,
  IncentiveStreak,
} from "../../Components/Admin/IncentiveSetting";
import ManagePassword from "../../Components/ManagePassword/ManagePassword";
import Team from "../../Components/Team/FindTeam/Team";
import AddVideo from "../../Components/Grow/ManageVideos/AddVideo";
import Video from "../../Components/Video";
import VideoDetail from "../../Components/VideoDetail";
import LinkCreator from "../../Components/AddSource/LinkCreator";
import axios from "axios";

import { API, Portal, User, InviteUser } from "../../Components/Admin/General";
import AddAPI from "../../Components/Admin/General/API/AddAPI";

import { Breadcrumb, message, Alert, Button, Space, Modal } from "antd";
import { BreadCrumbsWrapper } from "./TheLayout.styles";
import ProfileEdit from "Components/Profile/ProfileEdit";
import {
  ModuleAddVideo,
  ModuleManageVideo,
} from "Components/Admin/ModuleSetting/Grow/index";
import PlayList from "Components/Grow/PlayList";
import PlayListPage from "Components/Grow/PlayListPage/PlayListPage";
import useSound from "use-sound";
import StickyMessage from "ui/StickyMessage/StickyMessage";
import { useStickyMessage } from "utils/hooks/useStickyMessage";
import WatchVideo from "Components/Grow/WatchVideo";
import { ManageVideos } from "Components/Grow/index";
import RelationshipTabAPI from "api/http/relationshipTab";
import { useSelector, useDispatch } from "react-redux";
import { tabDatas } from "store/reducers/tab.reducer";
import { getDynamicMenuString } from "utils/fn";
import {
  paginationObj,
  querystring,
  shortenDateFromNow,
  downloadFile,
} from "utils/fn";
import FileUploadAPI from "api/http/fileupload";
import { SocketContext } from "utils/context/socket";
import PortalAPI from "api/http/portal";
import { logout, portal } from "store/reducers/user.reducer";

const userRouters = [
  {
    name: "Watch Video",
    exact: true,
    path: adminRoutes.WATCH_VIDEO,
    component: WatchVideo,
  },
  {
    name: "Manage Video",
    exact: true,
    path: adminRoutes.MANAGEVIDEO,
    component: ManageVideos,
  },
  {
    name: "EditVideo",
    exact: true,
    path: adminRoutes.ADDVIDEO,
    component: AddVideo,
  },
  {
    name: "My Profile",
    exact: true,
    path: adminRoutes.PROFILE,
    component: Profile,
  },
  {
    name: "View Profile",
    exact: true,
    path: adminRoutes.PROFILE_VIEW,
    component: Profile,
  },
  {
    name: "Edit Profile",
    exact: true,
    path: adminRoutes.PROFILE_EDIT,
    component: ProfileEdit,
  },
];

const adminRouters = [
  {
    name: "Portal",
    exact: true,
    path: settingsRoutes.PORTAL,
    component: Portal,
  },
  {
    name: "User",
    exact: true,
    path: settingsRoutes.USER,
    component: User,
  },
  {
    name: "Add User",
    exact: true,
    path: settingsRoutes.USER_ADD,
    component: InviteUser,
  },
  {
    name: "Edit User",
    exact: true,
    path: settingsRoutes.USER_EDIT,
    component: InviteUser,
  },
  {
    name: "API",
    exact: true,
    path: settingsRoutes.API,
    component: API,
  },
  {
    name: "Add API",
    exact: true,
    path: settingsRoutes.ADD_API,
    component: AddAPI,
  },
  {
    name: "Edit Team",
    exact: true,
    path: settingsRoutes.API_EDIT,
    component: AddAPI,
  },
  {
    name: "ModuleSource",
    exact: true,
    path: settingsRoutes.SOURCE,
    component: ModuleSources,
  },
  {
    name: "ModuleAddSource",
    exact: true,
    path: settingsRoutes.SOURCE_ADD,
    component: LinkCreator,
  },
  {
    name: "ModuleEditSource",
    exact: true,
    path: settingsRoutes.SOURCE_EDIT,
    component: LinkCreator,
  },
  {
    name: "ModuleRelationship",
    exact: true,
    path: settingsRoutes.RELATIONSHIP,
    component: ModuleRelationship,
  },
  {
    name: "ModuleEvents",
    exact: true,
    path: settingsRoutes.EVENT,
    component: ModuleEvents,
  },
  {
    name: "Add Events",
    exact: true,
    path: settingsRoutes.EVENT_ADD,
    component: ModuleAddEvents,
  },
  {
    name: "Edit Events",
    exact: true,
    path: settingsRoutes.EVENT_EDIT,
    component: ModuleAddEvents,
  },
  {
    name: "ModuleType",
    exact: true,
    path: settingsRoutes.TYPE,
    component: ModuleType,
  },
  {
    name: "Add Type",
    exact: true,
    path: settingsRoutes.TYPE_ADD,
    component: ModuleAddTypes,
  },
  {
    name: "Edit Type",
    exact: true,
    path: settingsRoutes.TYPE_EDIT,
    component: ModuleAddTypes,
  },
  {
    name: "ModuleTeam",
    exact: true,
    path: settingsRoutes.TEAM,
    component: ModuleTeam,
  },
  {
    name: "AddTeam",
    exact: true,
    path: settingsRoutes.TEAM_ADD,
    component: ModuleAddTeam,
  },
  {
    name: "EditTeam",
    exact: true,
    path: settingsRoutes.TEAM_EDIT,
    component: ModuleAddTeam,
  },
  // {
  //   name: "ManageVideo",
  //   exact: true,
  //   path: settingsRoutes.GROW_TEST,
  //   component: TestGrow,
  // },
  {
    name: "ManageVideo",
    exact: true,
    path: settingsRoutes.GROW,
    component: ModuleManageVideo,
  },
  {
    name: "ManageAddVideo",
    exact: true,
    path: settingsRoutes.ADDVIDEO,
    component: ModuleAddVideo,
  },
  {
    name: "EditVideo",
    exact: true,
    path: settingsRoutes.ADDVIDEO,
    component: ModuleAddVideo,
  },
  {
    name: "IncentivePoint",
    exact: true,
    path: settingsRoutes.POINT,
    component: IncentivePoints,
  },
  {
    name: "IncentiveAddPoint",
    exact: true,
    path: settingsRoutes.POINT_ADD,
    component: IncentiveAddPoints,
  },
  {
    name: "IncentiveEditPoint",
    exact: true,
    path: settingsRoutes.POINT_EDIT,
    component: IncentiveAddPoints,
  },
  {
    name: "IncentiveReward",
    exact: true,
    path: settingsRoutes.REWARD,
    component: IncentiveReward,
  },
  {
    name: "IncentiveAddReward",
    exact: true,
    path: settingsRoutes.REWARD_ADD,
    component: IncentiveAddReward,
  },
  {
    name: "IncentiveStreak",
    exact: true,
    path: settingsRoutes.STREAK,
    component: IncentiveStreak,
  },
];

const ignoreBreadCrumbs = [adminRoutes.RELATIONSHIP];

const AdminRoutes = ({ ...props }) => {
  return <Route {...props} />;
};

const Content = () => {
  const { user } = useSelector((state) => state.user);
  const { tabs } = useSelector((state) => state.tab);
  const dispatch = useDispatch();
  const [leadSound, setLeadSound] = useState(false);
  const [oldVersion, setOldVersion] = useState(false);
  const [latestVersion, setLatestVersion] = useState(0);
  const [sadSound, setSadSound] = useState();
  const [stateModel, setStateModal] = useState(false);
  const [stateRollbackModel, setStateRollbackModel] = useState(false);
  const [tabData, setTabData] = useState([]);
  const { configData } = useSelector((state) => state.configData);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
    filter: {},
    sorter: {},
    populate: [
      { path: "relationships" },
      {
        path: "relationships",
        path: "userId",
        select: ["firstName", "lastName", "avatar"],
      },
    ],
  });
  const location = useLocation();
  const history = useHistory();

  const {
    isActive,
    message: stickyMessage,
    isAutoHide,
    showStickyMessage,
    close,
  } = useStickyMessage();

  const breadcrumbNameMap = [
    { path: "/dashboard", label: "Dashboard", isLink: true },
    { path: "/admin", label: "Admin", isLink: false },
    { path: "/profile", label: "Profile", isLink: false },
    { path: "/manage-password", label: "Manage Password", isLink: false },
    { path: "/connect", label: "Connect", isLink: false },
    {
      path: "/connect/broadcast",
      label: getDynamicMenuString(configData?.[0], ["connect", "sources"]),
      isLink: true,
    },
    {
      path: "/connect/team",
      label: getDynamicMenuString(configData?.[0], ["connect", "team"]),
      isLink: false,
    },
    {
      path: "/connect/team/find",
      label:
        "Find A " + getDynamicMenuString(configData?.[0], ["connect", "team"]),
      isLink: true,
    },
    { path: "/connect/team/:name", label: "", isLink: true },
    // { path: "/grow", label: "Grow", isLink: false },
    {
      path: "/grow/watch",
      label: getDynamicMenuString(configData?.[0], ["grow", "watch-videos"]),
      isLink: true,
    },
    { path: "/grow/watch/playlist", label: "Welcome", isLink: true },
    // { path: "/grow/watch/playlist/video", label: "Welcome", isLink: true },
    {
      path: "/grow/manage-video",
      label: getDynamicMenuString(configData?.[0], ["grow", "manage-videos"]),
      isLink: true,
    },
    { path: "/admin/general", label: "General Settings", isLink: false },
    { path: "/admin/general/portal", label: "Portal", isLink: true },
    { path: "/admin/general/user", label: "Users", isLink: true },
    { path: "/admin/general/api", label: "API", isLink: true },
    { path: "/admin/module", label: "Module Settings", isLink: false },
    {
      path: "/admin/module/broadcast",
      label: getDynamicMenuString(configData?.[0], ["connect", "sources"]),
      isLink: true,
    },
    {
      path: "/admin/module/event",
      label: getDynamicMenuString(configData?.[0], ["focus", "find-an-events"]),
      isLink: true,
    },
    { path: "/admin/module/type", label: "Type", isLink: true },
    {
      path: "/admin/module/team",
      label: getDynamicMenuString(configData?.[0], ["connect", "team"]),
      isLink: true,
    },
    {
      path: "/admin/module/grow",
      label: getDynamicMenuString(configData?.[0], ["grow", "manage-videos"]),
      isLink: true,
    },
    { path: "/admin/incentive", label: "Incentive Settings", isLink: false },
    { path: "/admin/incentive/point", label: "Points", isLink: true },
    { path: "/admin/incentive/reward", label: "Rewards", isLink: true },
    { path: "/admin/incentive/streak", label: "Streaks", isLink: true },
    {
      path: "/communnication-setting",
      label: "Communication Settings",
      isLink: true,
    },
    { path: "/focus", label: "Focus", isLink: false },
    {
      path: "/focus/events",
      label: getDynamicMenuString(configData?.[0], ["focus", "my-events"]),
      isLink: true,
    },
  ];
  const pathSnippets = location.pathname.split("/").filter((i) => i);
  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      const breadcrumb = breadcrumbNameMap.find((b) => b.path === url);
      return breadcrumb ? (
        <Breadcrumb.Item key={`${breadcrumb.path}_${index}`}>
          {breadcrumb.isLink ? (
            <Link to={breadcrumb.isLink && breadcrumb.path}>
              {breadcrumb.label}
            </Link>
          ) : (
            <span className="breadcrumbs_label">{breadcrumb.label}</span>
          )}
          {/* <Link to={breadcrumb.isLink && breadcrumb.path}>
            {breadcrumb.label}
          </Link> */}
        </Breadcrumb.Item>
      ) : null;
    })
    .filter((i) => i);

  const socket = useContext(SocketContext);
  useEffect(() => {
    const configs = JSON.parse(localStorage.getItem("config"));
    if (localStorage.getItem("version")) {
      const currentVersion = localStorage.getItem("version");
      if (localStorage.getItem("version")) {


        return axios.get(`${configs.antUrl}/release`).then((data) => {
          console.log(">>>>>data", data?.data?.data[data?.data?.total - 1].version)
          let latestVersion = data?.data?.data[data?.data?.total - 1].version;
          console.log(">>>>>>>latestVersion", latestVersion)
          setLatestVersion(data?.data?.data[data?.data?.total - 1]?.version)
          // const currentVersion = JSON.parse(localStorage.getItem("version"));
          if (latestVersion !== currentVersion) {
            setOldVersion(true);
          }
          return data?.data.data;
        });
      }
    }
  }, [localStorage.getItem("version")]);

  useEffect(() => {
    if (socket && socket?.disconnected) {
      socket.connect();
    }
    if (socket) {


      socket.emit("join", "relationships");
      socket.on("lead-updated", (data) => {
        if (data?.action === "create") {
          if (data?.data.members.length === 0 && data.data?.sourceId) {
            showStickyMessage("New relationship just came in!", true);

            setLeadSound(true);
            setTimeout(() => {
              setLeadSound(false);
            }, 2000);
          }
        } else if (data?.action === "patch") {
          RelationshipTabAPI.getRelationshipTabList().then((tabData) => {
            const compare = (a, b) => {
              const bandA = b.sequence;
              const bandB = a.sequence;

              let comparison = 0;
              if (bandB > bandA) {
                comparison = 1;
              } else if (bandB < bandA) {
                comparison = -1;
              }
              return comparison;
            };

            tabData?.data.sort(compare);

            if (
              data?.data?.claimedBy &&
              data?.data?.claimedBy?._id !== localStorage.getItem("userId") &&
              data?.data?.type === tabData?.data?.[1]?._id
            ) {
              message.info(
                "Sorry you missed out because you weren't fast enough."
              );

              setSadSound(true);
              setTimeout(() => {
                setSadSound(false);
              }, 2000);
            }
          });
        }
      });

      return () => {
        socket.emit("leave", "relationships");
      };
    }
  }, [socket]);

  const [play, { stop }] = useSound("/audio/lead.wav");
  const [playSad] = useSound("/audio/sad.ogg");

  useEffect(() => {
    if (leadSound) {
      play();
    }
    return () => {
      stop();
    };
  }, [leadSound]);

  useEffect(() => {
    if (sadSound) {
      playSad();
    }
    return () => {
      stop();
    };
  }, [sadSound]);
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">Home</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  const configs = JSON.parse(localStorage.getItem("config"));
  const businessName = localStorage.getItem("businessName");


  const handleRollback = () => {
    setStateModal(false)
    console.log(">>>>>>>>>>>>>>>>inside rollback")
    FileUploadAPI.getDetail(localStorage.getItem("portalId"), querystring({ type: "rollback" }))
    message.success("Rollback done successfully")

    dispatch(portal());
    dispatch(logout())
  }



  const handleDownload = () => {
    setStateRollbackModel(false)
    FileUploadAPI.getFile(querystring(paginationObj({ pagination }))).then(
      (data) => {
        axios
          .patch(`${configs.antUrl}/client/${localStorage.getItem("portalId")}`, {
            version: latestVersion,
          })
        dispatch(portal());
        dispatch(logout())
      }
    );
    PortalAPI.updatePortal(localStorage.getItem("portalId"), { version: latestVersion })
      .then((data) => {
        message.success("New version download started");

        dispatch(portal());
        dispatch(logout())

      })
      .catch((err) => {
        console.log(err.message);
        message.error(err.message);
      });

  }
  const handleRollbackModal = () => {

    setStateRollbackModel(true)
  }
  const handleModal = () => {

    setStateModal(true)



    // FileUploadAPI.getFile(querystring(paginationObj({ pagination }))).then(
    //   (data) => {
    //     axios
    //       .patch(`${configs.antUrl}/client/${localStorage.getItem("portalId")}`, {
    //         version: latestVersion,
    //       })
    //     dispatch(portal());
    //   }
    // );
    // PortalAPI.updatePortal(localStorage.getItem("portalId"), { version: latestVersion })
    //   .then((data) => {
    //     // message.success("Information saved successfully");

    //     dispatch(portal());
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //     message.error(err.message);
    //   });
  };



  return (
    <div style={{ marginTop: "65px" }}>
      {localStorage.getItem("version") && oldVersion && (
        <Alert
          message="Latest version is available! 🆕 "
          type="info"
          action={
            <Space>
              <Button size="small" type="text" onClick={() => handleModal()}>
                Click here to update
              </Button>
            </Space>
          }
          closable
        />
      )}


      {/* <Alert
        message="Rollback"
        type="info"
        action={
          <Space>
            <Button size="small" type="text" onClick={() => handleRollbackModal()}>
              Click here for rollback
            </Button>
          </Space>
        }
        closable
      /> */}



      <StickyMessage
        isActive={isActive}
        message={stickyMessage}
        isAutoHide={isAutoHide}
        onClick={() => {
          close();
          history.push(adminRoutes.RELATIONSHIP);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />
      {!ignoreBreadCrumbs.includes(location.pathname) && (
        <BreadCrumbsWrapper>
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
        </BreadCrumbsWrapper>
      )}

      {stateModel &&
        <Modal
          title="New version download"
          centered
          visible={stateModel}
          onOk={() => handleDownload()}
          onCancel={() => setStateModal(false)}
          width={1000}
        >
          <p>If you want a new version download then click ok and you will be logged out</p>
          <p>If you dont want a new version please click on cancel</p>

        </Modal>
      }
      {stateRollbackModel &&
        <Modal
          title="New version download"
          centered
          visible={stateRollbackModel}
          onOk={() => handleRollback()}
          onCancel={() => setStateRollbackModel(false)}
          width={1000}
        >
          <p>If you want rollback then click ok and you will be logged out</p>
          <p>If you dont want a new version please click on cancel</p>

        </Modal>
      }
      <Switch>
        <Route
          exact={true}
          path={adminRoutes.RELATIONSHIP}
          component={Relationship}
        />
        <Route exact={true} path={adminRoutes.MYEVENTS} component={MyEvents} />
        <Route
          exact={true}
          path={adminRoutes.FINDEVENTS}
          component={FindEvents}
        />
        <Route exact={true} path={adminRoutes.PLAYLIST} component={PlayList} />
        <Route
          exact={true}
          path={adminRoutes.PLAYLISTPAGE}
          component={PlayListPage}
        />
        <Route
          exact={true}
          path={adminRoutes.SETTING}
          component={Notification}
        />
        <Route
          exact={true}
          path={adminRoutes.MANAGEPASSWORD}
          component={ManagePassword}
        />
        <Route exact={true} path={adminRoutes.SOURCE} component={Source} />

        <Route exact={true} path={adminRoutes.VIDEO} component={Video} />
        <Route
          exact={true}
          path={adminRoutes.VIDEODETAIL}
          component={VideoDetail}
        />
        <Route
          exact={true}
          path={settingsRoutes.ADDVIDEO}
          component={ModuleAddVideo}
        />
        <Route exact={true} path={adminRoutes.TEAM} component={Team} />
        <Route
          exact={true}
          path={adminRoutes.TEAMDETAILS}
          component={TeamDetails}
        />

        <Route
          exact={true}
          path={adminRoutes.DASHBOARD}
          component={Dashboard}
        />
        <Route
          exact={true}
          path={adminRoutes.LINKCREATOR}
          component={LinkCreator}
        />
        <Route
          exact={true}
          path={adminRoutes.SOURCE_EDIT}
          component={LinkCreator}
        />
        <Route
          exact={true}
          path={settingsRoutes.EDITVIDEO}
          component={ModuleAddVideo}
        />
        <Route exact={true} path={adminRoutes.EDITVIDEO} component={AddVideo} />
        {userRouters.map((route, idx) => (
          <Route key={idx} {...route} />
        ))}

        {adminRouters.map((route, idx) => (
          <AdminRoutes key={route.name + idx} {...route} />
        ))}

        <Redirect from="/" to={adminRoutes.DASHBOARD} />
      </Switch>
    </div>
  );
};

export default React.memo(Content);
