import React, { useState, useEffect } from "react";
import { Row, Skeleton, Col, Image, Avatar, Form } from "antd";
import { EventCard } from "../../../ui/Card";
import { StyledEventWrapper } from "../style";
import { FormSelect } from "ui/Form/Select";
import {
  StyledAvatarGroup,
  StyledImageWrapper,
  StyledEventCardWrapper,
  StyledAvatarGroupAllWrapper,
  StyledAvatarGroupWrapper,
  StyledAllContentCardWrapper,
  StyledRsvpButtonWrapper,
  StyledContentCardWrapper,
  StyledSelectItemWrapper,
} from "ui/Card/EventCard";

const EventSection = ({ UserProfileEventData, tabKey }) => {
  const typesOfEvent = UserProfileEventData.map((e) => e.type);
  const [forKey, setForKey] = useState();
  const [loading, setLoading] = useState(false);

  const tpye = typesOfEvent.forEach((element) => {
    if (element === tabKey) {
      console.log("done");
    } else {
      console.log("not tabkey");
    }
  });

  useEffect(() => {
    var intervalID = setInterval(() => setLoading(true), 1000);
    return () => {
      clearInterval(intervalID);
      setLoading(false);
    };
  }, []);

  return (
    <StyledEventWrapper>
      {!loading && (
          <Row gutter={[30, 30]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
               <StyledEventCardWrapper>
              {/* <StyledImageWrapper> */}
                <Skeleton.Image size={"medium"}
               active={true} style={{ width:"100%" ,height:"200px"}}
                ></Skeleton.Image>
              {/* </StyledImageWrapper> */}
              <StyledAllContentCardWrapper>
                <StyledContentCardWrapper>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                </StyledContentCardWrapper>

                <StyledAvatarGroupAllWrapper>
                  <StyledAvatarGroupWrapper>
                    <StyledAvatarGroup maxCount={0}>
                      <Avatar />
                    </StyledAvatarGroup>
                  </StyledAvatarGroupWrapper>
                </StyledAvatarGroupAllWrapper>
                <Form layout="vertical">
                  <StyledSelectItemWrapper name="RSVP" label="RSVP">
                    <FormSelect
                      placeholder="Select type"
                      options={[
                        { label: "I'm Going", value: "I'm Going" },
                        {
                          label: "Interested",
                          value: "Interested",
                        },
                        { label: "Next Time", value: "Next Time" },
                      ]}
                    />
                  </StyledSelectItemWrapper>
                </Form>
                <StyledRsvpButtonWrapper>
                  <Skeleton.Button></Skeleton.Button>
                </StyledRsvpButtonWrapper>
              </StyledAllContentCardWrapper>
            </StyledEventCardWrapper>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
            <StyledEventCardWrapper>
              {/* <StyledImageWrapper> */}
                <Skeleton.Image size={"medium"}
               active={true} style={{ width:"100%" ,height:"200px"}}
                ></Skeleton.Image>
              {/* </StyledImageWrapper> */}
              <StyledAllContentCardWrapper>
                <StyledContentCardWrapper>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                </StyledContentCardWrapper>

                <StyledAvatarGroupAllWrapper>
                  <StyledAvatarGroupWrapper>
                    <StyledAvatarGroup maxCount={0}>
                      <Avatar />
                    </StyledAvatarGroup>
                  </StyledAvatarGroupWrapper>
                </StyledAvatarGroupAllWrapper>
                <Form layout="vertical">
                  <StyledSelectItemWrapper name="RSVP" label="RSVP">
                    <FormSelect
                      placeholder="Select type"
                      options={[
                        { label: "I'm Going", value: "I'm Going" },
                        {
                          label: "Interested",
                          value: "Interested",
                        },
                        { label: "Next Time", value: "Next Time" },
                      ]}
                    />
                  </StyledSelectItemWrapper>
                </Form>
                <StyledRsvpButtonWrapper>
                  <Skeleton.Button></Skeleton.Button>
                </StyledRsvpButtonWrapper>
              </StyledAllContentCardWrapper>
            </StyledEventCardWrapper>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
            <StyledEventCardWrapper>
              {/* <StyledImageWrapper> */}
                <Skeleton.Image size={"medium"}
               active={true} style={{ width:"100%" ,height:"200px"}}
                ></Skeleton.Image>
              {/* </StyledImageWrapper> */}
              <StyledAllContentCardWrapper>
                <StyledContentCardWrapper>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                </StyledContentCardWrapper>

                <StyledAvatarGroupAllWrapper>
                  <StyledAvatarGroupWrapper>
                    <StyledAvatarGroup maxCount={0}>
                      <Avatar />
                    </StyledAvatarGroup>
                  </StyledAvatarGroupWrapper>
                </StyledAvatarGroupAllWrapper>
                <Form layout="vertical">
                  <StyledSelectItemWrapper name="RSVP" label="RSVP">
                    <FormSelect
                      placeholder="Select type"
                      options={[
                        { label: "I'm Going", value: "I'm Going" },
                        {
                          label: "Interested",
                          value: "Interested",
                        },
                        { label: "Next Time", value: "Next Time" },
                      ]}
                    />
                  </StyledSelectItemWrapper>
                </Form>
                <StyledRsvpButtonWrapper>
                  <Skeleton.Button></Skeleton.Button>
                </StyledRsvpButtonWrapper>
              </StyledAllContentCardWrapper>
            </StyledEventCardWrapper> <StyledEventCardWrapper>
              {/* <StyledImageWrapper> */}
                <Skeleton.Image size={"medium"}
               active={true} style={{ width:"100%" ,height:"200px"}}
                ></Skeleton.Image>
              {/* </StyledImageWrapper> */}
              <StyledAllContentCardWrapper>
                <StyledContentCardWrapper>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                </StyledContentCardWrapper>

                <StyledAvatarGroupAllWrapper>
                  <StyledAvatarGroupWrapper>
                    <StyledAvatarGroup maxCount={0}>
                      <Avatar />
                    </StyledAvatarGroup>
                  </StyledAvatarGroupWrapper>
                </StyledAvatarGroupAllWrapper>
                <Form layout="vertical">
                  <StyledSelectItemWrapper name="RSVP" label="RSVP">
                    <FormSelect
                      placeholder="Select type"
                      options={[
                        { label: "I'm Going", value: "I'm Going" },
                        {
                          label: "Interested",
                          value: "Interested",
                        },
                        { label: "Next Time", value: "Next Time" },
                      ]}
                    />
                  </StyledSelectItemWrapper>
                </Form>
                <StyledRsvpButtonWrapper>
                  <Skeleton.Button></Skeleton.Button>
                </StyledRsvpButtonWrapper>
              </StyledAllContentCardWrapper>
            </StyledEventCardWrapper>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
            <StyledEventCardWrapper>
              {/* <StyledImageWrapper> */}
                <Skeleton.Image size={"medium"}
               active={true} style={{ width:"100%" ,height:"200px"}}
                ></Skeleton.Image>
              {/* </StyledImageWrapper> */}
              <StyledAllContentCardWrapper>
                <StyledContentCardWrapper>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                  <Skeleton.Input active={true} style={{ width: 180 ,height:20 ,marginBottom:20}}></Skeleton.Input>
                </StyledContentCardWrapper>

                <StyledAvatarGroupAllWrapper>
                  <StyledAvatarGroupWrapper>
                    <StyledAvatarGroup maxCount={0}>
                      <Avatar />
                    </StyledAvatarGroup>
                  </StyledAvatarGroupWrapper>
                </StyledAvatarGroupAllWrapper>
                <Form layout="vertical">
                  <StyledSelectItemWrapper name="RSVP" label="RSVP">
                    <FormSelect
                      placeholder="Select type"
                      options={[
                        { label: "I'm Going", value: "I'm Going" },
                        {
                          label: "Interested",
                          value: "Interested",
                        },
                        { label: "Next Time", value: "Next Time" },
                      ]}
                    />
                  </StyledSelectItemWrapper>
                </Form>
                <StyledRsvpButtonWrapper>
                  <Skeleton.Button></Skeleton.Button>
                </StyledRsvpButtonWrapper>
              </StyledAllContentCardWrapper>
            </StyledEventCardWrapper>
            </Col>
          </Row>
        )}
      {loading && tabKey === "Upcoming" ? (
        <Row gutter={[30, 30]}>
          {UserProfileEventData.map((item) => (
            <EventCard
              eventName={item.eventName}
              eventAt={item.eventAt}
              eventBy={item.eventBy}
              eventUsers={item.users}
              eventImage={item.image}
              eventType={item.type}
            />
          ))}
        </Row>
      ) : (
        <Row gutter={[30, 30]}>
          {UserProfileEventData.map((item) =>
            item.type === tabKey ? (
              <EventCard
                eventName={item.eventName}
                eventAt={item.eventAt}
                eventBy={item.eventBy}
                eventUsers={item.users}
                eventImage={item.image ? item.image : <Skeleton.Image />}
              />
            ) : (
              ""
            )
          )}
        </Row>
      )}
    </StyledEventWrapper>
  );
};

export default EventSection;
