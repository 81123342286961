import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from "react";
import { Modal, Form } from "antd";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoIosClose } from "react-icons/io";
import { useBeforeunload } from "react-beforeunload";
import { UserPost } from "../../../Components/Team/TeamDetail/LeftCards/UserPost";
import { Prompt } from "react-router";
import { Link as Links, useHistory } from "react-router-dom";
import { UserAvatar } from "ui/Avatar";
import { imagePath, nFormatter, _merge } from "utils/fn";
import dayjs from "dayjs";
import { Spacer } from "ui/Layout/Spacer";
import { useSelector } from "react-redux";
import ShowMoreMenu from "ui/Menu/ShowMore";
import { MdMoreVert } from "react-icons/md";
import { Button, message } from "antd";
import FbImageLibrary from "react-fb-image-grid";
import CommentBar from "ui/PostCommentBar/CommentBar";
import EditComment from "../../../Components/Team/TeamDetail/LeftCards/EditComment/EditComment";
import { adminRoutes } from "helpers/adminRoutes";
import ImagePreview from "ui/ImagePreview/ImagePreview";
import { paginationObj, querystring, shortenDateFromNow } from "utils/fn";
import EditPost from "../../../Components/Team/TeamDetail/LeftCards/EditPost/EditPost";
import styled from "styled-components";

import {
  UserPostCard,
  PostInfoWrapper,
  UserImageWrapper,
  PostTextWrapper,
  PostTextMobile,
  PostImageWrapper,
  LinkCommentWrapper,
  LinkIconWrapper,
  CommentIconWrapper,
  CommentWrapper,
  CommentImageWrapper,
  CommentTextCardWrapper,
  CommentTextCard,
  LinkTo,
  CommentImageWrap,
  StyledCommentLike,
  CommentLikeWrapper,
  ViewMoreCommentWrapper,
} from "../../../Components/Team/TeamDetail/LeftCards/styled";
import { useWindowSize } from "utils/hooks/useWindowSize";
import useSocket from "utils/hooks/useSocket";
import config from "helpers/config";
import TeamAPI from "api/http/team";
import {
  FaRegThumbsUp,
  FaThumbsUp,
  FaRegComments,
  FaComments,
} from "react-icons/fa";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import { SocketContext } from "utils/context/socket";

const NotificationRedireact = ({
  teamId,
  postId,
  commentId,
  teamData,
  teamIdUser,
  modalState,
  onCancel = console.log,
  onSubmit = console.log,
  userPostActions = console.log,
  from = "",
  _id = "",
  isEdit = false,
  ...props
}) => {
  const { user: loggedInUser } = useSelector((state) => state.user);
  const commentRef = useRef();
  const [isFormChange, setIsFormChange] = React.useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [updateComment, setUpdateComment] = useState(false);
  const [commentData, setCommentData] = useState("");
  const history = useHistory();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 3,
    total: 0,
    filter: {
      post: _id,
    },
    populate: [
      {
        path: "user",
        select: ["firstName", "lastName", "avatar"],
      },
      {
        path: "post",
        select: ["team"],
        populate: [
          {
            path: "team",
            select: ["admins"],
          },
        ],
      },
      {
        path: "isLiked",
        match: { likers: loggedInUser?._id || localStorage.getItem("userId") },
      },
    ],
    sorter: {
      order: "descend",
      field: "_id",
    },
  });

  const getCommentList = useCallback(
    (id, pagination, isLoadMore = true, idx = null) => {
      TeamAPI.getCommentList(
        id,
        querystring(
          paginationObj({
            ...pagination,
            current: idx
              ? Math.ceil(+pagination.current / +pagination.pageSize)
              : pagination.current,
          })
        )
      )
        .then(({ data }) => {
          setData(
            (d) => _merge(d, data?.data || [], "_id")
            // [...new Set([...d, ...data?.data])]
          );
          const current = idx
            ? Math.ceil(+pagination.current / +pagination.pageSize) + 1
            : +pagination.current + 1;
          setPagination((p) => ({
            ...p,
            ...(isLoadMore ? { current } : { pageSize: 10 }),
            total: data?.total,
          }));
        })
        .catch((error) => {
          console.log(error);
          message.error(error.message);
        });
    },
    []
  );

  useEffect(() => {
    if (postId) {
      getCommentList(postId, { ...pagination, pageSize: 3 }, false);
    }
    if (commentId) {
      TeamAPI.getPostComment(
        postId,
        commentId,
        querystring(
          paginationObj({
            populate: [
              {
                path: "user",
                select: ["firstName", "lastName", "avatar"],
              },
              {
                path: "post",
                select: ["team"],
                populate: [
                  {
                    path: "team",
                    select: ["admins"],
                  },
                ],
              },
              {
                path: "isLiked",
                match: {
                  likers: loggedInUser?._id || localStorage.getItem("userId"),
                },
              },
            ],
          })
        )
      )
        .then((e) => {
          // data.unshift(e.data);
          setData((d) => _merge([e.data], d, "_id"));
        })
        .catch((err) => {
          onCancel(false);
          message.info("Comment has been deleted");
          console.log("err", err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentId, getCommentList, postId]);

  const onSubmitForm = () => {
    onCancel(false);
    onSubmit();
  };

  const openPrompt = (message) => {
    var answer = window.confirm(message);
    if (answer === true) {
      onCancel(false);
      setIsFormChange(false);
      // props.setModalStat(false);
    } else {
    }
  };

  useBeforeunload((event) => {
    if (isFormChange) {
      event.preventDefault();
    }
  });

  const actionPostOptions = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: true,
    },
  ];
  const actionOptionsDelete = [
    {
      label: "Edit",
      value: "edit",
      isDanger: false,
    },
    {
      label: "Delete",
      value: "delete",
      isDanger: false,
    },
  ];
  const actionOptionsEdit = [
    {
      label: "Delete",
      value: "delete",
      isDanger: false,
    },
  ];

  const getPostdetails = (postId, cb) => {
    TeamAPI.getPost(teamId, postId.item)
      .then((data) => {
        if (typeof cb === "function") cb(null, data.data);
      })
      .catch((err) => {
        message.error(err.message);
        if (typeof cb === "function") cb(err);
      });
  };
  const updatePostAction = (
    { event, props },
    cb,
    isJson,
    showMessage = true
  ) => {
    setOpen(false);
    switch (event) {
      case "edit":
        getPostdetails(props.val, (err, result) => {
          if (!err) {
            setOpen(true);
          }
        });
        break;
      default:
        userPostActions({ event, props }, cb, isJson, showMessage);
        break;
    }
  };
  const { width } = useWindowSize();

  const truncatedDateForMobile = React.useCallback(
    (str) => {
      if (width < 768) {
        return shortenDateFromNow(str);
      } else {
        return str;
      }
    },
    [width]
  );
  const createComment = (postIds, payload) => {
    TeamAPI.createComment(postId, payload)
      .then(({ data: comment }) => {
        getCommentList(postId, { ...pagination, pageSize: 3 }, false);
        // setDataChange(false);
        // setData((d) => [...new Set([{ ...comment, user: loggedInUser }])]);
        // actions({ event: "refreshPost", props: { _id } }, () => {
        // getCommentList(_id, { ...pagination, pageSize: 3 }, false);
        // });
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  const actionHandler = (postId, commentId, idx, { event }) => {
    switch (event) {
      case "delete":
        TeamAPI.deleteComment(postId, commentId, {
          id: commentId,
        })
          .then(() => {
            message.success("Comment deleted");
            // removeFromData(commentId);
            // actions({ event: "refreshPost", props: { _id } }, () => {
            //   getCommentList(_id, pagination, true, idx);
            // });
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
        break;
      case "edit":
        TeamAPI.getPostComment(postId._id, commentId)
          .then((data) => {
            setCommentData(data.data);
          })
          .catch((error) => {
            console.log(error);
            message.error(error.message);
          });
        setUpdateComment(true);
        break;
      default:
        break;
    }
  };
  const onSubmitUpdate = (
    _id,
    data,
    cb,
    keepOpen = false,
    showMessage = true
  ) => {
    TeamAPI.updateComment(commentData.post, commentData._id, data, keepOpen)
      .then((resp) => {
        getCommentList(postId, { ...pagination, pageSize: 3 }, false);
        if (showMessage) {
          message.success("Comment successfully updated");
        }
        //     // getPostList(team, pagination);
        if (typeof cb === "function") cb();
        if (!keepOpen) onSubmitForm();
        // updatePostComment(commentData._id, resp.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        if (typeof cb === "function") cb(error.message);
      });
  };

  const mergeComments = useCallback(
    (comment, isCreate = false, isDelete = false) => {
      setData((dt) => {
        const idx = dt.findIndex((i) => i._id === comment._id);
        const d = [...dt];
        if (isDelete) {
          return d.filter((dd) => dd._id !== comment._id);
        }
        if (idx > -1) {
          d[idx]["comment"] = comment?.comment;
          d[idx]["image"] = comment?.image;
          d[idx]["likers"] = comment?.likers;
          d[idx]["isLiked"] = (comment?.likers || []).includes(
            loggedInUser?._id
          )
            ? 1
            : 0;
        } else {
          if (isCreate) {
            d.unshift(comment);
          }
        }
        return d;
      });
    },
    [loggedInUser]
  );
  const socket = useContext(SocketContext);

  useEffect(() => {
    if (socket && socket?.disconnected) {
      socket.connect();
    }
    if (socket) {
      socket.emit("join", postId);
      socket.on("comment-updated", ({ action, data }) => {
        switch (action) {
          case "patch":
          case "update":
            mergeComments(data);
            userPostActions({ event: "refreshPost", props: { _id } }, () => {});
            break;
          case "create":
            mergeComments(data, true);
            userPostActions({ event: "refreshPost", props: { _id } }, () => {});
            break;
          case "remove":
            mergeComments(data, false, true);
            userPostActions({ event: "refreshPost", props: { _id } }, () => {});
            break;

          default:
            userPostActions({ event: "refreshPost", props: { _id } }, () => {});
            break;
        }
      });
      // socket.emit("join", team);
      socket.on("post-updated", ({ action, data }) => {
        switch (action) {
          case "patch":
          case "update":
            // updatePostdata(data);
            userPostActions({ event: "refreshPost", props: { _id } }, () => {});
            break;

          case "remove":
            // updatePostdata(data, false, true);
            // refreshPost();
            break;

          default:
            break;
        }
      });
      return () => {
        socket.emit("leave", _id);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const commentLikeActionHandler = (comment) => {
    userPostActions(
      { event: "comment-like", props: { postId, comment } },
      (err, result) => {
        // const updatedCommentIdx = data.findIndex(
        //   (d) => d._id === result.comment
        // );
        // const uData = [...data];
        // uData[updatedCommentIdx]["likers"] = uData[updatedCommentIdx]["isLiked"]
        //   ? uData[updatedCommentIdx]["likers"].filter(
        //       (l) => l !== loggedInUser?._id
        //     )
        //   : [
        //       ...new Set([
        //         ...uData[updatedCommentIdx]["likers"],
        //         loggedInUser?._id,
        //       ]),
        //     ];
        // uData[updatedCommentIdx]["isLiked"] = uData[updatedCommentIdx][
        //   "isLiked"
        // ]
        //   ? 0
        //   : 1;
        // setData(uData);
      }
    );
  };

  const StyleLink = styled(Links)`
    font-size: 14px;
    line-height: 22px;
    color: #232333 !important;
    word-break: break-word;
    white-space: normal;

    &:hover {
      color: #46c6e7 !important;
      text-decoration-line: underline !important;
    }
  `;

  const handleTeamRedirect = (teamId) => {
    onCancel(false);
    history.push(adminRoutes.TEAMDETAILS.replace(":name", teamId));
  };
  return (
    <>
      <Modal
        getContainer={() => document.querySelector("#modal-container")}
        visible={modalState}
        onCancel={() => {
          setData([]);
          !isFormChange
            ? onCancel(false)
            : openPrompt(
                "You will lose all changes if you navigate away from this page without saving"
              );
        }}
        width={"1260px"}
        footer={null}
        style={{ borderRadius: "5px" }}
        className="modal_wrapper"
        closeIcon={<IoIosClose className="modal_close_icon" />}
        maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
        focusTriggerAfterClose={false}
        destroyOnClose={true}
      >
        <UserPostCard padding="20px 30px 30px">
          <PostInfoWrapper>
            <UserImageWrapper>
              <UserAvatar
                src={
                  teamData?.user?.avatar?.path
                    ? imagePath(teamData?.user?.avatar?.path)
                    : ""
                }
                fullName={`${teamData?.user?.firstName} ${teamData?.user?.lastName}`}
                size={53}
              />
            </UserImageWrapper>
            <PostTextWrapper>
              <h4 style={{ display: "flex" }}>
                {`${teamData?.user?.firstName || ""} ${
                  teamData?.user?.lastName || ""
                }`}
                {"    "}{" "}
                <div style={{ color: "#747487", marginLeft: "6px" }}> (</div>
                <StyleLink
                  onClick={() => handleTeamRedirect(teamData?.team?._id)}
                >
                  {teamData?.team?.name}
                </StyleLink>
                <div style={{ color: "#747487" }}>)</div>
              </h4>
              <span>{dayjs(teamData.createdAt).fromNow()}</span>
              <p>
                {/* {teamData.text} */}
                {teamData.text.replace(
                  new RegExp(
                    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+"
                  ),
                  ""
                )}
                <a
                  href={teamData.text.match(
                    /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g
                  )}
                  target="_blank"
                >
                  {" "}
                  {teamData.text.match(/(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g)}
                </a>
              </p>
            </PostTextWrapper>
            <Spacer />

            {loggedInUser?._id === (teamData ? teamData?.user?._id : "") && (
              <ShowMoreMenu
                mw={50}
                Icon={MdMoreVert}
                options={actionPostOptions}
                item={_id}
                onChange={(val) => {
                  updatePostAction({ event: val.key, props: { val, _id } });
                }}
              />
            )}

            <EditPost
              onCancel={(value) => setOpen(value)}
              setModelState={(value) => setOpen(value)}
              PostData={teamData}
              modalState={open}
              onSubmit={(data, cb, isJson, showMessage) => {
                updatePostAction(
                  { event: "update", props: { post: data, _id } },
                  cb,
                  isJson,
                  showMessage
                );
              }}
            />
          </PostInfoWrapper>
          <PostTextMobile>{teamData.text}</PostTextMobile>

          <PostImageWrapper
            ho={(teamData.image || []).length === 1 ? "45" : "35"}
          >
            {(teamData.image || []).length ? (
              <>
                <FbImageLibrary
                  className="classes-gallary"
                  images={
                    (teamData.image || []).length &&
                    teamData.image.map((img) => imagePath(img.path))
                  }

                  // countForm={3}
                  // renderOverlay={() => "Preview"}
                  // overlayBackgroundColor={"#0005"}
                />
                {/* <Image
                src={
                  (image || []).length && imagePath(_get(image, "0.path", ""))
                }
                preview={{ visible: false }}
                onClick={() => setVisible(true)}
              /> */}
                {/* <div style={{ display: "none" }}>
                <Image.PreviewGroup
                  preview={{
                    visible,
                    onVisibleChange: (vis) => setVisible(vis),
                  }}
                >
                  {image.map((img, idx) => (
                    <Image key={idx} src={imagePath(img.url)} />
                  ))}
                </Image.PreviewGroup>
              </div> */}
              </>
            ) : (
              ""
            )}
          </PostImageWrapper>
          <LinkCommentWrapper>
            <LinkIconWrapper>
              <Button
                onClick={() =>
                  updatePostAction({
                    event: teamData.isLiked ? "-like" : "+like",
                    props: { _id },
                  })
                }
                // onClick={() =>
                //   actions({ event: isLiked ? "-like" : "+like", props: { _id } })
                // }
                // type="primary"
                shape="circle"
                icon={
                  teamData.isLiked ? (
                    <FaThumbsUp className="like_icon" />
                  ) : (
                    <FaRegThumbsUp
                      className="like_icon"
                      onClick={() =>
                        updatePostAction({ event: "+like", props: { _id } })
                      }
                    />
                  )
                }
              />
              <span>{nFormatter(teamData.likers?.length)}</span>
            </LinkIconWrapper>

            <CommentIconWrapper>
              {teamData.totalComments > 0 ? (
                <Button
                  // type="primary"
                  shape="circle"
                  icon={<FaComments className="comment_icon" />}
                />
              ) : (
                <Button
                  // type="primary"
                  shape="circle"
                  icon={<FaRegComments className="comment_icon" />}
                />
              )}
              <span>{nFormatter(teamData.totalComments)}</span>
            </CommentIconWrapper>
          </LinkCommentWrapper>
          <CommentBar
            mb={30}
            _id={_id}
            onSubmit={createComment}
            ref={commentRef}
            // data={data}
            // actions={actions}
            // actionHandler={actionHandler}
            // totalComments={totalComments}
            // updateComment={updateComment}
            // getCommentList={getCommentList}
            // pagination={pagination}
          />
          <EditComment
            _id={_id}
            modalState={updateComment}
            commentRef={commentRef}
            onCancel={(value) => {
              setUpdateComment(value);
            }}
            isEdit={true}
            commentData={commentData}
            actions={userPostActions}
            getCommentList={getCommentList}
            onSubmitUpdate={onSubmitUpdate}
          />

          {(data || []).map((comment, idx) => (
            <CommentWrapper key={idx}>
              <CommentImageWrapper>
                <UserAvatar
                  src={
                    comment?.user?.avatar?.path
                      ? imagePath(comment?.user?.avatar?.path)
                      : ""
                  }
                  fullName={`${comment.user?.firstName} ${comment?.user?.lastName}`}
                  size={42}
                />
              </CommentImageWrapper>
              <CommentTextCardWrapper>
                <CommentTextCard
                  title={
                    <LinkTo
                      to={adminRoutes.PROFILE_VIEW.replace(
                        ":id",
                        comment.user?._id
                      )}
                    >
                      {`${comment.user?.firstName} ${comment?.user?.lastName}`}
                    </LinkTo>
                  }
                >
                  <p>
                    {/* {comment?.comment} */}{" "}
                    {comment?.comment.replace(
                      new RegExp(
                        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+"
                      ),
                      ""
                    )}
                    <a
                      href={comment?.comment.match(
                        /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g
                      )}
                      target="_blank"
                    >
                      {" "}
                      {comment?.comment.match(
                        /(?:(?:https?:\/\/)|(?:www\.))[^\s]+/g
                      )}
                    </a>
                  </p>
                  {/* {comment?.image ? (
                  <ImagePreview
                    preview={false}
                    src={
                      comment?.image?.path
                        ? imagePath(comment?.image?.path)
                        : ""
                    }
                    height={200}
                    width={200}
                  />
                ) : (
                  ""
                )} */}
                </CommentTextCard>
                <CommentImageWrap>
                  {comment?.image ? (
                    <ImagePreview
                      preview={false}
                      src={
                        comment?.image?.path
                          ? imagePath(comment?.image?.path)
                          : ""
                      }
                      height={200}
                      width={200}
                    />
                  ) : (
                    ""
                  )}
                </CommentImageWrap>
                <StyledCommentLike>
                  <Button
                    type="primary"
                    shape="circle"
                    onClick={() => commentLikeActionHandler(comment)}
                    icon={
                      comment?.isLiked ? (
                        <FaThumbsUp className="comment_like_icon" />
                      ) : (
                        <FaRegThumbsUp className="comment_like_icon" />
                      )
                    }
                  />
                  <span>{comment?.likers?.length || 0}</span>
                </StyledCommentLike>
                <CommentLikeWrapper>
                  <span
                    className={comment?.isLiked ? "like_text" : "unlike_text"}
                    onClick={() => commentLikeActionHandler(comment)}
                  >
                    Like
                  </span>
                  <span
                    className="reply_text"
                    // onClick={() => commentRef.current.focus()}
                  >
                    Reply
                  </span>
                  <span className="comment_at">
                    {truncatedDateForMobile(
                      dayjs(comment?.createdAt).fromNow()
                    )}
                  </span>
                </CommentLikeWrapper>
              </CommentTextCardWrapper>
              {comment.user._id === loggedInUser?._id ? (
                <ShowMoreMenu
                  mw={50}
                  Icon={MdMoreVert}
                  options={actionOptionsDelete}
                  item={comment}
                  onChange={(val) => {
                    actionHandler(comment.post, comment._id, idx, {
                      event: val.key,
                      val: val,
                    });
                  }}
                />
              ) : comment.post.team.admins.includes(loggedInUser?._id) ? (
                <ShowMoreMenu
                  mw={50}
                  Icon={MdMoreVert}
                  options={actionOptionsEdit}
                  item={comment}
                  onChange={(val) => {
                    actionHandler(comment.post, comment._id, idx, {
                      event: val.key,
                      val: val,
                    });
                  }}
                />
              ) : (
                ""
              )}
            </CommentWrapper>
          ))}
          {teamData.totalComments > data.length && (
            <ViewMoreCommentWrapper>
              <Button
                type="link"
                onClick={() => getCommentList(postId, pagination)}
              >
                View {teamData.totalComments - (data.length || 0)} More Comments
              </Button>
            </ViewMoreCommentWrapper>
          )}
        </UserPostCard>
      </Modal>
    </>
  );
};

export default NotificationRedireact;
