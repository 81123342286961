import React, { useState, useCallback, useEffect } from "react";
import { HeadingWithIconButton } from "ui/Heading";
import { Container } from "ui/Layout/Container";
import {
  ProfileMobileTabWrapper,
  StyledVideoProgressWrapper,
} from "Components/Profile/style";
import { IoIosClose } from "react-icons/io";

import { Tabs, Row, Col, Skeleton, Slider, Modal, Input, Form } from "antd";
import VideoSection from "./VideoSection";
// import { UserProfileVideosDataHome } from "Components/Profile/ProfileData";
import { PaginationBar } from "ui/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { teamStatus, teamVisiblity } from "utils/constants";
import TeamAPI from "api/http/team";
import { imagePath, paginationObj, querystring, searchObj } from "utils/fn";
import { message } from "antd";
import { PrimaryButton } from "../../ui/Button/Button";

import {
  FloatButton,
  StyledContentTitle,
  StyledFormControl,
  StyledFormItem,
} from "Components/Relationship/TheRelationship.styles";
import { SecondaryButton } from "ui/Button/Button";
import { HiPlus } from "react-icons/hi";
import {
  StyledVideoCard,
  StyledVideoCardWrapper,
  StyledVideoContentWrapper,
} from "./VideoSection.style";
import { Link } from "react-router-dom";
import {
  SliderBar,
  StyledImageWrapper,
  StyledVideoIconWrapper,
} from "./PlayListVideoSection.style";
import { FaPlayCircle } from "react-icons/fa";
import GrowAPI from "api/http/grow";
import { StyledButton } from "containers/AuthLayout/AuthLayout.styles";
import {
  FileUpload,
  FormInput,
  FormItem,
  FormSelect,
  FormSelectSearch,
  FormTextArea,
  ToggleSwitch,
} from "ui/Form/index";
import { useParams } from "react-router";

import UserAPI from "api/http/user";
import { Card } from "ui/Card/Card";
import { adminRoutes, settingsRoutes } from "helpers/adminRoutes";
import { AddNewField } from "Components/Admin/IncentiveSetting/Rewards/style";
import { useHistory } from "react-router-dom";
import VideoAPI from "api/http/videos";

const WatchVideo = () => {
  const { TabPane } = Tabs;
  const [UserProfileVideosDataHome, setUserProfileVideosDataHome] = useState(
    []
  );
  const [form] = Form.useForm();
  const history = useHistory();

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { configData } = useSelector((state) => state.configData);

  const [loading, setLoading] = useState(true);
  const [modalState, setmodalState] = useState(false);
  const [video, setVideo] = useState("");
  const [playlistToggle, setPlaylistToggle] = useState(false);
  const [rewardableToggle, setRewardableToggle] = useState(false);
  const [playList, setPlayList] = useState([]);
  const [users, setUsers] = useState([]);
  const params = useParams();
  const [premiumContentToggle, setPremiumContentToggle] = useState(false);

  const userId = localStorage.getItem("userId");
  const defaultFilter = {
    visibility: {
      $ne: teamVisiblity.hidden,
    },
    _id: {
      $nin: (user?.teams || []).map((t) => t?.team?._id),
    },
  };
  const [paginations, setPaginations] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
    populate: [
      {
        path: "allVideos",
        match: {
          $or: [
            { uploadedBy: user?._id || localStorage.getItem("userId") },
            { visibility: "Public" },
          ],
          status: true,
        },
        populate: [
          {
            path: "videoTimer",
            match: {
              userId: user?._id || localStorage.getItem("userId"),
            },
          },
          {
            path: "uploadedBy",
          },
        ],
      },
    ],
    // filter: { allVideos: { $size: { $gt: 0 } } },
  });

  const getVideoList = useCallback((paginations) => {
    GrowAPI.getGrowList(querystring(paginationObj(paginations)))
      .then(({ data }) => {
        let arr = [];
        setPaginations((p) => ({ ...p, total: data?.total }));
        console.log("data?.data", data?.data);
        if (data?.data) {
          for (const dt of data?.data) {
            if (dt?.allVideos?.length >= 1) {
              arr.push(dt);
            }
          }
        }
        setUserProfileVideosDataHome(arr);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  }, []);

  useEffect(() => {
    getVideoList(paginations);

    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (user && user?._id) {
      form.setFieldsValue({
        uploadedBy: [user?._id || localStorage.getItem("userId")],
      });
    }
    getUserList({
      filter: {
        $or: [{ _id: { $in: user?._id || localStorage.getItem("userId") } }],
      },
    });
  }, [user]);

  const handleFinish = (values) => {
    const formdata = new FormData();
    Object.keys(values).forEach((k) => {
      if (values[k]) formdata.append(k, values[k]);
    });
    if (video?.video && !video.video?.url) {
      formdata.append("video", video?.video);
    }
    // if (params.id) {
    //   GrowAPI.updateGrow(params.id, formdata);
    formdata.append("numberOfVideos", 1);
    formdata.append("isPartOfPlaylist", false);
    formdata.append("current", true);
    // } else {
    GrowAPI.addGrow(formdata)
      .then((data) => {
        console.log("data", data);
        formdata.append("playlist", data?.data?._id);
        // VideoAPI.addVideo(formdata);
        VideoAPI.addVideo(formdata).then((data) => {
          VideoAPI.updateVideo(data?.data?._id, {
            thumbnail: `/uploads/frames/${data?.data.video.filename}`.replace(
              /(\.[\w\d_-]+)$/i,
              "_1.jpg"
            ),
            thumbnailImages: [
              `/uploads/frames/${data?.data.video.filename}`.replace(
                /(\.[\w\d_-]+)$/i,
                "_1.jpg"
              ),
              `/uploads/frames/${data?.data.video.filename}`.replace(
                /(\.[\w\d_-]+)$/i,
                "_2.jpg"
              ),
              `/uploads/frames/${data?.data.video.filename}`.replace(
                /(\.[\w\d_-]+)$/i,
                "_3.jpg"
              ),
              `/uploads/frames/${data?.data.video.filename}`.replace(
                /(\.[\w\d_-]+)$/i,
                "_4.jpg"
              ),
            ],
          });
        });
      })
      .then((data) => {
        setmodalState(false);
        getVideoList(paginations);
      });
    // }
  };

  const searchUsers = (search) => {
    const filter = searchObj(["email", "firstName", "lastName"], search);
    return getUserList({ filter });
  };

  useEffect(() => {
    if (params.id) {
      GrowAPI.getGrowListDetail(params.id)
        .then(({ data }) => {
          form.setFieldsValue({
            name: data.name,
            description: data.description,
            // availability: data.availability,
            uploadedBy: data.uploadedBy,
            visibility: data.visibility,
            isPartOfPlaylist: data.isPartOfPlaylist,
          });
          setRewardableToggle(data.rewardable);
          setPremiumContentToggle(data.premiumContent);
          setPlaylistToggle(data.isPartOfPlaylist);
          if (data?.video) {
            setVideo((s) => ({
              ...s,
              video: {
                ...data.video,
                name: data.video?.originalName,
                url: imagePath(data.video.path),
              },
              video_src: imagePath(data.video.path),
            }));
          }
        })

        .catch((error) => {
          if (error) {
            message.error();
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const VideoUpload = {
    name: "video",
    multiple: false,
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        console.log(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        console.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const isVideo = /video\/.+/gi.test(file.type);
      if (!isVideo) {
        message.error("You can only upload video file");
        return false;
      }
      // const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isLt2M) {
      //   message.error("Video must smaller than 10MB!");
      //   return false;
      // }
      getBase64(file, (base64) =>
        setVideo((s) => ({
          ...s,
          video_src: base64,
          video: file,
        }))
      );
      return false;
    },
    onRemove: (file) => {
      setVideo({
        video: "",
        video_src: "",
      });
    },
    fileList: video?.video ? [video?.video] : [],
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleRewardableToggle = (values) => {
    setRewardableToggle(values);
  };
  const handleplaylistToggle = (values) => {
    // setPlaylistToggle(values);
    setPlaylistToggle(false);
  };

  const createOptions = (data = []) => {
    return data.map((u) => ({
      avatar: u?.avatar
        ? { src: imagePath(u?.avatar?.path) }
        : { fullName: `${u?.firstName || ""} ${u?.lastName || ""}` },
      label: `${u.firstName} ${u.lastName}`,
      value: u._id,
    }));
  };

  const getUserList = useCallback(({ filter = {} }) => {
    return UserAPI.getUserList(querystring(paginationObj({ filter })))
      .then(({ data }) => {
        setUsers(createOptions(data.data));
        return createOptions(data.data);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
        return [];
      });
  }, []);

  return (
    <>
      <Container minH="100%" padding={"17px 30px 0px 30px"}>
        <HeadingWithIconButton
          buttonProps={{
            type: "secondary",
            onClick: () => setmodalState(true),
          }}
        >
          {configData?.[0].module?.[2]?.children?.[0]?.name}
        </HeadingWithIconButton>
      </Container>
      <Container>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <StyledVideoCardWrapper wd={"100"} ml={"0"}>
              <Row gutter={[30, 30]}>
                {loading &&
                  [...Array(12).keys()].map((v) => (
                    <Col key={v} xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                      <StyledVideoCard>
                        <Skeleton.Image
                          active={loading}
                          style={{ width: "100%" }}
                        ></Skeleton.Image>
                        <StyledVideoProgressWrapper></StyledVideoProgressWrapper>
                        <StyledVideoContentWrapper mh={"0"}>
                          <Skeleton.Input
                            active={loading}
                            style={{
                              width: "100%",
                              height: 40,
                              marginBottom: 20,
                            }}
                          ></Skeleton.Input>
                          <Skeleton.Input
                            active={loading}
                            style={{
                              width: "100%",
                              height: 40,
                              marginBottom: 20,
                            }}
                          ></Skeleton.Input>
                          <Skeleton.Input
                            active={loading}
                            style={{
                              width: "100%",
                              height: 40,
                              marginBottom: 20,
                            }}
                          ></Skeleton.Input>
                        </StyledVideoContentWrapper>
                      </StyledVideoCard>
                    </Col>
                  ))}
              </Row>
            </StyledVideoCardWrapper>
            <VideoSection UserProfileVideosData={UserProfileVideosDataHome} />
          </Col>
        </Row>

        {modalState !== false && (
          <Modal
            visible={modalState}
            onCancel={
              () => setmodalState(false)
              //  openPrompt(
              //     "You will lose all changes if you navigate away from this page without saving"
              //   )
            }
            width={"960px"}
            footer={null}
            // onOk={form.submit}
            style={{ borderRadius: "5px" }}
            className="modal_wrapper"
            closeIcon={<IoIosClose className="modal_close_icon" />}
            maskStyle={{ backgroundColor: "rgba(35, 35, 51, 0.5)" }}
            focusTriggerAfterClose={false}
          >
            <StyledContentTitle>Add New Video</StyledContentTitle>
            <StyledFormControl>
              <Row gutter={30}>
                <Form
                  layout="vertical"
                  onFinish={(e) => handleFinish(e)}
                  requiredMark={false}
                  form={form}
                  // initialValues={{ e }}
                  onValuesChange={() => {
                    // setShowPrompt(true);
                  }}
                >
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem
                      label="Video Name"
                      name="name"
                      type="text"
                      rules={[
                        {
                          required: true,
                          message: "This is required",
                        },
                      ]}
                    >
                      <FormInput placeholder="Enter video name" />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem
                      label="Description"
                      name="description"
                      type="text"
                      rules={[
                        {
                          required: true,
                          message: "This is required",
                        },
                      ]}
                    >
                      <FormTextArea placeholder="Enter description text" />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem label="Uploaded By" name="uploadedBy" type="text">
                      <FormSelectSearch
                        placeholder="Select Users"
                        mode="multiple"
                        filterOption={false}
                        defaultOptions={users}
                        onSearch={searchUsers}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem label="Visibility" name="visibility" type="text">
                      <FormSelect
                        placeholder="Select visibility"
                        maxWidth={"850px"}
                        options={[
                          { label: "Public", value: "Public" },
                          {
                            label: "Only Me",
                            value: "Only Me",
                          },
                        ]}
                      />
                    </FormItem>
                  </Col>

                  {/* {playList &&
                        playList.length > 0 &&
                        playList.map((e, index) => (
                          <>
                            <FormItem>
                              <FormInput></FormInput>
                            </FormItem>
                          </>
                        ))} */}
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem
                      label="Is This Rewardable"
                      name="rewardable"
                      type="text"
                    >
                      <ToggleSwitch
                      // checked={rewardableToggle}
                      // onChange={(e) => handleRewardableToggle(e)}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem
                      label="Premium Content"
                      name="premiumContent"
                      type="text"
                    >
                      <ToggleSwitch
                        checked={premiumContentToggle}
                        onChange={(e) => setPremiumContentToggle(e)}
                      />
                    </FormItem>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <FormItem label="Video Upload" maxWidth={"822px"}>
                      <FileUpload {...VideoUpload} />
                    </FormItem>
                  </Col>

                  <FormItem style={{ marginLeft: "85px" }}>
                    <PrimaryButton
                      margin="15px 0 0 0"
                      htmlType="submit"
                      loading={loading}
                    >
                      Save
                    </PrimaryButton>
                  </FormItem>
                </Form>
              </Row>
              {/* <RelationshipContent addEdit={addEdit}></RelationshipContent> */}
            </StyledFormControl>
          </Modal>
        )}
      </Container>
    </>
  );
};

export default WatchVideo;
