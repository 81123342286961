import { httpAPI } from "api";

const PointsAPI = {
  addPoints(params) {
    return httpAPI(`/points`, params, "POST");
  },
  getPointsList(query) {
    return httpAPI(`/points?${query || ""}`, "", "GET");
  },
  getPoints(id = "", query = "") {
    return httpAPI(`/points/${id}?${query}`, "", "GET");
  },
  updatePoints(id, params) {
    return httpAPI(`/points/${id}`, params, "PATCH");
  },
  deletePoints(id, params) {
    return httpAPI(`/points/${id}`, params, "DELETE");
  },
};

export default PointsAPI;
