import React, { useEffect } from "react";
import { Form, Image, Row, Col, message } from "antd";
import { SecondaryButton } from "ui/Button";
import {
  StyledContainer,
  StyledFormWrapper,
  StyledLogo,
  StyledSubmitButtonWrapper,
  StyledTextWrapper,
  StyledTextWrapperNoData,
  StyledVideo,
} from "./style";
import { FormItem } from "ui/Form/Input";
import { FormInput } from "ui/Form/Input";
import dayjs from "dayjs";

import { useParams, useLocation } from "react-router";
import SourceAPI from "api/http/source";
import RelationshipAPI from "api/http/relationship";
import NotificationAPI from "api/http/notification";
import { useProgressiveImage } from "utils/hooks/useProgressiveImage";
import { paginationObj, querystring, searchObj } from "utils/fn";
import { useSelector, useDispatch } from "react-redux";
import RelationshipTabAPI from "api/http/relationshipTab";
import { portal } from "store/reducers/user.reducer";
import { Beforeunload } from "react-beforeunload";
import { Prompt } from "react-router";

const Generate = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [defaulState, setDefaultState] = React.useState(false);
  const [dataChange, setDataChange] = React.useState(false);
  const location = useLocation();
  const [form] = Form.useForm();
  const [tabData, setTabData] = React.useState();
  const [stateData, setStateData] = React.useState({
    data: [],
  });
  const { landingPageLogo, landingPageBackground } = useSelector(
    (state) => state.user
  );
  const loadingLogo = useProgressiveImage(landingPageLogo);
  const bgImage = useProgressiveImage(landingPageBackground);

  const [data, setData] = React.useState({
    data: [],
  });
  const [inputValue, setInputValue] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    "location.address": "",
  });

  useEffect(() => {
    dispatch(portal());
  }, []);
  useEffect(() => {
    let id = location.pathname.replace("/landing/source/", "");
    if (id) {
      SourceAPI.getSourceListDetail(
        "111",
        querystring({ slug: id, status: true })
      )

        .then(({ data }) => {
          if (data && data.contact) {
            setStateData({ data: data.contact });
            setData({ data: data });
            let arr = [];
            for (const a of data.contact) {
              arr.push(a.fieldName);
            }
            RelationshipTabAPI.getRelationshipTabList()
              .then(({ data }) => {
                function compare(a, b) {
                  const bandA = b.sequence;
                  const bandB = a.sequence;

                  let comparison = 0;
                  if (bandB > bandA) {
                    comparison = 1;
                  } else if (bandB < bandA) {
                    comparison = -1;
                  }
                  return comparison;
                }

                data.sort(compare);
                setTabData(data);
              })
              .catch((error) => {
                if (error) {
                  message.error(error.message);
                }
              });
          } else {
            setData({
              data: "Sorry the web page is not currently available...",
            });
          }
        })

        .catch((error) => {});
    }
  }, [params.id]);

  const onFinish = (values) => {
    setDataChange(false);

    console.log("values", values);
    let datass = values;
    datass.type = tabData[0]?._id;
    datass.sourceId = data.data._id;
    datass.activities = {
      text: `came in at`,
      name: `${values.firstName ? values.firstName : ""}`,
    };
    datass.contacted = false;

    if (data.data?.visibilityType === "All users") {
      datass.members = [];
    } else {
      datass.members = data.data.userId;
    }
    RelationshipAPI.addRelationship(datass)
      .then(({ data }) => {
        message.success("Successfully submitted!");
        form.resetFields();
        setInputValue("");
      })
      .catch((error) => {
        if (error) {
          message.error(error.message);
        }
      });
  };

  const convertToPlayer = (source, link) => {
    switch (source) {
      case "youtube":
        return link.replace("watch?v=", "embed/");
      case "vimeo":
        return link.replace("vimeo.com", "player.vimeo.com/video");
      case "gdrive":
        return link.replace("/view", "/preview");

      default:
        return link;
    }
  };

  const fetchPlayerLink = (link) => {
    if (`${link}`.search("youtube") > -1) {
      return convertToPlayer("youtube", link);
    } else if (`${link}`.search("vimeo") > -1) {
      return convertToPlayer("vimeo", link);
    } else if (`${link}`.search("drive.google") > -1) {
      return convertToPlayer("gdrive", link);
    } else {
      return convertToPlayer("other", link);
    }
  };

  function formatPhoneNumber(value) {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const handleInput = (e, type) => {
    if (type === "phoneNumber") {
      console.log(formatPhoneNumber(e.target.value));
      const formattedPhoneNumber = formatPhoneNumber(e.target.value);
      setInputValue((p) => ({
        ...p,
        phoneNumber: formattedPhoneNumber,
      }));
      form.setFieldsValue({ phoneNumber: formattedPhoneNumber });
    } else if (type === "firstName") {
      setInputValue((p) => ({
        ...p,
        firstName: e.target.value,
      }));
    } else if (type === "lastName") {
      setInputValue((p) => ({
        ...p,
        lastName: e.target.value,
      }));
    } else if (type === "email") {
      setInputValue((p) => ({
        ...p,
        email: e.target.value,
      }));
    } else if (type === "address") {
      setInputValue((p) => ({
        ...p,
        "location.address": e.target.value,
      }));
    }
  };
  let bg = document.querySelectorAll("#bg1");
  function resizeBackground() {
    bg.height(window.innerHeight);
  }
  console.log("bg.height(window.height())", bg.height, bg);
  window.addEventListener("resize", resizeBackground);

  useEffect(() => {
    // window.onresize(resizeBackground);
    // resizeBackground();
  }, []);

  // function resizeBackground() {
  //   bg.height(window.height());
  // }

  // window.resize(resizeBackground);
  // resizeBackground();

  return (
    <StyledContainer id="bg1" backgroundImg={bgImage}>
      {/* { form.isFieldsTouched() || (dataChange===true && defaulState === false) && <Beforeunload
                    onBeforeunload={() => "You will lose all changes if you navigate away from this page without saving"} />}
                <Prompt
                    when={form.isFieldsTouched()||  (dataChange===true && defaulState === false)}
                    message='You will lose all changes if you navigate away from this page without saving'

                    ></Prompt> */}
      {data?.data?.contact ? (
        <>
          <Row>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 6 }}
              lg={{ span: 12, offset: 6 }}
              xl={{ span: 12, offset: 6 }}
              xxl={{ span: 12, offset: 6 }}
            >
              <StyledLogo>
                {loadingLogo && <Image src={loadingLogo} preview={false} />}
              </StyledLogo>
              {/* {data && data.data && data.data.videoEmbed} */}
              {data &&
                data.data &&
                data.data.videoEmbed &&
                data.data.videoEmbed && (
                  <StyledVideo>
                    {
                      <iframe
                        width="600"
                        height="400"
                        src={
                          data &&
                          data.data &&
                          data.data.videoEmbed &&
                          fetchPlayerLink(data.data.videoEmbed)
                        }
                        title="Video Player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    }
                  </StyledVideo>
                )}
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 6 }}
              lg={{ span: 12, offset: 6 }}
              xl={{ span: 12, offset: 6 }}
              xxl={{ span: 12, offset: 6 }}
            >
              <StyledTextWrapper>
                <h1 className="heading">
                  {data && data.data && data.data.header}
                </h1>
              </StyledTextWrapper>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 24, offset: 0 }}
              sm={{ span: 24, offset: 0 }}
              md={{ span: 12, offset: 6 }}
              lg={{ span: 12, offset: 6 }}
              xl={{ span: 12, offset: 6 }}
              xxl={{ span: 12, offset: 6 }}
            >
              <StyledTextWrapper>
                <p className="description">
                  {data && data.data && data.data.description}
                </p>
              </StyledTextWrapper>
            </Col>
          </Row>

          <StyledFormWrapper>
            <Form
              form={form}
              layout="vertical"
              onFinish={(e) => onFinish(e)}
              requiredMark={true}
            >
              <Row>
                {stateData.data.map((item) => (
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    sm={{ span: 24, offset: 0 }}
                    md={{ span: 12, offset: 6 }}
                  >
                    <FormItem
                      label={
                        (item.fieldName === "firstName" && "First Name") ||
                        (item.fieldName === "lastName" && "Last Name") ||
                        (item.fieldName === "email" && "Email") ||
                        (item.fieldName === "phoneNumber" && "Phone Number") ||
                        (item.fieldName === "address" && "Address")
                      }
                      name={
                        item.fieldName === "address"
                          ? "location.address"
                          : item.fieldName
                      }
                      type="text"
                      color="#ffff"
                      rules={[
                        {
                          required: item.required,
                          message: "This is required",
                        },
                      ]}
                    >
                      <FormInput
                        maxWidth={"100%"}
                        onChange={(e) => handleInput(e, item.fieldName)}
                        value={inputValue[item.fieldName]}
                      />
                    </FormItem>
                  </Col>
                ))}
              </Row>
              <StyledSubmitButtonWrapper>
                <SecondaryButton htmlType="submit">Submit</SecondaryButton>
              </StyledSubmitButtonWrapper>
            </Form>
          </StyledFormWrapper>
        </>
      ) : (
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 12, offset: 6 }}
            lg={{ span: 12, offset: 6 }}
            xl={{ span: 12, offset: 6 }}
            xxl={{ span: 12, offset: 6 }}
          >
            <StyledTextWrapperNoData>
              <p className="description">{data.data}</p>
            </StyledTextWrapperNoData>
          </Col>
        </Row>
      )}
    </StyledContainer>
  );
};

export default Generate;
