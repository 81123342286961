import styled from "styled-components";
import { PrimaryButton, SecondaryButton } from "ui/Button";

export const StyledHeaderTextwrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  button {
    margin-right: 14px;

    @media (max-width: 991.98px) {
      display: none;
    }
  }

  .plus_icon {
    font-size: 34px;
  }
`;

export const StyledActionButton = styled(PrimaryButton)`
  margin-left: auto;

  @media (max-width: 991.98px) {
    min-width: 80px !important;
    height: 36px !important;
    margin-left: 0;
  }
`;

export const StyledAddButton = styled(SecondaryButton)`
  margin-right: 14px;
`;

export const AcceptDeniedButton = styled.div`
  display: flex;
  height: 48px;
  min-width: 120px;
  @media (max-width: 991.98px) {
    min-width: 80px !important;
    height: 36px !important;
    margin-left: 0;
  }

  button {
    height: 48px;
    width: 50%;
    border: none;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .greenBtn {
    border-radius: 5px 0 0 5px;
    background: ${(props) => props.theme.colors.primary};
    color: white;
    :hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }

  button:hover {
    border: 1px solid #02162f;
    background: #fff;
  }
  .redBtn {
    border-radius: 0 5px 5px 0;
    background: ${(props) => props.theme.colors.primary};
    color: white;
    :hover {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;
