import { httpAPI } from "api";

const PortalAPI = {
  addPortal(params) {
    return httpAPI(`/portals`, params, "post");
  },

  getPortalList(query) {
    return httpAPI(`/portals?${query || ""}`, "", "get");
  },
  getPortalListDetail(id) {
    return httpAPI(`/portals/${id}`, "", "get");
  },
  updatePortal(id, params) {
    return httpAPI(`/portals/${id}`, params, "patch");
  },
  deletePortal(id, params) {
    return httpAPI(`/portals/${id}`, params, "delete");
  },
};

export default PortalAPI;
