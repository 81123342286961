import { httpAPI } from "api";

const EventAPI = {
  addEvent({ image, ...data }) {
    const formdata = new FormData();
    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((ele, index) => {
          const tempFormKey = `${key}[${index}]`;
          formdata.append(`${tempFormKey}`, ele);
        });
      } else if (typeof data[key] === "object") {
        for (let subKey in data[key]) {
          formdata.append(`${key}.${subKey}`, data[key][subKey]);
        }
      } else {
        if (data[key]) formdata.append(key, data[key]);
      }
    }
    if (image) formdata.append("image", image);
    return httpAPI(`/events`, formdata, "POST", true);
  },
  getEventList(query) {
    return httpAPI(`/events?${query || ""}`, "", "GET");
  },
  getEvent(id, query = "") {
    return httpAPI(`/events/${id}?${query}`, "", "GET");
  },
  updateEvent(id, { image, ...data }) {
    const formdata = new FormData();
    for (let key in data) {
      if (Array.isArray(data[key])) {
        data[key].forEach((ele, index) => {
          const tempFormKey = `${key}[${index}]`;
          formdata.append(`${tempFormKey}`, ele);
        });
      } else if (typeof data[key] === "object") {
        for (let subKey in data[key]) {
          formdata.append(`${key}.${subKey}`, data[key][subKey]);
        }
      } else {
        if (data[key]) formdata.append(key, data[key]);
      }
    }
    if (image) formdata.append("image", image);
    return httpAPI(`/events/${id}`, formdata, "PATCH", true);
  },
  deleteEvent(id, params) {
    return httpAPI(`/events/${id}`, params, "DELETE");
  },
};

export default EventAPI;
