import styled from "styled-components";

export const DescriptionLongText = styled.p`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  color: #747487;
  display: -webkit-box;
  -webkit-line-clamp: ${({ maxLines }) => maxLines || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
`;
