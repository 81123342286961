import React, { useState, useEffect } from "react";
import { Button, Form, Input, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import { adminRoutes, authRoutes } from "../helpers/adminRoutes";
import styled from "styled-components";
import EmailAPI from "api/http/email";
import { useParams, useLocation } from "react-router";
import { paginationObj, querystring } from "utils/fn";
import {
  StyledBg,
  StyledLogoLabel,
} from "containers/AuthLayout/AuthLayout.styles";
import { useProgressiveImage } from "utils/hooks/useProgressiveImage";
import { useSelector } from "react-redux";

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 474px;
  max-width: 100%;
`;

const EmailVerify = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const { landingPageLogo, homePageBackground } = useSelector(
    (state) => state.user
  );

  const bgImage = useProgressiveImage(homePageBackground);
  const logoImage = useProgressiveImage(landingPageLogo);

  useEffect(() => {
    EmailAPI.getEmail().then(({ data }) => {
      EmailAPI.verifyEmail(data?.data[0]._id, { id: params.id })
        .then(({ data }) => {
          localStorage.removeItem("token");
          localStorage.setItem("token", window.atob(params.id));
          localStorage.setItem("userId", data);
          message.success("Email verified successfully");
          history.push(adminRoutes.DASHBOARD);
        })
        .catch((error) => {
          if (error) {
            message.error(error.message);
          }
        });
    });
  }, []);

  return (
    //     <StyledBg backgroundImg={bgImage}>
    //     <StyledLogoLabel>
    //       {logoImage && <img src={logoImage} alt="cover" />}
    //     </StyledLogoLabel>
    //   </StyledBg>
    <div></div>
  );
};

export default EmailVerify;
