import React from "react";
import { Row } from "antd";
import { EventCard } from "../../../ui/Card";
import { Heading } from "../../../ui/Heading";
import { StyledEventWrapper, HeadingWrapper } from "../style";
import {
  StyledAddButton,
  StyledHeaderTextwrapper,
} from "Components/Team/FindTeam/style";
import { HiPlus } from "react-icons/hi";
import { FloatButton } from "Components/Relationship/TheRelationship.styles";
import { SecondaryButton } from "ui/Button/Button";

const EventSection = ({ UserProfileEventData }) => {
  return (
    <StyledEventWrapper>
      <HeadingWrapper>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
          onClick={() => console.log("Events add")}
          height={"52px"}
          width={"52px"}
        />
        <Heading fontSize={"24px"} color={"#232333"} marginBottom={"0px"}>
          Events
        </Heading>
      </HeadingWrapper>
      <Row gutter={[30, 30]}>
        {UserProfileEventData.map((item) => (
          <EventCard
            eventName={item.eventName}
            eventAt={item.eventAt}
            eventBy={item.eventBy}
            eventUsers={item.users}
            eventImage={item.image}
          />
        ))}
      </Row>
      <FloatButton>
        <SecondaryButton
          shape="circle"
          icon={<HiPlus className="plus_icon" />}
          onClick={() => console.log("Event add")}
        />
      </FloatButton>
    </StyledEventWrapper>
  );
};

export default EventSection;
